export const build = {
  appUrl: 'https://decentworld.com',
  seo: {
    appName: 'DecentWorld',
    defaultTitle: 'DecentWorld - Digital Real Estate Metaverse',
    defaultDescription:
      'Digital real estate metaverse platform powered by advanced blockchain technology. Buy and trade virtual NFT real estate with DecentWorld',
    defaultImage: 'https://decentworld.com/assets/decentworld-1200x630.png',
    defaultImageSize: { w: '1200', h: '630' },
  },
};
