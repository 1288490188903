import { CityTOListResponse } from 'api/models';
import { GisService } from 'dashboard/services/gis.service';
import { Observable } from 'rxjs';

import { CitySearchStrategy } from './city-search-strategy.interface';

export class CitySearchGisStrategy implements CitySearchStrategy {
  constructor(private gisService: GisService) {}

  getCities(
    name: string,
    countryCode?: string,
    pageSize?: number,
    regionCode?: string
  ): Observable<CityTOListResponse> {
    return this.gisService.findCityStartsWith(name, countryCode, pageSize, regionCode, undefined);
  }
}
