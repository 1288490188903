import { Injectable } from '@angular/core';
import { I18nService } from 'core/modules/i18n/i18n.service';
import { Toaster } from 'core/toaster';

import { APIError } from '../models/error.model';

export interface APIErrorHandler {
  handleError(error: APIError): boolean;
}

const MESSAGES_WHITELIST: string[] = [];

@Injectable({ providedIn: 'root' })
export class APIErrorsHandler {
  private handlers: APIErrorHandler[] = [];

  constructor(toaster: Toaster, i18n: I18nService) {
    this.addErrorHandler({
      handleError: (err: APIError) => {
        const message = MESSAGES_WHITELIST.includes(err.code)
          ? err.message
          : i18n.get($t(`shared.API_ERRORS.${err.code}`));
        toaster.warn(i18n.get($t('shared.toast.error_title')), message);
        return true;
      },
    });
  }

  handleErrors(errors: APIError[]): void {
    errors
      .filter(err => !err.isPrevented)
      .forEach(err => {
        for (const h of this.handlers) {
          if (h.handleError(err)) {
            break;
          }
        }
      });
  }

  handleError(error: APIError): void {
    if (error.isPrevented) {
      return;
    }
    for (const h of this.handlers) {
      if (h.handleError(error)) {
        break;
      }
    }
  }

  addErrorHandler(handler: APIErrorHandler): void {
    this.handlers.push(handler);
  }

  insertErrorHandler(handler: APIErrorHandler, index = 0): void {
    this.handlers.splice(index, 0, handler);
  }

  removeErrorHandler(handler: APIErrorHandler | number): void {
    if (typeof handler === 'number') {
      this.handlers.splice(handler, 1);
      return;
    }
    const foundIndex = this.handlers.findIndex(h => h === handler);
    if (foundIndex > -1) {
      this.handlers.splice(foundIndex, 1);
    }
  }
}
