<form
  [formGroup]="form"
  [ngClass]="submitted ? 'force-error' : 'default'"
  appAPIValidate
  class="form">

  <app-form-field class="width-100">
    <app-input
      [formControl]="form.controls.label"
      class="solid"
      name="label"
      title="Label"></app-input>
  </app-form-field>
</form>
