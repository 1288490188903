import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyService } from 'core/services/currency.service';

import { PrecisionPipe } from './precision.pipe';

@Pipe({
  name: 'money',
})
export class MoneyPipe implements PipeTransform {
  private readonly precisionPipe = new PrecisionPipe(this.currencyService);

  constructor(private currencyService: CurrencyService) {}

  transform(value?: number, code?: string, fallback?: string): string {
    if (!value && fallback) {
      return fallback;
    }
    if (code === 'DPC') {
      code = '';
    }
    return `${this.precisionPipe.transform(value, code)} ${code}`;
  }
}
