import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { I18nService, LocalizedString } from 'core/modules/i18n/i18n.service';
import { build } from 'environments/build';
import { Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';

import { SeoMeta, SeoService } from './seo.service';

@Injectable()
export class SeoClientService extends SeoService {
  private navSub = new Subscription();

  constructor(private router: Router, private seo: Title, private i18n: I18nService) {
    super();
  }

  set(meta: SeoMeta = {}) {
    if (meta.title instanceof LocalizedString) {
      this.seo.setTitle(this.i18n.get(meta.title).replace(/–\s+–/g, '–'));
    } else {
      meta.title = (meta.title || build.seo.defaultTitle).replace(/–\s+–/g, '–');
      this.seo.setTitle(meta.title);
    }

    this.clearOnNavigation();
  }

  private clearOnNavigation() {
    this.navSub.unsubscribe();
    this.navSub = this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        first()
      )
      .subscribe(() => {
        this.seo.setTitle(build.seo.defaultTitle);
      });
  }
}
