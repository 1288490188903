import { Injectable } from '@angular/core';
import { NftTO, OrderTO, OrderTOResponse } from 'api/models';
import { MarketService } from 'cashier/services/market.service';
import { dataToOrder, Order } from 'dashboard/models/order.model';

import { BuildingsFactory } from './buildings.factory';
import { StreetsFactory } from './streets.factory';

export const instanceOfOrderTOResponse = (object: unknown): object is OrderTOResponse =>
  !!(object as OrderTOResponse)?.nft?.properties;
@Injectable({ providedIn: 'root' })
export class OrdersFactory {
  private store: Map<number, Order> = new Map();

  constructor(
    private streetsFactory: StreetsFactory,
    private buildingsFactory: BuildingsFactory,
    private marketService: MarketService
  ) {}

  getOrder(id = 0, data: OrderTOResponse | OrderTO = {}): Order {
    let order = this.store.get(id);
    if (instanceOfOrderTOResponse(data)) {
      (data.nft?.properties as NftTO).marketId = data.marketId;
      if (order) {
        return data ? Object.assign(order, dataToOrder(data)) : order;
      } else {
        const orderAsset = data.nft?.properties?.buildingId
          ? this.buildingsFactory.get(data.nft.properties.buildingId, data.nft?.properties)
          : this.streetsFactory.get(data.nft?.properties?.streetId, {
              ...data.nft?.properties,
              orderId: id,
            } as NftTO);
        order = new Order(dataToOrder(data), orderAsset);
        this.store.set(id, order);
        return order;
      }
    } else {
      if (order) {
        return data ? Object.assign(order, dataToOrder(data)) : order;
      } else {
        const orderAsset = data.nft?.buildingId
          ? this.buildingsFactory.get(data.nft.buildingId, data.nft)
          : this.streetsFactory.get(data.nft?.streetId, data.nft);
        order = new Order(dataToOrder(data), orderAsset);
        this.store.set(id, order);
        return order;
      }
    }
  }

  getOrderExchange(id = 0, data: OrderTOResponse = {}): Order {
    let order = this.store.get(id);
    const market = this.marketService.getMarket(data.marketId || 0);
    if (order) {
      return data ? Object.assign(order, dataToOrder(data, market)) : order;
    } else {
      order = new Order(dataToOrder(data, market));
      this.store.set(id, order);
      return order;
    }
  }

  emptyStore(): void {
    this.store.clear();
  }
}
