import { Inject, Injectable } from '@angular/core';

export interface WindowDataLayer {
  dataLayer?: unknown[];
}

@Injectable({
  providedIn: 'root',
})
export class GTMService {
  constructor(@Inject(Window) private window: Window) {}

  pushTag(config: Record<string, unknown>): void {
    console.log('[GTM]: ' + JSON.stringify(config));
    const dataLayer = (this.window as WindowDataLayer).dataLayer || [];
    dataLayer.push(config);
  }
}
