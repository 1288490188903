/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { StreetTO } from '../models/street-to';
import { StreetBidResponse } from '../models/street-bid-response';
import { StreetHistoryResponse } from '../models/street-history-response';

/**
 * Street Controller
 */
@Injectable({
  providedIn: 'root',
})
class StreetControllerService extends __BaseService {
  static readonly updateStreetByGoogleUsingPUTPath = '/api/v1/street';
  static readonly streetBidUsingGETPath = '/api/v1/street/bid';
  static readonly streetHistoryUsingGETPath = '/api/v1/street/history';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * updateStreetByGoogle
   * @param streetTO streetTO
   * @return OK
   */
  updateStreetByGoogleUsingPUTResponse(streetTO: StreetTO): __Observable<__StrictHttpResponse<StreetTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = streetTO;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/street`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StreetTO>;
      })
    );
  }
  /**
   * updateStreetByGoogle
   * @param streetTO streetTO
   * @return OK
   */
  updateStreetByGoogleUsingPUT(streetTO: StreetTO): __Observable<StreetTO> {
    return this.updateStreetByGoogleUsingPUTResponse(streetTO).pipe(
      __map(_r => _r.body as StreetTO)
    );
  }

  /**
   * streetBid
   * @param markets markets
   * @return OK
   */
  streetBidUsingGETResponse(markets?: Array<number>): __Observable<__StrictHttpResponse<Array<StreetBidResponse>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (markets || []).forEach(val => {if (val != null) __params = __params.append('markets', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/street/bid`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<StreetBidResponse>>;
      })
    );
  }
  /**
   * streetBid
   * @param markets markets
   * @return OK
   */
  streetBidUsingGET(markets?: Array<number>): __Observable<Array<StreetBidResponse>> {
    return this.streetBidUsingGETResponse(markets).pipe(
      __map(_r => _r.body as Array<StreetBidResponse>)
    );
  }

  /**
   * streetHistory
   * @param markets markets
   * @return OK
   */
  streetHistoryUsingGETResponse(markets?: Array<number>): __Observable<__StrictHttpResponse<Array<StreetHistoryResponse>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (markets || []).forEach(val => {if (val != null) __params = __params.append('markets', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/street/history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<StreetHistoryResponse>>;
      })
    );
  }
  /**
   * streetHistory
   * @param markets markets
   * @return OK
   */
  streetHistoryUsingGET(markets?: Array<number>): __Observable<Array<StreetHistoryResponse>> {
    return this.streetHistoryUsingGETResponse(markets).pipe(
      __map(_r => _r.body as Array<StreetHistoryResponse>)
    );
  }
}

module StreetControllerService {
}

export { StreetControllerService }
