import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ConfirmEmailTOResponse,
  LoginForm,
  LoginTwoFactorAuthForm,
  LoginUserTOResponse,
  PasswordSetNewRequest,
  SignupForm,
  UserInfoTOResponse,
} from 'api/models';
import { UserControllerService } from 'api/services';
import {appNav, lazyModalsNav} from 'app/app.navigation';
import { JwtService } from 'auth/services/jwt.service';
import { ModalRouter } from 'core/modules/modal';
import { environment } from 'environments/environment';
import { UserInitializerService } from 'profile/initializers/user.initializer';
import { fromEvent, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { filter, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import {NavigationExtras,Router} from '@angular/router';
import {commonNav} from "app/_main/main.navigation";

declare global {
  interface Window {
    smartlook: any;
  }
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  private loggedIn$ = new ReplaySubject<UserInfoTOResponse | undefined>(1);
  private loggedOut$ = new Subject<void>();
  private onAuthorized$ = new Subject<void>();
  private redirectUrl: string | undefined;
  loggedIn = this.loggedIn$.asObservable();
  loggedOut = this.loggedOut$.asObservable();
  onAuthorized = this.onAuthorized$.asObservable();

  private initializer!: UserInitializerService;

  constructor(
    private api: UserControllerService,
    private modal: ModalRouter,
    private jwtService: JwtService,
    private http: HttpClient,
    private router: Router
  ) {
    fromEvent<StorageEvent>(window, 'storage')
      .pipe(filter(event => event.key === this.jwtService.key))
      .subscribe(event => {
        if (event.storageArea == localStorage) {
          window.location.reload();
        }
      });
  }

  get isAuth(): boolean {
    return this.jwtService.haveToken();
  }

  setupInitializer(initializer: UserInitializerService): void {
    this.initializer = initializer;
  }

  load(): Observable<UserInfoTOResponse> {
    return this.api.userSettingsUsingGET().pipe(
      tap(userData => {
        this.loggedIn$.next(userData);
        this.onAuthorized$.next();
      })
    );
  }

  login(login: LoginForm): Observable<LoginUserTOResponse> {
    return this.api.loginUsingPOST(login).pipe(mergeMap(data => this.handleLoginResponse(data)));
  }

  signup(
    form: SignupForm,
    offId?: string,
    affid?: string,
    c?: string
  ): Observable<LoginUserTOResponse> {
    return this.getAffiliateParams(offId, affid, c, form.referral).pipe(
      mergeMap(params =>
        this.api
          .signupUsingPOST({
            form: form,
            goal: params.goal,
            offid: params.offid,
            clickHash: params.clickHash,
          })
          .pipe(mergeMap(data => this.handleLoginResponse(data)))
      )
    );
  }

  private getAffiliateParams(
    offid?: string,
    affid?: string,
    c?: string,
    referral?: string
  ): Observable<{
    goal?: string;
    offid?: string;
    clickHash?: string;
  }> {
    if ((offid && c && affid) || !environment) {
      return of({
        clickHash: c,
        goal: affid,
        offid: offid,
      });
    }
    let affiliateUrl = 'https://aff.decentworld.com/track/OS4xODkuMy45LjAuMC4wLjAuMC4wLjAuMA';
    if (referral?.match(/[0-9]{6,}/) !== null) {
      affiliateUrl = 'https://aff.decentworld.com/track/MTE5LjE4OS4zLjkuMC4wLjAuMC4wLjAuMC4w';
    }
    return new Observable(s => {
      this.http
        .get(affiliateUrl)
        .pipe(finalize(() => s.complete()))
        .subscribe({
          error: err => {
            const url = (err as { url: string }).url;
            const queryParams = url.slice(url.indexOf('?') + 1);
            const httpParams = new HttpParams({ fromString: queryParams });
            const c = httpParams.get('c') || '';
            const offid = httpParams.get('offid') || '';
            const affid = httpParams.get('affid') || '';
            s.next({ clickHash: c, goal: affid, offid });
          },
        });
    });
  }

  loginSocialGoogle(socialToken: string): Observable<LoginUserTOResponse> {
    return this.api
      .socialLoginUsingGET({
        type: 'GOOGLE',
        token: socialToken,
      })
      .pipe(mergeMap(data => this.handleLoginResponse(data)));
  }

  loginSocialFacebook(token: string): Observable<LoginUserTOResponse> {
    return this.api
      .socialLoginUsingGET({
        type: 'FACEBOOK',
        token,
      })
      .pipe(mergeMap(data => this.handleLoginResponse(data)));
  }

  login2FAGoogle(login: LoginTwoFactorAuthForm): Observable<LoginUserTOResponse> {
    return this.api
      .loginTwoFactorAuthUsingPOST({
        provider: 'google',
        form: login,
      })
      .pipe(mergeMap(data => this.handleLoginResponse(data)));
  }

  logout(): Observable<unknown> {
    return this.api.logoutUsingPOST().pipe(tap(() => this.clearSession()));
  }

  clearSession(): void {
    this.jwtService.removeToken();
    this.loggedOut$.next();
  }

  confirmRegistration(token: string): Observable<ConfirmEmailTOResponse> {
    return this.api.confirmEmailUsingPOSTResponse({ token }).pipe(
      mergeMap(res => {
        const token = res.headers.get('x-token');
        if (token) {
          this.jwtService.setToken(token);
        }
        return this.initializer.load().pipe(
          switchMap(() => this.load()),
          map(() => res.body)
        );
      })
    );
  }

  setNewPassword(
    form: PasswordSetNewRequest & { confirmNewPassword: string }
  ): Observable<LoginUserTOResponse> {
    return this.api
      .setNewPasswordUsingPOST(form)
      .pipe(mergeMap(data => this.handleLoginResponse(data)));
  }

  handleUnauthorizedAction(): void {
    const wasLoggedIn = this._getWasLoggedIn();
    if (wasLoggedIn) {
      this.modal.navigate(lazyModalsNav.auth.signIn());
    } else {
      this.modal.navigate(lazyModalsNav.auth.signUp());
    }
  }

  private handleLoginResponse(data: LoginUserTOResponse): Observable<UserInfoTOResponse> {
    const wasLoggedIn = this._getWasLoggedIn();
    if (!wasLoggedIn) {
      localStorage.setItem('WAS_LOGGED_IN', '1');
    }
    const token = data.token;
    if (token && data.nextStep === 'NO') {
      this.jwtService.setToken(token);
      // SmartLook
      if(data.userInfo) {
        window.smartlook('identify', data.userInfo.uuid);
      }
      return this.initializer.load().pipe(switchMap(() => this.load()));
    }
    return of(data);
  }

  // Set the initially requested URL
  setRedirectUrl(url: string): void {
    this.redirectUrl = url;
  }

  // Get the initially requested URL
  getRedirectUrl(): string|undefined {
    return this.redirectUrl;
  }

  // Redirect to the initially requested URL
  redirect(): boolean {
    const redirectUrl = this.getRedirectUrl();
    if(redirectUrl) {
      const navigationExtras: NavigationExtras = {
        queryParamsHandling: 'preserve',
        preserveFragment: true
      };

      this.router.navigateByUrl(redirectUrl, navigationExtras);
      return true;
    }
    return false;
  }

  getWasLoggedIn(): boolean {
    return this._getWasLoggedIn();
  }

  private _getWasLoggedIn(): boolean {
    return Boolean(localStorage.getItem('WAS_LOGGED_IN'));
  }

}
