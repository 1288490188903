/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DecentWorldMarketplaceTO } from '../models/decent-world-marketplace-to';
import { HeartBeatTO } from '../models/heart-beat-to';
import { FinanceUserDataTO } from '../models/finance-user-data-to';

/**
 * Statistic Controller
 */
@Injectable({
  providedIn: 'root',
})
class StatisticControllerService extends __BaseService {
  static readonly getDecentWorldMarketplaceUsingGETPath = '/statistic/decent-world-marketplace';
  static readonly getHearBeatUsingGETPath = '/statistic/heart-beat';
  static readonly getUserFinanceDataUsingGETPath = '/statistic/user-finance-statistic';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get DecentWorld marketplace
   * @return OK
   */
  getDecentWorldMarketplaceUsingGETResponse(): __Observable<__StrictHttpResponse<DecentWorldMarketplaceTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/statistic/decent-world-marketplace`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DecentWorldMarketplaceTO>;
      })
    );
  }
  /**
   * Get DecentWorld marketplace
   * @return OK
   */
  getDecentWorldMarketplaceUsingGET(): __Observable<DecentWorldMarketplaceTO> {
    return this.getDecentWorldMarketplaceUsingGETResponse().pipe(
      __map(_r => _r.body as DecentWorldMarketplaceTO)
    );
  }

  /**
   * Get heart beat
   * @return OK
   */
  getHearBeatUsingGETResponse(): __Observable<__StrictHttpResponse<HeartBeatTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/statistic/heart-beat`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HeartBeatTO>;
      })
    );
  }
  /**
   * Get heart beat
   * @return OK
   */
  getHearBeatUsingGET(): __Observable<HeartBeatTO> {
    return this.getHearBeatUsingGETResponse().pipe(
      __map(_r => _r.body as HeartBeatTO)
    );
  }

  /**
   * Get user statistic
   * @param period period
   * @return OK
   */
  getUserFinanceDataUsingGETResponse(period?: 'DAY' | 'MONTH' | 'WEEK' | 'YEAR'): __Observable<__StrictHttpResponse<FinanceUserDataTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (period != null) __params = __params.set('period', period.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/statistic/user-finance-statistic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FinanceUserDataTO>;
      })
    );
  }
  /**
   * Get user statistic
   * @param period period
   * @return OK
   */
  getUserFinanceDataUsingGET(period?: 'DAY' | 'MONTH' | 'WEEK' | 'YEAR'): __Observable<FinanceUserDataTO> {
    return this.getUserFinanceDataUsingGETResponse(period).pipe(
      __map(_r => _r.body as FinanceUserDataTO)
    );
  }
}

module StatisticControllerService {
}

export { StatisticControllerService }
