<div class="content-container">
  <div class="disclaimer-section contact-section">
    <p class="header-h3 margin-bottom-hg">{{ 'footer.contact.title' | translate }}</p>

    <div class="initial">
      <div class="img">
        <img alt="" class="margin-bottom" src="assets/img/initial/swiss.png" width="40" />
      </div>
      <div [innerHTML]="'footer.contact.address' | translate"></div>
    </div>

    <div class="contacts">
      <div class="contact">
        <p class="contact-title">{{ 'footer.contact.email.title' | translate }}</p>
        <a class="contact-link margin-bottom-hg" href="mailto:hello@decentworld.com">
          {{ 'footer.contact.email.value' | translate }}
        </a>
      </div>
<!--      <div class="contact">-->
<!--        <p class="contact-title">{{ 'footer.contact.phone.title' | translate }}</p>-->
<!--        <a class="contact-link margin-bottom-hg" href="tel:+41435080222">-->
<!--          {{ 'footer.contact.phone.value' | translate }}-->
<!--        </a>-->
<!--      </div>-->
      <div class="contact">
        <p class="contact-title">{{ 'footer.contact.web.title' | translate }}</p>
        <a class="contact-link margin-bottom-hg" href="/">decentworld.com</a>
      </div>
      <div class="contact">
        <p class="contact-title">{{ 'footer.contact.metaverse.title' | translate }}</p>
        <a
          [routerLink]="appNav.landingNav.application()"
          appButton
          class="solid medium primary download-link margin-right">
          <div class="display-flex align-center">
            {{ 'footer.contact.metaverse.metaverse-download' | translate }}
            <app-icon [icon]="windowsIcon" class="margin-left"></app-icon>
            <app-icon [icon]="macosIcon" class="margin-left-tn"></app-icon>
          </div>
        </a>
      </div>
    </div>

<!--    <div class="social">-->
<!--      <p class="community-title contact-title margin-bottom text-left">-->
<!--        {{ 'footer.contact.social-media.title' | translate }}-->
<!--      </p>-->

<!--      <div class="social-links">-->
<!--        <a class="margin-right-lg" href="https://t.me/decent_world" target="_blank">-->
<!--          <img class="socila-img" src="assets/socials/telegram.svg" alt="" />-->
<!--        </a>-->
<!--        <a class="margin-right-lg" href="https://twitter.com/DecentWorldCom" target="_blank">-->
<!--          <img class="socila-img" src="assets/socials/twitter.svg" alt="" />-->
<!--        </a>-->
<!--        <a-->
<!--          class="margin-right-lg"-->
<!--          href="https://discord.gg/qqWJmBwFcT"-->
<!--          style="transform: scale(1.15)"-->
<!--          target="_blank">-->
<!--          <img class="socila-img" src="assets/socials/discord.png" alt="" />-->
<!--        </a>-->
<!--        &lt;!&ndash; <a class="margin-right-lg" href="https://www.facebook.com/DecentWorldCom/" target="_blank">-->
<!--          <img class="socila-img" src="assets/socials/facebook.svg" alt="" />-->
<!--        </a> &ndash;&gt;-->
<!--        <a class="margin-right-lg" href="https://www.instagram.com/decentworldcom/" target="_blank">-->
<!--          <img class="socila-img" src="assets/socials/instagram.svg" alt="" />-->
<!--        </a>-->
<!--        <a-->
<!--          class="margin-right-lg"-->
<!--          href="https://www.linkedin.com/company/decentworldcom"-->
<!--          target="_blank">-->
<!--          <img class="socila-img" src="assets/socials/linkedin.svg" alt="" />-->
<!--        </a>-->
<!--        <a class="margin-right-lg" href="https://medium.com/@decentworldcom" target="_blank">-->
<!--          <img class="socila-img" src="assets/socials/medium.svg" alt="" />-->
<!--        </a>-->
<!--      </div>-->
<!--    </div>-->
  </div>

  <div class="disclaimer-section explore-section">
    <div class="explore">
      <p class="header-h3 margin-bottom-hg">{{ 'footer.contact.explore.title' | translate }}</p>

      <div class="explore-content">
        <a class="link margin-right margin-bottom-lg" [routerLink]="appNav.initial()">
          {{ 'footer.contact.explore.home' | translate }}
        </a>
        <a class="link margin-right margin-bottom-lg" [routerLink]="appNav.streetsNav.map()">
          {{ 'footer.contact.explore.map' | translate }}
        </a>
        <a
          class="link margin-right margin-bottom-lg"
          [routerLink]="appNav.marketplaceNav.marketplaceInit()">
          {{ 'footer.contact.explore.marketplace' | translate }}
        </a>
        <a
          class="link margin-right margin-bottom-lg"
          [routerLink]="appNav.collectionsNav.allCollections()">
          {{ 'footer.contact.explore.collections' | translate }}
        </a>
        <a
          class="link margin-right margin-bottom-lg"
          [routerLink]="appNav.landingNav.application()">
          {{ 'footer.contact.explore.metaverse' | translate }}
        </a>
        <a
          class="link margin-right margin-bottom-lg"
          [routerLink]="appNav.streetsCatalogNav.streetsCatalog()">
          {{ 'footer.contact.explore.streets-catalog' | translate }}
        </a>
<!--        <a class="link margin-right margin-bottom-lg" [routerLink]="appNav.landingNav.coreTeam()">-->
<!--          {{ 'footer.contact.explore.team' | translate }}-->
<!--        </a>-->
        <a class="link margin-right margin-bottom-lg" href="https://discord.com/invite/qqWJmBwFcT" target="_blank">
          {{ 'footer.component.bug_bounty' | translate }}
        </a>
      </div>
    </div>

    <div class="learn">
      <p class="header-h3 margin-bottom-hg">{{ 'footer.contact.learn.title' | translate }}</p>

      <div class="learn-content">
        <!-- <a class="link margin-right margin-bottom-lg" href="" target="_blank">
          {{ 'footer.contact.learn.blog' | translate }}
        </a> -->
        <a class="link margin-right margin-bottom-lg" href="https://decentworld.com/academy/">
          {{ 'footer.contact.learn.academy' | translate }}
        </a>
        <a class="link margin-right margin-bottom-lg" [routerLink]="appNav.commonNav.help()">
          {{ 'footer.contact.learn.faq' | translate }}
        </a>
      </div>
    </div>
  </div>

  <div class="disclaimer-section legal">
    <p class="header-h3 margin-bottom-hg">{{ 'footer.contact.legal.title' | translate }}</p>

    <div class="legal-content">
      <div class="legal-content-item">
        <div (click)="redirectToLegal('disclaimer')" class="link margin-bottom-hg">
          {{ 'footer.contact.legal.disclaimer' | translate }}
        </div>
        <div (click)="redirectToLegal('terms_of_use')" class="link margin-bottom-hg">
          {{ 'footer.contact.legal.terms-of-use' | translate }}
        </div>
        <div (click)="redirectToLegal('privacy_policy')" class="link margin-bottom-hg">
          {{ 'footer.contact.legal.privacy-policy' | translate }}
        </div>

        <div (click)="redirectToLegal('privacy_policy_short')" class="link margin-bottom-hg">
          {{ 'footer.contact.legal.privacy-policy-short' | translate }}
        </div>

        <div (click)="redirectToLegal('supervision')" class="link margin-bottom-hg">
          {{ 'footer.contact.legal.supervision' | translate }}
        </div>
      </div>
      <div class="legal-content-item">
        <div (click)="redirectToLegal('impressum')" class="link margin-bottom-hg">
          {{ 'footer.contact.legal.impressum' | translate }}
        </div>
        <div (click)="redirectToLegal('cookie_statement')" class="link margin-bottom-hg">
          {{ 'footer.contact.legal.cookie-statement' | translate }}
        </div>
        <div (click)="redirectToLegal('aml_ctf_policy')" class="link margin-bottom-hg">
          {{ 'footer.contact.legal.ctf-policy' | translate }}
        </div>
        <div (click)="redirectToLegal('compliance_policy')" class="link margin-bottom-hg">
          {{ 'footer.contact.legal.complience-policy' | translate }}
        </div>
      </div>
    </div>
  </div>

  <div class="disclaimer-section press-section">
    <div class="press">
      <p class="header-h3 margin-bottom-hg">{{ 'main.footer.press.title' | translate }}</p>
      <div class="press-pages">
        <a
          class="link margin-right margin-bottom-lg"
          href="https://drive.google.com/drive/folders/11WCSBwf8zhh4a177Bk3k9GLqPZ0xjnD4"
          target="_blank">
          {{ 'main.footer.press.one_pages' | translate }}
        </a>
        <a
          class="link margin-right margin-bottom-lg"
          href="https://drive.google.com/drive/folders/14n0O2OdoMNTZhUSv-ymT5xDX7falFJkZ"
          target="_blank">
          {{ 'main.footer.press.press_kit' | translate }}
        </a>
        <a
          class="link margin-right margin-bottom-lg"
          href="https://drive.google.com/drive/folders/1cnNPNwU3j_C7fVzAIulBHAB3BK52hw4u"
          target="_blank">
          Tokenomics
        </a>
      </div>
    </div>

    <div class="partner" *ngIf="false">
      <p class="header-h3 margin-bottom-hg">{{ 'footer.contact.partner.title' | translate }}</p>

      <div>
        <a class="link margin-right" href="https://aff.decentworld.com/landing" target="_blank">
          {{ 'footer.contact.partner.affiliate' | translate }}
        </a>
      </div>
    </div>

    <div class="language user-menu-footer margin-top-hg">
      <div class="user-menu-footer-item">
        <app-data-expand
          class="clear arrow-down language-picker"
          [absoluteIcon]="true"
          [showMoreString]="false">
          <div class="language-picker-title" appAnchor>
            <app-svg
              [asset]="['flags', localizeFormControl.value.iso2 | lowercase]"
              class="round"></app-svg>
            <span>{{ localizeFormControl.value.code }}</span>
          </div>

          <ng-template>
            <div
              *ngFor="let option of localization"
              (click)="changeLanguage(option)"
              class="language-picker-item">
              <app-svg [asset]="['flags', option.iso2 | lowercase]" class="round"></app-svg>
              {{ option.name }}
            </div>
          </ng-template>
        </app-data-expand>
      </div>
    </div>
  </div>
</div>
<div class="footer">
  <div class="footer-data">
    <img class="footer-logo" src="assets/svg/logo-white.svg" style="max-width: 198px" />
  </div>
</div>
