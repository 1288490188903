<app-menu appearance="full-screen" class="autocomplete search-mobile width-100">
  <ng-template>
    <cdk-virtual-scroll-viewport
      *ngIf="cities.length; else emptyTmpl"
      [style.height.px]="40 * (cities.length > 6 ? 6 : cities.length)"
      class="data-host"
      itemSize="44"
      maxBufferPx="750"
      minBufferPx="650">
      <ng-container>
        <button
          (click)="select(c)"
          *cdkVirtualFor="let c of cities"
          appButton
          appMenuItem
          class="solid for-select">
          <span class="display-flex">
            {{ c.name }}
            <span class="color-txt-secondary" *ngIf="!countryCode">, {{ c.country }}</span>
          </span>
        </button>
      </ng-container>
    </cdk-virtual-scroll-viewport>
    <ng-template #emptyTmpl>
      <button appButton class="solid for-select">{{ 'main.city_search.empty' | translate }}</button>
    </ng-template>
  </ng-template>
  <app-input
    [extras]="value.name ? 'clear' : 'dropdown'"
    [formControl]="control"
    [placeholder]="value.name || ('main.city_search.placeholder' | translate)"
    autocomplete="new-city"
    appAnchor
    class="solid medium primary-dark with-flag"
    [ngClass]="{ 'with-value': value.name }"></app-input>
</app-menu>
