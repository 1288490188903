/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApplicationSettingsTOResponse } from '../models/application-settings-toresponse';
import { SettingsTOListResponse } from '../models/settings-tolist-response';
import { FeesLimitsTOListResponse } from '../models/fees-limits-tolist-response';

/**
 * Settings Controller
 */
@Injectable({
  providedIn: 'root',
})
class SettingsControllerService extends __BaseService {
  static readonly settingsUsingGETPath = '/api/v1/settings';
  static readonly commonSettingsUsingGETPath = '/api/v1/settings/common';
  static readonly currenciesUsingGETPath = '/api/v1/settings/fees-and-limits/list';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Application settings
   *
   * List of application settings.<br/>
   * @return OK
   */
  settingsUsingGETResponse(): __Observable<__StrictHttpResponse<ApplicationSettingsTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApplicationSettingsTOResponse>;
      })
    );
  }
  /**
   * Application settings
   *
   * List of application settings.<br/>
   * @return OK
   */
  settingsUsingGET(): __Observable<ApplicationSettingsTOResponse> {
    return this.settingsUsingGETResponse().pipe(
      __map(_r => _r.body as ApplicationSettingsTOResponse)
    );
  }

  /**
   * Common settings
   *
   * Common settings for application<br/>
   * @return OK
   */
  commonSettingsUsingGETResponse(): __Observable<__StrictHttpResponse<SettingsTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/settings/common`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SettingsTOListResponse>;
      })
    );
  }
  /**
   * Common settings
   *
   * Common settings for application<br/>
   * @return OK
   */
  commonSettingsUsingGET(): __Observable<SettingsTOListResponse> {
    return this.commonSettingsUsingGETResponse().pipe(
      __map(_r => _r.body as SettingsTOListResponse)
    );
  }

  /**
   * Fees and Limits
   *
   * Currencies which are available for purchase<br/>
   * @return OK
   */
  currenciesUsingGETResponse(): __Observable<__StrictHttpResponse<FeesLimitsTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/settings/fees-and-limits/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FeesLimitsTOListResponse>;
      })
    );
  }
  /**
   * Fees and Limits
   *
   * Currencies which are available for purchase<br/>
   * @return OK
   */
  currenciesUsingGET(): __Observable<FeesLimitsTOListResponse> {
    return this.currenciesUsingGETResponse().pipe(
      __map(_r => _r.body as FeesLimitsTOListResponse)
    );
  }
}

module SettingsControllerService {
}

export { SettingsControllerService }
