import { Injectable, NgZone } from '@angular/core';
import { MapboxGeoJSONFeature } from 'mapbox-gl';

import { MapMarketsWorkerData, MapMarketsWorkerResult } from '../models/map.data';
import { MapService } from './map.service';

@Injectable()
export class MapClientService implements MapService {
  private marketsWorker: Worker;
  private buildingsMarketsWorker: Worker;
  private pricesWorker: Worker;

  constructor(private zone: NgZone) {
    this.marketsWorker = new Worker(new URL('../workers/select-markets.worker', import.meta.url));
    this.buildingsMarketsWorker = new Worker(
      new URL('../workers/select-markets.worker', import.meta.url)
    );
    this.pricesWorker = new Worker(new URL('../workers/price-range.worker', import.meta.url));
  }

  getPriceRange(
    streetFeatures: MapboxGeoJSONFeature[],
    buildingFeatures: MapboxGeoJSONFeature[],
    cb: (value: { min: number; max: number }) => void
  ): void {
    if (typeof Worker !== 'undefined') {
      this.pricesWorker.onmessage = ({ data }) => {
        this.zone.run(() => {
          cb(data as { min: number; max: number });
        });
      };
      this.pricesWorker.postMessage({ streetFeatures, buildingFeatures });
    } else {
      // TODO: fallback to single thread or show some message
    }
  }

  getBuildingsMarketsFeatures(
    features: MapboxGeoJSONFeature[],
    cb: (value: MapMarketsWorkerResult) => void
  ): void {
    if (typeof Worker !== 'undefined') {
      this.buildingsMarketsWorker.onmessage = ({ data }: { data: MapMarketsWorkerResult }) => {
        this.zone.run(() => {
          cb(data);
        });
      };
      this.buildingsMarketsWorker.postMessage(features as MapMarketsWorkerData);
    } else {
      // TODO: fallback to single thread or show some message
    }
  }

  getMarketsFeatures(
    features: MapboxGeoJSONFeature[],
    cb: (value: MapMarketsWorkerResult) => void
  ): void {
    if (typeof Worker !== 'undefined') {
      this.marketsWorker.onmessage = ({ data }: { data: MapMarketsWorkerResult }) => {
        this.zone.run(() => {
          cb(data);
        });
      };
      this.marketsWorker.postMessage(features as MapMarketsWorkerData);
    } else {
      // TODO: fallback to single thread or show some message
    }
  }
}
