import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { MediaQuery } from 'core/modules/platform/services/media-query.service';
import { AppUI } from 'core/services/app-ui.service';
import { environment } from 'environments/environment';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil, throttleTime } from 'rxjs/operators';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[scrollLock]' })
export class ScrollLockDirective implements OnDestroy, OnInit, OnChanges {
  @Input()
  lockOffset = 0;

  @Input()
  scrollOffset = 0;

  @Input()
  lockPadding = 0;

  private width = 0;
  private height = 0;
  private offsetTop = 0;
  private top = 0;
  private parentPaddingTop = 0;
  private isLocked?: boolean;
  private parent!: HTMLElement;
  private destroy$ = new Subject();

  constructor(
    public elementRef: ElementRef<HTMLElement>,
    private appUi: AppUI,
    private renderer: Renderer2,
    private media: MediaQuery
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    if (environment.ssr) {
      return;
    }
    this.parent = this.renderer.parentNode(this.elementRef.nativeElement) as HTMLElement;
    fromEvent(window, 'scroll')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.updateLock());
    this.media.resized
      .pipe(throttleTime(150, undefined, { trailing: true }), takeUntil(this.destroy$))
      .subscribe(() => {
        this.updateSize();
      });
    this.renderer.setStyle(this.elementRef.nativeElement, 'z-index', '41');
    requestAnimationFrame(() => {
      this.updateSize();
      this.updateLock();
    });
  }

  ngOnChanges(changes: SimpleChanges<ScrollLockDirective>): void {
    if (changes.lockPadding) {
      this.updateStyle();
    }
  }

  private updateLock(): void {
    if (
      window.scrollY - this.scrollOffset >
      this.offsetTop - this.top - this.lockPadding + this.parentPaddingTop
    ) {
      if (!this.isLocked) {
        this.isLocked = true;
        this.renderer.addClass(this.elementRef.nativeElement, 'locked');
        this.renderer.setStyle(
          this.parent,
          'padding-top',
          `${
            this.height -
            this.lockPadding -
            this.lockOffset +
            this.parentPaddingTop +
            this.scrollOffset
          }px`
        );
        this.renderer.setStyle(this.elementRef.nativeElement, 'position', 'fixed');
        this.renderer.setStyle(
          this.elementRef.nativeElement,
          'top',
          `${this.top + this.lockPadding}px`
        );
      }
    } else if (this.isLocked) {
      this.isLocked = false;
      this.renderer.removeClass(this.elementRef.nativeElement, 'locked');
      this.renderer.setStyle(this.parent, 'padding-top', '');
      this.renderer.setStyle(this.elementRef.nativeElement, 'position', '');
    }
  }

  private updateSize(): void {
    const headerEl = this.appUi.headerComponent.elementRef.nativeElement;
    this.top = headerEl.offsetHeight;
    if (this.isLocked) {
      this.renderer.setStyle(
        this.elementRef.nativeElement,
        'top',
        `${this.top + this.lockPadding}px`
      );
    }
    this.width = this.parent.clientWidth;
    const computedStyle = window.getComputedStyle(this.parent);
    const paddingLeft = computedStyle.paddingLeft;
    const paddingRight = computedStyle.paddingRight;
    this.parentPaddingTop = parseInt(computedStyle.paddingTop);
    // height can be changed because of width changes
    requestAnimationFrame(() => {
      this.height = this.elementRef.nativeElement.clientHeight;
    });
    this.offsetTop = 0;
    // get parent offset top because page can be opened and immediately scrolled down
    this.offsetTop = this.parent.getBoundingClientRect().top + window.scrollY;
    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'width',
      `calc(${this.width}px - ${paddingRight} - ${paddingLeft})`
    );
  }

  private updateStyle(): void {
    this.renderer.setStyle(this.elementRef.nativeElement, 'padding-top', `${this.lockPadding}px`);
    this.renderer.setStyle(this.elementRef.nativeElement, 'margin-top', `-${this.lockPadding}px`);
  }
}
