import { createNavigation } from 'core/utils/router.utils';

import { QuizGameType } from './models/quiz.interface';

export interface MiniGameQuizParams {
  type: QuizGameType;
  id: number;
}

export const miniGamesRoot = 'mini-games';

export const root = (): string => '';

export const quiz = (type: QuizGameType, id: number): string => `quiz/${type}/${id}`;

export const paths = {
  root,
  quiz,
};

export const modalPaths = {};

export const miniGamesNav = createNavigation(paths, miniGamesRoot);
export const miniGamesModalsNav = createNavigation(modalPaths, miniGamesRoot);
