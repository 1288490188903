import { Component, HostListener, OnDestroy } from '@angular/core';
import { lazyModalsNav } from 'app/app.navigation';
import { iconProfile } from 'core/icons/lib/icon-profile';
import { ModalRouter } from 'core/modules/modal';
import { BaseCustomToast } from 'core/toaster/base-custom-toast.interface';
import { ToastExtras } from 'core/toaster/services/toaster.service';
import { StreetPreviewModal } from 'dashboard/dashboard.navigation';
import {
  NFTToastData,
  NftTradeEvents,
  UserTradeMessageType,
} from 'dashboard/services/notification-events.service';
import { StreetsService } from 'dashboard/services/streets.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-nft-trade-toast',
  templateUrl: './nft-trade-toast.component.html',
  styleUrls: ['./nft-trade-toast.component.scss'],
})
export class NftTradeToastComponent extends BaseCustomToast<NFTToastData> implements OnDestroy {
  private destroy$ = new Subject<void>();
  readonly iconProfile = iconProfile;
  nftBuyEvent?: NftTradeEvents;
  type?: UserTradeMessageType;

  constructor(private modalRouter: ModalRouter, private streetsService: StreetsService) {
    super();
  }

  onCustomToastInit(extras?: ToastExtras<NFTToastData>): void {
    this.nftBuyEvent = extras?.data.params;
    this.type = extras?.data.type;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('click')
  openPreview(): void {
    this.streetsService
      .findByNFTMarket({ nftId: Number(this.nftBuyEvent?.nftId) })
      .pipe(takeUntil(this.destroy$))
      .subscribe(street => {
        this.modalRouter.navigate(lazyModalsNav.streets.streetSell(), undefined, {
          data: {
            entity: street,
            isMarket: this.type === 'USER_WANT_BUY_NFT' ? true : false,
          } as StreetPreviewModal,
        });
      });
  }
}
