import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
} from '@angular/core';
import { FormControlDirective, FormGroupDirective } from '@angular/forms';
import { AppIconsLibrary } from 'core/icons/icons-library.service';
import { iconChevronDown } from 'core/icons/lib/icon-chevron-down';
import { iconClose } from 'core/icons/lib/icon-close';
import { iconEye } from 'core/icons/lib/icon-eye';
import { iconEyeSlash } from 'core/icons/lib/icon-eye-slash';
import { iconSearch } from 'core/icons/lib/icon-search';
import { asyncScheduler, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppIconName } from '../../icons/icon.type';
import { AnchorDirective } from '../dropdown/anchor.directive';
import { InputComponent } from './input.component';

// extras presets
export type ExtrasType = 'search' | 'password' | 'clear' | 'dropdown';

type ExtrasButtonData = { action: ExtrasType; icon: AppIconName };

@Component({
  selector: 'app-input-extras',
  template: `
    <app-icon
      *ngFor="let b of buttons"
      class="extra"
      [ngClass]="{ hidden: b.action === 'clear' && !filled && type === 'search' }"
      [icon]="b.icon"
      (mousedown)="handleExtrasClick(b); $event.preventDefault()"></app-icon>
  `,
  styleUrls: ['./styles/input-extras.component.scss'],
})
export class InputExtrasComponent implements OnChanges, OnInit, OnDestroy {
  @Input()
  type?: ExtrasType;

  buttons: ExtrasButtonData[] = [];
  filled?: boolean;

  // TODO: get from css
  private buttonWidth = 36;
  private destroy$ = new Subject();

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private iconLib: AppIconsLibrary,
    @Optional() private input?: InputComponent,
    @Optional() private anchor?: AnchorDirective,
    @Optional() private controlDirective?: FormControlDirective,
    @Optional() private formGroup?: FormGroupDirective
  ) {
    this.iconLib.addIcons([iconSearch, iconClose, iconEye, iconChevronDown, iconEyeSlash]);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.input) {
      this.renderer.setStyle(this.input.inputRef.nativeElement, 'padding-right', '');
    }
  }

  ngOnInit(): void {
    // add transitions later to prevent animation of inputs that disabled immediately
    asyncScheduler.schedule(() => {
      this.renderer.addClass(this.elementRef.nativeElement, 'add-transition');
    });
  }

  ngOnChanges(): void {
    this.buttons = [];
    if (this.type === 'clear') {
      this.buttons.push({ action: this.type, icon: 'close' });
    } else if (this.type === 'search') {
      this.buttons.push({ action: this.type, icon: 'search' });
      this.buttons.push({ action: 'clear', icon: 'close' });
      if (this.controlDirective && this.input) {
        this.controlDirective.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(v => {
          this.applyInputValue(v as string);
        });
        this.applyInputValue(this.controlDirective.value as string);
      }
    } else if (this.type === 'password') {
      this.buttons.push({ action: this.type, icon: 'eye' });
    } else if (this.type === 'dropdown') {
      this.buttons.push({ action: this.type, icon: 'chevron-down' });
    }
    this.updatePadding();
  }

  private updatePadding(): void {
    if (!this.input) {
      return;
    }
    let width = this.buttonWidth * this.buttons.length;
    if (!this.filled && this.type === 'search') {
      width -= this.buttonWidth;
    }
    this.renderer.setStyle(this.input.inputRef.nativeElement, 'padding-right', `${width}px`);
  }

  private applyInputValue(v: string): void {
    if (v) {
      this.filled = true;
    } else {
      this.filled = false;
    }
    this.updatePadding();
  }

  handleExtrasClick(btn: ExtrasButtonData): void {
    if (!this.input) {
      return;
    }
    if (btn.action === 'dropdown') {
      asyncScheduler.schedule(() => {
        this.anchor?.element.focus();
      });
    } else if (btn.action === 'clear' && this.controlDirective) {
      this.controlDirective.control.setValue('');
    } else if (btn.action === 'search' && this.formGroup) {
      this.formGroup.onSubmit(this.formGroup.value as Event);
    } else if (btn.action === 'password') {
      if (this.input.type === 'password') {
        this.input.type = 'text';
        // TODO: restore current position on type change
        btn.icon = 'eye-slash';
      } else {
        this.input.type = 'password';
        btn.icon = 'eye';
      }
    }
  }
}
