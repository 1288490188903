/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { MarketTOResponse } from '../models/market-toresponse';
import { MarketTOListResponse } from '../models/market-tolist-response';
import { MarketSearchForm } from '../models/market-search-form';
import { TradeVolumeByMarketTO } from '../models/trade-volume-by-market-to';

/**
 * Market Controller
 */
@Injectable({
  providedIn: 'root',
})
class MarketControllerService extends __BaseService {
  static readonly findMarketUsingGETPath = '/api/v1/market';
  static readonly getMarketsUsingGETPath = '/api/v1/market/list';
  static readonly listUsingPOSTPath = '/api/v1/market/list';
  static readonly getTrendingMarketsUsingGETPath = '/api/v1/market/trending-list';
  static readonly statsUsingGETPath = '/api/v1/market/volume-stats';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * findMarket
   * @param params The `MarketControllerService.FindMarketUsingGETParams` containing the following parameters:
   *
   * - `streetId`: streetId
   *
   * - `nftId`: nftId
   *
   * - `buildingId`: buildingId
   *
   * @return OK
   */
  findMarketUsingGETResponse(params: MarketControllerService.FindMarketUsingGETParams): __Observable<__StrictHttpResponse<MarketTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.streetId != null) __params = __params.set('streetId', params.streetId.toString());
    if (params.nftId != null) __params = __params.set('nftId', params.nftId.toString());
    if (params.buildingId != null) __params = __params.set('buildingId', params.buildingId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/market`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MarketTOResponse>;
      })
    );
  }
  /**
   * findMarket
   * @param params The `MarketControllerService.FindMarketUsingGETParams` containing the following parameters:
   *
   * - `streetId`: streetId
   *
   * - `nftId`: nftId
   *
   * - `buildingId`: buildingId
   *
   * @return OK
   */
  findMarketUsingGET(params: MarketControllerService.FindMarketUsingGETParams): __Observable<MarketTOResponse> {
    return this.findMarketUsingGETResponse(params).pipe(
      __map(_r => _r.body as MarketTOResponse)
    );
  }

  /**
   * getMarkets
   * @param params The `MarketControllerService.GetMarketsUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `nftIds`:
   *
   * - `isNotNft`:
   *
   * - `isNft`:
   *
   * @return OK
   */
  getMarketsUsingGETResponse(params: MarketControllerService.GetMarketsUsingGETParams): __Observable<__StrictHttpResponse<MarketTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    (params.nftIds || []).forEach(val => {if (val != null) __params = __params.append('nftIds', val.toString())});
    if (params.isNotNft != null) __params = __params.set('isNotNft', params.isNotNft.toString());
    if (params.isNft != null) __params = __params.set('isNft', params.isNft.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/market/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MarketTOListResponse>;
      })
    );
  }
  /**
   * getMarkets
   * @param params The `MarketControllerService.GetMarketsUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `nftIds`:
   *
   * - `isNotNft`:
   *
   * - `isNft`:
   *
   * @return OK
   */
  getMarketsUsingGET(params: MarketControllerService.GetMarketsUsingGETParams): __Observable<MarketTOListResponse> {
    return this.getMarketsUsingGETResponse(params).pipe(
      __map(_r => _r.body as MarketTOListResponse)
    );
  }

  /**
   * list
   * @param form form
   * @return OK
   */
  listUsingPOSTResponse(form: MarketSearchForm): __Observable<__StrictHttpResponse<MarketTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/market/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MarketTOListResponse>;
      })
    );
  }
  /**
   * list
   * @param form form
   * @return OK
   */
  listUsingPOST(form: MarketSearchForm): __Observable<MarketTOListResponse> {
    return this.listUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as MarketTOListResponse)
    );
  }

  /**
   * getTrendingMarkets
   * @return OK
   */
  getTrendingMarketsUsingGETResponse(): __Observable<__StrictHttpResponse<MarketTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/market/trending-list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MarketTOListResponse>;
      })
    );
  }
  /**
   * getTrendingMarkets
   * @return OK
   */
  getTrendingMarketsUsingGET(): __Observable<MarketTOListResponse> {
    return this.getTrendingMarketsUsingGETResponse().pipe(
      __map(_r => _r.body as MarketTOListResponse)
    );
  }

  /**
   * stats
   * @param params The `MarketControllerService.StatsUsingGETParams` containing the following parameters:
   *
   * - `uuid`: uuid
   *
   * - `period`: period
   *
   * - `marketId`: marketId
   *
   * - `dateTo`: dateTo
   *
   * - `dateFrom`: dateFrom
   *
   * @return OK
   */
  statsUsingGETResponse(params: MarketControllerService.StatsUsingGETParams): __Observable<__StrictHttpResponse<TradeVolumeByMarketTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.uuid != null) __params = __params.set('uuid', params.uuid.toString());
    if (params.period != null) __params = __params.set('period', params.period.toString());
    if (params.marketId != null) __params = __params.set('marketId', params.marketId.toString());
    if (params.dateTo != null) __params = __params.set('dateTo', params.dateTo.toString());
    if (params.dateFrom != null) __params = __params.set('dateFrom', params.dateFrom.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/market/volume-stats`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TradeVolumeByMarketTO>;
      })
    );
  }
  /**
   * stats
   * @param params The `MarketControllerService.StatsUsingGETParams` containing the following parameters:
   *
   * - `uuid`: uuid
   *
   * - `period`: period
   *
   * - `marketId`: marketId
   *
   * - `dateTo`: dateTo
   *
   * - `dateFrom`: dateFrom
   *
   * @return OK
   */
  statsUsingGET(params: MarketControllerService.StatsUsingGETParams): __Observable<TradeVolumeByMarketTO> {
    return this.statsUsingGETResponse(params).pipe(
      __map(_r => _r.body as TradeVolumeByMarketTO)
    );
  }
}

module MarketControllerService {

  /**
   * Parameters for findMarketUsingGET
   */
  export interface FindMarketUsingGETParams {

    /**
     * streetId
     */
    streetId?: number;

    /**
     * nftId
     */
    nftId?: number;

    /**
     * buildingId
     */
    buildingId?: number;
  }

  /**
   * Parameters for getMarketsUsingGET
   */
  export interface GetMarketsUsingGETParams {
    sort?: string;
    pageSize?: number;
    pageNumber?: number;
    nftIds?: Array<number>;
    isNotNft?: boolean;
    isNft?: boolean;
  }

  /**
   * Parameters for statsUsingGET
   */
  export interface StatsUsingGETParams {

    /**
     * uuid
     */
    uuid?: string;

    /**
     * period
     */
    period?: 'CUSTOM' | 'DAY' | 'HOUR' | 'MONTH' | 'UNKNOWN' | 'UNRECOGNIZED' | 'WEEK' | 'YEAR';

    /**
     * marketId
     */
    marketId?: number;

    /**
     * dateTo
     */
    dateTo?: string;

    /**
     * dateFrom
     */
    dateFrom?: string;
  }
}

export { MarketControllerService }
