import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, FormGroupDirective } from '@angular/forms';
import { Subscription } from 'rxjs';

import { APIError } from '../../models/error.model';
import { APIErrorHandler, APIErrorsHandler } from '../../services/api-errors.service';

// goal of this directive to set errors received from API to form controls by property name
// also it will set force-errors class to <form> on submit and control elements may show errors
// on fields even if control is untouched
@Directive({
  selector: '[appAPIValidate]',
})
export class APIValidateDirective implements OnInit, OnDestroy, APIErrorHandler {
  @Input()
  key?: string;

  private form!: FormGroup;
  private subs: Subscription[] = [];

  constructor(
    private errorsService: APIErrorsHandler,
    private formGroupDirective: FormGroupDirective
  ) {
    errorsService.insertErrorHandler(this);
  }

  ngOnDestroy(): void {
    this.errorsService.removeErrorHandler(this);
    this.subs.forEach(s => s.unsubscribe());
  }

  ngOnInit(): void {
    this.form = this.formGroupDirective.form;
  }

  handleError(apiError: APIError): boolean {
    let isHandled = false;
    apiError.properties.forEach(property => {
      if (property && property.field) {
        const props = property.field.split(/[[|\]|.]/g).filter(String);
        let control: AbstractControl | null = this.form.root;
        while (props.length) {
          const prop = props.shift();
          const index = Number.parseInt(prop || '-1', 10);
          // prop is index of array
          if (index >= 0 && control && control instanceof FormArray) {
            const arrayControl: FormArray = control;
            control = arrayControl.at(index);
          } else if (control && prop) {
            control = control.get(prop);
          }
        }
        if (control) {
          const errors = control.errors || {};
          errors['API'] =
            typeof property.details === 'string'
              ? property.details
              : $t(`shared.API_ERRORS.${apiError.code}`);
          control.setErrors(errors);
          isHandled = true;
        }
      }
    });
    return isHandled;
  }
}
