<div class="overlay"
     *ngIf="isOpened"
     (click)="hideCard()"
     [@fixedCardOverlayTransition]></div>
<div class="container"
     [@fixedCardFade]="isOpened ? 'open' : 'close'"
     (@fixedCardFade.done)="onEnd($event)">
     <ng-container #containerRef></ng-container>

     <app-icon class="close-icon"
               [icon]="closeIcon"
               (click)="hideCard()"></app-icon>
</div>
