import { GenericFormControl, GenericFormGroup } from 'core/utils/form-generics';

export interface NftFilterPriceForm {
  fromPrice?: number;
  toPrice?: number;
}

export class NftPriceFormGroup extends GenericFormGroup<NftFilterPriceForm> {
  constructor() {
    super({
      fromPrice: new GenericFormControl(undefined),
      toPrice: new GenericFormControl(undefined),
    });
  }
}
