import { NgModule } from '@angular/core';

import { CountdownDirective } from './countdown.directive';
import { DownloadDirective } from './download.directive';
import { InfiniteScrollDirective } from './infinite-scroll.directive';
import { LazyLoadingDirective } from './lazy-loading.directive';
import { RouterBackDirective } from './router-back.directive';
import { SectionAutoScrollDirective } from './section-auto-scroll.directive';
import { SectionFadeInDirective } from './section-fade-in.directive';
import { TabItemDirective } from './tab-item.directive';
import { TabsContainerDirective } from './tabs-container.directive';
import { VirtualForDirective } from './virtual-for.directive';

const exportedDeclarations = [
  CountdownDirective,
  DownloadDirective,
  InfiniteScrollDirective,
  LazyLoadingDirective,
  RouterBackDirective,
  VirtualForDirective,
  SectionAutoScrollDirective,
  SectionFadeInDirective,
  TabItemDirective,
  TabsContainerDirective,
];

@NgModule({
  imports: [],
  declarations: exportedDeclarations,
  exports: exportedDeclarations,
})
export class CoreDirectivesModule {}
