import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HistoryTO, PageTO } from 'api/models';
import { appNav, lazyModalsNav } from 'app/app.navigation';
import { WishlistStreetsService } from 'app/marketplace/services/wishlist-streets.service';
import { AuthService } from 'auth/services/auth.service';
import { iconBagCross } from 'core/icons/lib/icon-bag-cross';
import { iconCart } from 'core/icons/lib/icon-cart';
import { iconChevronLeft } from 'core/icons/lib/icon-chevron-left';
import { iconStar } from 'core/icons/lib/icon-start';
import { APIError } from 'core/models/error.model';
import { ModalRouter } from 'core/modules/modal';
import { SeoService } from 'core/seo/seo.service';
import { delayAtLeast } from 'core/utils/rxjs/delay-at-least.operator';
import { StreetPreviewModal } from 'dashboard/dashboard.navigation';
import { Street } from 'dashboard/models/street.model';
import { CartService } from 'dashboard/services/cart.service';
import { StreetsService } from 'dashboard/services/streets.service';
import { environment } from 'environments/environment';
import { UserService } from 'profile/services/user.service';
import { of, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { StreetDetailsService } from 'streets/services/street-details.service';
import {GTMService} from "core/services/gtm.service";
import {CurrencyService} from "core/services/currency.service";
import {FbService} from "core/services/fb.service";

@Component({
  selector: 'app-marketplace-street-overview',
  templateUrl: './marketplace-street-overview.component.html',
  styleUrls: ['./marketplace-street-overview.component.scss'],
  providers: [StreetDetailsService],
})
export class MarketplaceStreetOverviewComponent implements OnInit, OnDestroy {
  readonly chevronIcon = iconChevronLeft;
  readonly iconStar = iconStar;
  readonly iconCart = iconCart;
  readonly iconBagCross = iconBagCross;

  street = new Street();
  streetHistory: HistoryTO[] = [];
  pagination: PageTO = {};
  isError = false;
  isLoading = false;
  userBuyPrice? = 0;

  private destroy$ = new Subject<void>();

  constructor(
    private streetService: StreetsService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private wishlistService: WishlistStreetsService,
    private streetDetailsService: StreetDetailsService,
    private cartService: CartService,
    private seo: SeoService,
    private modal: ModalRouter,
    private auth: AuthService,
    private GTMService: GTMService,
    private FbService: FbService,
    private currencyService: CurrencyService,
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.activatedRoute.params
      .pipe(
        switchMap(params =>
          this.streetService.findStreetOrMarket(params.streetId).pipe(
            switchMap(street => {
              this.loadStreetHistory(street?.nft?.id);
              this.seo.set({
                title: `${street?.marketId ? 'Buy' : 'Mint'} ${street?.name} in ${street?.city}, ${street?.country} - Virtual Real Estate NFTs at DecentWorld`,
                description: `${street?.marketId ? 'Buy' : 'Mint'} ${street?.name} in ${street?.city}, ${street?.country}. Explore virtual real estate NFTs at DecentWorld metaverse`,
                image: `${environment.mainServiceUrl}/api/v1/gis/${street?.id}/image/STREET/view?width=1200&height=630&colored=true`,
                imageSize: ['1200px', '630px'],
                canonicalPath: appNav.marketplaceNav.streetPreview(
                  street?.id || 0,
                  street?.country || '',
                  street?.name || ''
                ),
              });
              return street?.marketId && this.auth.isAuth
                ? this.streetService.getStreetOrder(street).pipe(
                    map(order => {
                      this.userBuyPrice = order?.price;

                      return street;
                    })
                  )
                : of(street);
            })
          )
        ),
        delayAtLeast(1000),
        map(street => {
          this.street = street || new Street();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(
        () => (this.isLoading = false),
        (error: APIError) => {
          this.isLoading = false;
          if (error.code === 'STREET_NOT_FOUND') {
            error.preventHandling();
          }
          this.isError = true;
        }
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadStreetHistory(nftId?: number): void {
    this.streetDetailsService
      .getHistory(nftId || 0)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.streetHistory = data.elements?.[0]?.elements || [];
        this.pagination = data.pageInfo || {};

        if (this.pagination.pageNumber) {
          this.pagination.pageNumber++;
        }
      });
  }

  toggleFavorite(): void {
    if (!this.userService.user.id) {
      this.modal.navigate(lazyModalsNav.auth.signIn());
      return;
    }
    if (!this.street.isFavorite) {
      this.GTMService.pushTag({
        'event': 'add_to_wishlist',
        'user_id': this.userService.user.id,
        'ecommerce': {
          items: [{
            'item_name': this.street.name, // street name
            'item_id': this.street.id, // street ID
            'item_country': this.street.country, // street country
            'item_city':this.street.city, // city
            'item_level': this.street.level, // street level
            'item_token': this.street.marketPrice, // street value in DWRLD tokens
            'quantity': '1', // amount of items
            'price': this.currencyService.toDwrldRate(this.street.marketPrice, 'USD') // item worth amount in fiat
          }]
        }
      });
    }
    this.wishlistService.toggleFavorite(this.street);
  }

  buyStreet(): void {
    if (!this.userService.user.id) {
      this.modal.navigate(lazyModalsNav.auth.signIn());
      return;
    }
    this.modal.navigate(
      lazyModalsNav.streets.streetBuy(),
      (data) => {
        const resultCallback = data as { buy: boolean, cancel: boolean, price: number };
        if (resultCallback.buy) {
          this.userBuyPrice = resultCallback.price;
        }

        if (resultCallback.cancel) {
          this.userBuyPrice = undefined;
        }
      },
      {
        data: {
          entity: this.street,
        } as StreetPreviewModal,
      }
    );

    if (!this.street.isOwner && !this.userBuyPrice && (this.street.sellPrice || !this.street.marketId)) {
      this.sendMetricTags();
    }
  }

  private sendMetricTags(): void {
    const config = {
      'event': 'select_item',
      'user_id': this.userService.user.id,
      'ecommerce': {
        items: [{
          'item_name': this.street.name, // street name
          'item_id': this.street.id, // street ID
          'item_country': this.street.country, // street country
          'item_city': this.street.city, // city
          'item_level': this.street.level, // street level
          'item_token': this.street.lastPrice, // street value in DWRLD tokens
          'quantity': '1', // amount of items
          'price': this.currencyService.toDwrldRate(this.street.lastPrice, 'USD')// item worth amount in fiat
        }]
      }
    };
    this.FbService.pushTag(config);
    this.GTMService.pushTag(config);
  }

  sellStreet(): void {
    if (!this.userService.user.id) {
      this.modal.navigate(lazyModalsNav.auth.signIn());
      return;
    }
    this.modal.navigate(lazyModalsNav.streets.streetSell(), undefined, {
      data: {
        entity: this.street,
        isMarket: this.street.sellPrice ? true : false,
      } as StreetPreviewModal,
    });
  }

  onPaginationChange(pagination: PageTO): void {
    this.pagination = { ...pagination };
    this.streetDetailsService.setPaginationParams(pagination);
  }

  removeFromBasket(event: MouseEvent): void {
    event.stopPropagation();
    if (!this.userService.user.id) {
      this.modal.navigate(lazyModalsNav.auth.signIn());
      return;
    }
    this.cartService.remove(this.street).pipe(takeUntil(this.destroy$)).subscribe();
  }

  addToBasket(event: MouseEvent): void {
    event.stopPropagation();
    if (!this.userService.user.id) {
      this.modal.navigate(lazyModalsNav.auth.signIn());
      return;
    }
    this.cartService.add(this.street).pipe(takeUntil(this.destroy$)).subscribe();
  }
}
