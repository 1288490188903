import { GisControllerService } from 'api/services';
import { UIItem } from 'core/models/ui-item';
import { GenericFormControl, GenericFormGroup } from 'core/utils/form-generics';
import { NftFilterPlanForm } from 'dashboard/forms/nft-plan-filter.form';
import { NftFilterPriceForm } from 'dashboard/forms/nft-price-filter.form';

export type MarketplaceMarketType = UIItem<
  Exclude<
    'ALL_MARKETS' | 'PRIMARY' | 'SECONDARY',
    'UNRECOGNIZED'
  >
>;

export const marketplaceMarketTypes: MarketplaceMarketType[] = [
  new UIItem($t('marketplace.streets-form.type.all'), 'ALL_MARKETS'),
  new UIItem($t('marketplace.streets-form.type.primary'), 'PRIMARY'),
  new UIItem($t('marketplace.streets-form.type.secondary'), 'SECONDARY'),
];

export interface MarketplaceStreetsFilters {
  name?: string;
  nameStart?: string;
  price?: NftFilterPriceForm;
  zones?: NftFilterPlanForm;
  nftMarketType?: MarketplaceMarketType;
  countryCode?: string;
  cityId?: number;
  hasMatching?: boolean;
}

export class MarketplaceStreetsFormGroup extends GenericFormGroup<
  Omit<MarketplaceStreetsFilters, 'countryCode' | 'cityId' | 'nameStart'>
> {
  constructor() {
    super({
      name: new GenericFormControl(),
      nftMarketType: new GenericFormControl<MarketplaceMarketType>(marketplaceMarketTypes[0]),
      price: new GenericFormControl<NftFilterPriceForm>(),
      zones: new GenericFormControl<NftFilterPlanForm>(),
      hasMatching: new GenericFormControl<boolean>(),
    });
  }
}
