import { Component, Input } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
})
export class UserAvatarComponent {
  @Input() set uuid(id: string | undefined) {
    this.logoUrl += id || '';
  }

  @Input()
  logoUrl: string | SafeUrl = `${environment.mainServiceUrl}/api/v1/user/logo/public?uuid=`;
}
