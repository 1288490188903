<app-street-thumb
  [imageUrl]="order.street.imageUrl"
  [isAvailable]="order.street.isAvailable"
  [level]="order.street.level"
  [mediaClass]="['large', 'small-thumb xsmall']"
  [title]="order.street.name"
  class="rounded column markets-asset"
  style="grid-area: asset">
  <ng-container *mediaIf="'medium down'">
    <ng-container>{{ order.street.secondaryName }}</ng-container>
  </ng-container>
</app-street-thumb>

<div *mediaIf="'large'" class="column markets-city">{{ order.street.city }}</div>
<div *mediaIf="'large'" class="column markets-country">{{ order.street.country }}</div>

<app-level-icon
  [level]="order.street.level"
  [price]="-1"
  class="column markets-level sm"
  style="grid-area: level"></app-level-icon>
<div class="column markets-bid" style="grid-area: your-bid">
  <div *mediaIf="'large down'" class="title">
    {{ 'marketplace.ui.buying_item.your-bid' | translate }}
  </div>
  {{ order.price | money: 'DWRLD':'—' }}
</div>
<div class="column markets-max-bid" style="grid-area: max-bid">
  <div *mediaIf="'large down'" class="title">
    {{ 'marketplace.ui.buying_item.max-bid' | translate }}
  </div>
  {{ order.street.buyPrice | money: 'DWRLD':'—' }}
</div>
<div class="column markets-current-price" style="grid-area: price">
  <div *mediaIf="'large down'" class="title">
    {{ 'marketplace.ui.buying_item.current-price' | translate }}
  </div>
  {{ order.street.sellPrice | money: 'DWRLD':'—' }}
</div>

<app-menu [align]="'center'" [minWidth]="180" class="markets-buttons" style="grid-area: menu">
  <button
    (click)="$event.stopPropagation()"
    [disabled]="userService.user.accountId !== order.accountId"
    appAnchor
    appButton
    class="clear-primary">
    <app-icon [icon]="ellipsisIcon" class="primary xhg"></app-icon>
  </button>
  <ng-template>
    <div class="data-host">
      <button
        (click)="onCancelOrder()"
        [appButton]="closeIcon"
        appMenuItem
        class="solid for-select">
        {{ 'dashboard.ui.buying_item.cancel' | translate }}
      </button>
    </div>
  </ng-template>
</app-menu>
