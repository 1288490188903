import { Component, Input } from '@angular/core';
import { HistoryTO } from 'api/models';
import { iconAddSquare } from 'core/icons/lib/icon-add-square';
import { iconCopy } from 'core/icons/lib/icon-copy';
import { iconDeposit } from 'core/icons/lib/icon-deposit';
import { iconProfile } from 'core/icons/lib/icon-profile';
import { IconSell } from 'core/icons/lib/icon-sell';
import { iconWithdrawal } from 'core/icons/lib/icon-withdrawal';
import { iconTrade } from 'core/icons/lib/v2/icon-trade';

export type EventType = 'INTERNAL_TRANSFER_NFT' | 'MINTED' | 'SELL' | 'DEPOSIT' | 'WITHDRAW';

@Component({
  selector: 'app-street-history-list-item',
  templateUrl: './street-history-list-item.component.html',
  styleUrls: ['./street-history-list-item.component.scss'],
})
export class StreetHistoryListItemComponent {
  readonly iconMinted = iconAddSquare;
  readonly iconWithdrawal = iconWithdrawal;
  readonly iconTrade = iconTrade;
  readonly iconSell = IconSell;
  readonly iconDeposit = iconDeposit;
  readonly iconCopy = iconCopy;
  readonly iconProfile = iconProfile;

  @Input() historyItem: HistoryTO = {};
}
