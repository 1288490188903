import { Injectable } from '@angular/core';
import { CurrencyConvertTOListResponse } from 'api/models';
import { CurrencyConvertorControllerService, MarketControllerService } from 'api/services';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { mapMarketsListToMap, MarketExchanged } from '../models/market-statistic';
import { MarketsSocketsService } from './markets-sockets.service';
import { StorageMarketsService } from './storage-markets.service';

@Injectable({
  providedIn: 'root',
})
export class ExchangeMarketsService {
  marketsMap: Record<number, MarketExchanged> = {};

  currentMarketId$: BehaviorSubject<number> = new BehaviorSubject<number>(
    MarketsSocketsService.defaultMarket.id || 0
  );

  get currentMarket(): MarketExchanged | undefined {
    return this.getMarketById(this.currentMarketId$.getValue());
  }

  get markets(): MarketExchanged[] {
    return Object.values(this.marketsMap);
  }

  constructor(
    private storageMarketsService: StorageMarketsService,
    private marketApi: MarketControllerService,
    private currencyConvertorService: CurrencyConvertorControllerService
  ) {}

  getMarketById(marketId: number): MarketExchanged | undefined {
    return this.marketsMap[marketId];
  }

  updateMarket(market: MarketExchanged): void {
    if (market.id) {
      this.marketsMap[market.id] = market;
    }
  }

  updateFavorites(): void {
    const favoriteMarkets = this.storageMarketsService.getFavoriteList() || [];
    const updatedMarkets = this.markets.map(market => {
      if (favoriteMarkets.includes(market.id || 0)) {
        market.isFavorite = true;
      }
      return market;
    });

    this.marketsMap = mapMarketsListToMap(updatedMarkets);
  }

  initAllMarkets(marketTitle: string): Observable<MarketExchanged> {
    if (Object.keys(this.marketsMap).length) {
      const market = this.markets.find(m => m.title === marketTitle);
      return of(market || MarketsSocketsService.defaultMarket);
    } else {
      const yesterday = this.getYesterdayDate();
      return forkJoin([
        this.marketApi.listUsingPOST({}),
        this.currencyConvertorService.listUsingGET8(),
        this.currencyConvertorService.historyForAllCurrencyUsingGET(yesterday),
      ]).pipe(
        map(([markets, currentPrices, historyPrices]) => {
          const marketsList = (markets.list || []) as MarketExchanged[];
          const eventPrices = this.getEventPrices(marketsList, currentPrices);
          const favoriteMarkets = this.storageMarketsService.getFavoriteList() || [];
          marketsList.forEach(market => {
            if (favoriteMarkets.includes(market.id || 0)) {
              market.isFavorite = true;
            }
            const defaultPrice = eventPrices.find(x => x.marketId === market.id);
            const historyPrice = historyPrices.elements?.find(
              x =>
                x.curFrom?.toLowerCase() === market.leftCurrencyCode?.toLowerCase() &&
                x.curTo?.toLowerCase() === market.rightCurencyCode?.toLowerCase()
            );
            let percent = 0;
            if (defaultPrice && historyPrice && Object.keys(historyPrice).length !== 0) {
              percent = +((defaultPrice.price / +(historyPrice.rate || 1) - 1) * 100).toFixed(2);
            }
            market.changeValue = (defaultPrice?.price || 0) - +(historyPrice?.rate || 0);
            market.price = defaultPrice?.price;
            market.changePercent = percent;
          });

          return marketsList;
        }),
        tap(marketsList => {
          this.marketsMap = mapMarketsListToMap(marketsList);
        }),
        map(
          marketsList =>
            marketsList.find(market => market.title === marketTitle) ||
            MarketsSocketsService.defaultMarket
        )
      );
    }
  }

  private getYesterdayDate(): string {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);
    const yesterday = `${currentDate.getFullYear()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getDate()}`;

    return yesterday;
  }

  private getEventPrices(
    marketsList: MarketExchanged[] = [],
    currentPrices: CurrencyConvertTOListResponse
  ): { marketId: number; price: number }[] {
    return marketsList.map(market => {
      const currentPrice = currentPrices.list?.find(
        x => x.fromCurrencyId === market.leftCurrencyId && x.toCurrencyId === market.rightCurrencyId
      );
      return {
        marketId: market.id || 0,
        price: currentPrice?.converted || 0,
      };
    });
  }
}
