<div *ngIf="showCookiesBanner === null || showCookiesBanner === 'null'" class="container">
  <div class="wrapper">
    <div>
      {{ 'core.cookies.description-1' | translate }}
      <a [routerLink]="[url]" class="link" target="_blank">
        {{ 'core.cookies.cookies-policy' | translate }}
      </a>
      {{ 'core.cookies.description-2' | translate }}
    </div>
    <div class="agreement-btn">
      <button (click)="acceptAction()" appButton class="solid primary medium" type="submit">
        {{ 'core.cookies.button' | translate }}
      </button>
    </div>
  </div>
</div>
