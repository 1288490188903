import {
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { asyncScheduler, fromEvent, Subject } from 'rxjs';
import { debounceTime, filter, first, takeUntil } from 'rxjs/operators';

import { AppIcon, AppIconName } from '../../icons/icon.type';
import { ExtrasType } from './input-extras.component';

@Component({
  selector: 'app-input',
  template: `
    <ng-content select="app-select"></ng-content>
    <app-icon *ngIf="icon" [icon]="icon"></app-icon>
    <div class="container">
      <span class="title">{{ title }}</span>
      <input
        class="inp"
        type="{{ type }}"
        [attr.tabindex]="tabindex"
        [attr.autocomplete]="autocomplete"
        [attr.placeholder]="placeholder"
        [attr.name]="name"
        [attr.disabled]="readOnly ? 'disabled' : null"
        [attr.inputmode]="inputmode"
        [autofocus]="autofocus"
        #input />
    </div>
    <ng-content></ng-content>
    <app-input-extras *ngIf="extras" class="extras" [type]="extras"></app-input-extras>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
  styleUrls: ['./styles/solid.scss', './styles/solid-validation.scss'],
})
export class InputComponent implements ControlValueAccessor, OnInit, OnDestroy, OnChanges {
  @ViewChild('input', { static: true })
  inputRef!: ElementRef<HTMLElement>;

  @Input()
  autofocus = false;

  @Input()
  icon: AppIconName | AppIcon = '';

  @Input() applyChangesOnActive = false;

  @Input()
  @HostBinding('attr.type')
  type = 'text';

  @Input()
  placeholder = ' ';

  @Input()
  @HostBinding('class.with-title')
  title = '';

  @Input()
  notInteractive?: boolean;

  @Input()
  tabindex = 0;

  @Input()
  autocomplete = '';

  @Input()
  name: string | null = null;

  @Input()
  readOnly = false;

  @Input()
  inputmode: string | null = null;

  @Input()
  debounce: number | null = null;

  @Input()
  extras?: ExtrasType;
  protected active = false;
  protected destroy$ = new Subject();

  protected onControlTouched = (): null => null;
  protected onControlChanged = (_: unknown): null => null;
  constructor(public elementRef: ElementRef, protected renderer: Renderer2) {}

  get focused(): boolean {
    return this.active;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges<InputComponent>): void {
    if (changes.notInteractive) {
      this.setDisabledState(this.notInteractive);
    }

    if (changes.autofocus && changes.autofocus.currentValue === true) {
      this.inputRef.nativeElement.focus();
    }
  }

  ngOnInit(): void {
    // remove transitions to prevent animation of inputs that disabled immediately
    this.renderer.addClass(this.elementRef.nativeElement, 'no-transitions');
    asyncScheduler.schedule(() => {
      this.renderer.removeClass(this.elementRef.nativeElement, 'no-transitions');
    });
    // check focus with delay to prevent synthetic focus events (such autofill)
    fromEvent(this.inputRef.nativeElement, 'focus')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => asyncScheduler.schedule(() => (this.active = true), 150));
    fromEvent<InputEvent>(this.inputRef.nativeElement, 'input')
      .pipe(debounceTime(this.debounce || 0), takeUntil(this.destroy$))
      .subscribe(e => this.onInput(e));
    fromEvent(this.inputRef.nativeElement, 'blur')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.onBlur());
    fromEvent<AnimationEvent>(this.inputRef.nativeElement, 'animationstart')
      .pipe(
        filter(e => e.animationName === 'empty'),
        first()
      )
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(() => this.setFilledState(true));
  }

  focus(): void {
    this.inputRef.nativeElement.focus();
  }

  clear(): void {
    this.setInputValue('');
    this.onControlChanged('');
  }

  writeValue(value?: string | number | boolean | Date): void {
    this.setInputValue(value?.toString());
  }

  registerOnChange(fn: (val: unknown) => null): void {
    this.onControlChanged = fn;
  }

  registerOnTouched(fn: () => null): void {
    this.onControlTouched = fn;
  }

  setDisabledState(isDisabled?: boolean): void {
    if (isDisabled) {
      this.renderer.setAttribute(this.elementRef.nativeElement, 'disabled', '');
      this.renderer.setAttribute(this.inputRef.nativeElement, 'disabled', '');
    } else {
      this.renderer.removeAttribute(this.elementRef.nativeElement, 'disabled');
      this.renderer.removeAttribute(this.inputRef.nativeElement, 'disabled');
    }
  }

  protected onInput(_: InputEvent): void {
    const value = this.getInputValue();
    this.onControlChanged(this.type === 'number' ? +value : value);
  }

  protected onBlur(): void {
    if (this.active) {
      this.active = false;
      this.onControlTouched();
    }
    this.setFilledState(!!this.getInputValue());
  }

  protected setFilledState(enabled: boolean): void {
    if (enabled) {
      this.renderer.addClass(this.elementRef.nativeElement, 'filled');
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, 'filled');
    }
  }

  protected getInputValue(): string {
    return (this.inputRef.nativeElement as HTMLInputElement).value;
  }

  protected setInputValue(v?: string): void {
    (this.inputRef.nativeElement as HTMLInputElement).value = v || '';
    this.setFilledState(!!this.getInputValue());
  }
}
