/* eslint-disable prefer-arrow-functions/prefer-arrow-functions */
import { createNavigation } from 'core/utils/router.utils';

export interface LegalParams {
  tab:
    | 'disclaimer'
    | 'terms_of_use'
    | 'privacy_policy'
    | 'privacy_policy_short'
    | 'supervision'
    | 'impressum'
    | 'cookie_statement'
    | 'aml_ctf_policy'
    | 'compliance_policy';
}

export const legalRoot = 'legal';

export function root(tab: LegalParams['tab']): string {
  return `${tab}`;
}

export const paths = {
  root,
};

export const legalNav = createNavigation(paths, legalRoot);
