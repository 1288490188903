import {
  AfterViewInit,
  ContentChildren,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  QueryList,
} from '@angular/core';
import { MediaQuery } from 'core/modules/platform/services/media-query.service';
import { asyncScheduler, merge, Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';

import { TabItemDirective } from './tab-item.directive';

@Directive({
  selector: '[appTabsContainer]',
})
export class TabsContainerDirective implements AfterViewInit, OnDestroy {
  @ContentChildren(TabItemDirective) tabItems!: QueryList<TabItemDirective>;
  @Input() activeClass!: string;
  @Input() mediaCondition?: string;

  private destroy$ = new Subject<void>();

  constructor(private elementRef: ElementRef<HTMLElement>, private media: MediaQuery) {}

  ngAfterViewInit(): void {
    if ((this.mediaCondition && this.media.is(this.mediaCondition)) || !this.mediaCondition) {
      this.tabItems.forEach(tabItem => tabItem.observe());
      const observeInitActiveTab = merge(
        ...this.tabItems.map(tabItem => tabItem.initActiveTab.asObservable().pipe(take(1)))
      );

      observeInitActiveTab
        .pipe(
          tap(() => {
            this.tabItems.forEach(tabItem => {
              tabItem.disconnect();
            });
          }),
          takeUntil(this.destroy$)
        )
        .subscribe(tabItem => {
          asyncScheduler.schedule(() => {
            tabItem.scrollIntoView();
          });
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
