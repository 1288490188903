import { animate, style, transition, trigger } from '@angular/animations';

export const toasterFade = trigger('toasterFade', [
  transition(':enter', [
    style({ transform: 'translateX(-450px)', opacity: 0.45 }),
    animate('150ms ease-in', style({ transform: 'translateX(0)', opacity: 1 })),
  ]),
  transition(':leave', [
    style({ transform: 'translateX(0)' }),
    animate('150ms ease-in', style({ transform: 'translateX(-450px)', opacity: 0.45 })),
  ]),
]);
