<app-street-thumb
  [imageUrl]="imageUrl"
  [level]="level"
  [mediaClass]="['large', 'small-thumb xsmall']"
  [title]="name"
  [isAvailable]="order.side === 'BUY'"
  [isSold]="order.side === 'SELL'"
  class="rounded column history-asset thumb-column"
  style="grid-area: asset">
  <ng-container>
    {{ order.city }} {{ order.country }}
  </ng-container>
</app-street-thumb>

<div *mediaIf="'large'" class="column history-type ellipsis">
  {{
    order.nftType === 'BUILDING'
      ? ('dashboard.ui.history_item.building_type' | translate)
      : order.orderType === 'MYSTERY_BOX'
        ? ('dashboard.ui.history_item.mystery_box_type' | translate)
        : ('dashboard.ui.history_item.street_type' | translate)
  }}
</div>

<app-level-icon
  [currency]="'DWRLD'"
  [level]="level"
  [price]="-1"
  class="column history-level sm"
  style="grid-area: level"></app-level-icon>

<div class="column history-order" style="grid-area: order">#{{ order.id }}</div>

<div
  [ngClass]="{
    'color-error': order.side === 'BUY',
    'color-success': order.side === 'SELL'
  }"
  class="column history-balance"
  style="grid-area: balance">
  {{ order.avgPrice | money: 'DWRLD':'—' }}
</div>

<div class="column history-date" style="grid-area: date">
  {{ order.closeAt | date: 'MMM d, y HH:mm:ss' }}
</div>

<div class="display-flex align-center column history-status" style="grid-area: status">
  <app-icon
    [icon]="circleIcon"
    [ngClass]="{
      'color-success': order.side === 'BUY',
      'color-error': order.side === 'SELL'
    }"
    class="font-tn margin-right"></app-icon>
  <ng-container *ngIf="order.side === 'BUY'; else sellTmpl">
    {{ 'dashboard.ui.history_item.bought' | translate }}
  </ng-container>
  <ng-template #sellTmpl>
    {{ 'dashboard.ui.history_item.sell' | translate }}
  </ng-template>
</div>
