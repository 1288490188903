import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';

import { IconModule } from '../icon/icon.module';
import { TooltipModule } from '../tooltip/tooltip.module';
import { BadgeComponent } from './badge.component';
import { ButtonComponent } from './button.component';
import { CheckboxComponent } from './checkbox.component';
import { ClearButtonComponent } from './clear-button.component';
import { CopyDirective } from './copy.directive';
import { RadioButtonComponent } from './radio-button.component';
import { RadioGroupComponent } from './radio-group.component';
import { RoundButtonComponent } from './round-button.component';
import { ToggleComponent } from './toggle.component';

const exportedDeclarations = [
  ButtonComponent,
  CheckboxComponent,
  ClearButtonComponent,
  RadioButtonComponent,
  RoundButtonComponent,
  ToggleComponent,
  RadioGroupComponent,
  BadgeComponent,
  CopyDirective,
];

@NgModule({
  imports: [IconModule, CommonModule, MatRippleModule, TooltipModule],
  declarations: exportedDeclarations,
  exports: exportedDeclarations,
})
export class ButtonModule {}
