import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core';
import { BasketPurchaseResultTOResponse } from 'api/models';
import { lazyModalsNav } from 'app/app.navigation';
import { iconBag } from 'core/icons/lib/icon-bag';
import { iconCheckCircle } from 'core/icons/lib/icon-check-circle';
import { iconCircleLine } from 'core/icons/lib/icon-circle-line';
import { iconError } from 'core/icons/lib/icon-error';
import { iconOval } from 'core/icons/lib/icon-oval';
import { ModalRouter } from 'core/modules/modal';
import { StreetPreviewModal } from 'dashboard/dashboard.navigation';
import { Building } from 'dashboard/models/building.model';
import { Street } from 'dashboard/models/street.model';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-checkout-item',
  templateUrl: './checkout-item.component.html',
  styleUrls: ['./checkout-item.component.scss'],
})
export class CheckoutItemComponent implements OnDestroy, OnChanges {
  circleIcon = iconCircleLine;
  deleteIcon = iconBag;
  spinIcon = iconOval;
  iconError = iconError;
  iconPending = iconOval;
  iconSuccess = iconCheckCircle;

  @Input() item: Street | Building = new Street();
  @Input() status?: BasketPurchaseResultTOResponse['status'] | 'PENDING';

  @Output() removeEmitter = new EventEmitter<void>();

  type?: 'BUILDING' | 'STREET';

  get isBuilding(): boolean {
    return this.type === 'BUILDING';
  }

  private destroy$ = new Subject<void>();

  constructor(private modal: ModalRouter) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges<CheckoutItemComponent>): void {
    if (changes.item) {
      this.type = this.item instanceof Street ? 'STREET' : 'BUILDING';
    }
  }

  removeFromCart(): void {
    this.removeEmitter.next();
  }

  @HostListener('click')
  previewItem(): void {
    this.modal.navigate(
      this.item instanceof Street
        ? lazyModalsNav.streets.streetPreview()
        : lazyModalsNav.streets.buildingPreview(),
      undefined,
      {
        data: { entity: this.item } as StreetPreviewModal,
      }
    );
  }
}
