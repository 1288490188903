<app-street-thumb
  [imageUrl]="building.imageUrl"
  [isAvailable]="building.isAvailable"
  [level]="building.level"
  [mediaClass]="['large', 'small-thumb xsmall']"
  [title]="building.mainName"
  class="rounded column street"
  style="grid-area: asset">
  <ng-container>{{ building.secondaryName }}</ng-container>
</app-street-thumb>

<app-level-icon
  [level]="building.level"
  [price]="-1"
  class="column level sm"
  style="grid-area: level"></app-level-icon>

<div [class.taken]="!building.isAvailable" class="column owner" style="grid-area: owner">
  <!-- <div *mediaIf="'medium down'" class="label">{{ 'marketplace.streets.owner' | translate }}</div> -->
  <div class="display-flex align-center">
    <span class="point"></span>
    <div class="value">
      {{ building.nft.ownerName | nickname }}
    </div>
  </div>
</div>

<div class="column purchase-price" style="grid-area: purchase-price">
  <span>
    {{ building.lastPrice | money: 'DWRLD':'—' }}
  </span>
</div>

<div class="column current-price" style="grid-area: current-price">
  <!-- <div *mediaIf="'medium down'" class="label">{{ 'marketplace.streets.price' | translate }}</div> -->
  <span>
    {{ (building.marketId ? building.sellPrice : building.marketPrice) | money: 'DWRLD':'—' }}
  </span>
</div>

<!-- <div class="column max-bid" style="grid-area: max-bid">
  <div *mediaIf="'medium down'" class="label">{{ 'marketplace.streets.max_bid' | translate }}</div>
  <span>{{ building.buyPrice | money: 'DWRLD':'—' }}</span>
</div> -->

<div *mediaIf="'large up'" class="column actions-container">
  <div class="column buy-now-container" style="grid-area: buy-now">
    <ng-container *ngTemplateOutlet="tradeTmpl"></ng-container>
  </div>

  <ng-container *mediaIf="'large up'">
    <span class="column cart">
      <ng-container [ngTemplateOutlet]="basketTmp"></ng-container>
    </span>
    <ng-container [ngTemplateOutlet]="favoriteTmp"></ng-container>
  </ng-container>
</div>

<ng-container *mediaIf="'medium down'">
  <ng-container *ngIf="!isWithdrawalStreet">
    <div class="column buy-now-container" style="grid-area: buy-now">
      <ng-container *ngTemplateOutlet="tradeTmpl"></ng-container>

      <ng-container *ngIf="!building.marketId">
        <span class="margin-left">
          <ng-container [ngTemplateOutlet]="basketTmp"></ng-container>
        </span>
      </ng-container>
    </div>
  </ng-container>

  <ng-container [ngTemplateOutlet]="favoriteTmp"></ng-container>
</ng-container>

<ng-template #tradeTmpl>
  <ng-container *ngIf="building.canStake || !building.collectionCompleted; else stakingTmpl">
    <button
      (click)="buyNow($event)"
      *ngIf="!building.isOwner && !building.marketId; else sellTmpl"
      appButton
      class="buy-now warn solid medium width-100"
      style="grid-area: buy-now">
      {{
        building.marketId
        ? ('marketplace.streets.button.buy_now' | translate)
        : ('marketplace.streets.button.mint' | translate)
      }}
    </button>
    <ng-template #sellTmpl>
      <!-- <button
        (click)="sell($event)"
        appButton
        class="buy-now warn solid medium width-100"
        style="grid-area: buy-now">
        {{
          !building.sellPrice
            ? ('marketplace.streets.button.sell_street' | translate)
            : ('marketplace.streets.button.cancel_sell' | translate)
        }}
      </button> -->
    </ng-template>
  </ng-container>
  <ng-template #stakingTmpl>
    <button
      appButton
      [disabled]="true"
      class="buy-now warn solid medium width-100"
      style="grid-area: buy-now">
      <div class="display-flex">
        <app-icon
          class="margin-right-tn"
          [ngClass]="{ spin: !building.isStaked }"
          [icon]="building.isStaked ? iconLocked : spinIcon"></app-icon>
        {{
          building.isStaked
            ? ('marketplace.streets.button.staked' | translate)
            : ('marketplace.streets.button.confirming' | translate)
        }}
      </div>
    </button>
  </ng-template>
</ng-template>

<ng-template #basketTmp>
  <ng-container *ngIf="!building.marketId">
    <app-icon
      (click)="toggleBasket($event)"
      *ngIf="!building.cartId"
      [icon]="iconCart"
      class="font-xhg color-primary-light"></app-icon>
    <app-icon
      (click)="toggleBasket($event)"
      *ngIf="building.cartId"
      [icon]="iconBagCross"
      class="bag-cross font-xhg color-warn"></app-icon>
  </ng-container>
  <app-icon
    *ngIf="building.marketId"
    [icon]="iconBagCross"
    class="bag-cross font-xhg color-warn"
    style="visibility: hidden"></app-icon>
</ng-template>

<ng-template #favoriteTmp>
  <ng-container *ngIf="!isWithdrawalStreet; else locked">
    <app-icon
      style="grid-area: favorite-icon"
      (click)="onToggleFavorite($event)"
      [class.favorite]="building.isFavorite"
      [icon]="iconStar"
      class="column favorite favorite-icon font-xhg color-warn"></app-icon>
  </ng-container>

  <ng-template #locked>
    <app-icon
      style="grid-area: favorite-icon"
      (click)="onToggleFavorite($event)"
      [icon]="iconLocked"
      class="column favorite favorite-icon font-xhg"></app-icon>
  </ng-template>
</ng-template>
