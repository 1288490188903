import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { PageTO } from 'api/models';
import { lazyModalsNav } from 'app/app.navigation';
import { WishlistBuildingsService } from 'app/marketplace/services/wishlist-buildings.service';
import { AuthService } from 'auth/services/auth.service';
import { SortHeaderItem } from 'core/components/list-components/sorting.model';
import { ModalRouter } from 'core/modules/modal';
import { StreetPreviewModal } from 'dashboard/dashboard.navigation';
import { Building } from 'dashboard/models/building.model';
import { Street } from 'dashboard/models/street.model';
import { BuildingsService } from 'dashboard/services/buildings.service';
import { CartService } from 'dashboard/services/cart.service';
import { UserService } from 'profile/services/user.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {GTMService} from "core/services/gtm.service";
import {CurrencyService} from "core/services/currency.service";
import {StreetsFactory} from "dashboard/services/streets.factory";
import {FbService} from "core/services/fb.service";

@Component({
  selector: 'app-street-overview-building-list',
  templateUrl: './street-overview-building-list.component.html',
  styleUrls: ['./street-overview-building-list.component.scss'],
})
export class StreetOverviewBuildingListComponent implements OnChanges, OnDestroy {
  @Input() street = new Street();

  private destroy$: Subject<void> = new Subject<void>();
  pagination?: PageTO = {};

  isLoading = false;

  headers: SortHeaderItem[] = [
    {
      label: $t('dashboard.assets.buildings.column.build'),
      key: '',
      itemClass: 'build-asset',
    },
    {
      label: $t('dashboard.assets.buildings.column.type'),
      key: '',
      itemClass: 'build-level',
    },
    {
      label: $t('dashboard.assets.buildings.column.current_price'),
      key: '',
      itemClass: 'build-current-price',
    },
    {
      label: $t('dashboard.assets.buildings.column.purchase_price'),
      key: '',
      itemClass: 'build-purchase-price',
    },

    {
      label: $t('dashboard.assets.buildings.column.date'),
      key: '',
      itemClass: 'build-date',
    },
    { label: '', key: '', itemClass: 'build-buttons' },
  ];
  buildings: Building[] = [];

  constructor(
    private buildingService: BuildingsService,
    private userService: UserService,
    private wishlistService: WishlistBuildingsService,
    private auth: AuthService,
    private streetsFactory: StreetsFactory,
    private GTMService: GTMService,
    private FbService: FbService,
    private cartService: CartService,
    private currencyService: CurrencyService,
    private modal: ModalRouter
  ) {}

  ngOnChanges(changes: SimpleChanges<StreetOverviewBuildingListComponent>): void {
    if (changes.street && this.street) {
      this.loadBuildingsList(this.street.id);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadBuildingsList(streetId: number): void {
    this.isLoading = true;
    this.buildingService
      .getBuildingsList(streetId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.isLoading = false;
        this.buildings = [...this.buildings, ...res.elements];
        this.pagination = res.pageInfo;
      });
  }

  toggleFavorite(building: Building): void {
    if (this.userService.user.id) {
      this.wishlistService.toggleFavorite(building);
    } else {
      this.auth.handleUnauthorizedAction();
    }
  }

  toggleCart(building: Building): void {
    if (this.street.marketId) {
      this.toggleCartMarket(building);
    } else {
      this.toggleNonMarket(building);
    }
  }

  toggleCartMarket(building: Building): void {
    if (building.cartId) {
      this.cartService.remove(building).pipe(takeUntil(this.destroy$)).subscribe();
    } else {
      this.cartService.add(building).pipe(takeUntil(this.destroy$)).subscribe();
    }
  }

  toggleNonMarket(building: Building): void {
    if (building.cartId) {
      this.cartService
        .removeFromStreet(this.street || new Street(), [building])
        .pipe(takeUntil(this.destroy$))
        .subscribe();
    } else {
      this.cartService
        .addToStreet(this.street || new Street(), [building])
        .pipe(takeUntil(this.destroy$))
        .subscribe();
    }
  }

  purchaseBuildingNow(building: Building): void {
    this.modal.navigate(lazyModalsNav.streets.buildingBuy(), undefined, {
      data: {
        entity: building,
        isBuilding: true,
        additionalEntity: this.street,
      } as StreetPreviewModal,
    });

    this.sendMetricTags(building);
  }

  private sendMetricTags(building: Building): void {
    const config = {
      'event': 'select_item',
      'user_id': this.userService.user.id,
      'ecommerce': {
        items: [
          {
            'item_name': this.street.name, // street name
            'item_id': this.street.id, // street ID
            'item_country': this.street.country, // street country
            'item_city': this.street.city, // city
            'item_level': this.street.level, // street level
            'item_token': this.street.marketPrice, // street value in DWRLD tokens
            'quantity': '1', // amount of items
            'price': this.currencyService.toDwrldRate(this.street.marketPrice, 'USD')// item worth amount in fiat
          },
          {
            'item_name': building.mainName, // street name
            'item_id': building.id, // street ID
            'item_country': building.country, // street country
            'item_city': building.city, // city
            'item_level': building.level, // street level
            'item_token': building.marketPrice, // street value in DWRLD tokens
            'quantity': '1', // amount of items
            'price': this.currencyService.toDwrldRate(building.marketPrice, 'USD')// item worth amount in fiat
          }
        ]
      }
    };
    this.FbService.pushTag(config);
    this.GTMService.pushTag(config);
  }
  identifyBuilding(_: number, item: Building): number {
    return item.id;
  }

  nextPageAction($event: () => void): void {
    this.isLoading = true;
    if (Number(this.pagination?.pageNumber) < Number(this.pagination?.totalPages))
      this.buildingService
        .getBuildingsList(this.street.id, (this.pagination?.pageNumber || 0) + 1)
        .pipe(takeUntil(this.destroy$))
        .subscribe(res => {
          $event();

          this.isLoading = false;
          this.buildings = [...this.buildings, ...res.elements];
          this.pagination = res.pageInfo;
        });
  }
}
