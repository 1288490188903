import { HttpClient } from '@angular/common/http';
import { Directive, ElementRef, HostListener, Input, OnDestroy, Renderer2 } from '@angular/core';
import { environment } from 'environments/environment';
import { asyncScheduler, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Directive({
  selector: 'a[appDownload]',
})
export class DownloadDirective implements OnDestroy {
  @Input()
  appDownload = '';

  @Input()
  url = '';

  private ignoreClickHandling?: boolean;
  private isLoading?: boolean;
  private destroy$ = new Subject();

  constructor(
    public elementRef: ElementRef<HTMLAnchorElement>,
    private renderer: Renderer2,
    private http: HttpClient
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('click')
  onClick(): void {
    if (this.isLoading) {
      return;
    }
    if (this.ignoreClickHandling) {
      this.ignoreClickHandling = false;
      return;
    }
    this.renderer.setAttribute(this.elementRef.nativeElement, 'disabled', '');
    this.isLoading = true;
    this.http
      .get(environment.mainServiceUrl + this.url, {
        observe: 'body',
        responseType: 'blob',
      })
      .pipe(
        map(response => new Blob([response], { type: response.type || 'application/pdf' })),
        takeUntil(this.destroy$)
      )
      .subscribe((pdf: Blob) => {
        this.ignoreClickHandling = true;
        const linkEl = this.elementRef.nativeElement;
        this.renderer.setAttribute(linkEl, 'href', window.URL.createObjectURL(pdf));
        this.renderer.setAttribute(linkEl, 'download', this.appDownload);
        linkEl.click();
        // take a time to save file
        // TODO: is there any download events available for this case?
        asyncScheduler.schedule(() => {
          this.isLoading = false;
          this.renderer.removeAttribute(linkEl, 'disabled');
          this.renderer.removeAttribute(linkEl, 'href');
          this.renderer.removeAttribute(linkEl, 'download');
          window.URL.revokeObjectURL(linkEl.href);
        }, 100);
      });
  }
}
