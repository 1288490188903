import { animate, style, transition, trigger } from '@angular/animations';

export const fieldErrorTransition = trigger('fieldErrorTransition', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateY(-15px)' }),
    animate('.22s ease-out', style({ opacity: 1, transform: 'translateY(0)' })),
  ]),
  transition(':leave', [
    style({ opacity: 1, transform: 'translateY(0)' }),
    animate('.22s', style({ opacity: 0, transform: 'translateY(-15px)' })),
  ]),
]);
