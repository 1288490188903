import { Directive } from '@angular/core';

import { ToastExtras } from './services/toaster.service';

@Directive({})
export abstract class BaseCustomToast<D> {
  extras!: ToastExtras<D>;

  abstract onCustomToastInit(extras?: ToastExtras<D>): void;
}
