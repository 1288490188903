import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  Renderer2,
  Self,
} from '@angular/core';
import { ControlValueAccessor, FormControlDirective, NgControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MediaQuery } from 'core/modules/platform/services/media-query.service';
import { GenericFormControl } from 'core/utils/form-generics';
import { asyncScheduler, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DropdownBase } from './dropdown.base';
import { menuTransition } from './dropdown.transitions';
import { SelectInputHost, SelectItemDirective } from './select-item.directive';

@Component({
  selector: 'app-select',
  template: `
    <ng-content select="[appAnchor]"></ng-content>
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayMinWidth]="overlayMinWidth || minWidth"
      [cdkConnectedOverlayWidth]="overlayMinWidth || minWidth"
      [cdkConnectedOverlayPositions]="position"
      [cdkConnectedOverlayOrigin]="overlayOrigin.elementRef"
      [cdkConnectedOverlayOpen]="active">
      <div
        appOverlayHost
        class="{{ theme }} {{ appearance }} dropdown"
        [@menuTransition]="transitionState"
        (mousedown)="stopClose()">
        <div class="data-host" [ngClass]="{ 'dc-scroll-light': lightScroll }">
          <ng-container *ngTemplateOutlet="tmplRef"></ng-container>
        </div>
      </div>
    </ng-template>
    <ng-content></ng-content>
  `,
  animations: [menuTransition],
  styleUrls: ['./styles/select.scss'],
})
export class SelectComponent
  extends DropdownBase
  implements OnInit, ControlValueAccessor, SelectInputHost
{
  @Input() overlayMinWidth = 0;

  @Input() lightScroll = false;

  private changedSubj = new ReplaySubject<unknown | null>(1);
  @Output()
  changed = this.changedSubj.asObservable();

  private currentValue: unknown | null = null;

  protected onTouched = (): null => null;
  protected onChange = (_: unknown): null => null;

  constructor(
    media: MediaQuery,
    renderer: Renderer2,
    elementRef: ElementRef<HTMLElement>,
    router: Router,
    @Inject(DOCUMENT) document: Document,
    @Self() @Optional() ngControl?: NgControl,
    @Self() @Optional() private controlDirective?: FormControlDirective
  ) {
    super(media, renderer, router, elementRef, document);
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  get value(): unknown | null {
    return this.currentValue;
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.anchorRef && this.controlDirective) {
      this.syncNgClasses(this.controlDirective, this.anchorRef.hostElement);
    }
  }

  onItemSelected(item: SelectItemDirective): void {
    this.updateValue(item.value);
    this.onChange(this.currentValue);
    this.scheduleClose();
  }

  // ControlValueAccessor
  writeValue(value: unknown): void {
    this.currentValue = value;
    this.updateValue(value);
  }

  registerOnChange(fn: (val: unknown) => null): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => null): void {
    this.onTouched = fn;
  }

  protected onClose(): void {
    super.onClose();
    this.onTouched();
  }

  private syncNgClasses(
    controlDirective: FormControlDirective,
    anchorHostElement: HTMLElement
  ): void {
    // validation helper that will set validation classes to anchor host
    controlDirective.form.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.assignNgClasses(controlDirective, anchorHostElement);
    });
    if (controlDirective.control instanceof GenericFormControl) {
      controlDirective.control.stateChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
        asyncScheduler.schedule(() => {
          this.assignNgClasses(controlDirective, anchorHostElement);
        }, 100);
      });
    }
    this.assignNgClasses(controlDirective, anchorHostElement);
  }

  private assignNgClasses(
    controlDirective: FormControlDirective,
    anchorHostElement: HTMLElement
  ): void {
    if (controlDirective.invalid) {
      this.renderer.addClass(anchorHostElement, 'ng-invalid');
    } else {
      this.renderer.removeClass(anchorHostElement, 'ng-invalid');
    }
    if (controlDirective.dirty) {
      this.renderer.addClass(anchorHostElement, 'ng-dirty');
    } else {
      this.renderer.removeClass(anchorHostElement, 'ng-dirty');
    }
    if (controlDirective.touched) {
      this.renderer.addClass(anchorHostElement, 'ng-touched');
    } else {
      this.renderer.removeClass(anchorHostElement, 'ng-touched');
    }
  }

  private updateValue(value: unknown): void {
    this.currentValue = value;
    this.changedSubj.next(value);
  }
}
