/* eslint-disable prefer-arrow-functions/prefer-arrow-functions */
import { InjectionToken, Provider } from '@angular/core';

import { WebSocketConfig } from './socket.type';

export const SOCKET_CONFIG: InjectionToken<string> = new InjectionToken('appWebsocketConfig');

export function provideSocketConfig(wsConfig: WebSocketConfig): Provider {
  return { provide: SOCKET_CONFIG, useValue: wsConfig, multi: true };
}
