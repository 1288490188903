import { ObserversModule } from '@angular/cdk/observers';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IconModule } from '../icon/icon.module';
import { HtmlInputComponent } from './html-input.component';
import { InputComponent } from './input.component';
import { InputExtrasComponent } from './input-extras.component';
import { NumberInputComponent } from './number-input.component';
import { TextInputComponent } from './text-input.component';

const exportedDeclarations = [
  HtmlInputComponent,
  InputComponent,
  NumberInputComponent,
  TextInputComponent,
  InputExtrasComponent,
];

@NgModule({
  imports: [CommonModule, IconModule, ReactiveFormsModule, FormsModule, ObserversModule],
  declarations: exportedDeclarations,
  exports: exportedDeclarations,
})
export class InputModule {}
