import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from 'core/modules/i18n/translate.module';
import { PlatformModule } from 'core/modules/platform/platform.module';

import { IconModule } from '../icon/icon.module';
import { TooltipModule } from '../tooltip/tooltip.module';
import { DataExpandComponent } from './data-expand.component';

@NgModule({
  imports: [IconModule, CommonModule, PlatformModule, TranslateModule, TooltipModule],
  declarations: [DataExpandComponent],
  exports: [DataExpandComponent],
})
export class DataExpandModule {}
