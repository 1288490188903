import { Injectable } from '@angular/core';
import { CurrencyTOResponse } from 'api/models';

import { Currency, dataToCurrency } from '../models/currency.model';

@Injectable({ providedIn: 'root' })
export class CurrencyFactory {
  private store: Map<string, Currency> = new Map();

  get(code = '', data?: CurrencyTOResponse): Currency {
    let currency = this.store.get(code || '');
    if (currency) {
      return data ? Object.assign(currency, dataToCurrency(code, data)) : currency;
    } else {
      currency = new Currency(dataToCurrency(code, data));
      this.store.set(code, currency);
      return currency;
    }
  }

  emptyStore(): void {
    this.store.clear();
  }
}
