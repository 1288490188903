import { Component, Input } from '@angular/core';
import { DecentWorldMarketplaceTO } from 'api/models';

@Component({
  selector: 'app-marketplace-streets-statistic',
  templateUrl: './marketplace-streets-statistic.component.html',
  styleUrls: ['./marketplace-streets-statistic.component.scss'],
})
export class MarketplaceStreetsStatisticComponent {
  @Input() statistic!: DecentWorldMarketplaceTO;
}
