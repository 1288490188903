import { createNavigation, createUrl } from 'core/utils/router.utils';

export const marketplaceRoot = 'marketplace';

export const marketplaceInit = (): string => '';

export const marketplaceStreets = (): string => 'streets';

export const marketplaceBuildings = (): string => 'buildings';

export const streetPreview = (streetId: number, country: string, name: string): string =>
  `${country?.replace(/\s/g, '-').toLowerCase()}-${name
    ?.replace(/\s|,|\.|\//g, '-')
    .replace(/'|\*|-|`|\[|\\|=|\(|\?|\)|\+|#/g, '')
    .toLowerCase()}-${streetId}`;

export const buildingPreview = (
  buildingId: number,
  country: string,
  city: string,
  street: string,
  buildingType?: string[]
): string =>
  `building-${country?.replace(/\s/g, '-').toLowerCase()}-${city
    ?.replace(/\s|,|\.|\//g, '-')
    .replace(/'|\*|-|`|\[|\\|=|\(|\?|\)|\+|#/g, '')
    .toLowerCase()}-${street
    ?.replace(/\s|,|\.|\//g, '-')
    .replace(/'|\*|-|`|\[|\\|=|\(|\?|\)|\+|#/g, '')
    .toLowerCase()}-${
    checkBuildingType(buildingType)
      ?.replace(/\s|,|\.|\//g, '-')
      .replace(/'|\*|-|`|\[|\\|=|\(|\?|\)|\+|#/g, '')
      .toLowerCase() ?? ''
  }${checkBuildingType(buildingType) ? '-' : ''}${buildingId}`;

export const checkBuildingType = (type: string[] = []): string | undefined => {
  if (!type?.length) return undefined;

  const str = type[0].split(':')[0];
  return str !== 'building' ? str : undefined;
};

export const wishlist = (): string => 'wishlist';

export const marketsRoot = (): string => 'markets';

export const marketsBuying = (): string => 'buy';

export const marketsSelling = (): string => 'sell';

export const marketsOffersReceived = (): string => 'offers-received';

export const marketplaceHistory = (): string => 'history';

export const paths = {
  marketplaceInit,
  marketplaceStreets,
  marketplaceBuildings,
  streetPreview,
  buildingPreview,
  wishlist,
  marketsRoot,
  marketsBuying,
  marketsSelling,
  marketsOffersReceived,
  marketplaceHistory,
};

export const marketplaceNav = createNavigation(paths, marketplaceRoot, {
  streetPreview: (...args) => createUrl(streetPreview.apply(null, args)),
  buildingPreview: (...args) => createUrl(buildingPreview.apply(null, args)),
});
