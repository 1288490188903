import { Component, OnDestroy } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-base-page',
  templateUrl: './base-page.component.html',
  styleUrls: ['./base-page.component.scss'],
})
export class BasePageComponent implements OnDestroy {
  destroy$ = new Subject();
  private topOffset = 0;
  private scrollBehavior: 'smooth' | undefined = 'smooth';

  constructor() {}

  setTopOffset(value: number): void {
    this.topOffset = value;
  }

  setScrollBehavior(value: 'smooth' | undefined): void {
    this.scrollBehavior = value;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  scrollToTop(): void {
    timer(250)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        window.scrollTo({ top: this.topOffset, behavior: this.scrollBehavior });
      });
  }
}
