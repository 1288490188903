import { Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { lazyModalsNav } from 'app/app.navigation';
import { iconAssets } from 'core/icons/lib/icon-assets';
import { iconCloseRounded } from 'core/icons/lib/icon-close-rounded';
import { iconEllipsisH } from 'core/icons/lib/icon-ellipsis-h';
import { iconLockEllipsis } from 'core/icons/lib/icon-lock-ellipsis';
import { iconMoneyChange } from 'core/icons/lib/icon-money-change';
import { iconOval } from 'core/icons/lib/icon-oval';
import { iconShare } from 'core/icons/lib/icon-share';
import { iconShoppingCart } from 'core/icons/lib/icon-shopping-cart';
import { iconStar } from 'core/icons/lib/icon-start';
import { ModalRouter } from 'core/modules/modal';
import {SharePreviewModal, StreetPreviewModal} from 'dashboard/dashboard.navigation';
import { DwrldOrder } from 'dashboard/models/dwrld-order.model';
import { UserService } from 'profile/services/user.service';

@Component({
  selector: 'app-selling-item',
  templateUrl: './selling-item.component.html',
  styleUrls: ['./selling-item.component.scss'],
})
export class SellingItemComponent {
  readonly closeIcon = iconCloseRounded;
  readonly collectionIcon = iconAssets;
  readonly ellipsisIcon = iconEllipsisH;
  readonly starIcon = iconStar;
  readonly shoppingCartIcon = iconShoppingCart;
  readonly exchangeIcon = iconMoneyChange;
  readonly lockIcon = iconLockEllipsis;
  readonly spinIcon = iconOval;
  readonly iconShare = iconShare;

  @Input() order = new DwrldOrder();

  @Output() sellOrder = new EventEmitter<DwrldOrder>();

  @HostBinding('class.disabled')
  get inCollection(): boolean {
    return !!this.order.collectionId;
  }

  constructor(public userService: UserService, private modal: ModalRouter) {}

  onSellOrder(): void {
    this.sellOrder.next(this.order);
  }

  @HostListener('click')
  openStreetOrder(): void {
    this.modal.navigate(lazyModalsNav.streets.streetSell(), undefined, {
      data: { entity: this.order.street, closeOnOrderCancel: true } as StreetPreviewModal,
    });
  }

  shareAction(): void {
    this.modal.navigate(lazyModalsNav.dashboard.sharePreview(), undefined, {
      data: {
        entityData: this.order.street,
        entityType: 'street'
      } as SharePreviewModal,
    });
  }
}
