import { Component, EventEmitter, HostBinding, Input, OnDestroy, Output } from '@angular/core';
import { lazyModalsNav } from 'app/app.navigation';
import { iconBagCross } from 'core/icons/lib/icon-bag-cross';
import { iconCart } from 'core/icons/lib/icon-cart';
import { iconEllipsisH } from 'core/icons/lib/icon-ellipsis-h';
import { iconLock } from 'core/icons/lib/icon-lock';
import { iconOval } from 'core/icons/lib/icon-oval';
import { iconStar } from 'core/icons/lib/icon-start';
import { ModalRouter } from 'core/modules/modal';
import { StreetPreviewModal } from 'dashboard/dashboard.navigation';
import { Street } from 'dashboard/models/street.model';
import { CartService } from 'dashboard/services/cart.service';
import { StreetsService } from 'dashboard/services/streets.service';
import { UserService } from 'profile/services/user.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {GTMService} from "core/services/gtm.service";
import {DwrldToPipe} from "core/pipes/core-pipes/dwrld-to.pipe";
import {CurrencyService} from "core/services/currency.service";
import {FbService} from "core/services/fb.service";

@Component({
  selector: 'app-marketplace-street-item',
  templateUrl: './marketplace-street-item.component.html',
  styleUrls: ['./marketplace-street-item.component.scss'],
})
export class MarketplaceStreetItemComponent implements OnDestroy {
  readonly iconLocked = iconLock;
  readonly spinIcon = iconOval;
  readonly iconStar = iconStar;
  readonly iconEllipsis = iconEllipsisH;
  readonly iconCart = iconCart;
  readonly iconBagCross = iconBagCross;
  @Input() street = new Street();

  @Output() toggleFavorite = new EventEmitter<Street>();

  @HostBinding('class.disabled')
  get inCollection(): boolean {
    return !!this.street.collectionId;
  }

  private destroy$ = new Subject<void>();

  constructor(
    private modal: ModalRouter,
    private userService: UserService,
    private streetService: StreetsService,
    private cartService: CartService,
    private GTMService: GTMService,
    private FbService: FbService,
    private currencyService: CurrencyService,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  buyNow(event: MouseEvent): void {
    event.stopPropagation();
    if (!this.userService.user.id) {
      this.modal.navigate(lazyModalsNav.auth.signIn());
      return;
    }
    this.streetService
      .findByNFTMarket({ streetId: this.street.id })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {

        this.modal.navigate(lazyModalsNav.streets.streetBuy(), undefined, {
          data: {
            entity: this.street,
          } as StreetPreviewModal,
        });

        if( !this.street.orderId && ((this.street.marketId && this.street.sellPrice) || !this.street.marketId)) {
          this.sendMetricTags();
        }
      });
  }

  private sendMetricTags(): void {
    const config = {
      'event': 'select_item',
      'user_id': this.userService.user.id,
      'ecommerce': {
        items: [{
          'item_name': this.street.name, // street name
          'item_id': this.street.id, // street ID
          'item_country': this.street.country, // street country
          'item_city': this.street.city, // city
          'item_level': this.street.level, // street level
          'item_token': this.street.marketPrice, // street value in DWRLD tokens
          'quantity': '1', // amount of items
          'price': this.currencyService.toDwrldRate(this.street.marketPrice, 'USD')// item worth amount in fiat
        }]
      }
    };
    this.FbService.pushTag(config);
    this.GTMService.pushTag(config);
  }
  sell(event: MouseEvent): void {
    this.streetService
      .findByNFTMarket({ streetId: this.street.id })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.modal.navigate(lazyModalsNav.streets.streetSell(), undefined, {
          data: {
            entity: this.street,
            isMarket: !!this.street.buyPrice,
          } as StreetPreviewModal,
        });
      });
  }

  onToggleFavorite(event: MouseEvent): void {
    event.stopPropagation();
    if (!this.userService.user.id) {
      this.modal.navigate(lazyModalsNav.auth.signIn());
      return;
    }
    if (!this.street.isFavorite) {
      this.GTMService.pushTag({
        'event': 'add_to_wishlist',
        'user_id': this.userService.user.id,
        'ecommerce': {
          items: [{
            'item_name': this.street.name, // street name
            'item_id': this.street.id, // street ID
            'item_country': this.street.country, // street country
            'item_city':this.street.city, // city
            'item_level': this.street.level, // street level
            'item_token': this.street.marketPrice, // street value in DWRLD tokens
            'quantity': '1', // amount of items
            'price': this.currencyService.toDwrldRate(this.street.marketPrice, 'USD') // item worth amount in fiat
          }]
        }
      });
    }
    this.toggleFavorite.next(this.street);
  }

  removeFromBasket(event: MouseEvent): void {
    event.stopPropagation();
    if (!this.userService.user.id) {
      this.modal.navigate(lazyModalsNav.auth.signIn());
      return;
    }
    this.cartService.remove(this.street).pipe(takeUntil(this.destroy$)).subscribe();
  }

  addToBasket(event: MouseEvent): void {
    event.stopPropagation();
    if (!this.userService.user.id) {
      this.modal.navigate(lazyModalsNav.auth.signIn());
      return;
    }
    this.cartService.add(this.street).pipe(takeUntil(this.destroy$)).subscribe(() => {

    });
  }
}
