import { DwrldTOResponse } from 'api/models';

import { Street } from './street.model';

export class DwrldOrder {
  accountId!: number;
  buyingPrice!: number;
  city!: string;
  country!: string;
  description!: string;
  lastPrice!: number;
  nftId!: number;
  streetId!: number;
  collectionId!: number;
  orderId!: number;
  ownerName!: string;
  ownerUuid!: string;
  price!: number;

  sellPrice!: number;
  zone!: number;

  constructor(init?: PickData<Omit<DwrldOrder, 'street'>>, public street = new Street()) {
    Object.assign(this, init);
  }
}

export const dataToDwrldOrder = (
  data: DwrldTOResponse = {}
): PickData<Omit<DwrldOrder, 'street'>> => ({
  accountId: data.accountId || 0,
  buyingPrice: data.buyingPrice || 0,
  city: data.city || '',
  country: data.country || '',
  description: data.description || '',
  lastPrice: data.lastPrice || 0,
  nftId: data.nftId || 0,
  streetId: data.streetId || 0,
  collectionId: data.collectionId || 0,
  orderId: data.orderId || 0,
  ownerName: data.ownerName || '',
  ownerUuid: data.ownerUuid || '',
  price: data.price || 0,
  sellPrice: data.sellPrice || 0,
  zone: data.zone || 0,
});
