import { Component, ElementRef, forwardRef, HostBinding, Input } from '@angular/core';
import { AppIcon, AppIconName } from 'core/icons/icon.type';
import { iconCheckCircle } from 'core/icons/lib/icon-check-circle';
import { asyncScheduler } from 'rxjs';

import { ButtonComponent } from './button.component';
import { successTransition } from './button.transitions';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[appClearButton]',
  template: `
    <div
      *ngIf="ripple"
      matRipple
      class="mat-ripple"
      [matRippleTrigger]="elementRef.nativeElement"
      [matRippleUnbounded]="true"></div>
    <app-icon
      class="success-icon"
      *ngIf="showSuccessIcon"
      [@successTransition]="showSuccessIcon"
      [icon]="successIcon"></app-icon>
    <app-icon *ngIf="appClearButton" [icon]="appClearButton"></app-icon>
    <span class="content" [ngClass]="appClearButton ? 'with-icon' : ''">
      <ng-content></ng-content>
    </span>
    <ng-content select="app-badge"></ng-content>
  `,
  styleUrls: ['./styles/clear.scss', './styles/ripple.scss'],
  exportAs: 'clear-btn',
  providers: [{ provide: ButtonComponent, useExisting: forwardRef(() => ClearButtonComponent) }],
  animations: [successTransition],
})
export class ClearButtonComponent {
  @Input()
  appClearButton: AppIconName | AppIcon = '';

  @Input()
  ripple?: boolean;

  @Input()
  @HostBinding('attr.type')
  type = 'button';

  successIcon = iconCheckCircle;
  showSuccessIcon?: boolean;

  constructor(public elementRef: ElementRef<HTMLElement>) {}

  showSuccess(): void {
    this.showSuccessIcon = true;
    asyncScheduler.schedule(() => (this.showSuccessIcon = false), 750);
  }
}
