import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Renderer2,
} from '@angular/core';
import { AppIconsLibrary } from 'core/icons/icons-library.service';
import { iconRadioEmpty } from 'core/icons/lib/icon-radio-empty';
import { iconRadioFilled } from 'core/icons/lib/icon-radio-filled';
import { iconRadioInner } from 'core/icons/lib/icon-radio-inner';

import { AppIconName } from '../../icons/icon.type';
import { RadioGroupComponent } from './radio-group.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[appRadio]',
  template: `
    <span class="box">
      <app-icon icon="radio-empty"></app-icon>
      <app-icon icon="radio-filled"></app-icon>
      <app-icon icon="radio-inner"></app-icon>
    </span>
    <span class="content"><ng-content></ng-content></span>
  `,
  styleUrls: ['./styles/radio.scss'],
})
export class RadioButtonComponent implements OnDestroy, OnChanges {
  @Input()
  appRadio?: AppIconName = '';

  @Input()
  @HostBinding('class.on')
  active = false;

  @Input()
  value: unknown;

  @Input()
  @HostBinding('attr.type')
  type = 'button';

  changed = new EventEmitter();
  activated = new EventEmitter();

  constructor(
    iconLib: AppIconsLibrary,
    renderer: Renderer2,
    elementRef: ElementRef<HTMLElement>,
    @Optional() private group?: RadioGroupComponent
  ) {
    group?.addButton(this);
    iconLib.addIcons([iconRadioFilled, iconRadioInner, iconRadioEmpty]);
    renderer.addClass(elementRef.nativeElement, 'radio');
  }

  ngOnChanges(changes: SimpleChanges<RadioButtonComponent>): void {
    if (changes.value) {
      this.changed.next();
    }
    if (changes.active) {
      this.activated.next();
    }
  }

  ngOnDestroy(): void {
    this.group?.removeButton(this);
  }

  @HostListener('click')
  onClick(): void {
    if (!this.active) {
      this.active = !this.active;
      this.activated.next();
    }
  }
}
