import {
  AbstractControl,
  AbstractControlOptions,
  AsyncValidatorFn,
  FormArray,
  FormControl,
  FormGroup,
  ValidatorFn,
} from '@angular/forms';
import { Observable, Subject } from 'rxjs';

type ControlsProperties<T> = {
  [P in keyof T]: T[P] extends GenericFormControl<unknown>
    ? P
    : T[P] extends GenericFormGroup
    ? P
    : T[P] extends GenericFormArray
    ? P
    : never;
}[keyof T];
type PickControls<T> = Required<Pick<T, ControlsProperties<T>>>;
type ExtractGenerics<T> = {
  [P in keyof T]: T[P] extends GenericFormControl<infer X>
    ? X
    : T[P] extends GenericFormGroup
    ? ExtractGenerics<PickControls<T[P]>>
    : T[P] extends GenericFormArray
    ? T[P]['value']
    : unknown;
};
type FormArrayControls<T> = T extends AbstractControl ? T[] : GenericAbstractControl<T>[];
export type PickFormValues<T> = ExtractGenerics<PickControls<T>>;
type PickControlsFromModel<T> = Required<{
  [P in keyof T]: T[P] extends unknown[]
    ? GenericFormArray<GenericFormControl<T[P][number]>>
    : GenericFormControl<T[P]>;
}>;

export abstract class GenericAbstractControl<T = unknown> extends AbstractControl {
  value!: T;
}

export class GenericFormControl<T> extends FormControl {
  value!: T;
  readonly valueChanges!: Observable<T>;

  private stateChangesSubj = new Subject<void>();
  stateChanges = this.stateChangesSubj.asObservable();

  constructor(
    state?: T,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    super(state, validatorOrOpts, asyncValidator);
  }

  setValue(
    value?: T,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    }
  ): void {
    super.setValue(value, options);
    this.stateChangesSubj.next();
  }

  enable(opts?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    super.enable(opts);
    this.stateChangesSubj.next();
  }

  disable(opts?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    super.disable(opts);
    this.stateChangesSubj.next();
  }

  markAsTouched(opts?: { onlySelf?: boolean }): void {
    if (!this.touched) {
      super.markAsTouched(opts);
      this.stateChangesSubj.next();
    }
  }

  markAsUntouched(opts?: { onlySelf?: boolean }): void {
    if (this.touched) {
      // we need set it as untouched before sending event, so event receiver
      // will se new state of control
      super.markAsUntouched(opts);
      this.stateChangesSubj.next();
    }
  }

  markAsDirty(opts?: { onlySelf?: boolean | undefined }): void {
    super.markAsDirty(opts);
    this.stateChangesSubj.next();
  }

  markAsPristine(opts?: { onlySelf?: boolean | undefined }): void {
    super.markAsPristine(opts);
    this.stateChangesSubj.next();
  }
}

export class GenericFormArray<T = unknown> extends FormArray {
  value!: T extends AbstractControl ? PickFormValues<T>[] : T[];
  controls!: FormArrayControls<T>;

  constructor(
    controls: FormArrayControls<T>,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    super(controls, validatorOrOpts, asyncValidator);
  }
}

export abstract class GenericFormGroup<T = unknown> extends FormGroup {
  value!: T;
  controls!: PickControlsFromModel<T>;
  controlsProps: string[];
  readonly valueChanges!: Observable<T>;

  constructor(
    controls: PickControlsFromModel<T>,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    super(controls, validatorOrOpts, asyncValidator);
    this.controlsProps = Object.keys(controls);
  }

  setValue(
    value: Partial<T>,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    }
  ): void {
    const data = this.controlsProps.reduce((acc, curr) => {
      // eslint-disable-next-line no-prototype-builtins
      if (value.hasOwnProperty(curr)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        (acc as { [key: string]: unknown })[curr] =
          (value as { [key: string]: unknown })[curr] || null;
      }
      return acc;
    }, {});
    super.setValue(data, options);
  }

  patchValue(
    value: Partial<T>,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    }
  ): void {
    // intersect form and object fields to assign only existing fields
    const data = this.controlsProps.reduce((acc, curr) => {
      // eslint-disable-next-line no-prototype-builtins
      if (value.hasOwnProperty(curr)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        (acc as { [key: string]: unknown })[curr] =
          (value as { [key: string]: unknown })[curr] !== undefined
            ? (value as { [key: string]: unknown })[curr]
            : null;
      }
      return acc;
    }, {});
    super.patchValue(data, options);
  }
}
