import {
  AfterContentInit,
  Component,
  ElementRef,
  forwardRef,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

import { InputComponent } from './input.component';

@Component({
  selector: 'app-html-input',
  template: `
    <app-icon *ngIf="icon" [icon]="icon"></app-icon>
    <ng-content select="app-svg"></ng-content>
    <div class="container">
      <span class="title">{{ title }}</span>
      <div
        class="inp"
        [attr.contenteditable]="type === 'text'"
        [attr.tabindex]="tabindex"
        [attr.autocomplete]="autocomplete"
        [attr.name]="name"
        [attr.inputmode]="inputmode"
        [attr.ph]="placeholder"
        (cdkObserveContent)="projectContentChanged()"
        #input>
        <ng-content></ng-content>
      </div>
    </div>
    <ng-content select="[appClearButton]"></ng-content>
    <app-input-extras *ngIf="extras" class="extras" [type]="extras"></app-input-extras>
  `,
  styleUrls: ['./styles/solid.scss', './styles/solid-validation.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
    {
      provide: InputComponent,
      useExisting: forwardRef(() => HtmlInputComponent),
    },
  ],
})
export class HtmlInputComponent extends InputComponent implements OnDestroy, AfterContentInit {
  selectSub?: Subscription;

  constructor(public elementRef: ElementRef, protected renderer: Renderer2) {
    super(elementRef, renderer);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.selectSub?.unsubscribe();
  }

  ngAfterContentInit(): void {
    this.projectContentChanged();
  }

  projectContentChanged(): void {
    const v = this.getInputValue();
    if (!v || v.length === 0) {
      this.renderer.setAttribute(this.inputRef.nativeElement, 'empty', 'true');
    } else {
      this.renderer.removeAttribute(this.inputRef.nativeElement, 'empty');
    }
    this.setFilledState(!!v);
  }

  protected getInputValue(): string {
    return this.inputRef.nativeElement.innerText;
  }

  protected setInputValue(v: string): void {
    this.inputRef.nativeElement.innerText = v;
  }
}
