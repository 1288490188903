<ng-container [ngSwitch]="historyItem?.event">
  <ng-container *ngSwitchCase="'INTERNAL_TRANSFER_NFT'">
    <app-icon class="icon color-primary" [icon]="iconTrade"></app-icon>
  </ng-container>
  <ng-container *ngSwitchCase="'MINTED'">
    <app-icon class="icon color-primary" [icon]="iconMinted"></app-icon>
  </ng-container>
  <ng-container *ngSwitchCase="'SELL'">
    <app-icon class="icon color-primary" [icon]="iconSell"></app-icon>
  </ng-container>
  <ng-container *ngSwitchCase="'DEPOSIT'">
    <app-icon class="icon color-primary" [icon]="iconDeposit"></app-icon>
  </ng-container>
  <ng-container *ngSwitchCase="'WITHDRAW'">
    <app-icon class="icon color-primary" [icon]="iconWithdrawal"></app-icon>
  </ng-container>
</ng-container>

<div class="text">
  <ng-container [ngSwitch]="historyItem?.event">
    <ng-container *ngSwitchCase="'INTERNAL_TRANSFER_NFT'">
      <span class="color-primary margin-left-tn">
        {{ 'street.modals.street_history.transfer' | translate }}
      </span>
    </ng-container>
    <ng-container *ngSwitchCase="'MINTED'">
      <span class="color-primary margin-left-tn">
        {{ 'street.modals.street_history.minted' | translate }}
      </span>
    </ng-container>
    <ng-container *ngSwitchCase="'SELL'">
      <span class="color-primary margin-left-tn">
        {{ 'street.modals.street_history.sale' | translate }}
      </span>
    </ng-container>
    <ng-container *ngSwitchCase="'DEPOSIT'">
      <span class="color-primary margin-left-tn">
        {{ 'street.modals.street_history.deposit' | translate }}
      </span>
    </ng-container>
    <ng-container *ngSwitchCase="'WITHDRAW'">
      <span class="color-primary margin-left-tn">
        {{ 'street.modals.street_history.withdrawal' | translate }}
      </span>
    </ng-container>
  </ng-container>

  <span class="margin-left-tn">{{ historyItem?.date | date }}</span>

  <span *ngIf="+(historyItem?.price || 0)" class="amount margin-left-tn">
    {{ +(historyItem?.price || 0) | precision }} DWRLD
  </span>

  <span class="user-link" [appPublicProfile]="historyItem.accountFrom || ''">
    {{ historyItem?.accountFrom | nickname: undefined:historyItem.accountFrom }}
  </span>

  <span class="margin-left-tn">&#8594;</span>

  <span class="user-link" [appPublicProfile]="historyItem.accountTo || ''">
    {{ historyItem?.accountTo | nickname: undefined:historyItem.accountTo }}
  </span>

  <a *ngIf="historyItem?.fingerPrint" [href]="historyItem?.fingerPrint" class="link margin-left-tn">
    <!-- <app-icon [icon]="iconCopy"></app-icon> -->
  </a>
</div>
