/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CollectionStakeElementTOListResponse } from '../models/collection-stake-element-tolist-response';
import { CollectionStakeElementTOResponse } from '../models/collection-stake-element-toresponse';
import { RedeemAvailableStakeForm } from '../models/redeem-available-stake-form';

/**
 * Collection Stake Controller
 */
@Injectable({
  providedIn: 'root',
})
class CollectionStakeControllerService extends __BaseService {
  static readonly listUsingGET3Path = '/api/v1/nft-collection/stake/list';
  static readonly redeemAvailableStakeUsingPOSTPath = '/api/v1/nft-collection/stake/redeem';
  static readonly byIdUsingGETPath = '/api/v1/nft-collection/stake/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * list
   * @param params The `CollectionStakeControllerService.ListUsingGET3Params` containing the following parameters:
   *
   * - `stakeFilter`: stakeFilter
   *
   * - `sort`: sort
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * @return OK
   */
  listUsingGET3Response(params: CollectionStakeControllerService.ListUsingGET3Params): __Observable<__StrictHttpResponse<CollectionStakeElementTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.stakeFilter != null) __params = __params.set('stakeFilter', params.stakeFilter.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/nft-collection/stake/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionStakeElementTOListResponse>;
      })
    );
  }
  /**
   * list
   * @param params The `CollectionStakeControllerService.ListUsingGET3Params` containing the following parameters:
   *
   * - `stakeFilter`: stakeFilter
   *
   * - `sort`: sort
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * @return OK
   */
  listUsingGET3(params: CollectionStakeControllerService.ListUsingGET3Params): __Observable<CollectionStakeElementTOListResponse> {
    return this.listUsingGET3Response(params).pipe(
      __map(_r => _r.body as CollectionStakeElementTOListResponse)
    );
  }

  /**
   * redeemAvailableStake
   * @param form form
   * @return OK
   */
  redeemAvailableStakeUsingPOSTResponse(form: RedeemAvailableStakeForm): __Observable<__StrictHttpResponse<CollectionStakeElementTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/nft-collection/stake/redeem`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionStakeElementTOResponse>;
      })
    );
  }
  /**
   * redeemAvailableStake
   * @param form form
   * @return OK
   */
  redeemAvailableStakeUsingPOST(form: RedeemAvailableStakeForm): __Observable<CollectionStakeElementTOResponse> {
    return this.redeemAvailableStakeUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as CollectionStakeElementTOResponse)
    );
  }

  /**
   * byId
   * @param id id
   * @return OK
   */
  byIdUsingGETResponse(id: number): __Observable<__StrictHttpResponse<CollectionStakeElementTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/nft-collection/stake/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionStakeElementTOResponse>;
      })
    );
  }
  /**
   * byId
   * @param id id
   * @return OK
   */
  byIdUsingGET(id: number): __Observable<CollectionStakeElementTOResponse> {
    return this.byIdUsingGETResponse(id).pipe(
      __map(_r => _r.body as CollectionStakeElementTOResponse)
    );
  }
}

module CollectionStakeControllerService {

  /**
   * Parameters for listUsingGET3
   */
  export interface ListUsingGET3Params {

    /**
     * stakeFilter
     */
    stakeFilter?: 'ALL' | 'NOT_FILLED' | 'ONLY_STAKED' | 'ONLY_UNSTAKED' | 'READY_FOR_STAKE' | 'UNKNOWN_STAKE_TYPE' | 'UNRECOGNIZED' | 'UNSTAKED_FROM_BLOCKCHAIN';

    /**
     * sort
     */
    sort?: string;

    /**
     * pageSize
     */
    pageSize?: number;

    /**
     * pageNumber
     */
    pageNumber?: number;
  }
}

export { CollectionStakeControllerService }
