import { Pipe, PipeTransform } from '@angular/core';

import { I18nService } from '../i18n.service';

@Pipe({
  name: 'i18nKeyExists',
})
export class I18nKeyExistsPipe implements PipeTransform {
  constructor(private i18n: I18nService) {}

  transform(value: string | LocalizedString | undefined): boolean {
    return this.i18n.keyExists(value);
  }
}
