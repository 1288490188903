import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MarketExchanged } from 'app/exchange/models/market-statistic';
import { iconStar } from 'core/icons/lib/icon-start';

@Component({
  selector: 'app-exchange-statistic-market-item',
  templateUrl: './exchange-statistic-market-item.component.html',
  styleUrls: ['./exchange-statistic-market-item.component.scss'],
})
export class ExchangeStatisticMarketItemComponent {
  readonly iconStar = iconStar;
  @Input() market?: MarketExchanged;

  @Output() toggleFavoriteMarketEmitter = new EventEmitter<MarketExchanged>();
  constructor() {}

  onToggleFavorite(a?: MarketExchanged): void {
    this.toggleFavoriteMarketEmitter.emit(a);
  }
}
