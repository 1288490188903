import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { MediaQuery } from '../services/media-query.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[mediaClass]',
})
export class MediaClassDirective implements OnInit, OnDestroy, OnChanges {
  @Input()
  mediaClass: [string, string?, string?] = [''];

  private mediaSubs = new Subscription();

  constructor(
    private renderer: Renderer2,
    private elRef: ElementRef<HTMLElement>,
    private media: MediaQuery
  ) {}

  ngOnDestroy(): void {
    this.mediaSubs.unsubscribe();
  }

  ngOnInit(): void {
    this.mediaSubs = this.media.mediaChanged
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(() => {
        const media = this.mediaClass[0];
        const matchClasses = this.mediaClass[1];
        const notMatchClasses = this.mediaClass[2];
        if (this.media.is(media)) {
          this.addClasses(matchClasses);
          this.removeClasses(notMatchClasses);
        } else {
          this.addClasses(notMatchClasses);
          this.removeClasses(matchClasses);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges<MediaClassDirective>): void {
    if (changes.mediaClass) {
      const media = this.mediaClass[0];
      const matchClasses = this.mediaClass[1];
      const notMatchClasses = this.mediaClass[2];
      const oldMatchClasses = (changes.mediaClass.previousValue || [])[1];
      const oldNotMatchClasses = (changes.mediaClass.previousValue || [])[2];
      if (oldMatchClasses) {
        this.removeClasses(oldMatchClasses);
      }
      if (oldNotMatchClasses) {
        this.removeClasses(oldNotMatchClasses);
      }
      if (this.media.is(media)) {
        this.addClasses(matchClasses);
      } else {
        this.addClasses(notMatchClasses);
      }
    }
  }

  private addClasses(classes: string | undefined): void {
    classes
      ?.split(' ')
      .filter(cl => !!cl)
      .forEach(c => {
        this.renderer.addClass(this.elRef.nativeElement, c);
      });
  }

  private removeClasses(classes: string | undefined): void {
    classes
      ?.split(' ')
      .filter(cl => !!cl)
      .forEach(c => {
        this.renderer.removeClass(this.elRef.nativeElement, c);
      });
  }
}
