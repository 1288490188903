import { NgModule } from '@angular/core';

import { I18nKeyExistsPipe } from './pipes/i18n-key-exists.pipe';
import { TranslatePipe } from './pipes/translate.pipe';

@NgModule({
  imports: [],
  declarations: [TranslatePipe, I18nKeyExistsPipe],
  exports: [TranslatePipe, I18nKeyExistsPipe],
})
export class TranslateModule {}
