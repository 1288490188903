import { Injectable } from '@angular/core';
import { UserControllerService } from 'api/services';
import { StateClearService, StateFullService } from 'app/_main/services/state-clear.service';
import { UserService } from 'profile/services/user.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export interface GeolocationData {
  latitude: number;
  longitude: number;
  timestamp: number;
  cityId?: number;
  countryCode?: string;
}

export interface MapLocationData {
  latitude: number;
  longitude: number;
  timestamp?: number;
  zoom: number;
}

@Injectable({ providedIn: 'root' })
export class GeolocationService implements StateFullService {
  private lsKey = 'geod';
  private tmpMapKey = 'user-saved-geod';
  private defaultGeoData = {
    latitude: 40.71018827034137,
    longitude: -74.00719482094853,
    timestamp: 0,
  };
  private invalidated = false;

  constructor(
    private userService: UserService,
    private userController: UserControllerService,
    private stateClearService: StateClearService
  ) {
    this.stateClearService.register(this);
  }

  changeState(data: MapLocationData): void {
    localStorage.setItem(
      this.userSavedGeoKey,
      JSON.stringify({
        longitude: data.longitude,
        latitude: data.latitude,
        timestamp: Date.now(),
        zoom: data.zoom,
      })
    );
  }

  clearState(): void {
    this.invalidated = true;
  }

  private get userSavedGeoKey(): string {
    return `${this.userService.user.id}-${this.tmpMapKey}`;
  }

  get data(): GeolocationData {
    return this.getLocalData() || this.defaultGeoData;
  }

  initialize(): Observable<GeolocationData> {
    const daysForUpdate = 2;
    const dayOnMilliseconds = 86400000;
    const localData = this.getLocalData();
    if (
      localData &&
      !this.invalidated &&
      localData?.timestamp &&
      (Date.now() - localData.timestamp) < (dayOnMilliseconds * daysForUpdate)
    ) {
      return of(localData);
    }
    return this.userController.geoInfoUsingGET().pipe(
      map(res => {
        const data = {
          latitude: res.location?.lat || 40.71018827034137,
          longitude: res.location?.lon || -74.00719482094853,
          timestamp: Date.now(),
          cityId: res.cityId,
          countryCode: res.countryCode,
        };
        this.setLocalData(data);
        return data;
      })
    );
  }

  getMapData(): MapLocationData | null {
    const data = localStorage.getItem(this.userSavedGeoKey);
    const locationData = data ? (JSON.parse(data) as MapLocationData) : null;
    if (locationData && locationData?.timestamp && Date.now() - locationData?.timestamp < 900000) {
      return locationData;
    } else {
      localStorage.removeItem(this.userSavedGeoKey);
      return null;
    }
  }

  private getLocalData(): GeolocationData | null {
    const data = localStorage.getItem(this.lsKey);
    const location = data ? (JSON.parse(data) as GeolocationData) : null;
    return location;
  }

  private setLocalData(data: GeolocationData): void {
    this.invalidated = false;
    localStorage.setItem(this.lsKey, JSON.stringify(data));
  }
}
