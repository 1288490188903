import { Platform } from '@angular/cdk/platform';
import { DecimalPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { iconSliderArrow } from 'core/icons/lib/icon-slider-arrow';
import { asyncScheduler, fromEvent, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

const isMouse = (e: unknown): e is MouseEvent => (e as MouseEvent).clientX !== undefined;

@Component({
  selector: 'app-range',
  template: `
    <span class="rg-tip" #tipRef></span>
    <div class="rg-container" #containerRef>
      <span class="rg-handle {{customizedActiveHandleClasses}}" #handleRef style="display: flex">
        <ng-container *ngIf="customizedActiveHandle">
          <app-icon [icon]="iconArrows"></app-icon>
        </ng-container>
      </span>
      <span class="rg-fill" [style.opacity]="showFill ? 1 : 0" #fillRef></span>
    </div>
    <span
      class="rg-levels"
      *ngFor="let l of levels; let i = index"
      [ngClass]="{ active: activeLabelIndex === i }"
      #levelRefs>
      <span class="rg-label" *ngIf="showLevels">{{ labels[i] }}</span>
    </span>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RangeComponent),
      multi: true,
    },
  ],
  styleUrls: ['./solid.scss'],
})
export class RangeComponent
  implements ControlValueAccessor, AfterViewInit, OnDestroy, OnInit, OnChanges
{
  iconArrows = iconSliderArrow;

  @ViewChild('tipRef', { static: true })
  tipRef!: ElementRef<HTMLElement>;

  @ViewChild('fillRef', { static: true })
  fillRef!: ElementRef<HTMLElement>;

  @ViewChild('containerRef', { static: true })
  containerRef!: ElementRef<HTMLElement>;

  @ViewChild('handleRef', { static: true })
  handleRef!: ElementRef<HTMLElement>;

  @ViewChildren('levelRefs')
  levelsRef!: QueryList<ElementRef>;

  @Input()
  min = 0;

  @Input()
  max = 100;

  @Input()
  levels: number[] = [];

  // labels for levels
  @Input()
  labels: string[] = [];

  // map levels to values e.g [0, 100, 10000, 20000, 50000]
  // so levels output of formControl will be in range from 0 to 10000
  // 0 to 100 at levels[0] (it is 0 to 25 percents of total input width)
  // 0 to 10000 at levels[1] (it is 25 to 50 percents of total input width) and so on
  @Input()
  levelsMap?: number[];

  // attach input values to levels
  @Input()
  behavior?: 'discrete' | 'click-discrete';

  @Input()
  @HostBinding('attr.disabled')
  disabled = false;

  @Input()
  showLevels = true;

  @Input()
  @HostBinding('class.show-tip')
  showTip = false;

  @Input()
  showFill = true;

  @Input()
  @HostBinding('class.show-points')
  showPoints = false;

  @Input() customizedActiveHandle = false;

  @Input() customizedActiveHandleClasses = '';

  activeLabelIndex = 0;

  private readonly pipe = new DecimalPipe('en-US');
  // x step between levelsMap values
  private step = 25;
  private thumbSize = 30; // TODO: get from element
  private value: number | null = null;
  private handleMoveSub = new Subscription();
  private handleDownSub = new Subscription();
  private handleTouchSub = new Subscription();
  private rangeTouchSub = new Subscription();
  private rangeDownSub = new Subscription();
  private handleActive = false;
  private rangeActive = false;

  protected onControlTouched = (): null => null;
  protected onControlChanged = (_: unknown): null => null;

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef<HTMLElement>,
    private platform: Platform,
    private chRef: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this.handleMoveSub.unsubscribe();
    this.handleDownSub.unsubscribe();
    this.handleTouchSub.unsubscribe();
    this.rangeDownSub.unsubscribe();
    this.rangeTouchSub.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges<RangeComponent>): void {
    if (changes.levelsMap && this.levelsMap) {
      if (!this.labels.length) {
        this.labels = this.levelsMap.map(v => this.pipe.transform(v) || v.toString());
      }
      this.step = 100 / (this.levelsMap.length - 1);
      let level = 0;
      this.levels = [];
      this.levelsMap.forEach(() => {
        this.levels.push(level);
        level += this.step;
      });
      this.levels[this.levels.length - 1] = 100;
    }
    if (changes.labels) {
      if (this.labels.length) {
        this.renderer.addClass(this.elementRef.nativeElement, 'with-labels');
      } else {
        this.renderer.removeClass(this.elementRef.nativeElement, 'with-labels');
      }
    }
  }

  ngOnInit(): void {
    this.observeRange();
    this.observeHandle();
  }

  ngAfterViewInit(): void {
    this.levelsRef.forEach((l, i) => {
      const v = this.levels[i];
      const offset = (v / 100) * 12;
      this.renderer.setStyle(l.nativeElement, 'left', `calc(${v}% - ${offset}px)`);
      if (i === 0) {
        this.renderer.addClass(l.nativeElement, 'first');
      } else if (i === this.levelsRef.length - 1) {
        this.renderer.addClass(l.nativeElement, 'last');
      }
    });
    this.updateLevels(this.value || 0);
  }

  writeValue(v: string | number): void {
    if (typeof v === 'number') {
      if (this.levelsMap) {
        v = this.inputToDisplay(v, this.levelsMap);
      } else {
        v = Math.min(100, this.lerp(0, 100, (v - this.min) / (this.max - this.min)));
      }
      if (this.levelsMap) {
        // call it here to update active label
        // TODO: separate active label logic from display to input
        this.displayToInput(v, this.levelsMap);
      }
      this.setValue(v, false);
    }
  }

  registerOnChange(fn: (val: unknown) => null): void {
    this.onControlChanged = fn;
  }

  registerOnTouched(fn: () => null): void {
    this.onControlTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private observeRange(): void {
    let touchOccurred = false;
    this.rangeTouchSub = fromEvent(this.elementRef.nativeElement, 'touchstart', {
      passive: true,
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    }).subscribe(() => {
      if (!this.behavior && this.handleActive) {
        return;
      }
      touchOccurred = true;
      this.rangeActive = true;
      this.onRangeTouched('touch');
    });
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.rangeDownSub = fromEvent<MouseEvent>(this.elementRef.nativeElement, 'mousedown').subscribe(
      e => {
        if (!this.behavior && (this.handleActive || e.button !== 0)) {
          return;
        }
        if (touchOccurred) {
          touchOccurred = false;
          return;
        }
        this.rangeActive = true;
        this.onRangeTouched('mouse');
      }
    );
  }

  private onRangeTouched(source: 'touch' | 'mouse'): void {
    if (!this.disabled) {
      const eventName = source === 'touch' ? 'touchend' : 'mouseup';
      const elOffsetLeft = this.getOffsetLeft();
      let removeClassSub = new Subscription();
      let resetRangeSub = new Subscription();
      fromEvent<TouchEvent | MouseEvent>(window, eventName)
        .pipe(first())
        // eslint-disable-next-line rxjs-angular/prefer-takeuntil
        .subscribe(e => {
          const width = this.containerRef.nativeElement.clientWidth;
          const pointerX = isMouse(e) ? e.clientX : e.changedTouches[0].clientX;
          const layerX = pointerX - elOffsetLeft - this.thumbSize / 2;
          const v = Math.max(0, Math.min(100, Math.round((layerX / width) * 100)));
          if (this.behavior === 'discrete' || this.behavior === 'click-discrete') {
            const closest = this.levels.reduce((prev, curr) =>
              Math.abs(curr - v) < Math.abs(prev - v) ? curr : prev
            );
            this.setValue(closest);
          } else {
            this.setValue(v);
          }
          this.renderer.addClass(this.elementRef.nativeElement, 'rg-active');
          removeClassSub.unsubscribe();
          removeClassSub = asyncScheduler.schedule(() => {
            this.renderer.removeClass(this.elementRef.nativeElement, 'rg-active');
          }, 500);
          resetRangeSub.unsubscribe();
          resetRangeSub = asyncScheduler.schedule(() => {
            this.rangeActive = false;
          }, 100);
        });
    }
  }

  private setValue(v: number, emitValue = true): void {
    this.renderer.setStyle(this.handleRef.nativeElement, 'left', `${v}%`);
    this.renderer.setStyle(
      this.fillRef.nativeElement,
      'width',
      `calc(${v}% + ${this.thumbSize / 2}px)`
    );
    this.renderer.setStyle(
      this.tipRef.nativeElement,
      'left',
      `calc(${v}% - ${(v / 100) * this.thumbSize}px)`
    );
    this.renderer.setProperty(this.tipRef.nativeElement, 'innerHTML', `${v.toFixed(0)}%`);
    this.value = v;
    this.updateLevels(v);
    this.onControlTouched();
    const output = this.lerp(this.min, this.max, v / 100);
    if (emitValue) {
      if (this.levelsMap) {
        this.onControlChanged(this.displayToInput(output, this.levelsMap));
      } else {
        this.onControlChanged(output);
      }
    }
  }

  private displayToInput(value: number, levelsMap: number[]): number {
    // value position between two levels in percentage
    // e.g [0, 50, 200]: value = 25 - output offset = 50% because 25 is half way to 50
    let offset = value;
    let min = 0;
    let max = 0;
    let i = 1;
    for (i = 1; i < this.levels.length; i++) {
      if (value <= this.levels[i]) {
        offset -= this.levels[i - 1];
        min += levelsMap[i - 1];
        max = levelsMap[i];
        break;
      }
    }
    if (this.behavior === 'discrete') {
      const isCloserToMax = offset / (this.levels[i] - this.levels[i - 1]) > 0.5;
      const tmpActiveIndex = this.activeLabelIndex;
      this.activeLabelIndex = isCloserToMax ? i : i - 1;
      if (this.activeLabelIndex !== tmpActiveIndex) {
        this.chRef.detectChanges();
      }
      return isCloserToMax ? max : min;
    }
    return Math.round(this.lerp(min, max, offset / this.step));
  }

  private lerp(v0: number, v1: number, t: number): number {
    return v0 * (1 - t) + v1 * t;
  }

  private updateLevels(v: number): void {
    this.levelsRef?.forEach((l, i) => {
      if (v >= this.levels[i]) {
        this.renderer.addClass(l.nativeElement, 'rg-filled');
      } else {
        this.renderer.removeClass(l.nativeElement, 'rg-filled');
      }
    });
  }

  private observeHandle(): void {
    let touchOccurred = false;
    const mobile = this.platform.IOS || this.platform.ANDROID;
    // use passive event only on mobile devices to prevent edge swipe navigation
    this.handleTouchSub = fromEvent<TouchEvent>(this.handleRef.nativeElement, 'touchstart', {
      passive: !mobile,
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    }).subscribe(e => {
      if (this.rangeActive) {
        return;
      }
      if (mobile || this.behavior === 'click-discrete') {
        e.preventDefault();
        e.stopImmediatePropagation();
      }
      touchOccurred = true;
      this.handleActive = true;
      this.onHandleTouched('touchmove', e.touches[0].clientX);
    });
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.handleDownSub = fromEvent<MouseEvent>(this.handleRef.nativeElement, 'mousedown').subscribe(
      e => {
        if (e.button !== 0 || this.rangeActive) {
          return;
        }
        if (this.behavior === 'click-discrete') {
          e.preventDefault();
          e.stopImmediatePropagation();
        }
        if (touchOccurred) {
          touchOccurred = false;
          return;
        }
        this.handleActive = true;
        this.onHandleTouched('mousemove', e.clientX);
      }
    );
  }

  private onHandleTouched(event: 'touchmove' | 'mousemove', initialX: number): void {
    const width = this.containerRef.nativeElement.clientWidth;
    const elOffsetLeft = this.getOffsetLeft();
    let thumbX = this.handleRef.nativeElement.offsetLeft;
    let prevLayerX = initialX - elOffsetLeft;
    this.handleMoveSub.unsubscribe();
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.handleMoveSub = fromEvent<TouchEvent | MouseEvent>(window, event).subscribe(e => {
      const pointerX = isMouse(e) ? e.clientX : e.touches[0].clientX;
      const layerX = pointerX - elOffsetLeft;
      const delta = layerX - prevLayerX;
      thumbX += delta;
      prevLayerX = layerX;
      const v = Math.max(0, Math.min(100, (thumbX / width) * 100));
      this.setValue(v);
      this.renderer.addClass(this.elementRef.nativeElement, 'rg-active');
    });
    fromEvent(window, event === 'touchmove' ? 'touchend' : 'mouseup')
      .pipe(first())
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(() => {
        this.handleMoveSub.unsubscribe();
        this.handleActive = false;
        this.renderer.removeClass(this.elementRef.nativeElement, 'rg-active');
      });
  }

  private getOffsetLeft(): number {
    return this.containerRef.nativeElement.getBoundingClientRect().left;
  }

  private inputToDisplay(inputValue: number, levelsMap: number[]): number {
    let offset = 0;
    let min = 0;
    let max = 0;
    for (let i = 1; i < this.levels.length; i++) {
      if (inputValue <= levelsMap[i]) {
        offset += this.levels[i - 1];
        min = levelsMap[i - 1];
        max = levelsMap[i];
        break;
      }
    }
    return Math.min(100, offset + this.lerp(0, this.step, (inputValue - min) / (max - min)));
  }
}
