/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Resource } from '../models/resource';

/**
 * User Logo Controller
 */
@Injectable({
  providedIn: 'root',
})
class UserLogoControllerService extends __BaseService {
  static readonly getLogoUsingGETPath = '/api/v1/user/logo';
  static readonly createLogoUsingPOSTPath = '/api/v1/user/logo';
  static readonly deleteLogoUsingDELETEPath = '/api/v1/user/logo';
  static readonly getPublicLogoUsingGETPath = '/api/v1/user/logo/public';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * getLogo
   * @return OK
   */
  getLogoUsingGETResponse(): __Observable<__StrictHttpResponse<Resource>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/user/logo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Resource>;
      })
    );
  }
  /**
   * getLogo
   * @return OK
   */
  getLogoUsingGET(): __Observable<Resource> {
    return this.getLogoUsingGETResponse().pipe(
      __map(_r => _r.body as Resource)
    );
  }

  /**
   * createLogo
   * @param file undefined
   * @return OK
   */
  createLogoUsingPOSTResponse(file?: Blob): __Observable<__StrictHttpResponse<Resource>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (file != null) { __formData.append('file', file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/user/logo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Resource>;
      })
    );
  }
  /**
   * createLogo
   * @param file undefined
   * @return OK
   */
  createLogoUsingPOST(file?: Blob): __Observable<Resource> {
    return this.createLogoUsingPOSTResponse(file).pipe(
      __map(_r => _r.body as Resource)
    );
  }

  /**
   * deleteLogo
   * @return OK
   */
  deleteLogoUsingDELETEResponse(): __Observable<__StrictHttpResponse<Resource>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/user/logo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Resource>;
      })
    );
  }
  /**
   * deleteLogo
   * @return OK
   */
  deleteLogoUsingDELETE(): __Observable<Resource> {
    return this.deleteLogoUsingDELETEResponse().pipe(
      __map(_r => _r.body as Resource)
    );
  }

  /**
   * getPublicLogo
   * @param uuid uuid
   * @return OK
   */
  getPublicLogoUsingGETResponse(uuid: string): __Observable<__StrictHttpResponse<Resource>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (uuid != null) __params = __params.set('uuid', uuid.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/user/logo/public`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Resource>;
      })
    );
  }
  /**
   * getPublicLogo
   * @param uuid uuid
   * @return OK
   */
  getPublicLogoUsingGET(uuid: string): __Observable<Resource> {
    return this.getPublicLogoUsingGETResponse(uuid).pipe(
      __map(_r => _r.body as Resource)
    );
  }
}

module UserLogoControllerService {
}

export { UserLogoControllerService }
