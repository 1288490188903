<div
  [class.noRecords]="history.length === 0 && noData === 'icon'"
  class="list">
  <ng-container *ngIf="history.length === 0; else historyTmpl">
    <div class="no-records" *ngIf="noData === 'icon'; else txtTmpl">
      <div class="no-records-icon">
        <app-icon [icon]="iconNoRecords"></app-icon>
      </div>
      <div>
        <span>{{ 'street.ui.street_history.empty' | translate }}</span>
      </div>
    </div>
    <ng-template #txtTmpl>
      <span class="no-records-text">{{ 'street.ui.street_history.empty' | translate }}</span>
    </ng-template>
  </ng-container>
  <ng-template #historyTmpl></ng-template>
  <app-street-history-list-item
    *ngFor="let historyItem of history"
    [historyItem]="historyItem"
    class="column"></app-street-history-list-item>
</div>

<app-pagination
  (changed)="applyPageAction($event)"
  *ngIf="pagination.totalPages && pagination.totalPages > 1"
  [current]="pagination.pageNumber || 1"
  [total]="pagination.totalPages"
  class="margin-top-lg"></app-pagination>
