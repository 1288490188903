import { Injectable } from '@angular/core';
import { LocalizedString } from 'core/modules/i18n/i18n.service';

export interface SeoMeta {
  title?: LocalizedString | string;
  description?: LocalizedString | string;
  imageAlt?: LocalizedString | string;
  image?: string;
  canonicalPath?: string;
  imageSize?: [string, string];
}

@Injectable()
export abstract class SeoService {
  abstract set(meta?: SeoMeta): void;
}
