<div [ngStyle]="{ 'background-image': backgroundImage }" [class]="'img-holder ' + level || ''">
  <div class="cover-logo"></div>
  <div
    [ngClass]="isAvailable ? 'available' : isWithdrawn ? 'withdraw' : isSold ? 'sold' : 'taken'"
    class="status"></div>
</div>
<div *ngIf="titleSecondary || title" class="content">
  <p [class.singleEllipse]="ref.childNodes.length !== 1" class="title">{{ title }}</p>
  <p #ref [class.singleEllipse]="ref.childNodes.length !== 1" class="title-secondary">
    <ng-content></ng-content>
  </p>
</div>
