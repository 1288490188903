<app-wishlist-table-header
  [hasItems]="favorites.length !== 0"
  [headers]="headers"
  [lockPadding]="15"
  scrollLock>
  <button
    (click)="checkBulk(checkAllFormControl.value)"
    *ngIf="favorites.length"
    [formControl]="checkAllFormControl"
    appCheckbox
    class="column primary select-all"></button>
</app-wishlist-table-header>

<div class="street-list">
  <ng-container *ngIf="favorites.length">
    <app-wishlist-item
      (emitDeleting)="removeSingle($event)"
      (setChecked)="setCheckedItems($event)"
      *ngFor="let s of favorites; trackBy: identifyFavorite"
      [isChecked]="!!selectedItems.get(s.id)"
      [street]="s"
      class="margin-bottom"></app-wishlist-item>
  </ng-container>

  <ng-container *ngIf="!favorites.length && inProgress">
    <app-wishlist-item
      *ngFor="let s of skeletonItems; trackBy: identifyFavorite"
      [street]="s"
      class="margin-bottom skeleton"></app-wishlist-item>
  </ng-container>

  <ng-container *ngIf="!favorites.length && !inProgress">
    <app-no-data
      [canRedirect]="false"
      [text]="'dashboard.wishlist.empty.text' | translate"
      [title]="'dashboard.wishlist.empty.title' | translate"
      class="darken no-data-item"></app-no-data>
  </ng-container>
</div>

<button
  (click)="bulkRemove()"
  [disabled]="selectedItems.size === 0"
  appButton
  class="solid withdraw-button margin-top-hg">
  {{ 'dashboard.wishlist.button.remove' | translate }}
</button>

<app-pagination
  (changed)="applyPage($event)"
  *ngIf="pagination.totalPages && pagination.totalPages > 1"
  [current]="pagination.pageNumber || 1"
  [total]="pagination.totalPages"
  class="margin-top-lg">
  <ng-template let-current="isCurrent" let-page>
    <button (click)="applyPage(page.value)" [ngClass]="{ active: current }" class="page">
      {{ page.label }}
    </button>
  </ng-template>
</app-pagination>
