<div class="data-container container-column dark">
   <div class="scroller-hider">
    <div class="tabs" appTabsContainer activeClass="active" mediaCondition="small only">
      <button
        [routerLink]="[appNav.marketplaceNav.marketplaceStreets()]"
        routerLinkActive="active"
        [ripple]="false"
        appButton
        appTabItem
        activeClass="active"
        class="tab">

        {{ 'dashboard.assets.button.streets' | translate }}
      </button>
      <button
        [routerLink]="[appNav.marketplaceNav.marketplaceBuildings()]"
        routerLinkActive="active"
        [ripple]="false"
        appButton
        appTabItem
        activeClass="active"
        class="tab">
        {{ 'dashboard.assets.button.buildings' | translate }}

      </button>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>
