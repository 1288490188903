import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageTO } from 'api/models';
import { BasePageComponent } from 'app/_main/modules/components/base-page/base-page.component';
import { appNav, lazyModalsNav } from 'app/app.navigation';
import { SortHeaderItem } from 'core/components/list-components/sorting.model';
import { ModalRouter } from 'core/modules/modal';
import { StreetPreviewModal } from 'dashboard/dashboard.navigation';
import { NftFilterForm } from 'dashboard/forms/nft-filter.form';
import { DwrldOrder } from 'dashboard/models/dwrld-order.model';
import { DwrldOrdersService } from 'dashboard/services/dwrld-orders.service';
import { PageControllerService } from 'dashboard/services/page-controller.service';
import { StreetsService } from 'dashboard/services/streets.service';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-offers-received-markets-page',
  templateUrl: './offers-received-markets-page.component.html',
  styleUrls: [
    './offers-received-markets-page.component.scss',
    '../../../../../dashboard/components/_styles/dashboard-layout.scss',
  ],
  providers: [
    DwrldOrdersService,
    {
      provide: PageControllerService,
      useExisting: DwrldOrdersService,
      multi: false,
    },
  ],
})
export class OffersReceivedMarketsPageComponent
  extends BasePageComponent
  implements OnInit, OnDestroy
{
  appNav = appNav;
  nftFilterForm = new NftFilterForm();
  orders: DwrldOrder[] = [];
  pagination: PageTO = {};
  headers: SortHeaderItem[] = [
    {
      label: $t('dashboard.markets.selling.column.asset'),
      key: 'street',
      itemClass: 'markets-asset',
    },
    {
      label: $t('dashboard.markets.selling.column.city'),
      key: 'city',
      itemClass: 'markets-city',
    },
    {
      label: $t('dashboard.markets.selling.column.country'),
      key: 'country',
      itemClass: 'markets-country',
    },
    {
      label: $t('dashboard.markets.selling.column.level'),
      key: 'zone',
      itemClass: 'markets-level',
    },
    {
      label: $t('dashboard.markets.selling.column.request'),
      key: 'sellPrice',
      itemClass: 'markets-request',
    },
    {
      label: $t('dashboard.markets.selling.column.bid'),
      key: 'buyingPrice',
      itemClass: 'markets-max-bid',
    },
    {
      label: '',
      itemClass: 'markets-action',
    },
  ];
  inProgress = false;

  constructor(
    private dwrldOrdersService: DwrldOrdersService,
    private streetService: StreetsService,
    private modal: ModalRouter
  ) {
    super();
    this.setTopOffset(120);
  }

  ngOnInit(): void {
    this.inProgress = true;
    this.dwrldOrdersService
      .initQueryParamsListener()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(data => {
        this.pagination = { ...data.pagination };
        this.pagination.pageNumber = (this.pagination?.pageNumber || 0) + 1;
      });

    this.dwrldOrdersService
      .getMarketData('BUY_RECEIVED')
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        data => {
          this.orders = data.list || [];
          this.pagination = data.pageInfo ? { ...data.pageInfo } : {};
          this.pagination.pageNumber = (this.pagination?.pageNumber || 0) + 1;
          this.inProgress = false;
        },
        () => {
          this.inProgress = false;
        }
      );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.dwrldOrdersService.clearParams();
  }

  nextPageAction(pageNumber: number): void {
    this.dwrldOrdersService.setPaginationParams({ ...this.pagination, pageNumber: pageNumber - 1 });
    this.scrollToTop();
  }

  identifyMarket(_: number, item: DwrldOrder): number {
    return item.orderId;
  }

  sellOrder(order: DwrldOrder): void {
    this.streetService
      .findByNFTMarket({ streetId: order.streetId })
      .pipe(takeUntil(this.destroy$))
      .subscribe(street => {
        this.configurePaginationAfterDecrementing(order.orderId);
        this.modal.navigate(lazyModalsNav.streets.streetSell(), undefined, {
          data: {
            entity: street,
            isMarket: true,
          } as StreetPreviewModal,
        });
      });
  }

  cancelOrder(orderId: number): void {
    this.dwrldOrdersService
      .closeOrder(orderId)
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(() => {
        this.configurePaginationAfterDecrementing(orderId);
      });
  }

  detachOrder(order: DwrldOrder): void {
    this.dwrldOrdersService
      .detachNFTFromCollection(order.collectionId, order.nftId)
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(() => {
        order.collectionId = 0;
      });
  }

  configurePaginationAfterDecrementing(orderId: number): void {
    if (
      !this.orders.filter(item => !(item.orderId === orderId)).length &&
      (this.pagination?.totalPages || 0) > 1
    ) {
      this.nextPageAction((this.pagination?.pageNumber || 1) - 1);
    }
  }
}
