import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { GenericAbstractControl } from 'core/utils/form-generics';
import { validate } from 'multicoin-address-validator';

export class AppValidators {
  static spacePattern = /\s/gim;
  static emailPattern = /^\S+@\S+\.\S+/;
  static digitPattern = /\d/;
  static lowercasePattern = /[a-z]/;
  static uppercasePattern = /[A-Z]/;
  static eosAccountNamePattern = /^[a-z0-9.]{1,12}$/g;

  static walletAddress(blockchainNetworkName: string, currencyCode: string): ValidatorFn {
    return (control: AbstractControl) => {
      const networkControl = control.parent?.get(blockchainNetworkName);

      if (networkControl) {
        try {
          const isUnavailableCode = currencyCode === 'DWRLD' || currencyCode === 'WAX';

          const isValidAddress = validate(
            control.value,
            isUnavailableCode ? 'EOS' : networkControl.value,
            {
              networkType: (networkControl.value as string)?.toLowerCase(),
            }
          );
          return isValidAddress
            ? null
            : {
                walletAddress: true,
              };
        } catch (error) {
          return null;
        }
      }
      return null;
    };
  }

  static exactLength(length = 12): ValidatorFn {
    return (control: AbstractControl) =>
      (control.value as unknown as string)?.toString().length !== length
        ? { exactLength: $t('shared.validation.exact_length', [length]) }
        : null;
  }

  static emptyMessage(control: AbstractControl): { [key: string]: boolean } | null {
    if (!control.value) {
      return null;
    }
    const isMessageCorrect: string = control.value.replace(AppValidators.spacePattern, '');
    return !isMessageCorrect ? { emptyMessage: true } : null;
  }

  static email(control: AbstractControl): { [key: string]: boolean } | null {
    if (!control.value) {
      return null;
    }
    const isCorrectEmail = AppValidators.emailPattern.test(control.value);
    return !isCorrectEmail ? { email: true } : null;
  }

  static eosAccountName(): ValidatorFn {
    return (control: AbstractControl) =>
      (control.value as string)?.toString().search(AppValidators.eosAccountNamePattern) === -1 ||
      control.value === undefined ||
      control.value === null
        ? { eosAccountName: $t('shared.validation.eos-account-name-regexp') }
        : null;
  }

  static selfEmail(selfEmail = ''): ValidatorFn {
    return (control: AbstractControl) =>
      (control.value as string)?.toString() === selfEmail
        ? { selfEmail: $t('shared.validation.selfEmail') }
        : null;
  }

  static password(control: AbstractControl): { [key: string]: unknown } | null {
    if (!control.value) {
      return null;
    }
    if (!AppValidators.lowercasePattern.test(control.value)) {
      return { passwordsPattern: $t('shared.validation.passwordsLowercase') };
    } else if (!AppValidators.uppercasePattern.test(control.value)) {
      return { passwordsPattern: $t('shared.validation.passwordsUppercase') };
    } else if (!AppValidators.digitPattern.test(control.value)) {
      return { passwordsPattern: $t('shared.validation.passwordsDigit') };
    } else if (typeof control.value === 'string' && control.value.length < 8) {
      return { passwordsPattern: $t('shared.validation.passwordsLength') };
    }
    return null;
  }

  static passwordsMatch(options: { pass: AbstractControl; confirm: AbstractControl }): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        options.pass.value &&
        options.confirm.value &&
        options.pass.value !== options.confirm.value
      ) {
        if (control === options.confirm) {
          return { passwordsNotMatch: $t('shared.validation.passwordsNotMatch') };
        } else {
          const errors = options.confirm.errors || {};
          errors.passwordsNotMatch = $t('shared.validation.passwordsNotMatch');
          options.confirm.setErrors(errors);
          return null;
        }
      } else if (control !== options.confirm) {
        const errors = options.confirm.errors;
        delete errors?.passwordsNotMatch;
        options.confirm.setErrors(errors);
      }
      return null;
    };
  }

  static maxLength(max = 100): ValidatorFn {
    return (control: AbstractControl) =>
      (control.value as unknown as string)?.toString().length > max
        ? { maxLength: $t('shared.validation.less_than_max', [max]) }
        : null;
  }

  static minLength(min = 3): ValidatorFn {
    return (control: AbstractControl) =>
      (control.value as unknown as string)?.toString().length < min
        ? { maxLength: $t('shared.validation.more_than_min', [min]) }
        : null;
  }

  static notSelfDepositAddress(address: string, memoControlName: string, memo = ''): ValidatorFn {
    return (control: AbstractControl) => {
      if (memo) {
        const memoControl = control.parent?.get(memoControlName);
        if (memoControl) {
          return (control.value as unknown as string)?.trim().toLowerCase() ===
            address?.trim().toLowerCase() &&
            (memoControl.value as unknown as string)?.trim().toLowerCase() ===
              memo?.trim().toLowerCase()
            ? { notSelfAddress: $t('shared.validation.not_self_deposit_address', [address]) }
            : null;
        }

        return null;
      } else {
        return (control.value as unknown as string)?.trim().toLowerCase() ===
          address?.trim().toLowerCase()
          ? { notSelfAddress: $t('shared.validation.not_self_deposit_address', [address]) }
          : null;
      }
    };
  }

  static notSelf(equal: string, i18nKey: string): ValidatorFn {
    return (control: AbstractControl) =>
      (control.value as unknown as string)?.trim().toLowerCase() === equal.trim().toLowerCase()
        ? { maxLength: $t(i18nKey, [equal]) }
        : null;
  }

  static maxBalanceAmount(
    max: number | { available: number },
    maxWalletAmount?: boolean,
    feeAmount = 0
  ): ValidatorFn {
    return (control: AbstractControl) => {
      const maxValue = typeof max === 'number' ? max : max.available;
      return +control.value * (1 + feeAmount / 100) > maxValue
        ? {
            maxBalanceAmount: maxWalletAmount
              ? $t('shared.validation.insufficient_balance', [maxValue])
              : $t('shared.validation.maximum_amount_exceeded', [maxValue]),
          }
        : null;
    };
  }

  static twoFactorVerification(): ValidatorFn {
    return (
      control: GenericAbstractControl<{ isComplete: boolean; value: number | null }>
    ): ValidationErrors | null =>
      control.value?.isComplete
        ? null
        : { twoFactorInvalid: $t('shared.validation.invalid_field') };
  }

  static minBidAmount(buyPrice: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (buyPrice) {
        const isInvalid = Number(control.value) === buyPrice;
        return isInvalid
          ? {
              minBidAmount: $t('shared.validation.min_offer_price_amount'),
            }
          : null;
      }

      return null;
    };
  }

  static minAmount(min = 0, errors: { emptyAmount?: LocalizedString } = {}): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value && control.value !== 0) {
        return { required: errors.emptyAmount || $t('shared.validation.required') };
      }
      if (+control.value === 0 && min === 0) {
        return { minAmount: $t('shared.validation.greater_than_zero') };
      }
      return +control.value < min
        ? { minAmount: $t('shared.validation.greater_than_min', [min]) }
        : null;
    };
  }

  static username(): ValidatorFn {
    return Validators.compose([
      Validators.required,
      AppValidators.maxLength(16),
      AppValidators.minLength(4),
      Validators.pattern(/^[A-Za-z0-9]+$/),
      AppValidators.blacklist([
        'decentworld',
        'dwrld',
        'user',
        'dworld',
        'decentdwrld',
        'decdwrld',
        'decentdworld',
        'dwrlddecent',
        'dworlddecent',
      ]),
    ]) as ValidatorFn;
  }

  static blacklist(blacklist: string[]): ValidatorFn {
    return (control: GenericAbstractControl<string>): ValidationErrors | null =>
      blacklist.filter(s => control.value.toLowerCase() === s).length
        ? { forbiddenName: $t('shared.validation.forbidden_name') }
        : null;
  }

  static url(domains: string[]): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }
      const result = new RegExp(domains.join('|')).test(control.value);
      if (control.value && !result) {
        return {
          invalidUrl: $t('shared.validation.invalid_url', [
            domains[0],
            domains[1] || '',
            domains[2] || '',
          ]),
        };
      }
      return null;
    };
  }

  static attachedCount(count: number): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }
      const images: Blob[] = control.value as Blob[];
      if (images.length > count) {
        return {
          invalidAttachedCount: $t('shared.validation.to_much_attached', [count]),
        };
      }

      return null;
    };
  }

  static attachedFileSize(size: number): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }
      const images: Blob[] = control.value as Blob[];
      for (const item of images) {
        if (item.size > size) {
          return {
            invalidAttachedSize: $t('shared.validation.attached_file_size', [size / 1000000]),
          };
        }
      }

      return null;
    };
  }
}
