import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  Output,
} from '@angular/core';
import { fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { exhaustMap, filter, map, pairwise } from 'rxjs/operators';

interface ScrollPosition {
  sH: number;
  sT: number;
  cH: number;
}

@Directive({
  selector: '[appInfiniteScroll]',
})
export class InfiniteScrollDirective implements AfterViewInit, OnDestroy {
  @Input()
  nextPageOffset = 100;

  @Input()
  observeWindow = false;

  @Input()
  direction: 'down' | 'up' = 'down';

  private nearEndSubj = new Subject<() => void>();
  @Output()
  scrollNearEnd = this.nearEndSubj.asObservable();

  private scrollEvent!: Observable<Event>;
  private scrollEventPipe!: Observable<[ScrollPosition, ScrollPosition]>;
  private scrollElement!: HTMLElement;
  private subscription = new Subscription();

  private isUserScrolling = (positions: [ScrollPosition, ScrollPosition]) =>
    this.direction === 'up' ? positions[0].sT > positions[1].sT : positions[0].sT < positions[1].sT;

  private isOffsetReached = (position: ScrollPosition) =>
    this.direction === 'up'
      ? position.sT < this.nextPageOffset
      : position.sT + position.cH > position.sH - this.nextPageOffset;

  constructor(private elm: ElementRef<HTMLElement>, private zone: NgZone) {}

  ngAfterViewInit(): void {
    this.scrollElement = this.elm.nativeElement;
    this.registerScrollEvent();
    this.requestCallbackOnScroll();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private registerScrollEvent(): void {
    if (this.observeWindow) {
      this.scrollEvent = fromEvent(window, 'scroll', { passive: true });
      this.streamWindowScrollEvents();
    } else {
      this.scrollEvent = fromEvent(this.scrollElement, 'scroll', { passive: true });
      this.streamElementScrollEvents();
    }
  }

  private streamElementScrollEvents(): void {
    this.scrollEventPipe = this.scrollEvent.pipe(
      map(
        (): ScrollPosition => ({
          sH: this.scrollElement.scrollHeight,
          sT: this.scrollElement.scrollTop,
          cH: this.scrollElement.clientHeight,
        })
      ),
      pairwise(),
      filter(positions => this.isUserScrolling(positions) && this.isOffsetReached(positions[1]))
    );
  }

  private streamWindowScrollEvents(): void {
    this.scrollEventPipe = this.scrollEvent.pipe(
      map(
        (): ScrollPosition => ({
          sH: this.scrollElement.scrollHeight,
          sT: window.scrollY,
          cH: window.screen.height,
        })
      ),
      pairwise(),
      filter(positions => this.isUserScrolling(positions) && this.isOffsetReached(positions[1]))
    );
  }

  private requestCallbackOnScroll(): void {
    this.subscription = this.scrollEventPipe
      .pipe(
        exhaustMap(() => {
          const sub = new Subject();
          // TODO: run zone optionally, only if scroll event is excluded from zone
          this.zone.run(() => {
            this.nearEndSubj.next(() => {
              sub.complete();
            });
          });
          return sub.asObservable();
        })
      )
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(() => {
        this.subscription.unsubscribe();
        this.requestCallbackOnScroll();
      });
  }
}
