<div @tooltip class="content frequency">
  <div class="frequency-item">
    <span class="title">{{ 'collection.frequency-tooltip.title-1' | translate }}</span>
    <span class="value">{{ 'collection.frequency-tooltip.value-1' | translate }}</span>
  </div>
  <div class="frequency-item">
    <span class="title">{{ 'collection.frequency-tooltip.title-2' | translate }}</span>
    <span class="value">{{ 'collection.frequency-tooltip.value-2' | translate }}</span>
  </div>
  <div class="frequency-item">
    <span class="title">{{ 'collection.frequency-tooltip.title-3' | translate }}</span>
    <span class="value">{{ 'collection.frequency-tooltip.value-3' | translate }}</span>
  </div>
</div>
