<app-header-menu
  (closing)="toggleMobileMenu()"
  (logout)="logout()"
  (mouseup)="stopClose()"
  (openNotifications)="openNotifications()"
  (openProfile)="openProfile()"
  *mediaIf="'small only'"
  [ngClass]="{ active: showMobileMenu }"></app-header-menu>

<ng-container
  *ngIf="(bonusService.isFirstDeposit || isSmallScreen || !auth.isAuth) && !hideBonusScreen">
  <div
    class="promo-container"
    (click)="openDepositModal()"
    appOnInit
    (initialized)="setBonusHeader()"
    (destroyed)="setDefaultHeader()"
    *ngIf="bonusService.bonusChanged | async as x">
    <span class="promo-label">
      Make your
      <span class="promo-bold">
        {{ bonusService.isFirstDeposit || !auth.isAuth ? 'FIRST' : 'NEXT' }} top-up
      </span>
      and receive
      <span class class="promo-amount" [ngClass]="{ highlighted: +x >= 3 }">{{ x }}x</span>
      bonus!
    </span>
  </div>
</ng-container>

<div class="header-container">
  <a *ngIf="largeLogo; else mediumTmpl" [routerLink]="appNav.initial()">
    <img class="logo" src="assets/svg/logo-white.svg" style="max-width: 198px" />
  </a>
  <ng-template #mediumTmpl>
    <ng-container *ngIf="auth.isAuth; else mediumGuestTmpl">
      <app-icon
        (click)="toggleMobileMenu()"
        *mediaIf="'small only'"
        [icon]="menuIcon"
        class="margin-right-lg xhg color-white cursor-pointer"></app-icon>

      <a *mediaIf="'medium up'" [routerLink]="appNav.initial()" style="min-width: 30px">
        <img src="assets/svg/logo-small.svg" style="max-width: 30px" />
      </a>
    </ng-container>
    <ng-template #mediumGuestTmpl>
      <app-icon
        (click)="toggleMobileMenu()"
        *mediaIf="'small only'"
        [icon]="menuIcon"
        class="margin-right-lg xhg color-white cursor-pointer"></app-icon>

      <a *mediaIf="'medium only'" [routerLink]="appNav.initial()" style="min-width: 30px">
        <img src="assets/svg/logo-small.svg" style="max-width: 30px" />
      </a>
      <a *mediaIf="'large'" [routerLink]="appNav.initial()">
        <img class="logo" src="assets/svg/logo-white.svg" style="max-width: 198px" />
      </a>
    </ng-template>
  </ng-template>
  <ng-container *ngIf="auth.isAuth; else guestTmpl">
    <div *mediaIf="'medium'" class="dcoin-header" [class.dropdowns-btn-section]="!largeHeader">
      <ng-container *ngIf="largeHeader; else smallHeader">
        <button
          *ngFor="let item of navigationLinks; let i = index"
          [appClearButton]="item.icon"
          [routerLinkActiveOptions]="{ exact: item.isExact }"
          [routerLink]="item.link"
          class="primary-light icon-md weight-medium margin-right-lg"
          routerLinkActive="active">
          <div class="display-flex align-center">
            {{ item.text | translate }}
            <span *ngIf="item.isNew" class="item-new margin-left-tn">
              {{ 'main.header.new' | translate }}
            </span>
          </div>
        </button>
        <!-- <button
          *envIf="'dev'"
          [appClearButton]="exchangeIcon"
          [routerLinkActiveOptions]="{ exact: false }"
          [routerLink]="appNav.exchangeNav.exchangeMain()"
          class="primary-light icon-md weight-medium margin-right-lg"
          routerLinkActive="active">
          <div class="display-flex align-center">
            {{ 'main.header.exchange' | translate }}
          </div>
        </button> -->
      </ng-container>

      <ng-template #smallHeader>
        <app-menu [align]="'start'" [minWidth]="280" [theme]="'dark'" [offsetY]="10">
          <app-html-input appAnchor class="solid primary-dark" extras="dropdown" type="false">
            <div class="dropdown-active">
              <app-icon
                [icon]="navigationLinks[selectedLink].icon"
                class="xhg margin-right"></app-icon>
              {{ navigationLinks[selectedLink].text | translate }}
            </div>
          </app-html-input>
          <ng-template>
            <div class="currency-data-host data-host">
              <button
                *ngFor="let item of navigationLinks; let i = index"
                [appClearButton]="item.icon"
                [routerLinkActiveOptions]="{ exact: item.isExact }"
                [routerLink]="item.link"
                (click)="selectedLink = i"
                class="primary-light icon-lg menu-btn"
                routerLinkActive="active">
                {{ item.text | translate }}
              </button>
              <!-- <button
                *envIf="'dev'"
                [appClearButton]="exchangeIcon"
                [routerLinkActiveOptions]="{ exact: false }"
                [routerLink]="appNav.exchangeNav.exchangeMain()"
                class="primary-light icon-lg menu-btn"
                routerLinkActive="active">
                {{ 'main.header.exchange' | translate }}
              </button> -->
            </div>
          </ng-template>
        </app-menu>
      </ng-template>
    </div>

    <ng-container *ngIf="isExchangePage">
      <div *mediaIf="'small only'" class="width-100">
        <app-exchange-statistic-selector
          [currentMarket]="exchangeMarketsService.currentMarket"
          [marketsList]="exchangeMarketsService.markets"
          (changeMarketEmitter)="changeMarket($event)"></app-exchange-statistic-selector>
      </div>
    </ng-container>

    <div class="buttons-section">
      <div class="markets-list" *ngIf="!isExchangePage">
        <button
          appButton
          class="solid warn small margin-right"
          *mediaIf="'medium'"
          (click)="openBuyDwrld()">
          Buy DWRLD
        </button>
        <div class="currency-tmp" [class.active]="currencyMenu.active">
          <div class="currency-block">
            <app-menu
              #currencyMenu="app-menu"
              [align]="'center'"
              [minWidth]="280"
              [theme]="'dark'"
              [offsetY]="20"
              [overlayHostClass]="'contain-screen'"
              [useHover]="150">
              <div appAnchor class="default-currency">
                <app-svg
                  [asset]="['currency', defaultCurrency.currency.code]"
                  class="round minify currency small margin-right"></app-svg>
                <div>
                  {{ defaultCurrency.available | number: defaultCurrency.currency.precisionArgs }}
                </div>
              </div>
              <ng-template>
                <div class="currency-data-host data-host">
                  <ng-container *ngFor="let wallet of walletsService.wallets">
                    <div
                      (click)="changeDefaultCurrency(wallet); currencyMenu.close()"
                      *ngIf="wallet.available !== 0"
                      class="wallet-item">
                      <app-svg
                        [asset]="['currency', wallet.currency.code | lowercase]"
                        class="round minify currency small logo"></app-svg>
                      <div class="currency-name">{{ wallet.currency.code }}</div>
                      <div class="currency-value">
                        ({{ wallet.available | number: wallet.currency.precisionArgs }})
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div class="currency-footer">
                  <button
                    [appButton]="cashierIcon"
                    [routerLinkActiveOptions]="{ exact: true }"
                    [routerLink]="appNav.cashierNav.myBalances()"
                    class="solid primary medium width-100"
                    routerLinkActive="active">
                    <span class="margin-left-lg">
                      {{ 'main.header.go-to-wallets' | translate }}
                    </span>
                  </button>

                  <!-- <button
                    *envIf="'dev'"
                    [appButton]="exchangeIcon"
                    [routerLinkActiveOptions]="{ exact: true }"
                    [routerLink]="appNav.exchangeNav.exchangeMain()"
                    class="solid warn medium width-100 margin-top"
                    routerLinkActive="active">
                    <span class="margin-left-lg">
                      {{ 'main.header.exchange' | translate }}
                    </span>
                  </button> -->

                  <button
                    appClearButton
                    class="solid medium width-100 crypto-wallets-button"
                    (click)="connectToCryptoAction()">
                    <span class="margin-left-lg">
                      {{ 'main.header.connect-to-crypto' | translate }}
                    </span>
                  </button>
                </div>
              </ng-template>
            </app-menu>
          </div>

          <div class="deposit">
            <button
              [appClearButton]="addSquare"
              class="solid primary medium"
              [disabled]="defaultCurrency.isBonus"
              (click)="openDepositAction()"></button>
          </div>
        </div>
      </div>

      <div *mediaIf="'small only'">
        <ng-container *ngIf="isExchangePage">
          <button
            [appClearButton]="cashierIcon"
            [routerLinkActiveOptions]="{ exact: false }"
            [routerLink]="appNav.cashierNav.myBalances()"
            class="primary-light icon-md menu-btn"
            routerLinkActive="active"></button>
        </ng-container>
      </div>
      <app-menu
        *mediaIf="'medium'"
        #userMenu="app-menu"
        [align]="'center'"
        [minWidth]="280"
        [theme]="'dark'"
        [offsetY]="20"
        [useHover]="150">
        <div appAnchor class="user-data">
          <span class="avatar-overlay">
            <app-icon [icon]="profileIcon"></app-icon>
          </span>
          <app-user-avatar [logoUrl]="userService.user.logoUrl" class="small"></app-user-avatar>
        </div>

        <ng-template>
          <div class="user-menu-host data-host">
            <div
              class="user-menu-item"
              (click)="router.navigateByUrl(appNav.profileNav.root()); userMenu.close()">
              <button class="user-menu-icon" [appClearButton]="userIcon"></button>

              <div class="value">{{ 'profile.tabs.profile' | translate }}</div>
            </div>

            <div
              class="user-menu-item"
              (click)="
                router.navigateByUrl(appNav.profileNav.privacyAndSecurity()); userMenu.close()
              ">
              <button class="user-menu-icon" [appClearButton]="settingsCircleIcon"></button>

              <div class="value">{{ 'main.header.settings' | translate }}</div>
            </div>

            <div
              class="user-menu-item"
              (click)="router.navigateByUrl(appNav.profileNav.avatar()); userMenu.close()">
              <button class="user-menu-icon" [appClearButton]="avatarIcon"></button>

              <div class="value">{{ 'main.header.avatar' | translate }}</div>
            </div>

            <div class="user-menu-item" (click)="openNotifications(); userMenu.close()">
              <button class="user-menu-icon" [appClearButton]="bellIcon">
                <app-badge *ngIf="notificationService.data.ALL.unread !== 0" class="pulse notify">
                  {{ notificationService.data.ALL.unread }}
                </app-badge>
              </button>

              <div class="value">{{ 'profile.tabs.notifications' | translate }}</div>
            </div>
            <div
              class="user-menu-item"
              (click)="router.navigateByUrl(appNav.commonNav.help()); userMenu.close()">
              <button class="user-menu-icon" [appClearButton]="helpIcon"></button>

              <div class="value">{{ 'main.faq.support.service' | translate }}</div>
            </div>
          </div>

          <div class="user-menu-footer">
            <div class="user-menu-item" (click)="logout()">
              <app-icon class="user-menu-icon" [icon]="logoutIcon"></app-icon>
              <div class="value">{{ 'main.header.logout' | translate }}</div>
            </div>
          </div>
        </ng-template>
        <app-badge *ngIf="notificationService.data.ALL.unread" class="pulse user-badge">
          {{ notificationService.data.ALL.unread }}
        </app-badge>
      </app-menu>

      <button
        (click)="openCheckout()"
        [appClearButton]="basketIcon"
        [class.active]="isCheckout"
        class="primary icon-md margin-left">
        <app-badge *ngIf="cartService.length" class="pulse notify">
          {{ cartService.length }}
        </app-badge>
      </button>
    </div>
  </ng-container>
  <ng-template #guestTmpl>
    <div *mediaIf="'medium'" class="dcoin-header">
      <button
        appClearButton
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="appNav.streetsNav.map()"
        class="primary-light link-btn icon-md weight-medium margin-right-lg"
        routerLinkActive="active">
        {{ 'main.header.map' | translate }}
      </button>
      <button
        appClearButton
        [routerLinkActiveOptions]="{ exact: false }"
        [routerLink]="appNav.marketplaceNav.marketplaceInit()"
        class="primary-light link-btn icon-md weight-medium margin-right-lg"
        routerLinkActive="active">
        {{ 'main.header.shop' | translate }}
      </button>
      <button
        appClearButton
        [routerLinkActiveOptions]="{ exact: false }"
        [routerLink]="appNav.landingNav.application()"
        class="primary-light link-btn icon-md weight-medium margin-right-lg"
        routerLinkActive="active">
        <div class="display-flex align-center">
          {{ 'main.header.games' | translate }}
        </div>
      </button>
      <button
        appClearButton
        [routerLinkActiveOptions]="{ exact: false }"
        [routerLink]="appNav.collectionsNav.allCollections()"
        class="primary-light link-btn icon-md weight-medium margin-right-lg"
        routerLinkActive="active">
        {{ 'main.header.staking' | translate }}
      </button>
    </div>

    <div class="buttons-section">

      <button
        id="header-sign-up-button"
        (click)="openSignIn()"
        appButton
        class="solid primary-contrast medium margin-left-auto quest-button">
        {{ 'main-landing.explore-section.sign-in' | translate }}
      </button>

<!--      <button-->
<!--          id="header-sign-in-button"-->
<!--          (click)="openSignUp()"-->
<!--          appButton-->
<!--          class="solid primary medium margin-left-auto quest-button">-->
<!--        {{ 'main.header.log-in' | translate }}-->
<!--      </button>-->

<!--      <button-->
<!--        id="header-discord-invite-button"-->
<!--        [appButton]="discordIcon"-->
<!--        class="solid discord medium margin-left-lg quest-button"-->
<!--        (click)="openDiscordInviteLink()">-->
<!--        Discord-->
<!--      </button>-->
    </div>
  </ng-template>
</div>
