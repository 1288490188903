<div
  *ngIf="streetImage.src; else simpleImage"
  [ngStyle]="{
    'background-image':
      'url(/assets/img/cover-bg.webp),' + streetImage.gradient + ',url(' + this.imageSrc + ')'
  }"
  class="main-image">
  <img class="cover-logo" src="/assets/img/cover-logo.png" />
</div>
<ng-template #simpleImage>
  <div [ngStyle]="{ 'background-image': 'url(' + this.imageSrc + ')' }" class="main-image"></div>
</ng-template>
<div *ngIf="showLevelString" class="cover-plan {{ streetImage.mainClass }}">
  <div class="cover-plan-wrapper {{ streetImage.coverClass }}"></div>
  <span>{{ streetImage.title }}</span>
</div>
<div *ngIf="showLogo" class="cover-background"></div>
<div class="images-background">
  <div [ngClass]="{ 'align-right': showSmallStatus && !showCoverSymbol }" class="top-images">
    <div *ngIf="showCoverSymbol" class="cover-symbolf">
      <img alt="cover" src="/assets/svg/CoverSymbol.svg" />
    </div>

    <div
      *ngIf="showSmallStatus && showSecondaryElements"
      [ngClass]="isWithdraw ? 'withdraw' : isAvailable ? 'available' : 'taken'"
      class="small-chip-status"></div>
    <div
      *ngIf="!showSmallStatus"
      [ngClass]="!level ? 'waiting' : isWithdraw ? 'withdraw' : isAvailable ? 'available' : 'taken'"
      class="chip-status">
      {{
        !level
          ? ('street.ui.street_image.status.not_minted' | translate)
          : isWithdraw
          ? ('street.modals.street_history.withdrawal' | translate)
          : isAvailable
          ? ('street.ui.street_image.status.available' | translate)
          : ('street.ui.street_image.status.taken' | translate)
      }}
    </div>
  </div>

  <div *ngIf="showLogo" class="bottom-images">
    <div [ngClass]="{ large: !streetImage.src }" class="logo">
      <img alt="Decent Logo" src="{{ streetImage.src || 'assets/svg/logo-white.svg' }}" />
    </div>

    <div *ngIf="level" class="icon-label {{ level }}">
      <app-icon [icon]="levels[level]?.icon" class="icon"></app-icon>
      <span class="level-title">{{ levels[level]?.title }}</span>
    </div>
  </div>
</div>
