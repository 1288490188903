import { Component, ElementRef, HostBinding, Input } from '@angular/core';
import { AppIcon, AppIconName } from 'core/icons/icon.type';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[appButton]',
  template: `
    <div
      *ngIf="ripple"
      matRipple
      class="mat-ripple"
      [matRippleTrigger]="elementRef.nativeElement"></div>
    <app-icon *ngIf="appButton" [icon]="appButton"></app-icon>
    <ng-content select="app-svg"></ng-content>
    <span class="content" [ngClass]="appButton ? 'with-icon' : ''">
      <ng-content></ng-content>
    </span>
    <ng-content select="app-badge"></ng-content>
  `,
  styleUrls: [
    './styles/solid.scss',
    './styles/frame.scss',
    './styles/tab.scss',
    './styles/underline.scss',
    './styles/toggle-button.scss',
    './styles/ripple.scss',
  ],
})
export class ButtonComponent {
  @Input()
  appButton: AppIconName | AppIcon = '';

  @Input()
  ripple = true;

  @Input()
  @HostBinding('attr.type')
  type = 'button';

  constructor(public elementRef: ElementRef<HTMLElement>) {}

  showSuccess(): void {
    // TODO:
  }
}
