import {Component, HostListener, Input, OnInit} from '@angular/core';
import { NftOrderHistoryTO } from 'api/models/nft-order-history-to';
import { lazyModalsNav } from 'app/app.navigation';
import { iconCircleLine } from 'core/icons/lib/icon-circle-line';
import { ModalRouter } from 'core/modules/modal';
import { StreetLevelType, zoneToLevel } from 'dashboard/models/street.data';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-history-item',
  templateUrl: './history-item.component.html',
  styleUrls: ['./history-item.component.scss'],
})
export class HistoryItemComponent {
  circleIcon = iconCircleLine;

  @Input()
  order: NftOrderHistoryTO = {};

  constructor(private modalRouter: ModalRouter) {}

  @HostListener('click')
  openStreetDetails(): void {
    this.modalRouter.navigate(lazyModalsNav.dashboard.history(), undefined, {
      data: this.order,
    });
  }

  get name(): string {
    return `${this.order.street} ${this.order.number}`;
  }

  get imageUrl(): string {
    if (!this.order.id) {
      return '';
    }

    let result = '';
    switch (this.order.nftType) {
      case 'STREET':
      case 'COLLECTION':
        result = `${environment.mainServiceUrl}/api/v1/gis/${this.order.nftId}/image/STREET/view`;
        break;
      case 'BUILDING':
        result = `${environment.mainServiceUrl}/api/v1/gis/${this.order.nftId}/image/BUILDING/view`;
    }
    return result;
  }

  get level(): StreetLevelType {
    return zoneToLevel(this.order.zoneType);
  }
}
