import { Provider } from '@angular/core';
import { SeoClientService } from 'core/seo/seo.client';
import { SeoService } from 'core/seo/seo.service';
import { MapService } from 'streets/services/map.service';
import { MapClientService } from 'streets/services/map-client.service';

export const providers = [
  { provide: MapService, useClass: MapClientService },
  { provide: SeoService, useClass: SeoClientService },
] as Provider[];
