import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import {iconLock} from "core/icons/lib/icon-lock";
import {iconOval} from "core/icons/lib/icon-oval";
import {iconEllipsisH} from "core/icons/lib/icon-ellipsis-h";
import {Street} from "dashboard/models/street.model";
import {Subject} from "rxjs";
import {ModalRouter} from "core/modules/modal";
import {UserService} from "profile/services/user.service";
import {StreetsService} from "dashboard/services/streets.service";
import {CartService} from "dashboard/services/cart.service";
import {lazyModalsNav} from "app/app.navigation";
import {mergeMap, switchMap, takeUntil, tap} from "rxjs/operators";
import {StreetPreviewModal} from "dashboard/dashboard.navigation";
import { iconStar } from 'core/icons/lib/icon-start';
import { iconCart } from 'core/icons/lib/icon-cart';
import { iconBagCross } from 'core/icons/lib/icon-bag-cross';
import {Building} from "dashboard/models/building.model";
import {WishlistBuildingsService} from "app/marketplace/services/wishlist-buildings.service";

@Component({
  selector: 'app-marketplace-building-item',
  templateUrl: './marketplace-building-item.component.html',
  styleUrls: ['./marketplace-building-item.component.scss']
})
export class MarketplaceBuildingItemComponent  {
  readonly iconLocked = iconLock;
  readonly spinIcon = iconOval;
  readonly iconStar = iconStar;
  readonly iconEllipsis = iconEllipsisH;
  readonly iconCart = iconCart;
  readonly iconBagCross = iconBagCross;

  @Input() building = new Building();

  @Output() toggleFavorite = new EventEmitter<Building>();

  @HostBinding('class.disabled')
  get inCollection(): boolean {
    return !!this.building.collectionId;
  }

  private destroy$ = new Subject<void>();

  constructor(
    private modal: ModalRouter,
    private userService: UserService,
    private streetService: StreetsService,
    private cartService: CartService,
    private wishlistService: WishlistBuildingsService,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  buyNow(event: MouseEvent): void {
    event.stopPropagation();
    if (!this.userService.user.id) {
      this.modal.navigate(lazyModalsNav.auth.signIn());
      return;
    }

    this.streetService
      .findByNFTMarket({ nftId: this.building.nft.id })
      .pipe(
        switchMap(() => this.streetService.findStreetOrMarket(this.building.streetId)),
        takeUntil(this.destroy$)
      )
      .subscribe((street) => {
        this.modal.navigate(lazyModalsNav.streets.buildingBuy(), undefined, {
          data: {
            entity: this.building,
            isBuilding: true,
            additionalEntity: street
          } as StreetPreviewModal,
        });
      });
  }

  get taken(): boolean {
    return !this.building.isAvailable || this.building.ownerName !== '';
  }

  sell(event: MouseEvent): void {
    event.stopPropagation();
   /* this.streetService
      .findByNFTMarket({ streetId: this.street.id })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.modal.navigate(lazyModalsNav.streets.streetSell(), undefined, {
          data: {
            entity: this.street,
            isMarket: !!this.street.buyPrice,
          } as StreetPreviewModal,
        });
      });*/
  }

  onToggleFavorite(event: MouseEvent): void {
    event.stopPropagation();
    if (!this.userService.user.id) {
      this.modal.navigate(lazyModalsNav.auth.signIn());
      return;
    }
    this.wishlistService.toggleFavorite(this.building);
  }

  removeFromBasket(event: MouseEvent): void {
    event.stopPropagation();
    if (!this.userService.user.id) {
      this.modal.navigate(lazyModalsNav.auth.signIn());
      return;
    }
    this.toggleBasketWithFreeStreet();
  }

  addToBasket(event: MouseEvent): void {
    event.stopPropagation();
    if (!this.userService.user.id) {
      this.modal.navigate(lazyModalsNav.auth.signIn());
      return;
    }
    this.toggleBasketWithFreeStreet();
  }

  toggleBasketWithFreeStreet(): void {
    this.streetService
    .findStreetOrMarket(this.building.streetId)
    .pipe(
        mergeMap(streetRes => streetRes?.marketId?
            (this.building.cartId? this.cartService.remove(this.building)
                : this.cartService.add(this.building))
            : this.building.cartId
                ? this.cartService.removeFromStreet(streetRes || new Street(), [this.building])
                : this.cartService.addToStreet(streetRes || new Street(), [this.building])
        ),
        takeUntil(this.destroy$)
    )
    .subscribe();
  }
}
