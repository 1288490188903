import { Component } from '@angular/core';
import { appNav } from 'app/app.navigation';
import { BonusService } from 'profile/services/bonus.service';

@Component({
  selector: 'app-marketplace-page',
  templateUrl: './marketplace-page.component.html',
  styleUrls: ['./marketplace-page.component.scss'],
})
export class MarketplacePageComponent {
  appNav = appNav;

  constructor(bonusService: BonusService) {
    bonusService.generateBonus();
  }
}
