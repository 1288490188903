/* eslint-disable prefer-arrow-functions/prefer-arrow-functions */

import { authModalsNav, authNav } from 'auth/auth.navigation';
import { cashierModalsNav, cashierNav } from 'cashier/cashier.navigation';
import { dashboardModalsNav, dashboardNav } from 'dashboard/dashboard.navigation';
import { environment } from 'environments/environment';
import { profileModalsNav, profileNav } from 'profile/profile.navigation';

import { commonModalsNav, commonNav } from './_main/main.navigation';
import { collectionsNav } from './collections/collections.navigation';
import { exchangeNav } from './exchange/exchange.navigation';
import { landingNav } from './landing/landing.navigation';
import { legalNav } from './legal/legal.navigation';
import { marketplaceNav } from './marketplace/marketplace.navigation';
import { miniGamesNav } from './mini-games/mini-games.navigation';
import { streetsModalsNav, streetsNav } from './streets/streets.navigation';
import { streetsCatalogNav } from './streets-catalog/streets-catalog.navigation';

export function seoRoot(): string {
  return '/';
}

export function initial(): string {
  return environment.ssr ? appNav.seoRoot() : dashboardNav.dashboard();
}

export function guestInitial(): string {
  return landingNav.root();
}

export const appNav = {
  initial,
  guestInitial,
  seoRoot,
  authNav,
  profileNav,
  dashboardNav,
  collectionsNav,
  cashierNav,
  commonNav,
  streetsNav,
  landingNav,
  legalNav,
  marketplaceNav,
  streetsCatalogNav,
  exchangeNav,
  miniGamesNav,
};

export const lazyModalsNav = {
  auth: authModalsNav,
  profile: profileModalsNav,
  cashier: cashierModalsNav,
  dashboard: dashboardModalsNav,
  common: commonModalsNav,
  streets: streetsModalsNav,
};
