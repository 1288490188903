/* eslint-disable prefer-arrow-functions/prefer-arrow-functions */
import { Location } from '@angular/common';
import { APP_INITIALIZER, Injectable, Provider } from '@angular/core';
import { appNav } from 'app/app.navigation';
import { MarketplaceMarketsService } from 'app/marketplace/services/marketplace-markets.service';
import { WishlistBuildingsService } from 'app/marketplace/services/wishlist-buildings.service';
import { WishlistStreetsService } from 'app/marketplace/services/wishlist-streets.service';
import { AuthService } from 'auth/services/auth.service';
import { JwtService } from 'auth/services/jwt.service';
import { MarketService } from 'cashier/services/market.service';
import { WalletsService } from 'cashier/services/wallets.service';
import { APIError } from 'core/models/error.model';
import { navigateOnError } from 'core/utils/rxjs/navigate-on-error.operator';
import { CartService } from 'dashboard/services/cart.service';
import { DashboardService } from 'dashboard/services/dashboard.service';
import { MysteryBoxService } from 'dashboard/services/mystery-box.service';
import { AccountsService } from 'profile/services/accounts.service';
import { forkJoin, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { GeolocationService } from 'streets/services/geolocation.service';

export function provideUserInitializer(): Provider {
  return {
    provide: APP_INITIALIZER,
    useFactory: userInitializerFactory,
    deps: [UserInitializerService, AuthService, JwtService, Location],
    multi: true,
  };
}

export function userInitializerFactory(
  initializer: UserInitializerService,
  auth: AuthService,
  jwt: JwtService,
  location: Location
): () => Promise<unknown> {
  auth.setupInitializer(initializer);
  return () =>
    new Observable(s => {
      const path = location.path(false);
      const params = new RegExp(/at=([a-z0-9._-]*)/gi).exec(path) || [];
      if (params.length) {
        jwt.setToken(params[1]);
      }
      if (!auth.isAuth) {
        s.complete();
      } else {
        auth
          .load()
          .pipe(mergeMap(() => initializer.load()))
          .subscribe({
            next: () => {
              s.complete();
            },
            error: err => {
              console.error(`User initialization error!`);
              if (err instanceof APIError && err.original.status === 401) {
                auth.clearSession();
              }
              s.complete();
            },
          });
      }
    }).toPromise();
}

@Injectable({ providedIn: 'root' })
export class UserInitializerService {
  constructor(
    private wallets: WalletsService,
    private cartService: CartService,
    private marketService: MarketService,
    private mysteryBoxService: MysteryBoxService,
    private accountsService: AccountsService,
    private favoritesStreetsService: WishlistStreetsService,
    private favoritesBuildingsService: WishlistBuildingsService,
    private geolocation: GeolocationService,
    private dashboardService: DashboardService,
    private marketplaceMarketsService: MarketplaceMarketsService
  ) {}

  load(): Observable<unknown> {
    // add any init requests, that will be executed when user is sign in/up
    // from any place (such email confirm or page refresh with valid token)
    // TODO: load with dashboard module
    this.dashboardService.observeDashboardStatisticEvents();
    this.dashboardService.loadStreetsProgressStatistic();
    // TODO: load with marketplace module
    this.marketplaceMarketsService.observeMarketStatisticEvents();
    this.marketplaceMarketsService.loadMarketStatistic();

    return forkJoin([
      this.favoritesStreetsService.load(),
      this.favoritesBuildingsService.load(),
      this.wallets.load(),
      this.wallets.getDefaultCurrency(),
      // TODO: load with wallet module
      this.wallets.loadDepositAddresses(),
      this.accountsService.load().pipe(mergeMap(() => this.cartService.load())),
      // TODO: load with wallet module
      this.marketService.load(),
      this.geolocation.initialize(),
      // TODO: load with dashboard module
      this.mysteryBoxService.load(),
    ]).pipe(navigateOnError(appNav.commonNav.serviceUnavailable()));
  }
}
