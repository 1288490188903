<div class="top">
  <app-icon (click)="closing.next()" [icon]="closeIcon" class="cursor-pointer"></app-icon>
  <ng-container *ngIf="auth.isAuth; else topGuestTmpl">
    <div class="display-flex align-center">
      <button
        [appClearButton]="helpIcon"
        [routerLinkActiveOptions]="{ exact: false }"
        [routerLink]="appNav.commonNav.help()"
        class="primary icon-md icon icon-md margin-right-hg"
        routerLinkActive="active"></button>
      <button
        (click)="openNotifications.next()"
        [appClearButton]="bellIcon"
        class="icon icon-md margin-right-hg">
        <app-badge *ngIf="notificationService.data.ALL.unread !== 0" class="pulse notify">
          {{ notificationService.data.ALL.unread }}
        </app-badge>
      </button>
      <app-icon
        (click)="openProfile.next()"
        [icon]="profileIcon"
        class="cursor-pointer margin-right-hg"></app-icon>
      <app-icon (click)="logout.next()" [icon]="logoutIcon" class="cursor-pointer"></app-icon>
    </div>
  </ng-container>
</div>
<div [routerLink]="appNav.initial()" class="logo">
  <div>
    <img src="assets/svg/logo-small.svg" style="max-width: 60px" />
  </div>
  <div class="margin-top-md">
    <img src="assets/svg/logo-text.svg" style="max-width: 200px" />
  </div>
</div>
<div *ngIf="auth.isAuth" class="user">
  <div
    [routerLink]="appNav.profileNav.root()"
    class="user-data margin-right-md"
    routerLinkActive="active">
    <span class="avatar-overlay">
      <app-icon [icon]="profileIcon"></app-icon>
    </span>
    <app-user-avatar [logoUrl]="userService.user.logoUrl" class="small"></app-user-avatar>
  </div>
  <div class="username">{{ userService.user.firstName }} {{ userService.user.lastName }}</div>
</div>

<div class="buttons">
  <ng-container *ngIf="auth.isAuth; else buttonsGuestTmpl">
    <button
      [appClearButton]="dashboardIcon"
      [routerLinkActiveOptions]="{ exact: false }"
      [routerLink]="appNav.dashboardNav.dashboard()"
      class="primary-light icon-md margin-top-lg"
      routerLinkActive="active">
      {{ 'main.header.dashboard' | translate }}
    </button>

    <button
      [appClearButton]="mapMarketIcon"
      [routerLinkActiveOptions]="{ exact: true }"
      [routerLink]="appNav.streetsNav.map()"
      class="primary-light icon-md margin-top-lg"
      routerLinkActive="active">
      {{ 'main.header.map' | translate }}
    </button>

    <button
      [appClearButton]="marketPlaceIcon"
      [routerLinkActiveOptions]="{ exact: false }"
      [routerLink]="appNav.marketplaceNav.marketplaceInit()"
      class="primary-light icon-md margin-top-lg"
      routerLinkActive="active">
      {{ 'main.header.marketplace' | translate }}
    </button>



    <button
      [appClearButton]="documentsIcon"
      [routerLinkActiveOptions]="{ exact: false }"
      [routerLink]="appNav.collectionsNav.allCollections()"
      class="primary-light icon-md margin-top-lg"
      routerLinkActive="active">
      {{ 'main.header.collections' | translate }}
    </button>

    <button
        [appClearButton]="metaverseIcon"
        [routerLinkActiveOptions]="{ exact: false }"
        [routerLink]="appNav.landingNav.application()"
        class="primary-light icon-md margin-top-lg"
        routerLinkActive="active">
      <div class="display-flex align-center">
        {{ 'main.header.metaverse' | translate }}
        <span class="item-new">{{ 'main.header.new' | translate }}</span>
      </div>
    </button>

    <button
      *envIf="'dev'"
      [appClearButton]="exchangeIcon"
      [routerLinkActiveOptions]="{ exact: false }"
      [routerLink]="appNav.exchangeNav.exchangeMain()"
      class="primary-light icon-md margin-top-lg"
      routerLinkActive="active">
      {{ 'main.header.exchange' | translate }}
    </button>

    <button
      [appClearButton]="helpIcon"
      [routerLinkActiveOptions]="{ exact: false }"
      [routerLink]="appNav.commonNav.help()"
      class="primary-light icon-md margin-top-lg"
      routerLinkActive="active">
      {{ 'main.header.help' | translate }}
    </button>
  </ng-container>
</div>

<ng-template #topGuestTmpl>
  <div class="display-flex align-center"></div>
</ng-template>
<ng-template #buttonsGuestTmpl>
  <button
    [appClearButton]="mapMarketIcon"
    [routerLinkActiveOptions]="{ exact: true }"
    [routerLink]="appNav.streetsNav.map()"
    class="primary-light icon-md margin-top-lg"
    routerLinkActive="active">
    {{ 'main.header.map' | translate }}
  </button>

  <button
    [appClearButton]="marketPlaceIcon"
    [routerLinkActiveOptions]="{ exact: false }"
    [routerLink]="appNav.marketplaceNav.marketplaceInit()"
    class="primary-light icon-md margin-top-lg"
    routerLinkActive="active">
    {{ 'main.header.marketplace' | translate }}
  </button>

  <button
      [appClearButton]="documentsIcon"
      [routerLinkActiveOptions]="{ exact: false }"
      [routerLink]="appNav.collectionsNav.allCollections()"
      class="primary-light icon-md margin-top-lg"
      routerLinkActive="active">
    {{ 'main.header.collections' | translate }}
  </button>

  <button
    [appClearButton]="metaverseIcon"
    [routerLinkActiveOptions]="{ exact: false }"
    [routerLink]="appNav.landingNav.application()"
    class="primary-light icon-md margin-top-lg"
    routerLinkActive="active">
    <div class="display-flex align-center">
      {{ 'main.header.metaverse' | translate }}
      <span class="item-new">{{ 'main.header.new' | translate }}</span>
    </div>
  </button>



  <button
    [appClearButton]="helpIcon"
    [routerLinkActiveOptions]="{ exact: false }"
    [routerLink]="appNav.commonNav.help()"
    class="primary-light icon-md margin-top-lg"
    routerLinkActive="active">
    {{ 'main.header.help' | translate }}
  </button>
</ng-template>
