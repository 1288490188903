import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-additional-info',
  template: `
    <div class="container-splitter margin-bottom-md">
      <span class="weight-semibold color-txt-bold">{{ title }}</span>
    </div>
    <ng-content></ng-content>
  `,
  styleUrls: ['./additional-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdditionalInfoComponent {
  @Input() title?: string;
}
