import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, Input, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { iconChevronLeft } from 'core/icons/lib/icon-chevron-left';
import { MediaQuery } from 'core/modules/platform/services/media-query.service';

import { DropdownBase } from './dropdown.base';
import { menuTransition } from './dropdown.transitions';

@Component({
  selector: 'app-menu',
  template: `
    <ng-content select="[appAnchor]"></ng-content>
    <ng-container *ngIf="appearance === 'full-screen'">
      <app-icon *mediaIf="'small only'" [icon]="iconArrowLeft" (click)="scheduleClose()"></app-icon>
    </ng-container>
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayMinWidth]="minWidth"
      [cdkConnectedOverlayWidth]="minWidth"
      [cdkConnectedOverlayPositions]="position"
      [cdkConnectedOverlayOrigin]="overlayOrigin.elementRef"
      [cdkConnectedOverlayOffsetY]="media.current === 'small' ? 0 : offsetY"
      [cdkConnectedOverlayOpen]="active">
      <div
        appOverlayHost
        class="{{ theme }} {{ appearance }} {{ overlayHostClass }} dropdown"
        [@menuTransition]="transitionState"
        (mousedown)="stopClose()">
        <ng-container *ngTemplateOutlet="tmplRef"></ng-container>
      </div>
    </ng-template>
    <ng-content></ng-content>
  `,
  animations: [menuTransition],
  styleUrls: ['./styles/select.scss'],
  exportAs: 'app-menu',
})
export class MenuComponent extends DropdownBase {
  @Input()
  overlayHostClass = '';

  iconArrowLeft = iconChevronLeft;

  constructor(
    public media: MediaQuery,
    renderer: Renderer2,
    router: Router,
    @Inject(DOCUMENT) document: Document,
    public elementRef: ElementRef<HTMLElement>
  ) {
    super(media, renderer, router, elementRef, document);
  }
}
