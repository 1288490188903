<div class="marketplace-header">
  <div class="scroll-hider" *ngIf="authService.isAuth">
    <div class="tab-section">
      <button
        [routerLink]="appNav.marketplaceNav.marketplaceInit()"
        appButton
        class="tab"
        [class.active]="isRootPage">
        {{ 'marketplace.button.marketplace' | translate }}
      </button>

      <ng-container *ngIf="totalMarkets | async as marketsData">
        <button
          [routerLinkActiveOptions]="{ exact: false }"
          [routerLink]="appNav.marketplaceNav.marketsRoot()"
          appButton
          class="tab"
          routerLinkActive="active"
          [class.active]="isWatchlistPage">
          <app-badge *ngIf="marketsData.totalMarkets !== 0" class="margin-left">
            {{ marketsData.totalMarkets }}
          </app-badge>
          {{ 'dashboard.button.markets' | translate }}
        </button>
      </ng-container>
      <!-- [routerLinkActiveOptions]="{ exact: false }" -->

      <button
        [routerLink]="appNav.marketplaceNav.wishlist()"
        [routerLinkActiveOptions]="{ exact: false }"
        appButton
        class="tab"
        routerLinkActive="active"
        [class.active]="isWatchlistPage">
        {{ 'dashboard.button.watchlist' | translate }}
      </button>

      <button
        [routerLinkActiveOptions]="{ exact: false }"
        [routerLink]="appNav.marketplaceNav.marketplaceHistory()"
        appButton
        class="tab"
        routerLinkActive="active">
        {{ 'dashboard.button.history' | translate }}
      </button>
    </div>
  </div>

  <app-marketplace-streets-statistic
    [ngClass]="{ 'to-right': authService.isAuth }"
    [statistic]="streetsStatistic"></app-marketplace-streets-statistic>
</div>
<router-outlet></router-outlet>
