import { Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { appNav } from 'app/app.navigation';
import { LegalParams } from 'app/legal/legal.navigation';
import { iconFacebook } from 'core/icons/lib/icon-facebook';
import { iconInstagram } from 'core/icons/lib/icon-instagram';
import { iconLinkedinCircled } from 'core/icons/lib/icon-linkedin-circled';
import { iconMacos } from 'core/icons/lib/icon-macos';
import { iconMedium } from 'core/icons/lib/icon-medium';
import { iconTelegram } from 'core/icons/lib/icon-telegram';
import { iconTumblr } from 'core/icons/lib/icon-tumblr';
import { iconTwitter } from 'core/icons/lib/icon-twitter';
import { iconWindows } from 'core/icons/lib/icon-windows';
import { AppUI } from 'core/services/app-ui.service';
import {GenericFormControl} from "core/utils/form-generics";
import {I18nService, LangUIItem} from "core/modules/i18n/i18n.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  readonly windowsIcon = iconWindows;
  readonly macosIcon = iconMacos;
  readonly twitterIcon = iconTwitter;
  readonly facebookIcon = iconFacebook;
  readonly instagramIcon = iconInstagram;
  readonly telegramIcon = iconTelegram;
  readonly iconLinkedin = iconLinkedinCircled;
  readonly iconMedium = iconMedium;
  readonly iconTumblr = iconTumblr;

  appNav = appNav;

  localization = this.localizeService.languages.filter(
    i => i.iso2 !== this.localizeService.language.iso2
  );
  localizeFormControl = new GenericFormControl<LangUIItem>(this.localizeService.language);


  constructor(
    private router: Router,
    public elementRef: ElementRef<HTMLElement>,
    private localizeService: I18nService,
    private appUi: AppUI
  ) {
    this.appUi.footerComponent = this;
  }


  changeLanguage(language: LangUIItem): void {
    this.localizeService.change(language.code);
  }

  redirectToLegal(tab: LegalParams['tab']): void {
    this.router.navigateByUrl(appNav.legalNav.root(tab));
  }
}
