export interface OrderType {
  value: LocalizedString;
  type: 'limit' | 'market';
}

export interface SideType {
  value: LocalizedString;
  type: 'SELL' | 'BUY';
}

export enum ExchangePageMobileTab {
  CHART = 'CHART',
  ORDER_BOOK = 'ORDER_BOOK'
}

export type ExchangePageMobileTabType = ExchangePageMobileTab.CHART | ExchangePageMobileTab.ORDER_BOOK;

