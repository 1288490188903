import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'auth/services/auth.service';
import { SeoService } from 'core/seo/seo.service';
import { Building, dataToBuilding } from 'dashboard/models/building.model';
import { UserService } from 'profile/services/user.service';
import { Subject } from 'rxjs';
import {dataToStreet, Street} from "dashboard/models/street.model";
import {MarketplaceStreetsService} from "app/marketplace/services/marketplace-streets.service";
import {WishlistStreetsService} from "app/marketplace/services/wishlist-streets.service";
import {skip, takeUntil} from "rxjs/operators";
import {appNav} from "app/app.navigation";
import {StreetTO} from "api/models/street-to";
import {MarketplaceBuildingsService} from "app/marketplace/services/marketplace-buildings.service";
import {WishlistBuildingsService} from "app/marketplace/services/wishlist-buildings.service";

@Component({
  selector: 'app-marketplace-buildings-page',
  templateUrl: './marketplace-buildings-page.component.html',
  styleUrls: ['./marketplace-buildings-page.component.scss'],
})
export class MarketplaceBuildingsPageComponent implements OnInit, OnDestroy {
  readonly skeletonItems = Array<Building>(10).fill(
    new Building(
      dataToBuilding({
        name: 'Skeleton Name',
        city: 'Skeleton',
        country: 'Skeleton',
      })
    )
  );
  buildings: Building[] = [];

  private destroy$ = new Subject<void>();
  private onPageLoaded: () => void = () => {};

  constructor(
    private marketplaceBuildingsService: MarketplaceBuildingsService,
    private userService: UserService,
    private wishlistService: WishlistBuildingsService,
    private router: Router,
    private seo: SeoService,
    private route: ActivatedRoute,
    private auth: AuthService,

  ) {}

  ngOnInit(): void {
    this.auth.onAuthorized.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.marketplaceBuildingsService.reloadData();
      this.buildings = this.skeletonItems;
    });

    this.buildings = this.skeletonItems;
    this.marketplaceBuildingsService.reloadData();
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(query => {
      this.marketplaceBuildingsService.saveQueryParams(query);
    });

    this.marketplaceBuildingsService
      .initQueryParamsListener()
      .pipe(skip(1), takeUntil(this.destroy$))
      .subscribe(() => {
        this.buildings = this.skeletonItems;
      });

    this.marketplaceBuildingsService
      .getMarketplaceBuildings()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        const tmpLen = this.buildings.length - this.skeletonItems.length;
        this.buildings = data.elements;
        if (tmpLen !== this.buildings.length) {
          this.onPageLoaded();
        }
      });

    this.seo.set({
      title: $t('seo.title.marketplace'),
      description: $t('seo.description.marketplace'),
      image: 'https://decentworld.com/assets/marketplace-1200x630.png',
      canonicalPath: appNav.marketplaceNav.marketplaceBuildings(),
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  nextPageAction(next: () => void): void {
    this.marketplaceBuildingsService.nextPage();
    this.buildings = [...this.buildings, ...this.skeletonItems];
    this.onPageLoaded = next;
  }

  goToOverview(building: Building): void {
    this.router.navigateByUrl(
      appNav.marketplaceNav.buildingPreview(building.id, building.country, building.city, building.street)
    );
  }

  toggleFavorite(building: Building): void {
    if (this.userService.user.id) {
      this.wishlistService.toggleFavorite(building);
    } else {
      this.auth.handleUnauthorizedAction();
    }
  }

  identifyBy(_number: number, item: StreetTO): number {
    return item.id || 1;
  }
}
