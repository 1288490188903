import { Injectable } from '@angular/core';
import { MarketTOListResponse, MarketTOResponse } from 'api/models';
import { MarketControllerService } from 'api/services';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MarketService {
  markets: MarketTOResponse[] = [];

  constructor(private api: MarketControllerService) {}

  load(): Observable<MarketTOListResponse> {
    return this.api.getMarketsUsingGET({ isNotNft: true }).pipe(
      tap(res => {
        this.markets = res.list || [];
      })
    );
  }

  getMarket(id: number): MarketTOResponse {
    return this.markets?.filter(market => market.id === id)[0];
  }
}
