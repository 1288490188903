export interface APIErrorResponse {
  error: string | { [key: string]: unknown };
  message?: string;
  details?: APIErrorProperty[];
  status: number;
}

export type APIErrorDetails<T> = T[];
export interface APIErrorProperty {
  field: string;
  details?: string;
  value?: unknown;
}

export class APIError<T = unknown> {
  code: string;
  message: string;
  properties: APIErrorProperty[];
  details: APIErrorDetails<T>;
  original: APIErrorResponse;

  private prevented = false;

  get isPrevented(): boolean {
    return this.prevented;
  }

  constructor(
    code: string,
    message = '',
    properties: APIErrorProperty[] = [],
    original: APIErrorResponse,
    details: APIErrorDetails<T> = []
  ) {
    this.code = code;
    this.message = message;
    this.properties = properties || [];
    this.original = original;
    this.details = details;
  }

  preventHandling(): void {
    this.prevented = true;
  }
}
