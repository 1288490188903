import {
  ConnectionPositionPair,
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { asyncScheduler, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TooltipComponent } from './tooltip.component';

type TooltipPosition = 'left' | 'right' | 'above' | 'below';

export type TooltipStyleType = 'primary' | undefined;

const BELOW = {
  originY: 'top',
  overlayY: 'bottom',
  originX: 'center',
  overlayX: 'center',
} as const;

const ABOVE = {
  originY: 'bottom',
  overlayY: 'top',
  originX: 'center',
  overlayX: 'center',
} as const;

@Directive({ selector: '[appTooltip]' })
export class TooltipDirective implements OnInit, OnDestroy {
  @Input('appTooltip')
  text = '';

  @Input()
  tooltipStyleType: TooltipStyleType;

  // TODO: add support for left/right
  @Input()
  tooltipPosition: 'above' | 'below' = 'below';

  @Input()
  autoShow?: number;

  @Input() customComponent = TooltipComponent;

  @Input()
  tooltipTimeout = 300;

  private isTouch?: boolean;
  private showTimeout = new Subscription();
  private destroy$ = new Subject();
  private currentPosition?: TooltipPosition;
  private overlayRef?: OverlayRef;
  private instance?: TooltipComponent;

  private _active?: boolean;
  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private chRef: ChangeDetectorRef
  ) {}

  get active(): boolean | undefined {
    return this._active;
  }

  ngOnDestroy(): void {
    this.showTimeout.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.renderer.setStyle(this.elementRef.nativeElement, 'cursor', 'pointer');
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withViewportMargin(10)
      .withPositions(this.tooltipPosition === 'above' ? [ABOVE, BELOW] : [BELOW, ABOVE]);
    positionStrategy.positionChanges.pipe(takeUntil(this.destroy$)).subscribe(change => {
      this.updateCurrentPositionClass(change.connectionPair);
    });
    this.overlayRef = this.overlay.create({ positionStrategy });
    if (this.autoShow) {
      asyncScheduler.schedule(() => {
        this.show();
      }, this.autoShow);
    }
  }

  shake(text: string): void {
    if (!this.active) {
      this.show();
      asyncScheduler.schedule(() => {
        this.shakeAndClose();
      });
    } else {
      this.shakeAndClose();
    }
    if (this.instance) {
      this.instance.text = text;
    }
  }

  @HostListener('touchstart')
  onTouchstart(): void {
    this.isTouch = true;
  }

  @HostListener('window:scroll')
  windowScrollHandler(): void {
    if (this._active) {
      this.hide();
      this.chRef.detectChanges();
    }
  }

  @HostListener('mousedown')
  onMousedown(): void {
    if (!this.isTouch) {
      this.showTimeout.unsubscribe();
    }
  }

  @HostListener('click')
  onClick(): void {
    if (this.isTouch) {
      if (!this._active) {
        this.show();
      } else {
        this.hide();
      }
    }
  }

  @HostListener('mouseenter')
  onMouseenter(): void {
    if (this.isTouch) {
      return;
    }
    this.showTimeout.unsubscribe();
    this.showTimeout = asyncScheduler.schedule(() => {
      this.show();
    }, this.tooltipTimeout);
  }

  @HostListener('mouseleave')
  onMouseleave(): void {
    this.showTimeout.unsubscribe();
    this.hide();
  }

  show(): void {
    if (this._active) {
      return;
    }
    const tooltipRef = this.overlayRef?.attach(new ComponentPortal(this.customComponent));
    if (tooltipRef) {
      this.instance = tooltipRef.instance;
      this.instance.text = this.text;
      this.instance.styleType = this.tooltipStyleType;
      this._active = true;
    }
  }

  hide(): void {
    if (!this._active) {
      return;
    }
    this.overlayRef?.detach();
    this._active = false;
    this.currentPosition = undefined;
    this.instance = undefined;
  }

  private updateCurrentPositionClass(connectionPair: ConnectionPositionPair): void {
    const { overlayY, originX, originY } = connectionPair;
    let newPosition: TooltipPosition;
    if (overlayY === 'center') {
      newPosition = originX === 'start' ? 'left' : 'right';
    } else {
      newPosition = overlayY === 'bottom' && originY === 'top' ? 'above' : 'below';
    }

    if (newPosition !== this.currentPosition) {
      const overlayRef = this.overlayRef;
      if (overlayRef && this.instance) {
        this.renderer.removeClass(
          this.instance.elementRef.nativeElement,
          `${this.currentPosition}`
        );
        this.renderer.addClass(this.instance.elementRef.nativeElement, newPosition);
      }
      this.currentPosition = newPosition;
    }
  }

  private shakeAndClose(): void {
    if (!this.instance) {
      return;
    }
    this.renderer.addClass(this.instance.elementRef.nativeElement, 'shake');
    asyncScheduler.schedule(() => {
      this.hide();
    }, 1000);
  }
}
