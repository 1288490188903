import { Validators } from '@angular/forms';
import { ApiKeyForm } from 'api/models';
import { UIItem } from 'core/models/ui-item';
import { GenericFormControl, GenericFormGroup } from 'core/utils/form-generics';
import { ApiKeyFormData } from 'profile/models/api-key-form-data.model';

export type VerificationTypeUIItem = UIItem<ApiKeyForm['emailConfirmationType']>;

export const apiKeyVerificationTypes: VerificationTypeUIItem[] = [
  new UIItem($t('profile.forms.api-key.default'), 'DEFAULT'),
  new UIItem($t('profile.forms.api-key.digits'), 'DIGITS'),
];

export class ApiKeyFormGroup extends GenericFormGroup<ApiKeyFormData> {
  constructor(data?: { apiKey: string; label: string }) {
    super({
      label: new GenericFormControl(data?.label || '', [Validators.required]),
      emailConfirmationType: new GenericFormControl<VerificationTypeUIItem>(
        apiKeyVerificationTypes[0]
      ),
    });
  }
}
