import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BaseRatePipe } from './base-rate.pipe';
import { CountableStringPipe } from './countable-string.pipe';
import { CutDecimalsPipe } from './cut-decimals.pipe';
import { DwrldToPipe } from './dwrld-to.pipe';
import { HttpUrlPipe } from './http-url.pipe';
import { MoneyPipe } from './money.pipe';
import { PrecisionPipe } from './precision.pipe';
import { PrettyDatePipe } from './pretty-date.pipe';
import { SafePipe } from './safe.pipe';

const exportedDeclarations = [
  HttpUrlPipe,
  PrettyDatePipe,
  BaseRatePipe,
  CutDecimalsPipe,
  DwrldToPipe,
  MoneyPipe,
  PrecisionPipe,
  CountableStringPipe,
  SafePipe,
];

@NgModule({
  imports: [CommonModule],
  declarations: exportedDeclarations,
  exports: exportedDeclarations,
})
export class CorePipesModule {}
