import { GenericFormControl, GenericFormGroup } from 'core/utils/form-generics';
import { STREET_LEVELS, StreetLevelDescription } from 'dashboard/models/street.data';

export interface NftFilterPlanForm {
  isBasic: boolean;
  isStandard: boolean;
  isPremium: boolean;
  isElite: boolean;
  isUnique: boolean;
}

export class NftPlanFilterFormGroup extends GenericFormGroup<NftFilterPlanForm> {
  levels: ({ control: GenericFormControl<boolean> } & StreetLevelDescription)[];

  constructor() {
    super({
      isBasic: new GenericFormControl(true),
      isStandard: new GenericFormControl(true),
      isPremium: new GenericFormControl(true),
      isElite: new GenericFormControl(true),
      isUnique: new GenericFormControl(true),
    });
    this.levels = [
      { ...STREET_LEVELS.plan1, control: this.controls.isBasic },
      { ...STREET_LEVELS.plan2, control: this.controls.isStandard },
      { ...STREET_LEVELS.plan3, control: this.controls.isPremium },
      { ...STREET_LEVELS.plan4, control: this.controls.isElite },
      { ...STREET_LEVELS.plan5, control: this.controls.isUnique },
    ];
  }

  get isOnlyOneActive(): boolean {
    return Object.values(this.controls).filter(c => c.value).length === 1;
  }
}
