import { Injectable } from '@angular/core';
import { DwrldTOResponse, NftTO } from 'api/models';
import { dataToDwrldOrder, DwrldOrder } from 'dashboard/models/dwrld-order.model';

import { StreetsFactory } from './streets.factory';

@Injectable({ providedIn: 'root' })
export class DwrldOrdersFactory {
  private store: Map<number, DwrldOrder> = new Map();

  constructor(private streetsFactory: StreetsFactory) {}

  get(id = 0, data?: DwrldTOResponse): DwrldOrder {
    let order = this.store.get(id);
    if (order) {
      return data ? Object.assign(order, dataToDwrldOrder(data)) : order;
    } else {
      const nftTO: NftTO = {
        ...data,
      };
      nftTO.id = data?.nftId;
      (nftTO as { price: undefined }).price = undefined;
      order = new DwrldOrder(
        dataToDwrldOrder(data),
        this.streetsFactory.get(nftTO.streetId, nftTO)
      );
      this.store.set(id, order);
      return order;
    }
  }

  emptyStore(): void {
    this.store.clear();
  }
}
