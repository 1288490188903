/* eslint-disable prefer-arrow-functions/prefer-arrow-functions */
import { ApiKeyResponse } from 'api/models';
import { DocumentRequestsTOResponse } from 'api/models/document-requests-toresponse';
import { createNavigation } from 'core/utils/router.utils';

export interface PublicParams {
  user: string;
}

export interface UploadDocumentModal {
  document: DocumentRequestsTOResponse;
}

export interface ApiKeyConfirm {
  token: string;
  apiKey: string;
}

export interface EditApiKeyModal {
  apiKey: ApiKeyResponse;
}

export interface ChangeApiKeyModal {
  status: boolean;
  apiKey: string;
}

export interface RevokeApiKeyModal {
  apiKey: ApiKeyResponse;
}

export interface TransferBalanceModal {
  amount: number;
}

export interface HistoryItemDetailsModal {
  id: number;
}

export const profileRoot = 'profile';

export function root(): string {
  return '';
}

export function publicRoot(user: string): string {
  return `${user}`;
}

export function avatar(): string {
  return 'avatar';
}

export function privacyAndSecurity(): string {
  return 'privacy_and_security';
}

export function leaderboard(): string {
  return 'leaderboard';
}

export function apiKeys(): string {
  return 'api-keys';
}

export function confirmApiKey(apiKey: string, token: string): string {
  return `confirmApiKey/${apiKey}/${token}`;
}

export function confirmRevokeApiKey(apiKey: string, token: string): string {
  return `deleteApiKey/${apiKey}/${token}`;
}

// paths settings
export const paths = {
  root,
  publicRoot,
  privacyAndSecurity,
  leaderboard,
  apiKeys,
  avatar,
  confirmApiKey,
  confirmRevokeApiKey,
};

export function changeNewPasswordModal(): string {
  return 'change-new-password';
}

export function transferToBalanceModal(): string {
  return 'transfer-to-balance';
}

export function changePasswordModal(): string {
  return 'change_password';
}

export function submitDocumentModal(): string {
  return 'submit-document';
}

export function editProfileModal(): string {
  return 'edit-profile';
}

export function changePhoneModal(): string {
  return 'change-phone';
}

export function activateGoogleAuthModal(): string {
  return 'activate-google-auth';
}

export function activateCurrentPasswordGoogleModal(): string {
  return 'activate-current-password-google';
}

export function activateGoogleSuccessModal(): string {
  return 'activate-google-success';
}

export function deactivateGoogleAuthModal(): string {
  return 'deactivate-google-auth';
}

export function createNewApiKeyModal(): string {
  return 'create-new-api-key';
}

export function editApiKeyModal(): string {
  return 'edit-api-key';
}

export function changeApiKeyStatusModal(): string {
  return 'change-api-key-status';
}

export function revokeApiKeyModal(): string {
  return 'revoke-api-key';
}

export function setUsernameModal(): string {
  return 'set-username';
}

export const modalPaths = {
  changePassword: changePasswordModal,
  submitDocument: submitDocumentModal,
  changePhone: changePhoneModal,
  activateGoogleAuth: activateGoogleAuthModal,
  activateGoogleSuccess: activateGoogleSuccessModal,
  activateCurrentPasswordGoogle: activateCurrentPasswordGoogleModal,
  editProfile: editProfileModal,
  deactivateGoogleAuthModal: deactivateGoogleAuthModal,
  createNewApiKey: createNewApiKeyModal,
  editApiKey: editApiKeyModal,
  changeApiKeyStatus: changeApiKeyStatusModal,
  setUsername: setUsernameModal,
  revokeApiKey: revokeApiKeyModal,
  transferToBalance: transferToBalanceModal,
  changeNewPassword: changeNewPasswordModal,
};

export const profileNav = createNavigation(paths, profileRoot);
export const profileModalsNav = createNavigation(modalPaths, profileRoot);
