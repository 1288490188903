<ng-container [formGroup]="form">
  <div class="filter-inputs">
    <app-number-input
      prefix="DWRLD"
      currency="USD"
      [formControl]="form.controls.fromPrice"
      placeholder="0"
      class="price-filter solid medium primary-dark"></app-number-input>
    <div class="dash">—</div>
    <app-number-input
      prefix="DWRLD"
      currency="USD"
      placeholder="0"
      [formControl]="form.controls.toPrice"
      class="price-filter solid medium primary-dark"></app-number-input>
  </div>
</ng-container>
