<div class="container">
  <div class="item">
    <div class="label">
      {{
        (street.id
          ? 'marketplace.street_overview.details.name'
          : 'marketplace.building_overview.details.name'
        ) | translate
      }}
    </div>
    <div class="value">{{ street.id ? street.name : building.mainName }}</div>
  </div>

  <div class="item">
    <div class="label">{{ 'marketplace.street_overview.details.city' | translate }}</div>
    <div class="value">{{ street.id ? street.city : building.city }}</div>
  </div>

  <div class="item">
    <div class="label">{{ 'marketplace.street_overview.details.country' | translate }}</div>
    <div class="value">{{ street.id ? street.country : building.country }}</div>
  </div>
</div>

<div class="container">
  <div class="item">
    <div class="label">
      {{
        (street.id
          ? 'marketplace.street_overview.details.category'
          : 'marketplace.building_overview.details.category'
        ) | translate
      }}
    </div>
    <ng-container *ngIf="!street.id">
      <div class="value building">{{ building.types | buildingCategory }}</div>
    </ng-container>
    <ng-container *ngIf="street.id">
      <div class="value">{{ street.label }}</div>
    </ng-container>
  </div>

  <div class="item">
    <div class="label">{{ 'marketplace.street_overview.details.coordinates' | translate }}</div>
    <div class="value">
      {{ street.id ? street.lat + ', ' + street.lng : building.lat + ', ' + building.lng }}
    </div>
  </div>

  <div *mediaIf="'medium'" class="item"></div>
</div>

<div class="container">
  <div class="item">
    <div class="label">{{ 'marketplace.street_overview.details.owner' | translate }}</div>
    <div class="value display-flex">
      <app-icon [icon]="iconProfile" class="margin-right"></app-icon>
      <ng-container *ngIf="!street.id">
        <span>{{ building.ownerName | nickname }}</span>
      </ng-container>
      <ng-container *ngIf="street.id">
        <span>{{ street.nft.ownerName | nickname }}</span>
      </ng-container>

    </div>
  </div>

  <div class="item">
    <div class="label">
      {{
        (street.id
          ? 'marketplace.street_overview.details.id'
          : 'marketplace.building_overview.details.id'
        ) | translate
      }}
    </div>
    <div class="value">{{ street.id || building.id }}</div>
  </div>

  <div class="item"></div>
</div>

<div class="container">
  <div class="item">
    <div class="label">{{ 'marketplace.street_overview.details.blockchain' | translate }}</div>
    <div class="value">
      <a
        *ngIf="street.fingerprintUrl || building.fingerprintUrl; else noValueTmpl"
        [href]="street.fingerprintUrl || building.fingerprintUrl"
        class="info color-primary"
        target="_blank">
        {{ street.isWAXBlockchain ? 'https://wax.bloks.io' : 'https://bloks.io' }}
      </a>
      <ng-template #noValueTmpl>
        <div class="info color-white">—</div>
      </ng-template>
    </div>
  </div>
</div>

<ng-template #noValueTmpl>
  <div class="info color-white">—</div>
</ng-template>
