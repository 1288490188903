import { ComponentType } from '@angular/cdk/portal';
import {
  Component,
  ComponentRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AppIcon } from 'core/icons/icon.type';
import { BaseCustomToast } from 'core/toaster/base-custom-toast.interface';
import { ToastExtras } from 'core/toaster/services/toaster.service';

import { closeToastIcon } from '../../icons/toast-close-icon';
import { infoToastIcon } from '../../icons/toast-info-icon';
import { successToastIcon } from '../../icons/toast-success-icon';
import { warnToastIcon } from '../../icons/toast-warn-icon';
import { ToastIconType } from '../../services/toast.type';

@Component({
  selector: 'app-toast',
  template: `
    <div [ngClass]="{ 'cursor-pointer': closeOnClick }" class="top">
      <ng-container *ngIf="!componentRef">
        <div class="icon-wrapper" [class.notification]="notificationIcon">
          <span *ngIf="icon" class="toast-icon" [innerHTML]="icon"></span>
          <app-icon
            class="notification-icon"
            *ngIf="notificationIcon"
            [icon]="notificationIcon"></app-icon>
        </div>
        <div class="info-section">
          <span class="msg" [innerHTML]="message | safe: 'html'"></span>
          <span *ngIf="description" class="msg" [innerHTML]="description | safe: 'html'"></span>
        </div>
      </ng-container>
      <ng-container #customToaster></ng-container>
    </div>
    <button
      (click)="closing.next(id)"
      *ngIf="showCloseIcon"
      class="close"
      [innerHTML]="closeIcon"></button>
  `,
  styleUrls: ['toast.component.scss'],
})
export class ToastComponent implements OnChanges {
  @ViewChild('customToaster', { read: ViewContainerRef, static: true })
  customToasterRef!: ViewContainerRef;

  @Input()
  message = '';

  @Input()
  description: string | undefined = '';

  @Input()
  button? = '';

  @Input()
  id = 0;

  @Input()
  showCloseIcon = true;

  @Input()
  closeOnClick!: boolean;

  @Input()
  @HostBinding('class')
  type?: ToastIconType = 'info';

  @Input()
  notificationIcon?: AppIcon;

  @Input()
  componentRef?: ComponentType<BaseCustomToast<unknown>>;

  @Input()
  extras?: ToastExtras<unknown>;

  @Output()
  closing = new EventEmitter<number>();

  @Output()
  action = new EventEmitter<number>();

  icon!: SafeHtml;
  closeIcon: SafeHtml;

  constructor(private renderer: Renderer2, private sanitizer: DomSanitizer) {
    this.closeIcon = this.sanitizer.bypassSecurityTrustHtml(closeToastIcon.svgData);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.type) {
      const type = changes.type.currentValue as ToastIconType;
      if (type === 'info') {
        this.icon = this.sanitizer.bypassSecurityTrustHtml(infoToastIcon.svgData);
      } else if (type === 'success') {
        this.icon = this.sanitizer.bypassSecurityTrustHtml(successToastIcon.svgData);
      } else if (type === 'warn') {
        this.icon = this.sanitizer.bypassSecurityTrustHtml(warnToastIcon.svgData);
      } else if (type === 'error') {
        this.icon = this.sanitizer.bypassSecurityTrustHtml(infoToastIcon.svgData);
      }
    }

    if (changes.componentRef && changes.componentRef.currentValue) {
      const ref: ComponentRef<BaseCustomToast<unknown>> = this.customToasterRef.createComponent(
        changes.componentRef.currentValue
      );
      ref.instance.onCustomToastInit(this.extras);
    }
  }

  @HostListener('click') onClick(): void {
    if (this.closeOnClick) {
      this.action.emit(this.id);
      this.closing.emit(this.id);
    } else {
      this.action.emit(this.id);
    }
  }
}
