import { Directive, ElementRef, OnInit, Optional, Self } from '@angular/core';

import { InputComponent } from '../input/input.component';

@Directive({
  selector: '[appAnchor]',
})
export class AnchorDirective implements OnInit {
  get element(): HTMLElement {
    return this.anchorElementRef.nativeElement;
  }
  private anchorElementRef: ElementRef<HTMLElement>;

  get hostElement(): HTMLElement {
    return this.hostElementRef.nativeElement;
  }
  private hostElementRef: ElementRef<HTMLElement>;

  constructor(
    public elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() private input?: InputComponent
  ) {
    this.hostElementRef = this.anchorElementRef = elementRef;
  }

  ngOnInit(): void {
    if (this.input) {
      this.anchorElementRef = this.input.inputRef;
    }
  }
}
