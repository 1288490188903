import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyService } from 'core/services/currency.service';

@Pipe({
  name: 'precision',
})
export class PrecisionPipe implements PipeTransform {
  private maximumValueForFractionDigits = 1000000;

  private orderBookMaxFractionDigits = 5;

  constructor(private currencyService: CurrencyService) {}

  transform(value?: number, code?: string, fallback?: string, isOrderBook = false): string {
    if (!value && fallback) {
      return fallback;
    }
    const precision = code ? this.currencyService.currencies[code]?.precision || 0 : 0;

    const isValueExceedMaximum = value && value >= this.maximumValueForFractionDigits;
    let minimumFractionDigits;

    let maximumFractionDigits;

    if (isValueExceedMaximum) {
      maximumFractionDigits = 0;
    } else if (isOrderBook) {
      maximumFractionDigits = this.orderBookMaxFractionDigits;
    } else {
      maximumFractionDigits = precision;
    }

    if (isValueExceedMaximum) {
      minimumFractionDigits = 0;
    } else if (code !== 'DWRLD') {
      minimumFractionDigits =
        isOrderBook && precision > this.orderBookMaxFractionDigits
          ? this.orderBookMaxFractionDigits
          : precision;
    } else if (isOrderBook && code === 'DWRLD') {
      minimumFractionDigits = precision;
    }
    const numberFormat = new Intl.NumberFormat('en-US', {
      minimumFractionDigits,
      maximumFractionDigits,
    });
    return numberFormat.format(value || 0);
  }
}
