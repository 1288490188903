import {
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[appToggle]',
  template: `
    <span class="thumb"></span>
    <span class="content"><ng-content></ng-content></span>
  `,
  styleUrls: ['./styles/toggle.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleComponent),
      multi: true,
    },
  ],
})
export class ToggleComponent implements ControlValueAccessor {
  @Input()
  @HostBinding('class.on')
  active?: boolean;

  @Input()
  @HostBinding('attr.type')
  type = 'button';

  protected onControlTouched = (): null => null;
  protected onControlChanged = (_: unknown): null => null;

  constructor(public elementRef: ElementRef<HTMLElement>, private renderer: Renderer2) {}

  @HostListener('click')
  toggle(): void {
    this.active = !this.active;
    this.onControlTouched();
    this.onControlChanged(this.active);
  }

  writeValue(value: boolean): void {
    this.active = value;
  }

  registerOnChange(fn: (val: unknown) => null): void {
    this.onControlChanged = fn;
  }

  registerOnTouched(fn: () => null): void {
    this.onControlTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.renderer.setAttribute(this.elementRef.nativeElement, 'disabled', '');
    } else {
      this.renderer.removeAttribute(this.elementRef.nativeElement, 'disabled');
    }
  }
}
