/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Resource } from '../models/resource';
import { QuizTOResponse } from '../models/quiz-toresponse';
import { QuizTOListResponse } from '../models/quiz-tolist-response';

/**
 * Quiz Controller
 */
@Injectable({
  providedIn: 'root',
})
class QuizControllerService extends __BaseService {
  static readonly claimPrizeUsingPOSTPath = '/api/v1/quiz/claim-prize';
  static readonly getStreetImageUsingGETPath = '/api/v1/quiz/image/{quizId}/{optionId}';
  static readonly startQuizUsingPOSTPath = '/api/v1/quiz/start';
  static readonly startTimerUsingPOSTPath = '/api/v1/quiz/start-timer';
  static readonly submitGuessLevelUsingPOSTPath = '/api/v1/quiz/submit/level';
  static readonly submitGuessMoreExpensiveUsingPOSTPath = '/api/v1/quiz/submit/more-expensive';
  static readonly submitGuessPriceUsingPOSTPath = '/api/v1/quiz/submit/price';
  static readonly submitGuessWhereUsingPOSTPath = '/api/v1/quiz/submit/where';
  static readonly getTicketAmountForUserUsingGETPath = '/api/v1/quiz/tickets/amount';
  static readonly claimTicketUsingPOSTPath = '/api/v1/quiz/tickets/claim';
  static readonly setTimeoutUsingPOSTPath = '/api/v1/quiz/timeout';
  static readonly getQuizzesForTicketUsingGETPath = '/api/v1/quiz/{ticketId}/quizzes';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Claim prize
   * @param ticketId ticketId
   * @return OK
   */
  claimPrizeUsingPOSTResponse(ticketId: number): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (ticketId != null) __params = __params.set('ticketId', ticketId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/quiz/claim-prize`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * Claim prize
   * @param ticketId ticketId
   * @return OK
   */
  claimPrizeUsingPOST(ticketId: number): __Observable<boolean> {
    return this.claimPrizeUsingPOSTResponse(ticketId).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * Get safe street image
   * @param params The `QuizControllerService.GetStreetImageUsingGETParams` containing the following parameters:
   *
   * - `quizId`: quizId
   *
   * - `optionId`: optionId
   *
   * @return OK
   */
  getStreetImageUsingGETResponse(params: QuizControllerService.GetStreetImageUsingGETParams): __Observable<__StrictHttpResponse<Resource>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/quiz/image/${encodeURIComponent(String(params.quizId))}/${encodeURIComponent(String(params.optionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Resource>;
      })
    );
  }
  /**
   * Get safe street image
   * @param params The `QuizControllerService.GetStreetImageUsingGETParams` containing the following parameters:
   *
   * - `quizId`: quizId
   *
   * - `optionId`: optionId
   *
   * @return OK
   */
  getStreetImageUsingGET(params: QuizControllerService.GetStreetImageUsingGETParams): __Observable<Resource> {
    return this.getStreetImageUsingGETResponse(params).pipe(
      __map(_r => _r.body as Resource)
    );
  }

  /**
   * Start quiz
   * @param quizType quizType
   * @return OK
   */
  startQuizUsingPOSTResponse(quizType: 'GUESS_LEVEL' | 'GUESS_MORE_EXPENSIVE' | 'GUESS_PRICE' | 'GUESS_WHERE' | 'UNRECOGNIZED'): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (quizType != null) __params = __params.set('quizType', quizType.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/quiz/start`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * Start quiz
   * @param quizType quizType
   * @return OK
   */
  startQuizUsingPOST(quizType: 'GUESS_LEVEL' | 'GUESS_MORE_EXPENSIVE' | 'GUESS_PRICE' | 'GUESS_WHERE' | 'UNRECOGNIZED'): __Observable<number> {
    return this.startQuizUsingPOSTResponse(quizType).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * Start timer for quiz
   * @param quizId quizId
   * @return OK
   */
  startTimerUsingPOSTResponse(quizId: number): __Observable<__StrictHttpResponse<QuizTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (quizId != null) __params = __params.set('quizId', quizId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/quiz/start-timer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QuizTOResponse>;
      })
    );
  }
  /**
   * Start timer for quiz
   * @param quizId quizId
   * @return OK
   */
  startTimerUsingPOST(quizId: number): __Observable<QuizTOResponse> {
    return this.startTimerUsingPOSTResponse(quizId).pipe(
      __map(_r => _r.body as QuizTOResponse)
    );
  }

  /**
   * Submit guess level answer
   * @param params The `QuizControllerService.SubmitGuessLevelUsingPOSTParams` containing the following parameters:
   *
   * - `level`: level
   *
   * - `id`: id
   *
   * @return OK
   */
  submitGuessLevelUsingPOSTResponse(params: QuizControllerService.SubmitGuessLevelUsingPOSTParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.level != null) __params = __params.set('level', params.level.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/quiz/submit/level`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * Submit guess level answer
   * @param params The `QuizControllerService.SubmitGuessLevelUsingPOSTParams` containing the following parameters:
   *
   * - `level`: level
   *
   * - `id`: id
   *
   * @return OK
   */
  submitGuessLevelUsingPOST(params: QuizControllerService.SubmitGuessLevelUsingPOSTParams): __Observable<boolean> {
    return this.submitGuessLevelUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * Submit guess more expensive answer
   * @param params The `QuizControllerService.SubmitGuessMoreExpensiveUsingPOSTParams` containing the following parameters:
   *
   * - `option`: option
   *
   * - `id`: id
   *
   * @return OK
   */
  submitGuessMoreExpensiveUsingPOSTResponse(params: QuizControllerService.SubmitGuessMoreExpensiveUsingPOSTParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.option != null) __params = __params.set('option', params.option.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/quiz/submit/more-expensive`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * Submit guess more expensive answer
   * @param params The `QuizControllerService.SubmitGuessMoreExpensiveUsingPOSTParams` containing the following parameters:
   *
   * - `option`: option
   *
   * - `id`: id
   *
   * @return OK
   */
  submitGuessMoreExpensiveUsingPOST(params: QuizControllerService.SubmitGuessMoreExpensiveUsingPOSTParams): __Observable<boolean> {
    return this.submitGuessMoreExpensiveUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * Submit guess price answer
   * @param params The `QuizControllerService.SubmitGuessPriceUsingPOSTParams` containing the following parameters:
   *
   * - `price`: price
   *
   * - `id`: id
   *
   * @return OK
   */
  submitGuessPriceUsingPOSTResponse(params: QuizControllerService.SubmitGuessPriceUsingPOSTParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.price != null) __params = __params.set('price', params.price.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/quiz/submit/price`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * Submit guess price answer
   * @param params The `QuizControllerService.SubmitGuessPriceUsingPOSTParams` containing the following parameters:
   *
   * - `price`: price
   *
   * - `id`: id
   *
   * @return OK
   */
  submitGuessPriceUsingPOST(params: QuizControllerService.SubmitGuessPriceUsingPOSTParams): __Observable<boolean> {
    return this.submitGuessPriceUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * Submit guess where answer
   * @param params The `QuizControllerService.SubmitGuessWhereUsingPOSTParams` containing the following parameters:
   *
   * - `region`: region
   *
   * - `id`: id
   *
   * @return OK
   */
  submitGuessWhereUsingPOSTResponse(params: QuizControllerService.SubmitGuessWhereUsingPOSTParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.region != null) __params = __params.set('region', params.region.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/quiz/submit/where`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * Submit guess where answer
   * @param params The `QuizControllerService.SubmitGuessWhereUsingPOSTParams` containing the following parameters:
   *
   * - `region`: region
   *
   * - `id`: id
   *
   * @return OK
   */
  submitGuessWhereUsingPOST(params: QuizControllerService.SubmitGuessWhereUsingPOSTParams): __Observable<boolean> {
    return this.submitGuessWhereUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * Get tickets amount for current user
   * @return OK
   */
  getTicketAmountForUserUsingGETResponse(): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/quiz/tickets/amount`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * Get tickets amount for current user
   * @return OK
   */
  getTicketAmountForUserUsingGET(): __Observable<number> {
    return this.getTicketAmountForUserUsingGETResponse().pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * Claim ticket
   * @return OK
   */
  claimTicketUsingPOSTResponse(): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/quiz/tickets/claim`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * Claim ticket
   * @return OK
   */
  claimTicketUsingPOST(): __Observable<boolean> {
    return this.claimTicketUsingPOSTResponse().pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * Set loss for quiz with timeout
   * @param quizId quizId
   * @return OK
   */
  setTimeoutUsingPOSTResponse(quizId: number): __Observable<__StrictHttpResponse<QuizTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (quizId != null) __params = __params.set('quizId', quizId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/quiz/timeout`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QuizTOResponse>;
      })
    );
  }
  /**
   * Set loss for quiz with timeout
   * @param quizId quizId
   * @return OK
   */
  setTimeoutUsingPOST(quizId: number): __Observable<QuizTOResponse> {
    return this.setTimeoutUsingPOSTResponse(quizId).pipe(
      __map(_r => _r.body as QuizTOResponse)
    );
  }

  /**
   * Get quizzes for ticket
   * @param ticketId ticketId
   * @return OK
   */
  getQuizzesForTicketUsingGETResponse(ticketId: number): __Observable<__StrictHttpResponse<QuizTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/quiz/${encodeURIComponent(String(ticketId))}/quizzes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QuizTOListResponse>;
      })
    );
  }
  /**
   * Get quizzes for ticket
   * @param ticketId ticketId
   * @return OK
   */
  getQuizzesForTicketUsingGET(ticketId: number): __Observable<QuizTOListResponse> {
    return this.getQuizzesForTicketUsingGETResponse(ticketId).pipe(
      __map(_r => _r.body as QuizTOListResponse)
    );
  }
}

module QuizControllerService {

  /**
   * Parameters for getStreetImageUsingGET
   */
  export interface GetStreetImageUsingGETParams {

    /**
     * quizId
     */
    quizId: number;

    /**
     * optionId
     */
    optionId: number;
  }

  /**
   * Parameters for submitGuessLevelUsingPOST
   */
  export interface SubmitGuessLevelUsingPOSTParams {

    /**
     * level
     */
    level: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for submitGuessMoreExpensiveUsingPOST
   */
  export interface SubmitGuessMoreExpensiveUsingPOSTParams {

    /**
     * option
     */
    option: number;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for submitGuessPriceUsingPOST
   */
  export interface SubmitGuessPriceUsingPOSTParams {

    /**
     * price
     */
    price: number;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for submitGuessWhereUsingPOST
   */
  export interface SubmitGuessWhereUsingPOSTParams {

    /**
     * region
     */
    region: string;

    /**
     * id
     */
    id: number;
  }
}

export { QuizControllerService }
