import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { BasketPurchaseResultTOResponse } from 'api/models';
import { iconBag } from 'core/icons/lib/icon-bag';
import { iconCheckCircle } from 'core/icons/lib/icon-check-circle';
import { iconChevronDown } from 'core/icons/lib/icon-chevron-down';
import { iconCircleLine } from 'core/icons/lib/icon-circle-line';
import { iconError } from 'core/icons/lib/icon-error';
import { iconOval } from 'core/icons/lib/icon-oval';
import { Building } from 'dashboard/models/building.model';
import { Subject } from 'rxjs';

import { Street } from './../../../../../dashboard/models/street.model';

@Component({
  selector: 'app-buildings-heap-checkout-item',
  templateUrl: './buildings-heap-checkout-item.component.html',
  styleUrls: ['./buildings-heap-checkout-item.component.scss'],
})
export class BuildingsHeapCheckoutItemComponent implements OnDestroy {
  readonly circleIcon = iconCircleLine;
  readonly expandIcon = iconChevronDown;
  readonly deleteIcon = iconBag;
  readonly spinIcon = iconOval;
  readonly iconError = iconError;
  readonly iconPending = iconOval;
  readonly iconSuccess = iconCheckCircle;

  @Input() street = new Street();
  @Input() buildings: (Building | Street)[] = [];
  @Input() status?: BasketPurchaseResultTOResponse['status'] | 'PENDING';
  @Input() price = 0;

  @Output() removeStreetEmitter = new EventEmitter<void>();
  @Output() removeBuildingEmitter = new EventEmitter<{ street: Street; items: Building[] }>();

  expanded = false;

  private destroy$ = new Subject<void>();

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  removeFromCart(): void {
    this.removeStreetEmitter.next();
  }

  removeBuildingFromCart(building: Building | Street): void {
    this.removeBuildingEmitter.next({ street: this.street, items: [building as Building] });
  }

  onExpand(expanded: boolean): void {
    this.expanded = expanded;
  }
}
