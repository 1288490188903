<div (click)="toggleExpanded()" [ngClass]="{ expanded: expanded }" class="handler">
  <ng-container [ngTemplateOutlet]="toolsWrapper"></ng-container>
  <div class="weight-medium color-txt-bold">
    <ng-content></ng-content>
  </div>
</div>
<div *ngIf="expanded" [@expandTransition]="transition" class="container">
  <ng-container *ngTemplateOutlet="tmplRef"></ng-container>
</div>

<ng-template #toolsWrapper>
  <div class="tools-wrapper display-flex align-center">
    <app-icon
      *ngIf="tooltipIcon && tooltipText"
      [icon]="tooltipIcon"
      class="tooltip"
      [tooltipStyleType]="tooltipStyleType"
      [appTooltip]="tooltipText"></app-icon>

    <div *ngIf="!hideIcon" class="arrow-wrapper">
      <ng-container *mediaIf="'medium'">
        <span *ngIf="!expanded && showMoreString" class="more">
          {{ 'core.data-expand.more' | translate }}
        </span>
      </ng-container>
      <ng-container *ngIf="expandedArrowIcon">
        <app-icon *ngIf="!expanded" [icon]="arrowIcon" class="arrow-icon"></app-icon>
        <app-icon
          *ngIf="expanded && expandedArrowIcon"
          [icon]="expandedArrowIcon"
          class="arrow-icon"></app-icon>
      </ng-container>
      <ng-container *ngIf="!expandedArrowIcon">
        <app-icon [icon]="arrowIcon" class="arrow-icon"></app-icon>
      </ng-container>
    </div>
  </div>
</ng-template>
