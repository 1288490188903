<div class="checkbox-container">
  <ng-container
    *ngFor="let l of form.levels"
  >
    <button
      *ngIf="(isCollections && l.level === 'plan5') || (enable5zone ? true : l.level !== 'plan5')"
      [formControl]="l.control"
      [ngClass]="{
      'not-interactive': form.isOnlyOneActive && l.control.value,
      active: l.control.value
    }"
      appCheckbox
      class="level-button">
      <app-level-icon [level]="l.level" [price]="-1" class="tn"></app-level-icon>
    </button>
  </ng-container>

</div>
