import { Component, ContentChild, Input } from '@angular/core';
import { AbstractControl, FormControlDirective } from '@angular/forms';

@Component({
  selector: 'app-form-field',
  template: `
    <ng-content select="app-form-label"></ng-content>
    <ng-content></ng-content>
    <app-field-error
      class="form-error"
      [control]="control"
      *ngIf="!errorDisabled"></app-field-error>
  `,
  styleUrls: ['form-field.component.scss'],
})
export class FormFieldComponent {
  @ContentChild(FormControlDirective)
  set valueAccessor(controlDirective: FormControlDirective) {
    if (!controlDirective) {
      return;
    }
    this.control = controlDirective.control;
  }
  control?: AbstractControl;

  @Input()
  errorDisabled?: boolean;
}
