import { Injectable } from '@angular/core';
import { AuthService } from 'auth/services/auth.service';
import { WalletsService } from 'cashier/services/wallets.service';
import { CurrencyService } from 'core/services/currency.service';
import { ReplaySubject } from 'rxjs';

import { AccountsService } from './accounts.service';
import { NotificationService, ProcessedEventTypes } from './notification.service';

@Injectable({ providedIn: 'root' })
export class BonusService {
  bonusChanged = new ReplaySubject<string>(1);
  isFirstDeposit?: boolean;

  get popupMinimized(): boolean {
    return this.isFirstDeposit
      ? localStorage.getItem('_pmi_first') === 'true'
      : localStorage.getItem('_pmi_second') === 'true';
  }

  constructor(
    auth: AuthService,
    walletsService: WalletsService,
    notificationService: NotificationService,
    private currencyService: CurrencyService,
    private accountsService: AccountsService
  ) {
    this.bonusChanged.next('3');
    auth.loggedIn.pipe().subscribe(() => {
      let value = '';
      this.isFirstDeposit = walletsService.dwrldWallet.total === 0;
      if (accountsService.account.dwrldPrice) {
        value = (currencyService.getBaseRate('DWRLD') / accountsService.account.dwrldPrice).toFixed(
          1
        );
      } else {
        localStorage.removeItem('_pmi_first');
        localStorage.removeItem('_pmi_second');
      }
      this.bonusChanged.next(value);
    });
    auth.loggedOut.subscribe(() => {
      this.isFirstDeposit = undefined;
      this.bonusChanged.next('3');
    });
    notificationService.eventProcessed.subscribe(message => {
      if ((message.data.messageType as ProcessedEventTypes) === 'DEPOSIT_RECEIVED') {
        accountsService.account.dwrldPrice = undefined;
        localStorage.removeItem('_pmi_first');
        localStorage.removeItem('_pmi_second');
        this.bonusChanged.next('');
      }
    });
  }

  generateBonus(): void {
    if (this.accountsService.account.dwrldPrice) {
      return;
    }
    this.accountsService.reloadAccountBonus().subscribe(() => {
      let value = '';
      if (this.accountsService.account.dwrldPrice) {
        value = (
          this.currencyService.getBaseRate('DWRLD') / this.accountsService.account.dwrldPrice
        ).toFixed(1);
      }
      this.bonusChanged.next(value);
    });
  }

  minimizePopup(): void {
    if (this.isFirstDeposit) {
      localStorage.setItem('_pmi_first', 'true');
    } else {
      localStorage.setItem('_pmi_second', 'true');
    }
  }
}
