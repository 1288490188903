import { MapboxGeoJSONFeature } from 'mapbox-gl';
import { MapMarketsWorkerResult } from 'streets/models/map.data';

export interface MapCameraState {
  lat: number;
  lng: number;
  zoom?: number;
  pitch?: number;
  bearing?: number;
}

export abstract class MapService {
  cameraState?: MapCameraState | undefined;

  abstract getPriceRange(
    streetFeatures: MapboxGeoJSONFeature[],
    buildingFeatures: MapboxGeoJSONFeature[],
    cb: (value: { min: number; max: number }) => void
  ): void;

  abstract getBuildingsMarketsFeatures(
    features: MapboxGeoJSONFeature[],
    cb: (value: MapMarketsWorkerResult) => void
  ): void;

  abstract getMarketsFeatures(
    features: MapboxGeoJSONFeature[],
    cb: (value: MapMarketsWorkerResult) => void
  ): void;
}
