/* eslint-disable prefer-arrow-functions/prefer-arrow-functions */
import { createNavigation } from 'core/utils/router.utils';

export interface ProceedPaymentModalData {
  marketAddress?: string;
  memoAddress?: string;
  dwrldAmount?: string;
  amountValue?: string;
  amountCurrency?: string;
  minDepositValue?: number;
}

export const landingRoot = '';

export function root(): string {
  return '';
}

export function blackFridayRoot(): string {
  return 'black-friday';
}

export function secondaryRoot(): string {
  return 'initial';
}

export function application(): string {
  return 'metaverse';
}

export function download(): string {
  return 'download';
}

export function metaverse(autoplay?: boolean): string {
  return `metaverse${autoplay ? '?autoplay=true' : ''}`;
}

export function streaming(): string {
  return 'streaming';
}

export function whatIsMetaverse(): string {
  return 'what-is-metaverse';
}

export function whatIsNFT(): string {
  return 'what-is-nft';
}

export function digitalRealEstate(): string {
  return 'digital-real-estate';
}

export function coreTeamMember(): string {
  return 'team-member';
}

export function coreTeam(): string {
  return 'team';
}

export const paths = {
  root,
  secondaryRoot,
  blackFridayRoot,
  application,
  whatIsNFT,
  whatIsMetaverse,
  digitalRealEstate,
  coreTeam,
  coreTeamMember,
  download,
  metaverse,
  streaming,
};

export const landingNav = createNavigation(paths, landingRoot);
