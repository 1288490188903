import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from 'core/modules/i18n/i18n.service';

type LabelTypes = 'now' | 'today' | 'minutes' | 'hours' | 'yesterday' | 'days' | 'weeks';

@Pipe({
  name: 'prettyDate',
})
export class PrettyDatePipe implements PipeTransform {
  private datePipe = new DatePipe('en-US');

  constructor(private i18n: I18nService) {}

  transform(value: string | Date) {
    const date = value instanceof Date ? value : new Date(value);
    const dif = Math.floor((Date.now() - date.getTime()) / 1000 / 86400);
    if (dif < 30) {
      return this.toPrettyDate(date);
    } else {
      return this.datePipe.transform(value, 'shortDate');
    }
  }

  private toPrettyDate(date: Date) {
    const timeNowMid = new Date().setHours(0, 0, 0, 0);
    const timeMid = new Date(date).setHours(0, 0, 0, 0);
    const diff = (new Date().getTime() - date.getTime()) / 1000;
    const dayDiff = Math.floor((timeNowMid - timeMid) / 86400000);

    if (isNaN(dayDiff) || dayDiff < 0 || dayDiff >= 31) {
      return '';
    }
    return (
      (dayDiff === 0 &&
        ((diff < 60 && this.localizeValue('now')) ||
          (diff < 3600 && this.localizeValue('minutes', Math.floor(diff / 60))) ||
          (diff < 86400 && this.localizeValue('hours', Math.floor(diff / 3600))))) ||
      (dayDiff === 1 && this.localizeValue('yesterday')) ||
      (dayDiff < 7 && this.localizeValue('days', dayDiff)) ||
      (dayDiff < 31 && this.localizeValue('weeks', Math.ceil(dayDiff / 7)))
    );
  }

  private localizeValue(type: LabelTypes, value = 0) {
    if (this.i18n.language.code === 'RU') {
      switch (type) {
        case 'now':
          return 'только что';
        case 'today':
          return 'сегодня';
        case 'minutes':
          return `${value} мин.`;
        case 'hours':
          return `${value} ч.`;
        case 'yesterday':
          return 'вчера';
        case 'days':
          return `${value} дн.`;
        case 'weeks':
          return `${value} нед.`;
      }
    } else {
      switch (type) {
        case 'now':
          return 'Just now';
        case 'today':
          return 'Today';
        case 'minutes':
          return `${value} ${value < 2 ? 'minute' : 'minutes'} ago`;
        case 'hours':
          return `${value} ${value < 2 ? 'hour' : 'hours'} ago`;
        case 'yesterday':
          return `1 day ago`;
        case 'days':
          return `${value} days ago`;
        case 'weeks':
          return `${value} ${value < 2 ? 'week' : 'weeks'} ago`;
      }
    }
  }
}
