import { Pipe, PipeTransform } from '@angular/core';
import { SortHeaderItem } from 'core/components/list-components/sorting.model';

@Pipe({
  name: 'headersPipe',
})
export class HeadersPipe implements PipeTransform {
  transform(value: Array<SortHeaderItem>, key: keyof SortHeaderItem): Array<SortHeaderItem> {
    if (!key) return value;

    return value.filter(item => !!item[key]);
  }
}
