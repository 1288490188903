import { Location } from '@angular/common';
import { Injectable } from '@angular/core';

type RegionLocalizations = { name: string; pathLang?: string; queryLang?: string }[];
interface Regions {
  [key: string]: RegionLocalizations;
}

export interface RegionLocalization {
  name: string;
  region: string;
  locale: string;
  href?: string;
  lang?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppLocale {
  static appLocale?: string;

  static readonly allowedLocales = ['en', 'es', 'pt'];

  static getLocaleFromUrl = (url: string): string => (url.match(/^\/[a-z]{2}|$/g) || [])[0] || '';

  constructor(private location: Location) {}

  cleanUrl(url: string): string {
    const locale = AppLocale.getLocaleFromUrl(`${url}/`);
    const isAllowedLocal = AppLocale.allowedLocales.includes(locale.replace(/\//gim, ''));
    if (isAllowedLocal && locale) {
      return url.replace(locale, '');
    }
    return url;
  }

  getLocaleUrl(currentCode: string, code: string): string {
    const path = this.location.path(false);
    if (code === 'EN') {
      return path.replace(`${currentCode.toLowerCase()}`, '').replace('//', '/');
    }
    if (path.includes(currentCode.toLowerCase())) {
      return path.replace(`${currentCode.toLowerCase()}`, code.toLowerCase());
    } else {
      return `/${code.toLowerCase()}/${path}`;
    }
  }
}
