import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { navigateByUrl } from '../router.utils';

export const navigateOnError =
  <T>(url: string): OperatorFunction<T, T> =>
  (source$: Observable<T>): Observable<T> =>
    source$.pipe(
      catchError(error => {
        navigateByUrl(url, {
          skipLocationChange: true,
        });
        return of<T>(error);
      })
    );
