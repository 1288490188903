import { Injectable } from '@angular/core';
import {
  CollectionMetaInformationExtendedTOResponse,
  CollectionStakeElementTOResponse,
  NftCollectionTOResponse,
} from 'api/models';
import { Collection, dataToCollection } from 'app/collections/models/collection.model';
import { StreetsFactory } from 'dashboard/services/streets.factory';

@Injectable({ providedIn: 'root' })
export class CollectionFactory {
  private store: Map<number, Collection> = new Map();

  constructor(private streetFactory: StreetsFactory) {}

  get(
    id = 0,
    data?:
      | CollectionMetaInformationExtendedTOResponse
      | NftCollectionTOResponse
      | CollectionStakeElementTOResponse
  ): Collection {
    let collection = this.store.get(id);
    if (collection) {
      return data
        ? Object.assign(
            collection,
            dataToCollection(
              data as CollectionMetaInformationExtendedTOResponse,
              this.streetFactory,
              collection
            )
          )
        : collection;
    } else {
      collection = new Collection(
        dataToCollection(data as CollectionMetaInformationExtendedTOResponse, this.streetFactory)
      );
      this.store.set(id, collection);
      return collection;
    }
  }

  getUserCollection(userCollectionId: number): Collection {
    const collection = [...this.store.values()].filter(c => c.id === userCollectionId)[0];
    return collection || new Collection();
  }

  emptyStore(): void {
    this.store.clear();
  }
}
