<form [formGroup]="form" class="form">
  <div class="filters-container">
    <app-form-field class="item">
      <app-form-label>{{ 'marketplace.streets.filter.search.title' | translate }}</app-form-label>
      <app-input
        [debounce]="200"
        [formControl]="form.controls.name"
        [placeholder]="'marketplace.streets.filter.search.placeholder' | translate"
        autocomplete="new-street"
        class="solid medium primary-dark width-100"
        extras="search"></app-input>
    </app-form-field>

    <ng-container *mediaIf="'large'" [ngTemplateOutlet]="priceFilters"></ng-container>
    <ng-container *mediaIf="'large'" [ngTemplateOutlet]="planFilters"></ng-container>
  </div>

  <div class="filters-container">
    <ng-container *mediaIf="'large'">
      <ng-container [ngTemplateOutlet]="placeFilters"></ng-container>
      <ng-container [ngTemplateOutlet]="matchingFilters"></ng-container>
    </ng-container>
  </div>
</form>

<app-sort-header
  (sortChange)="onSortChange($event)"
  *mediaIf="'large'"
  [headers]="headers"
  [sortData]="sortData"
  class="data-header"></app-sort-header>

<app-mobile-filters
  (sortChange)="onSortChange($event)"
  *mediaIf="'medium down'"
  [headers]="headers"
  [sortData]="sortData"
  class="margin-bottom"
  [filterTitle]="'marketplace.streets.filter.title' | translate">
  <ng-container additionalLayout>
    <div class="mobile-filters-container">
      <ng-container *ngTemplateOutlet="priceFilters"></ng-container>
      <ng-container *ngTemplateOutlet="placeFilters"></ng-container>
      <ng-container *ngTemplateOutlet="planFilters"></ng-container>
      <ng-container *ngTemplateOutlet="matchingFilters"></ng-container>
    </div>
  </ng-container>
</app-mobile-filters>

<ng-template #matchingFilters>
  <ng-container *ngIf="authService.isAuth">
    <button
      *ngIf="'dev-uat'"
      [formControl]="form.controls.hasMatching"
      appToggle
      class="matching-button">
      <span class="color-white opacity">
        {{ 'marketplace.streets.filter.my_assets' | translate }}
      </span>
    </button>
  </ng-container>
</ng-template>
<ng-template #priceFilters>
  <app-form-field class="item">
    <app-form-label>{{ 'marketplace.streets.filter.asset_price' | translate }}</app-form-label>
    <app-nft-price-filters [formControl]="form.controls.price"></app-nft-price-filters>
  </app-form-field>
</ng-template>

<ng-template #planFilters>
  <app-nft-plan-filter
    [formControl]="form.controls.zones"
    class="item plan-filters"></app-nft-plan-filter>
</ng-template>

<ng-template #placeFilters>
  <app-form-field class="item">
    <app-form-label>{{ 'marketplace.streets.filter.country' | translate }}</app-form-label>
    <app-country-search
      [allowClearButton]="false"
      (selected)="pickCountry($event)"
      [countryCode]="countryCode"></app-country-search>
  </app-form-field>

  <app-form-field class="item">
    <app-form-label>{{ 'marketplace.streets.filter.city' | translate }}</app-form-label>

    <app-city-search
      (selected)="pickCity($event)"
      [cityId]="cityId"
      [countryCode]="countryCode"></app-city-search>
  </app-form-field>

  <app-form-field class="item">
    <app-form-label>{{ 'marketplace.streets.filter.market-type' | translate }}</app-form-label>
    <app-select>
      <app-html-input appAnchor class="solid medium primary-dark" extras="dropdown" type="false">
        {{ form.controls.nftMarketType.value?.name | translate }}
      </app-html-input>

      <ng-template>
        <button
          (click)="pickMarketType(option)"
          *ngFor="let option of marketplaceMarketTypes"
          [appSelectItem]="option"
          appButton
          class="solid for-select">
          {{ option.name | translate }}
        </button>
      </ng-template>
    </app-select>
  </app-form-field>
</ng-template>
