/* eslint-disable prefer-arrow-functions/prefer-arrow-functions */
import { WalletTOResponse } from 'api/models';
import { CurrencyFactory } from 'core/services/currency.factory';

import { Currency } from '../../_core/models/currency.model';

export type WalletType = Required<WalletTOResponse>['type'];

export class Wallet {
  accountId!: number;
  id!: number;

  isActive?: boolean;
  isDepositActive?: boolean;
  isWithdrawActive?: boolean;

  available!: number;
  reserved!: number;
  amountInBasicCurrency!: number;

  currency!: Currency;
  type!: WalletType;

  get total(): number {
    return this.available + this.reserved;
  }

  get isNFT(): boolean {
    return this.currency.code === 'NFT';
  }

  get isDWRLD(): boolean {
    return this.currency.code === 'DWRLD';
  }

  get isBonus(): boolean {
    return this.currency.code === 'DPC';
  }

  constructor(init: PickData<Wallet> = dataToAccount()) {
    Object.assign(this, init);
  }
}

export function dataToAccount(
  data: Partial<WalletTOResponse> = {},
  currencyFactory?: CurrencyFactory
): PickData<Required<Wallet>> {
  return {
    id: data.id || 0,
    accountId: data.accountId || 0,
    currency: currencyFactory?.get(data.currencyCode) || new Currency(),
    isActive: data.isActive || false,
    isDepositActive: data.isDepositeActive || false,
    isWithdrawActive: data.isWithdrawActive || false,
    available: data.isAvailable || 0,
    reserved: data.reserved || 0,
    amountInBasicCurrency: data.amountInBasicCurrency || 0,
    type: data.type || 'UNKNOWN',
  };
}
