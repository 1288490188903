import jsSHA from 'jssha';

const ALPHABET = '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';
const ALPHABET_MAP: Record<string, number> = {};
const BASE = 58;

for (let p = 0; p < ALPHABET.length; p++) {
  ALPHABET_MAP[ALPHABET.charAt(p)] = p;
}
/* eslint-disable */
function byte2hexStr(byte: number): string {
  const hexByteMap = '0123456789ABCDEF';
  let str = '';
  str += hexByteMap.charAt(byte >> 4);
  str += hexByteMap.charAt(byte & 0x0f);
  return str;
}

/* Convert byte arry to HEX string */

//byteArray格式数据转为16进制的ASCII字符串。
function byteArray2hexStr(byteArray: number[]): string {
  let str = '';
  for (var i = 0; i < byteArray.length - 1; i++) {
    str += byte2hexStr(byteArray[i]);
  }
  str += byte2hexStr(byteArray[i]);
  return str;
}

function SHA256(msgBytes: number[]): number[] {
  const shaObj = new jsSHA('SHA-256', 'HEX');
  const msgHex = byteArray2hexStr(msgBytes);
  shaObj.update(msgHex);
  const hashHex = shaObj.getHash('HEX');
  const hashBytes = hexStr2byteArray(hashHex);
  return hashBytes;
}

// function base64EncodeToString(bytes: any) {
//   const b = new Base64();
//   const string64 = b.encodeIgnoreUtf8(bytes);
//   return string64;
// }

function hexChar2byte(c: string): number {
  let d = 0;
  if (c >= 'A' && c <= 'F') {
    d = c.charCodeAt(0) - 'A'.charCodeAt(0) + 10;
  } else if (c >= 'a' && c <= 'f') {
    d = c.charCodeAt(0) - 'a'.charCodeAt(0) + 10;
  } else if (c >= '0' && c <= '9') {
    d = c.charCodeAt(0) - '0'.charCodeAt(0);
  }
  return d;
}

function encode58(buffer: string | any[]) {
  if (buffer.length === 0) {
    return '';
  }

  let i,
    j,
    digits = [0];
  for (i = 0; i < buffer.length; i++) {
    for (j = 0; j < digits.length; j++) {
      digits[j] <<= 8;
    }

    digits[0] += buffer[i];

    let carry = 0;
    for (j = 0; j < digits.length; ++j) {
      digits[j] += carry;

      carry = (digits[j] / BASE) | 0;
      digits[j] %= BASE;
    }

    while (carry) {
      digits.push(carry % BASE);

      carry = (carry / BASE) | 0;
    }
  }

  // deal with leading zeros
  for (i = 0; buffer[i] === 0 && i < buffer.length - 1; i++) {
    digits.push(0);
  }

  return digits
    .reverse()
    .map(digit => ALPHABET[digit])
    .join('');
}

function getBase58CheckAddress(addressBytes: number[]): string {
  const hash0 = SHA256(addressBytes);
  const hash1 = SHA256(hash0);
  let checkSum: string | any[] = hash1.slice(0, 4);
  checkSum = addressBytes.concat(checkSum);
  const base58Check = encode58(checkSum);

  return base58Check;
}

function decodeBase58Address(base58Sting: string) {
  var zeroAddress = hexStr2byteArray('000000000000000000000000000000000000000000');
  if (typeof base58Sting != 'string') {
    alert('Input format error!');
    return;
  }
  if (base58Sting.length <= 4) {
    alert('Input length error!');
    return;
  }
  var address = decode58(base58Sting);
  if (base58Sting.length <= 4) {
    alert('Decode58 output length error!');
    return;
  }
  var len = address.length;
  var offset = len - 4;
  var checkSum = address.slice(offset);
  address = address.slice(0, offset);
  var hash0 = SHA256(address);
  var hash1 = SHA256(hash0);
  var checkSum1 = hash1.slice(0, 4);
  if (
    checkSum[0] == checkSum1[0] &&
    checkSum[1] == checkSum1[1] &&
    checkSum[2] == checkSum1[2] &&
    checkSum[3] == checkSum1[3]
  ) {
    return address;
  }
  alert('Check sum error!');
  return zeroAddress;
}

function isHexChar(c: string): number {
  if ((c >= 'A' && c <= 'F') || (c >= 'a' && c <= 'f') || (c >= '0' && c <= '9')) {
    return 1;
  }
  return 0;
}

function decode58(string: string) {
  if (string.length === 0) {
    return [];
  }

  var i,
    j,
    bytes = [0];
  for (i = 0; i < string.length; i++) {
    var c: any = string[i];
    if (!(c in ALPHABET_MAP)) {
      throw new Error('Non-base58 character');
    }

    for (j = 0; j < bytes.length; j++) {
      bytes[j] *= BASE;
    }
    bytes[0] += ALPHABET_MAP[c];

    var carry = 0;
    for (j = 0; j < bytes.length; ++j) {
      bytes[j] += carry;

      carry = bytes[j] >> 8;
      bytes[j] &= 0xff;
    }

    while (carry) {
      bytes.push(carry & 0xff);

      carry >>= 8;
    }
  }

  // deal with leading zeros
  for (i = 0; string[i] === '1' && i < string.length - 1; i++) {
    bytes.push(0);
  }

  return bytes.reverse();
}

function hexStr2byteArray(str: string): number[] {
  const byteArray = [];
  let d = 0;
  let i = 0;
  let j = 0;
  let k = 0;

  for (i = 0; i < str.length; i++) {
    const c = str.charAt(i);
    if (isHexChar(c)) {
      d <<= 4;
      d += hexChar2byte(c);
      j++;
      if (0 == j % 2) {
        byteArray[k++] = d;
        d = 0;
      }
    }
  }
  return byteArray;
}

export function do58Encode(src: string): string {
  if (src.length < 2 || (src.length & 1) != 0) {
    alert('Input length error!');
    return '';
  }
  const bytes = hexStr2byteArray(src);
  return getBase58CheckAddress(bytes);
}

export function do58Decode(src: string): string {
  var bytes: any = decodeBase58Address(src);
  return byteArray2hexStr(bytes);
}
/* eslint-enable */
