import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from 'core/modules/i18n/translate.module';
import { PlatformModule } from 'core/modules/platform/platform.module';
import { CorePipesModule } from 'core/pipes/core-pipes/core-pipes.module';

import { ButtonModule } from '../button/button.module';
import { DropdownModule } from '../dropdown/dropdown.module';
import { IconModule } from '../icon/icon.module';
import { TooltipModule } from '../tooltip/tooltip.module';
import { HeadersPipe } from './headers.pipe';
import { MobileFiltersComponent } from './mobile-filters/mobile-filters.component';
import { NoDataComponent } from './no-data/no-data.component';
import { PaginationComponent } from './pagination/pagination.component';
import { SortHeaderComponent } from './sort-header/sort-header.component';

const exportedDeclarations = [
  NoDataComponent,
  MobileFiltersComponent,
  SortHeaderComponent,
  PaginationComponent,
  HeadersPipe,
];

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    TranslateModule,
    CorePipesModule,
    TooltipModule,
    ButtonModule,
    DropdownModule,
    PlatformModule,
    RouterModule,
  ],
  declarations: exportedDeclarations,
  exports: exportedDeclarations,
})
export class ListComponentsModule {}
