import { NgIfContext } from '@angular/common';
import {
  Directive,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { MediaQuery } from '../services/media-query.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[mediaIf]',
})
export class MediaIfDirective implements OnInit, OnDestroy, OnChanges {
  @Input('mediaIf')
  breakpoint?: string;

  @Input()
  mediaIfElse: TemplateRef<NgIfContext> | null = null;

  private thenViewCreated = false;
  private elseViewCreated = false;
  private mediaSubs = new Subscription();

  constructor(
    private templateRef: TemplateRef<HTMLElement>,
    private viewContainer: ViewContainerRef,
    private media: MediaQuery
  ) {}

  ngOnInit(): void {
    this.mediaSubs = this.media.mediaChanged
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(() => {
        if (this.breakpoint && this.media.is(this.breakpoint)) {
          this.createView();
        } else {
          this.clearView();
        }
      });
  }

  ngOnChanges(): void {
    if (this.breakpoint && this.media.is(this.breakpoint)) {
      this.createView();
    } else {
      this.clearView();
    }
  }

  ngOnDestroy(): void {
    this.mediaSubs.unsubscribe();
  }

  private createView(): void {
    if (this.elseViewCreated) {
      this.viewContainer.clear();
      this.elseViewCreated = false;
    }
    if (!this.thenViewCreated) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.thenViewCreated = true;
    }
  }

  private clearView(): void {
    if (this.thenViewCreated) {
      this.viewContainer.clear();
      this.thenViewCreated = false;
    }
    if (this.mediaIfElse && !this.elseViewCreated) {
      this.viewContainer.createEmbeddedView(this.mediaIfElse);
      this.elseViewCreated = true;
    }
  }
}
