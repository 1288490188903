import { Component, ElementRef, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { AppUI } from 'core/services/app-ui.service';

@Component({
  selector: 'app-toolbar-outlet',
  templateUrl: './toolbar-outlet.component.html',
  styleUrls: ['./toolbar-outlet.component.scss'],
})
export class ToolbarOutletComponent {
  @ViewChild('containerRef', { read: ViewContainerRef })
  viewContainerRef!: ViewContainerRef;

  private template: TemplateRef<unknown> | null = null;

  constructor(private appUI: AppUI, public elementRef: ElementRef<HTMLElement>) {
    this.appUI.toolbarOutletComponent = this;
  }

  setContent(template: TemplateRef<unknown>): void {
    this.template = template;
    this.viewContainerRef.createEmbeddedView(this.template);
  }

  clearContent(): void {
    this.template = null;
    this.viewContainerRef.clear();
  }
}
