import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface EosTableRowsResult {
  rows: any[];
  more: boolean;
  next_key: string;
  next_key_bytes: string;
}

@Injectable({ providedIn: 'root' })
export class EosService {
  usernameLength = 12;
  requestURL = 'https://eos.greymass.com';
  private apiVersion = 'v1';
  private requestHeaders = {
    accept: '*/*',
    'accept-language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7',
    'content-type': 'text/plain;charset=UTF-8',
  };

  constructor(private http: HttpClient) {}

  getAccountInfo(accountName: string): Observable<{ account_name?: string }> {
    const url = 'chain/get_account';
    const params = {
      account_name: accountName,
    };
    return this.http
      .post(this.getAccountRequestUrl(url), { ...params }, { headers: { ...this.requestHeaders } })
      .pipe(map(r => r as { account_name?: string }));
  }

  getAccountBalance(accountName: string): Observable<string[]> {
    const url = 'chain/get_currency_balance';
    const params = {
      account: accountName,
      code: 'eosio.token',
      symbol: 'eos',
    };
    return this.http
      .post(this.getAccountRequestUrl(url), { ...params }, { headers: { ...this.requestHeaders } })
      .pipe(map(r => r as string[]));
  }

  getCollectionsPaid(): Observable<EosTableRowsResult> {
    const url = 'chain/get_table_rows';
    const params = {
      code: 'd2centworlds',
      index_position: 1,
      json: true,
      limit: '100',
      reverse: false,
      scope: 'd2centworlds',
      show_payer: false,
      table: 'dwconfig',
    };
    return this.http
      .post(this.getMainRequestUrl(url), { ...params }, { headers: { ...this.requestHeaders } })
      .pipe(map(r => r as EosTableRowsResult));
  }

  getCollectionTVL(): Observable<EosTableRowsResult> {
    const url = 'chain/get_table_rows';
    const params = {
      code: 'd2centworlds',
      index_position: 1,
      json: true,
      limit: '10000',
      reverse: false,
      scope: 'decentwarmex',
      show_payer: false,
      table: 'yields',
    };
    return this.http
      .post(this.getMainRequestUrl(url), { ...params }, { headers: { ...this.requestHeaders } })
      .pipe(map(r => r as EosTableRowsResult));
  }

  private getAccountRequestUrl(url: string): string {
    return `${environment.EOSServiceUrl || ''}/${this.apiVersion}/${url}`;
  }

  private getMainRequestUrl(url: string): string {
    return `${this.requestURL}/${this.apiVersion}/${url}`;
  }
}
