/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { FavoriteObjectForm } from '../models/favorite-object-form';
import { GisObjectTOPage } from '../models/gis-object-topage';

/**
 * Favorite Object Controller
 */
@Injectable({
  providedIn: 'root',
})
class FavoriteObjectControllerService extends __BaseService {
  static readonly addFavoritesUsingPOSTPath = '/api/v2/favorite/add';
  static readonly removeFavoritesUsingPOSTPath = '/api/v2/favorite/remove';
  static readonly checkFavoriteObjectsUsingPOSTPath = '/api/v2/favorite/{type}/check';
  static readonly listUsingGET10Path = '/api/v2/favorite/{type}/objects';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Add to favorite
   * @param form form
   * @return OK
   */
  addFavoritesUsingPOSTResponse(form: FavoriteObjectForm): __Observable<__StrictHttpResponse<Array<number>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v2/favorite/add`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<number>>;
      })
    );
  }
  /**
   * Add to favorite
   * @param form form
   * @return OK
   */
  addFavoritesUsingPOST(form: FavoriteObjectForm): __Observable<Array<number>> {
    return this.addFavoritesUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as Array<number>)
    );
  }

  /**
   * Remove from favorite
   * @param form form
   * @return OK
   */
  removeFavoritesUsingPOSTResponse(form: FavoriteObjectForm): __Observable<__StrictHttpResponse<Array<number>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v2/favorite/remove`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<number>>;
      })
    );
  }
  /**
   * Remove from favorite
   * @param form form
   * @return OK
   */
  removeFavoritesUsingPOST(form: FavoriteObjectForm): __Observable<Array<number>> {
    return this.removeFavoritesUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as Array<number>)
    );
  }

  /**
   * Check favorite streets
   *
   * Returns list of favorite object ids.
   *     If requested list is empty, returns all favorite object ids
   *     If requested list is not empty, returned list only contains ids, provided in request<br/>
   * @param params The `FavoriteObjectControllerService.CheckFavoriteObjectsUsingPOSTParams` containing the following parameters:
   *
   * - `type`: type
   *
   * - `objectIds`: objectIds
   *
   * @return OK
   */
  checkFavoriteObjectsUsingPOSTResponse(params: FavoriteObjectControllerService.CheckFavoriteObjectsUsingPOSTParams): __Observable<__StrictHttpResponse<Array<number>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.objectIds;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v2/favorite/${encodeURIComponent(String(params.type))}/check`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<number>>;
      })
    );
  }
  /**
   * Check favorite streets
   *
   * Returns list of favorite object ids.
   *     If requested list is empty, returns all favorite object ids
   *     If requested list is not empty, returned list only contains ids, provided in request<br/>
   * @param params The `FavoriteObjectControllerService.CheckFavoriteObjectsUsingPOSTParams` containing the following parameters:
   *
   * - `type`: type
   *
   * - `objectIds`: objectIds
   *
   * @return OK
   */
  checkFavoriteObjectsUsingPOST(params: FavoriteObjectControllerService.CheckFavoriteObjectsUsingPOSTParams): __Observable<Array<number>> {
    return this.checkFavoriteObjectsUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as Array<number>)
    );
  }

  /**
   * List favorite objects
   * @param params The `FavoriteObjectControllerService.ListUsingGET10Params` containing the following parameters:
   *
   * - `type`: type
   *
   * - `zones`: zones
   *
   * - `toPrice`: toPrice
   *
   * - `sort`: sort
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `fromPrice`: fromPrice
   *
   * - `description`: description
   *
   * - `countryCode`: countryCode
   *
   * - `cityId`: cityId
   *
   * @return OK
   */
  listUsingGET10Response(params: FavoriteObjectControllerService.ListUsingGET10Params): __Observable<__StrictHttpResponse<GisObjectTOPage>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    (params.zones || []).forEach(val => {if (val != null) __params = __params.append('zones', val.toString())});
    if (params.toPrice != null) __params = __params.set('toPrice', params.toPrice.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.fromPrice != null) __params = __params.set('fromPrice', params.fromPrice.toString());
    if (params.description != null) __params = __params.set('description', params.description.toString());
    if (params.countryCode != null) __params = __params.set('countryCode', params.countryCode.toString());
    if (params.cityId != null) __params = __params.set('cityId', params.cityId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/favorite/${encodeURIComponent(String(params.type))}/objects`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GisObjectTOPage>;
      })
    );
  }
  /**
   * List favorite objects
   * @param params The `FavoriteObjectControllerService.ListUsingGET10Params` containing the following parameters:
   *
   * - `type`: type
   *
   * - `zones`: zones
   *
   * - `toPrice`: toPrice
   *
   * - `sort`: sort
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `fromPrice`: fromPrice
   *
   * - `description`: description
   *
   * - `countryCode`: countryCode
   *
   * - `cityId`: cityId
   *
   * @return OK
   */
  listUsingGET10(params: FavoriteObjectControllerService.ListUsingGET10Params): __Observable<GisObjectTOPage> {
    return this.listUsingGET10Response(params).pipe(
      __map(_r => _r.body as GisObjectTOPage)
    );
  }
}

module FavoriteObjectControllerService {

  /**
   * Parameters for checkFavoriteObjectsUsingPOST
   */
  export interface CheckFavoriteObjectsUsingPOSTParams {

    /**
     * type
     */
    type: 'BUILDING' | 'COLLECTION' | 'STREET' | 'UNKNOWN_DISCRIMINATOR' | 'UNRECOGNIZED';

    /**
     * objectIds
     */
    objectIds: Array<number>;
  }

  /**
   * Parameters for listUsingGET10
   */
  export interface ListUsingGET10Params {

    /**
     * type
     */
    type: 'BUILDING' | 'COLLECTION' | 'STREET' | 'UNKNOWN_DISCRIMINATOR' | 'UNRECOGNIZED';

    /**
     * zones
     */
    zones?: Array<number>;

    /**
     * toPrice
     */
    toPrice?: number;

    /**
     * sort
     */
    sort?: string;

    /**
     * pageSize
     */
    pageSize?: number;

    /**
     * pageNumber
     */
    pageNumber?: number;

    /**
     * fromPrice
     */
    fromPrice?: number;

    /**
     * description
     */
    description?: string;

    /**
     * countryCode
     */
    countryCode?: string;

    /**
     * cityId
     */
    cityId?: number;
  }
}

export { FavoriteObjectControllerService }
