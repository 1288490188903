<button appButton appRouterBack class="tab back-button align-center">
  <div class="display-flex align-center">
    <app-icon [icon]="chevronIcon" class="margin-right"></app-icon>
    <span>{{ 'marketplace.street_overview.back' | translate }}</span>
  </div>
</button>

<div class="data-container container-column dark">
  <ng-container *ngIf="!isError; else noData">
    <div [ngClass]="{ skeleton: isLoading }" class="overview-header">
      <div class="street-title width-100 margin-right">
        <h1 class="name">{{ building.mainName }}</h1>

        <div class="place">
          {{ building.secondaryName }}
        </div>
      </div>

      <div *mediaIf="'medium'" class="display-flex width-100 justify-space-between">
        <div class="street-price">
          <div class="street-price_label font-lg">
            {{ 'marketplace.building_overview.building_price' | translate }}
          </div>

          <div class="street-price_value">
            <span class="font-hg weight-semibold">
              {{ building.marketPrice | precision: 'DWRLD' }} DWRLD
            </span>
            <span class="color-primary">$ {{ building.marketPrice | baseRate: 'DWRLD' }}</span>
          </div>

          <div
            *ngIf="userBuyPrice || building.buyPrice"
            class="display-flex margin-top-tn text-right opacity-50">
            <div *ngIf="building.buyPrice" class="font-sm">
              <span class="margin-right-tn">
                {{ 'street.ui.street_details.max_bid' | translate }}
              </span>
              <span>{{ building.buyPrice | precision: 'DWRLD' }} DWRLD</span>
            </div>

            <ng-container *ngIf="userBuyPrice && building.buyPrice">
              <div class="margin-left-tn margin-right-tn">/</div>
            </ng-container>

            <div *ngIf="userBuyPrice" class="font-sm">
              <span class="margin-right-tn">
                {{ 'street.ui.street_details.your_price' | translate }}
              </span>
              <span>{{ userBuyPrice | precision: 'DWRLD' }} DWRLD</span>
            </div>
          </div>
        </div>

        <ng-container *ngIf="building.isEnabledActions">
          <div *mediaIf="'large'" class="street-actions">
      <!--      *ngIf="!building.isOwner; else sellTmpl"-->
            <button
              (click)="buyBuilding()"
              *ngIf="(!building.isOwner && (building.sellPrice || building.marketId === 0)); else sellTmpl"
              appButton
              class="solid primary">
              <ng-container *ngIf="!userBuyPrice; else orderTmpl">
                {{
                building.sellPrice || building.marketId === 0
                  ? building.marketId
                    ? ('marketplace.streets.button.buy_now' | translate)
                    : ('marketplace.streets.button.mint' | translate)
                  : ('marketplace.streets.button.offer_price' | translate)
                }}
              </ng-container>
              <ng-template #orderTmpl>
                {{ 'marketplace.streets.button.view_order' | translate }}
              </ng-template>
            </button>
            <ng-template #sellTmpl>
             <!-- <button (click)="sell()" appButton class="solid primary">
                {{
                !building.sellPrice
                  ? ('marketplace.streets.button.sell_street' | translate)
                  : ('marketplace.streets.button.cancel_sell' | translate)
                }}
              </button>-->
            </ng-template>

            <ng-container *ngTemplateOutlet="basketBtnTmp"></ng-container>

            <ng-container *ngTemplateOutlet="favoriteBtnTmp"></ng-container>

          </div>
        </ng-container>
        <ng-container *ngIf="!building.isEnabledActions">
          <div *mediaIf="'large'" class="street-actions">
            <ng-container *ngTemplateOutlet="favoriteBtnTmp"></ng-container>
          </div>
        </ng-container>

      </div>
    </div>

    <div class="wrapper">
      <div *mediaIf="'large'" class="street-info">
        <ng-container *ngTemplateOutlet="streetInfo"></ng-container>
      </div>

      <app-street-images
        [largeMap]="true"
        [navigateToMap]="true"
        [ngClass]="{ skeleton: isLoading }"
        [onlyLinesMap]="true"
        [asset]="building"
        class="margin-bottom-lg street-image width-100"
        mapStyle="DARK"></app-street-images>

      <div *mediaIf="'medium down'" class="street-addition-info">
        <div *mediaIf="'small only'" class="street-price">
          <div class="street-price_label">
            {{ 'marketplace.street_overview.street_price' | translate }}
          </div>

          <div class="street-price_value">
            <span class="font-hg weight-semibold color-white">
              {{ building.marketPrice | precision: 'DWRLD' }} DWRLD
            </span>
            <span class="color-primary weight-medium">
              $ {{ building.marketPrice | baseRate: 'DWRLD' }}
            </span>
          </div>

          <div
            *ngIf="userBuyPrice || building.buyPrice"
            class="display-flex margin-top-tn text-right opacity-50 color-white">
            <div *ngIf="building.buyPrice" class="font-sm">
              <span class="margin-right-tn">
                {{ 'street.ui.street_details.max_bid' | translate }}
              </span>
              <span>{{ building.buyPrice | precision: 'DWRLD' }} DWRLD</span>
            </div>

            <ng-container *ngIf="userBuyPrice && building.buyPrice">
              <div class="margin-left-tn margin-right-tn">/</div>
            </ng-container>

            <div *ngIf="userBuyPrice" class="font-sm">
              <span class="margin-right-tn">
                {{ 'street.ui.street_details.your_price' | translate }}
              </span>
              <span>{{ userBuyPrice | precision: 'DWRLD' }} DWRLD</span>
            </div>
          </div>
        </div>

        <div *ngIf="building.isEnabledActions" class="street-actions">
          <button
            (click)="buyBuilding()"
            *ngIf="!building.isOwner; else sellTmpl"
            appButton
            class="solid primary width-100 medium margin-right">
            <ng-container *ngIf="!userBuyPrice; else orderTmpl">
              {{
                building.sellPrice || !building.marketId
                  ? ('marketplace.streets.button.buy_now' | translate)
                  : ('marketplace.streets.button.offer_price' | translate)
              }}
            </ng-container>
            <ng-template #orderTmpl>
              {{ 'marketplace.streets.button.view_order' | translate }}
            </ng-template>
          </button>
          <ng-template #sellTmpl>
            <button (click)="sell()" appButton class="solid primary width-100 medium margin-right">
              {{
                !building.sellPrice
                  ? ('marketplace.streets.button.sell_street' | translate)
                  : ('marketplace.streets.button.cancel_sell' | translate)
              }}
            </button>
          </ng-template>

          <ng-container *ngTemplateOutlet="basketBtnTmp"></ng-container>

          <ng-container *ngTemplateOutlet="favoriteBtnTmp"></ng-container>

        </div>
        <div *ngIf="!building.isEnabledActions" class="street-actions">
          <ng-container *ngTemplateOutlet="favoriteBtnTmp"></ng-container>
        </div>
      </div>

      <div *mediaIf="'medium down'" class="street-info">
        <ng-container *ngTemplateOutlet="streetInfo"></ng-container>
      </div>
    </div>
  </ng-container>

  <ng-template #noData>
    <app-no-data
      [canRedirect]="false"
      class="no-data"
      text="The building is not found"></app-no-data>
  </ng-template>
</div>

<ng-template #streetInfo>
  <div [ngClass]="{ skeleton: isLoading }" class="street-info_container">
    <div class="street-info_container_label color-white font-lg weight-medium margin-bottom-lg">
      {{ 'marketplace.street_overview.details' | translate }}
    </div>

    <app-street-overview-details
      [building]="building"
      class="street-info_container_content"></app-street-overview-details>
  </div>

  <div [ngClass]="{ skeleton: isLoading }" class="street-info_container">
    <div class="street-info_container_label color-white font-lg weight-medium margin-bottom-lg">
      {{ 'marketplace.building_overview.building_history' | translate }}
    </div>

    <app-street-history-list
      (paginationChange)="onPaginationChange($event)"
      [history]="buildingHistory"
      [pagination]="pagination"
      class="width-100 color-white"></app-street-history-list>
  </div>
</ng-template>

<ng-template #favoriteBtnTmp>
  <app-icon
    (click)="toggleFavorite()"
    [class.favorite]="building.isFavorite"
    [icon]="iconStar"
    class="column favorite favorite-icon font-xhg color-warn"></app-icon>
</ng-template>
<ng-template #basketBtnTmp>
  <ng-container *ngIf="!building.marketId">
    <app-icon
      (click)="toggleBasketWithFreeStreet()"
      *ngIf="!building.cartId"
      [icon]="iconCart"
      class="font-xhg color-primary-light cursor-pointer margin-left"></app-icon>
    <app-icon
      (click)="toggleBasketWithFreeStreet()"
      *ngIf="building.cartId"
      [icon]="iconBagCross"
      class="bag-cross font-xhg color-warn cursor-pointer margin-left"></app-icon>
  </ng-container>
</ng-template>
