import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { fromEvent, ReplaySubject, Subject } from 'rxjs';

interface Breakpoints {
  small: string;
  medium: string;
  large: string;
  xlarge: string;
  xxlarge: string;
}
export type MediaName = keyof Breakpoints;
type MediaNames = Array<keyof Breakpoints>;
type Queries = {
  name: keyof Breakpoints;
  value: string;
}[];

@Injectable({ providedIn: 'root' })
export class MediaQuery {
  queries: Queries = [];
  current: MediaName = 'small';
  breakpoints: MediaNames = ['small', 'medium', 'large', 'xlarge', 'xxlarge'];

  private mediaSubj = new ReplaySubject<{ new: MediaName; previous: MediaName }>(1);
  mediaChanged = this.mediaSubj.asObservable();
  private resizeSubj = new Subject<MediaName>();
  resized = this.resizeSubj.asObservable();

  private matchMediaCache = new Map<string, boolean>();

  constructor(@Inject(PLATFORM_ID) private platformId: string) {
    // TODO: we use platformId for SSR only medium up screen
    // we can parse userAgent instead to determine breakpoints for SSr
  }

  /**
   * Initializes the media query service
   * TODO: pass breakpoint config with media InjectionToken
   * @function
   * @private
   */
  init(options: { breakpoints: Breakpoints; fontSizeVar: number }): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    const toEm = (breakpointValue: string) =>
      `${parseInt(breakpointValue, 10) / options.fontSizeVar}em`;
    const emBreakpoints: Partial<Breakpoints> = {};
    (Object.keys(options.breakpoints) as MediaNames).forEach(key => {
      emBreakpoints[key] = toEm(options.breakpoints[key]);
    });
    for (const key in emBreakpoints) {
      // eslint-disable-next-line no-prototype-builtins
      if (emBreakpoints.hasOwnProperty(key)) {
        this.queries.push({
          name: key as MediaName,
          value: `only screen and (min-width: ${emBreakpoints[key as MediaName]})`,
        });
      }
    }
    this.current = this.getCurrentSize();
    this.observeResize();
    this.mediaSubj.next({ new: this.current, previous: this.current });
  }

  /**
   * Checks if the screen is at least as wide as a breakpoint.
   * @function
   * @param {String} size - Name of the breakpoint to check.
   * @returns {Boolean} `true` if the breakpoint matches, `false` if it's smaller.
   */
  atLeast(size: MediaName): boolean {
    if (isPlatformServer(this.platformId)) {
      return size.includes('large');
    }
    const query = this.get(size);
    if (query) {
      return this.matchMedia(query);
    }
    return false;
  }

  private matchMedia(query: string): boolean {
    const cachedResult = this.matchMediaCache.get(query);
    if (cachedResult !== undefined) {
      return cachedResult;
    }
    const result = matchMedia(query).matches;
    this.matchMediaCache.set(query, result);
    return result;
  }

  /**
   * Checks if the screen matches to a breakpoint.
   * @function
   * @param {String} size - Name of the breakpoint to check, either 'medium only' or 'medium' or even 'medium down'.
   * Omitting 'only' falls back to using atLeast() method.
   * @returns {Boolean} `true` if the breakpoint matches, `false` if it does not.
   */
  is(size: string): boolean {
    if (typeof size === 'boolean') {
      return size;
    }
    const sizeParts = size.trim().split(' ');
    if (sizeParts.length > 1 && sizeParts[1] === 'only') {
      if (sizeParts[0] === this.getCurrentSize()) {
        return true;
      }
    } else if (sizeParts.length > 1 && sizeParts[1] === 'down') {
      const current = this.breakpoints.indexOf(this.getCurrentSize());
      const max = this.breakpoints.indexOf(sizeParts[0] as MediaName);
      return current <= max;
    } else {
      return this.atLeast(sizeParts[0] as MediaName);
    }
    return false;
  }

  /**
   * Gets the media query of a breakpoint.
   * @function
   * @param {String} size - Name of the breakpoint to get.
   * @returns {String|null} - The media query of the breakpoint, or `null` if the breakpoint doesn't exist.
   */
  get(size: MediaName): string | null {
    // eslint-disable-next-line @typescript-eslint/no-for-in-array
    for (const i in this.queries) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.queries.hasOwnProperty(i)) {
        const query = this.queries[i];
        if (size === query.name) {
          return query.value;
        }
      }
    }

    return null;
  }

  /**
   * Gets the current breakpoint name by testing every breakpoint and returning the last one to match (the biggest one).
   * @function
   * @private
   * @returns {String} Name of the current breakpoint.
   */
  private getCurrentSize(): MediaName {
    if (isPlatformServer(this.platformId)) {
      return 'large';
    }
    let matched: unknown;
    for (const q of this.queries) {
      if (this.matchMedia(q.value)) {
        matched = q;
      }
    }
    if (typeof matched === 'object') {
      return (matched as { name: MediaName }).name;
    } else {
      return matched as MediaName;
    }
  }

  /**
   * Activates the breakpoint watcher, which fires an event on the breakpoint changes.
   * @function
   * @private
   */
  private observeResize(): void {
    fromEvent(window, 'resize').subscribe(() => {
      this.matchMediaCache = new Map();
      const newSize = this.getCurrentSize();
      if (newSize !== this.current) {
        // Change the current media query
        const previous = this.current;
        this.current = newSize;
        // Broadcast the media query change
        this.mediaSubj.next({ new: newSize, previous });
      }
      this.resizeSubj.next();
    });
  }
}
