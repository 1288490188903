/* eslint-disable prefer-arrow-functions/prefer-arrow-functions */
import { Location } from '@angular/common';
import { APP_INITIALIZER, Provider } from '@angular/core';
import { appNav } from 'app/app.navigation';
import { navigateOnError } from 'core/utils/rxjs/navigate-on-error.operator';

import { AppLocale } from './app-locale.service';
import { I18nService } from './i18n.service';

export function provideI18nInitializer(): Provider {
  return {
    provide: APP_INITIALIZER,
    useFactory: i18nLoader,
    deps: [I18nService, Location],
    multi: true,
  };
}

export function i18nLoader(loader: I18nService, location: Location): () => Promise<unknown> {
  return () => {
    const path = location.path(false);
    let locale = AppLocale.getLocaleFromUrl(path);
    if (locale) {
      locale = locale
        .split('/')
        .filter(i => i !== '')
        .join('');
    }
    return loader
      .load(locale)
      .pipe(navigateOnError(appNav.commonNav.serviceUnavailable()))
      .toPromise();
  };
}
