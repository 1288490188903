import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { provideSocketConfig } from './socket.config';
import { SocketService } from './socket.service';
import { WebSocketConfig } from './socket.type';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [SocketService],
})
export class SocketModule {
  static withConfig(wsConfig: WebSocketConfig): ModuleWithProviders<SocketModule> {
    return {
      ngModule: SocketModule,
      providers: [provideSocketConfig(wsConfig)],
    };
  }
}
