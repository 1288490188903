import { Directive, ElementRef, HostListener, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { appNav } from 'app/app.navigation';
import { navigateByUrl } from 'core/utils/router.utils';

@Directive({
  selector: '[appPublicProfile]',
})
export class PublicProfileDirective implements OnChanges {
  @Input() appPublicProfile!: string;

  constructor(private elementRef: ElementRef<HTMLElement>, private router: Router) {}

  ngOnChanges(changes: SimpleChanges<{ appPublicProfile: string }>): void {
    const currValue: string = changes.appPublicProfile?.currentValue || '';
    const isExist = this.isValueExist(currValue);

    if (!isExist || currValue.includes('User_') || currValue.includes('Anonymous')) {
      this.elementRef.nativeElement.style.pointerEvents = 'none';
    } else {
      this.elementRef.nativeElement.style.pointerEvents = 'auto';
    }
  }

  @HostListener('click') onClick(): void {
    if (this.appPublicProfile.includes('User_') || this.appPublicProfile.includes('Anonymous')) {
      return;
    }
    navigateByUrl(appNav.profileNav.publicRoot(this.appPublicProfile));
  }

  isValueExist(value?: string): boolean {
    return !!value;
  }
}
