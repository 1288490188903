import { Injectable } from '@angular/core';
import { MysteryBoxListResponse, MysteryBoxMetaInfoResponse, MysteryBoxResponse } from 'api/models';
import { MysteryBoxControllerService } from 'api/services';
import { UserService } from 'profile/services/user.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MysteryBoxService {
  private audioMutedKeyPrefix = 'mystery-box-audio';
  mysteryBoxMeta: MysteryBoxMetaInfoResponse = {};

  constructor(
    private userService: UserService,
    private mysteryBoxController: MysteryBoxControllerService
  ) {}

  get audioMutedKey(): string {
    return `${this.audioMutedKeyPrefix}-${this.userService.user.id}`;
  }

  toggleAudio(): void {
    const value = this.getAudioValue();
    localStorage.setItem(this.audioMutedKey, JSON.stringify(!value));
  }

  getAudioValue(): boolean {
    const value = localStorage.getItem(this.audioMutedKey);
    return value === 'true';
  }

  load(): Observable<MysteryBoxMetaInfoResponse> {
    return this.mysteryBoxController
      .mysteryMetaBoxListUsingGET({
        pageSize: 10,
        pageNumber: 0,
      })
      .pipe(map(list => (this.mysteryBoxMeta = (list.list || [])[0] || {})));
  }

  openBox(boxId: number): Observable<MysteryBoxResponse> {
    return this.mysteryBoxController.openBoxUsingPOST(boxId);
  }

  buyBox(idMysteryBoxMetaInfo: number): Observable<MysteryBoxResponse> {
    return this.mysteryBoxController.buyBoxUsingPOST({
      currency: 'DWRLD',
      idMysteryBoxMetaInfo,
    });
  }

  getBoxList(): Observable<MysteryBoxListResponse> {
    return this.mysteryBoxController.mysteryBoxListUsingGET({
      pageNumber: 0,
      pageSize: 30,
      isOpen: true,
    });
  }
}
