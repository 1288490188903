import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { appNav, lazyModalsNav } from 'app/app.navigation';
import { I18nService } from 'core/modules/i18n/i18n.service';
import { ModalRouter } from 'core/modules/modal';
import { NotificationMessageType } from 'profile/enum/notification-message-type';
import { UserNotification } from 'profile/models/notification.model';
import {NicknamePipe} from "core/pipes/app-pipes/nickname.pipe";
import {UserService} from "profile/services/user.service";

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss'],
})
export class NotificationItemComponent implements OnChanges, OnInit {
  @Input() notification!: UserNotification;
  @Input() expanded!: boolean;

  @Output() readNotificationEvent = new EventEmitter();
  @Output() expand = new EventEmitter<number>();

  readonly appNav = appNav;
  readonly notificationMessageType = NotificationMessageType;

  hasLongText = false;

  constructor(private i18nService: I18nService, private modalRouter: ModalRouter, private userService: UserService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.notification && changes.notification.currentValue) {
      this.hasLongText = this.i18nService.keyExists(this.notification.long);
    }
  }

  ngOnInit() {
    if (this.notification.type === NotificationMessageType.TOPIC_USER_INTERNAL_RECEIVE && this.notification.params)  {
      const nicknamePipe = new NicknamePipe(this.userService);
      this.notification.params.userNameFrom = nicknamePipe.transform(this.notification.params?.userNameFrom as string);
    }
  }

  readNotificationAction(): void {
    this.readNotificationEvent.emit();
  }

  onToggle(expanded: boolean): void {
    this.expanded = expanded;
    if (expanded) {
      this.expand.emit(this.notification.id);
    }
  }

  openMysteryBox(): void {
    this.modalRouter.navigate(lazyModalsNav.dashboard.openMysteryBox());
  }
}
