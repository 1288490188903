import { Injectable } from '@angular/core';
import {
  CityTOListResponse,
  CityTOResponse,
  CountryResponseTO,
  List,
  RegionTOListResponse,
  StreetGeoJsonResponse,
} from 'api/models';
import { GisControllerService } from 'api/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Wallet } from '../../cashier/models/wallet.model';

@Injectable({ providedIn: 'root' })
export class GisService {
  accounts: Wallet[] = [];

  readonly forbiddenCountries = [
    'aq',
    'bv',
    'tf',
    'gp',
    'hm',
    'yt',
    'an',
    'nc',
    're',
    'sh',
    'mf',
    'pm',
    'gs',
    'um',
    'wf',
  ];

  constructor(private api: GisControllerService) {}

  findStreet(
    id?: number,
    name?: string,
    lon?: number,
    lat?: number
  ): Observable<StreetGeoJsonResponse> {
    return this.api.findStreetUsingGET({ id, name, lon, lat });
  }

  findStreets(
    name: string,
    pageSize?: number,
    pageNumber?: number,
    cityId?: number,
    zone?: number
  ): Observable<List> {
    return this.api.listStreetsUsingGET({
      name,
      pageSize,
      pageNumber,
      cityId,
      zones: zone ? [zone] : [1, 2, 3, 4, 5],
    });
  }

  findCityStartsWith(
    name: string,
    countryCode?: string,
    pageSize?: number,
    regionCode?: string,
    pageNumber?: number
  ): Observable<CityTOListResponse> {
    return this.api.listCitiesUsingGET({
      name,
      countryCode,
      pageSize,
      regionCode,
      pageNumber,
    });
  }

  findCountryStartsWith(
    name: string,
    pageSize?: number,
    countryCode?: string,
    regionCode?: string,
    containsCities = false
  ): Observable<CountryResponseTO[]> {
    return this.api
      .listCountriesUsingGET({ name, pageSize, countryCode, regionCode, containsCities })
      .pipe(
        map(res =>
          (res.elements || []).filter(c => !this.forbiddenCountries.includes(c.code || ''))
        )
      );
  }

  findCityById(id: number): Observable<CityTOResponse> {
    return this.api.findCityByIdUsingGET(id);
  }

  getRegions(): Observable<RegionTOListResponse> {
    return this.api.listRegionsUsingGET();
  }
}
