import {
  ActivatedRouteSnapshot,
  ActivationEnd,
  NavigationEnd,
  NavigationExtras,
  NavigationStart,
  Router,
  UrlCreationOptions,
  UrlTree,
} from '@angular/router';
import { asyncScheduler, ReplaySubject } from 'rxjs';

import { RouteData } from '../models/route.data';
import { ScrollRestoration } from '../services/scroll-restoration.service';

type RouteParams<F extends (...args: unknown[]) => string> = (...args: Parameters<F>) => string;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RouteDefinition = (...args: any[]) => string;

interface Routes {
  [key: string]: RouteDefinition;
}

export type ParsedRoutes<T extends Routes> = {
  [key in keyof T]: RouteParams<T[key]>;
};

/** Helper class to override routeReuseStrategy at runtime
 * unfortunatelly Angular router initialize it only at startup
 */
export class RouterBehavior {
  static router: Router;
  static previousUrl: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static navigationState: { [k: string]: any };

  private static routeDataSubj = new ReplaySubject<RouteData>(1);
  static routeDataChange = RouterBehavior.routeDataSubj.asObservable();

  private static defaultReuse: (
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ) => boolean;
  private static snapshotData: RouteData = {};
  private static trigger?: 'imperative' | 'popstate' | 'hashchange';

  static get isReloadStrategy(): boolean {
    return this.router.routeReuseStrategy.shouldReuseRoute !== this.defaultReuse;
  }

  static get routeData(): RouteData {
    return this.snapshotData;
  }

  static get navigationTrigger(): 'imperative' | 'popstate' | 'hashchange' | undefined {
    return this.trigger;
  }

  static get isImperative(): boolean {
    return this.trigger === 'imperative';
  }

  static setup(router: Router, scrollRestoration?: ScrollRestoration): void {
    this.router = router;
    if (scrollRestoration) {
      scrollRestoration.setup();
    }
    this.defaultReuse = router.routeReuseStrategy.shouldReuseRoute;
    this.observeEvents();
  }

  static reloadReuseStrategy(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  static defaultReuseStrategy(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = this.defaultReuse;
  }

  private static observeEvents(): void {
    const isPrimaryOutlet = (snapshot: ActivatedRouteSnapshot) =>
      snapshot.outlet === 'primary' && snapshot.parent?.outlet === 'primary';
    let snapshotData: RouteData = {};
    let navStartUrl = '';
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.trigger = event.navigationTrigger;
        this.snapshotData = snapshotData = {};
        navStartUrl = this.router.url;
      }
      // we provide snapshot only of primary outlet of application routing
      if (event instanceof ActivationEnd && isPrimaryOutlet(event.snapshot)) {
        // aggregate snapshot date through all router levels
        snapshotData = Object.assign(snapshotData, event.snapshot.data || {});
      }
      // set snapshotData on activation end to prevent UI flickering
      if (event instanceof NavigationEnd) {
        this.previousUrl = navStartUrl;
        this.snapshotData = snapshotData;
        this.navigationState = this.router.getCurrentNavigation()?.extras.state || {};
        this.routeDataSubj.next(this.snapshotData);
      }
    });
  }
}

/** Create UrlTree from string, same as default but use string instead string[] */
export function createUrlTree(url: string, extras?: UrlCreationOptions): UrlTree {
  const urlTree = RouterBehavior.router.createUrlTree([url], extras);
  delete urlTree.root.children['primaryModal'];
  return urlTree;
}

/**
 * Navigate by string url using NavigationExtras,
 * because Router allows only NavigationBehaviorOptions at navigateByUrl()
 */
export function navigateByUrl(
  url: string,
  extras?: NavigationExtras,
  sameUrlReload?: boolean
): Promise<boolean> {
  const urlTree = createUrlTree(url, extras);
  // close any modals on primary router navigation
  delete urlTree.root.children['primaryModal'];
  if (sameUrlReload && !RouterBehavior.isReloadStrategy) {
    RouterBehavior.reloadReuseStrategy();
    asyncScheduler.schedule(() => {
      RouterBehavior.defaultReuseStrategy();
    });
  }
  return RouterBehavior.router.navigateByUrl(urlTree, extras);
}

export function createUrl(url: string, moduleRoot?: string): string {
  moduleRoot = moduleRoot ? `/${moduleRoot}/` : '/';
  return url ? `${moduleRoot}${url}` : moduleRoot.replace(/\/$/, '');
}

export function createNavigation<T extends Routes, R = ParsedRoutes<T>>(
  from: T,
  moduleRoot?: string,
  overrides?: Partial<ParsedRoutes<T>>
): R {
  const createdRoutes: { [key: string]: unknown } = {};
  Object.keys(from).forEach(key => {
    createdRoutes[key] = (...args: unknown[]) =>
      createUrl(from[key].apply(null, args), moduleRoot || '/').replace('//', '/');
  });
  Object.assign(createdRoutes, overrides);
  return createdRoutes as unknown as R;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type paramExtractor<T> = (key: keyof T) => any;

// eslint-disable-next-line prefer-arrow-functions/prefer-arrow-functions
export function extractParam<T>(key: keyof T): string {
  return `:${key as string}`;
}
