import { NftControllerService } from 'api/services/nft-controller.service';
import { UIItem } from 'core/models/ui-item';
import { GenericFormControl, GenericFormGroup } from 'core/utils/form-generics';

export type HistorySideUIItem = UIItem<
  NftControllerService.GetNftOrderHistoryUsingGETParams['side']
>;
export type HistoryOrderTypeUIItem = UIItem<
  NftControllerService.GetNftOrderHistoryUsingGETParams['orderType']
>;

export type HistoryNftTypesUIItem = UIItem<NftControllerService.GetNftOrderHistoryUsingGETParams['nftType']>

export type HistoryFilterData = {
  closeFrom: number;
  closeTo: number;
  street: string;
  side: HistorySideUIItem;
  orderType: HistoryOrderTypeUIItem;
  nftType: HistoryNftTypesUIItem
};

export const historySides: UIItem<NftControllerService.GetNftOrderHistoryUsingGETParams['side']>[] =
  [
    new UIItem($t('marketplace.history-filter.side.all'), undefined),
    new UIItem($t('marketplace.history-filter.side.buy'), 'BUY'),
    new UIItem($t('marketplace.history-filter.side.sell'), 'SELL'),
  ];

export const historyNftTypes: UIItem<NftControllerService.GetNftOrderHistoryUsingGETParams['nftType']>[] =
  [
    new UIItem($t('marketplace.history-filter.nft-type.all'), undefined),
    new UIItem($t('marketplace.history-filter.nft-type.building'), 'BUILDING'),
    new UIItem($t('marketplace.history-filter.nft-type.street'), 'STREET'),
  ];

export const historyTypes: UIItem<
  NftControllerService.GetNftOrderHistoryUsingGETParams['orderType']
>[] = [
  new UIItem($t('marketplace.history-filter.type.all'), undefined),
  new UIItem($t('marketplace.history-filter.type.market'), 'MARKET'),
  new UIItem($t('marketplace.history-filter.type.limit'), 'LIMIT'),
];

export class HistoryFilterForm extends GenericFormGroup<HistoryFilterData> {
  constructor() {
    super({
      closeFrom: new GenericFormControl(),
      closeTo: new GenericFormControl(),
      side: new GenericFormControl<HistorySideUIItem>(),
      nftType: new GenericFormControl<HistoryNftTypesUIItem>(),
      street: new GenericFormControl(),
      orderType: new GenericFormControl<HistoryOrderTypeUIItem>(),
    });
  }
}
