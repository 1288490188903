<div class="street-image">
  <div class="street-image-box"></div>
  <div class="street-image-marker"></div>
</div>

<div class="street-info">
  <div class="street-info-title">
    {{ nftBuyEvent?.ownerName || 'User_' | nickname }} {{ 'main.mystery_box.toast' | translate }}
  </div>
  <div class="street-info-name" (click)="openPreview()">{{ nftBuyEvent?.streetName }}</div>
</div>

<div class="left-bg"></div>
<div class="right-bg"></div>

<div class="bg"></div>
