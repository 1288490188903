/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { OrderTOResponse } from '../models/order-toresponse';
import { CloseOrderForm } from '../models/close-order-form';
import { OrderTOList } from '../models/order-tolist';
import { CloseOrderOnMarketForm } from '../models/close-order-on-market-form';
import { OrderPageTOResponse } from '../models/order-page-toresponse';
import { DecentCoinOverviewTOResponse } from '../models/decent-coin-overview-toresponse';
import { OrderDetailsTOResponse } from '../models/order-details-toresponse';
import { OrderTOListResponse } from '../models/order-tolist-response';
import { OpenOrderForm } from '../models/open-order-form';
import { OrderWithMoneyResultTOList } from '../models/order-with-money-result-tolist';

/**
 * Order Controller
 */
@Injectable({
  providedIn: 'root',
})
class OrderControllerService extends __BaseService {
  static readonly closeUsingPOSTPath = '/api/v1/order/close';
  static readonly closeAllOrdersUsingPOSTPath = '/api/v1/order/close-all';
  static readonly closeOnMarketUsingPOSTPath = '/api/v1/order/close-by-market-id';
  static readonly getDwrldHistoryUsingGETPath = '/api/v1/order/dcw/list';
  static readonly getDecentCoinOverviewUsingGETPath = '/api/v1/order/dcw/overview';
  static readonly orderDetailsUsingGETPath = '/api/v1/order/details/{orderId}';
  static readonly historyUsingGET2Path = '/api/v1/order/history';
  static readonly historyUsingGET1Path = '/api/v1/order/history-view';
  static readonly listUsingGET15Path = '/api/v1/order/list';
  static readonly historyNftUsingGETPath = '/api/v1/order/nft/history';
  static readonly openUsingPOSTPath = '/api/v1/order/open';
  static readonly getStreetsSalesUsingGETPath = '/api/v1/order/streets/latest-sell';
  static readonly getOrderViewUsingGETPath = '/api/v1/order/view';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * close
   * @param form form
   * @return OK
   */
  closeUsingPOSTResponse(form: CloseOrderForm): __Observable<__StrictHttpResponse<OrderTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/order/close`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderTOResponse>;
      })
    );
  }
  /**
   * close
   * @param form form
   * @return OK
   */
  closeUsingPOST(form: CloseOrderForm): __Observable<OrderTOResponse> {
    return this.closeUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as OrderTOResponse)
    );
  }

  /**
   * Close limit 1000 orders at once
   * @param params The `OrderControllerService.CloseAllOrdersUsingPOSTParams` containing the following parameters:
   *
   * - `side`: side
   *
   * - `markets`: markets
   *
   * - `isNotNft`: isNotNft
   *
   * - `isNft`: isNft
   *
   * @return OK
   */
  closeAllOrdersUsingPOSTResponse(params: OrderControllerService.CloseAllOrdersUsingPOSTParams): __Observable<__StrictHttpResponse<OrderTOList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.side != null) __params = __params.set('side', params.side.toString());
    (params.markets || []).forEach(val => {if (val != null) __params = __params.append('markets', val.toString())});
    if (params.isNotNft != null) __params = __params.set('isNotNft', params.isNotNft.toString());
    if (params.isNft != null) __params = __params.set('isNft', params.isNft.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/order/close-all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderTOList>;
      })
    );
  }
  /**
   * Close limit 1000 orders at once
   * @param params The `OrderControllerService.CloseAllOrdersUsingPOSTParams` containing the following parameters:
   *
   * - `side`: side
   *
   * - `markets`: markets
   *
   * - `isNotNft`: isNotNft
   *
   * - `isNft`: isNft
   *
   * @return OK
   */
  closeAllOrdersUsingPOST(params: OrderControllerService.CloseAllOrdersUsingPOSTParams): __Observable<OrderTOList> {
    return this.closeAllOrdersUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as OrderTOList)
    );
  }

  /**
   * Use to close SELL order for NFT by marketId
   * @param form form
   * @return OK
   */
  closeOnMarketUsingPOSTResponse(form: CloseOrderOnMarketForm): __Observable<__StrictHttpResponse<OrderTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/order/close-by-market-id`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderTOResponse>;
      })
    );
  }
  /**
   * Use to close SELL order for NFT by marketId
   * @param form form
   * @return OK
   */
  closeOnMarketUsingPOST(form: CloseOrderOnMarketForm): __Observable<OrderTOResponse> {
    return this.closeOnMarketUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as OrderTOResponse)
    );
  }

  /**
   * getDwrldHistory
   * @param params The `OrderControllerService.GetDwrldHistoryUsingGETParams` containing the following parameters:
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * @return OK
   */
  getDwrldHistoryUsingGETResponse(params: OrderControllerService.GetDwrldHistoryUsingGETParams): __Observable<__StrictHttpResponse<OrderPageTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/dcw/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderPageTOResponse>;
      })
    );
  }
  /**
   * getDwrldHistory
   * @param params The `OrderControllerService.GetDwrldHistoryUsingGETParams` containing the following parameters:
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * @return OK
   */
  getDwrldHistoryUsingGET(params: OrderControllerService.GetDwrldHistoryUsingGETParams): __Observable<OrderPageTOResponse> {
    return this.getDwrldHistoryUsingGETResponse(params).pipe(
      __map(_r => _r.body as OrderPageTOResponse)
    );
  }

  /**
   * getDecentCoinOverview
   * @return OK
   */
  getDecentCoinOverviewUsingGETResponse(): __Observable<__StrictHttpResponse<DecentCoinOverviewTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/dcw/overview`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DecentCoinOverviewTOResponse>;
      })
    );
  }
  /**
   * getDecentCoinOverview
   * @return OK
   */
  getDecentCoinOverviewUsingGET(): __Observable<DecentCoinOverviewTOResponse> {
    return this.getDecentCoinOverviewUsingGETResponse().pipe(
      __map(_r => _r.body as DecentCoinOverviewTOResponse)
    );
  }

  /**
   * Order details for open or history
   * @param orderId orderId
   * @return OK
   */
  orderDetailsUsingGETResponse(orderId: number): __Observable<__StrictHttpResponse<OrderDetailsTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/details/${encodeURIComponent(String(orderId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderDetailsTOResponse>;
      })
    );
  }
  /**
   * Order details for open or history
   * @param orderId orderId
   * @return OK
   */
  orderDetailsUsingGET(orderId: number): __Observable<OrderDetailsTOResponse> {
    return this.orderDetailsUsingGETResponse(orderId).pipe(
      __map(_r => _r.body as OrderDetailsTOResponse)
    );
  }

  /**
   * history
   * @param params The `OrderControllerService.HistoryUsingGET2Params` containing the following parameters:
   *
   * - `street`: street
   *
   * - `state`: state
   *
   * - `sort`: sort
   *
   * - `side`: side
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `orderType`: orderType
   *
   * - `markets`: markets
   *
   * - `isNotNft`: isNotNft
   *
   * - `isNft`: isNft
   *
   * - `forAllUsers`: forAllUsers
   *
   * - `dateTo`: dateTo
   *
   * - `dateFrom`: dateFrom
   *
   * - `country`: country
   *
   * - `closeReason`: closeReason
   *
   * - `city`: city
   *
   * @return OK
   */
  historyUsingGET2Response(params: OrderControllerService.HistoryUsingGET2Params): __Observable<__StrictHttpResponse<OrderTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.street != null) __params = __params.set('street', params.street.toString());
    if (params.state != null) __params = __params.set('state', params.state.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.side != null) __params = __params.set('side', params.side.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.orderType != null) __params = __params.set('orderType', params.orderType.toString());
    (params.markets || []).forEach(val => {if (val != null) __params = __params.append('markets', val.toString())});
    if (params.isNotNft != null) __params = __params.set('isNotNft', params.isNotNft.toString());
    if (params.isNft != null) __params = __params.set('isNft', params.isNft.toString());
    if (params.forAllUsers != null) __params = __params.set('forAllUsers', params.forAllUsers.toString());
    if (params.dateTo != null) __params = __params.set('dateTo', params.dateTo.toString());
    if (params.dateFrom != null) __params = __params.set('dateFrom', params.dateFrom.toString());
    if (params.country != null) __params = __params.set('country', params.country.toString());
    if (params.closeReason != null) __params = __params.set('closeReason', params.closeReason.toString());
    if (params.city != null) __params = __params.set('city', params.city.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderTOListResponse>;
      })
    );
  }
  /**
   * history
   * @param params The `OrderControllerService.HistoryUsingGET2Params` containing the following parameters:
   *
   * - `street`: street
   *
   * - `state`: state
   *
   * - `sort`: sort
   *
   * - `side`: side
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `orderType`: orderType
   *
   * - `markets`: markets
   *
   * - `isNotNft`: isNotNft
   *
   * - `isNft`: isNft
   *
   * - `forAllUsers`: forAllUsers
   *
   * - `dateTo`: dateTo
   *
   * - `dateFrom`: dateFrom
   *
   * - `country`: country
   *
   * - `closeReason`: closeReason
   *
   * - `city`: city
   *
   * @return OK
   */
  historyUsingGET2(params: OrderControllerService.HistoryUsingGET2Params): __Observable<OrderTOListResponse> {
    return this.historyUsingGET2Response(params).pipe(
      __map(_r => _r.body as OrderTOListResponse)
    );
  }

  /**
   * history
   * @param params The `OrderControllerService.HistoryUsingGET1Params` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `side`: side
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `orderType`: orderType
   *
   * - `dateTo`: dateTo
   *
   * - `dateFrom`: dateFrom
   *
   * @return OK
   */
  historyUsingGET1Response(params: OrderControllerService.HistoryUsingGET1Params): __Observable<__StrictHttpResponse<OrderTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.side != null) __params = __params.set('side', params.side.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.orderType != null) __params = __params.set('orderType', params.orderType.toString());
    if (params.dateTo != null) __params = __params.set('dateTo', params.dateTo.toString());
    if (params.dateFrom != null) __params = __params.set('dateFrom', params.dateFrom.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/history-view`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderTOListResponse>;
      })
    );
  }
  /**
   * history
   * @param params The `OrderControllerService.HistoryUsingGET1Params` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `side`: side
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `orderType`: orderType
   *
   * - `dateTo`: dateTo
   *
   * - `dateFrom`: dateFrom
   *
   * @return OK
   */
  historyUsingGET1(params: OrderControllerService.HistoryUsingGET1Params): __Observable<OrderTOListResponse> {
    return this.historyUsingGET1Response(params).pipe(
      __map(_r => _r.body as OrderTOListResponse)
    );
  }

  /**
   * list
   * @param params The `OrderControllerService.ListUsingGET15Params` containing the following parameters:
   *
   * - `street`: street
   *
   * - `state`: state
   *
   * - `sort`: sort
   *
   * - `side`: side
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `orderType`: orderType
   *
   * - `nftWalletId`: nftWalletId
   *
   * - `markets`: markets
   *
   * - `isNotNft`: isNotNft
   *
   * - `isNft`: isNft
   *
   * - `dateTo`: dateTo
   *
   * - `dateFrom`: dateFrom
   *
   * - `country`: country
   *
   * - `closeReason`: closeReason
   *
   * - `city`: city
   *
   * @return OK
   */
  listUsingGET15Response(params: OrderControllerService.ListUsingGET15Params): __Observable<__StrictHttpResponse<OrderTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.street != null) __params = __params.set('street', params.street.toString());
    if (params.state != null) __params = __params.set('state', params.state.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.side != null) __params = __params.set('side', params.side.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.orderType != null) __params = __params.set('orderType', params.orderType.toString());
    if (params.nftWalletId != null) __params = __params.set('nftWalletId', params.nftWalletId.toString());
    (params.markets || []).forEach(val => {if (val != null) __params = __params.append('markets', val.toString())});
    if (params.isNotNft != null) __params = __params.set('isNotNft', params.isNotNft.toString());
    if (params.isNft != null) __params = __params.set('isNft', params.isNft.toString());
    if (params.dateTo != null) __params = __params.set('dateTo', params.dateTo.toString());
    if (params.dateFrom != null) __params = __params.set('dateFrom', params.dateFrom.toString());
    if (params.country != null) __params = __params.set('country', params.country.toString());
    if (params.closeReason != null) __params = __params.set('closeReason', params.closeReason.toString());
    if (params.city != null) __params = __params.set('city', params.city.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderTOListResponse>;
      })
    );
  }
  /**
   * list
   * @param params The `OrderControllerService.ListUsingGET15Params` containing the following parameters:
   *
   * - `street`: street
   *
   * - `state`: state
   *
   * - `sort`: sort
   *
   * - `side`: side
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `orderType`: orderType
   *
   * - `nftWalletId`: nftWalletId
   *
   * - `markets`: markets
   *
   * - `isNotNft`: isNotNft
   *
   * - `isNft`: isNft
   *
   * - `dateTo`: dateTo
   *
   * - `dateFrom`: dateFrom
   *
   * - `country`: country
   *
   * - `closeReason`: closeReason
   *
   * - `city`: city
   *
   * @return OK
   */
  listUsingGET15(params: OrderControllerService.ListUsingGET15Params): __Observable<OrderTOListResponse> {
    return this.listUsingGET15Response(params).pipe(
      __map(_r => _r.body as OrderTOListResponse)
    );
  }

  /**
   * NFT market history
   * @param params The `OrderControllerService.HistoryNftUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * @return OK
   */
  historyNftUsingGETResponse(params: OrderControllerService.HistoryNftUsingGETParams): __Observable<__StrictHttpResponse<OrderTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/nft/history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderTOListResponse>;
      })
    );
  }
  /**
   * NFT market history
   * @param params The `OrderControllerService.HistoryNftUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * @return OK
   */
  historyNftUsingGET(params: OrderControllerService.HistoryNftUsingGETParams): __Observable<OrderTOListResponse> {
    return this.historyNftUsingGETResponse(params).pipe(
      __map(_r => _r.body as OrderTOListResponse)
    );
  }

  /**
   * open
   * @param form form
   * @return OK
   */
  openUsingPOSTResponse(form: OpenOrderForm): __Observable<__StrictHttpResponse<OrderTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/order/open`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderTOResponse>;
      })
    );
  }
  /**
   * open
   * @param form form
   * @return OK
   */
  openUsingPOST(form: OpenOrderForm): __Observable<OrderTOResponse> {
    return this.openUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as OrderTOResponse)
    );
  }

  /**
   * getStreetsSales
   * @param params The `OrderControllerService.GetStreetsSalesUsingGETParams` containing the following parameters:
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * @return OK
   */
  getStreetsSalesUsingGETResponse(params: OrderControllerService.GetStreetsSalesUsingGETParams): __Observable<__StrictHttpResponse<OrderWithMoneyResultTOList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/streets/latest-sell`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderWithMoneyResultTOList>;
      })
    );
  }
  /**
   * getStreetsSales
   * @param params The `OrderControllerService.GetStreetsSalesUsingGETParams` containing the following parameters:
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * @return OK
   */
  getStreetsSalesUsingGET(params: OrderControllerService.GetStreetsSalesUsingGETParams): __Observable<OrderWithMoneyResultTOList> {
    return this.getStreetsSalesUsingGETResponse(params).pipe(
      __map(_r => _r.body as OrderWithMoneyResultTOList)
    );
  }

  /**
   * getOrderView
   * @param params The `OrderControllerService.GetOrderViewUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `side`: side
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `orderType`: orderType
   *
   * - `dateTo`: dateTo
   *
   * - `dateFrom`: dateFrom
   *
   * - `closeReason`: closeReason
   *
   * @return OK
   */
  getOrderViewUsingGETResponse(params: OrderControllerService.GetOrderViewUsingGETParams): __Observable<__StrictHttpResponse<OrderTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.side != null) __params = __params.set('side', params.side.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.orderType != null) __params = __params.set('orderType', params.orderType.toString());
    if (params.dateTo != null) __params = __params.set('dateTo', params.dateTo.toString());
    if (params.dateFrom != null) __params = __params.set('dateFrom', params.dateFrom.toString());
    if (params.closeReason != null) __params = __params.set('closeReason', params.closeReason.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/view`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderTOListResponse>;
      })
    );
  }
  /**
   * getOrderView
   * @param params The `OrderControllerService.GetOrderViewUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `side`: side
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `orderType`: orderType
   *
   * - `dateTo`: dateTo
   *
   * - `dateFrom`: dateFrom
   *
   * - `closeReason`: closeReason
   *
   * @return OK
   */
  getOrderViewUsingGET(params: OrderControllerService.GetOrderViewUsingGETParams): __Observable<OrderTOListResponse> {
    return this.getOrderViewUsingGETResponse(params).pipe(
      __map(_r => _r.body as OrderTOListResponse)
    );
  }
}

module OrderControllerService {

  /**
   * Parameters for closeAllOrdersUsingPOST
   */
  export interface CloseAllOrdersUsingPOSTParams {

    /**
     * side
     */
    side?: 'BUY' | 'SELL' | 'UNKNOWN_SIDE' | 'UNRECOGNIZED';

    /**
     * markets
     */
    markets?: Array<number>;

    /**
     * isNotNft
     */
    isNotNft?: boolean;

    /**
     * isNft
     */
    isNft?: boolean;
  }

  /**
   * Parameters for getDwrldHistoryUsingGET
   */
  export interface GetDwrldHistoryUsingGETParams {

    /**
     * pageSize
     */
    pageSize?: number;

    /**
     * pageNumber
     */
    pageNumber?: number;
  }

  /**
   * Parameters for historyUsingGET2
   */
  export interface HistoryUsingGET2Params {

    /**
     * street
     */
    street?: string;

    /**
     * state
     */
    state?: 'STATE_ACTIVE' | 'STATE_COMPLETED' | 'STATE_PENDING' | 'UNKNOWN_STATE' | 'UNRECOGNIZED';

    /**
     * sort
     */
    sort?: string;

    /**
     * side
     */
    side?: 'BUY' | 'SELL' | 'UNKNOWN_SIDE' | 'UNRECOGNIZED';

    /**
     * pageSize
     */
    pageSize?: number;

    /**
     * pageNumber
     */
    pageNumber?: number;

    /**
     * orderType
     */
    orderType?: 'LIMIT' | 'MARKET' | 'MARKET_MARGIN_CALL' | 'UNKNOWN_ORDER_TYPE' | 'UNRECOGNIZED';

    /**
     * markets
     */
    markets?: Array<number>;

    /**
     * isNotNft
     */
    isNotNft?: boolean;

    /**
     * isNft
     */
    isNft?: boolean;

    /**
     * forAllUsers
     */
    forAllUsers?: boolean;

    /**
     * dateTo
     */
    dateTo?: string;

    /**
     * dateFrom
     */
    dateFrom?: string;

    /**
     * country
     */
    country?: string;

    /**
     * closeReason
     */
    closeReason?: 'CANCELLED_BY_ADMIN' | 'CANCELLED_BY_OWNER' | 'COMPLETED' | 'EMPTY_AMOUNT' | 'ERROR' | 'MARKET_DISABLED' | 'MARKET_ORDER_NOT_FILLED' | 'OPEN' | 'PARTIALLY_FILLED' | 'PENDING_CANCEL' | 'PENDING_MARKET_DISABLED' | 'TIME_EXPIRED' | 'TOO_LOW_AMOUNT' | 'TOO_LOW_MATCH' | 'UNKNOWN_CLOSE_REASON' | 'UNRECOGNIZED';

    /**
     * city
     */
    city?: string;
  }

  /**
   * Parameters for historyUsingGET1
   */
  export interface HistoryUsingGET1Params {

    /**
     * sort
     */
    sort?: string;

    /**
     * side
     */
    side?: 'BUY' | 'SELL' | 'UNKNOWN_SIDE' | 'UNRECOGNIZED';

    /**
     * pageSize
     */
    pageSize?: number;

    /**
     * pageNumber
     */
    pageNumber?: number;

    /**
     * orderType
     */
    orderType?: 'LIMIT' | 'MARKET' | 'MARKET_MARGIN_CALL' | 'UNKNOWN_ORDER_TYPE' | 'UNRECOGNIZED';

    /**
     * dateTo
     */
    dateTo?: string;

    /**
     * dateFrom
     */
    dateFrom?: string;
  }

  /**
   * Parameters for listUsingGET15
   */
  export interface ListUsingGET15Params {

    /**
     * street
     */
    street?: string;

    /**
     * state
     */
    state?: 'STATE_ACTIVE' | 'STATE_COMPLETED' | 'STATE_PENDING' | 'UNKNOWN_STATE' | 'UNRECOGNIZED';

    /**
     * sort
     */
    sort?: string;

    /**
     * side
     */
    side?: 'BUY' | 'SELL' | 'UNKNOWN_SIDE' | 'UNRECOGNIZED';

    /**
     * pageSize
     */
    pageSize?: number;

    /**
     * pageNumber
     */
    pageNumber?: number;

    /**
     * orderType
     */
    orderType?: 'LIMIT' | 'MARKET' | 'MARKET_MARGIN_CALL' | 'UNKNOWN_ORDER_TYPE' | 'UNRECOGNIZED';

    /**
     * nftWalletId
     */
    nftWalletId?: number;

    /**
     * markets
     */
    markets?: Array<number>;

    /**
     * isNotNft
     */
    isNotNft?: boolean;

    /**
     * isNft
     */
    isNft?: boolean;

    /**
     * dateTo
     */
    dateTo?: string;

    /**
     * dateFrom
     */
    dateFrom?: string;

    /**
     * country
     */
    country?: string;

    /**
     * closeReason
     */
    closeReason?: 'CANCELLED_BY_ADMIN' | 'CANCELLED_BY_OWNER' | 'COMPLETED' | 'EMPTY_AMOUNT' | 'ERROR' | 'MARKET_DISABLED' | 'MARKET_ORDER_NOT_FILLED' | 'OPEN' | 'PARTIALLY_FILLED' | 'PENDING_CANCEL' | 'PENDING_MARKET_DISABLED' | 'TIME_EXPIRED' | 'TOO_LOW_AMOUNT' | 'TOO_LOW_MATCH' | 'UNKNOWN_CLOSE_REASON' | 'UNRECOGNIZED';

    /**
     * city
     */
    city?: string;
  }

  /**
   * Parameters for historyNftUsingGET
   */
  export interface HistoryNftUsingGETParams {

    /**
     * id
     */
    id: number;

    /**
     * pageSize
     */
    pageSize?: number;

    /**
     * pageNumber
     */
    pageNumber?: number;
  }

  /**
   * Parameters for getStreetsSalesUsingGET
   */
  export interface GetStreetsSalesUsingGETParams {

    /**
     * pageSize
     */
    pageSize?: number;

    /**
     * pageNumber
     */
    pageNumber?: number;
  }

  /**
   * Parameters for getOrderViewUsingGET
   */
  export interface GetOrderViewUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * side
     */
    side?: 'BUY' | 'SELL' | 'UNKNOWN_SIDE' | 'UNRECOGNIZED';

    /**
     * pageSize
     */
    pageSize?: number;

    /**
     * pageNumber
     */
    pageNumber?: number;

    /**
     * orderType
     */
    orderType?: 'LIMIT' | 'MARKET' | 'MARKET_MARGIN_CALL' | 'UNKNOWN_ORDER_TYPE' | 'UNRECOGNIZED';

    /**
     * dateTo
     */
    dateTo?: string;

    /**
     * dateFrom
     */
    dateFrom?: string;

    /**
     * closeReason
     */
    closeReason?: 'CANCELLED_BY_ADMIN' | 'CANCELLED_BY_OWNER' | 'COMPLETED' | 'EMPTY_AMOUNT' | 'ERROR' | 'MARKET_DISABLED' | 'MARKET_ORDER_NOT_FILLED' | 'OPEN' | 'PARTIALLY_FILLED' | 'PENDING_CANCEL' | 'PENDING_MARKET_DISABLED' | 'TIME_EXPIRED' | 'TOO_LOW_AMOUNT' | 'TOO_LOW_MATCH' | 'UNKNOWN_CLOSE_REASON' | 'UNRECOGNIZED';
  }
}

export { OrderControllerService }
