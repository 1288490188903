/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

export default function debounce(
  milliseconds = 0
): (target: any, propertyKey: string, descriptor: PropertyDescriptor) => PropertyDescriptor {
  return (_target: any, _propertyKey: string, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;
    descriptor.value = debounceFn(originalMethod, milliseconds);
    return descriptor;
  };
}

function debounceFn(func: () => any, ms: number): () => void {
  let savedArgs: any;
  let savedThis: any;
  let timeout: any;

  function wrapper(this: any): void {
    clearTimeout(timeout);
    savedArgs = arguments;
    savedThis = this;

    timeout = setTimeout(() => {
      func.apply(savedThis, savedArgs);
      savedArgs = savedThis = null;
    }, ms);
  }
  return wrapper;
}
