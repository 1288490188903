import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { iconBell } from 'core/icons/lib/icon-bell';
import { iconEye } from 'core/icons/lib/icon-eye';
import { forkJoin, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { UserNotification } from '../../../models/notification.model';
import { NotificationService, Tabs } from '../../../services/notification.service';

@Component({
  selector: 'app-profile-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class ProfileNotificationsComponent implements OnDestroy, OnInit {
  @ViewChild('cdkVirtualScroll')
  cdkVirtualScrollRef?: CdkVirtualScrollViewport;

  readonly bellIcon = iconBell;
  readonly eyeIcon = iconEye;

  expandedNotificationId!: number;

  isLoading = false;
  activeView: Tabs = 'ALL';
  notifications: UserNotification[] = [];
  markAllAsReadDisabled = false;

  private destroy$ = new Subject();

  constructor(public notificationService: NotificationService) {}

  ngOnInit(): void {
    this.isLoading = true;
    forkJoin(
      this.notificationService.load('ALL'),
      this.notificationService.load('ASSET'),
      this.notificationService.load('DOCUMENT'),
      this.notificationService.load('METAVERSE_3D')
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.isLoading = false;
        this.setNotifications();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTabAction(tab: Tabs): void {
    this.isLoading = true;
    this.activeView = tab;
    this.setNotifications();
    forkJoin([this.notificationService.load(this.activeView), this.notificationService.count()])
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.isLoading = false;
      });
    this.cdkVirtualScrollRef?.scrollTo({ top: 0 });
  }

  identifyNotification(_: number, item: UserNotification): number {
    return item.id;
  }

  readNotificationAction(notification: UserNotification): void {
    this.notificationService.markAsRead(notification);
  }

  nextPageAction($event: () => void): void {
    this.isLoading = true;
    this.notificationService
      .loadNextPage(this.activeView)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        $event();
        this.isLoading = false;
        this.setNotifications();
      });
  }

  markAllAsReadAction(): void {
    this.markAllAsReadDisabled = true;
    this.notificationService
      .markAllAsRead()
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.markAllAsReadDisabled = false;
        })
      )
      .subscribe();
  }

  onExpand(id: number): void {
    this.expandedNotificationId = id;
  }

  private setNotifications(): void {
    this.notifications = this.notificationService.data[this.activeView].notifications;
  }
}
