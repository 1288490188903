export enum NotificationMessageType {
  ORDER_OPENED = 'ORDER_OPENED',
  ORDER_OPENED_NFT = 'ORDER_OPENED_NFT',
  ORDER_EXECUTED = 'ORDER_EXECUTED',
  ORDER_EXECUTED_NFT = 'ORDER_EXECUTED_NFT',
  ORDER_CANCELLED = 'ORDER_CANCELLED',
  ORDER_CANCELLED_NFT = 'ORDER_CANCELLED_NFT',
  ORDER_PARTIALLY_FILLED = 'ORDER_PARTIALLY_FILLED',
  ORDER_PARTIALLY_FILLED_NFT = 'ORDER_PARTIALLY_FILLED_NFT',
  ORDER_CLOSED_NFT = 'ORDER_CLOSED_NFT',
  STREET_SELL = 'STREET_SELL',
  DEPOSIT_RECEIVED = 'DEPOSIT_RECEIVED',
  WITHDRAWAL_ACCEPTED = 'WITHDRAWAL_ACCEPTED',
  WITHDRAWAL_REJECTED = 'WITHDRAWAL_REJECTED',
  WITHDRAWAL_COMPLIANCE_CHECK = 'WITHDRAWAL_COMPLIANCE_CHECK',
  WITHDRAWAL_PAID_OUT = 'WITHDRAWAL_PAID_OUT',
  KYS_STATUS_CHANGED = 'KYS_STATUS_CHANGED',
  INCOMING_REFERRAL_COMMISSION = 'INCOMING_REFERRAL_COMMISSION',
  INCOMING_DIVIDEND = 'INCOMING_DIVIDEND',
  DOCUMENT_REQUEST = 'DOCUMENT_REQUEST',
  KAYAKO_NEW_MESSAGE = 'KAYAKO_NEW_MESSAGE',
  REFERRAL_CODE_INCOME = 'REFERRAL_CODE_INCOME',
  MYSTERY_BOX_OPEN = 'MYSTERY_BOX_OPEN',
  USER_WANT_BUY_NFT = 'USER_WANT_BUY_NFT',
  INITIAL_NFT_BUY = 'INITIAL_NFT_BUY',
  TOPIC_NFT_RECEIVED = 'TOPIC_NFT_RECEIVED',
  TOPIC_NFT_SEND = 'TOPIC_NFT_SEND',
  TOPIC_USER_INTERNAL_RECEIVED = 'TOPIC_USER_INTERNAL_RECEIVED',
  TOPIC_USER_INTERNAL_RECEIVE = 'TOPIC_USER_INTERNAL_RECEIVE',
  USER_INTERNAL_TRANSFER = 'USER_INTERNAL_TRANSFER',
  COLLECTION_BALANCE_CHANGED = 'COLLECTION_BALANCE_CHANGED',
  REFERRAL_PURCHASE = 'REFERRAL_PURCHASE',
  ACHIEVEMENT_CHANGED = 'ACHIEVEMENT_CHANGED',
  REWARD_3D = 'REWARD_3D'
}
