import {Component} from '@angular/core';

@Component({
  selector: 'app-landscape-lock',
  templateUrl: './landscape-lock.component.html',
  styleUrls: ['./landscape-lock.component.scss'],
})
export class LandscapeLockComponent{
  constructor() {}
}
