import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HistoryTO, PageTO } from 'api/models';
import { iconClipboardClose } from 'core/icons/lib/icon-clipboard-close';

@Component({
  selector: 'app-street-history-list',
  templateUrl: './street-history-list.component.html',
  styleUrls: ['./street-history-list.component.scss'],
})
export class StreetHistoryListComponent {
  readonly iconNoRecords = iconClipboardClose;
  @Input()
  history: HistoryTO[] = [];
  @Input() pagination: PageTO = {
    size: 5,
    pageNumber: 1,
  };
  @Input()
  noData: 'text' | 'icon' = 'icon';

  @Output() paginationChange = new EventEmitter<PageTO>();

  applyPageAction(pageNumber: number): void {
    this.pagination.pageNumber = pageNumber - 1;
    this.paginationChange.emit(this.pagination);
  }
}
