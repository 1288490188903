import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { appNav } from 'app/app.navigation';
import { AuthService } from 'auth/services/auth.service';
import { createUrlTree } from 'core/utils/router.utils';
import { UserService } from 'profile/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthSuccessGuard implements CanActivate, CanLoad {
  constructor(
    private auth: AuthService,
    private location: Location,
    private userService: UserService
  ) {}

  // eslint-disable-next-line unused-imports/no-unused-vars
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    return this.resolveNavigation(state);
  }

  canLoad(): boolean | UrlTree {
    return this.resolveNavigation();
  }

  private resolveNavigation(state?: RouterStateSnapshot): boolean | UrlTree {
    if (this.auth.isAuth) {
      return true;
    }
    if(state) {
      // User is not authenticated, save the initially requested URL and redirect to the authentication page
      this.auth.setRedirectUrl(state.url);
    }
    if (this.location.path() === appNav.cashierNav.externalDeposit()) {
      this.userService.preventedNavigation = appNav.cashierNav.externalDeposit();
    }
    return createUrlTree(appNav.guestInitial());
  }
}
