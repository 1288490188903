import { Injectable } from '@angular/core';

interface ReferralData {
  offid?: string;
  affid?: string;
  c?: string;
  referral?: string;
  promoCode?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ReferralsService {
  private data: ReferralData = {};

  updateReferrals(data: ReferralData): void {
    this.data = data;
  }

  getReferrals(): ReferralData {
    return this.data;
  }

  clearReferrals(): void {
    this.data = {};
  }
}
