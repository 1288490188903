import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { appNav } from 'app/app.navigation';
import { AuthService } from 'auth/services/auth.service';
import { navigateByUrl } from 'core/utils/router.utils';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

import { APIError, APIErrorResponse } from '../../_core/models/error.model';
import { APIErrorsHandler } from '../../_core/services/api-errors.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  // be prefix used for request to API endpoints without /api prefix
  // be will be removed while intercept
  private readonly regex = /^\/{0,1}(api)\//;

  constructor(private apiHandler: APIErrorsHandler, private auth: AuthService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // if request not to /api
    if (!this.regex.exec(req.url.replace(environment.mainServiceUrl, ''))) {
      return next.handle(req);
    }
    // wrap original request to override API errors that sent with 200 OK response
    // and emit error as error for Angular
    const observable = new Observable<HttpEvent<unknown>>(s => {
      const sourceSubscription = next.handle(req).subscribe(
        response => {
          s.next(response);
        },
        (errorResponse: HttpErrorResponse) => {
          const apiErrorResponse = this.mapPropertyIfNeeded(
            errorResponse.error as APIErrorResponse
          );
          const apiError = new APIError(
            apiErrorResponse?.error?.toString() || '',
            apiErrorResponse.message,
            apiErrorResponse.details,
            apiErrorResponse,
            apiErrorResponse.details
          );
          s.error(apiError);
          if (errorResponse.status === 401 && this.auth.isAuth) {
            this.auth.clearSession();
            navigateByUrl(appNav.guestInitial(), { queryParamsHandling: 'preserve' });
          } else if (errorResponse.status === 503) {
            apiError.code = 'SERVICE_UNAVAILABLE';
            this.apiHandler.handleError(apiError);
            // TODO: in this case need to show some modal window to not interrupt user action
            // navigateByUrl(appNav.commonNav.serviceUnavailable(), { skipLocationChange: true });
          } else if (errorResponse.status !== 401) {
            this.apiHandler.handleError(apiError);
          }
        },
        () => {
          s.complete();
        }
      );
      return () => {
        sourceSubscription.unsubscribe();
      };
    });
    return observable;
  }

  // for some errors it is better to show it near input field instead at toast notification
  private mapPropertyIfNeeded(response: APIErrorResponse): APIErrorResponse {
    if (typeof response === 'string') {
      response = JSON.parse(response) as APIErrorResponse;
    }
    if (typeof response.error !== 'string') {
      return response;
    }
    if (response.error === 'USER_NOT_FOUND' || response.error.includes('EMAIL')) {
      response.details = [{ field: 'email' }];
    } else if (response.error.includes('PASSWORD') || response.error.includes('LOGIN')) {
      response.details = [{ field: 'password' }];
    } else if (response.error === 'TWO_FACTOR_INVALID_CODE') {
      response.details = [{ field: 'code' }];
    }
    return response;
  }
}
