import { createNavigation, createUrl } from 'core/utils/router.utils';

export interface CollectionAssetParams {
  metaId?: string;
  collectionId?: string;
  type: CollectionPageType;
}

export type CollectionPageType = 'staking' | 'view';

export const collectionsRoot = 'collections';
export const collectionKeyword = '-nft-collection-';

export const allCollections = (): string => '';

export const stakingCollections = (): string => 'staking';

export const collection = (metaId: number, name: string, country?: string, city?: string): string =>
  `${name?.replace(/\s|,|\.|\//g, '-').toLowerCase()}${collectionKeyword}${
    country ? `${country.replace(/\s/g, '-').toLowerCase()}-` : ''
  }${city ? `${city.replace(/\s|,|\.|\//g, '-').toLowerCase()}-` : ''}${metaId}`;

export const userCollection = (id: string, type: CollectionPageType = 'view'): string =>
  `nft-collection/${id}/${type}`;

export const paths = {
  allCollections,
  userCollection,
  collection,
  stakingCollections,
};

export const collectionsNav = createNavigation(paths, collectionsRoot, {
  collection: (...args) => createUrl(collection.apply(null, args)),
});
