/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiKeyResponse } from '../models/api-key-response';
import { ApiKeyForm } from '../models/api-key-form';
import { ConfirmApiEmailTOResponse } from '../models/confirm-api-email-toresponse';
import { ConfirmApiKeyForm } from '../models/confirm-api-key-form';
import { ResendApiKeyConfirmationEmailTOResponse } from '../models/resend-api-key-confirmation-email-toresponse';
import { ResendApiKeyEmailConfirmationForm } from '../models/resend-api-key-email-confirmation-form';
import { ApiKeyAbilityForm } from '../models/api-key-ability-form';
import { RevokeApiKeyForm } from '../models/revoke-api-key-form';

/**
 * Api Key Controller
 */
@Injectable({
  providedIn: 'root',
})
class ApiKeyControllerService extends __BaseService {
  static readonly getAccountApiKeysUsingGETPath = '/api/v1/api-key';
  static readonly createAccountApiKeyUsingPOSTPath = '/api/v1/api-key';
  static readonly updateAccountApiKeyUsingPUTPath = '/api/v1/api-key';
  static readonly confirmApiKeyUsingPOSTPath = '/api/v1/api-key/confirm';
  static readonly resendConfirmationEmailUsingPOSTPath = '/api/v1/api-key/confirm/resend';
  static readonly disableApiKeyUsingPOSTPath = '/api/v1/api-key/disable';
  static readonly enableApiKeyUsingPOSTPath = '/api/v1/api-key/enable';
  static readonly addApiKeyPermissionsUsingPOSTPath = '/api/v1/api-key/permissions/list';
  static readonly revokeApiKeyUsingPOSTPath = '/api/v1/api-key/revoke';
  static readonly confirmRevokeApiKeyUsingPOSTPath = '/api/v1/api-key/revoke/confirm';
  static readonly resendConfirmationRevokeEmailUsingPOSTPath = '/api/v1/api-key/revoke/confirm/resend';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List account API keys
   * @return OK
   */
  getAccountApiKeysUsingGETResponse(): __Observable<__StrictHttpResponse<Array<ApiKeyResponse>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/api-key`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ApiKeyResponse>>;
      })
    );
  }
  /**
   * List account API keys
   * @return OK
   */
  getAccountApiKeysUsingGET(): __Observable<Array<ApiKeyResponse>> {
    return this.getAccountApiKeysUsingGETResponse().pipe(
      __map(_r => _r.body as Array<ApiKeyResponse>)
    );
  }

  /**
   * Create account API key
   *
   * Throws errors: USER_NOT_FOUND
   * @param form form
   * @return OK
   */
  createAccountApiKeyUsingPOSTResponse(form: ApiKeyForm): __Observable<__StrictHttpResponse<ApiKeyResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/api-key`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiKeyResponse>;
      })
    );
  }
  /**
   * Create account API key
   *
   * Throws errors: USER_NOT_FOUND
   * @param form form
   * @return OK
   */
  createAccountApiKeyUsingPOST(form: ApiKeyForm): __Observable<ApiKeyResponse> {
    return this.createAccountApiKeyUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as ApiKeyResponse)
    );
  }

  /**
   * Update account API key permissions data
   *
   * Throws errors: API_KEY_NOT_FOUND; VALIDATION_ERROR
   * @param form form
   * @return OK
   */
  updateAccountApiKeyUsingPUTResponse(form: ApiKeyForm): __Observable<__StrictHttpResponse<ApiKeyResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/api-key`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiKeyResponse>;
      })
    );
  }
  /**
   * Update account API key permissions data
   *
   * Throws errors: API_KEY_NOT_FOUND; VALIDATION_ERROR
   * @param form form
   * @return OK
   */
  updateAccountApiKeyUsingPUT(form: ApiKeyForm): __Observable<ApiKeyResponse> {
    return this.updateAccountApiKeyUsingPUTResponse(form).pipe(
      __map(_r => _r.body as ApiKeyResponse)
    );
  }

  /**
   * Confirm account API key
   *
   * Throws errors: API_KEY_ALREADY_CONFIRMED; API_KEY_CONFIRM_TOKEN_EXPIRED; API_KEY_NOT_FOUND; VALIDATION_ERROR
   * @param form form
   * @return OK
   */
  confirmApiKeyUsingPOSTResponse(form: ConfirmApiKeyForm): __Observable<__StrictHttpResponse<ConfirmApiEmailTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/api-key/confirm`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ConfirmApiEmailTOResponse>;
      })
    );
  }
  /**
   * Confirm account API key
   *
   * Throws errors: API_KEY_ALREADY_CONFIRMED; API_KEY_CONFIRM_TOKEN_EXPIRED; API_KEY_NOT_FOUND; VALIDATION_ERROR
   * @param form form
   * @return OK
   */
  confirmApiKeyUsingPOST(form: ConfirmApiKeyForm): __Observable<ConfirmApiEmailTOResponse> {
    return this.confirmApiKeyUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as ConfirmApiEmailTOResponse)
    );
  }

  /**
   * Resend api key email confirmation
   *
   * Throws errors: API_KEY_ALREADY_CONFIRMED; API_KEY_CONFIRMATION_TOKEN_NOT_FOUND; API_KEY_NOT_FOUND; TOO_OFTEN; VALIDATION_ERROR
   * @param form form
   * @return OK
   */
  resendConfirmationEmailUsingPOSTResponse(form: ResendApiKeyEmailConfirmationForm): __Observable<__StrictHttpResponse<ResendApiKeyConfirmationEmailTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/api-key/confirm/resend`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResendApiKeyConfirmationEmailTOResponse>;
      })
    );
  }
  /**
   * Resend api key email confirmation
   *
   * Throws errors: API_KEY_ALREADY_CONFIRMED; API_KEY_CONFIRMATION_TOKEN_NOT_FOUND; API_KEY_NOT_FOUND; TOO_OFTEN; VALIDATION_ERROR
   * @param form form
   * @return OK
   */
  resendConfirmationEmailUsingPOST(form: ResendApiKeyEmailConfirmationForm): __Observable<ResendApiKeyConfirmationEmailTOResponse> {
    return this.resendConfirmationEmailUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as ResendApiKeyConfirmationEmailTOResponse)
    );
  }

  /**
   * Disable account API key
   *
   * Throws errors: API_KEY_NOT_FOUND; VALIDATION_ERROR
   * @param form form
   * @return OK
   */
  disableApiKeyUsingPOSTResponse(form: ApiKeyAbilityForm): __Observable<__StrictHttpResponse<ApiKeyResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/api-key/disable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiKeyResponse>;
      })
    );
  }
  /**
   * Disable account API key
   *
   * Throws errors: API_KEY_NOT_FOUND; VALIDATION_ERROR
   * @param form form
   * @return OK
   */
  disableApiKeyUsingPOST(form: ApiKeyAbilityForm): __Observable<ApiKeyResponse> {
    return this.disableApiKeyUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as ApiKeyResponse)
    );
  }

  /**
   * Enable account API key
   *
   * Throws errors: API_KEY_NOT_FOUND; VALIDATION_ERROR
   * @param form form
   * @return OK
   */
  enableApiKeyUsingPOSTResponse(form: ApiKeyAbilityForm): __Observable<__StrictHttpResponse<ApiKeyResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/api-key/enable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiKeyResponse>;
      })
    );
  }
  /**
   * Enable account API key
   *
   * Throws errors: API_KEY_NOT_FOUND; VALIDATION_ERROR
   * @param form form
   * @return OK
   */
  enableApiKeyUsingPOST(form: ApiKeyAbilityForm): __Observable<ApiKeyResponse> {
    return this.enableApiKeyUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as ApiKeyResponse)
    );
  }

  /**
   * Add API key's permissions
   *
   * Throws errors: API_KEY_NOT_FOUND; VALIDATION_ERROR
   * @param form form
   * @return OK
   */
  addApiKeyPermissionsUsingPOSTResponse(form: ApiKeyForm): __Observable<__StrictHttpResponse<ApiKeyResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/api-key/permissions/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiKeyResponse>;
      })
    );
  }
  /**
   * Add API key's permissions
   *
   * Throws errors: API_KEY_NOT_FOUND; VALIDATION_ERROR
   * @param form form
   * @return OK
   */
  addApiKeyPermissionsUsingPOST(form: ApiKeyForm): __Observable<ApiKeyResponse> {
    return this.addApiKeyPermissionsUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as ApiKeyResponse)
    );
  }

  /**
   * Revoke account API key by key
   *
   * Throws errors: API_KEY_NOT_FOUND; API_KEY_INVALID; API_KEY_NOT_VERIFIED; VALIDATION_ERROR
   * @param form form
   * @return OK
   */
  revokeApiKeyUsingPOSTResponse(form: RevokeApiKeyForm): __Observable<__StrictHttpResponse<ApiKeyResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/api-key/revoke`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiKeyResponse>;
      })
    );
  }
  /**
   * Revoke account API key by key
   *
   * Throws errors: API_KEY_NOT_FOUND; API_KEY_INVALID; API_KEY_NOT_VERIFIED; VALIDATION_ERROR
   * @param form form
   * @return OK
   */
  revokeApiKeyUsingPOST(form: RevokeApiKeyForm): __Observable<ApiKeyResponse> {
    return this.revokeApiKeyUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as ApiKeyResponse)
    );
  }

  /**
   * Confirm revoke account API key
   *
   * Throws errors: API_KEY_NOT_FOUND; API_KEY_INVALID; API_KEY_NOT_VERIFIED; VALIDATION_ERROR
   * @param form form
   * @return OK
   */
  confirmRevokeApiKeyUsingPOSTResponse(form: ConfirmApiKeyForm): __Observable<__StrictHttpResponse<ConfirmApiEmailTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/api-key/revoke/confirm`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ConfirmApiEmailTOResponse>;
      })
    );
  }
  /**
   * Confirm revoke account API key
   *
   * Throws errors: API_KEY_NOT_FOUND; API_KEY_INVALID; API_KEY_NOT_VERIFIED; VALIDATION_ERROR
   * @param form form
   * @return OK
   */
  confirmRevokeApiKeyUsingPOST(form: ConfirmApiKeyForm): __Observable<ConfirmApiEmailTOResponse> {
    return this.confirmRevokeApiKeyUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as ConfirmApiEmailTOResponse)
    );
  }

  /**
   * Resend revoke api key email confirmation
   *
   * Throws errors: API_KEY_ALREADY_CONFIRMED; API_KEY_CONFIRMATION_TOKEN_NOT_FOUND; API_KEY_NOT_FOUND; TOO_OFTEN; VALIDATION_ERROR
   * @param form form
   * @return OK
   */
  resendConfirmationRevokeEmailUsingPOSTResponse(form: ResendApiKeyEmailConfirmationForm): __Observable<__StrictHttpResponse<ResendApiKeyConfirmationEmailTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/api-key/revoke/confirm/resend`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResendApiKeyConfirmationEmailTOResponse>;
      })
    );
  }
  /**
   * Resend revoke api key email confirmation
   *
   * Throws errors: API_KEY_ALREADY_CONFIRMED; API_KEY_CONFIRMATION_TOKEN_NOT_FOUND; API_KEY_NOT_FOUND; TOO_OFTEN; VALIDATION_ERROR
   * @param form form
   * @return OK
   */
  resendConfirmationRevokeEmailUsingPOST(form: ResendApiKeyEmailConfirmationForm): __Observable<ResendApiKeyConfirmationEmailTOResponse> {
    return this.resendConfirmationRevokeEmailUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as ResendApiKeyConfirmationEmailTOResponse)
    );
  }
}

module ApiKeyControllerService {
}

export { ApiKeyControllerService }
