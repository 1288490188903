/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CollectionStakeHistoryListTO } from '../models/collection-stake-history-list-to';
import { CollectionStakeStatisticTOResponse } from '../models/collection-stake-statistic-toresponse';

/**
 * Collection Stake History Controller
 */
@Injectable({
  providedIn: 'root',
})
class CollectionStakeHistoryControllerService extends __BaseService {
  static readonly listUsingGET4Path = '/api/v1/collection/stake-history/list';
  static readonly statisticFprUserUsingGETPath = '/api/v1/collection/stake-history/statistic';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * list
   * @param params The `CollectionStakeHistoryControllerService.ListUsingGET4Params` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * @return OK
   */
  listUsingGET4Response(params: CollectionStakeHistoryControllerService.ListUsingGET4Params): __Observable<__StrictHttpResponse<CollectionStakeHistoryListTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/collection/stake-history/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionStakeHistoryListTO>;
      })
    );
  }
  /**
   * list
   * @param params The `CollectionStakeHistoryControllerService.ListUsingGET4Params` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * @return OK
   */
  listUsingGET4(params: CollectionStakeHistoryControllerService.ListUsingGET4Params): __Observable<CollectionStakeHistoryListTO> {
    return this.listUsingGET4Response(params).pipe(
      __map(_r => _r.body as CollectionStakeHistoryListTO)
    );
  }

  /**
   * statisticFprUser
   * @return OK
   */
  statisticFprUserUsingGETResponse(): __Observable<__StrictHttpResponse<CollectionStakeStatisticTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/collection/stake-history/statistic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionStakeStatisticTOResponse>;
      })
    );
  }
  /**
   * statisticFprUser
   * @return OK
   */
  statisticFprUserUsingGET(): __Observable<CollectionStakeStatisticTOResponse> {
    return this.statisticFprUserUsingGETResponse().pipe(
      __map(_r => _r.body as CollectionStakeStatisticTOResponse)
    );
  }
}

module CollectionStakeHistoryControllerService {

  /**
   * Parameters for listUsingGET4
   */
  export interface ListUsingGET4Params {

    /**
     * sort
     */
    sort?: string;

    /**
     * pageSize
     */
    pageSize?: number;

    /**
     * pageNumber
     */
    pageNumber?: number;
  }
}

export { CollectionStakeHistoryControllerService }
