import { Injectable } from '@angular/core';
import { UserMessageTOListResponse, UserMessageTOResponse } from 'api/models';
import { PageTO } from 'api/models/page-to';
import { UserMessageCountsResponseTO } from 'api/models/user-message-counts-response-to';
import { UserMessagesControllerService } from 'api/services/user-messages-controller.service';
import { StateClearService, StateFullService } from 'app/_main/services/state-clear.service';
import { AuthService } from 'auth/services/auth.service';
import { I18nService } from 'core/modules/i18n/i18n.service';
import { SocketService } from 'core/modules/socket/socket.service';
import { CurrencyService } from 'core/services/currency.service';
import { FbService } from 'core/services/fb.service';
import { GTMService } from 'core/services/gtm.service';
import { Toaster } from 'core/toaster';
import {
  DepositGTMData,
  MarketBuySellGTMData,
  MarketGTMService,
} from 'dashboard/services/market-gtm.service';
import { NotificationMessageType } from 'profile/enum/notification-message-type';
import { NotificationTabType } from 'profile/enum/notification-tab-type';
import { UserService } from 'profile/services/user.service';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { map, mergeMap, switchMap, take, tap } from 'rxjs/operators';

import { UserNotification } from '../models/notification.model';
import { AccountsService } from './accounts.service';
import { NotificationsFactory } from './notifications.factory';

export interface NotificationEventDataData<P> {
  id: number;
  accountId: number;
  createdAt: number;
  readAt: number;
  read: boolean;
  messageType: string;
  params: P;
  groupId: number;
  processed: boolean;
  processedForMetrics?: boolean;
  closeReason: string;
  nftId: number;
}

export interface NotificationEventData<P = string> {
  data: NotificationEventDataData<P>;
  eventType: 'USER_MESSAGE_RECEIVED' | 'ORDER_PARTIALLY_FILLED' | 'ORDER_CLOSED';
}

export type NotificationEventProcessType = NotificationEventData<
  DepositGTMData | MarketBuySellGTMData
>;

export type Tabs = 'ALL' | 'ASSET' | 'DOCUMENT' | 'METAVERSE_3D';

export const NOTIFICATIONS_WHITELIST_EVENTS: string[] = [
  'ORDER_PARTIALLY_FILLED',
  'ORDER_EXECUTED',
  'ORDER_OPENED',
  'DEPOSIT_RECEIVED',
  'USER_WANT_BUY_NFT',
  'USER_BEAT_OLD_BET',
  'TOPIC_NFT_RECEIVED',
  'TOPIC_USER_INTERNAL_RECEIVE',
  'REFERRAL_CODE_INCOME',
  'ACHIEVEMENT_CHANGED',
];

export type ProcessedEventTypes = 'DEPOSIT_RECEIVED' | 'STREET_SELL' | 'ORDER_EXECUTED';

export const NOTIFICATIONS_WHITELIST_EVENTS_PROCESSES: ProcessedEventTypes[] = [
  'DEPOSIT_RECEIVED',
  'STREET_SELL',
];
interface dataContent {
  page: PageTO;
  unread: number;
  notifications: UserNotification[];
}

interface data {
  ALL: dataContent;
  ASSET: dataContent;
  DOCUMENT: dataContent;
  METAVERSE_3D: dataContent;
}

@Injectable({ providedIn: 'root' })
export class NotificationService implements StateFullService {
  data: data = {
    ALL: {
      page: {},
      unread: 0,
      notifications: [],
    },
    ASSET: {
      page: {},
      unread: 0,
      notifications: [],
    },
    DOCUMENT: {
      page: {},
      unread: 0,
      notifications: [],
    },
    METAVERSE_3D: {
      page: {},
      unread: 0,
      notifications: [],
    },
  };

  private eventProcessedSubj = new Subject<NotificationEventProcessType>();
  eventProcessed = this.eventProcessedSubj.asObservable();

  constructor(
    private api: UserMessagesControllerService,
    private factory: NotificationsFactory,
    private stateClearService: StateClearService,
    private toaster: Toaster,
    private i18nService: I18nService,
    private GTMService: GTMService,
    private FbService: FbService,
    private currencyService: CurrencyService,
    private marketGtmService: MarketGTMService,
    private userService: UserService,
    private accountsService: AccountsService,
    auth: AuthService,
    socket: SocketService
  ) {
    this.stateClearService.register(this);
    auth.loggedIn.subscribe(() => {
      if (!localStorage.getItem('REFERRAL_CODE_CHECKED')) {
        this.checkReferralCodeNotification().subscribe(notification => {
          if (notification) {
            this.toaster.notification(
              this.i18nService.get(notification?.title || ''),
              notification?.icon
            );
          }
          localStorage.setItem('REFERRAL_CODE_CHECKED', '1');
        });
      }
      this.count().subscribe();
      this.processAll().subscribe();
      this.loadMetrics().subscribe();
    });
    socket
      .on<NotificationEventData<string | MarketBuySellGTMData | DepositGTMData>>()
      .pipe(
        mergeMap(message =>
          this.processEvent(message as NotificationEventProcessType).pipe(take(1))
        )
      )
      .subscribe(
        (message: NotificationEventData<string | MarketBuySellGTMData | DepositGTMData>) => {
          if (message.eventType === 'USER_MESSAGE_RECEIVED' && message.data.read === false) {
            this.data.ALL.unread++;
            const notification = this.factory.get(message.data as Partial<UserMessageTOResponse>);
            this.data.ALL.notifications.unshift(notification);

            if (!message.data.processedForMetrics && message.data.messageType === 'STREET_SELL') {
              this.api
                .markAsProcessedForMetricsUsingPOST(message.data.id)
                .pipe(
                  tap(() => {
                    this.pushGTMSingle(message.data as NotificationEventDataData<string>);
                  })
                )
                .subscribe();
            }

            // TODO: RL: Need to add tabType on response
            switch (notification.type) {
              case NotificationMessageType.DOCUMENT_REQUEST:
                this.data.DOCUMENT.unread++;
                this.data.DOCUMENT.notifications.unshift(notification);
                break;
              case NotificationMessageType.REWARD_3D:
                this.data.METAVERSE_3D.unread++;
                this.data.METAVERSE_3D.notifications.unshift(notification);
                break;
              default:
                this.data.ASSET.unread++;
                this.data.ASSET.notifications.unshift(notification);
            }
          }
        }
      );
  }

  count(): Observable<UserMessageCountsResponseTO> {
    return this.api.userMessageCountsUsingGET().pipe(
      tap(data => {
        this.data.ALL.unread =
          (data?.documentsCount || 0) + (data?.assetsCount || 0) + (data?.metaverseCount || 0);
        this.data.ASSET.unread = data?.assetsCount || 0;
        this.data.DOCUMENT.unread = data?.documentsCount || 0;
        this.data.METAVERSE_3D.unread = data?.metaverseCount || 0;
      })
    );
  }

  loadMetrics(): Observable<UserMessageTOListResponse> {
    return this.api.listUsingGET19({ type: 'ALL', pageSize: 100 }).pipe(
      switchMap((result: UserMessageTOListResponse) => {
        const metricsNotification = result.list?.filter(
          (x: UserMessageTOResponse) => !x.processedForMetrics && x.messageType === 'STREET_SELL'
        );
        if (metricsNotification && metricsNotification?.length !== 0) {
          return this.api.markAllProcessedForMetricsUsingPOST({ type: 'ALL' }).pipe(
            tap(() => {
              this.pushGTMArray(metricsNotification as NotificationEventDataData<string>[]);
            })
          );
        } else {
          return of({});
        }
      })
    );
  }

  load(type: Tabs): Observable<UserMessageTOListResponse> {
    return this.api.listUsingGET19({ type: type }).pipe(
      tap(data => {
        this.data[type].page = data.page || {};
        if (data.list) {
          this.data[type].notifications = data.list.map(n => this.factory.get(n));
        }
      })
    );
  }

  checkReferralCodeNotification(): Observable<UserNotification | null> {
    return this.api.listUsingGET19({ pageSize: 1, pageNumber: 0 }).pipe(
      map(data => this.factory.get(data.list?.[0])),
      map((notification: UserNotification) =>
        notification?.type === 'REFERRAL_CODE_INCOME' ? notification : null
      )
    );
  }

  loadNextPage(type: Tabs): Observable<UserMessageTOListResponse> {
    const nextPage = (this.data[type].page.pageNumber || 0) + 1;
    if (nextPage <= (this.data[type].page.totalPages || 0)) {
      return this.api
        .listUsingGET19({
          type: type,
          pageNumber: nextPage,
        })
        .pipe(
          tap(data => {
            this.data[type].page = data.page || {};
            if (data.list) {
              this.data[type].notifications = [
                ...this.data[type].notifications,
                ...data.list.map(n => this.factory.get(n)),
              ];
            }
          })
        );
    } else {
      return of({});
    }
  }

  markAsRead(notification: UserNotification): void {
    if (notification.isRead !== true) {
      this.api
        .markAsReadUsingPOST(notification.id)
        .pipe(
          tap(result => {
            if (this.data.ALL.unread > 0) {
              this.data.ALL.unread--;
            }

            if (
              notification.tabType === NotificationTabType.DOCUMENT &&
              this.data.DOCUMENT.unread > 0
            ) {
              this.data.DOCUMENT.unread--;
            }

            if (notification.tabType === NotificationTabType.ASSET && this.data.ASSET.unread > 0) {
              this.data.ASSET.unread--;
            }

            if (
              notification.tabType === NotificationTabType.METAVERSE_3D &&
              this.data.METAVERSE_3D.unread > 0
            ) {
              this.data.METAVERSE_3D.unread--;
            }

            notification.isRead = true;
          })
        )
        .subscribe();
    }
  }

  markAllAsRead(): Observable<UserMessageTOListResponse[]> {
    return this.api.markAllReadUsingPOST({ type: 'ALL' }).pipe(
      switchMap(() => this.count()),
      switchMap(() =>
        forkJoin([
          this.load('ALL'),
          this.load('ASSET'),
          this.load('DOCUMENT'),
          this.load('METAVERSE_3D'),
        ])
      )
    );
  }

  processAll(): Observable<unknown> {
    return this.api
      .listUsingGET19({
        onlyNotProcessed: true,
        pageSize: 100,
        pageNumber: 0,
        type: 'ALL',
      })
      .pipe(
        map(
          data =>
            data.list?.map(item => ({
              ...item,
              params: JSON.parse(item.params || '') as unknown,
            })) || []
        ),
        map(messages =>
          messages.filter(
            item =>
              NOTIFICATIONS_WHITELIST_EVENTS_PROCESSES.includes(
                item.messageType as ProcessedEventTypes
              ) ||
              (item.messageType === 'ORDER_EXECUTED' &&
                (item.params as { currency?: string })?.currency === 'NFT')
          )
        ),
        switchMap(messages => {
          if (messages?.length) {
            return this.api.markAllProcessedUsingPOST({ type: 'ALL' }).pipe(
              tap(() => {
                messages?.forEach(message => {
                  this.pushProcessedEventTag(
                    message.messageType as ProcessedEventTypes,
                    message.params as DepositGTMData | MarketBuySellGTMData
                  );
                });
              })
            );
          }
          return of(null);
        })
      );
  }

  clearState(): void {
    this.factory.emptyStore();

    this.data = {
      ALL: {
        page: {},
        unread: 0,
        notifications: [],
      },
      ASSET: {
        page: {},
        unread: 0,
        notifications: [],
      },
      DOCUMENT: {
        page: {},
        unread: 0,
        notifications: [],
      },
      METAVERSE_3D: {
        page: {},
        unread: 0,
        notifications: [],
      },
    };
  }

  private pushProcessedEventTag(
    type: ProcessedEventTypes,
    params: MarketBuySellGTMData | DepositGTMData
  ): void {
    switch (type) {
      case 'STREET_SELL': {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const { city, country, price, street, zone } = params as MarketBuySellGTMData;
        this.marketGtmService.pushSellTag({
          city,
          country,
          price,
          street,
          zone,
        });
        break;
      }
      case 'ORDER_EXECUTED': {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const { street, country, city, zone, lastPrice: price } = params as MarketBuySellGTMData;
        this.marketGtmService.pushBuyTag({
          street,
          country,
          city,
          zone,
          price,
        } as MarketBuySellGTMData);
        break;
      }
      case 'DEPOSIT_RECEIVED': {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const { currency, amount, transactionId } = params as DepositGTMData;
        const method = currency === 'USD' || currency === 'EUR' ? 'fiat' : 'crypto';
        const usdAmount =
          method === 'crypto' ? this.currencyService.toBaseRate(amount, currency) : +amount;
        let quantity = 0;
        if (method === 'fiat') {
          const baseDWRLDrate = this.currencyService.getBaseRate('DWRLD');
          const baseCurrencyRate = this.currencyService.getBaseRate(currency);
          quantity = amount * (1 / baseDWRLDrate) * baseCurrencyRate;
        } else {
          quantity = this.currencyService.toDwrldRate(+amount, currency);
        }
        const config = {
          event: 'purchase',
          user_id: this.userService.user.id,
          email: this.userService.user.email,
          ecommerce: {
            transaction_id: transactionId,
            value: usdAmount,
            currency: 'USD',
            items: [
              {
                item_name: 'DWRLD', // item name
                quantity: quantity, // amount of items
                price: +amount,
                currency: currency,
              },
            ],
          },
        };
        this.FbService.pushTag(config);
        this.marketGtmService.pushDepositTag({
          currency,
          amount: +amount,
          userId: this.userService.user.id,
          email: this.userService.user.email,
          transactionId,
        });
      }
    }
  }

  private processEvent(
    message: NotificationEventProcessType
  ): Observable<NotificationEventProcessType> {
    const isProcessedType =
      NOTIFICATIONS_WHITELIST_EVENTS_PROCESSES.includes(
        message.data.messageType as ProcessedEventTypes
      ) ||
      (message.data.messageType === 'ORDER_EXECUTED' && message.data.params?.currency === 'NFT');
    if (message.eventType === 'USER_MESSAGE_RECEIVED' && isProcessedType) {
      return this.api.markAsProcessedUsingPOST(message.data.id).pipe(
        map(() => ({
          ...message,
          data: {
            ...message.data,
            processed: true,
          },
        })),
        tap(() => {
          this.pushProcessedEventTag(
            message.data.messageType as ProcessedEventTypes,
            message.data.params
          );
          this.eventProcessedSubj.next(message);
        })
      );
    }
    return of(message);
  }

  private pushGTMArray(data: NotificationEventDataData<any>[]): void {
    const items = data.map(item => {
      const name = `${item.params.name || item.params.street} ${item.params.number || ''}`;
      return {
        item_name: name, // street name
        item_id: item.params.nftId, // street ID
        item_country: item.params.country, // street country
        item_city: item.params.city, // city
        item_level: item.params.zone, // street level
        item_token: item.params.price, // street value in DWRLD tokens
        quantity: '1', // amount of items
        price: this.currencyService.toDwrldRate(item.params.price, 'USD'), // item worth amount in fiat
      };
    });
    const totalValue = items.reduce((acum, item) => {
      acum += item.item_token;
      return acum;
    }, 0);
    const config = {
      event: 'in_game_sell',
      ecommerce: {
        in_game_transaction_id: `${this.userService.user.accountId}-${data[0].params.nftId}`,
        token_name: 'DWRLD', // token name
        token_amount: totalValue, // amount of tokens
        value: this.currencyService.toDwrldRate(totalValue, 'USD'), // total tokens value in fiat
        currency: 'USD', // currency
        items: [...items],
      },
    };
    this.FbService.pushTag(config);
    this.GTMService.pushTag(config);
  }

  private pushGTMSingle(data: NotificationEventDataData<any>): void {
    const item = {
      ...data,
    };

    const name = `${item.params.name || item.params.street} ${item.params.number || ''}`;
    const config = {
      event: 'in_game_sell',
      ecommerce: {
        in_game_transaction_id: `${this.userService.user.accountId}-${data.params.nftId}`,
        token_name: 'DWRLD', // token name
        token_amount: item.params.price, // amount of tokens
        value: this.currencyService.toDwrldRate(item.params.price, 'USD'), // total tokens value in fiat
        currency: 'USD', // currency
        items: [
          {
            item_name: name, // street name
            item_id: item.params.nftId, // street ID
            item_country: item.params.country, // street country
            item_city: item.params.city, // city
            item_level: item.params.zone, // street level
            item_token: item.params.price, // street value in DWRLD tokens
            quantity: '1', // amount of items
            price: this.currencyService.toDwrldRate(item.params.price, 'USD'), // item worth amount in fiat
          },
        ],
      },
    };
    this.FbService.pushTag(config);
    this.GTMService.pushTag(config);
  }
}
