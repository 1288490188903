import { Injectable } from '@angular/core';
import {
  MarketOhlcvDataDtoResponse,
  MarketTOResponse,
  NftHistoryTOList,
  OrderTOResponse,
  PageTO,
} from 'api/models';
import {
  GisControllerService,
  MarketControllerService,
  MarketOrderControllerService,
  NftControllerService,
  OrderControllerService,
  TradeControllerService,
} from 'api/services';
import { StateFullService } from 'app/_main/services/state-clear.service';
import { APIError } from 'core/models/error.model';
import { Order } from 'dashboard/models/order.model';
import { UserService } from 'profile/services/user.service';
import { asyncScheduler, Observable, of, Subject } from 'rxjs';
import { finalize, map, mergeMap, tap } from 'rxjs/operators';

import { Street } from '../models/street.model';
import { CartService } from './cart.service';
import { MarketGTMService } from './market-gtm.service';
import { OrdersFactory } from './orders.factory';
import { StreetsFactory } from './streets.factory';

export interface StreetHistory {
  price: number;
  date: Date;
  user: string;
}

export interface StreetHistoryWithMoneyResultResponse {
  elements?: StreetHistoryWithMoneyResult[];
  pageInfo?: PageTO;
}

export interface StreetHistoryWithMoneyResult {
  moneyResult?: number;
  order?: Order;
}

@Injectable({ providedIn: 'root' })
export class StreetsService implements StateFullService {
  private streetSellSubj = new Subject<{ street: Street; isLimit: boolean }>();
  onStreetSell = this.streetSellSubj.asObservable();

  constructor(
    private streetsFactory: StreetsFactory,
    private ordersFactory: OrdersFactory,
    private userService: UserService,
    private gisApi: GisControllerService,
    private tradeApi: TradeControllerService,
    private cartService: CartService,
    private streetOrderApi: MarketOrderControllerService,
    private ordersApi: OrderControllerService,
    private marketApi: MarketControllerService,
    private marketGtmService: MarketGTMService,
    private nftApi: NftControllerService
  ) {}

  findStreetOrMarket(id?: number): Observable<Street | undefined> {
    if (!id) {
      return of(undefined);
    }
    return this.findStreet({ id }).pipe(
      mergeMap(street => (street.marketId ? this.findByNFTMarket({ streetId: id }) : of(street)))
    );
  }

  findStreet(params: GisControllerService.FindStreetUsingGETParams): Observable<Street> {
    return this.gisApi
      .findStreetUsingGET(params)
      .pipe(map(data => this.streetsFactory.get(data.id, data)));
  }

  findByNFTMarket(params: { streetId?: number; nftId?: number }): Observable<Street | undefined> {
    return new Observable<undefined | Street>(s => {
      const sub = this.marketApi
        .findMarketUsingGET({ streetId: params.streetId, nftId: params.nftId })
        .pipe(finalize(() => s.complete()))
        .subscribe(
          res => {
            const market = this.streetsFactory.getMarket(res.nft?.properties?.streetId, res);
            s.next(market.street as Street);
          },
          (err: APIError) => {
            err.preventHandling();
            s.next(undefined);
          }
        );
      return () => {
        sub.unsubscribe();
      };
    });
  }

  getBidsHistory(marketId?: number): Observable<StreetHistory[]> {
    if (!marketId) {
      return of([]);
    }
    return this.ordersApi
      .listUsingGET15({ markets: [marketId], side: 'BUY', orderType: 'LIMIT', isNft: true })
      .pipe(
        map(res =>
          (res.list || []).map(o => ({
            price: o.price || 0,
            date: new Date(o.createdAt || 0),
            user: o.userName || '',
          }))
        )
      );
  }

  getHistory(marketId?: number): Observable<StreetHistory[]> {
    if (!marketId) {
      return of([]);
    }
    return this.ordersApi.historyUsingGET1({ side: 'BUY' }).pipe(
      map(res =>
        (res.list || []).map(d => ({
          user: d.userName || '',
          date: new Date(d.processedAt || 0),
          price: d.averagePrice || 0,
        }))
      )
    );
  }

  getPriceHistory(marketId: number): Observable<MarketOhlcvDataDtoResponse> {
    return this.tradeApi.ohlcvHistoryUsingGET({
      marketId,
      resolution: '12h',
      dateFrom: 1,
      dateTo: Date.now(),
    });
  }

  buy(
    street: Street,
    price?: number,
    currencyCode?: string
  ): Observable<MarketTOResponse | OrderTOResponse> {
    if (street.marketId || street.nft.ownerId) {
      let req = this.findByNFTMarket({ streetId: street.id });
      if (street.marketId) {
        req = of(undefined);
      }
      return req.pipe(
        mergeMap(res =>
          this.ordersApi
            .openUsingPOST({
              side: 'BUY',
              type: price ? 'LIMIT' : 'MARKET',
              amount: 1,
              marketId: res?.marketId || street.marketId,
              price,
            })
            .pipe(
              tap(orderRes => {
                if (!price) {
                  street.lastPrice = street.sellPrice;
                  street.sellPrice = 0;
                  street.buyPrice = 0;
                  street.orderId = orderRes?.id || 0;
                  street.nft.ownerId = this.userService.user.id;
                  street.nft.ownerName = this.userService.user.username;
                  asyncScheduler.schedule(() => {
                    this.findByNFTMarket({ streetId: street.id }).subscribe();
                  }, 1000);
                } else {
                  price = price || 0;
                  street.buyPrice = street.buyPrice < price ? price : street.buyPrice;
                  street.orderId = orderRes?.id || 0;

                  this.marketGtmService.pushOfferTag(street);
                }
                this.cartService.remove(street).subscribe();
              })
            )
        )
      );
    }
    return this.streetOrderApi
      .buyingMarketUsingPOST1({
        streetId: street.id,
        currencyCode,
      })
      .pipe(
        tap(res => {
          street.sellPrice = 0;
          street.nft.ownerId = this.userService.user.id;
          street.nft.ownerName = this.userService.user.username;
          street.marketId = res.id || 0;
          this.cartService.remove(street).subscribe();
          // this.marketGtmService.pushPurchaseTag(street);
        })
      );
  }

  sell(street: Street, price?: number): Observable<OrderTOResponse> {
    let req = this.findByNFTMarket({ streetId: street.id });
    if (street.marketId) {
      req = of(undefined);
    }
    return req.pipe(
      mergeMap(res =>
        this.ordersApi
          .openUsingPOST({
            side: 'SELL',
            type: price ? 'LIMIT' : 'MARKET',
            amount: 1,
            marketId: res?.marketId || street.marketId,
            price,
          })
          .pipe(
            tap(() => {
              if (!price) {
                // reset owner for market order
                street.nft.ownerId = '00';
                street.nft.ownerName = '  ';
                street.sellPrice = 0;
                street.buyPrice = 0;
                asyncScheduler.schedule(() => {
                  this.findByNFTMarket({ streetId: street.id }).subscribe();
                }, 1000);
              } else {
                street.sellPrice = price;
                this.marketGtmService.pushListingTag(street);
              }
              this.streetSellSubj.next({ street, isLimit: !!price });
            })
          )
      )
    );
  }

  cancelOrder(street: Street): Observable<unknown> {
    return this.ordersApi.listUsingGET15({ markets: [street.marketId] }).pipe(
      mergeMap(res => {
        if (res.list && res.list.length !== 0) {
          return this.ordersApi.closeUsingPOST({ orderId: (res.list || [])[0].id || 0 });
        }
        return of(null);
      }),
      tap(order => {
        if (order) {
          if (street.isOwner) {
            street.sellPrice = 0;
          } else {
            street.buyPrice = order?.nft?.properties?.buyingPrice || 0;
            street.orderId = 0;
          }
        }
      })
    );
  }

  getNftHistory(
    params: NftControllerService.GetNftHistoryUsingGETParams = {
      nftId: 0,
      pageSize: 5,
      pageNumber: 0,
    }
  ): Observable<NftHistoryTOList> {
    return this.nftApi.getNftHistoryUsingGET(params);
  }

  getStreetOrder(street: Street, marketId = 0): Observable<Order | undefined> {
    return this.ordersApi.listUsingGET15({ markets: [street.marketId || marketId] }).pipe(
      map(res => {
        const orderData = (res.list || [])[0];
        if (orderData) {
          return this.ordersFactory.getOrder(orderData.id, orderData);
        }
        return undefined;
      })
    );
  }

  clearState(): void {
    this.streetsFactory.emptyStore();
    this.ordersFactory.emptyStore();
  }

  private pushTag(): void {}
}
