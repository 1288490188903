import { CdkConnectedOverlay, CdkOverlayOrigin, ConnectedPosition } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { asyncScheduler, fromEvent, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, first, takeUntil } from 'rxjs/operators';

import { MediaQuery } from '../../modules/platform/services/media-query.service';
import { AnchorDirective } from './anchor.directive';

@Directive({})
export class DropdownBase implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  private static dropdownList: DropdownBase[] = [];

  // TODO: for now support only static button anchor
  @ContentChild(AnchorDirective, { static: true })
  anchorRef?: AnchorDirective;

  @ContentChild(TemplateRef, { static: true })
  tmplRef!: TemplateRef<unknown>;

  @ViewChild(CdkConnectedOverlay, { static: true })
  overlay!: CdkConnectedOverlay;

  @Input()
  offsetY = 0;

  @Input()
  enabled = true;
  @Input()
  enabledOverlay = true;
  @Input()
  theme: 'light' | 'dark' = 'light';
  @Input()
  appearance: 'full-screen' | 'default' | 'filters' = 'default';
  @Input()
  align: 'center' | 'start' | 'end' | 'top' = 'start';
  @Input()
  // close on click inside dropdown container
  // default: false - so it will close only on click outside
  innerClickClose = false;
  @Input()
  minWidth: number | string = 0;
  @Input()
  useHover?: number;
  @Input()
  manualClose?: boolean;
  @Input()
  animation: 'slide' | 'fade' = 'slide';
  @Output()
  opened = new EventEmitter();
  @Output()
  closed = new EventEmitter();

  transitionState?: 'slide' | 'fade';
  position: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
    },
    {
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom',
    },
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
  ];

  protected destroy$ = new Subject();
  private isActive = false;
  private preventClosing = false;
  private isFocused = false;
  private isTouch?: boolean;

  constructor(
    protected media: MediaQuery,
    protected renderer: Renderer2,
    protected router: Router,
    public elementRef: ElementRef<HTMLElement>,
    protected document: Document
  ) {
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationStart),
        takeUntil(this.destroy$)
      )
      .subscribe(e => {
        const toUrl = (e as NavigationStart).url.replace(/\?.*$/, '');
        if (toUrl !== router.url.replace(/\?.*$/, '')) {
          this.close();
        }
      });
    this.renderer.addClass(this.elementRef.nativeElement, 'dropdown');
  }

  get active(): boolean {
    return this.isActive && this.enabled;
  }

  get overlayOrigin(): CdkOverlayOrigin {
    return this;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    const foundIndex = DropdownBase.dropdownList.findIndex(d => d === this);
    if (foundIndex >= 0) {
      DropdownBase.dropdownList.splice(foundIndex, 1);
    }
  }

  ngOnInit(): void {
    fromEvent(window, 'click')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.useHover && !this.isTouch) {
          return;
        }
        if (!this.isFocused && !this.preventClosing && !this.manualClose) {
          this.close();
        }
      });
    fromEvent(window, 'blur')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.media.atLeast('medium')) {
          this.close();
        }
      });
    fromEvent(window, 'touchstart')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.isTouch = true;
      });
    this.media.mediaChanged.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.close();
    });
    DropdownBase.dropdownList.push(this);
  }

  ngOnChanges(changes: SimpleChanges<DropdownBase>): void {
    if (changes.align) {
      this.updateAlign(this.align);
    }
  }

  ngAfterViewInit(): void {
    if (this.anchorRef) {
      const anchorElement = this.anchorRef.element;
      const anchorHostElement = this.anchorRef.hostElement;
      const currentElement = this.elementRef.nativeElement;
      // if not input - close dropdown on second click at anchor
      if (!(anchorElement instanceof HTMLInputElement)) {
        this.renderer.setAttribute(anchorHostElement, 'tabindex', '-1');
        fromEvent<MouseEvent>(anchorHostElement, 'mousedown')
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            if (this.isFocused) {
              asyncScheduler.schedule(() => anchorElement.blur());
            } else {
              asyncScheduler.schedule(() => anchorElement.focus());
            }
          });
      }
      if (this.useHover) {
        let mouseLeaveSub = new Subscription();
        let overlayEnterSub = new Subscription();
        let overlayLeaveSub = new Subscription();
        let leaveOccurred = false;
        let isTouch = false;
        let overlayElement: HTMLElement | undefined;
        fromEvent(currentElement, 'touchstart')
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            isTouch = true;
          });
        fromEvent(currentElement, 'mouseenter')
          .pipe(debounceTime(this.useHover), takeUntil(this.destroy$))
          .subscribe(() => {
            if (leaveOccurred || isTouch) {
              isTouch = false;
              return;
            }
            this.open();
            // close other dropdown immediately instead 250ms debounce
            DropdownBase.dropdownList.forEach(d => (d !== this ? d.close() : null));
            mouseLeaveSub.unsubscribe();
            overlayLeaveSub.unsubscribe();
            overlayEnterSub.unsubscribe();
            mouseLeaveSub = fromEvent(currentElement, 'mouseleave')
              .pipe(debounceTime(250), takeUntil(this.destroy$))
              .subscribe(() => {
                this.close();
              });
            if (isTouch) {
              return;
            }
            asyncScheduler.schedule(() => {
              overlayElement = this.overlay.overlayRef.overlayElement;
              overlayEnterSub = fromEvent(overlayElement, 'mouseenter')
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => {
                  mouseLeaveSub.unsubscribe();
                  overlayLeaveSub.unsubscribe();
                  overlayLeaveSub = fromEvent(overlayElement as HTMLElement, 'mouseleave')
                    .pipe(debounceTime(250), takeUntil(this.destroy$))
                    .subscribe(() => {
                      this.close();
                    });
                });
            });
          });
        fromEvent(currentElement, 'mouseleave')
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            if (this.isActive) {
              return;
            }
            leaveOccurred = true;
            asyncScheduler.schedule(() => (leaveOccurred = false), (this.useHover || 0) + 50);
          });
      }
      if (this.appearance === 'full-screen' && this.media.is('small only')) {
        fromEvent(anchorElement, 'touchstart')
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.renderer.addClass(this.anchorRef?.hostElement, 'anchor-touched');
          });
      }
      fromEvent(anchorElement, 'focus')
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.isFocused = true;
          this.open();
        });
      fromEvent(anchorElement, 'blur')
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.isFocused = false;
          if (this.preventClosing || this.manualClose) {
            return;
          }
          this.close();
        });
    }
  }

  toggle(): void {
    if (this.active) {
      this.close();
    } else {
      this.open();
    }
  }

  open(): void {
    if (!this.enabledOverlay) {
      return;
    }

    if (this.active) {
      return;
    }

    this.minWidth = this.minWidth || this.elementRef.nativeElement.clientWidth;
    this.isActive = true;
    this.onOpen();
  }

  close(): void {
    if (!this.isActive) {
      return;
    }
    this.isActive = false;
    this.anchorRef?.element.blur();
    this.onClose();
  }

  stopClose(): void {
    if (this.innerClickClose) {
      return;
    }
    this.forceStopClose();
  }

  forceStopClose(): void {
    this.preventClosing = true;
    fromEvent(window, 'mouseup')
      .pipe(first())
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(() => {
        asyncScheduler.schedule(() => (this.preventClosing = false), 100);
      });
  }

  // close with delay to be able to show some feedback to user
  scheduleClose(): void {
    asyncScheduler.schedule(() => {
      this.close();
    }, 150);
  }

  protected onOpen(): void {
    this.renderer.addClass(this.anchorRef?.hostElement, 'anchor-active');
    if (this.media.is('small only')) {
      this.renderer.addClass(this.document.body, 'cdk-anchor-active');
      if (this.appearance === 'default') {
        this.renderer.addClass(this.document.body, 'cdk-overlay');
      } else if (this.appearance === 'full-screen') {
        this.renderer.addClass(this.elementRef.nativeElement, 'full-screen');
      }
    }
    this.transitionState = this.animation;
    this.opened.next();
  }

  protected onClose(): void {
    this.renderer.removeClass(this.anchorRef?.elementRef.nativeElement, 'anchor-active');
    if (this.appearance === 'default') {
      this.renderer.addClass(this.document.body, 'cdk-overlay-off');
      asyncScheduler.schedule(() => {
        this.renderer.removeClass(this.document.body, 'cdk-overlay');
        this.renderer.removeClass(this.document.body, 'cdk-overlay-off');
      }, 300);
    } else if (this.appearance === 'full-screen') {
      this.renderer.removeClass(this.elementRef.nativeElement, 'full-screen');
      this.renderer.removeClass(this.anchorRef?.hostElement, 'anchor-touched');
    }
    this.renderer.removeClass(this.document.body, 'cdk-anchor-active');
    this.transitionState = undefined;
    this.closed.next();
  }

  private updateAlign(align: 'center' | 'start' | 'end' | 'top'): void {
    if (align === 'center') {
      this.position[0] = {
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top',
      };
    } else if (align === 'start') {
      this.position[0] = {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      };
    } else if (align === 'end') {
      this.position[0] = {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top',
      };
    } else if (align === 'top') {
      this.position[0] = {
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom',
      };
    }
  }
}
