<app-data-expand
  *ngIf="hasLongText"
  [hideIcon]="true"
  [expanded]="expanded"
  [ngClass]="{ unread: !notification?.isRead }"
  (click)="readNotificationAction()"
  class="expand notification-body"
  (toggled)="onToggle($event)">
  <ng-template>
    <div class="expand-content">
      {{ notification?.long | translate }}
      <div class="action-container" [ngSwitch]="notification?.type">
        <a
          *ngSwitchCase="notificationMessageType.DEPOSIT_RECEIVED"
          [routerLink]="appNav.cashierNav.myBalances()">
          {{ 'profile.notifications.go-to-wallet' | translate }}
        </a>
        <ng-container *ngSwitchCase="notificationMessageType.INITIAL_NFT_BUY">
          <a
            *ngIf="notification.params?.dtype === 'BuildingNft'"
            [routerLink]="appNav.dashboardNav.assets('buildings')">
            {{ 'profile.notifications.go-to-my-assets' | translate }}
          </a>
          <a
            *ngIf="notification.params?.dtype === 'Nft'"
            [routerLink]="appNav.dashboardNav.assets('streets')">
            {{ 'profile.notifications.go-to-my-assets' | translate }}
          </a>
        </ng-container>
        <a
          *ngSwitchCase="
            notification.type === notificationMessageType.ORDER_EXECUTED &&
            notification.params?.currency === 'NFT'
              ? notification.type
              : ''
          "
          [routerLink]="appNav.dashboardNav.assets('streets')">
          {{ 'profile.notifications.go-to-my-assets' | translate }}
        </a>
        <a
          *ngSwitchCase="notificationMessageType.STREET_SELL"
          [routerLink]="appNav.marketplaceNav.marketplaceHistory()">
          {{ 'profile.notifications.go-to-market-history' | translate }}
        </a>
        <a
          *ngSwitchCase="notificationMessageType.COLLECTION_BALANCE_CHANGED"
          [routerLink]="
            appNav.collectionsNav.userCollection(notification.params?.collectionId + '')
          ">
          {{ 'profile.notifications.go-to-collection' | translate }}
        </a>
        <a
          *ngSwitchCase="notificationMessageType.INCOMING_REFERRAL_COMMISSION"
          [routerLink]="appNav.dashboardNav.affiliate()">
          {{ 'profile.notifications.go-to-referrals' | translate }}
        </a>
        <a *ngSwitchCase="notificationMessageType.MYSTERY_BOX_OPEN" (click)="openMysteryBox()">
          {{ 'profile.notifications.open-mystery-box' | translate }}
        </a>
        <a
          *ngSwitchCase="notificationMessageType.USER_WANT_BUY_NFT"
          [routerLink]="appNav.marketplaceNav.marketsRoot()">
          {{ 'profile.notifications.go-to-markets' | translate }}
        </a>
        <a
          *ngSwitchCase="notificationMessageType.KAYAKO_NEW_MESSAGE"
          [routerLink]="[appNav.commonNav.support(), 'inbox', notification.long?.args?.ticketId]">
          {{ 'profile.notifications.go-to-ticket' | translate }}
        </a>
      </div>
    </div>
  </ng-template>

  <ng-container *ngTemplateOutlet="notificationTemplate"></ng-container>
</app-data-expand>

<div
  class="expand notification-body"
  [ngClass]="{ unread: !notification?.isRead }"
  (click)="readNotificationAction()"
  *ngIf="!hasLongText">
  <ng-container *ngTemplateOutlet="notificationTemplate"></ng-container>
</div>

<ng-template #notificationTemplate>
  <div class="notification-content">
    <app-icon [icon]="notification?.icon" class="notification-icon"></app-icon>
    <div class="content">
      <ng-container *ngIf="notification?.short | i18nKeyExists; else titleTemplate">
        <ng-container
          *ngTemplateOutlet="
            titleTemplate;
            context: { $implicit: notification?.short }
          "></ng-container>
      </ng-container>
      <p class="date" *mediaIf="'large'">
        {{ notification?.date | date: 'medium' }}
      </p>
      <p class="date" *mediaIf="'medium down'">
        {{ notification?.date | date: 'd LLL y hh:mm aa' }}
      </p>
    </div>
  </div>
</ng-template>

<ng-template #titleTemplate let-title>
  <p class="title ellipsis">{{ title || notification?.title | translate }}</p>
</ng-template>
