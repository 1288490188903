import { DOCUMENT } from '@angular/common';
import {
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Input,
  Optional,
  Renderer2,
} from '@angular/core';
import { ButtonComponent } from 'core/components/button/button.component';
import { TooltipDirective } from 'core/components/tooltip/tooltip.directive';

@Directive({ selector: '[appCopy]' })
export class CopyDirective {
  @Input()
  appCopy?: string;

  constructor(
    public elementRef: ElementRef<HTMLElement>,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    @Optional() private button?: ButtonComponent,
    @Optional() private tooltip?: TooltipDirective
  ) {
    if (tooltip) {
      tooltip.text = 'Copy';
    }
  }

  @HostListener('click')
  onClick(): void {
    this.copyToClipboard(this.appCopy || '');
    if (this.tooltip) {
      this.tooltip.shake('Copied!');
    } else if (this.button) {
      this.button.showSuccess();
    }
  }

  private copyToClipboard(text: string): void {
    const el = this.renderer.createElement('textarea') as HTMLTextAreaElement;
    el.value = text;
    this.renderer.appendChild(this.document.body, el);
    el.select();
    this.document.execCommand('copy');
    this.renderer.removeChild(this.document.body, el);
    this.elementRef.nativeElement.focus();
  }
}
