/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { KayakoMessageResponse } from '../models/kayako-message-response';
import { KayakoTicketResponse } from '../models/kayako-ticket-response';
import { KayakoMessageListResponse } from '../models/kayako-message-list-response';
import { KayakoTicketListResponse } from '../models/kayako-ticket-list-response';

/**
 * Kayako Controller
 */
@Injectable({
  providedIn: 'root',
})
class KayakoControllerService extends __BaseService {
  static readonly addAttachmentUsingPOSTPath = '/api/v1/kayako/add-attachment';
  static readonly changeTicketStatusUsingPOSTPath = '/api/v1/kayako/change-status';
  static readonly createTicketUsingPOSTPath = '/api/v1/kayako/create-ticket';
  static readonly getAttachmentUsingGETPath = '/api/v1/kayako/get-attachment';
  static readonly getMessagesForTicketUsingGETPath = '/api/v1/kayako/messages';
  static readonly listPriorityOptionsUsingGETPath = '/api/v1/kayako/priority-options';
  static readonly sendMessageUsingPOSTPath = '/api/v1/kayako/send-message';
  static readonly listTicketsUsingGETPath = '/api/v1/kayako/tickets';
  static readonly listTopicOptionsUsingGETPath = '/api/v1/kayako/topics-options';
  static readonly getUnreadPostsCountUsingGETPath = '/api/v1/kayako/unread-posts-count';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * addAttachment
   * @param params The `KayakoControllerService.AddAttachmentUsingPOSTParams` containing the following parameters:
   *
   * - `ticketId`: ticketId
   *
   * - `postId`: postId
   *
   * - `file`:
   *
   * @return OK
   */
  addAttachmentUsingPOSTResponse(params: KayakoControllerService.AddAttachmentUsingPOSTParams): __Observable<__StrictHttpResponse<KayakoMessageResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.ticketId != null) __params = __params.set('ticketId', params.ticketId.toString());
    if (params.postId != null) __params = __params.set('postId', params.postId.toString());
    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/kayako/add-attachment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<KayakoMessageResponse>;
      })
    );
  }
  /**
   * addAttachment
   * @param params The `KayakoControllerService.AddAttachmentUsingPOSTParams` containing the following parameters:
   *
   * - `ticketId`: ticketId
   *
   * - `postId`: postId
   *
   * - `file`:
   *
   * @return OK
   */
  addAttachmentUsingPOST(params: KayakoControllerService.AddAttachmentUsingPOSTParams): __Observable<KayakoMessageResponse> {
    return this.addAttachmentUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as KayakoMessageResponse)
    );
  }

  /**
   * changeTicketStatus
   * @param params The `KayakoControllerService.ChangeTicketStatusUsingPOSTParams` containing the following parameters:
   *
   * - `ticketId`: ticketId
   *
   * - `status`: status
   *
   * @return OK
   */
  changeTicketStatusUsingPOSTResponse(params: KayakoControllerService.ChangeTicketStatusUsingPOSTParams): __Observable<__StrictHttpResponse<KayakoTicketResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ticketId != null) __params = __params.set('ticketId', params.ticketId.toString());
    if (params.status != null) __params = __params.set('status', params.status.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/kayako/change-status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<KayakoTicketResponse>;
      })
    );
  }
  /**
   * changeTicketStatus
   * @param params The `KayakoControllerService.ChangeTicketStatusUsingPOSTParams` containing the following parameters:
   *
   * - `ticketId`: ticketId
   *
   * - `status`: status
   *
   * @return OK
   */
  changeTicketStatusUsingPOST(params: KayakoControllerService.ChangeTicketStatusUsingPOSTParams): __Observable<KayakoTicketResponse> {
    return this.changeTicketStatusUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as KayakoTicketResponse)
    );
  }

  /**
   * createTicket
   * @param params The `KayakoControllerService.CreateTicketUsingPOSTParams` containing the following parameters:
   *
   * - `title`: title
   *
   * - `message`: message
   *
   * - `topic`: topic
   *
   * - `priority`: priority
   *
   * - `file`:
   *
   * @return OK
   */
  createTicketUsingPOSTResponse(params: KayakoControllerService.CreateTicketUsingPOSTParams): __Observable<__StrictHttpResponse<KayakoMessageResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.title != null) __params = __params.set('title', params.title.toString());
    if (params.message != null) __params = __params.set('message', params.message.toString());
    if (params.topic != null) __params = __params.set('topic', params.topic.toString());
    if (params.priority != null) __params = __params.set('priority', params.priority.toString());
    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/kayako/create-ticket`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<KayakoMessageResponse>;
      })
    );
  }
  /**
   * createTicket
   * @param params The `KayakoControllerService.CreateTicketUsingPOSTParams` containing the following parameters:
   *
   * - `title`: title
   *
   * - `message`: message
   *
   * - `topic`: topic
   *
   * - `priority`: priority
   *
   * - `file`:
   *
   * @return OK
   */
  createTicketUsingPOST(params: KayakoControllerService.CreateTicketUsingPOSTParams): __Observable<KayakoMessageResponse> {
    return this.createTicketUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as KayakoMessageResponse)
    );
  }

  /**
   * getAttachment
   * @param params The `KayakoControllerService.GetAttachmentUsingGETParams` containing the following parameters:
   *
   * - `ticketId`: ticketId
   *
   * - `postId`: postId
   *
   * - `attachmentId`: attachmentId
   *
   * @return OK
   */
  getAttachmentUsingGETResponse(params: KayakoControllerService.GetAttachmentUsingGETParams): __Observable<__StrictHttpResponse<ArrayBuffer>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ticketId != null) __params = __params.set('ticketId', params.ticketId.toString());
    if (params.postId != null) __params = __params.set('postId', params.postId.toString());
    if (params.attachmentId != null) __params = __params.set('attachmentId', params.attachmentId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/kayako/get-attachment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'arraybuffer'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ArrayBuffer>;
      })
    );
  }
  /**
   * getAttachment
   * @param params The `KayakoControllerService.GetAttachmentUsingGETParams` containing the following parameters:
   *
   * - `ticketId`: ticketId
   *
   * - `postId`: postId
   *
   * - `attachmentId`: attachmentId
   *
   * @return OK
   */
  getAttachmentUsingGET(params: KayakoControllerService.GetAttachmentUsingGETParams): __Observable<ArrayBuffer> {
    return this.getAttachmentUsingGETResponse(params).pipe(
      __map(_r => _r.body as ArrayBuffer)
    );
  }

  /**
   * getMessagesForTicket
   * @param params The `KayakoControllerService.GetMessagesForTicketUsingGETParams` containing the following parameters:
   *
   * - `ticketId`: ticketId
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * @return OK
   */
  getMessagesForTicketUsingGETResponse(params: KayakoControllerService.GetMessagesForTicketUsingGETParams): __Observable<__StrictHttpResponse<KayakoMessageListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ticketId != null) __params = __params.set('ticketId', params.ticketId.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/kayako/messages`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<KayakoMessageListResponse>;
      })
    );
  }
  /**
   * getMessagesForTicket
   * @param params The `KayakoControllerService.GetMessagesForTicketUsingGETParams` containing the following parameters:
   *
   * - `ticketId`: ticketId
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * @return OK
   */
  getMessagesForTicketUsingGET(params: KayakoControllerService.GetMessagesForTicketUsingGETParams): __Observable<KayakoMessageListResponse> {
    return this.getMessagesForTicketUsingGETResponse(params).pipe(
      __map(_r => _r.body as KayakoMessageListResponse)
    );
  }

  /**
   * listPriorityOptions
   * @return OK
   */
  listPriorityOptionsUsingGETResponse(): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/kayako/priority-options`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * listPriorityOptions
   * @return OK
   */
  listPriorityOptionsUsingGET(): __Observable<Array<string>> {
    return this.listPriorityOptionsUsingGETResponse().pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * sendMessage
   * @param params The `KayakoControllerService.SendMessageUsingPOSTParams` containing the following parameters:
   *
   * - `ticketId`: ticketId
   *
   * - `message`: message
   *
   * - `file`:
   *
   * @return OK
   */
  sendMessageUsingPOSTResponse(params: KayakoControllerService.SendMessageUsingPOSTParams): __Observable<__StrictHttpResponse<KayakoMessageResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.ticketId != null) __params = __params.set('ticketId', params.ticketId.toString());
    if (params.message != null) __params = __params.set('message', params.message.toString());
    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/kayako/send-message`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<KayakoMessageResponse>;
      })
    );
  }
  /**
   * sendMessage
   * @param params The `KayakoControllerService.SendMessageUsingPOSTParams` containing the following parameters:
   *
   * - `ticketId`: ticketId
   *
   * - `message`: message
   *
   * - `file`:
   *
   * @return OK
   */
  sendMessageUsingPOST(params: KayakoControllerService.SendMessageUsingPOSTParams): __Observable<KayakoMessageResponse> {
    return this.sendMessageUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as KayakoMessageResponse)
    );
  }

  /**
   * listTickets
   * @param params The `KayakoControllerService.ListTicketsUsingGETParams` containing the following parameters:
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * @return OK
   */
  listTicketsUsingGETResponse(params: KayakoControllerService.ListTicketsUsingGETParams): __Observable<__StrictHttpResponse<KayakoTicketListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/kayako/tickets`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<KayakoTicketListResponse>;
      })
    );
  }
  /**
   * listTickets
   * @param params The `KayakoControllerService.ListTicketsUsingGETParams` containing the following parameters:
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * @return OK
   */
  listTicketsUsingGET(params: KayakoControllerService.ListTicketsUsingGETParams): __Observable<KayakoTicketListResponse> {
    return this.listTicketsUsingGETResponse(params).pipe(
      __map(_r => _r.body as KayakoTicketListResponse)
    );
  }

  /**
   * listTopicOptions
   * @return OK
   */
  listTopicOptionsUsingGETResponse(): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/kayako/topics-options`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * listTopicOptions
   * @return OK
   */
  listTopicOptionsUsingGET(): __Observable<Array<string>> {
    return this.listTopicOptionsUsingGETResponse().pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * getUnreadPostsCount
   * @return OK
   */
  getUnreadPostsCountUsingGETResponse(): __Observable<__StrictHttpResponse<KayakoTicketResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/kayako/unread-posts-count`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<KayakoTicketResponse>;
      })
    );
  }
  /**
   * getUnreadPostsCount
   * @return OK
   */
  getUnreadPostsCountUsingGET(): __Observable<KayakoTicketResponse> {
    return this.getUnreadPostsCountUsingGETResponse().pipe(
      __map(_r => _r.body as KayakoTicketResponse)
    );
  }
}

module KayakoControllerService {

  /**
   * Parameters for addAttachmentUsingPOST
   */
  export interface AddAttachmentUsingPOSTParams {

    /**
     * ticketId
     */
    ticketId: number;

    /**
     * postId
     */
    postId: number;
    file?: Blob;
  }

  /**
   * Parameters for changeTicketStatusUsingPOST
   */
  export interface ChangeTicketStatusUsingPOSTParams {

    /**
     * ticketId
     */
    ticketId: number;

    /**
     * status
     */
    status?: string;
  }

  /**
   * Parameters for createTicketUsingPOST
   */
  export interface CreateTicketUsingPOSTParams {

    /**
     * title
     */
    title: string;

    /**
     * message
     */
    message: string;

    /**
     * topic
     */
    topic?: string;

    /**
     * priority
     */
    priority?: string;
    file?: Blob;
  }

  /**
   * Parameters for getAttachmentUsingGET
   */
  export interface GetAttachmentUsingGETParams {

    /**
     * ticketId
     */
    ticketId: number;

    /**
     * postId
     */
    postId: number;

    /**
     * attachmentId
     */
    attachmentId: number;
  }

  /**
   * Parameters for getMessagesForTicketUsingGET
   */
  export interface GetMessagesForTicketUsingGETParams {

    /**
     * ticketId
     */
    ticketId: number;

    /**
     * pageSize
     */
    pageSize?: number;

    /**
     * pageNumber
     */
    pageNumber?: number;
  }

  /**
   * Parameters for sendMessageUsingPOST
   */
  export interface SendMessageUsingPOSTParams {

    /**
     * ticketId
     */
    ticketId: number;

    /**
     * message
     */
    message: string;
    file?: Blob;
  }

  /**
   * Parameters for listTicketsUsingGET
   */
  export interface ListTicketsUsingGETParams {

    /**
     * pageSize
     */
    pageSize?: number;

    /**
     * pageNumber
     */
    pageNumber?: number;
  }
}

export { KayakoControllerService }
