import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RoomDialogPageComponent } from 'app/_main/modules/components/room-dialog-page/room-dialog-page.component';
import { RoomDialogMessageItemComponent } from 'app/_main/modules/components/room-dialog-page/ui/room-dialog-message-item.component';
import { ExchangeComponentsModule } from 'app/exchange/modules/exchange-components.module';
import { ButtonModule } from 'core/components/button/button.module';
import { DataExpandModule } from 'core/components/data-expand/data-expand.module';
import { DropdownModule } from 'core/components/dropdown/dropdown.module';
import { FormControlModule } from 'core/components/form-control/form-control.module';
import { IconModule } from 'core/components/icon/icon.module';
import { InputModule } from 'core/components/input/input.module';
import { ListComponentsModule } from 'core/components/list-components/list-components.module';
import { TooltipModule } from 'core/components/tooltip/tooltip.module';
import { UserAvatarModule } from 'core/components/user-avatar/user-avatar.module';
import { AppDirectivesModule } from 'core/directives/app-directives/app-directives.module';
import { CoreDirectivesModule } from 'core/directives/core-directives/core-directives.module';
import { TranslateModule } from 'core/modules/i18n/translate.module';
import { PlatformModule } from 'core/modules/platform/platform.module';
import { AppPipesModule } from 'core/pipes/app-pipes/app-pipes.module';
import { CorePipesModule } from 'core/pipes/core-pipes/core-pipes.module';
import { ProfileComponentsModule } from 'profile/modules/profile-components.module';

import { BuildingsHeapCheckoutItemComponent } from './components/_ui/buildings-heap-checkout-item/buildings-heap-checkout-item.component';
import { CheckoutItemComponent } from './components/_ui/checkout-item/checkout-item.component';
import { CollectionCheckoutItemComponent } from './components/_ui/collection-checkout-item/collection-checkout-item.component';
import { CollectionIconComponent } from './components/_ui/collection-icon/collection-icon.component';
import { LevelIconComponent } from './components/_ui/level-icon/level-icon.component';
import { StreetThumbComponent } from './components/_ui/street-thumb/street-thumb.component';
import { CheckoutPageComponent } from './components/checkout-page/checkout-page.component';
import { FixedCardComponent } from './components/fixed-card/fixed-card.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { HeaderMenuComponent } from './components/header-menu/header-menu.component';
import { LandscapeLockComponent } from './components/landscape-lock/landscape-lock.component';
import { MysteryBoxToastComponent } from './components/mystery-box-toast/mystery-box-toast.component';
import { NftTradeToastComponent } from './components/nft-trade-toast/nft-trade-toast.component';
import { StreetPriceSumPipe } from './components/street-toast/street-price-sum.pipe';
import { StreetToastComponent } from './components/street-toast/street-toast.component';

const exportedDeclarations = [
  HeaderComponent,
  HeaderMenuComponent,
  LandscapeLockComponent,
  CheckoutPageComponent,
  RoomDialogPageComponent,
  CheckoutItemComponent,
  CollectionCheckoutItemComponent,
  BuildingsHeapCheckoutItemComponent,
  LevelIconComponent,
  StreetThumbComponent,
  FixedCardComponent,
  CollectionIconComponent,
  StreetToastComponent,
  NftTradeToastComponent,
  MysteryBoxToastComponent,
  StreetPriceSumPipe,
  FooterComponent,
  RoomDialogMessageItemComponent,
];
@NgModule({
  imports: [
    RouterModule,
    ProfileComponentsModule,
    TranslateModule,
    PlatformModule,
    ButtonModule,
    ReactiveFormsModule,
    FormControlModule,
    IconModule,
    CommonModule,
    DropdownModule,
    AppPipesModule,
    CorePipesModule,
    ListComponentsModule,
    DataExpandModule,
    AppDirectivesModule,
    InputModule,
    UserAvatarModule,
    ExchangeComponentsModule,
    DataExpandModule,
    TooltipModule,
    CoreDirectivesModule,
    OverlayModule,
  ],
  declarations: exportedDeclarations,
  exports: exportedDeclarations,
})
export class MainComponentsModule {}
