import { Component } from '@angular/core';

@Component({
  selector: 'app-form-label',
  template: `
    <ng-content></ng-content>
  `,
  styleUrls: ['form-label.component.scss'],
})
export class FormLabelComponent {}
