/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ItemLikesResponse } from '../models/item-likes-response';
import { ItemLikesListResponse } from '../models/item-likes-list-response';

/**
 * Item Likes Controller
 */
@Injectable({
  providedIn: 'root',
})
class ItemLikesControllerService extends __BaseService {
  static readonly saveUsingPOSTPath = '/api/v1/item_likes';
  static readonly deleteUsingDELETEPath = '/api/v1/item_likes';
  static readonly listUsingGET11Path = '/api/v1/item_likes/likes-list';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Save like
   * @param itemName itemName
   * @return OK
   */
  saveUsingPOSTResponse(itemName: string): __Observable<__StrictHttpResponse<ItemLikesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (itemName != null) __params = __params.set('itemName', itemName.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/item_likes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ItemLikesResponse>;
      })
    );
  }
  /**
   * Save like
   * @param itemName itemName
   * @return OK
   */
  saveUsingPOST(itemName: string): __Observable<ItemLikesResponse> {
    return this.saveUsingPOSTResponse(itemName).pipe(
      __map(_r => _r.body as ItemLikesResponse)
    );
  }

  /**
   * Delete like
   * @param itemName itemName
   * @return OK
   */
  deleteUsingDELETEResponse(itemName: string): __Observable<__StrictHttpResponse<ItemLikesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (itemName != null) __params = __params.set('itemName', itemName.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/item_likes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ItemLikesResponse>;
      })
    );
  }
  /**
   * Delete like
   * @param itemName itemName
   * @return OK
   */
  deleteUsingDELETE(itemName: string): __Observable<ItemLikesResponse> {
    return this.deleteUsingDELETEResponse(itemName).pipe(
      __map(_r => _r.body as ItemLikesResponse)
    );
  }

  /**
   * list
   * @param itemNames itemNames
   * @return OK
   */
  listUsingGET11Response(itemNames: Array<string>): __Observable<__StrictHttpResponse<ItemLikesListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (itemNames || []).forEach(val => {if (val != null) __params = __params.append('itemNames', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/item_likes/likes-list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ItemLikesListResponse>;
      })
    );
  }
  /**
   * list
   * @param itemNames itemNames
   * @return OK
   */
  listUsingGET11(itemNames: Array<string>): __Observable<ItemLikesListResponse> {
    return this.listUsingGET11Response(itemNames).pipe(
      __map(_r => _r.body as ItemLikesListResponse)
    );
  }
}

module ItemLikesControllerService {
}

export { ItemLikesControllerService }
