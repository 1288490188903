import { Component, HostBinding, Input } from '@angular/core';
import { AppIconsLibrary } from 'core/icons/icons-library.service';
import { iconPlan1 } from 'core/icons/lib/icon-plan1';
import { iconPlan2 } from 'core/icons/lib/icon-plan2';
import { iconPlan3 } from 'core/icons/lib/icon-plan3';
import { iconPlan4 } from 'core/icons/lib/icon-plan4';
import { iconPlan5 } from 'core/icons/lib/icon-plan5';
import { STREET_LEVELS, StreetLevelType } from 'dashboard/models/street.data';

@Component({
  selector: 'app-level-icon',
  template: `
    <app-icon [icon]="levels[level]?.icon"></app-icon>
    <ng-container *ngIf="!disableLabel">
      <div
        class="description"
        *ngIf="price && (price >= 0 || price === -1 || price === -2 || price === -3)">
        <p class="value" [class.thin]="price === -3" [class.light]="price === -1">
          {{ levels[level]?.title }}
        </p>
        <p *ngIf="price >= 0" class="price">
          {{ displayDigits ? (price | number: '.2-2') : price }} {{ currency }}
        </p>
      </div>

      <div class="description" *ngIf="points || points === 0">
        <p class="value">{{ levels[level]?.title }}</p>
        <p>
          {{ points }}
          {{
            points > 1
              ? ('main.level-icon.points' | translate)
              : ('main.level-icon.point' | translate)
          }}
        </p>
      </div>
    </ng-container>
  `,
  styleUrls: ['./level-icon.component.scss'],
})
export class LevelIconComponent {
  levels = STREET_LEVELS;

  @Input()
  @HostBinding('class')
  level: StreetLevelType = 'plan1';

  @Input()
  price?: number;

  @Input()
  points?: number;

  @Input()
  currency?: string;

  @Input()
  displayDigits = true;

  @Input()
  disableLabel = false;

  constructor(iconLib: AppIconsLibrary) {
    iconLib.addIcons([iconPlan3, iconPlan2, iconPlan4, iconPlan1, iconPlan5]);
  }
}
