<app-marketplace-streets-header
  class="header display-inline-block"
  [lockPadding]="15"
  scrollLock></app-marketplace-streets-header>
<div
  class="list-wrapper"
  (scrollNearEnd)="nextPageAction($event)"
  [observeWindow]="true"
  appInfiniteScroll
  #containerRef>
  <div class="list" #virtualContainerRef>
    <ng-template
      appVirtualFor
      let-street
      [itemSizes]="['large', 70, 318]"
      [appVirtualFor]="streets"
      [overflow]="400"
      [offsetTop]="400"
      [trackBy]="streets.length"
      [containerRef]="containerRef"
      [virtualContainerRef]="virtualContainerRef">
      <app-marketplace-street-item
        (click)="goToOverview(street)"
        (toggleFavorite)="toggleFavorite($event)"
        [street]="street"
        [ngClass]="{ skeleton: !street.id }"></app-marketplace-street-item>
    </ng-template>
  </div>
  <ng-container *ngIf="!streets.length">
    <app-no-data
      [canRedirect]="false"
      [text]="'marketplace.streets.empty.text' | translate"
      [title]="'marketplace.streets.empty.title' | translate"
      class="darken"></app-no-data>
  </ng-container>
</div>


