import { Platform } from '@angular/cdk/platform';
import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RangeData } from 'core/models/range.data';
import { fromEvent, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

const isMouse = (e: unknown): e is MouseEvent => (e as MouseEvent).clientX !== undefined;

@Component({
  selector: 'app-twin-range',
  template: `
    <span class="handle min" #handleMinRef>
      <span class="inner-circle"></span>
    </span>
    <span class="handle max" #handleMaxRef>
      <span class="inner-circle"></span>
    </span>
    <span class="fill min" #fillMinRef></span>
    <span class="fill max" #fillMaxRef></span>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TwinRangeComponent),
      multi: true,
    },
  ],
  styleUrls: ['./twin-range.component.scss'],
})
export class TwinRangeComponent implements ControlValueAccessor, OnDestroy, OnInit {
  @Input()
  constraints = { min: 0, max: 100 };

  @ViewChild('fillMinRef', { static: true })
  fillMinRef!: ElementRef<HTMLElement>;

  @ViewChild('fillMaxRef', { static: true })
  fillMaxRef!: ElementRef<HTMLElement>;

  @ViewChild('handleMinRef', { static: true })
  handleMinRef!: ElementRef<HTMLElement>;

  @ViewChild('handleMaxRef', { static: true })
  handleMaxRef!: ElementRef<HTMLElement>;

  private handleMoveSub = new Subscription();
  private subs: Subscription[] = [];
  private rangeActive = false;
  private rangeMin = 0; // 0 to 100 range value
  private rangeMax = 100;
  private valueMin = 0; // value of user defined range from this.min to this.max;
  private valueMax = 0;
  private uiMin = 0; // it is 100% size - relative handle size
  private uiMax = 0;
  private thumbSizeRatio = 0; // size of thumb to track size
  private thumbSize = 24; // thumb size in px
  private trackWidth = 0; // track size in px
  protected onControlTouched = (): null => null;
  protected onControlChanged = (_: unknown): null => null;

  constructor(
    private renderer: Renderer2,
    private platform: Platform,
    private elementRef: ElementRef<HTMLElement>
  ) {}

  ngOnDestroy(): void {
    this.handleMoveSub.unsubscribe();
    this.subs.forEach(s => s.unsubscribe());
  }

  ngOnInit(): void {
    this.observeHandle(this.handleMinRef);
    this.observeHandle(this.handleMaxRef);
  }

  private observeHandle(handleRef: ElementRef<HTMLElement>): void {
    let touchOccurred = false;
    const mobile = this.platform.IOS || this.platform.ANDROID;
    // use passive event only on mobile devices to prevent edge swipe navigation
    const handleTouchSub = fromEvent(handleRef.nativeElement, 'touchstart', {
      passive: !mobile,
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    }).subscribe(e => {
      if (this.rangeActive) {
        return;
      }
      if (mobile) {
        e.preventDefault();
      }
      touchOccurred = true;
      this.onHandleTouched(handleRef, 'touchmove');
    });
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    const handleDownSub = fromEvent<MouseEvent>(handleRef.nativeElement, 'mousedown').subscribe(
      e => {
        if (e.button !== 0 || this.rangeActive) {
          return;
        }
        if (touchOccurred) {
          touchOccurred = false;
          return;
        }
        this.onHandleTouched(handleRef, 'mousemove');
      }
    );
    this.subs.push(handleDownSub, handleTouchSub);
  }

  private calcHandleSize(): void {
    this.trackWidth = this.elementRef.nativeElement.clientWidth;
    this.thumbSizeRatio = ((this.thumbSize * 2) / this.trackWidth) * 100;
  }

  private onHandleTouched(
    handleRef: ElementRef<HTMLElement>,
    event: 'touchmove' | 'mousemove'
  ): void {
    const elOffsetLeft = this.getOffsetLeft();
    this.calcHandleSize();
    this.handleMoveSub.unsubscribe();
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.handleMoveSub = fromEvent<TouchEvent | MouseEvent>(window, event).subscribe(e => {
      const pointerX = isMouse(e) ? e.clientX : e.touches[0].clientX;
      if (handleRef === this.handleMinRef) {
        const layerX = pointerX - elOffsetLeft - this.thumbSize / 2;
        const v = Math.max(0, Math.min(100, (layerX / this.trackWidth) * 100));
        this.moveMinHandle(v, { updateValue: true });
      } else {
        const layerX = pointerX - elOffsetLeft + this.thumbSize / 2;
        const v = Math.max(0, Math.min(100, (layerX / this.trackWidth) * 100));
        this.moveMaxHandle(100 - v, { updateValue: true });
      }
      this.emitControlChanges();
      this.renderer.addClass(this.elementRef.nativeElement, 'active');
    });
    fromEvent(window, event === 'touchmove' ? 'touchend' : 'mouseup')
      .pipe(first())
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(() => {
        this.handleMoveSub.unsubscribe();
        this.renderer.removeClass(this.elementRef.nativeElement, 'active');
      });
  }

  private moveMinHandle(v: number, options: { updateValue?: boolean } = {}): void {
    const minUiMin = 100 - this.uiMax - this.thumbSizeRatio;
    this.uiMin = Math.min(Math.max(v, 0), minUiMin);
    this.renderer.setStyle(this.handleMinRef.nativeElement, 'left', `${this.uiMin}%`);
    this.renderer.setStyle(this.fillMinRef.nativeElement, 'width', `${this.uiMin}%`);
    if (!options.updateValue) {
      return;
    }
    if (this.uiMin === minUiMin) {
      this.rangeMin = this.rangeMax;
      this.valueMin = this.valueMax;
    } else {
      const valueOffset = this.lerp(0, this.thumbSizeRatio, v / 100);
      this.rangeMin = Math.min(Math.max(v + valueOffset * 1.25, 0), this.rangeMax);
      this.valueMin = Math.floor(
        this.lerp(this.constraints.min, this.constraints.max, this.rangeMin / 100)
      );
    }
  }

  private emitControlChanges(): void {
    const data: RangeData = {
      min: this.valueMin,
      max: this.valueMax,
    };
    this.onControlTouched();
    this.onControlChanged(data);
  }

  private lerp(v0: number, v1: number, t: number): number {
    return v0 * (1 - t) + v1 * t;
  }

  private moveMaxHandle(v: number, options: { updateValue?: boolean } = {}): void {
    const maxUiMax = 100 - this.uiMin - this.thumbSizeRatio;
    this.uiMax = Math.min(Math.max(maxUiMax, 0), v);
    this.renderer.setStyle(this.handleMaxRef.nativeElement, 'right', `${this.uiMax}%`);
    this.renderer.setStyle(this.fillMaxRef.nativeElement, 'width', `${this.uiMax}%`);
    if (!options.updateValue) {
      return;
    }
    if (this.uiMax === maxUiMax) {
      this.rangeMax = this.rangeMin;
      this.valueMax = this.valueMin;
    } else {
      const valueOffset = this.lerp(0, this.thumbSizeRatio, v / 100);
      this.rangeMax = 100 - Math.min(Math.max(100 - this.rangeMin, 0), v + valueOffset * 1.25);
      this.valueMax = Math.ceil(
        this.lerp(this.constraints.min, this.constraints.max, this.rangeMax / 100)
      );
    }
  }

  private getOffsetLeft(): number {
    let el = this.elementRef.nativeElement;
    let x = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      x += el.offsetLeft - el.scrollLeft;
      el = el.offsetParent as HTMLElement;
    }
    return x;
  }

  writeValue(v: RangeData): void {
    if (v.min > v.max) {
      if (!this.valueMin && !this.valueMax) {
        this.valueMin = this.constraints.min;
        this.valueMax = this.constraints.max;
      }
      return;
    }
    this.valueMin = v.min;
    this.valueMax = v.max;
    // wait for DOM init to calc handle size and set handles positions
    requestAnimationFrame(() => {
      if (v.min === v.max) {
        this.moveMaxHandle(0);
        this.moveMinHandle(0);
        return;
      }
      this.calcHandleSize();
      const minUiValue = this.lerp(
        0,
        100 - this.thumbSizeRatio,
        (v.min - this.constraints.min) / (this.constraints.max - this.constraints.min)
      );
      const maxUiValue = this.lerp(
        this.thumbSizeRatio,
        100,
        (v.max - this.constraints.min) / (this.constraints.max - this.constraints.min)
      );
      this.moveMaxHandle(100 - Math.min(100, maxUiValue));
      this.moveMinHandle(Math.max(minUiValue, 0));
    });
  }

  registerOnChange(fn: (val: unknown) => null): void {
    this.onControlChanged = fn;
  }

  registerOnTouched(fn: () => null): void {
    this.onControlTouched = fn;
  }

  setDisabledState?(_: boolean): void {
    // TODO:
  }
}
