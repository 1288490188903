import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-collection-icon',
  template: '',
  styleUrls: ['./collection-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectionIconComponent {
  @HostBinding('style.background-image') @Input() imageUrl!: string;

  backgroundImage = 'url()';
}
