/* eslint-disable prefer-arrow-functions/prefer-arrow-functions */

import { createNavigation } from 'core/utils/router.utils';

export const exchangeRoot = 'exchange';

// dashboard paths

export function exchangeHeader(marketTitle = ''): string {
  return `${exchangeRoot}/${marketTitle}`;
}

export function exchangeMain(marketTitle = ''): string {
  return marketTitle;
}

export const paths = {
  exchangeHeader: exchangeHeader,
  exchangeMain: exchangeMain
};

// modals

export const modalPaths = {};

export const exchangeNav = createNavigation(paths, exchangeRoot);
// export const dashboardModalsNav = createNavigation(modalPaths, dashboardRoot);
