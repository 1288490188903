import { Injectable } from '@angular/core';
import {
  DepositAddressTOListResponse,
  DepositAddressTOResponse,
  InternalTransferForm,
  UserDefaultWalletDto,
  UserInfoTOListResponse,
} from 'api/models';
import {
  UserDefaultWalletControllerService,
  UserFilterControllerService,
  WalletControllerService,
} from 'api/services';
import { StateClearService, StateFullService } from 'app/_main/services/state-clear.service';
import { do58Encode } from 'cashier/utils/convert-tron-address';
import { CurrencyNetworkType } from 'core/models/currency.model';
import { SocketService } from 'core/modules/socket/socket.service';
import { UserService } from 'profile/services/user.service';
import {Observable, of, ReplaySubject, Subject} from 'rxjs';
import {map, takeUntil, tap} from 'rxjs/operators';

import { Wallet } from '../models/wallet.model';
import { WalletsFactory } from './wallets.factory';

interface BalanceEventData {
  data: {
    id: number;
    accountId: number;
    available: number;
    reserved: number;
    currencyCode: string;
    timestamp: number;
    levelPoints?: number;
  };
  eventType: 'BALANCE_UPDATED';
}

@Injectable({ providedIn: 'root' })
export class WalletsService implements StateFullService {
  wallets: Wallet[] = [];
  defaultWalletId?: number;
  private currencyKey = 'default-currency';
  private bonusPointsUpdatedSubj = new ReplaySubject<number>();
  bonusPointsUpdated = this.bonusPointsUpdatedSubj.asObservable();
  private depositAddresses: DepositAddressTOResponse[] = [];
  private destroy$: Subject<void> = new Subject<void>();
  private _bonusBalanceId = 0;


  private _nftWalletId = 0;
  private _dwrldWalletId = 0;
  constructor(
    private api: WalletControllerService,
    private factory: WalletsFactory,
    private userService: UserService,
    private userFilterApi: UserFilterControllerService,
    private userDefaultWalletController: UserDefaultWalletControllerService,
    private stateClearService: StateClearService,
    socket: SocketService
  ) {
    this.stateClearService.register(this);

    socket.on<BalanceEventData>().subscribe(message => {
      if (message.eventType === 'BALANCE_UPDATED') {
        const wallet = this.factory.get(message.data.id);
        if (message.data.currencyCode === 'DPC') {
          const diff = message.data.available - this.bonusBalance;
          if (diff > 0) {
            this.userService.user.decentBonus += diff;
          }
          this.bonusPointsUpdatedSubj.next(this.userService.user.decentBonus);
        }
        wallet.reserved = message.data.reserved;
        wallet.available = message.data.available;
      }
    });
  }

  get nftWalletId(): number {
    return this._nftWalletId;
  }

  get dwrldWalletId(): number {
    return this._dwrldWalletId;
  }

  get dwrldWallet(): Wallet {
    return this.factory.get(this._dwrldWalletId);
  }

  get bonusBalance(): number {
    return this.factory.get(this._bonusBalanceId).available;
  }

  get bonusPoints(): number {
    return this.userService.user.decentBonus;
  }

  load(): Observable<Wallet[]> {
    return this.api.listUsingGET21().pipe(
      map(
        res =>
          (this.wallets =
            res.list
              ?.filter(item => {
                if (item.currencyCode === 'NFT') {
                  this._nftWalletId = item.id || 0;
                  return false;
                } else if (item.currencyCode === 'DPC') {
                  this._bonusBalanceId = item.id || 0;
                } else {
                  if (item.currencyCode === 'DWRLD') {
                    this._dwrldWalletId = item.id || 0;
                  }
                }
                return true;
              })
              .map(data => this.factory.get(data.id, data)) || [])
      )
    );
  }

  loadDepositAddresses(): Observable<DepositAddressTOListResponse> {
    return this.api.listAccountDepositAddressesUsingGET().pipe(
      tap(data => {
        this.depositAddresses = (data?.list || []).filter(item => item.marketId === 0);
      })
    );
  }

  getDepositAmount():Observable<string>{
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.api.depositAmountVolumeUsingGET();
  }

  // Get or create deposit address
  getDepositAddress(
    walletId: number,
    networkType: CurrencyNetworkType
  ): Observable<DepositAddressTOResponse> {
    const address = this.depositAddresses.find(
      item => item.wallet?.id === walletId && item.network === networkType
    );

    if (address) {
      return of(address).pipe(
        map(res => {
          let address = res.address;
          if (res.network === 'TRON' && res.address) {
            address = do58Encode(`41${res.address.slice(2)}` || '');
          }
          return {
            ...res,
            address,
          };
        })
      );
    }
    return this.api
      .getDepositAddressUsingPOST({
        walletId,
        network: networkType,
      })
      .pipe(
        map(res => {
          if (res.network === 'TRON' && res.address) {
            res.address = do58Encode(`41${res.address.slice(2)}` || '');
          }
          return res;
        }),
        tap(res => {
          this.depositAddresses.push(res);
        })
      );
  }

  filterByZeroAvailable(): Wallet[] {
    return this.wallets.filter(item => !!item.available);
  }

  filterByCode(code: string): Wallet[] {
    return this.wallets.filter(account =>
      account.currency.code.toLowerCase().includes(code.trim().toLowerCase())
    );
  }

  transferDWRLD(params: InternalTransferForm): Observable<boolean> {
    return this.api.internalTransferUsingPOST(params);
  }

  searchUsers(value: string): Observable<UserInfoTOListResponse> {
    const params: UserFilterControllerService.GetUsersUsingGETParams = {
      email: value,
      pageSize: 1,
    };
    return this.userFilterApi.getUsersUsingGET(params);
  }

  isInternalWallet(address: string): Observable<DepositAddressTOResponse> {
    return this.api.checkDepositAddressUsingGET(address);
  }

  clearState(): void {
    this.factory.emptyStore();
    this.wallets = [];
    this.bonusPointsUpdatedSubj.next(0);

    this.depositAddresses = [];
    this._nftWalletId = 0;
    this._bonusBalanceId = 0;
    this._dwrldWalletId = 0;
  }

  getDefaultCurrency(): Observable<UserDefaultWalletDto> {
    return this.userDefaultWalletController.getForCurrentUserOrCreateDefaultUsingGET().pipe(
      tap(result => {
        this.defaultWalletId = result.walletId;
      })
    );
  }

  setDefaultCurrency(wallet: Wallet): Observable<UserDefaultWalletDto> {
    return this.userDefaultWalletController.saveUsingPOST1({
      walletId: wallet.id,
      accountId: wallet.accountId,
    });
  }
}
