import { Inject, Injectable, Optional } from '@angular/core';
import { CurrencyPriceTOList } from 'api/models';
import { CurrencyControllerService, CurrencyConvertorControllerService } from 'api/services';
import { BaseAccountService } from 'core/models/base-account-service.model';
import { UIConfig } from 'core/services/ui-config.service';
import { BASE_ACCOUNT_SERVICE_TOKEN } from 'core/tokens/base-account-service.token';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Currency } from '../models/currency.model';
import { CurrencyFactory } from './currency.factory';

@Injectable({ providedIn: 'root' })
export class CurrencyService {
  currencies: { [key: string]: Currency } = {};

  private baseRates: { [key: string]: number } = {};

  constructor(
    uiConfig: UIConfig,
    private factory: CurrencyFactory,
    @Optional() @Inject(BASE_ACCOUNT_SERVICE_TOKEN) private accountsService: BaseAccountService,
    private currencyApi: CurrencyControllerService,
    private convertorApi: CurrencyConvertorControllerService
  ) {
    uiConfig.precisionProvider = this;
  }

  get rates(): { [key: string]: number } {
    return this.baseRates;
  }

  getPrecision(code = 'USD'): number {
    return this.currencies[code]?.precision || 0;
  }

  getBaseRate(code: string, type?: 'personal'): number {
    if (code === 'DWRLD' && type === 'personal') {
      return this.accountsService?.account?.dwrldPrice || this.baseRates['DWRLD'] || 1;
    }
    return this.baseRates[code.toUpperCase()] || 1;
  }

  toBaseRate(value: number, code: string): number {
    return value * this.getBaseRate(code);
  }

  toDwrldRate(value: number, code: string): number {
    if (code.toLowerCase() === 'dwrld' || code.toLocaleLowerCase() === 'dpc') {
      return value;
    }
    const rate = this.getBaseRate('DWRLD');
    const cRate = this.getBaseRate(code);
    return value * rate * (1 / cRate);
  }

  load(): Observable<{ [key: string]: Currency }> {
    return this.currencyApi
      .listUsingGET7()
      .pipe(
        map(
          r =>
            (this.currencies = r.list?.reduce(
              (obj, item) =>
                Object.assign(obj, { [item.code as string]: this.factory.get(item.code, item) }),
              {}
            ) as { [key: string]: Currency })
        )
      );
  }

  getPriceScale(currencyCode: string): number {
    return +`1${Array(this.currencies[currencyCode].precision).fill(0).join('')}`;
  }

  loadCurrencyRates(currency: string): Observable<CurrencyPriceTOList> {
    return this.convertorApi.pricesUsingPOST({ baseCurrency: currency });
  }

  loadBaseRates(): Observable<CurrencyPriceTOList> {
    return this.convertorApi.pricesUsingPOST({ baseCurrency: 'USD' }).pipe(
      tap(currencies => {
        const arr = currencies.entries || [];
        this.baseRates = arr.reduce(
          (obj, item) => Object.assign(obj, { [(item.code as string).toUpperCase()]: item.price }),
          {}
        ) as { [key: string]: number };
      })
    );
  }
}
