import { Component, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { AppIconsLibrary } from 'core/icons/icons-library.service';
import { iconInfo } from 'core/icons/lib/icon-info';
import { GenericFormControl } from 'core/utils/form-generics';
import { asyncScheduler, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ControlHandler } from './control-handler.base';
import { fieldErrorTransition } from './field-error.transition';

interface FieldError {
  key: string;
  controlName: string;
  value: string | LocalizedString;
}

@Component({
  selector: 'app-field-error',
  template: `
    <div class="container" [@fieldErrorTransition]>
      <app-icon class="err-icon" icon="info"></app-icon>
      <span class="err">{{ error | translate }}</span>
    </div>
  `,
  styleUrls: ['./field-error.component.scss'],
  animations: [fieldErrorTransition],
})
// this component will show FE validation errors that exists on formControl
export class FieldErrorComponent extends ControlHandler implements OnDestroy {
  get error(): string | LocalizedString {
    return this.getError(this.errors[0]);
  }

  private errors: FieldError[] = [];
  private controlName?: string;
  private destroy$ = new Subject();

  constructor(
    iconLib: AppIconsLibrary,
    private renderer: Renderer2,
    private elementRef: ElementRef<HTMLElement>
  ) {
    super();
    iconLib.addIcons([iconInfo]);
  }

  private getError(err: FieldError): string | LocalizedString {
    if (!err) {
      return '';
    }
    if (err.key === 'API') {
      return err.value;
    }
    return !(err.value as { key: string }).key
      ? $t(`shared.validation.${err.key}`) || $t('shared.validation.invalid_field')
      : err.value;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  protected onControlAssigned(): void {
    this.assignControlName();
    this.observeControlErrors();
  }

  private assignControlName(): void {
    if (!this.control) {
      console.error('Form control is required');
      return;
    }
    const formGroup = this.control.parent?.controls;
    if (formGroup && !Array.isArray(formGroup)) {
      // TODO: FromArray is not supported for now
      this.controlName = Object.keys(formGroup).find(name => this.control === formGroup[name]);
    }
    if (!this.controlName) {
      console.warn('Can not determine name of FormControl');
    }
  }

  protected observeControlErrors(): void {
    if (!this.control) {
      return;
    }
    this.control.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.assignErrors();
    });
    if (this.control instanceof GenericFormControl) {
      this.control.stateChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
        asyncScheduler.schedule(() => {
          this.assignErrors();
        }, 100);
      });
    }
    this.assignErrors();
  }

  protected assignErrors(): void {
    if (!this.control) {
      return;
    }
    const errors = this.control.errors;
    if (errors && this.control.enabled) {
      this.errors = Object.keys(errors).map(
        key =>
          ({
            key,
            controlName: this.controlName,
            value: errors[key] as string,
          } as FieldError)
      );
    } else {
      this.errors = [];
    }
    this.updateClass();
  }

  protected updateClass(): void {
    if (!this.control) {
      return;
    }
    if (this.control.dirty) {
      this.renderer.addClass(this.elementRef.nativeElement, 'ng-dirty');
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, 'ng-dirty');
    }
    if (this.control.invalid) {
      this.renderer.addClass(this.elementRef.nativeElement, 'ng-invalid');
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, 'ng-invalid');
    }
    if (this.control.touched) {
      this.renderer.addClass(this.elementRef.nativeElement, 'ng-touched');
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, 'ng-touched');
    }
  }
}
