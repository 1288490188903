import { Injectable } from '@angular/core';
import { InitialBuyForm } from 'api/models';
import {
  GisControllerService,
  InitialBuyControllerService,
  MarketControllerService,
  OrderControllerService,
} from 'api/services';
import { StateFullService } from 'app/_main/services/state-clear.service';
import { APIError } from 'core/models/error.model';
import { Building } from 'dashboard/models/building.model';
import { UserService } from 'profile/services/user.service';
import { Observable, of } from 'rxjs';
import { finalize, map, mergeMap, tap } from 'rxjs/operators';

import { BuildingsFactory } from './buildings.factory';
import { BuildingAssetsResponse } from './buildings-assets.service';
import { CartService } from './cart.service';
import { StreetHistory } from './streets.service';

@Injectable({
  providedIn: 'root',
})
export class BuildingsService implements StateFullService {
  constructor(
    private gisService: GisControllerService,
    private buildingsFactory: BuildingsFactory,
    private initialBuyApi: InitialBuyControllerService,
    private marketApi: MarketControllerService,
    private cartService: CartService,
    private userService: UserService,
    private ordersApi: OrderControllerService
  ) {}

  clearState(): void {}

  findBuildingOrMarket(id?: number): Observable<Building | undefined> {
    if (!id) {
      return of(undefined);
    }
    return this.findBuilding(id).pipe(
      mergeMap(b => (b.marketId ? this.findByNFTMarket({ buildingId: id }) : of(b)))
    );
  }

  findByNFTMarket(params: { buildingId?: number; nftId?: number }) {
    return new Observable<undefined | Building>(s => {
      const sub = this.marketApi
        .findMarketUsingGET({ buildingId: params.buildingId, nftId: params.nftId })
        .pipe(finalize(() => s.complete()))
        .subscribe(
          res => {
            const market = this.buildingsFactory.getMarket(res.nft?.properties?.id, res);
            s.next(market.street as Building);
          },
          (err: APIError) => {
            err.preventHandling();
            s.next(undefined);
          }
        );

      return () => {
        sub.unsubscribe();
      };
    });
  }

  findBuilding(id?: number): Observable<Building> {
    return this.gisService
      .getBuildingUsingGET({ id })
      .pipe(map(data => this.buildingsFactory.get(data.id, data)));
  }

  getBidsHistory(marketId?: number): Observable<StreetHistory[]> {
    if (!marketId) {
      return of([]);
    }
    return this.ordersApi
      .listUsingGET15({ markets: [marketId], side: 'BUY', orderType: 'LIMIT', isNft: true })
      .pipe(
        map(res =>
          (res.list || []).map(o => ({
            price: o.price || 0,
            date: new Date(o.createdAt || 0),
            user: o.userName || '',
          }))
        )
      );
  }

  buy(building: Building, currencyCode: string) {
    const form: InitialBuyForm = {
      type: 'BUILDING',
      currencyCode,
      ids: [building.id],
    };
    return this.initialBuyApi.buyingMarketUsingPOST(form).pipe(
      tap(res => {
        building.nft.ownerId = this.userService.user.id;
        building.nft.ownerName = this.userService.user.username;
        building.marketId = res.id || 0;
        this.cartService.remove(building).subscribe();
        // this.marketGtmService.pushPurchaseTag(street);
      })
    );
  }

  getBuildingsList(
    streetId: number,
    pageNumber = 0,
    isWithdrawn = false
  ): Observable<BuildingAssetsResponse> {
    return this.gisService
      .listBuildingsUsingGET({ streetIds: [streetId], pageNumber, isWithdrawn })
      .pipe(
        map(result => ({
          pageInfo: result.pageInfo || {},
          elements:
            result.elements?.map(element => this.buildingsFactory.get(element.id, element)) || [],
        }))
      );
  }
}
