import { createNavigation } from 'core/utils/router.utils';

export const streetsCatalogRoot = 'digital-real-estate-catalog';

export type StreetsCatalogStreetTypes = 'basic' | 'elite' | 'premium' | 'standard';

export const streetsCatalogStreetTypes: StreetsCatalogStreetTypes[] = [
  'basic',
  'elite',
  'premium',
  'standard',
];
export const STREETS_CATALOG_STREET_TYPES_LABELS = {
  basic: 'Basic',
  elite: 'Elite',
  premium: 'Premium',
  standard: 'Standard',
};

export const streetsCatalog = () => '';

export const streetsCatalogDetail = (
  streetType?: StreetsCatalogStreetTypes,
  countryName?: string,
  cityName?: string,
  countryCode?: string,
  cityId?: number
) => {
  if (countryName && countryCode) {
    countryName = `${countryName.replace(/\s/g, '_').toLowerCase()}-${countryCode}`;
  }
  if (cityName && cityId) {
    cityName = `${cityName.replace(/\s/g, '_').toLowerCase()}-${cityId}`;
  }

  const paramsQueue = [countryName, cityName, streetType].filter(i => Boolean(i));

  return `${paramsQueue.join('-')}`;
};

export const paths = {
  streetsCatalog,
  streetsCatalogDetail,
};

export const streetsCatalogNav = createNavigation(paths, streetsCatalogRoot);
