import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslationsControllerService } from 'api/services';
import { environment } from 'environments/environment';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AppLocale } from './app-locale.service';

export interface LangUIItem {
  code: string;
  iso2: string;
  name: string;
}

interface TranslationJson {
  [key: string]: string;
}

export class LocalizedString {
  constructor(public key: string, public args: TranslateParams) {}
}

// also $t is i18n ally plugin marker function
window.$t = (s: string, args?: TranslateParams) => new LocalizedString(s, args || []);

@Injectable({ providedIn: 'root' })
export class I18nService {
  localizations = new Map<string, string>();
  enLocalizations = new Map<string, string>();
  language: LangUIItem;
  // map - lang code to country code, can be useful in feature
  readonly defaultRegionLangs: { [key: string]: string } = { ua: 'uk' };
  readonly languages: LangUIItem[] = [
    { code: 'EN', iso2: 'US', name: 'English' },
    { code: 'ES', iso2: 'ES', name: 'Español' },
    { code: 'PT', iso2: 'PT', name: 'Português' },
  ];

  private changedSubj = new Subject();
  changed = this.changedSubj.asObservable();

  private readonly defaultLang: string;
  private readonly lastLangLsKey = '_ll';
  private readonly isDebug?: boolean;

  constructor(
    private http: HttpClient,
    private api: TranslationsControllerService,
    private locale: AppLocale
  ) {
    const cachedCode = localStorage.getItem(this.lastLangLsKey);
    this.language = this.languages[0];

    if (typeof navigator !== 'undefined') {
      const browserLanguageCode = navigator.language.split('-')[0];
      this.defaultLang = this.isLangAvailable(browserLanguageCode)
        ? browserLanguageCode
        : this.language.code.toLowerCase();
    } else {
      this.defaultLang = this.language.code.toLowerCase();
    }
    if (cachedCode) {
      const lang = this.languages.filter(l => l.code === cachedCode.toUpperCase())[0];
      if (lang) {
        this.language = lang;
      }
    }
    this.isDebug = localStorage.getItem('_i18dg') === 'true';
  }

  load(code?: string): Observable<unknown> {
    code = (code || localStorage.getItem(this.lastLangLsKey))?.toLowerCase() || '';
    if (!this.isLangAvailable(code)) {
      code = this.defaultLang;
    }
    this.language = this.languages.filter(l => l.code === code?.toUpperCase())[0];
    localStorage.setItem(this.lastLangLsKey, code);
    let req: Observable<[TranslationJson, TranslationJson] | TranslationJson> =
      code !== 'en'
        ? forkJoin([this.api.getTranslationUsingGET(code), this.api.getTranslationUsingGET('en')])
        : this.api.getTranslationUsingGET(code);
    if (this.isDebug) {
      req = of({});
    } else if (environment.localI18n) {
      req =
        code !== 'en'
          ? forkJoin([
              this.http.get<{ [key: string]: string }>(`assets/i18n/${code}.json?t=${Date.now()}`),
              this.http.get<{ [key: string]: string }>(`assets/i18n/en.json?t=${Date.now()}`),
            ])
          : this.http.get<{ [key: string]: string }>(`assets/i18n/${code}.json?t=${Date.now()}`);
    }
    return req.pipe(
      tap(json => {
        this.localizations = new Map<string, string>();

        if (Array.isArray(json)) {
          this.enLocalizations = new Map<string, string>();
          this.createLocalizationsMap(json[0], '', this.localizations);
          this.createLocalizationsMap(json[1], '', this.enLocalizations);
        } else {
          this.createLocalizationsMap(json, '', this.localizations);
        }
      })
    );
  }

  change(code: string): void {
    if (localStorage.getItem(this.lastLangLsKey) === code.toLocaleLowerCase()) {
      return;
    }
    if (this.isLangAvailable(code)) {
      localStorage.setItem(this.lastLangLsKey, code);
      window.location.href = this.locale.getLocaleUrl(this.language.code, code);
    }
  }

  get(value: string | LocalizedString | undefined, args?: TranslateParams): string {
    let key = '';
    if (!value) {
      return '';
    }
    if (typeof value === 'string') {
      key = value;
    } else {
      key = value.key;
      args = value.args || args;
    }
    const localization = this.localizations.get(key) || this.getEnLocalization(key);
    if (!localization) {
      return this.handleEmptyLocalization(value, key);
    }
    if (Array.isArray(args)) {
      return this.parameterizeFromArray(
        localization,
        args.map(a => a.toString())
      );
    } else {
      return this.parametrizeFromObject(localization, args || {});
    }
  }

  keyExists(value: LocalizedString | string | undefined): boolean {
    let key: string | null = null;
    if (typeof value === 'object') {
      key = value.key;
    }
    const translation = this.get(value);
    return translation !== (key || value);
  }

  getEnLocalization(key: string): string | undefined {
    return this.enLocalizations.get(key);
  }

  isEnglishLang(): boolean {
    return localStorage.getItem(this.lastLangLsKey) === 'en';
  }

  private isLangAvailable(code: string): boolean {
    return this.languages.findIndex(l => l.code.toLowerCase() === code.toLowerCase()) >= 0;
  }

  private handleEmptyLocalization(value: string | LocalizedString, key: string): string {
    if (this.isDebug) {
      return key;
    }
    return typeof value === 'string' ? value : value.key;
  }

  private parametrizeFromObject(
    str: string,
    args: { [key: string]: string | number | undefined }
  ): string {
    Object.keys(args).forEach(key => {
      const value = args[key];
      const regExp = new RegExp(`{${key}}`, 'ig');
      str = str.replace(regExp, value?.toString() || '');
    });
    return str;
  }

  private parameterizeFromArray(str: string, args: string[]): string {
    return str.replace(/%s[0-9]+/g, (matchedStr: string) => {
      const variableIndex = (matchedStr.replace('%s', '') as unknown as number) - 1;
      return args[variableIndex];
    });
  }

  private createLocalizationsMap(
    obj: { [key: string]: string },
    currentKey: string,
    map: Map<string, string>
  ): void {
    for (const key in obj) {
      let value = obj[key];
      const newKey = currentKey ? currentKey + '.' + key : key; // joined key with dot
      if (value && typeof value === 'object') {
        this.createLocalizationsMap(value, newKey, map); // it's a nested object, so do it again
      } else {
        value = value.replace(/\\n/g, '\n');
        map.set(newKey, value); // it's not an object, so set the property
      }
    }
  }
}
