/* eslint-disable rxjs-angular/prefer-takeuntil */
import { DOCUMENT } from '@angular/common';
import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { NavigationStart, ɵEmptyOutletComponent, Route, RouterOutlet } from '@angular/router';
import { iconArrowLeft } from 'core/icons/lib/icon-arrow-left';
import { iconCloseRounded } from 'core/icons/lib/icon-close-rounded';
import { asyncScheduler, fromEvent, Subscription } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';

import { ModalRouteData, ModalView, StateRouteData } from '../data/modal.data';
import { ModalRouter } from '../services/modal.router';
import { modalTransitions } from './modal.animations';
import { OutletWrapperComponent } from './outlet-wrapper.component';

type ActivatedRoutePrivate = Record<string, { routeConfig: Route }>;

@Component({
  selector: 'app-modal',
  template: `
    <div class="overlay"></div>
    <div class="container" #container>
      <ng-content></ng-content>
    </div>
  `,
  animations: [modalTransitions],
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnDestroy {
  get outlet(): RouterOutlet {
    return this.hostOutlet;
  }
  @ContentChild(RouterOutlet)
  set outlet(o: RouterOutlet) {
    this.hostOutlet = o;
    this.patchHostOutlet();
    this.observeHostOutlet();
  }

  @Output()
  activated = new EventEmitter<ModalView<unknown>>();
  @Output()
  deactivated = new EventEmitter<ModalView<unknown>>();
  @Output()
  animationEnd = new EventEmitter<ModalView<unknown>>();

  @ViewChild('container', { static: true })
  containerRef!: ElementRef<HTMLElement>;

  @HostBinding('@routerTransition')
  get outletAnimationData(): string | undefined {
    return this.animationState;
  }

  get isActivated(): boolean {
    return (
      (this.outlet !== undefined && this.outlet.isActivated) ||
      (this.outletWrapper !== undefined && this.outletWrapper.isActivated)
    );
  }

  get component(): ModalView<unknown> | undefined {
    return this.currentOutlet && this.currentOutlet.isActivated
      ? this.currentOutlet.component
      : undefined;
  }

  private hostOutlet!: RouterOutlet;

  private isAnimating = false;
  private animationState = '';
  private isMousedownOutside = false;
  private hostClass = '';
  private keyboardSubs: Subscription;
  private resizeSub = new Subscription();
  private routeData?: ModalRouteData;
  private stateData?: StateRouteData;
  private backNavigation?: boolean;
  // see OutletWrapperComponent description
  private outletWrapper?: OutletWrapperComponent;
  // it can be lazy(outletWrapper) or hostOutlet
  private currentOutlet?: RouterOutlet;

  // TODO: pass through some config
  private openAnimation = 'fadeIn';
  private navigationAnimation = 'slideLeft';
  private backNavigationAnimation = 'slideRight';

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef<HTMLElement>,
    private modalRouter: ModalRouter,
    @Inject(DOCUMENT) private document: Document
  ) {
    modalRouter.init(this);
    this.activated.subscribe(() => this.onActivated());
    this.deactivated.subscribe(() => this.onDeactivated());
    this.keyboardSubs = fromEvent<KeyboardEvent>(this.document.body, 'keyup').subscribe(e =>
      this.onKeyup(e)
    );
    this.modalRouter.router.events.pipe(filter(e => e instanceof NavigationStart)).subscribe(() => {
      this.animationState = Date.now().toString();
      this.stateData = this.modalRouter.router.getCurrentNavigation()?.extras.state;
    });
    this.animationEnd.subscribe(() => {
      this.containerRef.nativeElement.scrollTop = 0;
      if (this.outletWrapper) {
        this.stateData = undefined;
        this.backNavigation = false;
        this.renderer.removeClass(this.outletWrapper.currentElement, this.navigationAnimation);
        this.renderer.removeClass(this.outletWrapper.currentElement, this.backNavigationAnimation);
        this.renderer.removeClass(this.outletWrapper.currentElement, this.openAnimation);
      }
    });
  }

  ngOnDestroy(): void {
    this.keyboardSubs.unsubscribe();
    this.resizeSub.unsubscribe();
  }

  private onActivated(): void {
    if (this.modalRouter.isActivating || this.isActivated) {
      this.renderer.addClass(this.document.body, 'modal-active');
    }
  }

  private onDeactivated(): void {
    requestAnimationFrame(() => {
      if (!this.isActivated && !this.isAnimating) {
        this.renderer.removeClass(this.document.body, 'modal-active');
      } else if (!this.isActivated && this.isAnimating) {
        this.renderer.addClass(this.document.body, 'modal-deactivating');
        this.animationEnd.pipe(first()).subscribe(() => {
          this.renderer.removeClass(this.document.body, 'modal-active');
          this.renderer.removeClass(this.document.body, 'modal-deactivating');
        });
      }
    });
  }

  private patchHostOutlet(): void {
    const originalMethod = this.hostOutlet.activateWith;
    this.setCurrentOutlet(this.hostOutlet);
    // TODO: need some automated test to check modal opening
    // because it can break on some major updates of Angular
    this.hostOutlet.activateWith = (activatedRoute, resolver) => {
      if (activatedRoute.component === ɵEmptyOutletComponent) {
        (activatedRoute as unknown as ActivatedRoutePrivate)._futureSnapshot.routeConfig.component =
          OutletWrapperComponent;
      }
      originalMethod.apply(this.hostOutlet, [activatedRoute, resolver]);
      this.setCurrentOutlet(this.hostOutlet);
    };
  }

  private setCurrentOutlet(outlet: RouterOutlet): void {
    this.currentOutlet = outlet;
    const navState = (this.modalRouter.router.getCurrentNavigation()?.extras.state ||
      {}) as ModalRouteData;
    this.routeData = Object.assign({}, outlet.activatedRouteData.modal as ModalRouteData, navState);
    this.hostClass = this.routeData?.hostClass || 'normal';
  }

  private observeHostOutlet(): void {
    const subs: Subscription[] = [];
    let activated = false;
    this.hostOutlet.activateEvents
      .pipe(
        tap(e => {
          if (e instanceof OutletWrapperComponent) {
            const containerEl = this.containerRef.nativeElement;
            this.outletWrapper = e;
            asyncScheduler.schedule(() => {
              activated = true;
            });
            // wait for lazy component loading
            const activateSub = e.onActivate.subscribe(event => {
              if (!activated) {
                this.renderer.addClass(event.element, this.openAnimation);
              } else {
                if (this.stateData?.backNavigation || this.backNavigation) {
                  this.renderer.addClass(event.element, this.backNavigationAnimation);
                } else {
                  this.renderer.addClass(event.element, this.navigationAnimation);
                }
              }
              this.setCurrentOutlet(e.outlet);
              this.renderer.addClass(event.element, 'modal-element');
              this.renderer.addClass(event.element, 'enter');
              this.renderer.addClass(event.element, this.hostClass);
              this.renderer.appendChild(containerEl, event.element);
              if (this.routeData?.type) {
                this.renderer.addClass(event.element, `modal-${this.routeData.type}`);
              }
              this.createCloseButton(event.element);
              if (event.component.onModalBack) {
                this.createBackButton(
                  event.element,
                  event.component.onModalBack.bind(event.component)
                );
              }
              this.activated.next(event.component);
            });
            const deactivateSub = e.onDeactivate.subscribe(event => {
              this.renderer.removeClass(event.element, 'enter');
              if (this.modalRouter.isActivating) {
                if (this.stateData?.backNavigation || this.backNavigation) {
                  this.renderer.addClass(event.element, this.backNavigationAnimation);
                } else {
                  this.renderer.addClass(event.element, this.navigationAnimation);
                }
              } else {
                this.renderer.addClass(event.element, this.openAnimation);
              }
              this.renderer.removeChild(containerEl, event.element);
              this.deactivated.next(event.component);
            });
            subs.push(activateSub, deactivateSub);
          } else {
            this.activated.next(e);
          }
        })
      )
      .subscribe();
    this.hostOutlet.deactivateEvents
      .pipe(
        tap(e => {
          asyncScheduler.schedule(() => {
            this.routeData = undefined;
            activated = false;
          });
          if (e instanceof OutletWrapperComponent) {
            this.outletWrapper = undefined;
            subs.forEach(s => s.unsubscribe());
          } else {
            this.deactivated.next(e);
          }
        })
      )
      .subscribe();
  }

  private createCloseButton(element: HTMLElement): void {
    const closeElement = this.renderer.createElement('span') as HTMLElement;
    closeElement.innerHTML = iconCloseRounded.svgData.replace('<svg', '<svg class="svg-icon" ');
    this.renderer.addClass(closeElement, 'svg-close');
    this.renderer.appendChild(element, closeElement);
    fromEvent(closeElement, 'click').subscribe(() => {
      this.modalRouter.close();
    });
  }

  private createBackButton(element: HTMLElement, cb: () => void): void {
    const closeElement = this.renderer.createElement('span') as HTMLElement;
    closeElement.innerHTML = iconArrowLeft.svgData.replace('<svg', '<svg class="svg-icon" ');
    this.renderer.addClass(closeElement, 'svg-back');
    this.renderer.appendChild(element, closeElement);
    fromEvent(closeElement, 'click').subscribe(() => {
      this.backNavigation = true;
      cb();
    });
  }

  private onKeyup(e: KeyboardEvent): void {
    if (this.currentOutlet && this.currentOutlet.activatedRouteData.obsessive) {
      return;
    }
    if (this.isActivated && (e.keyCode ? e.keyCode : e.which) === 27) {
      void this.modalRouter.close();
    }
  }

  @HostListener('@routerTransition.start', ['$event'])
  onRouterTransitionStart(): void {
    this.isAnimating = true;
  }

  @HostListener('@routerTransition.done', ['$event'])
  onRouterTransitionDone(): void {
    this.isAnimating = false;
    this.animationEnd.next();
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(e: MouseEvent): void {
    if (this.currentOutlet && this.currentOutlet.activatedRouteData.obsessive) {
      return;
    }
    const parent = this.renderer.parentNode(e.target) as HTMLElement;
    const emptySpaceClick =
      parent === this.containerRef.nativeElement || parent === this.elementRef.nativeElement;
    if (emptySpaceClick && e.button === 0) {
      this.isMousedownOutside = true;
    }
  }

  @HostListener('mouseup')
  onClick(): void {
    if (this.isMousedownOutside) {
      void this.modalRouter.close();
    }
    this.isMousedownOutside = false;
  }
}
