import { Injectable } from '@angular/core';
import { WalletTOResponse } from 'api/models';
import { CurrencyFactory } from 'core/services/currency.factory';

import { dataToAccount, Wallet } from '../models/wallet.model';

@Injectable({ providedIn: 'root' })
export class WalletsFactory {
  private store: Map<number, Wallet> = new Map();

  constructor(private currencyFactory: CurrencyFactory) {}

  get(id = 0, data?: Partial<WalletTOResponse>): Wallet {
    let account = this.store.get(id);
    if (account) {
      return data ? Object.assign(account, dataToAccount(data, this.currencyFactory)) : account;
    } else {
      account = new Wallet(dataToAccount(data, this.currencyFactory));
      this.store.set(id, account);
      return account;
    }
  }

  emptyStore(): void {
    this.store.clear();
  }
}
