/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DwrldOrderPageTOResponse } from '../models/dwrld-order-page-toresponse';

/**
 * Dwrld Controller
 */
@Injectable({
  providedIn: 'root',
})
class DwrldControllerService extends __BaseService {
  static readonly ordersUsingGETPath = '/api/v1/dwrld/list';
  static readonly ordersForUuidUsingGETPath = '/api/v1/dwrld/public-list';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * orders
   * @param params The `DwrldControllerService.OrdersUsingGETParams` containing the following parameters:
   *
   * - `zones`: zones
   *
   * - `types`: types
   *
   * - `sort`: sort
   *
   * - `side`: side
   *
   * - `searchText`: searchText
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `minPrice`: minPrice
   *
   * - `maxPrice`: maxPrice
   *
   * @return OK
   */
  ordersUsingGETResponse(params: DwrldControllerService.OrdersUsingGETParams): __Observable<__StrictHttpResponse<DwrldOrderPageTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.zones || []).forEach(val => {if (val != null) __params = __params.append('zones', val.toString())});
    (params.types || []).forEach(val => {if (val != null) __params = __params.append('types', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.side != null) __params = __params.set('side', params.side.toString());
    if (params.searchText != null) __params = __params.set('searchText', params.searchText.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.minPrice != null) __params = __params.set('minPrice', params.minPrice.toString());
    if (params.maxPrice != null) __params = __params.set('maxPrice', params.maxPrice.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/dwrld/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DwrldOrderPageTOResponse>;
      })
    );
  }
  /**
   * orders
   * @param params The `DwrldControllerService.OrdersUsingGETParams` containing the following parameters:
   *
   * - `zones`: zones
   *
   * - `types`: types
   *
   * - `sort`: sort
   *
   * - `side`: side
   *
   * - `searchText`: searchText
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `minPrice`: minPrice
   *
   * - `maxPrice`: maxPrice
   *
   * @return OK
   */
  ordersUsingGET(params: DwrldControllerService.OrdersUsingGETParams): __Observable<DwrldOrderPageTOResponse> {
    return this.ordersUsingGETResponse(params).pipe(
      __map(_r => _r.body as DwrldOrderPageTOResponse)
    );
  }

  /**
   * ordersForUuid
   * @param params The `DwrldControllerService.OrdersForUuidUsingGETParams` containing the following parameters:
   *
   * - `uuid`: uuid
   *
   * - `zones`: zones
   *
   * - `types`: types
   *
   * - `sort`: sort
   *
   * - `side`: side
   *
   * - `searchText`: searchText
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `minPrice`: minPrice
   *
   * - `maxPrice`: maxPrice
   *
   * @return OK
   */
  ordersForUuidUsingGETResponse(params: DwrldControllerService.OrdersForUuidUsingGETParams): __Observable<__StrictHttpResponse<DwrldOrderPageTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.uuid != null) __params = __params.set('uuid', params.uuid.toString());
    (params.zones || []).forEach(val => {if (val != null) __params = __params.append('zones', val.toString())});
    (params.types || []).forEach(val => {if (val != null) __params = __params.append('types', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.side != null) __params = __params.set('side', params.side.toString());
    if (params.searchText != null) __params = __params.set('searchText', params.searchText.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.minPrice != null) __params = __params.set('minPrice', params.minPrice.toString());
    if (params.maxPrice != null) __params = __params.set('maxPrice', params.maxPrice.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/dwrld/public-list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DwrldOrderPageTOResponse>;
      })
    );
  }
  /**
   * ordersForUuid
   * @param params The `DwrldControllerService.OrdersForUuidUsingGETParams` containing the following parameters:
   *
   * - `uuid`: uuid
   *
   * - `zones`: zones
   *
   * - `types`: types
   *
   * - `sort`: sort
   *
   * - `side`: side
   *
   * - `searchText`: searchText
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `minPrice`: minPrice
   *
   * - `maxPrice`: maxPrice
   *
   * @return OK
   */
  ordersForUuidUsingGET(params: DwrldControllerService.OrdersForUuidUsingGETParams): __Observable<DwrldOrderPageTOResponse> {
    return this.ordersForUuidUsingGETResponse(params).pipe(
      __map(_r => _r.body as DwrldOrderPageTOResponse)
    );
  }
}

module DwrldControllerService {

  /**
   * Parameters for ordersUsingGET
   */
  export interface OrdersUsingGETParams {

    /**
     * zones
     */
    zones?: Array<number>;

    /**
     * types
     */
    types?: Array<'BUILDING' | 'COLLECTION' | 'STREET' | 'UNKNOWN_DISCRIMINATOR' | 'UNRECOGNIZED'>;

    /**
     * sort
     */
    sort?: string;

    /**
     * side
     */
    side?: 'BUY' | 'BUY_RECEIVED' | 'SELL' | 'UNKNOWN_SIDE' | 'UNRECOGNIZED';

    /**
     * searchText
     */
    searchText?: string;

    /**
     * pageSize
     */
    pageSize?: number;

    /**
     * pageNumber
     */
    pageNumber?: number;

    /**
     * minPrice
     */
    minPrice?: number;

    /**
     * maxPrice
     */
    maxPrice?: number;
  }

  /**
   * Parameters for ordersForUuidUsingGET
   */
  export interface OrdersForUuidUsingGETParams {

    /**
     * uuid
     */
    uuid: string;

    /**
     * zones
     */
    zones?: Array<number>;

    /**
     * types
     */
    types?: Array<'BUILDING' | 'COLLECTION' | 'STREET' | 'UNKNOWN_DISCRIMINATOR' | 'UNRECOGNIZED'>;

    /**
     * sort
     */
    sort?: string;

    /**
     * side
     */
    side?: 'BUY' | 'BUY_RECEIVED' | 'SELL' | 'UNKNOWN_SIDE' | 'UNRECOGNIZED';

    /**
     * searchText
     */
    searchText?: string;

    /**
     * pageSize
     */
    pageSize?: number;

    /**
     * pageNumber
     */
    pageNumber?: number;

    /**
     * minPrice
     */
    minPrice?: number;

    /**
     * maxPrice
     */
    maxPrice?: number;
  }
}

export { DwrldControllerService }
