import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from 'core/modules/i18n/translate.module';

import { TooltipComponent } from './tooltip.component';
import { TooltipDirective } from './tooltip.directive';
import { TooltipFrequencyComponent } from './tooltip-frequency/tooltip-frequency.component';

const exportedDeclarations = [TooltipDirective, TooltipComponent, TooltipFrequencyComponent];

@NgModule({
  imports: [OverlayModule, CommonModule, TranslateModule],
  declarations: exportedDeclarations,
  exports: exportedDeclarations,
})
export class TooltipModule {}
