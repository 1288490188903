import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PlatformModule } from 'core/modules/platform/platform.module';

import { IconModule } from '../icon/icon.module';
import { AnchorDirective } from './anchor.directive';
import { MenuComponent } from './menu.component';
import { MenuItemDirective } from './menu-item.directive';
import { OverlayHostComponent } from './overlay-host.component';
import { SelectComponent } from './select.component';
import { SelectItemDirective } from './select-item.directive';

const exportedDeclarations = [
  AnchorDirective,
  MenuItemDirective,
  MenuComponent,
  OverlayHostComponent,
  SelectComponent,
  SelectItemDirective,
];

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    OverlayModule,
    PlatformModule,
  ],
  declarations: exportedDeclarations,
  exports: exportedDeclarations,
})
export class DropdownModule {}
