<div class="column watchList-all" style="grid-area: menu">
  <button
    (click)="$event.stopPropagation(); emitIsChecked()"
    [formControl]="isCheckedControl"
    appCheckbox
    [mediaClass]="['small only', '', 'margin-right']"></button>
</div>

<app-street-thumb
  [imageUrl]="street.id ? street.imageUrl : building.imageUrl"
  [isAvailable]="street.id ? street.isAvailable : building.isAvailable"
  [isWithdrawn]="!!(street.isWithdrawn ? street.isWithdrawn : building.isWithdrawn)"
  [level]="street.id ? street.level : building.level"
  [mediaClass]="['large', 'small-thumb xsmall']"
  [title]="street.id ? street.name : building.mainName"
  class="rounded column watchList-asset"
  style="grid-area: asset"></app-street-thumb>

<div *mediaIf="'large'" class="column watchList-city">
  {{ street.id ? street.city : building.city }}
</div>
<app-level-icon
  [level]="street.id ? street.level : building.level"
  [price]="-1"
  class="column watchList-level sm"
  style="grid-area: level"></app-level-icon>

<div class="column watchList-price" style="grid-area: your-price">
  {{ (street.id ? (street.sellPrice || street.price) : building.price) | money: 'DWRLD':'—' }}
</div>

<div class="column watchList-action" style="grid-area: action">
  <button
    (click)="openStreetBuy(); $event.stopPropagation()"
    *ngIf="street.id && !street.isOwner && !street.isWithdrawn"
    [ngClass]="[street.marketId ? 'primary' : 'warn', 'solid', 'medium', 'width-100']"
    appButton>
    {{
      street.marketId
        ? ('dashboard.ui.wishlist_item.button.offer' | translate)
        : ('dashboard.ui.wishlist_item.button.buy' | translate)
    }}
  </button>

  <button
    (click)="openStreetBuy(); $event.stopPropagation()"
    *ngIf="building.id && !building.nft.ownerId && !building.isWithdrawn"
    [ngClass]="[building.nft.ownerId ? 'primary' : 'warn', 'solid', 'medium', 'width-100']"
    appButton>
    {{ 'dashboard.ui.wishlist_item.button.buy' | translate }}
  </button>
</div>

<div *mediaIf="'large'" class="display-flex align-center column watchList-buttons">
  <button
    (click)="deleteFromFavorites(); $event.stopPropagation()"
    [appRoundButton]="deleteIcon"
    appAnchor
    class="clear-error"></button>
</div>
