<div class="data-container container-column dark">

  <div class="width-100 content-container position-relative">
    <h3 class="title margin-bottom-hg weight-semibold color-white">{{ 'profile.notification.title' | translate }}</h3>

    <div class="tabs">
      <button
        (click)="changeTabAction('ALL')"
        [ngClass]="{ active: activeView === 'ALL' }"
        [ripple]="false"
        appButton
        class="tab">
        {{ 'profile.notification.tab.all' | translate }}
        <app-badge *ngIf="notificationService.data.ALL.unread !== 0" class="margin-left">
          {{ notificationService.data.ALL.unread }}
        </app-badge>
      </button>
      <button
        (click)="changeTabAction('ASSET')"
        [ngClass]="{ active: activeView === 'ASSET' }"
        [ripple]="false"
        appButton
        class="tab">
        {{ 'profile.notification.tab.assets' | translate }}
        <app-badge *ngIf="notificationService.data.ASSET.unread !== 0" class="margin-left">
          {{ notificationService.data.ASSET.unread }}
        </app-badge>
      </button>
      <button
        (click)="changeTabAction('DOCUMENT')"
        [ngClass]="{ active: activeView === 'DOCUMENT' }"
        [ripple]="false"
        appButton
        class="tab">
        {{ 'profile.notification.tab.documents' | translate }}
        <app-badge *ngIf="notificationService.data.DOCUMENT.unread !== 0" class="margin-left">
          {{ notificationService.data.DOCUMENT.unread }}
        </app-badge>
      </button>

      <button
        (click)="changeTabAction('METAVERSE_3D')"
        [ngClass]="{ active: activeView === 'METAVERSE_3D' }"
        [ripple]="false"
        appButton
        class="tab">
        {{ 'profile.notification.tab.metaverse' | translate }}
        <app-badge *ngIf="notificationService.data.METAVERSE_3D.unread !== 0" class="margin-left">
          {{ notificationService.data.METAVERSE_3D.unread }}
        </app-badge>
      </button>
    </div>

    <div class="container dc-scroll-light" >
      <cdk-virtual-scroll-viewport
        *ngIf="notifications.length"
        (scrollNearEnd)="nextPageAction($event)"
        appInfiniteScroll
        [itemSize]="47"
        [maxBufferPx]="300"
        [minBufferPx]="150"
        class="notifications dc-scroll-light"
        #cdkVirtualScroll>
        <app-notification-item
          (readNotificationEvent)="readNotificationAction(n)"
          [notification]="n"
          [expanded]="n.id === expandedNotificationId"
          *cdkVirtualFor="let n of notifications; trackBy: identifyNotification"
          class="notification-item"
          (expand)="onExpand($event)"></app-notification-item>
      </cdk-virtual-scroll-viewport>
      <app-no-data
        class="no-data darken"
        *ngIf="!notifications.length && !isLoading"
        [title]="'profile.notification.empty.title' | translate"
        [text]="'profile.notification.empty.text' | translate"
        [canRedirect]="false"></app-no-data>
    </div>
  </div>

  <div *ngIf="notifications.length" class="width-100 content-footer sticky">
    <div>
      <ng-container *mediaIf="'medium down'; else desktopViewBtn">
        <button
          (click)="markAllAsReadAction()"
          [ripple]="false"
          [disabled]="markAllAsReadDisabled"
          appButton
          class="tab button margin-left-auto"
          style="margin-right: 0">
          <div class="display-flex justify-center align-center"
               [class.color-warn]="
            notificationService.data.ALL.unread !== 0 ||
            notificationService.data.ASSET.unread !== 0 ||
            notificationService.data.DOCUMENT.unread !== 0 ||
            notificationService.data.METAVERSE_3D.unread !== 0
          ">
            <app-icon
              class="xhg margin-right"
              [class.color-warn]="
            notificationService.data.ALL.unread !== 0 ||
            notificationService.data.ASSET.unread !== 0 ||
            notificationService.data.DOCUMENT.unread !== 0 ||
            notificationService.data.METAVERSE_3D.unread !== 0
          "
              [icon]="eyeIcon"></app-icon>
            {{ 'profile.notification.tab.mark_as_read' | translate }}
          </div>

        </button>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #desktopViewBtn>
  <button
    (click)="markAllAsReadAction()"
    *mediaIf="'large'"
    [ripple]="false"
    [disabled]="markAllAsReadDisabled"
    appButton
    class="tab button margin-left-auto"
    style="margin-right: 0">
    <div>
      {{ 'profile.notification.tab.mark_as_read' | translate }}
    </div>
  </button>
</ng-template>
