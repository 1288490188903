import { Platform } from '@angular/cdk/platform';
import { NgIfContext } from '@angular/common';
import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

type PlatformName = keyof Platform;

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[platformIf]',
})
export class OsIfDirective implements OnInit {
  @Input()
  platformIf: PlatformName[] | PlatformName = [];
  @Input()
  platformIfElse: TemplateRef<NgIfContext> | null = null;

  private thenViewCreated = false;
  private elseViewCreated = false;

  constructor(
    private templateRef: TemplateRef<HTMLElement>,
    private viewContainer: ViewContainerRef,
    private platform: Platform
  ) {}

  ngOnInit(): void {
    this.updateViewContainer();
  }

  private updateViewContainer(): void {
    const pl = this.platformIf;
    const isAllowed = Array.isArray(pl) ? pl.some(o => this.platform[o]) : this.platform[pl];
    if (isAllowed) {
      if (this.elseViewCreated) {
        this.viewContainer.clear();
        this.elseViewCreated = false;
      }
      if (!this.thenViewCreated) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.thenViewCreated = true;
      }
    } else {
      if (this.thenViewCreated) {
        this.viewContainer.clear();
        this.thenViewCreated = false;
      }
      if (this.platformIfElse && !this.elseViewCreated) {
        this.viewContainer.createEmbeddedView(this.platformIfElse);
        this.elseViewCreated = true;
      }
    }
  }
}
