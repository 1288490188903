import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

import { RadioButtonComponent } from './radio-button.component';

@Component({
  selector: 'app-radio-group',
  template: `
    <ng-content></ng-content>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioGroupComponent),
      multi: true,
    },
  ],
  styleUrls: ['./styles/radio-group.component.scss'],
})
export class RadioGroupComponent implements ControlValueAccessor {
  private buttons: { button: RadioButtonComponent; subs: Subscription[] }[] = [];
  protected value: unknown;
  protected onControlTouched = (): null => null;
  protected onControlChanged = (_: unknown): null => null;

  addButton(btn: RadioButtonComponent): void {
    const subs: Subscription[] = [];
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    const changedSub = btn.changed.subscribe(() => {
      this.checkButtons();
    });
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    const activatedSub = btn.activated.subscribe(() => {
      this.value = btn.value;
      this.onControlTouched();
      this.onControlChanged(this.value);
      this.checkButtons();
    });
    subs.push(changedSub, activatedSub);
    this.buttons.push({ button: btn, subs });
  }

  private checkButtons(): void {
    this.buttons.forEach(b => {
      b.button.active = this.value === b.button.value;
    });
  }

  removeButton(btn: RadioButtonComponent): void {
    const i = this.buttons.findIndex(b => b.button === btn);
    if (i >= 0) {
      this.buttons[i].subs.forEach(s => s.unsubscribe());
      this.buttons.splice(i, 1);
    }
  }

  writeValue(value: unknown): void {
    this.value = value;
    this.checkButtons();
  }

  registerOnChange(fn: (val: unknown) => null): void {
    this.onControlChanged = fn;
  }

  registerOnTouched(fn: () => null): void {
    this.onControlTouched = fn;
  }
}
