import { animate, state, style, transition, trigger } from '@angular/animations';

export const fixedCardOverlayTransition = trigger('fixedCardOverlayTransition', [
  transition(':enter', [style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]),
  transition(':leave', [animate('300ms', style({ opacity: 0 }))]),
]);

export const fixedCardFade = trigger('fixedCardFade', [
  state(
    'open',
    style({
      opacity: 1,
      transform: 'translateX(0)',
    })
  ),
  state(
    'close',
    style({
      opacity: 0,
      transform: 'translateX(100%)',
    })
  ),
  transition('open <=> close', [animate('0.4s cubic-bezier(0.51, 0.01, 0, 1.3)')]),
]);
