/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { User3DInformationTOList } from '../models/user-3dinformation-tolist';
import { User3DInformationTO } from '../models/user-3dinformation-to';
import { User3DDataDto } from '../models/user-3ddata-dto';

/**
 * Game User Setting Data Controller
 */
@Injectable({
  providedIn: 'root',
})
class GameUserSettingDataControllerService extends __BaseService {
  static readonly get3DSectionDataUsingGETPath = '/api/v1/user-3d-game-data/{uuid}';
  static readonly save3DSectionUsingPOSTPath = '/api/v1/user-3d-game-data/{uuid}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * get3DSectionData
   * @param uuid uuid
   * @return OK
   */
  get3DSectionDataUsingGETResponse(uuid: string): __Observable<__StrictHttpResponse<User3DInformationTOList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/user-3d-game-data/${encodeURIComponent(String(uuid))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User3DInformationTOList>;
      })
    );
  }
  /**
   * get3DSectionData
   * @param uuid uuid
   * @return OK
   */
  get3DSectionDataUsingGET(uuid: string): __Observable<User3DInformationTOList> {
    return this.get3DSectionDataUsingGETResponse(uuid).pipe(
      __map(_r => _r.body as User3DInformationTOList)
    );
  }

  /**
   * save3DSection
   * @param form form
   * @return OK
   */
  save3DSectionUsingPOSTResponse(form: User3DDataDto): __Observable<__StrictHttpResponse<User3DInformationTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/user-3d-game-data/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User3DInformationTO>;
      })
    );
  }
  /**
   * save3DSection
   * @param form form
   * @return OK
   */
  save3DSectionUsingPOST(form: User3DDataDto): __Observable<User3DInformationTO> {
    return this.save3DSectionUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as User3DInformationTO)
    );
  }
}

module GameUserSettingDataControllerService {
}

export { GameUserSettingDataControllerService }
