<form (ngSubmit)="submit()" [formGroup]="form" class="history-filter-form">
  <app-date-input
    [formControl]="form.controls.closeFrom"
    [placeholder]="'dashboard.ui.filter_history.from' | translate"
    class="solid primary-dark ph-always weight-semibold filter-input-item width-100"
    title="Date"></app-date-input>
  <app-date-input
    [formControl]="form.controls.closeTo"
    [placeholder]="'dashboard.ui.filter_history.to' | translate"
    class="solid primary-dark ph-always weight-semibold filter-input-item width-100"
    title="Date"></app-date-input>

  <app-select [formControl]="form.controls.orderType" class="filter-input-item width-100">
    <app-html-input
      [title]="'dashboard.ui.filter_history.type' | translate"
      appAnchor
      class="solid primary-dark"
      extras="dropdown"
      type="false">
      {{ form.controls.orderType.value.name | translate }}
    </app-html-input>
    <ng-template>
      <button
        *ngFor="let option of types"
        [appSelectItem]="option"
        appButton
        class="solid for-select">
        {{ option.name | translate }}
      </button>
    </ng-template>
  </app-select>

  <app-select [formControl]="form.controls.side" class="filter-input-item width-100">
    <app-html-input
      [title]="'dashboard.ui.filter_history.side' | translate"
      appAnchor
      class="solid primary-dark"
      extras="dropdown"
      type="false">
      {{ form.controls.side.value.name | translate }}
    </app-html-input>
    <ng-template>
      <button
        *ngFor="let option of sides"
        [appSelectItem]="option"
        appButton
        class="solid for-select">
        {{ option.name | translate }}
      </button>
    </ng-template>
  </app-select>


  <app-select [formControl]="form.controls.nftType" class="filter-input-item width-100">
    <app-html-input
      [title]="'dashboard.ui.filter_history.nftType' | translate"
      appAnchor
      class="solid primary-dark"
      extras="dropdown"
      type="false">
      {{ form.controls.nftType.value.name | translate }}
    </app-html-input>
    <ng-template>
      <button
        *ngFor="let option of nftTypesList"
        [appSelectItem]="option"
        appButton
        class="solid for-select">
        {{ option.name | translate }}
      </button>
    </ng-template>
  </app-select>
</form>
