/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CityTOListResponse } from '../models/city-tolist-response';
import { CountryListResponse } from '../models/country-list-response';
import { RegionTO } from '../models/region-to';
import { CollectionMetaInformationTOListResponse } from '../models/collection-meta-information-tolist-response';
import { CollectionMetaInformationExtendedTOResponse } from '../models/collection-meta-information-extended-toresponse';

/**
 * Collection Meta Information Controller
 */
@Injectable({
  providedIn: 'root',
})
class CollectionMetaInformationControllerService extends __BaseService {
  static readonly availableCitiesUsingGETPath = '/api/v1/collection-meta-information/available-cities';
  static readonly availableCountriesUsingGETPath = '/api/v1/collection-meta-information/available-countries';
  static readonly availableRegionsUsingGETPath = '/api/v1/collection-meta-information/available-regions';
  static readonly listUsingGET2Path = '/api/v1/collection-meta-information/list';
  static readonly publicListUsingGETPath = '/api/v1/collection-meta-information/list/public';
  static readonly recommendedUsingGETPath = '/api/v1/collection-meta-information/recommended';
  static readonly publicRecommendedUsingGETPath = '/api/v1/collection-meta-information/recommended/public';
  static readonly findByIdUsingGETPath = '/api/v1/collection-meta-information/{id}';
  static readonly publicFindByIdUsingGETPath = '/api/v1/collection-meta-information/{id}/public';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * availableCities
   * @param params The `CollectionMetaInformationControllerService.AvailableCitiesUsingGETParams` containing the following parameters:
   *
   * - `regionCode`: regionCode
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `nameStart`: nameStart
   *
   * - `countryCode`: countryCode
   *
   * @return OK
   */
  availableCitiesUsingGETResponse(params: CollectionMetaInformationControllerService.AvailableCitiesUsingGETParams): __Observable<__StrictHttpResponse<CityTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionCode != null) __params = __params.set('regionCode', params.regionCode.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.nameStart != null) __params = __params.set('nameStart', params.nameStart.toString());
    if (params.countryCode != null) __params = __params.set('countryCode', params.countryCode.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/collection-meta-information/available-cities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CityTOListResponse>;
      })
    );
  }
  /**
   * availableCities
   * @param params The `CollectionMetaInformationControllerService.AvailableCitiesUsingGETParams` containing the following parameters:
   *
   * - `regionCode`: regionCode
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `nameStart`: nameStart
   *
   * - `countryCode`: countryCode
   *
   * @return OK
   */
  availableCitiesUsingGET(params: CollectionMetaInformationControllerService.AvailableCitiesUsingGETParams): __Observable<CityTOListResponse> {
    return this.availableCitiesUsingGETResponse(params).pipe(
      __map(_r => _r.body as CityTOListResponse)
    );
  }

  /**
   * availableCountries
   * @param params The `CollectionMetaInformationControllerService.AvailableCountriesUsingGETParams` containing the following parameters:
   *
   * - `regionCode`: regionCode
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * @return OK
   */
  availableCountriesUsingGETResponse(params: CollectionMetaInformationControllerService.AvailableCountriesUsingGETParams): __Observable<__StrictHttpResponse<CountryListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionCode != null) __params = __params.set('regionCode', params.regionCode.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/collection-meta-information/available-countries`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CountryListResponse>;
      })
    );
  }
  /**
   * availableCountries
   * @param params The `CollectionMetaInformationControllerService.AvailableCountriesUsingGETParams` containing the following parameters:
   *
   * - `regionCode`: regionCode
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * @return OK
   */
  availableCountriesUsingGET(params: CollectionMetaInformationControllerService.AvailableCountriesUsingGETParams): __Observable<CountryListResponse> {
    return this.availableCountriesUsingGETResponse(params).pipe(
      __map(_r => _r.body as CountryListResponse)
    );
  }

  /**
   * availableRegions
   * @return OK
   */
  availableRegionsUsingGETResponse(): __Observable<__StrictHttpResponse<Array<RegionTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/collection-meta-information/available-regions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RegionTO>>;
      })
    );
  }
  /**
   * availableRegions
   * @return OK
   */
  availableRegionsUsingGET(): __Observable<Array<RegionTO>> {
    return this.availableRegionsUsingGETResponse().pipe(
      __map(_r => _r.body as Array<RegionTO>)
    );
  }

  /**
   * list
   * @param params The `CollectionMetaInformationControllerService.ListUsingGET2Params` containing the following parameters:
   *
   * - `zones`: zones
   *
   * - `sort`: sort
   *
   * - `showOnlyWithMatchingStreets`: showOnlyWithMatchingStreets
   *
   * - `searchText`: searchText
   *
   * - `regionCode`: regionCode
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `isExtendedSearch`: isExtendedSearch
   *
   * - `countryCode`: countryCode
   *
   * - `cityId`: cityId
   *
   * @return OK
   */
  listUsingGET2Response(params: CollectionMetaInformationControllerService.ListUsingGET2Params): __Observable<__StrictHttpResponse<CollectionMetaInformationTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.zones || []).forEach(val => {if (val != null) __params = __params.append('zones', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.showOnlyWithMatchingStreets != null) __params = __params.set('showOnlyWithMatchingStreets', params.showOnlyWithMatchingStreets.toString());
    if (params.searchText != null) __params = __params.set('searchText', params.searchText.toString());
    if (params.regionCode != null) __params = __params.set('regionCode', params.regionCode.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.isExtendedSearch != null) __params = __params.set('isExtendedSearch', params.isExtendedSearch.toString());
    if (params.countryCode != null) __params = __params.set('countryCode', params.countryCode.toString());
    if (params.cityId != null) __params = __params.set('cityId', params.cityId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/collection-meta-information/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionMetaInformationTOListResponse>;
      })
    );
  }
  /**
   * list
   * @param params The `CollectionMetaInformationControllerService.ListUsingGET2Params` containing the following parameters:
   *
   * - `zones`: zones
   *
   * - `sort`: sort
   *
   * - `showOnlyWithMatchingStreets`: showOnlyWithMatchingStreets
   *
   * - `searchText`: searchText
   *
   * - `regionCode`: regionCode
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `isExtendedSearch`: isExtendedSearch
   *
   * - `countryCode`: countryCode
   *
   * - `cityId`: cityId
   *
   * @return OK
   */
  listUsingGET2(params: CollectionMetaInformationControllerService.ListUsingGET2Params): __Observable<CollectionMetaInformationTOListResponse> {
    return this.listUsingGET2Response(params).pipe(
      __map(_r => _r.body as CollectionMetaInformationTOListResponse)
    );
  }

  /**
   * publicList
   * @param params The `CollectionMetaInformationControllerService.PublicListUsingGETParams` containing the following parameters:
   *
   * - `zones`: zones
   *
   * - `sort`: sort
   *
   * - `showOnlyWithMatchingStreets`: showOnlyWithMatchingStreets
   *
   * - `searchText`: searchText
   *
   * - `regionCode`: regionCode
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `isExtendedSearch`: isExtendedSearch
   *
   * - `countryCode`: countryCode
   *
   * - `cityId`: cityId
   *
   * @return OK
   */
  publicListUsingGETResponse(params: CollectionMetaInformationControllerService.PublicListUsingGETParams): __Observable<__StrictHttpResponse<CollectionMetaInformationTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.zones || []).forEach(val => {if (val != null) __params = __params.append('zones', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.showOnlyWithMatchingStreets != null) __params = __params.set('showOnlyWithMatchingStreets', params.showOnlyWithMatchingStreets.toString());
    if (params.searchText != null) __params = __params.set('searchText', params.searchText.toString());
    if (params.regionCode != null) __params = __params.set('regionCode', params.regionCode.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.isExtendedSearch != null) __params = __params.set('isExtendedSearch', params.isExtendedSearch.toString());
    if (params.countryCode != null) __params = __params.set('countryCode', params.countryCode.toString());
    if (params.cityId != null) __params = __params.set('cityId', params.cityId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/collection-meta-information/list/public`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionMetaInformationTOListResponse>;
      })
    );
  }
  /**
   * publicList
   * @param params The `CollectionMetaInformationControllerService.PublicListUsingGETParams` containing the following parameters:
   *
   * - `zones`: zones
   *
   * - `sort`: sort
   *
   * - `showOnlyWithMatchingStreets`: showOnlyWithMatchingStreets
   *
   * - `searchText`: searchText
   *
   * - `regionCode`: regionCode
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `isExtendedSearch`: isExtendedSearch
   *
   * - `countryCode`: countryCode
   *
   * - `cityId`: cityId
   *
   * @return OK
   */
  publicListUsingGET(params: CollectionMetaInformationControllerService.PublicListUsingGETParams): __Observable<CollectionMetaInformationTOListResponse> {
    return this.publicListUsingGETResponse(params).pipe(
      __map(_r => _r.body as CollectionMetaInformationTOListResponse)
    );
  }

  /**
   * recommended
   * @param params The `CollectionMetaInformationControllerService.RecommendedUsingGETParams` containing the following parameters:
   *
   * - `zones`: zones
   *
   * - `sort`: sort
   *
   * - `searchText`: searchText
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * @return OK
   */
  recommendedUsingGETResponse(params: CollectionMetaInformationControllerService.RecommendedUsingGETParams): __Observable<__StrictHttpResponse<CollectionMetaInformationTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.zones || []).forEach(val => {if (val != null) __params = __params.append('zones', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.searchText != null) __params = __params.set('searchText', params.searchText.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/collection-meta-information/recommended`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionMetaInformationTOListResponse>;
      })
    );
  }
  /**
   * recommended
   * @param params The `CollectionMetaInformationControllerService.RecommendedUsingGETParams` containing the following parameters:
   *
   * - `zones`: zones
   *
   * - `sort`: sort
   *
   * - `searchText`: searchText
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * @return OK
   */
  recommendedUsingGET(params: CollectionMetaInformationControllerService.RecommendedUsingGETParams): __Observable<CollectionMetaInformationTOListResponse> {
    return this.recommendedUsingGETResponse(params).pipe(
      __map(_r => _r.body as CollectionMetaInformationTOListResponse)
    );
  }

  /**
   * publicRecommended
   * @param params The `CollectionMetaInformationControllerService.PublicRecommendedUsingGETParams` containing the following parameters:
   *
   * - `zones`: zones
   *
   * - `sort`: sort
   *
   * - `searchText`: searchText
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * @return OK
   */
  publicRecommendedUsingGETResponse(params: CollectionMetaInformationControllerService.PublicRecommendedUsingGETParams): __Observable<__StrictHttpResponse<CollectionMetaInformationTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.zones || []).forEach(val => {if (val != null) __params = __params.append('zones', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.searchText != null) __params = __params.set('searchText', params.searchText.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/collection-meta-information/recommended/public`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionMetaInformationTOListResponse>;
      })
    );
  }
  /**
   * publicRecommended
   * @param params The `CollectionMetaInformationControllerService.PublicRecommendedUsingGETParams` containing the following parameters:
   *
   * - `zones`: zones
   *
   * - `sort`: sort
   *
   * - `searchText`: searchText
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * @return OK
   */
  publicRecommendedUsingGET(params: CollectionMetaInformationControllerService.PublicRecommendedUsingGETParams): __Observable<CollectionMetaInformationTOListResponse> {
    return this.publicRecommendedUsingGETResponse(params).pipe(
      __map(_r => _r.body as CollectionMetaInformationTOListResponse)
    );
  }

  /**
   * findById
   * @param id id
   * @return OK
   */
  findByIdUsingGETResponse(id: number): __Observable<__StrictHttpResponse<CollectionMetaInformationExtendedTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/collection-meta-information/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionMetaInformationExtendedTOResponse>;
      })
    );
  }
  /**
   * findById
   * @param id id
   * @return OK
   */
  findByIdUsingGET(id: number): __Observable<CollectionMetaInformationExtendedTOResponse> {
    return this.findByIdUsingGETResponse(id).pipe(
      __map(_r => _r.body as CollectionMetaInformationExtendedTOResponse)
    );
  }

  /**
   * publicFindById
   * @param id id
   * @return OK
   */
  publicFindByIdUsingGETResponse(id: number): __Observable<__StrictHttpResponse<CollectionMetaInformationExtendedTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/collection-meta-information/${encodeURIComponent(String(id))}/public`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionMetaInformationExtendedTOResponse>;
      })
    );
  }
  /**
   * publicFindById
   * @param id id
   * @return OK
   */
  publicFindByIdUsingGET(id: number): __Observable<CollectionMetaInformationExtendedTOResponse> {
    return this.publicFindByIdUsingGETResponse(id).pipe(
      __map(_r => _r.body as CollectionMetaInformationExtendedTOResponse)
    );
  }
}

module CollectionMetaInformationControllerService {

  /**
   * Parameters for availableCitiesUsingGET
   */
  export interface AvailableCitiesUsingGETParams {

    /**
     * regionCode
     */
    regionCode?: string;

    /**
     * pageSize
     */
    pageSize?: number;

    /**
     * pageNumber
     */
    pageNumber?: number;

    /**
     * nameStart
     */
    nameStart?: string;

    /**
     * countryCode
     */
    countryCode?: string;
  }

  /**
   * Parameters for availableCountriesUsingGET
   */
  export interface AvailableCountriesUsingGETParams {

    /**
     * regionCode
     */
    regionCode: string;

    /**
     * pageSize
     */
    pageSize?: number;

    /**
     * pageNumber
     */
    pageNumber?: number;
  }

  /**
   * Parameters for listUsingGET2
   */
  export interface ListUsingGET2Params {

    /**
     * zones
     */
    zones?: Array<number>;

    /**
     * sort
     */
    sort?: string;

    /**
     * showOnlyWithMatchingStreets
     */
    showOnlyWithMatchingStreets?: boolean;

    /**
     * searchText
     */
    searchText?: string;

    /**
     * regionCode
     */
    regionCode?: string;

    /**
     * pageSize
     */
    pageSize?: number;

    /**
     * pageNumber
     */
    pageNumber?: number;

    /**
     * isExtendedSearch
     */
    isExtendedSearch?: boolean;

    /**
     * countryCode
     */
    countryCode?: string;

    /**
     * cityId
     */
    cityId?: number;
  }

  /**
   * Parameters for publicListUsingGET
   */
  export interface PublicListUsingGETParams {

    /**
     * zones
     */
    zones?: Array<number>;

    /**
     * sort
     */
    sort?: string;

    /**
     * showOnlyWithMatchingStreets
     */
    showOnlyWithMatchingStreets?: boolean;

    /**
     * searchText
     */
    searchText?: string;

    /**
     * regionCode
     */
    regionCode?: string;

    /**
     * pageSize
     */
    pageSize?: number;

    /**
     * pageNumber
     */
    pageNumber?: number;

    /**
     * isExtendedSearch
     */
    isExtendedSearch?: boolean;

    /**
     * countryCode
     */
    countryCode?: string;

    /**
     * cityId
     */
    cityId?: number;
  }

  /**
   * Parameters for recommendedUsingGET
   */
  export interface RecommendedUsingGETParams {

    /**
     * zones
     */
    zones?: Array<number>;

    /**
     * sort
     */
    sort?: string;

    /**
     * searchText
     */
    searchText?: string;

    /**
     * pageSize
     */
    pageSize?: number;

    /**
     * pageNumber
     */
    pageNumber?: number;
  }

  /**
   * Parameters for publicRecommendedUsingGET
   */
  export interface PublicRecommendedUsingGETParams {

    /**
     * zones
     */
    zones?: Array<number>;

    /**
     * sort
     */
    sort?: string;

    /**
     * searchText
     */
    searchText?: string;

    /**
     * pageSize
     */
    pageSize?: number;

    /**
     * pageNumber
     */
    pageNumber?: number;
  }
}

export { CollectionMetaInformationControllerService }
