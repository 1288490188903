import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DecentWorldMarketplaceTO } from 'api/models';
import { appNav } from 'app/app.navigation';
import { AuthService } from 'auth/services/auth.service';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { marketplaceNav } from './marketplace.navigation';
import {
  MarketplaceMarketsService,
  MarketsStatistic,
} from './services/marketplace-markets.service';
import { MarketplaceStatisticService } from './services/marketplace-statistic.service';

@Component({
  selector: 'app-marketplace',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.scss'],
})
export class MarketplaceComponent implements OnInit, OnDestroy {
  isMarketsPage = true;
  isWatchlistPage = false;
  isRootPage = false;

  readonly appNav = appNav;
  totalMarkets: Observable<MarketsStatistic>;

  streetsStatistic: DecentWorldMarketplaceTO = {};

  private destroy$ = new Subject<void>();

  constructor(
    public authService: AuthService,
    private marketplaceStatisticService: MarketplaceStatisticService,
    private marketplaceMarketsService: MarketplaceMarketsService,
    private router: Router
  ) {
    this.totalMarkets = this.marketplaceMarketsService.getMarketsStatisticData();
  }

  ngOnInit(): void {
    this.marketplaceStatisticService
      .getMarketplaceStatistic()
      .pipe(takeUntil(this.destroy$))
      .subscribe(streetsStatistic => {
        this.streetsStatistic = streetsStatistic;
      });
    this.checkIfIsRootPage(this.router.url);

    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe(event => {
        const _event = event as NavigationEnd;
        this.checkIfIsRootPage(_event.url);
      });
  }

  private checkIfIsRootPage(url: string): void {
    const a = url?.split('/');

    if (
      a.length > 2 &&
      a[1] === 'marketplace' &&
      (a[2].includes('buildings') || a[2].includes('streets'))
    ) {
      this.isRootPage = true;
    } else {
      this.isRootPage = false;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  checkRouterUrl(item?: NavigationEnd): void {
    if (item) {
      const a = item?.url?.split('/');
      this.isMarketsPage = a[2] === 'streets' || a[2] === 'buildings';

      this.isWatchlistPage =
        a.length > 2 &&
        a[2] ===
          marketplaceNav.wishlist().split('/')[marketplaceNav.wishlist().split('/').length - 1];
    } else {
      const urlArray = this.router.url.split('/');
      this.isMarketsPage = urlArray[2] === 'streets' || urlArray[2] === 'buildings';
      this.isWatchlistPage =
        urlArray.length > 2 &&
        urlArray[2] ===
          marketplaceNav.wishlist().split('/')[marketplaceNav.wishlist().split('/').length - 1];
    }
  }
}
