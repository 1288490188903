import { Injectable } from '@angular/core';
import {
  AccountTOResponse,
  AccountUpgradeLevelTO,
  FeeSetValueTOResponse,
  LevelListForAccountResponse,
  PaymentPlanTOResponse,
} from 'api/models';
import {
  AccountControllerService,
  AccountUpgradeLevelServiceControllerService,
} from 'api/services';
import { WalletsService } from 'cashier/services/wallets.service';
import { UserPlanUppercase } from 'profile/enum/user-plan';
import { forkJoin, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export interface AccountPropsValues {
  p2pFee?: number;
  transferFee?: number;
  transferNftStaticFee?: number;
  bonusPoints?: number;
  mysteryBoxLimit?: number;
  dailyPurchaseLimit?: number;
  decentBonusCashback?: number;
  withdrawFundsFee?: number;
  makerFee?: number;
}

export interface AccountProps {
  basic: AccountPropsValues;
  elite: AccountPropsValues;
  premium: AccountPropsValues;
  standard: AccountPropsValues;
}

@Injectable({ providedIn: 'root' })
export class AccountsService {
  account: AccountTOResponse = {};
  updateAccounts: Readonly<AccountUpgradeLevelTO> = {};

  accountProps: AccountProps = {
    basic: {},
    elite: {},
    premium: {},
    standard: {},
  };

  constructor(
    private accountApi: AccountControllerService,
    private walletService: WalletsService,
    private accountUpdateApi: AccountUpgradeLevelServiceControllerService
  ) {
    this.walletService.bonusPointsUpdated.subscribe((points: number) => {
      if (!this.account.paymentPlan?.planType) {
        return;
      }
      const elitePoints = this.updateAccounts.elite?.bonusPoints || 0;
      const premiumPoints = this.updateAccounts.premium?.bonusPoints || 0;
      const standardPoints = this.updateAccounts.standard?.bonusPoints || 0;
      if (points < standardPoints) {
        this.account.paymentPlan.planType = 'DEFAULT_PLAN';
      } else if (points >= standardPoints && points < premiumPoints) {
        this.account.paymentPlan.planType = 'STANDARD';
      } else if (points >= premiumPoints && points < elitePoints) {
        this.account.paymentPlan.planType = 'PREMIUM';
      } else if (points >= elitePoints) {
        this.account.paymentPlan.planType = 'ELITE';
      }
    });
  }

  get paymentPlan(): PaymentPlanTOResponse {
    return this.account.paymentPlan || {};
  }

  get feeSet(): FeeSetValueTOResponse {
    return this.account.feeSetValue || {};
  }

  get activeProps(): AccountPropsValues {
    switch (this.paymentPlan.planType) {
      case UserPlanUppercase.BASIC:
        return this.accountProps.basic;
      case UserPlanUppercase.STANDARD:
        return this.accountProps.standard;
      case UserPlanUppercase.PREMIUM:
        return this.accountProps.premium;
      case UserPlanUppercase.ELITE:
        return this.accountProps.elite;
    }
    return {};
  }

  load(): Observable<[AccountTOResponse, AccountUpgradeLevelTO, LevelListForAccountResponse]> {
    return forkJoin([
      this.accountApi.listUsingGET().pipe(
        map(res => (res.list || [{}])[0]),
        tap(res => {
          this.account = res;
        })
      ),
      this.accountUpdateApi.listUsingGET1().pipe(
        tap(res => {
          this.updateAccounts = res;
        })
      ),
      this.accountUpdateApi.getAccountLevelInfoUsingGET().pipe(
        tap(res => {
          this.createAccountsProps(res);
        })
      ),
    ]);
  }

  reloadAccountBonus() {
    return this.accountApi.listUsingGET().pipe(
      map(res => (res.list || [{}])[0]),
      tap(res => {
        this.account.dwrldPrice = res.dwrldPrice;
      })
    );
  }

  private createAccountsProps(result: LevelListForAccountResponse): void {
    result.paymentPlanTOListResponse?.list?.forEach((x: PaymentPlanTOResponse) => {
      switch (x.planType) {
        case UserPlanUppercase.BASIC:
          this.accountProps.basic = {
            bonusPoints: result?.accountUpgradeLevelTOResponse?.basic?.bonusPoints,
            p2pFee: x.p2pFee,
            transferFee: x.transferFee,
            transferNftStaticFee: x.transferNftStaticFee,
            mysteryBoxLimit: x.mysteryBoxLimit,
            decentBonusCashback: x.decentBonusCashback,
            withdrawFundsFee: x.withdrawFundsFee,
            makerFee: x.feeSetTOResponse?.feeSetList?.[0].makerFee,
          };
          break;
        case UserPlanUppercase.STANDARD:
          this.accountProps.standard = {
            bonusPoints: result?.accountUpgradeLevelTOResponse?.standard?.bonusPoints,
            p2pFee: x.p2pFee,
            transferFee: x.transferFee,
            transferNftStaticFee: x.transferNftStaticFee,
            mysteryBoxLimit: x.mysteryBoxLimit,
            decentBonusCashback: x.decentBonusCashback,
            withdrawFundsFee: x.withdrawFundsFee,
            makerFee: x.feeSetTOResponse?.feeSetList?.[0].makerFee,
          };
          break;
        case UserPlanUppercase.ELITE:
          this.accountProps.elite = {
            bonusPoints: result?.accountUpgradeLevelTOResponse?.elite?.bonusPoints,
            p2pFee: x.p2pFee,
            transferFee: x.transferFee,
            transferNftStaticFee: x.transferNftStaticFee,
            mysteryBoxLimit: x.mysteryBoxLimit,
            decentBonusCashback: x.decentBonusCashback,
            withdrawFundsFee: x.withdrawFundsFee,
            makerFee: x.feeSetTOResponse?.feeSetList?.[0].makerFee,
          };
          break;
        case UserPlanUppercase.PREMIUM:
          this.accountProps.premium = {
            bonusPoints: result?.accountUpgradeLevelTOResponse?.premium?.bonusPoints,
            p2pFee: x.p2pFee,
            transferFee: x.transferFee,
            transferNftStaticFee: x.transferNftStaticFee,
            mysteryBoxLimit: x.mysteryBoxLimit,
            decentBonusCashback: x.decentBonusCashback,
            withdrawFundsFee: x.withdrawFundsFee,
            makerFee: x.feeSetTOResponse?.feeSetList?.[0].makerFee,
          };
          break;
      }
    });
  }
}
