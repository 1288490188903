import { Location } from '@angular/common';
import { ElementRef, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ToolbarOutletComponent } from 'core/components/toolbar-outlet/toolbar-outlet/toolbar-outlet.component';
import { Observable, ReplaySubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export interface AppUIEvent<T extends string = string, V = unknown> {
  type: T;
  value: V;
}

@Injectable({ providedIn: 'root' })
export class AppUI {
  headerComponent!: { elementRef: ElementRef<HTMLElement> };
  footerComponent!: { elementRef: ElementRef<HTMLElement> };
  toolbarOutletComponent!: ToolbarOutletComponent;

  private eventsSub = new ReplaySubject<AppUIEvent>();

  constructor(private router: Router, private location: Location) {}

  setup(): void {
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        // hide secondaryModal and primaryModal outlets from address bar
        if (
          this.router.url.includes('secondaryModal') ||
          this.router.url.includes('primaryModal')
        ) {
          this.location.replaceState(
            this.router.url.replace(/\((secondaryModal|primaryModal):.[^)]*\)/gi, '')
          );
        }
      }
    });
  }

  sendEvent(event: AppUIEvent): void {
    this.eventsSub.next(event);
  }

  onEvent<T extends AppUIEvent>(type: T['type']): Observable<T['value']> {
    return this.eventsSub.pipe(
      filter(e => e.type === type),
      map(e => e.value)
    );
  }

  dispatchNativeEvent<K extends keyof WindowEventMap>(event: K): void {
    window.dispatchEvent(new Event(event));
  }
}
