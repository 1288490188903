/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UserConfigurationsDto } from '../models/user-configurations-dto';
import { DefaultConfigurationDto } from '../models/default-configuration-dto';
import { EventDto } from '../models/event-dto';

/**
 * Notifications Controller
 */
@Injectable({
  providedIn: 'root',
})
class NotificationsControllerService extends __BaseService {
  static readonly getUserConfigurationUsingGETPath = '/notifications/configuration';
  static readonly updateUserConfigurationUsingPOSTPath = '/notifications/configuration';
  static readonly getDefaultSettingsUsingGETPath = '/notifications/default-settings';
  static readonly listEventsUsingGETPath = '/notifications/notifications/list-events';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get notification configuration for user
   * @return OK
   */
  getUserConfigurationUsingGETResponse(): __Observable<__StrictHttpResponse<UserConfigurationsDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/notifications/configuration`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserConfigurationsDto>;
      })
    );
  }
  /**
   * Get notification configuration for user
   * @return OK
   */
  getUserConfigurationUsingGET(): __Observable<UserConfigurationsDto> {
    return this.getUserConfigurationUsingGETResponse().pipe(
      __map(_r => _r.body as UserConfigurationsDto)
    );
  }

  /**
   * Update notification configuration for user
   * @param settings settings
   * @return OK
   */
  updateUserConfigurationUsingPOSTResponse(settings: UserConfigurationsDto): __Observable<__StrictHttpResponse<UserConfigurationsDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = settings;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/notifications/configuration`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserConfigurationsDto>;
      })
    );
  }
  /**
   * Update notification configuration for user
   * @param settings settings
   * @return OK
   */
  updateUserConfigurationUsingPOST(settings: UserConfigurationsDto): __Observable<UserConfigurationsDto> {
    return this.updateUserConfigurationUsingPOSTResponse(settings).pipe(
      __map(_r => _r.body as UserConfigurationsDto)
    );
  }

  /**
   * Get default configuration
   * @return OK
   */
  getDefaultSettingsUsingGETResponse(): __Observable<__StrictHttpResponse<DefaultConfigurationDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/notifications/default-settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DefaultConfigurationDto>;
      })
    );
  }
  /**
   * Get default configuration
   * @return OK
   */
  getDefaultSettingsUsingGET(): __Observable<DefaultConfigurationDto> {
    return this.getDefaultSettingsUsingGETResponse().pipe(
      __map(_r => _r.body as DefaultConfigurationDto)
    );
  }

  /**
   * List configured events
   * @return OK
   */
  listEventsUsingGETResponse(): __Observable<__StrictHttpResponse<Array<EventDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/notifications/notifications/list-events`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EventDto>>;
      })
    );
  }
  /**
   * List configured events
   * @return OK
   */
  listEventsUsingGET(): __Observable<Array<EventDto>> {
    return this.listEventsUsingGETResponse().pipe(
      __map(_r => _r.body as Array<EventDto>)
    );
  }
}

module NotificationsControllerService {
}

export { NotificationsControllerService }
