import { AnimationEvent } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FixedCardService } from 'app/_main/services/fixed-card.service';
import { iconCloseRounded } from 'core/icons/lib/icon-close-rounded';
import { ModalRouter } from 'core/modules/modal';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { fixedCardFade, fixedCardOverlayTransition } from './fixed-card.animations';

@Component({
  selector: 'app-fixed-card',
  templateUrl: './fixed-card.component.html',
  styleUrls: ['./fixed-card.component.scss'],
  animations: [fixedCardOverlayTransition, fixedCardFade],
})
export class FixedCardComponent implements OnInit, OnDestroy {
  @ViewChild('containerRef', { static: true, read: ViewContainerRef })
  containerRef!: ViewContainerRef;

  @HostBinding('class.active') active = false;

  isOpened = false;

  readonly closeIcon = iconCloseRounded;

  private destroy$ = new Subject<void>();

  constructor(
    public fixedCardService: FixedCardService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private modalRouter: ModalRouter
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        if (!this.fixedCardService.isForcedActive) {
          this.fixedCardService.hideCard(true);
        }
      });
    this.fixedCardService
      .isOpened()
      .pipe(takeUntil(this.destroy$))
      .subscribe(isOpened => {
        this.isOpened = isOpened;

        if (isOpened) {
          this.active = true;
          this.renderer.addClass(this.document.body, 'fixed-card-active');
        }
      });
    this.fixedCardService.initViewContainerRef(this.containerRef);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onEnd(event: AnimationEvent): void {
    if (event.toState === 'close') {
      this.active = false;
      this.renderer.removeClass(this.document.body, 'fixed-card-active');
    }
  }

  hideCard(): void {
    this.fixedCardService.hideCard(true);
  }
}
