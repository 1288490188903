/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TickerDtoResponse } from '../models/ticker-dto-response';
import { MarketOhlcvDataDtoResponse } from '../models/market-ohlcv-data-dto-response';
import { MarketOrderbookTOResponse } from '../models/market-orderbook-toresponse';
import { TradeFeeResponse } from '../models/trade-fee-response';
import { MarketOhlcvDataForUserTOList } from '../models/market-ohlcv-data-for-user-tolist';

/**
 * Trade Controller
 */
@Injectable({
  providedIn: 'root',
})
class TradeControllerService extends __BaseService {
  static readonly getMarketPriceUsingGETPath = '/api/v1/exchange/market/price';
  static readonly ohlcvHistoryUsingGETPath = '/api/v1/exchange/ohlcv/history';
  static readonly getOrderBookUsingGETPath = '/api/v1/exchange/orderbook';
  static readonly getUserTradeFeeUsingGETPath = '/api/v1/exchange/trade/fee';
  static readonly marketHistoryByUserIdUsingGETPath = '/api/v1/exchange/trade/history-for-user/';
  static readonly getOrderTradeHistoryUsingGETPath = '/api/v1/exchange/trade/order/history';
  static readonly getMarketTradeHistoryUsingGETPath = '/api/v1/exchange/trade/recent';
  static readonly getUserTradeHistoryUsingGETPath = '/api/v1/exchange/trade/user/history';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Market price by last trade
   * @param marketId marketId
   * @return OK
   */
  getMarketPriceUsingGETResponse(marketId: number): __Observable<__StrictHttpResponse<TickerDtoResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (marketId != null) __params = __params.set('marketId', marketId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/exchange/market/price`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TickerDtoResponse>;
      })
    );
  }
  /**
   * Market price by last trade
   * @param marketId marketId
   * @return OK
   */
  getMarketPriceUsingGET(marketId: number): __Observable<TickerDtoResponse> {
    return this.getMarketPriceUsingGETResponse(marketId).pipe(
      __map(_r => _r.body as TickerDtoResponse)
    );
  }

  /**
   * OHLCV history by market id
   * @param params The `TradeControllerService.OhlcvHistoryUsingGETParams` containing the following parameters:
   *
   * - `marketId`: marketId
   *
   * - `resolution`: resolution
   *
   * - `dateTo`: dateTo
   *
   * - `dateFrom`: dateFrom
   *
   * @return OK
   */
  ohlcvHistoryUsingGETResponse(params: TradeControllerService.OhlcvHistoryUsingGETParams): __Observable<__StrictHttpResponse<MarketOhlcvDataDtoResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.marketId != null) __params = __params.set('marketId', params.marketId.toString());
    if (params.resolution != null) __params = __params.set('resolution', params.resolution.toString());
    if (params.dateTo != null) __params = __params.set('dateTo', params.dateTo.toString());
    if (params.dateFrom != null) __params = __params.set('dateFrom', params.dateFrom.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/exchange/ohlcv/history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MarketOhlcvDataDtoResponse>;
      })
    );
  }
  /**
   * OHLCV history by market id
   * @param params The `TradeControllerService.OhlcvHistoryUsingGETParams` containing the following parameters:
   *
   * - `marketId`: marketId
   *
   * - `resolution`: resolution
   *
   * - `dateTo`: dateTo
   *
   * - `dateFrom`: dateFrom
   *
   * @return OK
   */
  ohlcvHistoryUsingGET(params: TradeControllerService.OhlcvHistoryUsingGETParams): __Observable<MarketOhlcvDataDtoResponse> {
    return this.ohlcvHistoryUsingGETResponse(params).pipe(
      __map(_r => _r.body as MarketOhlcvDataDtoResponse)
    );
  }

  /**
   * Current orderbook by market id
   * @param params The `TradeControllerService.GetOrderBookUsingGETParams` containing the following parameters:
   *
   * - `marketId`: marketId
   *
   * - `precision`: precision
   *
   * - `limit`: limit
   *
   * @return OK
   */
  getOrderBookUsingGETResponse(params: TradeControllerService.GetOrderBookUsingGETParams): __Observable<__StrictHttpResponse<MarketOrderbookTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.marketId != null) __params = __params.set('marketId', params.marketId.toString());
    if (params.precision != null) __params = __params.set('precision', params.precision.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/exchange/orderbook`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MarketOrderbookTOResponse>;
      })
    );
  }
  /**
   * Current orderbook by market id
   * @param params The `TradeControllerService.GetOrderBookUsingGETParams` containing the following parameters:
   *
   * - `marketId`: marketId
   *
   * - `precision`: precision
   *
   * - `limit`: limit
   *
   * @return OK
   */
  getOrderBookUsingGET(params: TradeControllerService.GetOrderBookUsingGETParams): __Observable<MarketOrderbookTOResponse> {
    return this.getOrderBookUsingGETResponse(params).pipe(
      __map(_r => _r.body as MarketOrderbookTOResponse)
    );
  }

  /**
   * Trade fee of user
   * @return OK
   */
  getUserTradeFeeUsingGETResponse(): __Observable<__StrictHttpResponse<TradeFeeResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/exchange/trade/fee`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TradeFeeResponse>;
      })
    );
  }
  /**
   * Trade fee of user
   * @return OK
   */
  getUserTradeFeeUsingGET(): __Observable<TradeFeeResponse> {
    return this.getUserTradeFeeUsingGETResponse().pipe(
      __map(_r => _r.body as TradeFeeResponse)
    );
  }

  /**
   * Currencies history by userId
   * @param params The `TradeControllerService.MarketHistoryByUserIdUsingGETParams` containing the following parameters:
   *
   * - `baseCurrency`: baseCurrency
   *
   * - `resolution`: resolution
   *
   * - `dateTo`: dateTo
   *
   * - `dateFrom`: dateFrom
   *
   * @return OK
   */
  marketHistoryByUserIdUsingGETResponse(params: TradeControllerService.MarketHistoryByUserIdUsingGETParams): __Observable<__StrictHttpResponse<MarketOhlcvDataForUserTOList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.baseCurrency != null) __params = __params.set('baseCurrency', params.baseCurrency.toString());
    if (params.resolution != null) __params = __params.set('resolution', params.resolution.toString());
    if (params.dateTo != null) __params = __params.set('dateTo', params.dateTo.toString());
    if (params.dateFrom != null) __params = __params.set('dateFrom', params.dateFrom.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/exchange/trade/history-for-user/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MarketOhlcvDataForUserTOList>;
      })
    );
  }
  /**
   * Currencies history by userId
   * @param params The `TradeControllerService.MarketHistoryByUserIdUsingGETParams` containing the following parameters:
   *
   * - `baseCurrency`: baseCurrency
   *
   * - `resolution`: resolution
   *
   * - `dateTo`: dateTo
   *
   * - `dateFrom`: dateFrom
   *
   * @return OK
   */
  marketHistoryByUserIdUsingGET(params: TradeControllerService.MarketHistoryByUserIdUsingGETParams): __Observable<MarketOhlcvDataForUserTOList> {
    return this.marketHistoryByUserIdUsingGETResponse(params).pipe(
      __map(_r => _r.body as MarketOhlcvDataForUserTOList)
    );
  }

  /**
   * Last trades by order id
   * @param orderId orderId
   * @return OK
   */
  getOrderTradeHistoryUsingGETResponse(orderId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (orderId != null) __params = __params.set('orderId', orderId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/exchange/trade/order/history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Last trades by order id
   * @param orderId orderId
   * @return OK
   */
  getOrderTradeHistoryUsingGET(orderId: number): __Observable<string> {
    return this.getOrderTradeHistoryUsingGETResponse(orderId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Last trades by market id
   * @param marketId marketId
   * @return OK
   */
  getMarketTradeHistoryUsingGETResponse(marketId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (marketId != null) __params = __params.set('marketId', marketId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/exchange/trade/recent`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Last trades by market id
   * @param marketId marketId
   * @return OK
   */
  getMarketTradeHistoryUsingGET(marketId: number): __Observable<string> {
    return this.getMarketTradeHistoryUsingGETResponse(marketId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * getUserTradeHistory
   * @param params The `TradeControllerService.GetUserTradeHistoryUsingGETParams` containing the following parameters:
   *
   * - `type`: type
   *
   * - `side`: side
   *
   * - `offset`: offset
   *
   * - `markets`: markets
   *
   * - `limit`: limit
   *
   * - `dateTo`: dateTo
   *
   * - `dateFrom`: dateFrom
   *
   * - `accountId`: accountId
   *
   * @return OK
   */
  getUserTradeHistoryUsingGETResponse(params: TradeControllerService.GetUserTradeHistoryUsingGETParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.type != null) __params = __params.set('type', params.type.toString());
    if (params.side != null) __params = __params.set('side', params.side.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    (params.markets || []).forEach(val => {if (val != null) __params = __params.append('markets', val.toString())});
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.dateTo != null) __params = __params.set('dateTo', params.dateTo.toString());
    if (params.dateFrom != null) __params = __params.set('dateFrom', params.dateFrom.toString());
    if (params.accountId != null) __params = __params.set('accountId', params.accountId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/exchange/trade/user/history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * getUserTradeHistory
   * @param params The `TradeControllerService.GetUserTradeHistoryUsingGETParams` containing the following parameters:
   *
   * - `type`: type
   *
   * - `side`: side
   *
   * - `offset`: offset
   *
   * - `markets`: markets
   *
   * - `limit`: limit
   *
   * - `dateTo`: dateTo
   *
   * - `dateFrom`: dateFrom
   *
   * - `accountId`: accountId
   *
   * @return OK
   */
  getUserTradeHistoryUsingGET(params: TradeControllerService.GetUserTradeHistoryUsingGETParams): __Observable<string> {
    return this.getUserTradeHistoryUsingGETResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module TradeControllerService {

  /**
   * Parameters for ohlcvHistoryUsingGET
   */
  export interface OhlcvHistoryUsingGETParams {

    /**
     * marketId
     */
    marketId: number;

    /**
     * resolution
     */
    resolution?: string;

    /**
     * dateTo
     */
    dateTo?: number;

    /**
     * dateFrom
     */
    dateFrom?: number;
  }

  /**
   * Parameters for getOrderBookUsingGET
   */
  export interface GetOrderBookUsingGETParams {

    /**
     * marketId
     */
    marketId: number;

    /**
     * precision
     */
    precision?: 'P0' | 'P1' | 'P2' | 'P3' | 'P4';

    /**
     * limit
     */
    limit?: number;
  }

  /**
   * Parameters for marketHistoryByUserIdUsingGET
   */
  export interface MarketHistoryByUserIdUsingGETParams {

    /**
     * baseCurrency
     */
    baseCurrency: string;

    /**
     * resolution
     */
    resolution?: string;

    /**
     * dateTo
     */
    dateTo?: number;

    /**
     * dateFrom
     */
    dateFrom?: number;
  }

  /**
   * Parameters for getUserTradeHistoryUsingGET
   */
  export interface GetUserTradeHistoryUsingGETParams {

    /**
     * type
     */
    type?: 'LIMIT' | 'MARKET' | 'MARKET_MARGIN_CALL' | 'UNKNOWN_ORDER_TYPE' | 'UNRECOGNIZED';

    /**
     * side
     */
    side?: 'BUY' | 'SELL' | 'UNKNOWN_SIDE' | 'UNRECOGNIZED';

    /**
     * offset
     */
    offset?: number;

    /**
     * markets
     */
    markets?: Array<number>;

    /**
     * limit
     */
    limit?: number;

    /**
     * dateTo
     */
    dateTo?: string;

    /**
     * dateFrom
     */
    dateFrom?: string;

    /**
     * accountId
     */
    accountId?: number;
  }
}

export { TradeControllerService }
