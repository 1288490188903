import { Injectable, Optional } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PageTO } from 'api/models';
import { BehaviorSubject, iif, Observable } from 'rxjs';
import { exhaustMap, map, tap } from 'rxjs/operators';

export interface RequestData<F, S> {
  sort: S | string;
  filter: F;
  pagination: PageTO;
}

@Injectable()
export class PageControllerService<F, S> {
  queryParamsCache = false;
  protected pageSize = 15;
  protected requestData$: BehaviorSubject<RequestData<F, S>> = new BehaviorSubject<
    RequestData<F, S>
  >({
    sort: '',
    pagination: {
      size: this.pageSize,
    },
    filter: {} as F,
  });

  constructor(protected route: ActivatedRoute, @Optional() protected router?: Router) {
    if (!this.router) {
      console.error('Inject router to your service');
    }
  }

  initQueryParamsListener(): Observable<RequestData<F, S>> {
    this.queryParamsCache = true;
    return this.getQueryParams();
  }

  getQueryParams(): Observable<RequestData<F, S>> {
    return this.route.queryParams.pipe(map(queryParams => this.convertToRequestData(queryParams)));
  }

  getRequestData(): Observable<RequestData<F, S>> {
    return iif(
      () => this.queryParamsCache,
      this.getQueryParams().pipe(
        tap(data => {
          this.requestData$.next(data);
        }),
        exhaustMap(() => this.requestData$.asObservable())
      ),
      this.requestData$.asObservable()
    );
  }

  setFilterParams(params: F, savePrevious = false): void {
    const sort = this.requestData$.value.sort;
    const pagination = this.requestData$.value.pagination;
    const filterData = savePrevious
      ? { ...this.requestData$.value.filter, ...params }
      : { ...params };
    const data = {
      sort,
      pagination: {
        ...pagination,
        pageNumber: 0,
      },
      filter: filterData,
    };
    if (this.queryParamsCache) {
      this.replaceQueryParams(data);
    } else {
      this.requestData$.next(data);
    }
  }

  setSortParams(params: S): void {
    const filter = this.requestData$.value.filter;
    const pagination = this.requestData$.value.pagination;

    const data = {
      filter,
      pagination,
      sort: params,
    };

    if (this.queryParamsCache) {
      this.replaceQueryParams(data);
    } else {
      this.requestData$.next(data);
    }
  }

  setPaginationParams(params: PageTO, isReload = false): void {
    const sort = this.requestData$.value.sort;
    const filter = this.requestData$.value.filter;
    const pagination = this.requestData$.value.pagination;
    const data = {
      sort,
      filter,
      pagination: {
        ...pagination,
        ...params,
      },
    };

    if (this.queryParamsCache) {
      if (!isReload) {
        this.pushQueryParams(data);
      } else {
        this.requestData$.next(data);
      }
    } else {
      this.requestData$.next(data);
    }
  }

  refreshPage(): void {
    this.requestData$.next(this.requestData$.value);
  }

  nextPage(): void {
    if (typeof this.requestData$.value.pagination.pageNumber === 'number') {
      this.requestData$.value.pagination.pageNumber += 1;
    } else {
      this.requestData$.value.pagination.pageNumber = 1;
    }
    this.requestData$.next(this.requestData$.value);
  }

  clearParams(): void {
    this.requestData$.next({
      sort: '',
      pagination: {
        pageNumber: 0,
      },
      filter: {} as F,
    });
  }

  clearQueryParams(): void {
    if (this.router) {
      const url = window.location.href.split('?')[0];
      window.history.replaceState('', '', url);
    }
  }

  protected convertToRequestData(queryParams: Partial<Record<string, string>>): RequestData<F, S> {
    const { sort, pageNumber, ...filter } = queryParams || {};
    return {
      sort: sort || '',
      filter: filter as unknown as F,
      pagination: {
        ...this.requestData$.getValue().pagination,
        pageNumber: pageNumber ? +pageNumber : 0,
      },
    };
  }

  protected createQueryParams(data: RequestData<F, S>): Params {
    const { sort, pagination, filter } = data;
    const queryParams = {
      sort: (sort as string) || null,
      pageNumber: pagination.pageNumber || null,
      ...(filter
        ? Object.entries(filter).reduce(
            (acc, [key, value]: [string, unknown]) => ({
              ...acc,
              [key]: value || null,
            }),
            {}
          )
        : {}),
    };

    return queryParams;
  }

  protected replaceQueryParams(data: RequestData<F, S>): void {
    if (this.queryParamsCache && this.router) {
      const queryParams = this.createQueryParams(data);
      this.router.navigate([], {
        queryParams: queryParams,
        queryParamsHandling: 'merge',
        replaceUrl: true,
        relativeTo: this.route,
      });
    }
  }

  private pushQueryParams(data: RequestData<F, S>): void {
    if (this.queryParamsCache && this.router) {
      const queryParams = this.createQueryParams(data);
      this.router.navigate([], {
        queryParams,
        queryParamsHandling: 'merge',
        replaceUrl: false,
        relativeTo: this.route,
      });
    }
  }
}
