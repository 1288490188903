import { Injectable } from '@angular/core';
import {
  CollectionStakeElementTOResponse,
  NftCollectionExtendedTOResponse,
  PageTO,
  StakeCollectionPropertyTO,
} from 'api/models';
import { CollectionStakeControllerService, NftCollectionControllerService } from 'api/services';
import { StateClearService, StateFullService } from 'app/_main/services/state-clear.service';
import { I18nService } from 'core/modules/i18n/i18n.service';
import { PrecisionPipe } from 'core/pipes/core-pipes/precision.pipe';
import { CurrencyService } from 'core/services/currency.service';
import { Toaster } from 'core/toaster';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';

import { Collection } from '../models/collection.model';
import { StakingSettingsFormData } from '../models/staking-settings.model';
import { CollectionFactory } from './collection.factory';

export type StakingCollectionParams = CollectionStakeControllerService.ListUsingGET3Params;
export type StakeFilterType = Exclude<
  StakingCollectionParams['stakeFilter'],
  'ALL' | 'UNKNOWN_STAKE_TYPE' | 'UNRECOGNIZED'
>;

export interface StakingCollectionListResponse {
  elements: Collection[];
  page: PageTO;
}

const WEEK_IN_DAYS = 7;

@Injectable({
  providedIn: 'root',
})
export class StakingService implements StateFullService {
  inProgress = false;
  private needToSyncStaking$ = new BehaviorSubject<void>(undefined);
  private needToSyncUnStaking$ = new BehaviorSubject<void>(undefined);
  private stakingCollectionMap$ = new BehaviorSubject<
    Map<StakeFilterType, StakingCollectionListResponse>
  >(new Map<StakeFilterType, StakingCollectionListResponse>());

  private precisionPipe = new PrecisionPipe(this.currencyService);

  constructor(
    private nftCollectionController: NftCollectionControllerService,
    private collectionStakeController: CollectionStakeControllerService,
    private collectionFactory: CollectionFactory,
    private stateClearService: StateClearService,
    private i18nService: I18nService,
    private toaster: Toaster,
    private currencyService: CurrencyService
  ) {
    this.stateClearService.register(this);
  }

  stakeCollection(
    collection: Collection,
    form: StakingSettingsFormData
  ): Observable<StakeCollectionPropertyTO> {
    return this.nftCollectionController
      .stakeCollectionPropertyUsingPOST({
        collectionId: collection.id,
        payoutsFrequency: form.payoutsFrequency * WEEK_IN_DAYS,
        periodStaking: form.periodStaking * WEEK_IN_DAYS,
        stakeType: form.stakeType,
      })
      .pipe(
        mergeMap(() =>
          this.nftCollectionController.stakeUsingPOST({
            id: collection.id,
            isStake: true,
            twoFaCode: form.twoFaCode.value,
          })
        ),
        tap(res => {
          collection.isStake = true;
          // TODO: for now it is wrong from BE response
          collection.statusFromBlockchain = 'WAITING_FOR_REGISTRATION';
          collection.stakeType = res.stakeType;
        })
      );
  }

  redeemCollection(collection: Collection): Observable<CollectionStakeElementTOResponse> {
    return this.collectionStakeController
      .redeemAvailableStakeUsingPOST({
        collectionId: collection.id,
        amount: collection.currentBalance,
      })
      .pipe(
        tap(() => {
          const amount = this.precisionPipe.transform(collection.currentBalance, 'DWRLD');
          this.toaster.success(
            '',
            this.i18nService.get($t('collection.staking.success', { amount }))
          );

          collection.currentBalance = 0;
        })
      );
  }

  extendCollection(collectionId: number, value: boolean): Observable<StakeCollectionPropertyTO> {
    return this.nftCollectionController.changeAutoExtensionUsingPOST({
      collectionId,
      isAutoExtension: value,
    });
  }

  getStakeProperties(collectionId: number): Observable<StakeCollectionPropertyTO> {
    return this.nftCollectionController.stakePropertyByCollectionIdUsingGET(collectionId);
  }

  getCollections(
    stakeFilter: StakeFilterType,
    pageNumber: number,
    sort: string
  ): Observable<StakingCollectionListResponse> {
    return this.collectionStakeController
      .listUsingGET3({
        stakeFilter,
        pageSize: 5,
        pageNumber,
        sort,
      })
      .pipe(
        map(response => ({
          page: response.page || {},
          elements:
            response.elements?.map(c => {
              if (stakeFilter === 'ONLY_STAKED' || stakeFilter === 'ONLY_UNSTAKED') {
                (c as NftCollectionExtendedTOResponse).complete = true;
              }
              return this.collectionFactory.get(c.metaInformation?.id, c);
            }) || [],
        })),
        tap(response => {
          this.setToMap(stakeFilter, response);
        })
      );
  }

  getStakedCollectionById(id: number): Observable<CollectionStakeElementTOResponse> {
    return this.collectionStakeController.byIdUsingGET(id);
  }

  needToSyncStaking(): void {
    this.needToSyncStaking$.next();
  }

  needToSyncUnStaking(): void {
    this.needToSyncUnStaking$.next();
  }

  selectNeedToSyncStaking(): Observable<void> {
    return this.needToSyncStaking$.asObservable();
  }

  selectNeedToSyncUnStaking(): Observable<void> {
    return this.needToSyncUnStaking$.asObservable();
  }

  selectCollectionsByStakeFilter(
    stakeFilter: StakeFilterType
  ): Observable<StakingCollectionListResponse> {
    return this.stakingCollectionMap$.asObservable().pipe(
      map(
        data =>
          data.get(stakeFilter) || {
            elements: [],
            page: {},
          }
      )
    );
  }

  clearState(): void {
    const map = this.stakingCollectionMap$.getValue();
    map.clear();
    this.stakingCollectionMap$.next(map);
  }

  private setToMap(stakeFilterType: StakeFilterType, data: StakingCollectionListResponse): void {
    const map = this.stakingCollectionMap$.getValue();
    map.set(stakeFilterType, data);
    this.stakingCollectionMap$.next(map);
  }
}
