import { Collection } from 'app/collections/models/collection.model';

import { Building } from './building.model';
import { Street } from './street.model';

export class CartItem {
  id!: number;

  set object(o: Collection | Street | Building) {
    this.cartObject = o;
    if (this.object instanceof Street) {
      this.streetItem = this.object;
    }
    if (this.object instanceof Building) {
      this.buildingItem = this.object;
    }
  }
  get object(): Collection | Street | Building {
    return this.cartObject;
  }
  private cartObject!: Collection | Street | Building;

  set nestedItems(s: (Street | Building)[]) {
    this.items = s;
    if (this.object instanceof Collection) {
      this.collectionItem = this.object;
      this.streets = this.nestedItems as Street[];
    }
  }
  get nestedItems(): (Street | Building)[] {
    return this.items;
  }
  private items: (Street | Building)[] = [];

  get collection(): Collection | undefined {
    return this.collectionItem;
  }
  private collectionItem?: Collection;

  get nestedStreets(): Street[] {
    return this.streets;
  }
  private streets: Street[] = [];

  get street(): Street | undefined {
    return this.streetItem;
  }
  private streetItem?: Street;

  get building(): Building | undefined {
    return this.buildingItem;
  }
  private buildingItem?: Building;

  get price(): number {
    if (this.street) {
      return this.street.lastPrice;
    } else if (this.building) {
      return this.building.lastPrice;
    } else if (this.collection) {
      return this.nestedItems.reduce((acc, i) => acc + (i.lastPrice || 0), 0);
    }
    return 0;
  }

  constructor(init: PickData<CartItem>) {
    Object.assign(this, init);
  }
}
