import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from 'core/modules/i18n/translate.module';
import { CorePipesModule } from 'core/pipes/core-pipes/core-pipes.module';

import { IconModule } from '../icon/icon.module';
import { APIValidateDirective } from './api-validate.directive';
import { FieldErrorComponent } from './field-error/field-error.component';
import { FormFieldComponent } from './form-field.component';
import { FormLabelComponent } from './form-label/form-label.component';

const exportedDeclarations = [
  FieldErrorComponent,
  FormFieldComponent,
  FormLabelComponent,
  APIValidateDirective,
];

@NgModule({
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    IconModule,
    CorePipesModule,
    TranslateModule,
  ],
  declarations: exportedDeclarations,
  exports: exportedDeclarations,
})
export class FormControlModule {}
