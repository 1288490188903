import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from 'core/modules/i18n/i18n.service';

@Pipe({
  name: 'countableString',
})
export class CountableStringPipe implements PipeTransform {
  private datePipe = new DatePipe('en-US');

  constructor(private i18n: I18nService) {}

  transform(value: number): string {
    return String(value) + this.localizeValue(+String(value || 0).slice(-1), value);
  }

  private localizeValue(type = 0, exactValue = 0): string {
    if (this.i18n.language.code === 'RU') {
      switch (type) {
        case 0:
        case 1:
        case 4:
        case 5:
        case 9:
          return 'ый';
        case 2:
        case 6:
        case 7:
        case 8:
          return 'ой';
        case 3:
          return 'ий';
        default:
          return 'ый';
      }
    } else {
      if (exactValue < 4) {
        switch (exactValue) {
          case 1:
            return 'st';
          case 2:
            return 'nd';
          case 3:
            return 'rd';
          default:
            return 'th';
        }
      } else {
        switch (type) {
          case 1:
            return 'st';
          case 2:
            return 'nd';
          case 3:
            return 'rd';
          default:
            return 'th';
        }
      }
    }
  }
}
