import { CdkOverlayOrigin, ConnectedPosition } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  forwardRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { iconCalendar } from 'core/icons/lib/icon-calendar';
import { iconClose } from 'core/icons/lib/icon-close';
import { asyncScheduler, fromEvent, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { InputComponent } from '../../../components/input/input.component';
import { MediaQuery } from '../../platform/services/media-query.service';
import { datepickerTransition } from './date-input.transitions';
import { DMYHandler } from './handlers/dmyHandler';

@Component({
  selector: 'app-date-input',
  template: `
    <span class="title">{{ title }}</span>
    <input
      class="inp"
      inputmode="numeric"
      [attr.placeholder]="placeholder"
      (click)="togglePicker(); stopClose()"
      #input />
    <button
      *ngIf="date"
      class="primary icon-md"
      [appClearButton]="iconClear"
      (click)="clear()"></button>
    <button
      class="primary icon-md"
      [appClearButton]="iconCalendar"
      (click)="togglePicker(); stopClose()"></button>
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayPositions]="[position]"
      [cdkConnectedOverlayOrigin]="overlayOrigin.elementRef"
      [cdkConnectedOverlayOpen]="showDatepicker">
      <app-datepicker
        [@datepickerTransition]="transitionState"
        [date]="date"
        [ngClass]="{ mobile: isMobile }"
        (click)="stopClose()"
        (changes)="onPickerChanges($event)"></app-datepicker>
    </ng-template>
  `,
  styleUrls: [
    '../../../components/input/styles/solid.scss',
    '../../../components/input/styles/solid-validation.scss',
    'date-input.component.scss',
  ],
  animations: [datepickerTransition],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateInputComponent),
      multi: true,
    },
  ],
})
export class DateInputComponent
  extends InputComponent
  implements ControlValueAccessor, OnInit, OnDestroy
{
  @Input()
  typing = true;

  iconCalendar = iconCalendar;
  iconClear = iconClose;
  position: ConnectedPosition = {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top',
  };
  showDatepicker = false;
  date?: Date;
  transitionState?: 'open' | 'mobile';
  isMobile?: boolean;
  private dateHandler: DMYHandler;
  private stopPickerClose?: boolean;
  private subs: Subscription[] = [];

  protected onControlTouched = (): null => null;
  protected onControlChanged = (_: unknown): null => null;
  constructor(
    public elementRef: ElementRef<HTMLElement>,
    protected renderer: Renderer2,
    private media: MediaQuery,
    @Inject(DOCUMENT) private document: Document
  ) {
    super(elementRef, renderer);
    this.dateHandler = new DMYHandler('/', 'mdy');
  }

  get overlayOrigin(): CdkOverlayOrigin {
    return this;
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  ngOnInit(): void {
    super.ngOnInit();
    fromEvent(window, 'click')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (!this.stopPickerClose && this.showDatepicker) {
          this.showDatepicker = false;
          this.onPickerStateChange();
        }
        this.stopPickerClose = false;
      });
    fromEvent(this.inputRef.nativeElement, 'focus')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (!this.typing) {
          this.inputRef.nativeElement.blur();
        }
      });
    fromEvent(this.inputRef.nativeElement, 'blur')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if ((this.inputRef.nativeElement as HTMLInputElement).value.length < 10) {
          this.setInputValue('');
        }
      });
    this.handleInput(this.inputRef.nativeElement as HTMLInputElement);
  }

  togglePicker(): void {
    this.showDatepicker = !this.showDatepicker;
    this.onPickerStateChange();
  }

  onPickerChanges(e?: Date): void {
    this.date = e;
    this.setInputValue(this.dateHandler.getDateString(e));
    this.onControlChanged(e);
    asyncScheduler.schedule(() => {
      this.showDatepicker = false;
      this.onPickerStateChange();
    }, 250);
  }

  stopClose(): void {
    this.stopPickerClose = true;
  }

  clear(): void {
    this.onPickerChanges(undefined);
  }

  writeValue(date?: Date): void {
    this.date = date;
    super.writeValue(this.dateHandler.getDateString(date));
  }

  private onPickerStateChange(): void {
    if (this.showDatepicker) {
      if (this.media.is('small only')) {
        this.isMobile = true;
        this.transitionState = 'mobile';
        this.renderer.setStyle(this.document.body, 'overflow', 'hidden');
        this.renderer.addClass(this.document.body, 'cdk-overlay');
      } else {
        this.transitionState = 'open';
      }
    } else {
      this.transitionState = undefined;
      this.isMobile = undefined;
      this.renderer.setStyle(this.document.body, 'overflow', '');
      this.renderer.addClass(this.document.body, 'cdk-overlay-off');
      asyncScheduler.schedule(() => {
        this.renderer.removeClass(this.document.body, 'cdk-overlay');
        this.renderer.removeClass(this.document.body, 'cdk-overlay-off');
      }, 300);
    }
  }

  private handleInput(input: HTMLInputElement): void {
    let lastText = '';
    let tmpMap: { m: string; d: string; y: string } = { m: '', d: '', y: '' };
    fromEvent(input, 'blur')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.onControlTouched();
      });
    fromEvent<KeyboardEvent>(input, 'keydown')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        lastText = input.value;
      });
    fromEvent<InputEvent>(input, 'input')
      .pipe(takeUntil(this.destroy$))
      .subscribe(e => {
        const allowedInput = e.data?.match(/(\d|\/)/g);
        const isBackInput = e.inputType === 'deleteContentBackward';
        let text = (input.value.match(/(\d+|\/)/g) || ['']).join(''); // remove all not allowed
        let caret = input.selectionEnd || 0;
        if (lastText.length >= 10 && isBackInput) {
          // if valid and backspace
          tmpMap = this.dateHandler.stringToMap(lastText);
        }
        if (!isBackInput && !allowedInput) {
          // if typed some letter we just move caret
          caret--;
        } else if (isBackInput) {
          // is backspace
          // if separator removed
          if (lastText.match(/\//g)?.length !== text.match(/\//g)?.length) {
            text = text.slice(0, caret - 1); // we also remove character before
          } else {
            // if decimal removed
            text = text.slice(0, caret); // just remove it
          }
        } else if (text[caret] === '/') {
          // if input just before separator
          // get char that overflows some part of date 1234[5]-
          const overflow = text[caret - 1];
          // cut text 1234
          text = text.slice(0, caret - 1) + text.slice(caret);
          // move overflow char after separator 1234-[5]
          text = text.substring(0, caret) + overflow + text.substring(caret + 1);
          // move caret because of separator
          caret++;
        } else if (allowedInput) {
          // any other input of decimal
          // just replace char at caret e.g. 12[7]34-56 => 1274-56
          // if we do not do this it will be 12[7]34-56 => 1273-56
          text = text.slice(0, caret) + text.slice(caret + 1);
        }
        const res = this.dateHandler.handle(text, caret, isBackInput, tmpMap);
        input.value = res.text;
        res.caret = Math.min(res.text.length, res.caret);
        input.setSelectionRange(res.caret, res.caret);
        this.date = res.date || undefined;
        this.onControlChanged(res.date);
      });
  }
}
