/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CurrencyConvertTOListResponse } from '../models/currency-convert-tolist-response';
import { CurrencyRateTOList } from '../models/currency-rate-tolist';
import { AverageCurrencyPriceRespTOListResponse } from '../models/average-currency-price-resp-tolist-response';
import { CurrencyConvertorForm } from '../models/currency-convertor-form';
import { CurrencyRateTOListResponse } from '../models/currency-rate-tolist-response';
import { CurrencyPriceTOList } from '../models/currency-price-tolist';
import { BaseCurrencyConvertorForm } from '../models/base-currency-convertor-form';

/**
 * Currency Convertor Controller
 */
@Injectable({
  providedIn: 'root',
})
class CurrencyConvertorControllerService extends __BaseService {
  static readonly listUsingGET8Path = '/api/v1/exchange';
  static readonly historyForAllCurrencyUsingGETPath = '/api/v1/exchange/all-rates-by-date';
  static readonly historyUsingGETPath = '/api/v1/exchange/history';
  static readonly convertUsingPOSTPath = '/api/v1/exchange/rate';
  static readonly rateByDateUsingGETPath = '/api/v1/exchange/rate-history';
  static readonly pricesUsingPOSTPath = '/api/v1/exchange/xrates';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * list
   * @return OK
   */
  listUsingGET8Response(): __Observable<__StrictHttpResponse<CurrencyConvertTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/exchange`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CurrencyConvertTOListResponse>;
      })
    );
  }
  /**
   * list
   * @return OK
   */
  listUsingGET8(): __Observable<CurrencyConvertTOListResponse> {
    return this.listUsingGET8Response().pipe(
      __map(_r => _r.body as CurrencyConvertTOListResponse)
    );
  }

  /**
   * historyForAllCurrency
   * @param date date
   * @return OK
   */
  historyForAllCurrencyUsingGETResponse(date: string): __Observable<__StrictHttpResponse<CurrencyRateTOList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (date != null) __params = __params.set('date', date.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/exchange/all-rates-by-date`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CurrencyRateTOList>;
      })
    );
  }
  /**
   * historyForAllCurrency
   * @param date date
   * @return OK
   */
  historyForAllCurrencyUsingGET(date: string): __Observable<CurrencyRateTOList> {
    return this.historyForAllCurrencyUsingGETResponse(date).pipe(
      __map(_r => _r.body as CurrencyRateTOList)
    );
  }

  /**
   * history
   * @param params The `CurrencyConvertorControllerService.HistoryUsingGETParams` containing the following parameters:
   *
   * - `dateTo`: dateTo
   *
   * - `dateFrom`: dateFrom
   *
   * - `curTo`: curTo
   *
   * - `curFrom`: curFrom
   *
   * @return OK
   */
  historyUsingGETResponse(params: CurrencyConvertorControllerService.HistoryUsingGETParams): __Observable<__StrictHttpResponse<AverageCurrencyPriceRespTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.dateTo != null) __params = __params.set('dateTo', params.dateTo.toString());
    if (params.dateFrom != null) __params = __params.set('dateFrom', params.dateFrom.toString());
    if (params.curTo != null) __params = __params.set('curTo', params.curTo.toString());
    if (params.curFrom != null) __params = __params.set('curFrom', params.curFrom.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/exchange/history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AverageCurrencyPriceRespTOListResponse>;
      })
    );
  }
  /**
   * history
   * @param params The `CurrencyConvertorControllerService.HistoryUsingGETParams` containing the following parameters:
   *
   * - `dateTo`: dateTo
   *
   * - `dateFrom`: dateFrom
   *
   * - `curTo`: curTo
   *
   * - `curFrom`: curFrom
   *
   * @return OK
   */
  historyUsingGET(params: CurrencyConvertorControllerService.HistoryUsingGETParams): __Observable<AverageCurrencyPriceRespTOListResponse> {
    return this.historyUsingGETResponse(params).pipe(
      __map(_r => _r.body as AverageCurrencyPriceRespTOListResponse)
    );
  }

  /**
   * convert
   * @param form form
   * @return OK
   */
  convertUsingPOSTResponse(form: CurrencyConvertorForm): __Observable<__StrictHttpResponse<CurrencyConvertTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/exchange/rate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CurrencyConvertTOListResponse>;
      })
    );
  }
  /**
   * convert
   * @param form form
   * @return OK
   */
  convertUsingPOST(form: CurrencyConvertorForm): __Observable<CurrencyConvertTOListResponse> {
    return this.convertUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as CurrencyConvertTOListResponse)
    );
  }

  /**
   * rateByDate
   * @param params The `CurrencyConvertorControllerService.RateByDateUsingGETParams` containing the following parameters:
   *
   * - `dateTo`: dateTo
   *
   * - `curTo`: curTo
   *
   * - `curFrom`: curFrom
   *
   * @return OK
   */
  rateByDateUsingGETResponse(params: CurrencyConvertorControllerService.RateByDateUsingGETParams): __Observable<__StrictHttpResponse<CurrencyRateTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.dateTo != null) __params = __params.set('dateTo', params.dateTo.toString());
    if (params.curTo != null) __params = __params.set('curTo', params.curTo.toString());
    if (params.curFrom != null) __params = __params.set('curFrom', params.curFrom.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/exchange/rate-history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CurrencyRateTOListResponse>;
      })
    );
  }
  /**
   * rateByDate
   * @param params The `CurrencyConvertorControllerService.RateByDateUsingGETParams` containing the following parameters:
   *
   * - `dateTo`: dateTo
   *
   * - `curTo`: curTo
   *
   * - `curFrom`: curFrom
   *
   * @return OK
   */
  rateByDateUsingGET(params: CurrencyConvertorControllerService.RateByDateUsingGETParams): __Observable<CurrencyRateTOListResponse> {
    return this.rateByDateUsingGETResponse(params).pipe(
      __map(_r => _r.body as CurrencyRateTOListResponse)
    );
  }

  /**
   * prices
   * @param form form
   * @return OK
   */
  pricesUsingPOSTResponse(form: BaseCurrencyConvertorForm): __Observable<__StrictHttpResponse<CurrencyPriceTOList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/exchange/xrates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CurrencyPriceTOList>;
      })
    );
  }
  /**
   * prices
   * @param form form
   * @return OK
   */
  pricesUsingPOST(form: BaseCurrencyConvertorForm): __Observable<CurrencyPriceTOList> {
    return this.pricesUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as CurrencyPriceTOList)
    );
  }
}

module CurrencyConvertorControllerService {

  /**
   * Parameters for historyUsingGET
   */
  export interface HistoryUsingGETParams {

    /**
     * dateTo
     */
    dateTo: string;

    /**
     * dateFrom
     */
    dateFrom: string;

    /**
     * curTo
     */
    curTo: string;

    /**
     * curFrom
     */
    curFrom: string;
  }

  /**
   * Parameters for rateByDateUsingGET
   */
  export interface RateByDateUsingGETParams {

    /**
     * dateTo
     */
    dateTo: string;

    /**
     * curTo
     */
    curTo: string;

    /**
     * curFrom
     */
    curFrom: string;
  }
}

export { CurrencyConvertorControllerService }
