import { Properties } from 'api/models';
import { UserService } from 'profile/services/user.service';

export class NFT {
  id!: number;
  walletId!: number;
  currencyId!: number;
  ownerId!: string;
  ownerName!: string;
  ownerOriginalName!: string;
  isReserved?: boolean;

  constructor(init: PickData<NFT> = dataToNFT({})) {
    Object.assign(this, init);
  }
}

export const dataToNFT = (
  data: Properties & { ownerAccId?: number; ownerId?: number },
  ref?: NFT
): PickData<NFT> => ({
  id: data.id || ref?.id || 0,
  walletId: data.walletId || ref?.walletId || 0,
  currencyId: data.currencyId || ref?.currencyId || 0,
  ownerId:
    data.ownerUuid ||
    accIdToString(data.ownerId) ||
    accIdToString(data.ownerAccId) ||
    ref?.ownerId ||
    '',
  ownerOriginalName: data.ownerName || ref?.ownerOriginalName || '',
  ownerName:
    (data.ownerAccId || data.ownerUuid) &&
    (UserService.instance.accountId === data.ownerAccId ||
      UserService.instance.id === data.ownerUuid)
      ? 'You' // TODO: translate
      : data.ownerName || ref?.ownerName || '',
  isReserved: typeof data.isReserved === 'boolean' ? data.isReserved : ref?.isReserved,
});

const accIdToString = (accId?: number) => (accId === 0 ? '' : accId?.toString());
