/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Subject } from 'rxjs';

import { ModalView } from '../data/modal.data';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'outlet-wrapper',
  template: `
    <router-outlet
      (activate)="handleOnActivate($event)"
      (deactivate)="handleOnDeactivate($event)"></router-outlet>
  `,
})
// OutletWrapperComponent is needed because when activating lazy component
// activateEvent will emits immediately on native ɵEmptyOutletComponent created
// but we need to know when target Component will be loaded and added
// also we need reference to such components but got only ɵEmptyOutletComponent reference by default
export class OutletWrapperComponent {
  @ViewChild(RouterOutlet, { static: true })
  outlet!: RouterOutlet;

  @ViewChild(RouterOutlet, { static: true, read: ElementRef })
  private outletElement!: ElementRef;

  currentElement?: HTMLElement;

  private onActivateSubj = new Subject<{ element: HTMLElement; component: ModalView }>();
  onActivate = this.onActivateSubj.asObservable();
  private onDeactivateSubj = new Subject<{
    element: HTMLElement;
    component: ModalView;
  }>();
  onDeactivate = this.onDeactivateSubj.asObservable();

  get isActivated(): boolean {
    return this.outlet.isActivated;
  }

  constructor(private renderer: Renderer2) {}

  handleOnActivate(e: ModalView): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const el = this.renderer.nextSibling(this.outletElement.nativeElement);
    if (el === this.currentElement) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this.currentElement = this.renderer.nextSibling(this.currentElement);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this.currentElement = el;
    }
    if (this.currentElement) {
      this.onActivateSubj.next({ element: this.currentElement, component: e });
    } else {
      throw new Error('[OutletWrapper] There is no Modal Component element');
    }
  }

  handleOnDeactivate(e: ModalView): void {
    if (this.currentElement) {
      this.onDeactivateSubj.next({ element: this.currentElement, component: e });
    } else {
      throw new Error('[OutletWrapper] There is no Modal Component element');
    }
  }
}
