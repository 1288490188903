<ng-container [formGroup]="nftFilterForm">
  <div class="filters">
    <div class="first-line">
      <app-nft-search
        [placeholderKey]="'wishlist-table-header.nft-search-street.placeholder'"
        [formControl]="nftFilterForm.controls.description"
        class="search-field item"></app-nft-search>

      <ng-container *mediaIf="'large'">
        <app-form-field class="item">
          <app-form-label>{{ 'marketplace.streets.filter.country' | translate }}</app-form-label>
          <app-country-search
            (selected)="pickCountry($event)"
            [countryCode]="countryCode"></app-country-search>
        </app-form-field>

        <app-form-field class="item">
          <app-form-label>{{ 'marketplace.streets.filter.city' | translate }}</app-form-label>

          <app-city-search
            (selected)="pickCity($event)"
            [cityId]="cityId"
            [countryCode]="countryCode"></app-city-search>
        </app-form-field>
      </ng-container>

      <app-mobile-filters
        (sortChange)="onSortChange($event)"
        *mediaIf="'small only' && 'medium down'"
        [headers]="headers"
        [sortData]="selectedData">
        <ng-container [ngTemplateOutlet]="responsiveFilters" additionalLayout></ng-container>
        <ng-container [ngTemplateOutlet]="handlerButtons" handleButtons></ng-container>
      </app-mobile-filters>
    </div>

    <div class="secondary-line" *mediaIf="'large'">
      <ng-container  [ngTemplateOutlet]="responsiveFilters"></ng-container>
    </div>
  </div>

  <ng-template #responsiveFilters>
    <app-nft-price-filters
      [formControl]="nftFilterForm.controls.price"
      class="price-range"></app-nft-price-filters>
    <app-nft-plan-filter [formControl]="nftFilterForm.controls.zones"
                         [isCollections]="isCollections" [enable5zone]="enable5zone" class="width-100"></app-nft-plan-filter>


    <ng-container *mediaIf="'small only' && 'medium down'">
      <div class="sm-item">
        <app-country-search
          (selected)="pickCountry($event)"
          [countryCode]="countryCode"></app-country-search>
      </div>
      <div class="sm-item">
        <app-city-search
          (selected)="pickCity($event)"
          [cityId]="cityId"
          [countryCode]="countryCode"></app-city-search>
      </div>
    </ng-container>
  </ng-template>
</ng-container>

<div *mediaIf="'large'" class="display-flex align-center width-100 flex-1 margin-bottom-lg">
  <ng-container [ngTemplateOutlet]="handlerButtons"></ng-container>

  <ng-container *ngIf="hasItems">
    <app-sort-header
      (sortChange)="onSortChange($event)"
      [headers]="headers"
      [sortData]="selectedData"
      class="data-header width-100"></app-sort-header>
  </ng-container>
</div>

<ng-template #handlerButtons>
  <ng-content></ng-content>
</ng-template>
