<app-street-thumb
  [imageUrl]="order.street.imageUrl"
  [isAvailable]="order.street.isAvailable"
  [level]="order.street.level"
  [mediaClass]="['large', 'small-thumb xsmall']"
  [title]="order.street.name"
  class="rounded column markets-asset"
  style="grid-area: asset">
  <ng-container *mediaIf="'medium down'">
    <ng-container>{{ order.street.secondaryName }}</ng-container>
  </ng-container>
</app-street-thumb>

<div *mediaIf="'large'" class="column markets-city">{{ order.street.city }}</div>
<div *mediaIf="'large'" class="column markets-country">{{ order.street.country }}</div>

<app-level-icon
  [level]="order.street.level"
  [price]="-1"
  class="column markets-level sm"
  style="grid-area: level"></app-level-icon>
<div class="column markets-request" style="grid-area: request">
  <div *mediaIf="'medium down'" class="title">
    {{ 'marketplace.ui.selling_item.your-request' | translate }}
  </div>
  {{ order.sellPrice | money: 'DWRLD':'—' }}
</div>

<div class="column markets-max-bid" style="grid-area: max-bid">
  <div *mediaIf="'medium down'" class="title">
    {{ 'marketplace.ui.selling_item.max-bid' | translate }}
  </div>
  {{ order.buyingPrice | money: 'DWRLD':'—' }}
</div>

<div class="column markets-action" style="grid-area: action">
  <ng-container
    *ngIf="order.street.canStake || !order.street.collectionCompleted; else stakingTmpl">
    <ng-container *ngIf="order.street.isEnabledActions">
      <button
        *ngIf="order.buyingPrice; else cancelTmpl"
        (click)="$event.stopPropagation(); onSellOrder()"
        appButton
        class="warn solid medium width-100">
        {{
          userService.user.accountId !== order.accountId
            ? ('dashboard.ui.selling_item.sell' | translate)
            : ('dashboard.ui.selling_item.view_order' | translate)
        }}
      </button>
      <ng-template #cancelTmpl>
        <button
          (click)="$event.stopPropagation(); openStreetOrder()"
          appButton
          class="solid primary medium width-100">
          {{ 'dashboard.ui.selling_item.cancel' | translate }}
        </button>
      </ng-template>
      <button
        *envIf="'dev'"
        (click)="$event.stopPropagation(); shareAction()"
        [appClearButton]="iconShare"
        class="margin-left-lg solid primary medium width-100 icon-md"></button>
    </ng-container>
  </ng-container>

  <ng-template #stakingTmpl>
    <button
      appButton
      [disabled]="true"
      class="buy-now warn solid medium width-100"
      style="grid-area: buy-now">
      <div class="display-flex">
        <app-icon
          class="margin-right-tn"
          [ngClass]="{ spin: !order.street.isStaked }"
          [icon]="order.street.isStaked ? lockIcon : spinIcon"></app-icon>
        {{
          order.street.isStaked
            ? ('marketplace.ui.selling_item.button.staked' | translate)
            : ('marketplace.ui.selling_item.button.confirming' | translate)
        }}
      </div>
    </button>
  </ng-template>
</div>

<!-- <app-menu
  [align]="'center'"
  [minWidth]="240"
  [ngClass]="{ 'hide-menu': !order.collectionId && userService.user.accountId !== order.accountId }"
  style="grid-area: menu">
  <button (click)="$event.stopPropagation()" appAnchor appButton class="clear-primary">
    <app-icon [icon]="ellipsisIcon" class="primary xhg"></app-icon>
  </button>
  <ng-template>
    <div class="data-host">
      <button
        (click)="onCancelOrder()"
        *ngIf="userService.user.accountId === order.accountId"
        [appButton]="closeIcon"
        [disabled]="isCancelling"
        appMenuItem
        class="solid for-select">
        {{ 'dashboard.ui.selling_item.cancel' | translate }}
      </button>

      <ng-container *ngIf="order.collectionId">
        <button
          (click)="openCollectionPreview()"
          [appButton]="collectionIcon"
          appMenuItem
          class="solid for-select">
          {{ 'dashboard.ui.street_item.button.view_collection' | translate }}
        </button>
        <button
          (click)="detachStreet()"
          [disabled]="isCancelling"
          [appButton]="closeIcon"
          appMenuItem
          class="solid for-select">
          {{ 'dashboard.ui.street_item.button.detach' | translate }}
        </button>
      </ng-container>
    </div>
  </ng-template>
</app-menu> -->
