<span class="margin-bottom-md">{{ 'dashboard.markets.buying.hint' | translate }}</span>

<app-table-header
  [hasItems]="orders.length !== 0"
  [headers]="headers"
  [lockPadding]="15"
  [scrollOffset]="32"
  scrollLock></app-table-header>

<div class="market-list">
  <ng-container *ngIf="orders.length !== 0">
    <app-buying-item
      (cancelOrder)="cancelOrder($event)"
      *ngFor="let order of orders; trackBy: identifyMarket"
      [order]="order"
      class="margin-bottom"></app-buying-item>
  </ng-container>

  <ng-container *ngIf="!orders.length && !inProgress">
    <app-no-data
      [canRedirect]="false"
      [text]="'dashboard.markets.buying.empty.text' | translate"
      [title]="'dashboard.markets.buying.empty.title' | translate"
      class="darken no-data-item"></app-no-data>
  </ng-container>
</div>

<app-pagination
  (changed)="nextPageAction($event)"
  *ngIf="pagination.totalPages && pagination.totalPages > 1"
  [current]="pagination.pageNumber || 1"
  [total]="pagination.totalPages"
  class="margin-top-lg">
  <ng-template let-current="isCurrent" let-page>
    <button (click)="nextPageAction(page.value)" [ngClass]="{ active: current }" class="page">
      {{ page.label }}
    </button>
  </ng-template>
</app-pagination>
