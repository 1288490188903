import {
  FormStyle,
  getLocaleDayNames,
  getLocaleMonthNames,
  TranslationWidth,
} from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
// TODO: add EU calendar format, for now it US, add filters, ranges and localizations
export class DatepickerComponent implements OnInit, OnDestroy {
  @Input()
  set date(d: Date | undefined) {
    this.selectedDate = d || new Date();
    this.dateChanged = !!d;
    if (this.dateChanged) {
      this.updateLastDate();
    }
    this.update();
  }
  get date(): Date | undefined {
    return this.selectedDate;
  }

  @ViewChild('yearsScroll', { static: true })
  yearsScrollRef!: ElementRef<HTMLElement>;

  @ViewChild('monthsScroll', { static: true })
  monthsScrollRef!: ElementRef<HTMLElement>;

  @Input()
  min?: Date;

  @Input()
  max?: Date;

  private selectedDate = new Date();

  @Output()
  changes = new EventEmitter<Date>();

  days: readonly string[] = [];
  months: readonly { label: string; disabled?: boolean }[] = [];

  dates: { label: number; current?: boolean; disabled?: boolean }[] = [];

  showYears = false;
  showMonths = false;
  dateChanged = false;

  get years(): { label: number; disabled?: boolean }[] {
    return DatepickerComponent._years;
  }

  get month(): number {
    return this.selectedDate.getMonth();
  }

  get year(): number {
    return this.selectedDate.getFullYear();
  }

  get dayOfMonth(): number {
    return this.dateChanged ? this.selectedDate.getDate() : -1;
  }

  private itemHeight = 40; // height + margin;
  private clickSub = new Subscription();
  private stopListClose?: boolean;
  private today = new Date();
  private lastDate = 1;
  private lastMonth = 0;
  private lastYear = 0;
  private static _years: { label: number; disabled?: boolean }[] = [];

  constructor() {
    this.days = getLocaleDayNames('en-US', FormStyle.Format, TranslationWidth.Abbreviated);
    this.months = getLocaleMonthNames('en-US', FormStyle.Format, TranslationWidth.Wide).map(m => ({
      label: m,
    }));
  }

  ngOnDestroy(): void {
    this.clickSub.unsubscribe();
  }

  ngOnInit(): void {
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.clickSub = fromEvent(window, 'click').subscribe(() => {
      if (!this.stopListClose) {
        this.showMonths = false;
        this.showYears = false;
      }
      this.stopListClose = false;
    });
    this.update();
  }

  private updateLastDate(): void {
    this.lastDate = this.selectedDate.getDate();
    this.lastMonth = this.month;
    this.lastYear = this.year;
  }

  stopClose(): void {
    this.stopListClose = true;
  }

  isToday(date: number): boolean {
    return (
      this.today.getDate() === date &&
      this.year === this.today.getFullYear() &&
      this.month === this.today.getMonth()
    );
  }

  setMonth(v: number): void {
    this.selectedDate.setMonth(v, 1);
    const daysInMonth = new Date(
      this.selectedDate.getFullYear(),
      this.selectedDate.getMonth() + 1,
      0
    ).getDate();
    this.selectedDate.setDate(Math.min(this.lastDate, daysInMonth));
    this.dateChanged = this.lastMonth === this.month && this.lastYear === this.year;
    this.showMonths = false;
    this.update();
  }

  setYear(v: number): void {
    this.selectedDate.setFullYear(v, this.month, 1);
    const daysInMonth = new Date(
      this.selectedDate.getFullYear(),
      this.selectedDate.getMonth() + 1,
      0
    ).getDate();
    this.selectedDate.setDate(Math.min(this.lastDate, daysInMonth));
    this.dateChanged = this.lastMonth === this.month && this.lastYear === this.year;
    this.showYears = false;
    this.update();
  }

  private update(): void {
    const viewDate = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth() + 1, 0);
    let lastDay = viewDate.getDate();
    viewDate.setDate(1);
    const daysBefore = viewDate.getDay();
    this.dates = [];
    for (let i = 0; i < lastDay; i++) {
      this.dates.push({
        label: i + 1,
        current: true,
        disabled: this.isDayDisabled(i + 1),
      });
    }
    viewDate.setDate(0);
    lastDay = viewDate.getDate();
    for (let i = 0; i < daysBefore; i++) {
      this.dates.unshift({
        label: lastDay - i,
        disabled: this.isDayDisabled(lastDay - i),
      });
    }
    const itemsLeft = this.dates.length;
    for (let i = 0; i < 42 - itemsLeft; i++) {
      this.dates.push({ label: i + 1, disabled: this.isDayDisabled(i + 1) });
    }
  }

  private isDayDisabled(day: number): boolean {
    if (this.min) {
      const date = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), day);
      return date <= this.min;
    } else if (this.max) {
      const date = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), day);
      return date >= this.max;
    }
    return false;
  }

  setDate(v: number): void {
    this.dateChanged = true;
    this.updateLastDate();
    this.selectedDate.setDate(v);
    this.changes.next(this.date);
  }

  toggleShowYears(): void {
    this.stopListClose = true;
    this.showYears = !this.showYears;
    this.showMonths = false;

    // move to virtual scroll and add more years
    // init years if not exists
    if (DatepickerComponent._years.length === 0) {
      for (let i = 1940; i < 2041; i++) {
        DatepickerComponent._years.push({ label: i });
      }
    }

    if (this.showYears) {
      let max = 0;
      let min = 0;
      if (this.max) {
        max = this.max.getFullYear();
      }
      if (this.min) {
        min = this.min.getFullYear();
      }
      if (min || max) {
        this.years.forEach(y => {
          y.disabled = (!!min && y.label < min) || (!!max && y.label > max);
        });
      }
      // TODO: scroll container may be null and scroll will complete
      requestAnimationFrame(() => {
        const index = this.years.findIndex(y => y.label === this.selectedDate.getFullYear());
        const scroll = this.yearsScrollRef.nativeElement.firstElementChild;
        const offset = index * this.itemHeight;
        scroll?.scrollTo({ top: offset });
      });
    }
  }

  toggleShowMonths(): void {
    this.stopListClose = true;
    this.showMonths = !this.showMonths;
    this.showYears = false;
    if (this.showMonths) {
      this.months.forEach((m, i) => {
        m.disabled = this.isMonthDisabled(i + 1);
      });
      // TODO: scroll container may be null and scroll will complete
      requestAnimationFrame(() => {
        const index = this.selectedDate.getMonth();
        const scroll = this.monthsScrollRef.nativeElement.firstElementChild;
        const offset = index * this.itemHeight;
        scroll?.scrollTo({ top: offset });
      });
    }
  }

  private isMonthDisabled(month: number): boolean {
    if (this.min) {
      const min = new Date(this.min);
      min.setDate(1);
      const date = new Date(this.selectedDate.getFullYear(), month, 1);
      return date < min;
    } else if (this.max) {
      const max = new Date(this.max);
      max.setDate(1);
      const date = new Date(this.selectedDate.getFullYear(), month, 1);
      return date > max;
    }
    return false;
  }
}
