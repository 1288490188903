import { Injectable } from '@angular/core';
import { UserMessageTOResponse } from 'api/models';
import { AppIcon } from 'core/icons/icon.type';
import { iconBell } from 'core/icons/lib/icon-bell';
import { iconCardCancelled } from 'core/icons/lib/icon-card-cancelled';
import { iconCardTick } from 'core/icons/lib/icon-card-tick';
import { iconDeposit } from 'core/icons/lib/icon-deposit';
import { iconDocumentText } from 'core/icons/lib/icon-document-text';
import { iconGift } from 'core/icons/lib/icon-gift';
import { iconLocationSuccess } from 'core/icons/lib/icon-location-success';
import { iconNFT } from 'core/icons/lib/icon-nft';
import { iconNFTSend } from 'core/icons/lib/icon-nft-send';
import { iconPartialCard } from 'core/icons/lib/icon-partial-card';
import { iconPartialNFT } from 'core/icons/lib/icon-partial-nft';
import { iconProfileCircle } from 'core/icons/lib/icon-profile-circle';
import { iconShieldSearch } from 'core/icons/lib/icon-shield-search';
import { iconTransferCardFilled } from 'core/icons/lib/icon-transfer-card-filled';
import { iconUserSuccess } from 'core/icons/lib/icon-user-success';
import { iconWithdrawal } from 'core/icons/lib/icon-withdrawal';
import { iconWithdrawalRejected } from 'core/icons/lib/icon-withdrawal-rejected';
import { I18nService } from 'core/modules/i18n/i18n.service';
import { PrecisionPipe } from 'core/pipes/core-pipes/precision.pipe';
import { CurrencyService } from 'core/services/currency.service';
import { NotificationTabType } from 'profile/enum/notification-tab-type';

import { NotificationMessageType } from '../enum/notification-message-type';
import { UserNotification } from '../models/notification.model';

const OLD_NOTIFICATIONS_PARAMS_MAP: Record<string, string[]> = {
  [NotificationMessageType.USER_WANT_BUY_NFT]: ['buyerUsername', 'street'],
  [NotificationMessageType.TOPIC_USER_INTERNAL_RECEIVED]: ['userNameFrom'],
  [NotificationMessageType.TOPIC_USER_INTERNAL_RECEIVE]: ['userNameFrom'],
  [NotificationMessageType.USER_INTERNAL_TRANSFER]: ['userNameTo'],
  [NotificationMessageType.ORDER_EXECUTED]: ['street', 'city', 'country'],
  [NotificationMessageType.COLLECTION_BALANCE_CHANGED]: ['amount', 'collectionName'],
};

type NotificationParams = {
  amount?: number | string;
  avgPrice?: number | string;
  currency?: string;
  street?: string;
  reason?: string;
  [key: string]: string | number | undefined;
};

interface NotificationText {
  title?: LocalizedString;
  short?: LocalizedString;
  long?: LocalizedString<{ [key: string]: string | number | undefined }>;
  currency?: string;
}
@Injectable({ providedIn: 'root' })
export class NotificationsFactory {
  private store: Map<number, UserNotification> = new Map();
  private pipe = new PrecisionPipe(this.currencyService);

  constructor(private currencyService: CurrencyService, private i18n: I18nService) {}

  get(data: Partial<UserMessageTOResponse> = {}): UserNotification {
    let notification = this.store.get(data.id || 0);
    if (notification) {
      return Object.assign(notification, this.dataToNotification(data));
    } else {
      notification = new UserNotification(this.dataToNotification(data));
      this.store.set(notification.id, notification);
      return notification;
    }
  }

  private dataToNotification(
    data: Partial<UserMessageTOResponse> = {}
  ): PickData<UserNotification> {
    const messageParams =
      typeof data.params === 'string'
        ? (JSON.parse(data.params || '{}') as NotificationParams)
        : { ...(data.params || {}) };
    const { title, short, long, currency } = this.getText(
      data.messageType as NotificationMessageType,
      messageParams
    );
    return {
      id: data.id || 0,
      date: new Date(data.createdAt || 0),
      isRead: data.isRead,
      icon: this.getIcon(data.messageType as NotificationMessageType, currency),
      title,
      short,
      long,
      type: data.messageType as NotificationMessageType,
      tabType: data.tabType as NotificationTabType,
      params: messageParams,
    };
  }

  private getText(type: NotificationMessageType, params: NotificationParams): NotificationText {
    if (type === 'ACHIEVEMENT_CHANGED' && params.level) {
      return {
        short: $t(
          params?.level > 1
            ? 'profile.notifications.short.achievement_up'
            : 'profile.notifications.short.achievement_unlocked'
        ),
        long: $t(
          params?.level > 1
            ? 'profile.notifications.long.achievement_up'
            : 'profile.notifications.long.achievement_unlocked',
          {
            level: params.level,
            achievementType: this.i18n.get(`profile.achievement.${params.achievementType}.title`),
          }
        ) as LocalizedString<{ [key: string]: string | number | undefined }>,
      };
    }

    if (params.amount && (params.currency || params.currencyCode)) {
      params.amount =
        this.pipe.transform(+params.amount, params.currency || (params.currencyCode as string)) ||
        undefined;
    }
    if (params.street && !params.currency) {
      params.currency = 'DWRLD';
    }

    if (params.side) {
      params.side = String(params.side).toLowerCase();
    }

    if (params.price && params.currency) {
      params.price =
        this.pipe.transform(+params.price, params.currency === 'NFT' ? 'DWRLD' : params.currency) ||
        undefined;
    }
    if (params.avgPrice && params.currency) {
      params.avgPrice =
        this.pipe.transform(
          +params.avgPrice,
          params.currency === 'NFT' ? 'DWRLD' : params.currency
        ) || undefined;
    }

    const suffix = this.getSuffix(type, params);
    return {
      title: $t(
        params.currency === 'NFT'
          ? `profile.notifications.${type.toLowerCase()}_nft`
          : `profile.notifications.${type.toLowerCase()}`,
        params
      ),
      short: $t(
        params.currency === 'NFT'
          ? `profile.notifications.short${suffix}.${type.toLowerCase()}_nft${
              type === 'ORDER_EXECUTED' ? '_' + String(params.side).toLowerCase() : ''
            }`
          : `profile.notifications.short${suffix}.${type.toLowerCase()}`,
        params
      ),
      long: $t(
        params.currency === 'NFT'
          ? `profile.notifications.long${suffix}.${type.toLowerCase()}_nft${
              type === 'ORDER_EXECUTED' ? '_' + String(params.side).toLowerCase() : ''
            }`
          : `profile.notifications.long${suffix}.${type.toLowerCase()}`,
        params
      ) as LocalizedString<{ [key: string]: string | number | undefined }>,
      currency: params.currency,
    };
  }

  private getIcon(type: NotificationMessageType, currency?: string): AppIcon {
    let result = iconBell;
    if (currency && currency === 'NFT') {
      type = `${type}_NFT` as NotificationMessageType;
    }
    switch (type) {
      case 'MYSTERY_BOX_OPEN':
        result = iconGift;
        break;
      case 'WITHDRAWAL_REJECTED':
        result = iconWithdrawalRejected;
        break;
      case 'WITHDRAWAL_ACCEPTED':
      case 'WITHDRAWAL_COMPLIANCE_CHECK':
      case 'WITHDRAWAL_PAID_OUT':
        result = iconWithdrawal;
        break;

      case 'ORDER_OPENED':
        result = iconCardTick;
        break;
      case 'ORDER_CANCELLED':
        result = iconCardCancelled;
        break;
      case 'ORDER_CANCELLED_NFT':
        result = iconCardCancelled;
        break;
      case 'ORDER_PARTIALLY_FILLED':
        result = iconPartialCard;
        break;
      case 'ORDER_PARTIALLY_FILLED_NFT':
        result = iconPartialNFT;
        break;
      case 'DEPOSIT_RECEIVED':
        result = iconDeposit;
        break;
      case 'KYS_STATUS_CHANGED':
        result = iconShieldSearch;
        break;
      case 'INCOMING_DIVIDEND':
      case 'INCOMING_REFERRAL_COMMISSION':
        result = iconUserSuccess;
        break;
      case 'DOCUMENT_REQUEST':
        result = iconDocumentText;
        break;
      case 'INITIAL_NFT_BUY':
      case 'ORDER_EXECUTED_NFT':
      case 'ORDER_OPENED_NFT':
      case 'ORDER_CLOSED_NFT':
      case 'STREET_SELL':
        result = iconLocationSuccess;
        break;
      case 'USER_WANT_BUY_NFT':
        result = iconProfileCircle;
        break;
      case 'TOPIC_NFT_RECEIVED':
        result = iconNFT;
        break;
      case 'TOPIC_NFT_SEND':
        result = iconNFTSend;
        break;
      case 'TOPIC_USER_INTERNAL_RECEIVED':
      case 'TOPIC_USER_INTERNAL_RECEIVE':
      case 'USER_INTERNAL_TRANSFER':
        result = iconTransferCardFilled;
        break;
    }
    return result;
  }

  private getSuffix(type: NotificationMessageType, params: NotificationParams): string {
    const suffixes = [
      this.getOldSuffixType(type, params),
      this.getNftSuffixType(type, params),
    ].filter(suffix => Boolean(suffix));

    return suffixes.length ? `.${suffixes.join('.')}` : '';
  }

  private getOldSuffixType(type: NotificationMessageType, params: NotificationParams): string {
    const oldTypeMap = OLD_NOTIFICATIONS_PARAMS_MAP[type];
    if (oldTypeMap) {
      const hasParams = oldTypeMap.every(key => params[key]);

      return hasParams ? '' : 'old';
    } else if (type === NotificationMessageType.REFERRAL_PURCHASE && params.reason) {
      return `.${params.reason.toLowerCase()}`;
    }

    return '';
  }

  private getNftSuffixType(type: NotificationMessageType, params: NotificationParams): string {
    if (type === NotificationMessageType.INITIAL_NFT_BUY) {
      const isBuilding = (params as { dtype: 'Nft' | 'BuildingNft' }).dtype === 'BuildingNft';

      return isBuilding ? 'building' : '';
    }

    return '';
  }

  emptyStore(): void {
    this.store.clear();
  }
}
