import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { OrderControllerService } from 'api/services';
import {
  HistoryFilterData,
  HistoryFilterForm, historyNftTypes,
  historySides,
  historyTypes,
} from 'app/marketplace/forms/history-filter.form';
import {
  HistoryFilterParams,
  MarketplaceHistoryService,
} from 'app/marketplace/services/marketplace-history.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import HistoryUsingGET1Params = OrderControllerService.HistoryUsingGET1Params;
import {NodeUtils} from "three/examples/jsm/nodes/core/NodeUtils";
import elements = NodeUtils.elements;

@Component({
  selector: 'app-filter-history-form',
  templateUrl: './filter-history-form.component.html',
  styleUrls: ['./filter-history-form.component.scss'],
})
export class FilterHistoryFormComponent implements OnInit, OnDestroy {
  @Output() emitHistoryFilter = new EventEmitter<HistoryFilterParams>();
  readonly sides = historySides;
  readonly types = historyTypes;
  readonly nftTypesList = historyNftTypes;
  form: HistoryFilterForm;
  submitted?: boolean;
  private inProgress?: boolean;
  private destroy$ = new Subject();

  constructor(private historyService: MarketplaceHistoryService) {
    this.form = new HistoryFilterForm();
  }

  get cantSubmit(): boolean | undefined {
    return this.inProgress || (this.form.invalid && this.submitted);
  }

  ngOnInit(): void {
    this.form.patchValue({
      orderType: this.types[0],
      side: this.sides[0],
      nftType: this.nftTypesList[0]
    });
    this.historyService
      .getQueryParams()
      .pipe(takeUntil(this.destroy$))
      .subscribe(requestData => {
        this.form.controls.closeFrom.setValue(
          requestData?.filter?.closedFrom
            ? (new Date(requestData.filter.closedFrom) as any)
            : undefined,
          {
            emitEvent: false,
          }
        );

        this.form.controls.closeTo.setValue(
          requestData?.filter?.closedTo
            ? (new Date(requestData.filter.closedTo) as any)
            : undefined,
          {
            emitEvent: false,
          }
        );

        const type = this.types.find(element => element.item === requestData.filter.orderType);
        const side = this.sides.find(element => element.item === requestData.filter.side);
        const nftType = this.nftTypesList.find(element => element.item === requestData.filter.nftType);

        this.form.controls.orderType.setValue(type, { emitEvent: false });
        this.form.controls.side.setValue(side, { emitEvent: false });
        this.form.controls.nftType.setValue(nftType, { emitEvent: false });
      });
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      const params = this.prepareFilterParams(value);
      this.emitHistoryFilter.emit(params);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  submit(): void {
    if (this.form.invalid) {
      this.submitted = true;
      return;
    }

    const params = this.prepareFilterParams(this.form.value);
    this.emitHistoryFilter.emit(params);
  }

  private prepareFilterParams(params: HistoryFilterData): HistoryFilterParams {
    const dateFrom = params.closeFrom ? new Date(params.closeFrom) : undefined;
    const dateTo = params.closeTo ? new Date(params.closeTo) : undefined;

    if (dateFrom) {
      dateFrom.setHours(0, 0, 0, 0);
    }

    if (dateTo) {
      dateTo.setHours(0, 0, 0, 0);
      dateTo.setDate(dateTo.getDate() + 1);
    }

    const result: HistoryFilterParams = {
      side: params.side.item,
      nftType: params.nftType.item,
      orderType: params.orderType.item,
      closedFrom: dateFrom?.getTime(),
      closedTo: dateTo?.getTime(),
      pageSize: 15,
      pageNumber: 0,
    };

    return result;
  }
}
