import { Injectable } from '@angular/core';
import { DwrldControllerService } from 'api/services';
import { StateClearService, StateFullService } from 'app/_main/services/state-clear.service';
import { SocketService } from 'core/modules/socket/socket.service';
import {
  EventType,
  OrderClosedEventData,
  OrderOpenedEventData,
  UserMessageReceived,
} from 'dashboard/services/dashboard.service';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';

export interface MarketsStatistic {
  totalMarkets: number;
  totalBuy: number;
  totalSell: number;
  totalOffersReceived: number;
}

@Injectable({
  providedIn: 'root',
})
export class MarketplaceMarketsService implements StateFullService {
  private marketsStatistic$ = new BehaviorSubject<MarketsStatistic>({
    totalBuy: 0,
    totalMarkets: 0,
    totalSell: 0,
    totalOffersReceived: 0,
  });

  constructor(
    private ordersApi: DwrldControllerService,
    private socket: SocketService,
    private stateClearService: StateClearService
  ) {
    this.stateClearService.register(this);
  }

  observeMarketStatisticEvents(): void {
    this.socket
      .on<{
        eventType: EventType;
        data: OrderOpenedEventData | OrderClosedEventData | UserMessageReceived;
      }>()
      .subscribe(response => {
        if (response.eventType === 'ORDER_OPENED' || response.eventType === 'ORDER_CLOSED') {
          const data = response.data as OrderClosedEventData | OrderOpenedEventData;

          if (data.nftId) {
            this.loadMarketStatistic();
          }
        }

        if (response.eventType === 'USER_MESSAGE_RECEIVED') {
          const data = response.data as UserMessageReceived;
          if (data.messageType === 'USER_WANT_BUY_NFT') {
            this.loadOffersReceivedStatistic();
          }
        }
      });
  }

  loadOrdersOpenedClosedStatistic(): void {
    forkJoin([
      this.ordersApi.ordersUsingGET({ side: 'BUY', pageSize: 0, zones: [1, 2, 3, 4] }),
      this.ordersApi.ordersUsingGET({ side: 'SELL', pageSize: 0, zones: [1, 2, 3, 4] }),
    ]).subscribe(([buyData, sellData]) => {
      const totalBuy = Number(buyData.pageInfo?.totalElements);
      const totalSell = Number(sellData.pageInfo?.totalElements);
      const statistic = this.marketsStatistic$.getValue();
      this.marketsStatistic$.next({
        ...statistic,
        totalBuy: Number(buyData.pageInfo?.totalElements),
        totalSell: Number(sellData.pageInfo?.totalElements),
        totalMarkets: totalBuy + totalSell + statistic.totalOffersReceived,
      });
    });
  }

  loadOffersReceivedStatistic(): void {
    this.ordersApi
      .ordersUsingGET({ side: 'BUY_RECEIVED', pageSize: 0, zones: [1, 2, 3, 4] })
      .subscribe(offersReceived => {
        const totalOffersReceived = Number(offersReceived.pageInfo?.totalElements);
        const statistic = this.marketsStatistic$.getValue();
        this.marketsStatistic$.next({
          ...statistic,
          totalOffersReceived,
          totalMarkets: statistic.totalBuy + statistic.totalSell + totalOffersReceived,
        });
      });
  }

  loadMarketStatistic(): void {
    forkJoin([
      this.ordersApi.ordersUsingGET({ side: 'BUY', pageSize: 0, zones: [1, 2, 3, 4] }),
      this.ordersApi.ordersUsingGET({ side: 'SELL', pageSize: 0, zones: [1, 2, 3, 4] }),
      this.ordersApi.ordersUsingGET({ side: 'BUY_RECEIVED', pageSize: 0, zones: [1, 2, 3, 4] }),
    ]).subscribe(([buyData, sellData, buyReceived]) => {
      const totalBuy = Number(buyData.pageInfo?.totalElements);
      const totalSell = Number(sellData.pageInfo?.totalElements);
      const totalOffersReceived = Number(buyReceived.pageInfo?.totalElements);
      this.marketsStatistic$.next({
        totalBuy,
        totalSell,
        totalOffersReceived,
        totalMarkets: totalBuy + totalSell + totalOffersReceived,
      });
    });
  }

  getMarketsStatisticData(): Observable<MarketsStatistic> {
    return this.marketsStatistic$.asObservable();
  }

  clearState(): void {
    this.marketsStatistic$.next({
      totalBuy: 0,
      totalMarkets: 0,
      totalOffersReceived: 0,
      totalSell: 0,
    });
  }
}
