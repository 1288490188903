import { Injectable } from '@angular/core';
import { AccountBalanceTimeLineResponseEntryTO } from 'api/models/account-balance-time-line-response-entry-to';
import { FinanceUserDataTO } from 'api/models/finance-user-data-to';
import { HeartBeatTO } from 'api/models/heart-beat-to';
import { NftStatisticTOResponse } from 'api/models/nft-statistic-toresponse';
import {
  NftControllerService,
  StatisticControllerService,
  WalletControllerService,
} from 'api/services';
import { StateClearService, StateFullService } from 'app/_main/services/state-clear.service';
import { WalletsService } from 'cashier/services/wallets.service';
import { SocketService } from 'core/modules/socket/socket.service';
import {
  DurationType,
  ResolutionType,
} from 'dashboard/components/_ui/dashboard-finance-data/dashboard-finance-data.component';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export type EventType =
  | 'ORDER_OPENED'
  | 'ORDER_CLOSED'
  | 'USER_MESSAGE_RECEIVED'
  | 'NFT_BUY'
  | 'MYSTERY_BOX';

export interface OrderOpenedEventData {
  id: number;
  accountId: number;
  marketId: number;
  initialAmount: number;
  nftId: number | null;
  price: number;
  side: 'UNKNOWN_SIDE' | 'BUY' | 'SELL' | 'UNRECOGNIZED';
  type: 'UNKNOWN_ORDER_TYPE' | 'MARKET' | 'LIMIT' | 'MARKET_MARGIN_CALL' | 'UNRECOGNIZED';
}

export interface OrderClosedEventData {
  id: number;
  accountId: number;
  marketId: number;
  initialAmount: number;
  price: number;
  side: 'UNKNOWN_SIDE' | 'BUY' | 'SELL' | 'UNRECOGNIZED';
  type: 'UNKNOWN_ORDER_TYPE' | 'MARKET' | 'LIMIT' | 'MARKET_MARGIN_CALL' | 'UNRECOGNIZED';
  nftId: number;
  closeReason:
    | 'UNKNOWN_CLOSE_REASON'
    | 'COMPLETED'
    | 'CANCELLED_BY_OWNER'
    | 'CANCELLED_BY_ADMIN'
    | 'ERROR,PENDING_CANCEL'
    | 'MARKET_ORDER_NOT_FILLED'
    | 'PARTIALLY_FILLED'
    | 'EMPTY_AMOUNT'
    | 'TOO_LOW_AMOUNT'
    | 'TOO_LOW_MATCH'
    | 'MARKET_DISABLED'
    | 'TIME_EXPIRED'
    | 'OPEN'
    | 'PENDING_MARKET_DISABLED'
    | 'UNRECOGNIZED';
  closedAt: number;
}

export interface UserMessageReceived {
  id: number;
  accountId: number;
  createdAt: number;
  readAt: number;
  read: boolean;
  messageType: string;
  params: string;
  groupId: number;
}

@Injectable({ providedIn: 'root' })
export class DashboardService implements StateFullService {
  private heartBeat = new BehaviorSubject<HeartBeatTO>({
    rateDwrld: 0,
    totalOnline: 0,
    percentSold: 0,
    tradingVolume: 0,
    streetSoldCount: 0,
    currentDwrldPrice: 0,
  });
  private streetsProgress = new BehaviorSubject<NftStatisticTOResponse>({
    totalNfts: 0,
    totalPrice: 0,
    zone1Amount: 0,
    zone4Amount: 0,
    zone3Amount: 0,
    zone2Amount: 0,
    totalZone1Price: 0,
    totalZone2Price: 0,
    totalZone3Price: 0,
    totalZone4Price: 0,
    totalCollections: 0,
  });

  private financeDataDuration = new BehaviorSubject<{
    duration: DurationType;
    resolution: ResolutionType;
  }>({
    duration: 'MONTH',
    resolution: 'DAYS',
  });
  private financeDataChart = new BehaviorSubject<AccountBalanceTimeLineResponseEntryTO[]>([]);
  private financeDataUser = new BehaviorSubject<FinanceUserDataTO>({
    totalDeposit: 0,
    totalAssetsValue: 0,
    totalWithdrawals: 0,
  });

  constructor(
    private nftApiService: NftControllerService,
    private walletsService: WalletsService,
    private walletApiService: WalletControllerService,
    private statisticApiService: StatisticControllerService,
    private stateClearService: StateClearService,
    private socket: SocketService
  ) {
    this.stateClearService.register(this);
  }

  observeDashboardStatisticEvents(): void {
    this.socket
      .on<{
        eventType: EventType;
        data: OrderOpenedEventData | OrderClosedEventData | UserMessageReceived;
      }>()
      .subscribe(response => {
        if (response.eventType === 'ORDER_OPENED' || response.eventType === 'ORDER_CLOSED') {
          const data = response.data as OrderOpenedEventData | OrderClosedEventData;
          if (data.nftId) {
            this.loadStreetsProgressStatistic();
          }
        }
        if (response.eventType === 'USER_MESSAGE_RECEIVED') {
          const data = response.data as UserMessageReceived;
          if (
            data.messageType === 'INITIAL_NFT_BUY' ||
            data.messageType === 'TOPIC_NFT_SEND' ||
            data.messageType === 'TOPIC_NFT_RECEIVED'
          ) {
            this.loadStreetsProgressStatistic();
          }
        }
      });
  }

  loadDashboard(): void {
    this.statisticApiService.getHearBeatUsingGET().subscribe(statistic => {
      this.heartBeat.next(statistic);
    });
  }

  setFinanceDataDuration(duration: DurationType, resolution: ResolutionType) {
    this.financeDataDuration.next({
      duration,
      resolution,
    });
  }

  getFinanceDataDuration(): Observable<{ duration: DurationType; resolution: ResolutionType }> {
    return this.financeDataDuration.asObservable();
  }

  getFinanceDataChart(): Observable<AccountBalanceTimeLineResponseEntryTO[]> {
    return this.financeDataChart.asObservable();
  }

  getFinanceDataUser(): Observable<FinanceUserDataTO> {
    return this.financeDataUser.asObservable();
  }

  loadStreetsProgressStatistic(): void {
    this.nftApiService.statisticUsingGET().subscribe(streetsProgress => {
      this.streetsProgress.next(streetsProgress);
    });
  }

  loadFinanceData() {
    this.getFinanceDataDuration()
      .pipe(
        switchMap(chartSettings =>
          forkJoin([
            this.statisticApiService.getUserFinanceDataUsingGET(chartSettings.duration),
            this.walletApiService.timeLineUsingPOST({
              period: chartSettings.duration,
              resolution: chartSettings.resolution,
              walletId: this.walletsService.nftWalletId,
            }),
          ])
        )
      )
      .subscribe(([userData, chart]) => {
        this.financeDataUser.next(userData);
        this.financeDataChart.next(chart);
      });
  }

  getStreetsProgress(): Observable<NftStatisticTOResponse> {
    return this.streetsProgress.asObservable();
  }

  getDwrldStatistic(): Observable<HeartBeatTO> {
    return this.heartBeat.asObservable();
  }

  clearState(): void {
    this.streetsProgress.next({
      totalNfts: 0,
      totalPrice: 0,
      zone1Amount: 0,
      zone4Amount: 0,
      zone3Amount: 0,
      zone2Amount: 0,
      totalZone1Price: 0,
      totalZone2Price: 0,
      totalZone3Price: 0,
      totalZone4Price: 0,
      totalCollections: 0,
    });
  }
}
