/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DocumentRequestsTOResponseList } from '../models/document-requests-toresponse-list';
import { DocumentRequestsTOResponse } from '../models/document-requests-toresponse';

/**
 * Document Request Controller
 */
@Injectable({
  providedIn: 'root',
})
class DocumentRequestControllerService extends __BaseService {
  static readonly listUsingGET9Path = '/api/v1/document/requests';
  static readonly updateDocumentRequestUsingPOSTPath = '/api/v1/document/requests';
  static readonly downloadAttachmentUsingGETPath = '/api/v1/document/requests/{requestId}/attachments/{attachmentId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get document requests list
   * @param transactionId transactionId
   * @return OK
   */
  listUsingGET9Response(transactionId?: number): __Observable<__StrictHttpResponse<DocumentRequestsTOResponseList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (transactionId != null) __params = __params.set('transactionId', transactionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/document/requests`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DocumentRequestsTOResponseList>;
      })
    );
  }
  /**
   * Get document requests list
   * @param transactionId transactionId
   * @return OK
   */
  listUsingGET9(transactionId?: number): __Observable<DocumentRequestsTOResponseList> {
    return this.listUsingGET9Response(transactionId).pipe(
      __map(_r => _r.body as DocumentRequestsTOResponseList)
    );
  }

  /**
   * Update document request
   * @param params The `DocumentRequestControllerService.UpdateDocumentRequestUsingPOSTParams` containing the following parameters:
   *
   * - `responseText`:
   *
   * - `documentRequestId`:
   *
   * - `attachmentsToRemove[0].id`:
   *
   * - `attachmentsToRemove[0].documentRequestId`:
   *
   * - `attachments`:
   *
   * - `adminName`:
   *
   * @return OK
   */
  updateDocumentRequestUsingPOSTResponse(params: DocumentRequestControllerService.UpdateDocumentRequestUsingPOSTParams): __Observable<__StrictHttpResponse<DocumentRequestsTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.responseText != null) { __formData.append('responseText', params.responseText as string | Blob);}
    if (params.documentRequestId != null) { __formData.append('documentRequestId', JSON.stringify(params.documentRequestId));}
    if (params.attachmentsToRemove0Id != null) { __formData.append('attachmentsToRemove[0].id', JSON.stringify(params.attachmentsToRemove0Id));}
    if (params.attachmentsToRemove0DocumentRequestId != null) { __formData.append('attachmentsToRemove[0].documentRequestId', JSON.stringify(params.attachmentsToRemove0DocumentRequestId));}
    (params.attachments || []).forEach(val => {if (val != null) __formData.append('attachments', val as string | Blob)});
    if (params.adminName != null) { __formData.append('adminName', params.adminName as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/document/requests`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DocumentRequestsTOResponse>;
      })
    );
  }
  /**
   * Update document request
   * @param params The `DocumentRequestControllerService.UpdateDocumentRequestUsingPOSTParams` containing the following parameters:
   *
   * - `responseText`:
   *
   * - `documentRequestId`:
   *
   * - `attachmentsToRemove[0].id`:
   *
   * - `attachmentsToRemove[0].documentRequestId`:
   *
   * - `attachments`:
   *
   * - `adminName`:
   *
   * @return OK
   */
  updateDocumentRequestUsingPOST(params: DocumentRequestControllerService.UpdateDocumentRequestUsingPOSTParams): __Observable<DocumentRequestsTOResponse> {
    return this.updateDocumentRequestUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as DocumentRequestsTOResponse)
    );
  }

  /**
   * Download document request attachment
   * @param params The `DocumentRequestControllerService.DownloadAttachmentUsingGETParams` containing the following parameters:
   *
   * - `requestId`: requestId
   *
   * - `attachmentId`: attachmentId
   */
  downloadAttachmentUsingGETResponse(params: DocumentRequestControllerService.DownloadAttachmentUsingGETParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/document/requests/${encodeURIComponent(String(params.requestId))}/attachments/${encodeURIComponent(String(params.attachmentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Download document request attachment
   * @param params The `DocumentRequestControllerService.DownloadAttachmentUsingGETParams` containing the following parameters:
   *
   * - `requestId`: requestId
   *
   * - `attachmentId`: attachmentId
   */
  downloadAttachmentUsingGET(params: DocumentRequestControllerService.DownloadAttachmentUsingGETParams): __Observable<null> {
    return this.downloadAttachmentUsingGETResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module DocumentRequestControllerService {

  /**
   * Parameters for updateDocumentRequestUsingPOST
   */
  export interface UpdateDocumentRequestUsingPOSTParams {
    responseText?: string;
    documentRequestId?: number;
    attachmentsToRemove0Id?: number;
    attachmentsToRemove0DocumentRequestId?: number;
    attachments?: Array<Blob>;
    adminName?: string;
  }

  /**
   * Parameters for downloadAttachmentUsingGET
   */
  export interface DownloadAttachmentUsingGETParams {

    /**
     * requestId
     */
    requestId: number;

    /**
     * attachmentId
     */
    attachmentId: number;
  }
}

export { DocumentRequestControllerService }
