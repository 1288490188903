<div class="data-container container-column dark">
  <div class="width-100 content-container">
    <div class="title">
      <h3 class="color-white text-left">{{ 'checkout.title' | translate }}</h3>
    </div>

    <div class="container dc-scroll-light">
      <ng-container *mediaIf="'large'">
        <div *ngIf="items.length" class="table-header">
          <div class="column asset">{{ 'checkout.table.asset' | translate }}</div>
          <div class="column price">{{ 'checkout.table.price' | translate }}</div>
          <div class="column type">{{ 'checkout.table.type' | translate }}</div>
          <div class="column action"></div>
        </div>
      </ng-container>
      <div class="table-content">
        <ng-container *ngFor="let item of items">
          <ng-container *ngIf="item.street">
            <ng-container *ngIf="item.nestedItems.length; else singularStreet">
              <app-buildings-heap-checkout-item
                (removeStreetEmitter)="removeGroupedBuildings({ street: item.street, items: [] })"
                (removeBuildingEmitter)="removeGroupedBuildings($event)"
                [street]="item.street"
                [status]="purchaseStatusMap[item.street.cartId]"
                [buildings]="item.nestedItems"></app-buildings-heap-checkout-item>
            </ng-container>
            <ng-template #singularStreet>
              <app-checkout-item
                [item]="item.street"
                [status]="purchaseStatusMap[item.street.cartId]"
                (removeEmitter)="remove(item.street)"
                class="table-item"></app-checkout-item>
            </ng-template>
          </ng-container>

          <!-- <app-checkout-item
            [item]="item.street"
            [status]="purchaseStatusMap[item.street.cartId]"
            (removeEmitter)="remove(item.street)"
            class="table-item"></app-checkout-item> -->

          <app-checkout-item
            *ngIf="item.building"
            [item]="item.building"
            [status]="purchaseStatusMap[item.building.cartId]"
            (removeEmitter)="remove(item.building)"
            class="table-item"></app-checkout-item>

          <app-collection-checkout-item
            *ngIf="item.collection"
            [collection]="item.collection"
            [streets]="item.nestedStreets"
            [price]="item.price"
            [status]="purchaseStatusMap[item.collection.cartId]"
            (removeEmitter)="removeCollection(item.collection)"
            (removeStreetEmitter)="removeCollection(item.collection, $event)"
            class="table-item collection-asset"></app-collection-checkout-item>
        </ng-container>
      </div>
      <app-no-data
        (redirectEmitter)="noDataRedirect()"
        *ngIf="!items.length && !inProgress"
        [text]="'checkout.empty.text' | translate"
        [title]="'checkout.empty.title' | translate"></app-no-data>
    </div>
  </div>
  <div *ngIf="items.length" [class.sticky]="true" class="width-100">
    <div
      [formGroup]="form"
      [ngClass]="{ 'force-error': isInvalid && submitted }"
      class="margin-top-md spend">
      <div class="color-primary-light font-md text-left">
        {{ 'checkout.spend.title' | translate }}
      </div>
      <app-form-field class="margin-top">
        <app-select [formControl]="walletControl" class="width-100">
          <ng-template>
            <button
              (click)="$event.stopPropagation()"
              *ngFor="let wallet of walletsService.wallets"
              [appSelectItem]="wallet"
              appButton
              class="solid for-select">
              <div class="display-flex align-center">
                <app-svg
                  [asset]="['currency', wallet.currency.code || '']"
                  class="round margin-right"></app-svg>
                {{ wallet.currency.name }} ({{
                  wallet.available | precision: wallet.currency.code
                }})
              </div>
            </button>
          </ng-template>
          <app-number-input
            [disable]="true"
            appAnchor
            class="solid primary-dark with-balance"
            extras="dropdown">
            <app-svg
              [asset]="['currency', walletControl.value.currency.code]"
              [label]="
                walletControl.value.isBonus ? 'Decent Bonus' : walletControl.value.currency.code
              "
              class="round light"></app-svg>
            <span class="input-balance">
              {{ walletControl.value.currency.name }}
              {{ walletControl.value.available | precision: walletControl.value.currency.code }}
            </span>
          </app-number-input>
        </app-select>
      </app-form-field>
    </div>
    <div>
      <div class="bonuses display-flex justify-end align-center">
        <div class="display-flex margin-right-auto">
          <div class="margin-right-lg">
            <app-icon [icon]="iconDiamond" class="bonus-icon"></app-icon>
          </div>
          <div>
            <div class="text-left">{{ 'checkout.spend.decent_bonus' | translate }}</div>
            <div class="margin-top-tn color-primary-light text-left">
              +{{ bonuses | number: '.2-2' }}
            </div>
          </div>
        </div>
        <div class="text-right">
          <div>{{ 'checkout.spend.total' | translate }}:</div>
          <div class="color-primary-light font-xhg margin-top-tn total-count">
            {{ total | dwrldTo: walletControl.value.currency.code }}
            {{
              walletControl.value.currency.code === 'DPC' ? '' : walletControl.value.currency.code
            }}
          </div>
        </div>
      </div>
      <div class="footer">
        <button
          (click)="purchaseAction()"
          [disabled]="inProgress || (isInvalid && submitted) || total === 0"
          appButton
          class="solid primary medium width-100 purchase-button">
          {{ 'checkout.spend.purchase' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
