/* eslint-disable prefer-arrow-functions/prefer-arrow-functions */
import { createNavigation } from 'core/utils/router.utils';

export const streetsRoot = 'map';

export interface StreetsMapNavState {
  streetId: number;
  buildingId: number;
}

export function map(): string {
  return '';
}

export const paths = {
  map,
};

// modals
export function streetPreviewModal(): string {
  return 'street-preview';
}

export function streetSellModal(): string {
  return 'street-sell';
}

export function streetBuyModal(): string {
  return 'street-buy';
}

export function streetOrderConfirmModal(): string {
  return 'buying';
}

export function streetRequestModal(): string {
  return 'street-request';
}

export function streetComplainModal(): string {
  return 'street-complain';
}

export function buildingPreviewModal(): string {
  return 'building-preview';
}

export function buildingBuyModal(): string {
  return 'building-buy';
}

export const modalPaths = {
  streetOrderConfirm: streetOrderConfirmModal,
  streetPreview: streetPreviewModal,
  streetRequest: streetRequestModal,
  streetComplain: streetComplainModal,
  streetSell: streetSellModal,
  streetBuy: streetBuyModal,
  buildingPreview: buildingPreviewModal,
  buildingBuy: buildingBuyModal,
};

export const streetsNav = createNavigation(paths, streetsRoot);
export const streetsModalsNav = createNavigation(modalPaths, `${streetsRoot}-modals`);
