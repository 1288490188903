import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit, Renderer2,
  ViewChild
} from '@angular/core';
import { ChatMessageTO, DirectMessageTOResponse } from 'api/models';
import { ModalRouter } from 'core/modules/modal';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ChatRoomModel, ChatRoomService } from 'app/_main/services/chat-room.service';
import { GenericFormControl, GenericFormGroup } from 'core/utils/form-generics';
import { iconOval } from 'core/icons/lib/icon-oval';
import { AppValidators } from 'core/validators/validators';
import {MediaQuery} from "core/modules/platform/services/media-query.service";

export interface ChatMessage {
  text?: string;
  time?: Date;
  isMine?: boolean;
}

export class ChatRoomForm extends GenericFormGroup<{  message: string; }> {
  constructor() {
    super({
      message: new GenericFormControl<string>('', [AppValidators.emptyMessage]),
    });
  }
}

@Component({
  selector: 'app-room-dialog-page',
  templateUrl: './room-dialog-page.component.html',
  styleUrls: ['./room-dialog-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoomDialogPageComponent implements OnInit, OnDestroy {
  @Input() roomName!: string;

  messageList: ChatMessageTO[] = [];

  form!: ChatRoomForm;
  private destroy$: Subject<void> = new Subject<void>();
  private chatDestroy$: Subject<void> = new Subject<void>();
  private sendMessage$: Subject<void> = new Subject<void>();

  readonly spinIcon = iconOval;

  @ViewChild('chatContainer', { static: true }) private chatContainer!: ElementRef;

  constructor(
    private cdr: ChangeDetectorRef,
    private modal: ModalRouter,
    private mediaQuery: MediaQuery,
    private chatRoomService: ChatRoomService,
  ) {
    this.form = new ChatRoomForm();
  }

  get firstMessage(): ChatMessageTO {
    return (this.room?.messages || [])[0];
  }

  get online(): number {
    return this.room?.online || 0;
  }

  get room(): ChatRoomModel | undefined {
    return this.chatRoomService.rooms?.find(x => x.name === this.roomName);
  }

  ngOnInit() {

    this.maxCardSizeMobile();

    this.chatRoomService.joinRoom(this.roomName)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.messageList = [ ...(this.room?.messages || [])];
        this.cdr.detectChanges();
        this.scrollToBottom();
      });

    this.sendMessage()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.form.controls.message.reset();
        this.focusInput();
        this.cdr.detectChanges();
      });

    this.chatRoomService.readMessage()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.cdr.detectChanges();
      });

    this.chatRoomService.refreshMessage()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        const roomMessages = this.room?.messages || [];
        const currentPosition = this.chatContainer.nativeElement.scrollTop;
        const maxScrollOnBottom = this.chatContainer.nativeElement.scrollHeight - this.chatContainer.nativeElement.clientHeight;
        const needToScrollBottom = result.needToScrollBottom || ((roomMessages.length - this.messageList.length) === 1 && currentPosition === maxScrollOnBottom );

        this.messageList = [ ...(this.room?.messages || [])];
        this.cdr.detectChanges();

       if (needToScrollBottom) {
         this.scrollToBottom();
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.chatDestroy$.next();
    this.chatDestroy$.complete();
  }

  scrollToBottom(): void {
    requestAnimationFrame(() => {
      if (this.chatContainer && this.chatContainer.nativeElement) {
        try {
          this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
        } catch(err) {
          console.log(err);
        }
      }
      this.cdr.detectChanges();
    });
  }

  updateScroll($event: any): void {
    const roomId = this.room?.id || 0;
    const roomMessages = this.room?.messages || [];
    const currentPosition = this.chatContainer.nativeElement.scrollTop;
    const maxScrollOnBottom = this.chatContainer.nativeElement.scrollHeight - this.chatContainer.nativeElement.clientHeight;
    const maxScrollSpace = (this.chatContainer.nativeElement.scrollHeight / 100 * 10);
    const scrollPercentBeforeHistory = 40;
    const scrollPercentBeforeLoadMore = 60;

    const scrollPercent = (currentPosition / (maxScrollOnBottom / 100));
    const needToLoadMore = scrollPercent > scrollPercentBeforeLoadMore;
    const needToLoadHistory = scrollPercent < scrollPercentBeforeHistory;


    if (needToLoadHistory && this.firstMessage?.id !== this.room?.firstMessageId) {
      this.chatRoomService.loadNextPage$.next({ roomId: roomId, moveBack: true, initialLoad: false });
    }

    if (needToLoadMore && (this.room?.unreadMessages || 0) > 0) {
      const lastMessageId = roomMessages[roomMessages.length - 1]?.id || 0;
      this.chatRoomService.readMessage$.next({ roomId: roomId, messageId: lastMessageId });
      this.chatRoomService.loadNextPage$.next({ roomId: roomId, moveBack: false, initialLoad: false });
    }

    if (needToLoadHistory && currentPosition === 0 && this.firstMessage?.id !== this.room?.firstMessageId) {
      this.chatContainer.nativeElement.scrollTop = maxScrollSpace;
    }
  }

  keyDown($event: any) {
    if ($event.keyCode === 13 && $event.ctrlKey) {
      this.submit();
    }
  }

  submit(): void {
    if (this.form.invalid) {
      return;
    }

    this.focusInput();
    this.sendMessage$.next();
  }

  private sendMessage(): Observable<DirectMessageTOResponse> {
    return this.sendMessage$.asObservable().pipe(
      debounceTime(200),
      switchMap(() => {
        return this.chatRoomService.sendMessage(this.room?.id || 0, this.form.controls.message.value);
      }),
    );
  }

  private maxCardSizeMobile(): void {
    const documentHeight = () => {
      if (this.mediaQuery.current === 'medium' || this.mediaQuery.current === 'small') {
        const doc = document.documentElement;
        doc.style.setProperty('--doc-height', `${window.innerHeight}px`);
      }
    };
    window.addEventListener('resize',documentHeight);
    documentHeight();
  }

  private focusInput(): void {
    const element = document.getElementsByTagName('textarea');
    if (element.length !== 0) {
      element[0].focus();
    };
  }
}
