import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PublicProfileDirective } from './public-profile.directive';
import { ScrollLockDirective } from './scroll-lock.directive';

const exportedDeclarations = [PublicProfileDirective, ScrollLockDirective];

@NgModule({
  imports: [CommonModule],
  declarations: exportedDeclarations,
  exports: exportedDeclarations,
})
export class AppDirectivesModule {}
