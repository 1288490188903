<div class="data-container container-column dark">
  <div class="scroller-hider">
    <div
      class="tabs"
      *ngIf="marketsData$ | async as marketsData"
      appTabsContainer
      activeClass="active"
      mediaCondition="small only">
      <button
        [routerLink]="[appNav.marketplaceNav.marketsRoot(), 'buy']"
        routerLinkActive="active"
        [ripple]="false"
        appButton
        appTabItem
        activeClass="active"
        class="tab">
        <app-badge *ngIf="marketsData.totalBuy !== 0" class="margin-left">
          {{ marketsData.totalBuy }}
        </app-badge>
        {{ 'dashboard.markets.button.buying' | translate }}
      </button>
      <button
        [routerLink]="[appNav.marketplaceNav.marketsRoot(), 'offers-received']"
        routerLinkActive="active"
        [ripple]="false"
        appButton
        appTabItem
        activeClass="active"
        class="tab">
        {{ 'dashboard.markets.button.selling' | translate }}
        <app-badge *ngIf="marketsData.totalOffersReceived !== 0" class="margin-left">
          {{ marketsData.totalOffersReceived }}
        </app-badge>
      </button>

      <button
        [routerLink]="[appNav.marketplaceNav.marketsRoot(), 'sell']"
        routerLinkActive="active"
        [ripple]="false"
        appButton
        appTabItem
        activeClass="active"
        class="tab">
        {{ 'dashboard.markets.button.offers-placed' | translate }}
        <app-badge *ngIf="marketsData.totalSell !== 0" class="margin-left">
          {{ marketsData.totalSell }}
        </app-badge>
      </button>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>
