/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { NftCertificateTOResponse } from '../models/nft-certificate-toresponse';

/**
 * Nft Certificate Controller
 */
@Injectable({
  providedIn: 'root',
})
class NftCertificateControllerService extends __BaseService {
  static readonly createNftCertificateUsingGETPath = '/api/v1/nft-certificate/{authenticityNumber}';
  static readonly downloadCertificateUsingGETPath = '/api/v1/nft-certificate/{nftId}/download';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * NFT Certificate
   *
   * NFT Certificate<br/>
   * @param authenticityNumber authenticityNumber
   * @return OK
   */
  createNftCertificateUsingGETResponse(authenticityNumber: number): __Observable<__StrictHttpResponse<NftCertificateTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/nft-certificate/${encodeURIComponent(String(authenticityNumber))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NftCertificateTOResponse>;
      })
    );
  }
  /**
   * NFT Certificate
   *
   * NFT Certificate<br/>
   * @param authenticityNumber authenticityNumber
   * @return OK
   */
  createNftCertificateUsingGET(authenticityNumber: number): __Observable<NftCertificateTOResponse> {
    return this.createNftCertificateUsingGETResponse(authenticityNumber).pipe(
      __map(_r => _r.body as NftCertificateTOResponse)
    );
  }

  /**
   * NFT Certificate
   *
   * download NFT Certificate<br/>
   * @param nftId nftId
   * @return OK
   */
  downloadCertificateUsingGETResponse(nftId: number): __Observable<__StrictHttpResponse<ArrayBuffer>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/nft-certificate/${encodeURIComponent(String(nftId))}/download`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'arraybuffer'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ArrayBuffer>;
      })
    );
  }
  /**
   * NFT Certificate
   *
   * download NFT Certificate<br/>
   * @param nftId nftId
   * @return OK
   */
  downloadCertificateUsingGET(nftId: number): __Observable<ArrayBuffer> {
    return this.downloadCertificateUsingGETResponse(nftId).pipe(
      __map(_r => _r.body as ArrayBuffer)
    );
  }
}

module NftCertificateControllerService {
}

export { NftCertificateControllerService }
