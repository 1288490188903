import { Injectable } from '@angular/core';

import { AppIcon, AppIconName } from './icon.type';

@Injectable({ providedIn: 'root' })
export class AppIconsLibrary {
  private icons = new Map<AppIconName, string>();

  constructor() {
    this.addIcons([]);
  }

  addIcons(icons: AppIcon[]): void {
    icons.forEach(i => {
      this.icons.set(i.name, i.svgData);
    });
  }

  getIcon(name: AppIconName): string {
    const icon = this.icons.get(name);
    if (!icon) {
      if (name) {
        console.error(`${name} icon was not found`);
      }
      return '';
    }
    return icon;
  }
}
