import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { IconModule } from '../icon/icon.module';
import { RangeComponent } from './range/range.component';
import { TwinRangeComponent } from './twin-range/twin-range.component';

const exportedDeclarations = [TwinRangeComponent, RangeComponent];

@NgModule({
  imports: [CommonModule, IconModule, ReactiveFormsModule],
  declarations: exportedDeclarations,
  exports: exportedDeclarations,
})
export class RangeModule {}
