import { Routes, UrlMatchResult, UrlSegment } from '@angular/router';
import { RouteData } from 'core/models/route.data';

import { HistoryPageComponent } from './components/history-page/history-page.component';
import { MarketplaceBuildingOverviewComponent } from './components/marketplace-building-overview/marketplace-building-overview.component';
import { MarketplaceStreetsPageComponent } from './components/marketplace-page/_ui/marketplace-streets-page/marketplace-streets-page.component';
import { MarketplaceStreetOverviewComponent } from './components/marketplace-street-overview/marketplace-street-overview.component';
import { BuyingMarketsPageComponent } from './components/markets-page/_ui/buying-markets-page/buying-markets-page.component';
import { OffersReceivedMarketsPageComponent } from './components/markets-page/_ui/offers-received-markets-page/offers-received-markets-page.component';
import { SellingMarketsComponent } from './components/markets-page/_ui/selling-markets-page/selling-markets.page.component';
import { MarketsPageComponent } from './components/markets-page/markets-page.component';
import { WishlistBuildingsComponent } from './components/wishlist-page/_ui/wishlist-buildings/wishlist-buildings.component';
import { WishlistStreetsComponent } from './components/wishlist-page/_ui/wishlist-streets/wishlist-streets.component';
import { WishlistPageComponent } from './components/wishlist-page/wishlist-page.component';
import { MarketplaceComponent } from './marketplace.component';
import { paths } from './marketplace.navigation';
import {
  MarketplaceBuildingsPageComponent
} from "app/marketplace/components/marketplace-page/_ui/marketplace-buildings-page/marketplace-buildings-page.component";
import {MarketplacePageComponent} from "app/marketplace/components/marketplace-page/marketplace-page.component";

const MARKETPLACE_MATCHER_WHITELIST = [
  paths.wishlist(),
  paths.marketplaceInit(),
  paths.marketplaceBuildings(),
  paths.marketplaceStreets(),
  paths.marketsBuying(),
  paths.marketsOffersReceived(),
  paths.marketsSelling(),
  paths.marketsRoot(),
  paths.marketplaceHistory(),
];

export const isBrokenMarketplaceSideMapUrl = (url: UrlSegment[]): boolean =>
  url.length === 2 && url[0].path.endsWith('-') && url[1].path.startsWith('-');

export const marketplaceMatcher =
  (consume?: boolean) =>
  (url: UrlSegment[]): UrlMatchResult | null => {
    const isBroken = isBrokenMarketplaceSideMapUrl(url);

    if (isBroken) {
      const streetId = url[1].path.replace('-', '').split('-').slice(-1)[0];
      return consume
        ? {
            consumed: url,
            posParams: { streetId: new UrlSegment(streetId, {}) },
          }
        : null;
    }

    const pathHead = url[0]?.path || '';

    if (pathHead.split('-').length > 1) {
      const streetId = pathHead.split('-').slice(-1)[0];

      return {
        consumed: consume ? url : [],
        posParams: consume ? { streetId: new UrlSegment(streetId, {}) } : undefined,
      };
    } else if (!pathHead || MARKETPLACE_MATCHER_WHITELIST.includes(pathHead)) {
      return { consumed: [] };
    }
    return null;
  };

export const buildingMatcher =
  (consume?: boolean) =>
  (url: UrlSegment[]): UrlMatchResult | null => {
    const isBroken = isBrokenMarketplaceSideMapUrl(url);

    if (isBroken) {
      const streetId = url[1].path.replace('-', '').split('-').slice(-1)[0];
      return consume
        ? {
            consumed: url,
            posParams: { streetId: new UrlSegment(streetId, {}) },
          }
        : null;
    }

    const pathHead = url[0]?.path || '';

    if (pathHead.split('-').length > 1 && pathHead.split('-')?.[0] === 'building') {
      const buildingId = pathHead.split('-').slice(-1)[0];

      return {
        consumed: consume ? url : [],
        posParams: consume ? { buildingId: new UrlSegment(buildingId, {}) } : undefined,
      };
    } else if (!pathHead || MARKETPLACE_MATCHER_WHITELIST.includes(pathHead)) {
      return { consumed: [] };
    }
    return null;
  };

export const routes: Routes = [
  {
    matcher: marketplaceMatcher(false),
    component: MarketplaceComponent,
    children: [
      // {
      //   path: paths.marketplaceStreets(),
      //   component: MarketplaceStreetsPageComponent,
      // },
      {
        path: paths.wishlist(),
        component: WishlistPageComponent,
        // data: {
        //   seoTitle: $t('seo.title.my-watchlist'),
        // } as RouteData,
        children: [
          {
            path: paths.marketplaceStreets(),
            component: WishlistStreetsComponent,
            // data: {
            //   seoTitle: $t('seo.title.my-markets'),
            // } as RouteData,
          },
          {
            path: paths.marketplaceBuildings(),
            component: WishlistBuildingsComponent,
            // data: {
            //   seoTitle: $t('seo.title.my-markets'),
            // } as RouteData,
          },
          {
            path: '',
            redirectTo: paths.marketplaceStreets(),
          },
        ],
      },
      {
        path: paths.marketsRoot(),
        component: MarketsPageComponent,
        children: [
          {
            path: paths.marketsBuying(),
            component: BuyingMarketsPageComponent,
            data: {
              seoTitle: $t('seo.title.my-markets'),
            } as RouteData,
          },
          {
            path: paths.marketsOffersReceived(),
            component: OffersReceivedMarketsPageComponent,
            data: {
              seoTitle: $t('seo.title.my-markets'),
            } as RouteData,
          },
          {
            path: paths.marketsSelling(),
            component: SellingMarketsComponent,
            data: {
              seoTitle: $t('seo.title.my-markets'),
            } as RouteData,
          },
          {
            path: '',
            redirectTo: paths.marketsBuying(),
            data: {
              seoTitle: $t('seo.title.my-markets'),
            } as RouteData,
          },
        ],
      },
      {
        path: paths.marketplaceHistory(),
        component: HistoryPageComponent,
      },
      {
        path: paths.marketplaceInit(),
        component: MarketplacePageComponent,
        children: [
          {
            path: paths.marketplaceStreets(),
            component: MarketplaceStreetsPageComponent,
          },
          {
            path: paths.marketplaceBuildings(),
            component: MarketplaceBuildingsPageComponent,
          },
          {
            path: '',
            redirectTo: paths.marketplaceStreets()
          }
        ],
      },
    ],
  },
  // SEO friendly path
  {
    matcher: buildingMatcher(true),
    component: MarketplaceBuildingOverviewComponent,
  },
  {
    matcher: marketplaceMatcher(true),
    component: MarketplaceStreetOverviewComponent,
  },
];
