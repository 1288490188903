<div class="inputs">
  <div class="input first">
    <div class="prev" (click)="setYear(year - 1)">
      <ng-container *ngTemplateOutlet="arrowTmpl"></ng-container>
    </div>
    <span class="year" (click)="toggleShowYears()">{{ year }}</span>
    <div class="next" (click)="setYear(year + 1)">
      <ng-container *ngTemplateOutlet="arrowTmpl"></ng-container>
    </div>

    <div class="list years" (click)="stopClose()" #yearsScroll>
      <ng-container *ngIf="showYears">
        <div class="container">
          <span
            class="item"
            *ngFor="let y of years"
            [ngClass]="{ active: y.label === year, disabled: y.disabled }"
            (click)="setYear(y.label)">
            {{ y.label }}
          </span>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="input">
    <div class="prev" (click)="setMonth(month - 1)">
      <ng-container *ngTemplateOutlet="arrowTmpl"></ng-container>
    </div>
    <span class="month" (click)="toggleShowMonths()">{{ months[month].label }}</span>
    <div class="next" (click)="setMonth(month + 1)">
      <ng-container *ngTemplateOutlet="arrowTmpl"></ng-container>
    </div>
    <div class="list months" (click)="stopClose()" #monthsScroll>
      <ng-container *ngIf="showMonths">
        <div class="container">
          <span
            class="item"
            *ngFor="let m of months; let i = index"
            [ngClass]="{ active: month === i, disabled: m.disabled }"
            (click)="setMonth(i)">
            {{ m.label }}
          </span>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<div class="days" [ngClass]="{ disabled: showYears || showMonths }">
  <span class="day" *ngFor="let d of days">{{ d }}</span>
</div>
<div class="dates" [ngClass]="{ disabled: showYears || showMonths }">
  <div *ngFor="let _ of [].constructor(6); let i = index">
    <ng-container *ngFor="let _ of [].constructor(7); let j = index">
      <span
        class="date"
        [ngClass]="{
          'current-month': dates[j + i * 7].current,
          disabled: dates[j + i * 7].disabled
        }">
        <span
          class="dot"
          [ngClass]="{
            active: dates[j + i * 7].label === dayOfMonth,
            today: isToday(dates[j + i * 7].label)
          }"
          (click)="dates[j + i * 7].current ? setDate(dates[j + i * 7].label) : ''">
          {{ dates[j + i * 7].label }}
        </span>
      </span>
    </ng-container>
  </div>
</div>

<ng-template #arrowTmpl>
  <svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" viewBox="0 0 24 24">
    <path
      d="M15.398 10.2a1 1 0 111.2 1.6l-3.998 3a1 1 0 01-1.2 0l-4-3a1 1 0 111.2-1.6l3.4 2.55 3.398-2.55z"
      fill="currentColor"
      fill-rule="evenodd" />
  </svg>
</ng-template>
