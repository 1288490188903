import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { lazyModalsNav } from 'app/app.navigation';
import { iconCloseRounded } from 'core/icons/lib/icon-close-rounded';
import { iconEllipsisH } from 'core/icons/lib/icon-ellipsis-h';
import { iconMoneyChange } from 'core/icons/lib/icon-money-change';
import { iconShoppingCart } from 'core/icons/lib/icon-shopping-cart';
import { iconStar } from 'core/icons/lib/icon-start';
import { ModalRouter } from 'core/modules/modal';
import { StreetPreviewModal } from 'dashboard/dashboard.navigation';
import { DwrldOrder } from 'dashboard/models/dwrld-order.model';
import { UserService } from 'profile/services/user.service';

@Component({
  selector: 'app-buying-item',
  templateUrl: './buying-item.component.html',
  styleUrls: ['./buying-item.component.scss'],
})
export class BuyingItemComponent {
  readonly closeIcon = iconCloseRounded;

  @Input() order = new DwrldOrder();

  @Output() cancelOrder = new EventEmitter<number>();

  readonly ellipsisIcon = iconEllipsisH;
  readonly starIcon = iconStar;
  readonly shoppingCartIcon = iconShoppingCart;
  readonly exchangeIcon = iconMoneyChange;

  constructor(public userService: UserService, private modal: ModalRouter) {}

  onCancelOrder(): void {
    this.cancelOrder.next(this.order.orderId);
  }

  @HostListener('click')
  openStreetPreview(): void {
    this.modal.navigate(lazyModalsNav.streets.streetBuy(), undefined, {
      data: { entity: this.order.street, closeOnOrderCancel: true } as StreetPreviewModal,
    });
  }
}
