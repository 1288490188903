/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ReferralBalanceTO } from '../models/referral-balance-to';
import { ReferralBalanceTransactionDetailsTO } from '../models/referral-balance-transaction-details-to';
import { TransferToBalanceRequest } from '../models/transfer-to-balance-request';
import { ReferralInvite } from '../models/referral-invite';
import { UserInfoTOResponse } from '../models/user-info-toresponse';
import { ReferralSettingTO } from '../models/referral-setting-to';
import { PrivateAPISettingTO } from '../models/private-apisetting-to';
import { ReferralPaymentsStatsTOResponse } from '../models/referral-payments-stats-toresponse';
import { ReferralBalanceTransactionsTOList } from '../models/referral-balance-transactions-tolist';
import { UserInfoTOListResponse } from '../models/user-info-tolist-response';

/**
 * Referral Controller
 */
@Injectable({
  providedIn: 'root',
})
class ReferralControllerService extends __BaseService {
  static readonly referralBalanceInfoUsingGETPath = '/api/v1/referral/balance/statistics';
  static readonly transferAdditionalToBalanceUsingPOSTPath = '/api/v1/referral/balance/transfer';
  static readonly saveUniqueReferralClickUsingGETPath = '/api/v1/referral/click';
  static readonly sendReferralEmailUsingPOSTPath = '/api/v1/referral/email';
  static readonly getParentReferralUserInfoUsingGETPath = '/api/v1/referral/parent';
  static readonly getReferralSettingsUsingGETPath = '/api/v1/referral/referral-settings';
  static readonly getPrivateAPISettingsUsingGETPath = '/api/v1/referral/settings';
  static readonly getReferralStatsUsingGETPath = '/api/v1/referral/stats/brake-down';
  static readonly getUserStatsUsingGET1Path = '/api/v1/referral/stats/currency';
  static readonly referralBalanceTransactionsUsingGETPath = '/api/v1/referral/transactions/history';
  static readonly referralTransactionDetailsUsingGETPath = '/api/v1/referral/transactions/{id}/details';
  static readonly getUserStatsUsingGETPath = '/api/v1/referral/user-history';
  static readonly getUserInfoByReferralCodeUsingGETPath = '/api/v1/referral/userinfo';
  static readonly getReferralUsersUsingGETPath = '/api/v1/referral/users';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get referral balance info
   *
   * Throws errors: ACCESS_DENIED
   * @param period period
   * @return OK
   */
  referralBalanceInfoUsingGETResponse(period?: 'ALL_TIME' | 'LAST_MONTH' | 'THIS_MONTH' | 'TODAY' | 'YESTERDAY'): __Observable<__StrictHttpResponse<ReferralBalanceTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (period != null) __params = __params.set('period', period.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/referral/balance/statistics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReferralBalanceTO>;
      })
    );
  }
  /**
   * Get referral balance info
   *
   * Throws errors: ACCESS_DENIED
   * @param period period
   * @return OK
   */
  referralBalanceInfoUsingGET(period?: 'ALL_TIME' | 'LAST_MONTH' | 'THIS_MONTH' | 'TODAY' | 'YESTERDAY'): __Observable<ReferralBalanceTO> {
    return this.referralBalanceInfoUsingGETResponse(period).pipe(
      __map(_r => _r.body as ReferralBalanceTO)
    );
  }

  /**
   * Transfer additionally to balance
   *
   * Throws errors: ACCESS_DENIED
   * @param transferToBalanceRequest transferToBalanceRequest
   * @return OK
   */
  transferAdditionalToBalanceUsingPOSTResponse(transferToBalanceRequest: TransferToBalanceRequest): __Observable<__StrictHttpResponse<ReferralBalanceTransactionDetailsTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = transferToBalanceRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/referral/balance/transfer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReferralBalanceTransactionDetailsTO>;
      })
    );
  }
  /**
   * Transfer additionally to balance
   *
   * Throws errors: ACCESS_DENIED
   * @param transferToBalanceRequest transferToBalanceRequest
   * @return OK
   */
  transferAdditionalToBalanceUsingPOST(transferToBalanceRequest: TransferToBalanceRequest): __Observable<ReferralBalanceTransactionDetailsTO> {
    return this.transferAdditionalToBalanceUsingPOSTResponse(transferToBalanceRequest).pipe(
      __map(_r => _r.body as ReferralBalanceTransactionDetailsTO)
    );
  }

  /**
   * Save unique referral click
   * @param referralCode referralCode
   */
  saveUniqueReferralClickUsingGETResponse(referralCode: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (referralCode != null) __params = __params.set('referralCode', referralCode.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/referral/click`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Save unique referral click
   * @param referralCode referralCode
   */
  saveUniqueReferralClickUsingGET(referralCode: string): __Observable<null> {
    return this.saveUniqueReferralClickUsingGETResponse(referralCode).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Send referral email
   *
   * Throws errors: ACCESS_DENIED; VALIDATION_ERROR; DUPLICATED_EMAIL
   * @param form form
   */
  sendReferralEmailUsingPOSTResponse(form: ReferralInvite): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/referral/email`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Send referral email
   *
   * Throws errors: ACCESS_DENIED; VALIDATION_ERROR; DUPLICATED_EMAIL
   * @param form form
   */
  sendReferralEmailUsingPOST(form: ReferralInvite): __Observable<null> {
    return this.sendReferralEmailUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get parent referral user
   *
   * Throws errors: ACCESS_DENIED
   * @return OK
   */
  getParentReferralUserInfoUsingGETResponse(): __Observable<__StrictHttpResponse<UserInfoTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/referral/parent`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserInfoTOResponse>;
      })
    );
  }
  /**
   * Get parent referral user
   *
   * Throws errors: ACCESS_DENIED
   * @return OK
   */
  getParentReferralUserInfoUsingGET(): __Observable<UserInfoTOResponse> {
    return this.getParentReferralUserInfoUsingGETResponse().pipe(
      __map(_r => _r.body as UserInfoTOResponse)
    );
  }

  /**
   * getReferralSettings
   * @return OK
   */
  getReferralSettingsUsingGETResponse(): __Observable<__StrictHttpResponse<ReferralSettingTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/referral/referral-settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReferralSettingTO>;
      })
    );
  }
  /**
   * getReferralSettings
   * @return OK
   */
  getReferralSettingsUsingGET(): __Observable<ReferralSettingTO> {
    return this.getReferralSettingsUsingGETResponse().pipe(
      __map(_r => _r.body as ReferralSettingTO)
    );
  }

  /**
   * Current referral percents settings
   * @return OK
   */
  getPrivateAPISettingsUsingGETResponse(): __Observable<__StrictHttpResponse<PrivateAPISettingTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/referral/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PrivateAPISettingTO>;
      })
    );
  }
  /**
   * Current referral percents settings
   * @return OK
   */
  getPrivateAPISettingsUsingGET(): __Observable<PrivateAPISettingTO> {
    return this.getPrivateAPISettingsUsingGETResponse().pipe(
      __map(_r => _r.body as PrivateAPISettingTO)
    );
  }

  /**
   * Get user's statistic
   *
   * Throws errors: ACCESS_DENIED
   * @param params The `ReferralControllerService.GetReferralStatsUsingGETParams` containing the following parameters:
   *
   * - `dateTo`: dateTo
   *
   * - `dateFrom`: dateFrom
   *
   * @return OK
   */
  getReferralStatsUsingGETResponse(params: ReferralControllerService.GetReferralStatsUsingGETParams): __Observable<__StrictHttpResponse<ReferralPaymentsStatsTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.dateTo != null) __params = __params.set('dateTo', params.dateTo.toString());
    if (params.dateFrom != null) __params = __params.set('dateFrom', params.dateFrom.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/referral/stats/brake-down`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReferralPaymentsStatsTOResponse>;
      })
    );
  }
  /**
   * Get user's statistic
   *
   * Throws errors: ACCESS_DENIED
   * @param params The `ReferralControllerService.GetReferralStatsUsingGETParams` containing the following parameters:
   *
   * - `dateTo`: dateTo
   *
   * - `dateFrom`: dateFrom
   *
   * @return OK
   */
  getReferralStatsUsingGET(params: ReferralControllerService.GetReferralStatsUsingGETParams): __Observable<ReferralPaymentsStatsTOResponse> {
    return this.getReferralStatsUsingGETResponse(params).pipe(
      __map(_r => _r.body as ReferralPaymentsStatsTOResponse)
    );
  }

  /**
   * Get user's statistic
   *
   * Throws errors: ACCESS_DENIED
   * @param params The `ReferralControllerService.GetUserStatsUsingGET1Params` containing the following parameters:
   *
   * - `dateTo`: dateTo
   *
   * - `dateFrom`: dateFrom
   *
   * @return OK
   */
  getUserStatsUsingGET1Response(params: ReferralControllerService.GetUserStatsUsingGET1Params): __Observable<__StrictHttpResponse<ReferralPaymentsStatsTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.dateTo != null) __params = __params.set('dateTo', params.dateTo.toString());
    if (params.dateFrom != null) __params = __params.set('dateFrom', params.dateFrom.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/referral/stats/currency`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReferralPaymentsStatsTOResponse>;
      })
    );
  }
  /**
   * Get user's statistic
   *
   * Throws errors: ACCESS_DENIED
   * @param params The `ReferralControllerService.GetUserStatsUsingGET1Params` containing the following parameters:
   *
   * - `dateTo`: dateTo
   *
   * - `dateFrom`: dateFrom
   *
   * @return OK
   */
  getUserStatsUsingGET1(params: ReferralControllerService.GetUserStatsUsingGET1Params): __Observable<ReferralPaymentsStatsTOResponse> {
    return this.getUserStatsUsingGET1Response(params).pipe(
      __map(_r => _r.body as ReferralPaymentsStatsTOResponse)
    );
  }

  /**
   * Get referral transactions
   *
   * Throws errors: ACCESS_DENIED
   * @param params The `ReferralControllerService.ReferralBalanceTransactionsUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * @return OK
   */
  referralBalanceTransactionsUsingGETResponse(params: ReferralControllerService.ReferralBalanceTransactionsUsingGETParams): __Observable<__StrictHttpResponse<ReferralBalanceTransactionsTOList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/referral/transactions/history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReferralBalanceTransactionsTOList>;
      })
    );
  }
  /**
   * Get referral transactions
   *
   * Throws errors: ACCESS_DENIED
   * @param params The `ReferralControllerService.ReferralBalanceTransactionsUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * @return OK
   */
  referralBalanceTransactionsUsingGET(params: ReferralControllerService.ReferralBalanceTransactionsUsingGETParams): __Observable<ReferralBalanceTransactionsTOList> {
    return this.referralBalanceTransactionsUsingGETResponse(params).pipe(
      __map(_r => _r.body as ReferralBalanceTransactionsTOList)
    );
  }

  /**
   * Get referral transaction's details
   *
   * Throws errors: ACCESS_DENIED
   * @param id id
   * @return OK
   */
  referralTransactionDetailsUsingGETResponse(id: number): __Observable<__StrictHttpResponse<ReferralBalanceTransactionDetailsTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/referral/transactions/${encodeURIComponent(String(id))}/details`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReferralBalanceTransactionDetailsTO>;
      })
    );
  }
  /**
   * Get referral transaction's details
   *
   * Throws errors: ACCESS_DENIED
   * @param id id
   * @return OK
   */
  referralTransactionDetailsUsingGET(id: number): __Observable<ReferralBalanceTransactionDetailsTO> {
    return this.referralTransactionDetailsUsingGETResponse(id).pipe(
      __map(_r => _r.body as ReferralBalanceTransactionDetailsTO)
    );
  }

  /**
   * Get user's history
   *
   * Throws errors: ACCESS_DENIED
   * @param params The `ReferralControllerService.GetUserStatsUsingGETParams` containing the following parameters:
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `dateTo`: dateTo
   *
   * - `dateFrom`: dateFrom
   *
   * @return OK
   */
  getUserStatsUsingGETResponse(params: ReferralControllerService.GetUserStatsUsingGETParams): __Observable<__StrictHttpResponse<ReferralPaymentsStatsTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.dateTo != null) __params = __params.set('dateTo', params.dateTo.toString());
    if (params.dateFrom != null) __params = __params.set('dateFrom', params.dateFrom.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/referral/user-history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReferralPaymentsStatsTOResponse>;
      })
    );
  }
  /**
   * Get user's history
   *
   * Throws errors: ACCESS_DENIED
   * @param params The `ReferralControllerService.GetUserStatsUsingGETParams` containing the following parameters:
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `dateTo`: dateTo
   *
   * - `dateFrom`: dateFrom
   *
   * @return OK
   */
  getUserStatsUsingGET(params: ReferralControllerService.GetUserStatsUsingGETParams): __Observable<ReferralPaymentsStatsTOResponse> {
    return this.getUserStatsUsingGETResponse(params).pipe(
      __map(_r => _r.body as ReferralPaymentsStatsTOResponse)
    );
  }

  /**
   * getUserInfoByReferralCode
   * @param referralCode referralCode
   * @return OK
   */
  getUserInfoByReferralCodeUsingGETResponse(referralCode: string): __Observable<__StrictHttpResponse<UserInfoTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (referralCode != null) __params = __params.set('referralCode', referralCode.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/referral/userinfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserInfoTOResponse>;
      })
    );
  }
  /**
   * getUserInfoByReferralCode
   * @param referralCode referralCode
   * @return OK
   */
  getUserInfoByReferralCodeUsingGET(referralCode: string): __Observable<UserInfoTOResponse> {
    return this.getUserInfoByReferralCodeUsingGETResponse(referralCode).pipe(
      __map(_r => _r.body as UserInfoTOResponse)
    );
  }

  /**
   * Get users than was registered by referral link of current user
   *
   * Throws errors: ACCESS_DENIED
   * @return OK
   */
  getReferralUsersUsingGETResponse(): __Observable<__StrictHttpResponse<UserInfoTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/referral/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserInfoTOListResponse>;
      })
    );
  }
  /**
   * Get users than was registered by referral link of current user
   *
   * Throws errors: ACCESS_DENIED
   * @return OK
   */
  getReferralUsersUsingGET(): __Observable<UserInfoTOListResponse> {
    return this.getReferralUsersUsingGETResponse().pipe(
      __map(_r => _r.body as UserInfoTOListResponse)
    );
  }
}

module ReferralControllerService {

  /**
   * Parameters for getReferralStatsUsingGET
   */
  export interface GetReferralStatsUsingGETParams {

    /**
     * dateTo
     */
    dateTo?: string;

    /**
     * dateFrom
     */
    dateFrom?: string;
  }

  /**
   * Parameters for getUserStatsUsingGET1
   */
  export interface GetUserStatsUsingGET1Params {

    /**
     * dateTo
     */
    dateTo?: string;

    /**
     * dateFrom
     */
    dateFrom?: string;
  }

  /**
   * Parameters for referralBalanceTransactionsUsingGET
   */
  export interface ReferralBalanceTransactionsUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * pageSize
     */
    pageSize?: number;

    /**
     * pageNumber
     */
    pageNumber?: number;
  }

  /**
   * Parameters for getUserStatsUsingGET
   */
  export interface GetUserStatsUsingGETParams {

    /**
     * pageSize
     */
    pageSize?: number;

    /**
     * pageNumber
     */
    pageNumber?: number;

    /**
     * dateTo
     */
    dateTo?: string;

    /**
     * dateFrom
     */
    dateFrom?: string;
  }
}

export { ReferralControllerService }
