<div class="street-images">
  <app-street-image
    class="street-image"
    [imageSrc]="imageUrl"
    [isAvailable]="asset.isAvailable"
    [isWithdraw]="asset.isWithdrawn || false"
    [level]="levelNFT"></app-street-image>

  <app-scroller
    *ngIf="!onlyLinesMap; else mapImageTmpl"
    type="slider"
    class="map-images"
    #mapImagesScroller>
    <button
      *ngIf="!mapImagesScroller.isFirst"
      [appRoundButton]="'chevron-left'"
      class="small slider-btn prev primary margin-right-lg"
      (click)="mapImagesScroller.prev()"></button>
    <button
      *ngIf="!mapImagesScroller.isLast"
      [appRoundButton]="'chevron-left'"
      class="small slider-btn next primary flip-x"
      (click)="mapImagesScroller.next()"></button>

    <div class="scroll-cell">
      <div class="map-preview">
        <img
          class="map-image"
          [class.building-image]="assetType === 'BUILDING' && !largeMap"
          [attr.src]="pathImage"
          alt="image-map-main" />
      </div>
    </div>

    <div class="scroll-cell">
      <div class="map-preview">
        <img class="map-image" [attr.src]="markerImage" alt="image-map-marker" />
      </div>
    </div>
  </app-scroller>

  <ng-template #mapImageTmpl>
    <div
      class="map-preview"
      [ngClass]="{ 'cursor-pointer': navigateToMap }"
      (click)="openStreetOnMap()">
      <img class="map-image" [attr.src]="pathImage" alt="image-map" />
    </div>
  </ng-template>
</div>
