import { Component, EventEmitter, Output } from '@angular/core';
import { appNav } from 'app/app.navigation';
import { AuthService } from 'auth/services/auth.service';
import { iconBell } from 'core/icons/lib/icon-bell';
import { iconCashier } from 'core/icons/lib/icon-cashier';
import { iconClose } from 'core/icons/lib/icon-close';
import { iconCubeScan } from 'core/icons/lib/icon-cube-scan';
import { iconDashboard } from 'core/icons/lib/icon-dashboard';
import { iconDocuments } from 'core/icons/lib/icon-documents';
import { iconFacebook } from 'core/icons/lib/icon-facebook';
import { iconHelp } from 'core/icons/lib/icon-help';
import { iconInstagram } from 'core/icons/lib/icon-instagram';
import { iconLogout } from 'core/icons/lib/icon-logout';
import { iconMapMarker } from 'core/icons/lib/icon-map-marker';
import { iconSettings } from 'core/icons/lib/icon-settings';
import { iconShop } from 'core/icons/lib/icon-shop';
import { iconTelegram } from 'core/icons/lib/icon-telegram';
import { iconTwitter } from 'core/icons/lib/icon-twitter';
import { I18nService, LangUIItem } from 'core/modules/i18n/i18n.service';
import { GenericFormControl } from 'core/utils/form-generics';
import { NotificationService } from 'profile/services/notification.service';
import { UserService } from 'profile/services/user.service';
import {iconExchange} from "core/icons/lib/icon-exchange";

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent {
  appNav = appNav;

  readonly twitterIcon = iconTwitter;
  readonly facebookIcon = iconFacebook;
  readonly instagramIcon = iconInstagram;
  readonly telegramIcon = iconTelegram;
  readonly closeIcon = iconClose;
  readonly logoutIcon = iconLogout;
  readonly mapMarketIcon = iconMapMarker;
  readonly dashboardIcon = iconDashboard;
  readonly documentsIcon = iconDocuments;
  readonly marketPlaceIcon = iconShop;
  readonly cashierIcon = iconCashier;
  readonly bellIcon = iconBell;
  readonly profileIcon = iconSettings;
  readonly helpIcon = iconHelp;
  readonly metaverseIcon = iconCubeScan;
  readonly exchangeIcon = iconExchange;

  localization = this.localizeService.languages;
  localizeFormControl = new GenericFormControl<LangUIItem>(this.localizeService.language);

  @Output()
  closing = new EventEmitter();

  @Output()
  logout = new EventEmitter();

  @Output()
  openNotifications = new EventEmitter();

  @Output()
  openProfile = new EventEmitter();

  constructor(
    public userService: UserService,
    public notificationService: NotificationService,
    public auth: AuthService,
    private localizeService: I18nService
  ) {}

  changeLanguage(language: LangUIItem): void {
    this.localizeService.change(language.code);
  }
}
