/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TwoFactorAuthUpdateTOResponse } from '../models/two-factor-auth-update-toresponse';
import { ConfirmTwoFactorFrom } from '../models/confirm-two-factor-from';
import { RemoveTwoFactorAuthForm } from '../models/remove-two-factor-auth-form';
import { TwoFactorAuthAddTOResponse } from '../models/two-factor-auth-add-toresponse';
import { PasswordForm } from '../models/password-form';
import { BindTwoFactorAuthForm } from '../models/bind-two-factor-auth-form';
import { SendSmsTwoFaCodeTOResponse } from '../models/send-sms-two-fa-code-toresponse';
import { LoginUserTOResponse } from '../models/login-user-toresponse';
import { LoginTwoFactorAuthForm } from '../models/login-two-factor-auth-form';
import { TwoFactorAuthStartDisableTOResponse } from '../models/two-factor-auth-start-disable-toresponse';
import { UserAcceptTosVersionTO } from '../models/user-accept-tos-version-to';
import { UserInfoTOResponse } from '../models/user-info-toresponse';
import { UpdateUserInfoForm } from '../models/update-user-info-form';
import { UserActivityTOListResponse } from '../models/user-activity-tolist-response';
import { ConfirmEmailTOResponse } from '../models/confirm-email-toresponse';
import { ConfirmEmailForm } from '../models/confirm-email-form';
import { ResendConfirmationEmailTOResponse } from '../models/resend-confirmation-email-toresponse';
import { ResendEmailConfirmationForm } from '../models/resend-email-confirmation-form';
import { CheckUserExistTOResponse } from '../models/check-user-exist-toresponse';
import { CheckUserExistForm } from '../models/check-user-exist-form';
import { AccountTO } from '../models/account-to';
import { GeoIPTOResponse } from '../models/geo-iptoresponse';
import { LoginForm } from '../models/login-form';
import { UserProfileTOResponse } from '../models/user-profile-toresponse';
import { PasswordChangeTOResponse } from '../models/password-change-toresponse';
import { PasswordChangeForm } from '../models/password-change-form';
import { PasswordResetRequestEmailTOResponse } from '../models/password-reset-request-email-toresponse';
import { PasswordResetRequest } from '../models/password-reset-request';
import { PasswordSetNewRequest } from '../models/password-set-new-request';
import { UserPrivacySettingsTO } from '../models/user-privacy-settings-to';
import { PublicProfileResponse } from '../models/public-profile-response';
import { SignupForm } from '../models/signup-form';
import { UserInfoTO } from '../models/user-info-to';
import { UsernameCheckResponse } from '../models/username-check-response';

/**
 * User Controller
 */
@Injectable({
  providedIn: 'root',
})
class UserControllerService extends __BaseService {
  static readonly confirmRemoveTwoFactorAuthUsingPOSTPath = '/api/v1/user/2fa/disable/{provider}/confirm';
  static readonly sendRemoveTwoFactorAuthUsingPOSTPath = '/api/v1/user/2fa/disable/{provider}/email';
  static readonly addTwoFactorAuthUsingPOSTPath = '/api/v1/user/2fa/enable/{provider}';
  static readonly confirmEnableTwoFactorAuthUsingPOSTPath = '/api/v1/user/2fa/enable/{provider}/confirm';
  static readonly sendVerificationEmailTwoFactorAuthUsingPOSTPath = '/api/v1/user/2fa/enable/{provider}/email';
  static readonly resendSmsTwoFaCodeUsingGETPath = '/api/v1/user/2fa/sms/resend';
  static readonly loginTwoFactorAuthUsingPOSTPath = '/api/v1/user/2fa/{provider}/login';
  static readonly startRemoveUsingPOSTPath = '/api/v1/user/2fa/{provider}/st-cross-out';
  static readonly acceptTosVersionUsingGETPath = '/api/v1/user/accept-tos/{tosVersion}';
  static readonly activateReferralUsingPUTPath = '/api/v1/user/activate-referral';
  static readonly userActivityHistoryUsingGETPath = '/api/v1/user/activity';
  static readonly confirmEmailUsingPOSTPath = '/api/v1/user/confirmation/accept';
  static readonly resendConfirmationEmailUsingPOST1Path = '/api/v1/user/confirmation/resend';
  static readonly checkUserExistUsingPOSTPath = '/api/v1/user/exists';
  static readonly favouriteMarketsUsingGETPath = '/api/v1/user/favourite/market/list';
  static readonly toggleMarketInFavouriteUsingGETPath = '/api/v1/user/favourite/market/toggle/{marketId}';
  static readonly userFreezeAccountUsingGETPath = '/api/v1/user/freeze';
  static readonly geoInfoUsingGETPath = '/api/v1/user/geolocation';
  static readonly loginUsingPOSTPath = '/api/v1/user/login';
  static readonly uploadUserLogoUsingPOSTPath = '/api/v1/user/logo/upload';
  static readonly logoutUsingPOSTPath = '/api/v1/user/logout';
  static readonly changePasswordUsingPOSTPath = '/api/v1/user/password/change';
  static readonly requestPasswordResetUsingPOSTPath = '/api/v1/user/password/resend';
  static readonly setNewPasswordUsingPOSTPath = '/api/v1/user/password/set';
  static readonly checkTokenActualUsingGETPath = '/api/v1/user/password/{token}/check';
  static readonly getUserPrivacySettingsUsingGETPath = '/api/v1/user/privacy/settings';
  static readonly updateUserPrivacySettingsUsingPUTPath = '/api/v1/user/privacy/settings';
  static readonly getPrivacySettingsForUuidUsingGETPath = '/api/v1/user/privacy/settings-for-uuid';
  static readonly updateUsingPOSTPath = '/api/v1/user/profile/update';
  static readonly userPublicSettingsUsingGETPath = '/api/v1/user/public-profile';
  static readonly getUserInfoByReferralCodeUsingGET1Path = '/api/v1/user/referral';
  static readonly signupUsingPOSTPath = '/api/v1/user/registration';
  static readonly userSettingsUsingGETPath = '/api/v1/user/settings';
  static readonly bindingSocialNetworkUsingPUTPath = '/api/v1/user/social-binding/{type}';
  static readonly socialTestRedirectUsingGETPath = '/api/v1/user/social-test-redirect/{type}';
  static readonly socialLoginUsingGETPath = '/api/v1/user/social/{type}';
  static readonly updateUserWsSettingsUsingPUTPath = '/api/v1/user/user-ws-settings';
  static readonly checkUsernameUsingPOSTPath = '/api/v1/user/username/check';
  static readonly updateUsernameUsingPOSTPath = '/api/v1/user/username/update';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Confirm disabling 2FA
   *
   * curSidTs and specialMark are required for this call<br/>
   * @param params The `UserControllerService.ConfirmRemoveTwoFactorAuthUsingPOSTParams` containing the following parameters:
   *
   * - `provider`: provider
   *
   * - `from`: from
   *
   * @return OK
   */
  confirmRemoveTwoFactorAuthUsingPOSTResponse(params: UserControllerService.ConfirmRemoveTwoFactorAuthUsingPOSTParams): __Observable<__StrictHttpResponse<TwoFactorAuthUpdateTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.from;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/user/2fa/disable/${encodeURIComponent(String(params.provider))}/confirm`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TwoFactorAuthUpdateTOResponse>;
      })
    );
  }
  /**
   * Confirm disabling 2FA
   *
   * curSidTs and specialMark are required for this call<br/>
   * @param params The `UserControllerService.ConfirmRemoveTwoFactorAuthUsingPOSTParams` containing the following parameters:
   *
   * - `provider`: provider
   *
   * - `from`: from
   *
   * @return OK
   */
  confirmRemoveTwoFactorAuthUsingPOST(params: UserControllerService.ConfirmRemoveTwoFactorAuthUsingPOSTParams): __Observable<TwoFactorAuthUpdateTOResponse> {
    return this.confirmRemoveTwoFactorAuthUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as TwoFactorAuthUpdateTOResponse)
    );
  }

  /**
   * Send email to disable 2FA vector for an account
   *
   * Sends email with disable 2FA link<br/>
   * @param params The `UserControllerService.SendRemoveTwoFactorAuthUsingPOSTParams` containing the following parameters:
   *
   * - `provider`: provider
   *
   * - `form`: form
   */
  sendRemoveTwoFactorAuthUsingPOSTResponse(params: UserControllerService.SendRemoveTwoFactorAuthUsingPOSTParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/user/2fa/disable/${encodeURIComponent(String(params.provider))}/email`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Send email to disable 2FA vector for an account
   *
   * Sends email with disable 2FA link<br/>
   * @param params The `UserControllerService.SendRemoveTwoFactorAuthUsingPOSTParams` containing the following parameters:
   *
   * - `provider`: provider
   *
   * - `form`: form
   */
  sendRemoveTwoFactorAuthUsingPOST(params: UserControllerService.SendRemoveTwoFactorAuthUsingPOSTParams): __Observable<null> {
    return this.sendRemoveTwoFactorAuthUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Start enabling 2FA vector
   *
   * Returns secret and QR's code URL to scan with app or sends SMS. Current password is required to bind 2fa<br/>Throws errors: ACCESS_DENIED; TWO_FACTOR_VECTOR_ALREADY_ENABLED
   * @param params The `UserControllerService.AddTwoFactorAuthUsingPOSTParams` containing the following parameters:
   *
   * - `provider`: provider
   *
   * - `form`: form
   *
   * @return OK
   */
  addTwoFactorAuthUsingPOSTResponse(params: UserControllerService.AddTwoFactorAuthUsingPOSTParams): __Observable<__StrictHttpResponse<TwoFactorAuthAddTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/user/2fa/enable/${encodeURIComponent(String(params.provider))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TwoFactorAuthAddTOResponse>;
      })
    );
  }
  /**
   * Start enabling 2FA vector
   *
   * Returns secret and QR's code URL to scan with app or sends SMS. Current password is required to bind 2fa<br/>Throws errors: ACCESS_DENIED; TWO_FACTOR_VECTOR_ALREADY_ENABLED
   * @param params The `UserControllerService.AddTwoFactorAuthUsingPOSTParams` containing the following parameters:
   *
   * - `provider`: provider
   *
   * - `form`: form
   *
   * @return OK
   */
  addTwoFactorAuthUsingPOST(params: UserControllerService.AddTwoFactorAuthUsingPOSTParams): __Observable<TwoFactorAuthAddTOResponse> {
    return this.addTwoFactorAuthUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as TwoFactorAuthAddTOResponse)
    );
  }

  /**
   * Confirm enable 2FA
   *
   * curSidTs and specialMark are required for this call<br/>Throws errors: ACCESS_DENIED; TWO_FACTOR_VECTOR_ALREADY_ENABLED
   * @param params The `UserControllerService.ConfirmEnableTwoFactorAuthUsingPOSTParams` containing the following parameters:
   *
   * - `provider`: provider
   *
   * - `from`: from
   *
   * @return OK
   */
  confirmEnableTwoFactorAuthUsingPOSTResponse(params: UserControllerService.ConfirmEnableTwoFactorAuthUsingPOSTParams): __Observable<__StrictHttpResponse<TwoFactorAuthUpdateTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.from;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/user/2fa/enable/${encodeURIComponent(String(params.provider))}/confirm`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TwoFactorAuthUpdateTOResponse>;
      })
    );
  }
  /**
   * Confirm enable 2FA
   *
   * curSidTs and specialMark are required for this call<br/>Throws errors: ACCESS_DENIED; TWO_FACTOR_VECTOR_ALREADY_ENABLED
   * @param params The `UserControllerService.ConfirmEnableTwoFactorAuthUsingPOSTParams` containing the following parameters:
   *
   * - `provider`: provider
   *
   * - `from`: from
   *
   * @return OK
   */
  confirmEnableTwoFactorAuthUsingPOST(params: UserControllerService.ConfirmEnableTwoFactorAuthUsingPOSTParams): __Observable<TwoFactorAuthUpdateTOResponse> {
    return this.confirmEnableTwoFactorAuthUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as TwoFactorAuthUpdateTOResponse)
    );
  }

  /**
   * Enable 2FA request
   *
   * Sends email with enable 2FA link<br/>Throws errors: ACCESS_DENIED
   * @param params The `UserControllerService.SendVerificationEmailTwoFactorAuthUsingPOSTParams` containing the following parameters:
   *
   * - `provider`: provider
   *
   * - `form`: form
   */
  sendVerificationEmailTwoFactorAuthUsingPOSTResponse(params: UserControllerService.SendVerificationEmailTwoFactorAuthUsingPOSTParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/user/2fa/enable/${encodeURIComponent(String(params.provider))}/email`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Enable 2FA request
   *
   * Sends email with enable 2FA link<br/>Throws errors: ACCESS_DENIED
   * @param params The `UserControllerService.SendVerificationEmailTwoFactorAuthUsingPOSTParams` containing the following parameters:
   *
   * - `provider`: provider
   *
   * - `form`: form
   */
  sendVerificationEmailTwoFactorAuthUsingPOST(params: UserControllerService.SendVerificationEmailTwoFactorAuthUsingPOSTParams): __Observable<null> {
    return this.sendVerificationEmailTwoFactorAuthUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Resend SMS for 2FA auth
   *
   * Throws errors: VALIDATION_ERROR
   * @param token token
   * @return OK
   */
  resendSmsTwoFaCodeUsingGETResponse(token: string): __Observable<__StrictHttpResponse<SendSmsTwoFaCodeTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (token != null) __params = __params.set('token', token.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/user/2fa/sms/resend`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SendSmsTwoFaCodeTOResponse>;
      })
    );
  }
  /**
   * Resend SMS for 2FA auth
   *
   * Throws errors: VALIDATION_ERROR
   * @param token token
   * @return OK
   */
  resendSmsTwoFaCodeUsingGET(token: string): __Observable<SendSmsTwoFaCodeTOResponse> {
    return this.resendSmsTwoFaCodeUsingGETResponse(token).pipe(
      __map(_r => _r.body as SendSmsTwoFaCodeTOResponse)
    );
  }

  /**
   * Login with 2FA code
   *
   * Throws errors: UNAUTHORIZED; ACCESS_DENIED; VALIDATION_ERROR; TWO_FACTOR_INVALID_TOKEN; TWO_FACTOR_VECTOR_DISABLED; TWO_FACTOR_TOKEN_EXPIRED; TWO_FACTOR_INVALID_CODE
   * @param params The `UserControllerService.LoginTwoFactorAuthUsingPOSTParams` containing the following parameters:
   *
   * - `provider`: Specify two factor vector
   *
   * - `form`: form
   *
   * @return OK
   */
  loginTwoFactorAuthUsingPOSTResponse(params: UserControllerService.LoginTwoFactorAuthUsingPOSTParams): __Observable<__StrictHttpResponse<LoginUserTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/user/2fa/${encodeURIComponent(String(params.provider))}/login`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LoginUserTOResponse>;
      })
    );
  }
  /**
   * Login with 2FA code
   *
   * Throws errors: UNAUTHORIZED; ACCESS_DENIED; VALIDATION_ERROR; TWO_FACTOR_INVALID_TOKEN; TWO_FACTOR_VECTOR_DISABLED; TWO_FACTOR_TOKEN_EXPIRED; TWO_FACTOR_INVALID_CODE
   * @param params The `UserControllerService.LoginTwoFactorAuthUsingPOSTParams` containing the following parameters:
   *
   * - `provider`: Specify two factor vector
   *
   * - `form`: form
   *
   * @return OK
   */
  loginTwoFactorAuthUsingPOST(params: UserControllerService.LoginTwoFactorAuthUsingPOSTParams): __Observable<LoginUserTOResponse> {
    return this.loginTwoFactorAuthUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as LoginUserTOResponse)
    );
  }

  /**
   * Start disabling 2FA vector
   *
   * Sends SMS code for disabling, does nothing for google<br/>Throws errors: TWO_FACTOR_VECTOR_DISABLED
   * @param provider Specify two factor vector
   * @return OK
   */
  startRemoveUsingPOSTResponse(provider: 'UNRECOGNIZED' | 'google' | 'sms'): __Observable<__StrictHttpResponse<TwoFactorAuthStartDisableTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/user/2fa/${encodeURIComponent(String(provider))}/st-cross-out`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TwoFactorAuthStartDisableTOResponse>;
      })
    );
  }
  /**
   * Start disabling 2FA vector
   *
   * Sends SMS code for disabling, does nothing for google<br/>Throws errors: TWO_FACTOR_VECTOR_DISABLED
   * @param provider Specify two factor vector
   * @return OK
   */
  startRemoveUsingPOST(provider: 'UNRECOGNIZED' | 'google' | 'sms'): __Observable<TwoFactorAuthStartDisableTOResponse> {
    return this.startRemoveUsingPOSTResponse(provider).pipe(
      __map(_r => _r.body as TwoFactorAuthStartDisableTOResponse)
    );
  }

  /**
   * Accept ToS
   *
   * Throws errors: VALIDATION_ERROR
   * @param tosVersion tosVersion
   * @return OK
   */
  acceptTosVersionUsingGETResponse(tosVersion: string): __Observable<__StrictHttpResponse<UserAcceptTosVersionTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/user/accept-tos/${encodeURIComponent(String(tosVersion))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserAcceptTosVersionTO>;
      })
    );
  }
  /**
   * Accept ToS
   *
   * Throws errors: VALIDATION_ERROR
   * @param tosVersion tosVersion
   * @return OK
   */
  acceptTosVersionUsingGET(tosVersion: string): __Observable<UserAcceptTosVersionTO> {
    return this.acceptTosVersionUsingGETResponse(tosVersion).pipe(
      __map(_r => _r.body as UserAcceptTosVersionTO)
    );
  }

  /**
   * Activate user referral
   * @param form form
   * @return OK
   */
  activateReferralUsingPUTResponse(form: UpdateUserInfoForm): __Observable<__StrictHttpResponse<UserInfoTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/user/activate-referral`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserInfoTOResponse>;
      })
    );
  }
  /**
   * Activate user referral
   * @param form form
   * @return OK
   */
  activateReferralUsingPUT(form: UpdateUserInfoForm): __Observable<UserInfoTOResponse> {
    return this.activateReferralUsingPUTResponse(form).pipe(
      __map(_r => _r.body as UserInfoTOResponse)
    );
  }

  /**
   * Get user activity history
   *
   * Throws errors: ACCESS_DENIED
   * @param params The `UserControllerService.UserActivityHistoryUsingGETParams` containing the following parameters:
   *
   * - `type`: type
   *
   * - `offset`: offset
   *
   * - `limit`: limit
   *
   * - `dateTo`: dateTo
   *
   * - `dateFrom`: dateFrom
   *
   * @return OK
   */
  userActivityHistoryUsingGETResponse(params: UserControllerService.UserActivityHistoryUsingGETParams): __Observable<__StrictHttpResponse<UserActivityTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.type != null) __params = __params.set('type', params.type.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.dateTo != null) __params = __params.set('dateTo', params.dateTo.toString());
    if (params.dateFrom != null) __params = __params.set('dateFrom', params.dateFrom.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/user/activity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserActivityTOListResponse>;
      })
    );
  }
  /**
   * Get user activity history
   *
   * Throws errors: ACCESS_DENIED
   * @param params The `UserControllerService.UserActivityHistoryUsingGETParams` containing the following parameters:
   *
   * - `type`: type
   *
   * - `offset`: offset
   *
   * - `limit`: limit
   *
   * - `dateTo`: dateTo
   *
   * - `dateFrom`: dateFrom
   *
   * @return OK
   */
  userActivityHistoryUsingGET(params: UserControllerService.UserActivityHistoryUsingGETParams): __Observable<UserActivityTOListResponse> {
    return this.userActivityHistoryUsingGETResponse(params).pipe(
      __map(_r => _r.body as UserActivityTOListResponse)
    );
  }

  /**
   * Email confirmation
   *
   * Throws errors: VALIDATION_ERROR; INVALID_LOGIN_OR_PASSWORD; EMAIL_ALREADY_CONFIRMED; EMAIL_CONFIRM_TOKEN_EXPIRED; EMAIL_CONFIRM_BAD_TOKEN
   * @param form form
   * @return OK
   */
  confirmEmailUsingPOSTResponse(form: ConfirmEmailForm): __Observable<__StrictHttpResponse<ConfirmEmailTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/user/confirmation/accept`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ConfirmEmailTOResponse>;
      })
    );
  }
  /**
   * Email confirmation
   *
   * Throws errors: VALIDATION_ERROR; INVALID_LOGIN_OR_PASSWORD; EMAIL_ALREADY_CONFIRMED; EMAIL_CONFIRM_TOKEN_EXPIRED; EMAIL_CONFIRM_BAD_TOKEN
   * @param form form
   * @return OK
   */
  confirmEmailUsingPOST(form: ConfirmEmailForm): __Observable<ConfirmEmailTOResponse> {
    return this.confirmEmailUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as ConfirmEmailTOResponse)
    );
  }

  /**
   * Resend email confirmation
   *
   * Throws errors: VALIDATION_ERROR; TOO_OFTEN; INVALID_LOGIN_OR_PASSWORD; EMAIL_ALREADY_CONFIRMED
   * @param form form
   * @return OK
   */
  resendConfirmationEmailUsingPOST1Response(form: ResendEmailConfirmationForm): __Observable<__StrictHttpResponse<ResendConfirmationEmailTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/user/confirmation/resend`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResendConfirmationEmailTOResponse>;
      })
    );
  }
  /**
   * Resend email confirmation
   *
   * Throws errors: VALIDATION_ERROR; TOO_OFTEN; INVALID_LOGIN_OR_PASSWORD; EMAIL_ALREADY_CONFIRMED
   * @param form form
   * @return OK
   */
  resendConfirmationEmailUsingPOST1(form: ResendEmailConfirmationForm): __Observable<ResendConfirmationEmailTOResponse> {
    return this.resendConfirmationEmailUsingPOST1Response(form).pipe(
      __map(_r => _r.body as ResendConfirmationEmailTOResponse)
    );
  }

  /**
   * Check user exist
   * @param form form
   * @return OK
   */
  checkUserExistUsingPOSTResponse(form: CheckUserExistForm): __Observable<__StrictHttpResponse<CheckUserExistTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/user/exists`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CheckUserExistTOResponse>;
      })
    );
  }
  /**
   * Check user exist
   * @param form form
   * @return OK
   */
  checkUserExistUsingPOST(form: CheckUserExistForm): __Observable<CheckUserExistTOResponse> {
    return this.checkUserExistUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as CheckUserExistTOResponse)
    );
  }

  /**
   * Get user's favourites markets
   *
   * Throws errors: ACCESS_DENIED; VALIDATION_ERROR
   * @return OK
   */
  favouriteMarketsUsingGETResponse(): __Observable<__StrictHttpResponse<Array<number>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/user/favourite/market/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<number>>;
      })
    );
  }
  /**
   * Get user's favourites markets
   *
   * Throws errors: ACCESS_DENIED; VALIDATION_ERROR
   * @return OK
   */
  favouriteMarketsUsingGET(): __Observable<Array<number>> {
    return this.favouriteMarketsUsingGETResponse().pipe(
      __map(_r => _r.body as Array<number>)
    );
  }

  /**
   * Toggle market in favourite
   *
   * Throws errors: ACCESS_DENIED; VALIDATION_ERROR
   * @param marketId marketId
   * @return OK
   */
  toggleMarketInFavouriteUsingGETResponse(marketId: number): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/user/favourite/market/toggle/${encodeURIComponent(String(marketId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Toggle market in favourite
   *
   * Throws errors: ACCESS_DENIED; VALIDATION_ERROR
   * @param marketId marketId
   * @return OK
   */
  toggleMarketInFavouriteUsingGET(marketId: number): __Observable<{}> {
    return this.toggleMarketInFavouriteUsingGETResponse(marketId).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Freeze account
   *
   * Throws errors: ACCESS_DENIED
   * @return OK
   */
  userFreezeAccountUsingGETResponse(): __Observable<__StrictHttpResponse<AccountTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/user/freeze`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AccountTO>;
      })
    );
  }
  /**
   * Freeze account
   *
   * Throws errors: ACCESS_DENIED
   * @return OK
   */
  userFreezeAccountUsingGET(): __Observable<AccountTO> {
    return this.userFreezeAccountUsingGETResponse().pipe(
      __map(_r => _r.body as AccountTO)
    );
  }

  /**
   * Get current geo info by IP
   * @param ipAddress ipAddress
   * @return OK
   */
  geoInfoUsingGETResponse(ipAddress?: string): __Observable<__StrictHttpResponse<GeoIPTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (ipAddress != null) __params = __params.set('ipAddress', ipAddress.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/user/geolocation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GeoIPTOResponse>;
      })
    );
  }
  /**
   * Get current geo info by IP
   * @param ipAddress ipAddress
   * @return OK
   */
  geoInfoUsingGET(ipAddress?: string): __Observable<GeoIPTOResponse> {
    return this.geoInfoUsingGETResponse(ipAddress).pipe(
      __map(_r => _r.body as GeoIPTOResponse)
    );
  }

  /**
   * Login
   *
   * Throws errors: VALIDATION_ERROR; INVALID_LOGIN_OR_PASSWORD; RECAPTCHA_ERROR
   * @param form form
   * @return OK
   */
  loginUsingPOSTResponse(form: LoginForm): __Observable<__StrictHttpResponse<LoginUserTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/user/login`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LoginUserTOResponse>;
      })
    );
  }
  /**
   * Login
   *
   * Throws errors: VALIDATION_ERROR; INVALID_LOGIN_OR_PASSWORD; RECAPTCHA_ERROR
   * @param form form
   * @return OK
   */
  loginUsingPOST(form: LoginForm): __Observable<LoginUserTOResponse> {
    return this.loginUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as LoginUserTOResponse)
    );
  }

  /**
   * Upload user logo
   *
   * Throws errors: ACCESS_DENIED; VALIDATION_ERROR
   * @param file undefined
   * @return OK
   */
  uploadUserLogoUsingPOSTResponse(file?: Blob): __Observable<__StrictHttpResponse<UserProfileTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (file != null) { __formData.append('file', file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/user/logo/upload`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserProfileTOResponse>;
      })
    );
  }
  /**
   * Upload user logo
   *
   * Throws errors: ACCESS_DENIED; VALIDATION_ERROR
   * @param file undefined
   * @return OK
   */
  uploadUserLogoUsingPOST(file?: Blob): __Observable<UserProfileTOResponse> {
    return this.uploadUserLogoUsingPOSTResponse(file).pipe(
      __map(_r => _r.body as UserProfileTOResponse)
    );
  }

  /**
   * Logout
   * @return OK
   */
  logoutUsingPOSTResponse(): __Observable<__StrictHttpResponse<{[key: string]: boolean}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/user/logout`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: boolean}>;
      })
    );
  }
  /**
   * Logout
   * @return OK
   */
  logoutUsingPOST(): __Observable<{[key: string]: boolean}> {
    return this.logoutUsingPOSTResponse().pipe(
      __map(_r => _r.body as {[key: string]: boolean})
    );
  }

  /**
   * Change login password
   *
   * Throws errors: ACCESS_DENIED; INVALID_PASSWORD; PASSWORDS_ARE_SAME
   * @param form form
   * @return OK
   */
  changePasswordUsingPOSTResponse(form: PasswordChangeForm): __Observable<__StrictHttpResponse<PasswordChangeTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/user/password/change`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PasswordChangeTOResponse>;
      })
    );
  }
  /**
   * Change login password
   *
   * Throws errors: ACCESS_DENIED; INVALID_PASSWORD; PASSWORDS_ARE_SAME
   * @param form form
   * @return OK
   */
  changePasswordUsingPOST(form: PasswordChangeForm): __Observable<PasswordChangeTOResponse> {
    return this.changePasswordUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as PasswordChangeTOResponse)
    );
  }

  /**
   * Request reset password token
   *
   * Throws errors: VALIDATION_ERROR; USER_NOT_FOUND; TOO_OFTEN
   * @param form form
   * @return OK
   */
  requestPasswordResetUsingPOSTResponse(form: PasswordResetRequest): __Observable<__StrictHttpResponse<PasswordResetRequestEmailTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/user/password/resend`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PasswordResetRequestEmailTOResponse>;
      })
    );
  }
  /**
   * Request reset password token
   *
   * Throws errors: VALIDATION_ERROR; USER_NOT_FOUND; TOO_OFTEN
   * @param form form
   * @return OK
   */
  requestPasswordResetUsingPOST(form: PasswordResetRequest): __Observable<PasswordResetRequestEmailTOResponse> {
    return this.requestPasswordResetUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as PasswordResetRequestEmailTOResponse)
    );
  }

  /**
   * Set a new password
   *
   * Throws errors: VALIDATION_ERROR; USER_NOT_FOUND; EXPIRED_TOKEN; BAD_TOKEN
   * @param form form
   * @return OK
   */
  setNewPasswordUsingPOSTResponse(form: PasswordSetNewRequest): __Observable<__StrictHttpResponse<LoginUserTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/user/password/set`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LoginUserTOResponse>;
      })
    );
  }
  /**
   * Set a new password
   *
   * Throws errors: VALIDATION_ERROR; USER_NOT_FOUND; EXPIRED_TOKEN; BAD_TOKEN
   * @param form form
   * @return OK
   */
  setNewPasswordUsingPOST(form: PasswordSetNewRequest): __Observable<LoginUserTOResponse> {
    return this.setNewPasswordUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as LoginUserTOResponse)
    );
  }

  /**
   * checkTokenActual
   * @param token token
   */
  checkTokenActualUsingGETResponse(token: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/user/password/${encodeURIComponent(String(token))}/check`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * checkTokenActual
   * @param token token
   */
  checkTokenActualUsingGET(token: string): __Observable<null> {
    return this.checkTokenActualUsingGETResponse(token).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get user privacy settings
   * @return OK
   */
  getUserPrivacySettingsUsingGETResponse(): __Observable<__StrictHttpResponse<UserPrivacySettingsTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/user/privacy/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserPrivacySettingsTO>;
      })
    );
  }
  /**
   * Get user privacy settings
   * @return OK
   */
  getUserPrivacySettingsUsingGET(): __Observable<UserPrivacySettingsTO> {
    return this.getUserPrivacySettingsUsingGETResponse().pipe(
      __map(_r => _r.body as UserPrivacySettingsTO)
    );
  }

  /**
   * Update user privacy settings
   * @param params The `UserControllerService.UpdateUserPrivacySettingsUsingPUTParams` containing the following parameters:
   *
   * - `socialMediaIconShow`: socialMediaIconShow
   *
   * - `nameHide`: nameHide
   *
   * - `includeInLeaderBoard`: includeInLeaderBoard
   *
   * - `hideFromNetwork`: hideFromNetwork
   *
   * @return OK
   */
  updateUserPrivacySettingsUsingPUTResponse(params: UserControllerService.UpdateUserPrivacySettingsUsingPUTParams): __Observable<__StrictHttpResponse<UserPrivacySettingsTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.socialMediaIconShow != null) __params = __params.set('socialMediaIconShow', params.socialMediaIconShow.toString());
    if (params.nameHide != null) __params = __params.set('nameHide', params.nameHide.toString());
    if (params.includeInLeaderBoard != null) __params = __params.set('includeInLeaderBoard', params.includeInLeaderBoard.toString());
    if (params.hideFromNetwork != null) __params = __params.set('hideFromNetwork', params.hideFromNetwork.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/user/privacy/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserPrivacySettingsTO>;
      })
    );
  }
  /**
   * Update user privacy settings
   * @param params The `UserControllerService.UpdateUserPrivacySettingsUsingPUTParams` containing the following parameters:
   *
   * - `socialMediaIconShow`: socialMediaIconShow
   *
   * - `nameHide`: nameHide
   *
   * - `includeInLeaderBoard`: includeInLeaderBoard
   *
   * - `hideFromNetwork`: hideFromNetwork
   *
   * @return OK
   */
  updateUserPrivacySettingsUsingPUT(params: UserControllerService.UpdateUserPrivacySettingsUsingPUTParams): __Observable<UserPrivacySettingsTO> {
    return this.updateUserPrivacySettingsUsingPUTResponse(params).pipe(
      __map(_r => _r.body as UserPrivacySettingsTO)
    );
  }

  /**
   * Get privacy settings for UUID
   * @param uuid uuid
   * @return OK
   */
  getPrivacySettingsForUuidUsingGETResponse(uuid: string): __Observable<__StrictHttpResponse<UserPrivacySettingsTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (uuid != null) __params = __params.set('uuid', uuid.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/user/privacy/settings-for-uuid`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserPrivacySettingsTO>;
      })
    );
  }
  /**
   * Get privacy settings for UUID
   * @param uuid uuid
   * @return OK
   */
  getPrivacySettingsForUuidUsingGET(uuid: string): __Observable<UserPrivacySettingsTO> {
    return this.getPrivacySettingsForUuidUsingGETResponse(uuid).pipe(
      __map(_r => _r.body as UserPrivacySettingsTO)
    );
  }

  /**
   * Update user info
   *
   * Throws errors: ACCESS_DENIED; VALIDATION_ERROR
   * @param form form
   * @return OK
   */
  updateUsingPOSTResponse(form: UpdateUserInfoForm): __Observable<__StrictHttpResponse<UserProfileTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/user/profile/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserProfileTOResponse>;
      })
    );
  }
  /**
   * Update user info
   *
   * Throws errors: ACCESS_DENIED; VALIDATION_ERROR
   * @param form form
   * @return OK
   */
  updateUsingPOST(form: UpdateUserInfoForm): __Observable<UserProfileTOResponse> {
    return this.updateUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as UserProfileTOResponse)
    );
  }

  /**
   * Get User public profile
   * @param uuid uuid
   * @return OK
   */
  userPublicSettingsUsingGETResponse(uuid: string): __Observable<__StrictHttpResponse<PublicProfileResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (uuid != null) __params = __params.set('uuid', uuid.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/user/public-profile`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PublicProfileResponse>;
      })
    );
  }
  /**
   * Get User public profile
   * @param uuid uuid
   * @return OK
   */
  userPublicSettingsUsingGET(uuid: string): __Observable<PublicProfileResponse> {
    return this.userPublicSettingsUsingGETResponse(uuid).pipe(
      __map(_r => _r.body as PublicProfileResponse)
    );
  }

  /**
   * getUserInfoByReferralCode
   * @param referralCode referralCode
   * @return OK
   */
  getUserInfoByReferralCodeUsingGET1Response(referralCode: string): __Observable<__StrictHttpResponse<UserInfoTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (referralCode != null) __params = __params.set('referralCode', referralCode.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/user/referral`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserInfoTOResponse>;
      })
    );
  }
  /**
   * getUserInfoByReferralCode
   * @param referralCode referralCode
   * @return OK
   */
  getUserInfoByReferralCodeUsingGET1(referralCode: string): __Observable<UserInfoTOResponse> {
    return this.getUserInfoByReferralCodeUsingGET1Response(referralCode).pipe(
      __map(_r => _r.body as UserInfoTOResponse)
    );
  }

  /**
   * signup
   *
   * Throws errors: VALIDATION_ERROR; DUPLICATED_EMAIL; DUPLICATED_USERNAME; RECAPTCHA_ERROR
   * @param params The `UserControllerService.SignupUsingPOSTParams` containing the following parameters:
   *
   * - `form`: form
   *
   * - `offid`: offid
   *
   * - `goal`: goal
   *
   * - `click_hash`: click_hash
   *
   * @return OK
   */
  signupUsingPOSTResponse(params: UserControllerService.SignupUsingPOSTParams): __Observable<__StrictHttpResponse<LoginUserTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.form;
    if (params.offid != null) __params = __params.set('offid', params.offid.toString());
    if (params.goal != null) __params = __params.set('goal', params.goal.toString());
    if (params.clickHash != null) __params = __params.set('click_hash', params.clickHash.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/user/registration`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LoginUserTOResponse>;
      })
    );
  }
  /**
   * signup
   *
   * Throws errors: VALIDATION_ERROR; DUPLICATED_EMAIL; DUPLICATED_USERNAME; RECAPTCHA_ERROR
   * @param params The `UserControllerService.SignupUsingPOSTParams` containing the following parameters:
   *
   * - `form`: form
   *
   * - `offid`: offid
   *
   * - `goal`: goal
   *
   * - `click_hash`: click_hash
   *
   * @return OK
   */
  signupUsingPOST(params: UserControllerService.SignupUsingPOSTParams): __Observable<LoginUserTOResponse> {
    return this.signupUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as LoginUserTOResponse)
    );
  }

  /**
   * Get current User settings
   *
   * Throws errors: ACCESS_DENIED; VALIDATION_ERROR
   * @return OK
   */
  userSettingsUsingGETResponse(): __Observable<__StrictHttpResponse<UserInfoTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/user/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserInfoTOResponse>;
      })
    );
  }
  /**
   * Get current User settings
   *
   * Throws errors: ACCESS_DENIED; VALIDATION_ERROR
   * @return OK
   */
  userSettingsUsingGET(): __Observable<UserInfoTOResponse> {
    return this.userSettingsUsingGETResponse().pipe(
      __map(_r => _r.body as UserInfoTOResponse)
    );
  }

  /**
   * Binding social networks
   * @param params The `UserControllerService.BindingSocialNetworkUsingPUTParams` containing the following parameters:
   *
   * - `type`: type
   *
   * - `token`: token
   *
   * @return OK
   */
  bindingSocialNetworkUsingPUTResponse(params: UserControllerService.BindingSocialNetworkUsingPUTParams): __Observable<__StrictHttpResponse<UserInfoTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.token != null) __params = __params.set('token', params.token.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/user/social-binding/${encodeURIComponent(String(params.type))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserInfoTO>;
      })
    );
  }
  /**
   * Binding social networks
   * @param params The `UserControllerService.BindingSocialNetworkUsingPUTParams` containing the following parameters:
   *
   * - `type`: type
   *
   * - `token`: token
   *
   * @return OK
   */
  bindingSocialNetworkUsingPUT(params: UserControllerService.BindingSocialNetworkUsingPUTParams): __Observable<UserInfoTO> {
    return this.bindingSocialNetworkUsingPUTResponse(params).pipe(
      __map(_r => _r.body as UserInfoTO)
    );
  }

  /**
   * socialTestRedirect
   * @param params The `UserControllerService.SocialTestRedirectUsingGETParams` containing the following parameters:
   *
   * - `type`: type
   *
   * - `state`: state
   *
   * - `code`: code
   *
   * @return OK
   */
  socialTestRedirectUsingGETResponse(params: UserControllerService.SocialTestRedirectUsingGETParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.state != null) __params = __params.set('state', params.state.toString());
    if (params.code != null) __params = __params.set('code', params.code.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/user/social-test-redirect/${encodeURIComponent(String(params.type))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * socialTestRedirect
   * @param params The `UserControllerService.SocialTestRedirectUsingGETParams` containing the following parameters:
   *
   * - `type`: type
   *
   * - `state`: state
   *
   * - `code`: code
   *
   * @return OK
   */
  socialTestRedirectUsingGET(params: UserControllerService.SocialTestRedirectUsingGETParams): __Observable<string> {
    return this.socialTestRedirectUsingGETResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * socialLogin
   * @param params The `UserControllerService.SocialLoginUsingGETParams` containing the following parameters:
   *
   * - `type`: type
   *
   * - `token`: token
   *
   * @return OK
   */
  socialLoginUsingGETResponse(params: UserControllerService.SocialLoginUsingGETParams): __Observable<__StrictHttpResponse<LoginUserTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.token != null) __params = __params.set('token', params.token.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/user/social/${encodeURIComponent(String(params.type))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LoginUserTOResponse>;
      })
    );
  }
  /**
   * socialLogin
   * @param params The `UserControllerService.SocialLoginUsingGETParams` containing the following parameters:
   *
   * - `type`: type
   *
   * - `token`: token
   *
   * @return OK
   */
  socialLoginUsingGET(params: UserControllerService.SocialLoginUsingGETParams): __Observable<LoginUserTOResponse> {
    return this.socialLoginUsingGETResponse(params).pipe(
      __map(_r => _r.body as LoginUserTOResponse)
    );
  }

  /**
   * Update user WS settings
   * @param params The `UserControllerService.UpdateUserWsSettingsUsingPUTParams` containing the following parameters:
   *
   * - `showNftBuy`: showNftBuy
   *
   * - `showMysteryBoxOpen`: showMysteryBoxOpen
   *
   * @return OK
   */
  updateUserWsSettingsUsingPUTResponse(params: UserControllerService.UpdateUserWsSettingsUsingPUTParams): __Observable<__StrictHttpResponse<UserInfoTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.showNftBuy != null) __params = __params.set('showNftBuy', params.showNftBuy.toString());
    if (params.showMysteryBoxOpen != null) __params = __params.set('showMysteryBoxOpen', params.showMysteryBoxOpen.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/user/user-ws-settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserInfoTO>;
      })
    );
  }
  /**
   * Update user WS settings
   * @param params The `UserControllerService.UpdateUserWsSettingsUsingPUTParams` containing the following parameters:
   *
   * - `showNftBuy`: showNftBuy
   *
   * - `showMysteryBoxOpen`: showMysteryBoxOpen
   *
   * @return OK
   */
  updateUserWsSettingsUsingPUT(params: UserControllerService.UpdateUserWsSettingsUsingPUTParams): __Observable<UserInfoTO> {
    return this.updateUserWsSettingsUsingPUTResponse(params).pipe(
      __map(_r => _r.body as UserInfoTO)
    );
  }

  /**
   * Check if username changed
   *
   * Throws errors: ACCESS_DENIED; VALIDATION_ERROR
   * @param username username
   * @return OK
   */
  checkUsernameUsingPOSTResponse(username: string): __Observable<__StrictHttpResponse<UsernameCheckResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (username != null) __params = __params.set('username', username.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/user/username/check`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UsernameCheckResponse>;
      })
    );
  }
  /**
   * Check if username changed
   *
   * Throws errors: ACCESS_DENIED; VALIDATION_ERROR
   * @param username username
   * @return OK
   */
  checkUsernameUsingPOST(username: string): __Observable<UsernameCheckResponse> {
    return this.checkUsernameUsingPOSTResponse(username).pipe(
      __map(_r => _r.body as UsernameCheckResponse)
    );
  }

  /**
   * Update username
   *
   * Throws errors: ACCESS_DENIED; VALIDATION_ERROR
   * @param newUsername newUsername
   * @return OK
   */
  updateUsernameUsingPOSTResponse(newUsername: string): __Observable<__StrictHttpResponse<UserInfoTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (newUsername != null) __params = __params.set('newUsername', newUsername.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/user/username/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserInfoTOResponse>;
      })
    );
  }
  /**
   * Update username
   *
   * Throws errors: ACCESS_DENIED; VALIDATION_ERROR
   * @param newUsername newUsername
   * @return OK
   */
  updateUsernameUsingPOST(newUsername: string): __Observable<UserInfoTOResponse> {
    return this.updateUsernameUsingPOSTResponse(newUsername).pipe(
      __map(_r => _r.body as UserInfoTOResponse)
    );
  }
}

module UserControllerService {

  /**
   * Parameters for confirmRemoveTwoFactorAuthUsingPOST
   */
  export interface ConfirmRemoveTwoFactorAuthUsingPOSTParams {

    /**
     * provider
     */
    provider: 'UNRECOGNIZED' | 'google' | 'sms';

    /**
     * from
     */
    from: ConfirmTwoFactorFrom;
  }

  /**
   * Parameters for sendRemoveTwoFactorAuthUsingPOST
   */
  export interface SendRemoveTwoFactorAuthUsingPOSTParams {

    /**
     * provider
     */
    provider: 'UNRECOGNIZED' | 'google' | 'sms';

    /**
     * form
     */
    form: RemoveTwoFactorAuthForm;
  }

  /**
   * Parameters for addTwoFactorAuthUsingPOST
   */
  export interface AddTwoFactorAuthUsingPOSTParams {

    /**
     * provider
     */
    provider: 'UNRECOGNIZED' | 'google' | 'sms';

    /**
     * form
     */
    form: PasswordForm;
  }

  /**
   * Parameters for confirmEnableTwoFactorAuthUsingPOST
   */
  export interface ConfirmEnableTwoFactorAuthUsingPOSTParams {

    /**
     * provider
     */
    provider: 'UNRECOGNIZED' | 'google' | 'sms';

    /**
     * from
     */
    from: ConfirmTwoFactorFrom;
  }

  /**
   * Parameters for sendVerificationEmailTwoFactorAuthUsingPOST
   */
  export interface SendVerificationEmailTwoFactorAuthUsingPOSTParams {

    /**
     * provider
     */
    provider: 'UNRECOGNIZED' | 'google' | 'sms';

    /**
     * form
     */
    form: BindTwoFactorAuthForm;
  }

  /**
   * Parameters for loginTwoFactorAuthUsingPOST
   */
  export interface LoginTwoFactorAuthUsingPOSTParams {

    /**
     * Specify two factor vector
     */
    provider: 'UNRECOGNIZED' | 'google' | 'sms';

    /**
     * form
     */
    form: LoginTwoFactorAuthForm;
  }

  /**
   * Parameters for userActivityHistoryUsingGET
   */
  export interface UserActivityHistoryUsingGETParams {

    /**
     * type
     */
    type?: 'ADMIN_FREEZE_USER' | 'ADMIN_UNFREEZE_USER' | 'API_KEY_DISABLED' | 'API_KEY_EMAIL_CONFIRMATION' | 'API_KEY_EMAIL_CONFIRMATION_REQUEST' | 'API_KEY_ENABLED' | 'API_KEY_REVOKE_CONFIRMATION' | 'API_KEY_REVOKE_CONFIRMATION_REQUEST' | 'DEPOSIT_ADDR_REQUESTED' | 'EMAIL_CONFIRM' | 'EMAIL_CONFIRMATION_REQUEST' | 'EOS_ACCOUNT_REQUEST' | 'FREEZE_USER' | 'GAME_3D' | 'GOOGLE_2FA_DISABLE' | 'GOOGLE_2FA_ENABLE' | 'GOOGLE_2FA_LOGIN' | 'GOOGLE_2FA_START_DISABLE' | 'GOOGLE_2FA_START_ENABLE' | 'INITIAL_STREET_BUY' | 'KYC_IDENTITY_VERIFICATION_CORRECTED' | 'KYC_IDENTITY_VERIFICATION_IN_PROGRESS' | 'KYC_IDENTITY_VERIFICATION_PASSED' | 'KYC_IDENTITY_VERIFICATION_PENDING' | 'KYC_IDENTITY_VERIFICATION_REJECTED' | 'KYC_IDENTITY_VERIFICATION_SENT' | 'KYC_IDENTITY_VERIFICATION_UPDATED' | 'LOGOUT' | 'NEW_DEPOSIT_TRANSACTION' | 'NONE' | 'ORDER_CANCELLED' | 'ORDER_CREATED' | 'ORDER_EXPIRATION_TIME_UPDATED' | 'PASSWORD_CHANGE' | 'PASSWORD_LOGIN' | 'PASSWORD_RESET' | 'PASSWORD_RESET_REQUEST' | 'PROFILE_UPDATED' | 'REGISTERED_NEW_IP' | 'SIGNUP' | 'SMS_2FA_DISABLE' | 'SMS_2FA_ENABLE' | 'SMS_2FA_LOGIN' | 'SMS_2FA_START_DISABLE' | 'SMS_2FA_START_ENABLE' | 'SOCIAL_LOGIN' | 'TWO_FA_DISABLE_CONFIRM' | 'TWO_FA_DISABLE_EMAIL' | 'TWO_FA_ENABLE_CONFIRM' | 'TWO_FA_ENABLE_EMAIL' | 'UNRECOGNIZED' | 'VOUCHER_CREATE' | 'VOUCHER_REDEEM' | 'WITHDRAWAL_CANCELLED' | 'WITHDRAWAL_REPEATED' | 'WITHDRAWAL_REQUESTED' | 'WITHDRAW_EMAIL_CONFIRM';

    /**
     * offset
     */
    offset?: number;

    /**
     * limit
     */
    limit?: number;

    /**
     * dateTo
     */
    dateTo?: string;

    /**
     * dateFrom
     */
    dateFrom?: string;
  }

  /**
   * Parameters for updateUserPrivacySettingsUsingPUT
   */
  export interface UpdateUserPrivacySettingsUsingPUTParams {

    /**
     * socialMediaIconShow
     */
    socialMediaIconShow: boolean;

    /**
     * nameHide
     */
    nameHide: boolean;

    /**
     * includeInLeaderBoard
     */
    includeInLeaderBoard: boolean;

    /**
     * hideFromNetwork
     */
    hideFromNetwork: boolean;
  }

  /**
   * Parameters for signupUsingPOST
   */
  export interface SignupUsingPOSTParams {

    /**
     * form
     */
    form: SignupForm;

    /**
     * offid
     */
    offid?: string;

    /**
     * goal
     */
    goal?: string;

    /**
     * click_hash
     */
    clickHash?: string;
  }

  /**
   * Parameters for bindingSocialNetworkUsingPUT
   */
  export interface BindingSocialNetworkUsingPUTParams {

    /**
     * type
     */
    type: 'DISCORD' | 'FACEBOOK' | 'GOOGLE' | 'INSTAGRAM' | 'LINKEDIN' | 'TWITTER';

    /**
     * token
     */
    token: string;
  }

  /**
   * Parameters for socialTestRedirectUsingGET
   */
  export interface SocialTestRedirectUsingGETParams {

    /**
     * type
     */
    type: 'DISCORD' | 'FACEBOOK' | 'GOOGLE' | 'INSTAGRAM' | 'LINKEDIN' | 'TWITTER';

    /**
     * state
     */
    state?: string;

    /**
     * code
     */
    code?: string;
  }

  /**
   * Parameters for socialLoginUsingGET
   */
  export interface SocialLoginUsingGETParams {

    /**
     * type
     */
    type: 'DISCORD' | 'FACEBOOK' | 'GOOGLE' | 'INSTAGRAM' | 'LINKEDIN' | 'TWITTER';

    /**
     * token
     */
    token: string;
  }

  /**
   * Parameters for updateUserWsSettingsUsingPUT
   */
  export interface UpdateUserWsSettingsUsingPUTParams {

    /**
     * showNftBuy
     */
    showNftBuy: boolean;

    /**
     * showMysteryBoxOpen
     */
    showMysteryBoxOpen: boolean;
  }
}

export { UserControllerService }
