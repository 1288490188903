<div class="display-flex align-center justify-space-between">
  <div class="currency display-flex align-center">
    <div class="currencies-icons margin-right">
      <app-svg
        [asset]="['currency', market?.leftCurrencyCode || '']"
        class="round tiny currency-top"></app-svg>
      <app-svg
        [asset]="['currency', market?.rightCurencyCode || '']"
        class="round tiny currency-bottom"></app-svg>
    </div>
    <div class="currencies-name">
      {{ market?.title?.replace('-', '/') }}
    </div>
  </div>
  <div class="values display-flex align-center">
    <div class="currency-values margin-right">
      <span class="price margin-right">
        {{ market?.price || 0 | precision: market?.rightCurencyCode || 'DWRLD' }}
      </span>
      <span
        class="percent"
        [ngClass]="{
          'color-green': (market?.changePercent || 0) > 0,
          'color-red': (market?.changePercent || 0) < 0 || (market?.changePercent || 0) === 0,
          'color-white': (market?.changePercent || 0) === 0
        }">
        <span *ngIf="market?.changePercent; else emptyState">
          {{ market?.changePercent || 0 | number: '.2-2' }}%
        </span>
      </span>
    </div>
    <div class="currency-buttons">
      <app-icon
        (click)="$event.stopPropagation(); onToggleFavorite(market)"
        [class.favorite]="market?.isFavorite"
        [icon]="iconStar"
        class="favorite-icon font-lg color-warn"></app-icon>
    </div>
  </div>
</div>

<ng-template #emptyState>0.00%</ng-template>
