/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { NftCollectionTOResponse } from '../models/nft-collection-toresponse';
import { NftCollectionStakeForm } from '../models/nft-collection-stake-form';
import { NftCollectionTOListResponse } from '../models/nft-collection-tolist-response';
import { NftAttachToCollectionForm } from '../models/nft-attach-to-collection-form';
import { StakeCollectionPropertyTO } from '../models/stake-collection-property-to';
import { StakeCollectionPropertyForm } from '../models/stake-collection-property-form';
import { AutoExtensionForm } from '../models/auto-extension-form';
import { AutoRedeemForm } from '../models/auto-redeem-form';
import { NftCollectionExtendedTOResponse } from '../models/nft-collection-extended-toresponse';

/**
 * Nft Collection Controller
 */
@Injectable({
  providedIn: 'root',
})
class NftCollectionControllerService extends __BaseService {
  static readonly createUsingPOST1Path = '/api/v1/nft-collection';
  static readonly stakeUsingPOSTPath = '/api/v1/nft-collection/api/v1/nft-collection/stake';
  static readonly listUsingGET13Path = '/api/v1/nft-collection/list';
  static readonly findByMetaInformationIdUsingGETPath = '/api/v1/nft-collection/meta-information-info/{metaInformationId}';
  static readonly attachNftToCollectionUsingPOSTPath = '/api/v1/nft-collection/nft/attach';
  static readonly detachNftFromCollectionUsingPOSTPath = '/api/v1/nft-collection/nft/detach';
  static readonly stakeCollectionPropertyUsingPOSTPath = '/api/v1/nft-collection/stake-property';
  static readonly changeAutoExtensionUsingPOSTPath = '/api/v1/nft-collection/stake-property/auto-extension';
  static readonly changeAutoRedeemUsingPOSTPath = '/api/v1/nft-collection/stake-property/auto-redeem';
  static readonly findByIdUsingGET1Path = '/api/v1/nft-collection/{id}';
  static readonly deleteCollectionUsingDELETEPath = '/api/v1/nft-collection/{id}';
  static readonly stakePropertyByCollectionIdUsingGETPath = '/api/v1/nft-collection/{id}/stake-property';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * create
   * @param metaCollectionId undefined
   * @return OK
   */
  createUsingPOST1Response(metaCollectionId?: number): __Observable<__StrictHttpResponse<NftCollectionTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (metaCollectionId != null) __params = __params.set('metaCollectionId', metaCollectionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/nft-collection`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NftCollectionTOResponse>;
      })
    );
  }
  /**
   * create
   * @param metaCollectionId undefined
   * @return OK
   */
  createUsingPOST1(metaCollectionId?: number): __Observable<NftCollectionTOResponse> {
    return this.createUsingPOST1Response(metaCollectionId).pipe(
      __map(_r => _r.body as NftCollectionTOResponse)
    );
  }

  /**
   * stake
   * @param form form
   * @return OK
   */
  stakeUsingPOSTResponse(form: NftCollectionStakeForm): __Observable<__StrictHttpResponse<NftCollectionTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/nft-collection/api/v1/nft-collection/stake`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NftCollectionTOResponse>;
      })
    );
  }
  /**
   * stake
   * @param form form
   * @return OK
   */
  stakeUsingPOST(form: NftCollectionStakeForm): __Observable<NftCollectionTOResponse> {
    return this.stakeUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as NftCollectionTOResponse)
    );
  }

  /**
   * list
   * @param params The `NftCollectionControllerService.ListUsingGET13Params` containing the following parameters:
   *
   * - `zones`: zones
   *
   * - `toPrice`: toPrice
   *
   * - `sort`: sort
   *
   * - `searchText`: searchText
   *
   * - `regionCode`: regionCode
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `isAttachedElementFilter`: isAttachedElementFilter
   *
   * - `fromPrice`: fromPrice
   *
   * @return OK
   */
  listUsingGET13Response(params: NftCollectionControllerService.ListUsingGET13Params): __Observable<__StrictHttpResponse<NftCollectionTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.zones || []).forEach(val => {if (val != null) __params = __params.append('zones', val.toString())});
    if (params.toPrice != null) __params = __params.set('toPrice', params.toPrice.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.searchText != null) __params = __params.set('searchText', params.searchText.toString());
    if (params.regionCode != null) __params = __params.set('regionCode', params.regionCode.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.isAttachedElementFilter != null) __params = __params.set('isAttachedElementFilter', params.isAttachedElementFilter.toString());
    if (params.fromPrice != null) __params = __params.set('fromPrice', params.fromPrice.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/nft-collection/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NftCollectionTOListResponse>;
      })
    );
  }
  /**
   * list
   * @param params The `NftCollectionControllerService.ListUsingGET13Params` containing the following parameters:
   *
   * - `zones`: zones
   *
   * - `toPrice`: toPrice
   *
   * - `sort`: sort
   *
   * - `searchText`: searchText
   *
   * - `regionCode`: regionCode
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `isAttachedElementFilter`: isAttachedElementFilter
   *
   * - `fromPrice`: fromPrice
   *
   * @return OK
   */
  listUsingGET13(params: NftCollectionControllerService.ListUsingGET13Params): __Observable<NftCollectionTOListResponse> {
    return this.listUsingGET13Response(params).pipe(
      __map(_r => _r.body as NftCollectionTOListResponse)
    );
  }

  /**
   * findByMetaInformationId
   * @param metaInformationId metaInformationId
   * @return OK
   */
  findByMetaInformationIdUsingGETResponse(metaInformationId: number): __Observable<__StrictHttpResponse<NftCollectionTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/nft-collection/meta-information-info/${encodeURIComponent(String(metaInformationId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NftCollectionTOResponse>;
      })
    );
  }
  /**
   * findByMetaInformationId
   * @param metaInformationId metaInformationId
   * @return OK
   */
  findByMetaInformationIdUsingGET(metaInformationId: number): __Observable<NftCollectionTOResponse> {
    return this.findByMetaInformationIdUsingGETResponse(metaInformationId).pipe(
      __map(_r => _r.body as NftCollectionTOResponse)
    );
  }

  /**
   * attachNftToCollection
   * @param form form
   * @return OK
   */
  attachNftToCollectionUsingPOSTResponse(form: NftAttachToCollectionForm): __Observable<__StrictHttpResponse<NftCollectionTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/nft-collection/nft/attach`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NftCollectionTOResponse>;
      })
    );
  }
  /**
   * attachNftToCollection
   * @param form form
   * @return OK
   */
  attachNftToCollectionUsingPOST(form: NftAttachToCollectionForm): __Observable<NftCollectionTOResponse> {
    return this.attachNftToCollectionUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as NftCollectionTOResponse)
    );
  }

  /**
   * detachNftFromCollection
   * @param form form
   * @return OK
   */
  detachNftFromCollectionUsingPOSTResponse(form: NftAttachToCollectionForm): __Observable<__StrictHttpResponse<NftCollectionTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/nft-collection/nft/detach`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NftCollectionTOResponse>;
      })
    );
  }
  /**
   * detachNftFromCollection
   * @param form form
   * @return OK
   */
  detachNftFromCollectionUsingPOST(form: NftAttachToCollectionForm): __Observable<NftCollectionTOResponse> {
    return this.detachNftFromCollectionUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as NftCollectionTOResponse)
    );
  }

  /**
   * stakeCollectionProperty
   * @param form form
   * @return OK
   */
  stakeCollectionPropertyUsingPOSTResponse(form: StakeCollectionPropertyForm): __Observable<__StrictHttpResponse<StakeCollectionPropertyTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/nft-collection/stake-property`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StakeCollectionPropertyTO>;
      })
    );
  }
  /**
   * stakeCollectionProperty
   * @param form form
   * @return OK
   */
  stakeCollectionPropertyUsingPOST(form: StakeCollectionPropertyForm): __Observable<StakeCollectionPropertyTO> {
    return this.stakeCollectionPropertyUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as StakeCollectionPropertyTO)
    );
  }

  /**
   * changeAutoExtension
   * @param form form
   * @return OK
   */
  changeAutoExtensionUsingPOSTResponse(form: AutoExtensionForm): __Observable<__StrictHttpResponse<StakeCollectionPropertyTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/nft-collection/stake-property/auto-extension`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StakeCollectionPropertyTO>;
      })
    );
  }
  /**
   * changeAutoExtension
   * @param form form
   * @return OK
   */
  changeAutoExtensionUsingPOST(form: AutoExtensionForm): __Observable<StakeCollectionPropertyTO> {
    return this.changeAutoExtensionUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as StakeCollectionPropertyTO)
    );
  }

  /**
   * changeAutoRedeem
   * @param form form
   * @return OK
   */
  changeAutoRedeemUsingPOSTResponse(form: AutoRedeemForm): __Observable<__StrictHttpResponse<StakeCollectionPropertyTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/nft-collection/stake-property/auto-redeem`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StakeCollectionPropertyTO>;
      })
    );
  }
  /**
   * changeAutoRedeem
   * @param form form
   * @return OK
   */
  changeAutoRedeemUsingPOST(form: AutoRedeemForm): __Observable<StakeCollectionPropertyTO> {
    return this.changeAutoRedeemUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as StakeCollectionPropertyTO)
    );
  }

  /**
   * findById
   * @param id id
   * @return OK
   */
  findByIdUsingGET1Response(id: number): __Observable<__StrictHttpResponse<NftCollectionExtendedTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/nft-collection/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NftCollectionExtendedTOResponse>;
      })
    );
  }
  /**
   * findById
   * @param id id
   * @return OK
   */
  findByIdUsingGET1(id: number): __Observable<NftCollectionExtendedTOResponse> {
    return this.findByIdUsingGET1Response(id).pipe(
      __map(_r => _r.body as NftCollectionExtendedTOResponse)
    );
  }

  /**
   * deleteCollection
   * @param id id
   */
  deleteCollectionUsingDELETEResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/nft-collection/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * deleteCollection
   * @param id id
   */
  deleteCollectionUsingDELETE(id: number): __Observable<null> {
    return this.deleteCollectionUsingDELETEResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * stakePropertyByCollectionId
   * @param id id
   * @return OK
   */
  stakePropertyByCollectionIdUsingGETResponse(id: number): __Observable<__StrictHttpResponse<StakeCollectionPropertyTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/nft-collection/${encodeURIComponent(String(id))}/stake-property`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StakeCollectionPropertyTO>;
      })
    );
  }
  /**
   * stakePropertyByCollectionId
   * @param id id
   * @return OK
   */
  stakePropertyByCollectionIdUsingGET(id: number): __Observable<StakeCollectionPropertyTO> {
    return this.stakePropertyByCollectionIdUsingGETResponse(id).pipe(
      __map(_r => _r.body as StakeCollectionPropertyTO)
    );
  }
}

module NftCollectionControllerService {

  /**
   * Parameters for listUsingGET13
   */
  export interface ListUsingGET13Params {

    /**
     * zones
     */
    zones?: Array<number>;

    /**
     * toPrice
     */
    toPrice?: number;

    /**
     * sort
     */
    sort?: string;

    /**
     * searchText
     */
    searchText?: string;

    /**
     * regionCode
     */
    regionCode?: string;

    /**
     * pageSize
     */
    pageSize?: number;

    /**
     * pageNumber
     */
    pageNumber?: number;

    /**
     * isAttachedElementFilter
     */
    isAttachedElementFilter?: 'ALL' | 'UNDEFINED' | 'UNRECOGNIZED' | 'WITHOUT_ATTACHED_NFT' | 'WITH_ATTACHED_NFT';

    /**
     * fromPrice
     */
    fromPrice?: number;
  }
}

export { NftCollectionControllerService }
