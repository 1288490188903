/* eslint-disable prefer-arrow-functions/prefer-arrow-functions */
import { createNavigation } from 'core/utils/router.utils';

import { Like } from './components/faq/faq.component';

export const commonRoot = 'common';

export interface HelpModal {
  answers: string[];
  questions: string[];
  likes: Like[];
}

export interface InboxParams {
  ticketId: string;
  title?: string;
  topic?: string;
  status?: string;
  priority?: string;
  unreadMessages?: number;
}

export interface ConfirmModal {
  type: 'error' | 'success';
  title: LocalizedString;
  message: LocalizedString;
  cancelButtonMessage: LocalizedString;
  confirmButtonMessage: LocalizedString;
}

export interface UserPromptModal {
  type: 'error' | 'success' | 'info';
  title: LocalizedString;
  message: LocalizedString;
  buttonMessage: LocalizedString;
}

export type CropperType = 'round' | 'square' | 'any';

export interface CropperParams {
  type: CropperType;
  objectUrl: string;
}

export interface CropperResult {
  blob: Blob | null;
}

export function kyc(): string {
  return 'kyc';
}

export function serviceUnavailable(): string {
  return 'unavailable';
}

export function help(): string {
  return '';
}

export function support(): string {
  return 'support';
}

export function inboxDetails(ticketId: string): string {
  return `inbox/${ticketId}`;
}

export const paths = {
  help,
  support,
  inboxDetails,
  kyc,
  serviceUnavailable,
};

export function confirmModal(): string {
  return 'confirm';
}

export function userPromptModal(): string {
  return 'user-prompt';
}

export function helpAnswersModal(): string {
  return 'help-answers';
}

export function cropImageModal(type: CropperType, objectUrl: string): string {
  return `crop/${type}/${objectUrl}`;
}

export function createNewMessageModal(): string {
  return 'create-new-message';
}

export const modalPaths = {
  confirm: confirmModal,
  cropImage: cropImageModal,
  helpAnswers: helpAnswersModal,
  createNewMessage: createNewMessageModal,
  userPromptModal: userPromptModal,
};

export const commonNav = createNavigation(paths, commonRoot);
export const commonModalsNav = createNavigation(modalPaths, commonRoot);
