import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NftTradeToastComponent } from 'app/_main/modules/components/nft-trade-toast/nft-trade-toast.component';
import {
  StreetToastComponent,
  StreetToastExtras,
} from 'app/_main/modules/components/street-toast/street-toast.component';
import { StateClearService, StateFullService } from 'app/_main/services/state-clear.service';
import { appNav } from 'app/app.navigation';
import { I18nService } from 'core/modules/i18n/i18n.service';
import { SocketService } from 'core/modules/socket/socket.service';
import { Toaster } from 'core/toaster';
import { dataToNFTBuyEvent, NFTBuyEvent } from 'dashboard/models/nft-buy-event.model';
import { NftStreetSell } from 'dashboard/models/nft-street-toast-events';
import { UserOutbidYourBid, UserWantsToBuyEvent } from 'dashboard/models/nft-trade-events';
import { NotificationMessageType } from 'profile/enum/notification-message-type';
import {
  NotificationEventData,
  NOTIFICATIONS_WHITELIST_EVENTS,
} from 'profile/services/notification.service';
import { NotificationsFactory } from 'profile/services/notifications.factory';
import { UserService } from 'profile/services/user.service';

export type UserTradeMessageType = 'USER_WANT_BUY_NFT' | 'USER_BEAT_OLD_BET';
export type NftTradeEvents = UserWantsToBuyEvent & UserOutbidYourBid;
export const STREET_TOAST_WHITELIST = [
  NotificationMessageType.ORDER_EXECUTED_NFT,
  NotificationMessageType.STREET_SELL,
  NotificationMessageType.INITIAL_NFT_BUY,
];

export interface NFTToastData {
  type: UserTradeMessageType;
  params: NftTradeEvents;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationEventsService implements StateFullService {
  constructor(
    private socket: SocketService,
    private toaster: Toaster,
    private notificationFactory: NotificationsFactory,
    private i18nService: I18nService,
    private stateClearService: StateClearService,
    private router: Router,
    private userService: UserService
  ) {
    this.stateClearService.register(this);
  }

  observeNotificationEvents(): void {
    this.socket.on<NotificationEventData>().subscribe(message => {
      if (
        message.eventType === 'ORDER_CLOSED' &&
        (message.data as unknown as { closeReason: string }).closeReason ===
          'MARKET_ORDER_NOT_FILLED'
      ) {
        message.data.messageType = (message.data as unknown as { closeReason: string }).closeReason;
        message.data.params = JSON.stringify(message.data);
        const notification = this.notificationFactory.get(message.data);

        this.toaster.notification(
          this.i18nService.get(notification.title || ''),
          notification.icon
        );
      }

      if (message.eventType === 'USER_MESSAGE_RECEIVED' && message.data.read === false) {
        const notification = this.notificationFactory.get(message.data);

        const nftMessage =
          (message.data.params as unknown as { currency?: string }).currency === 'NFT'
            ? `${message.data.messageType}_NFT`
            : message.data.messageType;

        if (STREET_TOAST_WHITELIST.includes(nftMessage as NotificationMessageType)) {
          const { city, country, price, lastPrice, streetId, zone, street, username, dtype, number } =
            message.data.params as unknown as NftStreetSell;

          this.toaster.customToast<StreetToastExtras>(StreetToastComponent, {
            data: {
              count: 1,
              elements: [
                new NFTBuyEvent(
                  dataToNFTBuyEvent({
                    city,
                    country,
                    lastPrice: Number(price) || Number(lastPrice),
                    street,
                    streetId: Number(streetId),
                    zone: Number(zone),
                    id: 0,
                    ownerUuid: '',
                    countryCode: '',
                    dtype: dtype || 'none',
                    number: number || '',
                    ownerName:
                      nftMessage === 'ORDER_EXECUTED_NFT'
                        ? this.userService.user.username || ''
                        : username || '',
                  })
                ),
              ],
            },
          });
        } else if (NOTIFICATIONS_WHITELIST_EVENTS.includes(message.data.messageType)) {
          if (
            message.data.messageType === 'USER_WANT_BUY_NFT' ||
            message.data.messageType === 'USER_BEAT_OLD_BET'
          ) {
            this.toaster.customToast(NftTradeToastComponent, {
              data: {
                type: message.data.messageType,
                params: message.data.params,
              },
            });
          } else if (message.data.messageType === 'ORDER_EXECUTED') {
            this.toaster.notification(
              this.i18nService.get(notification.title || ''),
              notification.icon,
              () => {
                if ((message.data.params as { currency?: string })?.currency === 'NFT') {
                  this.router.navigateByUrl(appNav.marketplaceNav.marketplaceHistory());
                } else {
                  this.router.navigateByUrl(appNav.cashierNav.transactions());
                }
              }
            );
          } else if (message.data.messageType === 'ACHIEVEMENT_CHANGED') {
            this.toaster.notification(
              this.i18nService.get(notification.short),
              notification.icon,
              () => {},
              this.i18nService.get(notification.long)
            );
          } else {
            this.toaster.notification(
              this.i18nService.get(notification.title || ''),
              notification.icon
            );
          }
        }
      }
    });
  }

  clearState(): void {
    this.notificationFactory.emptyStore();
  }
}
