export class DMYHandler {
  constructor(private separator: string, private format: 'dmy' | 'mdy') {}

  getDateString(date?: Date): string {
    if (!date) {
      return '';
    }
    let d: string[] = [];
    if (this.format === 'dmy') {
      d = [
        this.zeroPad(date.getDate()),
        this.zeroPad(date.getMonth() + 1),
        date.getFullYear().toString(),
      ];
    } else {
      d = [
        this.zeroPad(date.getMonth() + 1),
        this.zeroPad(date.getDate()),
        date.getFullYear().toString(),
      ];
    }
    return d.join(this.separator);
  }

  stringToMap(dateString: string): { m: string; d: string; y: string } {
    if (this.format === 'dmy') {
      const [td, tm, ty] = dateString.split(this.separator); // split d,m,y
      return { m: tm || '', d: td || '', y: ty || '' };
    } else {
      const [tm, td, ty] = dateString.split(this.separator); // split m,d,y
      return { m: tm || '', d: td || '', y: ty || '' };
    }
  }

  private zeroPad(d: number): string {
    return d < 10 ? `0${d}` : d.toString();
  }

  handle(
    text: string,
    caret: number,
    isBackInput: boolean,
    tmpMap: { m: string; d: string; y: string }
  ): { text: string; caret: number; date: Date | null } {
    let [d, m, y] = text.split(this.separator); // split d-m-y
    let parts: string[] = [];
    if (this.format === 'mdy') {
      const tmp = m;
      m = d;
      d = tmp;
    }
    y = (y || '').slice(0, 4);
    m = (m || '').slice(0, 2);
    d = (d || '').slice(0, 2);
    if (this.format === 'dmy') {
      // if date is first - validate day before mont
      [d, m, y] = this.validateDay(d, m, y, text, isBackInput, caret, tmpMap);
      [d, m, y] = this.validateMonth(d, m, y, text, isBackInput, caret, tmpMap);
      parts = [d];
      if (d.length === 2 && m.length) {
        parts.push(m);
      }
      if (m.length === 2 && y.length) {
        parts.push(y);
      }
    } else {
      // otherwise validate month first
      [d, m, y] = this.validateMonth(d, m, y, text, isBackInput, caret, tmpMap);
      [d, m, y] = this.validateDay(d, m, y, text, isBackInput, caret, tmpMap);
      parts = [m];
      if (m.length === 2 && d.length) {
        parts.push(d);
      }
      if (d.length === 2 && y.length) {
        parts.push(y);
      }
    }
    text = parts.join(this.separator);
    // add separator to the end if DAY is empty
    if (text.length === 2 || text.length === 5) {
      text += this.separator;
      caret++;
    }
    let date: Date | null = null;
    if (text.length >= 10) {
      const [year, month, day] =
        this.format === 'dmy'
          ? [+parts[2], +parts[1] - 1, +parts[0]]
          : [+parts[2], +parts[0] - 1, +parts[1]];
      date = new Date(year, month, day);
    }
    return { text, caret, date };
  }

  private validateDay(
    d: string,
    m: string,
    y: string,
    text: string,
    isBackInput: boolean,
    caret: number,
    tmpMap: { m: string; d: string; y: string }
  ): [string, string, string] {
    if (d.length === 1 && +d > 3) {
      // add zero padding and move caret
      d = this.zeroPad(+d);
      caret++;
    } else if (d.length >= 2) {
      // validate day
      const maxDay = new Date(+y, +m, 0).getDate();
      d = Math.max(1, Math.min(maxDay, +d)).toString();
      d = this.zeroPad(+d);
      if (caret === (this.format === 'dmy' ? 1 : 4) && +text[caret - 1] > 3) {
        d = this.zeroPad(+text[caret - 1]);
        caret++;
      }
      // restore previously saved month/year
      if (this.format === 'dmy') {
        m = !isBackInput ? tmpMap.m || m : m;
        tmpMap.m = '';
      } else {
        // mdy
        y = !isBackInput ? tmpMap.y || y : y;
        tmpMap.y = '';
      }
    }
    return [d, m, y];
  }

  private validateMonth(
    d: string,
    m: string,
    y: string,
    text: string,
    isBackInput: boolean,
    caret: number,
    tmpMap: { m: string; d: string; y: string }
  ): [string, string, string] {
    if (m.length === 1 && +m > 1) {
      // add zero padding and move caret
      m = this.zeroPad(+m);
      caret++;
    } else if (m.length >= 2) {
      // validate month
      m = Math.max(1, Math.min(12, +m)).toString();
      m = this.zeroPad(+m);
      if (caret === (this.format === 'dmy' ? 4 : 1) && +text[caret - 1] > 1) {
        m = this.zeroPad(+text[caret - 1]);
        caret++;
      }
      // restore previously saved year/day
      if (this.format === 'dmy') {
        y = !isBackInput ? tmpMap.y || y : y;
        tmpMap.y = '';
      } else {
        d = !isBackInput ? tmpMap.d || d : d;
        tmpMap.d = '';
      }
    }
    return [d, m, y];
  }
}
