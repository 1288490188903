<app-menu *ngIf="headers.length" [minWidth]="240">
  <button [appClearButton]="dropdownIcon" appAnchor class="primary icon-right">
    <span class="margin-right-tn">{{ 'core.mobile-filters.sort' | translate }}</span>
    <span class="margin-right-tn">
      {{ activeSort.header.label || '—' | translate }}
    </span>
  </button>
  <ng-template>
    <div class="data-host">
      <button
        (click)="onSortChange(item)"
        *ngFor="let item of headers | headersPipe: 'key'"
        appButton
        appMenuItem
        class="solid for-select">
        {{ item.label | translate }}
        <app-icon
          *ngIf="item.key === activeSort.key"
          [icon]="checkIcon"
          class="color-primary margin-left-auto xhg"></app-icon>
      </button>
    </div>
  </ng-template>
</app-menu>

<div *ngIf="activeSort?.key" class="sort-direction-icons">
  <button
    (click)="changeDirection('desc')"
    [appClearButton]="arrowIcon"
    [class.active]="activeSort.direction === 'desc'"
    class="direction-icon reversed-up margin-right-md"></button>
  <button
    (click)="changeDirection('asc')"
    [appClearButton]="arrowIcon"
    [class.active]="activeSort.direction === 'asc'"
    class="direction-icon reversed-down margin-right-md"></button>
  <button
    (click)="disableSort()"
    [appClearButton]="closeIcon"
    class="direction-icon none-direction"></button>
</div>

<app-menu
  #menu="app-menu"
  *ngIf="!hideFilters"
  [manualClose]="true"
  [minWidth]="320"
  appearance="filters"
  class="filter-items"
  theme="dark">
  <button [appClearButton]="filterIcon" appAnchor class="primary icon-md"></button>
  <ng-template>
    <div class="data-host dc-scroll-light">
      <div *mediaIf="'small only'" class="overlay-title">{{ filterTitle }}</div>
      <ng-content select="[additionalLayout]"></ng-content>
      <button
        (click)="menu.close()"
        *mediaIf="'small only'"
        appButton
        class="solid primary medium overlay-action result-button">
        {{ 'core.mobile-filters.show-results' | translate }}
      </button>
    </div>
  </ng-template>
</app-menu>
<ng-content select="[handleButtons]"></ng-content>
