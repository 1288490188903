import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { interval, Subscription } from 'rxjs';

@Directive({
  selector: '[appCountdown]',
})
export class CountdownDirective implements OnInit, OnDestroy, OnChanges {
  @Input()
  fromSeconds?: number;

  @Input()
  updateInterval = 500;

  @Output()
  stopped = new EventEmitter();

  @Output()
  changed = new EventEmitter<number>();

  private startTime = 0;
  private intervalSub = new Subscription();
  private ONE_DAY = 24 * 60 * 60 * 1000;

  constructor(
    protected renderer: Renderer2,
    private zone: NgZone,
    private elementRef: ElementRef<HTMLElement>
  ) {}

  ngOnDestroy(): void {
    this.intervalSub.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges<CountdownDirective>): void {
    if (changes.fromSeconds && this.fromSeconds) {
      this.startTime = Date.now() + this.fromSeconds * 1000;
    } else {
      this.startTime = 0;
    }
  }

  ngOnInit(): void {
    this.start();
  }

  reset(): void {
    if (this.fromSeconds) {
      this.startTime = Date.now() + this.fromSeconds * 1000;
      this.start();
    }
  }

  protected start(): void {
    this.updateTime();
    this.zone.runOutsideAngular(() => {
      this.intervalSub.unsubscribe();
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      this.intervalSub = interval(this.updateInterval).subscribe(() => {
        this.updateTime();
      });
    });
  }

  protected updateTime(): void {
    if (!this.startTime) {
      this.renderer.setProperty(this.elementRef.nativeElement, 'innerText', '');
    } else {
      const diff = Math.max(0, this.startTime - Date.now());
      const curTime = new Date(diff);

      const { h, m, s } = {
        s: curTime.getUTCSeconds(),
        m: curTime.getUTCMinutes(),
        h: curTime.getUTCHours(),
      };
      const countOfDays = Math.abs(Math.floor(curTime.getTime() / this.ONE_DAY));
      if (countOfDays > 1) {
        this.renderer.setProperty(
          this.elementRef.nativeElement,
          'innerText',
          `${countOfDays}d ${h < 10 ? `0${h}` : h}h`
        );
      } else {
        let time = `${m < 10 ? `0${m}` : m}:${s < 10 ? `0${s}` : s}`;
        if (h) {
          time = `${h < 10 ? `0${h}` : h}:${time}`;
        }
        this.renderer.setProperty(this.elementRef.nativeElement, 'innerText', time);
      }
      this.zone.run(() => this.changed.next(curTime.getTime()));
      if (diff <= 0) {
        this.stop();
      }
    }
  }

  protected stop(): void {
    this.intervalSub.unsubscribe();
    this.zone.run(() => this.stopped.next());
  }
}
