import { Component, HostListener } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, timer } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

import { ToastData, Toaster, ToastParameters } from '../../services/toaster.service';
import { toasterFade } from './toaster.animation';

@Component({
  selector: 'app-toaster',
  template: `
    <ng-container *ngFor="let t of toasts; trackBy: toastsTrackBy">
      <app-toast
        [@toasterFade]
        [title]="t.title"
        [description]="t.description"
        [message]="t.message"
        [button]="t.button"
        [id]="t.id"
        [type]="t.type"
        [notificationIcon]="t.notificationIcon"
        [extras]="t.extras"
        [class]="t.toastClass || ''"
        [componentRef]="t.componentRef"
        [closeOnClick]="!!t.closeOnClick"
        (closing)="close($event)"
        (action)="callback($event)"></app-toast>
      <ng-container></ng-container>
    </ng-container>
  `,
  styleUrls: ['toaster.component.scss'],
  animations: [toasterFade],
})
export class ToasterComponent {
  toasts: ToastData[] = [];

  private lastUID = 0;
  private isEntered$ = new BehaviorSubject<boolean>(false);

  private destroy$ = new Subject<void>();

  constructor(service: Toaster) {
    service.toasterHost = this;
  }

  @HostListener('mouseenter') onEnter(): void {
    this.isEntered$.next(true);
  }

  @HostListener('mouseleave') onLeave(): void {
    this.isEntered$.next(false);
  }

  toastsTrackBy(_: number, item: ToastData): number {
    return item.id;
  }

  toast({
    timeout,
    message,
    buttonMessage,
    title,
    description,
    type,
    callback,
    icon,
    componentRef,
    extras,
    toastClass,
    closeOnClick,
  }: ToastParameters): void {
    if (type === 'error') {
      const dupeToast = this.toasts.filter(t => t.message === message)[0];
      // prevent duplicate error messages;
      if (dupeToast) {
        // extend time to auto closing
        dupeToast.timeoutSub.unsubscribe();
        this.closeOnTimeout(dupeToast.id, timeout).pipe(takeUntil(this.destroy$)).subscribe();
        return;
      }
    }
    const uid = this.UID();
    const timeoutSub = this.closeOnTimeout(uid, timeout).pipe(takeUntil(this.destroy$)).subscribe();
    this.toasts.push({
      title: title || '',
      message: message || '',
      button: buttonMessage,
      id: uid,
      timeoutSub,
      type,
      description,
      notificationIcon: icon,
      callback,
      componentRef,
      extras,
      toastClass,
      closeOnClick,
    });
  }

  private closeOnTimeout(id: number, timeout: number): Observable<unknown> {
    return this.isEntered$.asObservable().pipe(
      switchMap(isEntered =>
        isEntered
          ? of(null)
          : timer(timeout).pipe(
              tap(() => {
                this.toasts = this.toasts.filter(t => t.id !== id);
              })
            )
      )
    );
  }

  close(toastId: number): void {
    const toast = this.toasts.filter(t => t.id === toastId)[0];
    toast.timeoutSub.unsubscribe();
    this.toasts = this.toasts.filter(t => t.id !== toastId);
  }

  callback(toastId: number): void {
    const toast = this.toasts.filter(t => t.id === toastId)[0];
    if (toast.callback) {
      toast.callback();
    }
  }

  private UID(): number {
    let num = Date.now();
    if (num <= this.lastUID) {
      num = num + (this.lastUID - num + 1);
    }
    this.lastUID = num;
    return num;
  }
}
