import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { RouteData } from 'core/models/route.data';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

import { ModalRouter } from '../modules/modal';
import { RouterBehavior } from '../utils/router.utils';

@Injectable({ providedIn: 'root' })
export class ScrollRestoration {
  private scrollStates = new Map<string, Map<string, number>>();
  private lastSnapshotData: RouteData = {};
  private previousUrl = '';
  private scrollY = 0;

  constructor(
    private router: Router,
    private modal: ModalRouter,
    @Inject(PLATFORM_ID) private platformId: string
  ) {}

  clear(): void {
    this.scrollStates = new Map<string, Map<string, number>>();
    this.lastSnapshotData = {};
  }

  clearById(scrollId: string): void {
    this.scrollStates.delete(scrollId);
  }

  setup(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }
    let scrollSub = new Subscription();
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        debounceTime(100)
      )
      .subscribe(() => {
        scrollSub = fromEvent(window, 'scroll', { passive: true }).subscribe(() => {
          this.scrollY = window.scrollY;
        });
      });
    let previousUrl = '';
    this.router.events.pipe(filter(e => e instanceof NavigationStart)).subscribe(e => {
      scrollSub.unsubscribe();
      if (this.modal.isActivating || previousUrl === (e as NavigationStart).url) {
        return;
      }
      previousUrl = (e as NavigationStart).url;
      const disposer = this.saveScrollState(this.scrollY);
      const sub = this.router.events
        .pipe(filter(evt => evt instanceof NavigationEnd || evt instanceof NavigationCancel))
        .subscribe(evt => {
          if (evt instanceof NavigationEnd) {
            if (
              !evt.url.includes('primaryModal') &&
              !evt.urlAfterRedirects.includes('primaryModal')
            ) {
              this.restoreScrollState();
            }
          } else {
            disposer();
          }
          sub.unsubscribe();
        });
    });
  }

  private saveScrollState(scrollY: number): () => void {
    const restorationId = this.lastSnapshotData.scrollId;
    this.previousUrl = this.router.url;
    if (restorationId) {
      if (!this.scrollStates.has(restorationId)) {
        this.scrollStates.set(restorationId, new Map());
      }
      const restorationCacheSize = this.lastSnapshotData.scrollCacheSize;
      const cacheMap = this.scrollStates.get(restorationId);
      if (!cacheMap) {
        return () => {
          // dummy
        };
      }
      if (cacheMap.size >= (restorationCacheSize || 1)) {
        // remove last item because of cache overflow
        const m = Array.from(cacheMap)[cacheMap.size - 1][0];
        cacheMap.delete(m);
      }
      const url = this.modal.clearUrl(this.router.url.toLowerCase());
      cacheMap.set(url, scrollY);
      return () => {
        cacheMap.delete(this.router.url);
      };
    }
    return () => {
      // dummy
    };
  }

  private restoreScrollState(): void {
    const previousId = this.lastSnapshotData.scrollId;
    this.lastSnapshotData = RouterBehavior.routeData;
    const currentId = this.lastSnapshotData.scrollId;
    // if same component navigated and pages are same
    if (
      currentId &&
      previousId === currentId &&
      this.getPage(this.previousUrl) === this.getPage(this.router.url)
    ) {
      return;
    }
    if (currentId) {
      const storedScrolls = this.scrollStates.get(currentId);
      const url = this.modal.clearUrl(this.router.url.toLowerCase());
      const scrollYForUrl = storedScrolls ? storedScrolls.get(url) || 0 : 0;
      requestAnimationFrame(() => {
        window.scrollTo(0, scrollYForUrl);
      });
      window.scrollTo(0, scrollYForUrl);
      this.scrollY = scrollYForUrl;
    } else {
      window.scrollTo(0, 0);
    }
  }

  private getPage(url: string): number {
    return +(/[?&]page=(\d+)/.exec(url) || ['', '0'])[1];
  }
}
