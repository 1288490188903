import { Component, ElementRef, HostBinding, Input, Renderer2 } from '@angular/core';
import { AppIcon, AppIconName } from 'core/icons/icon.type';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[appRoundButton]',
  template: `
    <div matRipple class="mat-ripple" [matRippleTrigger]="elementRef.nativeElement"></div>
    <app-icon *ngIf="appRoundButton" [icon]="appRoundButton"></app-icon>
    <ng-content select="app-badge"></ng-content>
  `,
  styleUrls: ['./styles/round.scss', './styles/ripple.scss'],
})
export class RoundButtonComponent {
  @Input()
  appRoundButton: AppIconName | AppIcon = '';

  @Input()
  @HostBinding('attr.type')
  type = 'button';

  constructor(public elementRef: ElementRef<HTMLElement>, renderer: Renderer2) {
    renderer.addClass(elementRef.nativeElement, 'round');
  }
}
