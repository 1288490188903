/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Languages } from '../models/languages';

/**
 * Translations Controller
 */
@Injectable({
  providedIn: 'root',
})
class TranslationsControllerService extends __BaseService {
  static readonly updateLocalizeUsingPOSTPath = '/api/v1/i18n';
  static readonly getLanguageListUsingGETPath = '/api/v1/i18n/language/list';
  static readonly getTranslationUsingGETPath = '/api/v1/i18n/{locale}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * updateLocalize
   */
  updateLocalizeUsingPOSTResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/i18n`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * updateLocalize
   */
  updateLocalizeUsingPOST(): __Observable<null> {
    return this.updateLocalizeUsingPOSTResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * getLanguageList
   * @return OK
   */
  getLanguageListUsingGETResponse(): __Observable<__StrictHttpResponse<Languages>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/i18n/language/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Languages>;
      })
    );
  }
  /**
   * getLanguageList
   * @return OK
   */
  getLanguageListUsingGET(): __Observable<Languages> {
    return this.getLanguageListUsingGETResponse().pipe(
      __map(_r => _r.body as Languages)
    );
  }

  /**
   * getTranslation
   * @param locale locale
   * @return OK
   */
  getTranslationUsingGETResponse(locale: string): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/i18n/${encodeURIComponent(String(locale))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * getTranslation
   * @param locale locale
   * @return OK
   */
  getTranslationUsingGET(locale: string): __Observable<{[key: string]: string}> {
    return this.getTranslationUsingGETResponse(locale).pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }
}

module TranslationsControllerService {
}

export { TranslationsControllerService }
