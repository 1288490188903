import {
  CollectionMetaInformationExtendedTOResponse,
  CollectionMetaInformationTOResponse,
  CollectionStakeElementTOResponse,
  CountryTOResponse,
  NftCollectionExtendedTOResponse,
  StakeCollectionPropertyForm,
} from 'api/models';
import { StreetLevelType, zoneToLabel, zoneToLevel } from 'dashboard/models/street.data';
import { Street } from 'dashboard/models/street.model';
import { StreetsFactory } from 'dashboard/services/streets.factory';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';

export const CollectionType: { [key in StreetLevelType]: string } = {
  plan5: 'Unique',
  plan4: 'Elite',
  plan3: 'Premium',
  plan2: 'Standard',
  plan1: 'Basic',
};

export type CollectionStatusFromBlockchain =
  CollectionStakeElementTOResponse['statusFromBlockchain'];

export class Collection {
  id!: number;
  assetId!: number;
  cartId!: number;
  buyAt!: Date;
  collectionPrice!: number;
  currentBalance!: number;
  expectedWeeklyIncome?: number;
  stakingEnd?: Date;
  nextPayOutAt!: number;
  nextPayout!: number;
  completedPercent!: number;
  countries!: CountryTOResponse[];

  basicStreetsCount!: number;
  standardStreetsCount!: number;
  premiumStreetsCount!: number;
  eliteStreetsCount!: number;
  suggestionForCurrentUser!: number;

  meta!: CollectionMeta;
  basicStreets!: PrivateArray<Street>;
  standardStreets!: PrivateArray<Street>;
  premiumStreets!: PrivateArray<Street>;
  eliteStreets!: PrivateArray<Street>;

  stakeType!: StakeCollectionPropertyForm['stakeType'];
  statusFromBlockchain!: CollectionStatusFromBlockchain;
  periodStaking!: number;
  isCompleted?: boolean;
  isStake?: boolean;
  isAutoExtension?: boolean;
  isReserved?: boolean;
  isWithdrawn?: boolean;
  payoutsFrequency!: number;
  transactionId?: string;

  private collectionUpdatedSubj = new Subject<void>();

  constructor(init: PickData<Collection> = dataToCollection()) {
    Object.assign(this, init);
  }

  get canStake(): boolean {
    return (
      (this.statusFromBlockchain === 'READY_FOR_STAKE' ||
        this.statusFromBlockchain === 'UNSTAKED') &&
      !this.isReserved
    );
  }

  get transactionUrl(): string {
    return `https://bloks.io/transaction/${this.transactionId}`;
  }

  get isAvailable(): boolean {
    return !!this.isWithdrawn;
  }

  get isStaked(): boolean {
    return this.statusFromBlockchain === 'STAKED';
  }

  get title(): string {
    return this.meta.title;
  }

  get secondaryTitle(): string {
    return (this.meta.title || this.meta.description) + ' ' + this.meta.label;
  }

  get country(): string {
    return (this.countries[0] || {}).name || (this.meta.countries[0] || {}).name || 'Country';
  }

  get currentCount(): number {
    const basic = this.basicStreetsCount;
    const standard = this.standardStreetsCount;
    const premium = this.premiumStreetsCount;
    const elite = this.eliteStreetsCount;
    return basic + elite + premium + standard;
  }

  get totalCount(): number {
    const basic = this.meta.basicStreetsCount;
    const standard = this.meta.standardStreetsCount;
    const premium = this.meta.premiumStreetsCount;
    const elite = this.meta.eliteStreetsCount;
    return basic + standard + premium + elite;
  }

  get dailyYield(): number {
    return (this.meta.yieldValue || 0) / 365;
  }

  get monthlyYield(): number {
    return (this.meta.yieldValue || 0) / 12;
  }

  get weeklyYield(): number {
    return (this.meta.yieldValue || 0) / 48;
  }

  get isGlobal(): boolean {
    return this.title.toLowerCase().includes('world');
  }

  get collectionUpdated() {
    return this.collectionUpdatedSubj.asObservable();
  }

  update(): void {
    console.log('update: ');
    this.collectionUpdatedSubj.next();
  }

  reset(): void {
    this.id = 0;
    this.isCompleted = false;
    this.basicStreetsCount = 0;
    this.standardStreetsCount = 0;
    this.premiumStreetsCount = 0;
    this.eliteStreetsCount = 0;
    this.basicStreets = [];
    this.eliteStreets = [];
    this.premiumStreets = [];
    this.standardStreets = [];
    this.collectionUpdatedSubj.next();
  }

  addStreet(street: Street): void {
    const type = this.getFieldByStreetType(street);
    const i = this[type].findIndex(s => s.id === street.id);
    if (i < 0) {
      (this[type] as Array<Street>).push(street);
      this.collectionUpdatedSubj.next();
    }
  }

  private getFieldByStreetType(
    street: Street
  ): 'basicStreets' | 'standardStreets' | 'premiumStreets' | 'eliteStreets' {
    if (street.level === 'plan1') {
      return 'basicStreets';
    } else if (street.level === 'plan2') {
      return 'standardStreets';
    } else if (street.level === 'plan3') {
      return 'premiumStreets';
    } else {
      return 'eliteStreets';
    }
  }

  removeStreet(street: Street): void {
    const type = this.getFieldByStreetType(street);
    const i = this[type].findIndex(s => s.id === street.id);
    if (i >= 0) {
      (this[type] as Array<Street>).splice(i, 1);
    }
    this.collectionUpdatedSubj.next();
  }
}

export class CollectionMeta {
  id!: number;
  title!: string;
  description!: string;
  imageUrl?: string;
  smallImageUrl?: string;
  period!: CollectionMetaInformationExtendedTOResponse['period'];

  numberOfKind!: number;
  yieldValue!: number;
  level!: StreetLevelType;
  label!: string;
  rating!: number;

  basicStreetsCount!: number;
  premiumStreetsCount!: number;
  standardStreetsCount!: number;
  eliteStreetsCount!: number;

  requiredStreetIds!: number[];
  countries!: Array<CountryTOResponse>;
  countryCodes!: string[];
  cityIds!: number[];

  constructor(init: PickData<CollectionMeta> = dataToCollectionMeta({})) {
    Object.assign(this, init);
  }

  get placeholderUrl(): string {
    return `${environment.mainServiceUrl}/api/v1/gis/${this.cityIds[0]}/image/city?width=550&height=820&zoom=12.5`;
  }
}

export const dataToCollectionMeta = (
  data: CollectionMetaInformationTOResponse = {},
  ref?: CollectionMeta
): PickData<CollectionMeta> => ({
  id: data.id || 0,
  title: data.title || data.name || '',
  description: data.description || ref?.description || '',
  imageUrl: data.imageUrl || ref?.imageUrl || '',
  smallImageUrl: buildSmallImageUrl(data.imageUrl || '') || ref?.smallImageUrl,
  period: data.period || ref?.period || 'MONTH',

  numberOfKind: data.numberOfKind || ref?.numberOfKind || 0,
  yieldValue: data.yieldValue || ref?.yieldValue || 0,
  level: zoneToLevel(data.zone || 0),
  label: zoneToLabel(data.zone),
  rating: data.zone || 1,

  basicStreetsCount: data.basicStreetsCount || ref?.basicStreetsCount || 0,
  standardStreetsCount: data.standardStreetsCount || ref?.standardStreetsCount || 0,
  premiumStreetsCount: data.premiumStreetsCount || ref?.premiumStreetsCount || 0,
  eliteStreetsCount: data.eliteStreetsCount || ref?.eliteStreetsCount || 0,
  requiredStreetIds: data.requiredStreetIds || ref?.requiredStreetIds || [],
  countryCodes: data.countryCodes || ref?.countryCodes || [],
  countries: data.countries || ref?.countries || [],
  cityIds: data.cityIds || ref?.cityIds || [],
});

export const dataToCollection = (
  data: NftCollectionExtendedTOResponse &
    CollectionMetaInformationExtendedTOResponse &
    CollectionStakeElementTOResponse & {
      cartId?: number;
      isReserved?: boolean;
      isWithdrawn?: boolean;
    } = {},
  streetFactory?: StreetsFactory,
  ref?: Collection
): PickData<Collection> => ({
  id: data.collectionIdCreatedCurrentUser || data.id || ref?.id || 0,
  cartId: data.cartId || ref?.cartId || 0,
  buyAt: new Date(data.buyAt || ref?.buyAt || 0),
  assetId: data.assetId || ref?.assetId || 0,
  isReserved: data.isReserved || ref?.isReserved || false,
  isWithdrawn: data.isWithdrawn || ref?.isWithdrawn || false,
  isCompleted: data.complete || data.completedForCurrentUser || ref?.isCompleted,
  isStake: data.isStake || ref?.isStake,
  isAutoExtension: data.isAutoExtension || ref?.isAutoExtension,
  suggestionForCurrentUser: data.suggestionForCurrentUser || ref?.suggestionForCurrentUser || 0,
  completedPercent: data.completedPercent || ref?.completedPercent || 0,
  currentBalance: data.currentBalance || ref?.currentBalance || 0,
  collectionPrice: data.collectionPrice || ref?.collectionPrice || 0,
  nextPayOutAt: +(data.nextPayOutDate || ref?.nextPayOutAt || 0),
  nextPayout: data.nextPayouts || ref?.nextPayout || 0,
  countries: data.countries || ref?.countries || [],
  basicStreetsCount:
    data.basicIncludeStreetsCount ||
    (data.complete ? data.metaInformation?.basicStreetsCount || 0 : 0),
  standardStreetsCount:
    data.standardIncludeStreetsCount ||
    (data.complete ? data.metaInformation?.standardStreetsCount || 0 : 0),
  premiumStreetsCount:
    data.premiumIncludeStreetsCount ||
    (data.complete ? data.metaInformation?.premiumStreetsCount || 0 : 0),
  eliteStreetsCount:
    data.eliteIncludeStreetsCount ||
    (data.complete ? data.metaInformation?.eliteStreetsCount || 0 : 0),
  stakingEnd: new Date(
    (data as CollectionStakeElementTOResponse)?.stakingEnd || ref?.stakingEnd || 0
  ),
  basicStreets: ref?.basicStreets.length
    ? ref.basicStreets
    : (data.basicIncludedNftIds || []).map(s => streetFactory?.get(s.id, s) || new Street()),
  standardStreets: ref?.standardStreets.length
    ? ref.standardStreets
    : (data.standardIncludedNftIds || []).map(s => streetFactory?.get(s.id, s) || new Street()),
  premiumStreets: ref?.premiumStreets.length
    ? ref.premiumStreets
    : (data.premiumIncludedNftIds || []).map(s => streetFactory?.get(s.id, s) || new Street()),
  eliteStreets: ref?.eliteStreets.length
    ? ref.eliteStreets
    : (data.eliteIncludedNftIds || []).map(s => streetFactory?.get(s.id, s) || new Street()),
  stakeType: data.stakeType || ref?.stakeType,
  statusFromBlockchain: data.statusFromBlockchain || ref?.statusFromBlockchain || 'CREATING',
  payoutsFrequency: data.payoutsFrequency || ref?.payoutsFrequency || 0,
  transactionId: data.transactionId || ref?.transactionId || '',
  periodStaking: data.periodStaking || ref?.periodStaking || 0,
  meta: new CollectionMeta(dataToCollectionMeta(data.metaInformation || data, ref?.meta)),
});

function buildSmallImageUrl(imageUrl: string): string {
  if (!imageUrl) {
    return '';
  }
  const decoded = decodeURIComponent(imageUrl);
  let url;
  if (decoded.includes('/2x/')) {
    url = decoded.replace('/2x/', '/1x/').replace('@2x', '');
  } else {
    url = decoded.replace('@2x', '');
  }

  return encodeURI(url);
}
