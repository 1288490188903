import {Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GenericFormControl } from 'core/utils/form-generics';
import { GisService } from 'dashboard/services/gis.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-nft-search',
  template: `
    <app-input
      [formControl]="searchControl"
      class="solid medium primary-dark width-100"
      extras="search"
      [placeholder]="placeholderKey | translate"></app-input>
  `,
  styleUrls: ['nft-search-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NftSearchFormComponent),
      multi: true,
    },
  ],
})
export class NftSearchFormComponent implements OnInit, OnDestroy, ControlValueAccessor {
  searchControl = new GenericFormControl<string>();
  protected destroy$ = new Subject();

  protected onControlTouched = (): null => null;
  protected onControlChanged = (_: unknown): null => null;
  constructor(private gisService: GisService) {}

  @Input() placeholderKey = 'dashboard.ui.filter_nft_search.placeholder';

  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(value => {
        this.onControlChanged(value);
        // this.applySearch(value, '', '');
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  writeValue(v?: string): void {
    if (!v) {
      return;
    }
    this.setFormValue(v);
  }

  registerOnChange(fn: (val: unknown) => null): void {
    this.onControlChanged = fn;
  }

  registerOnTouched(fn: () => null): void {
    this.onControlTouched = fn;
  }

  protected getFormValue(): string {
    return this.searchControl.value;
  }

  protected setFormValue(v?: string): void {
    this.searchControl.setValue(v || '', { emitEvent: false });
  }
}
