import { Component, OnDestroy } from '@angular/core';
import { appNav } from 'app/app.navigation';
import {
  MarketplaceMarketsService,
  MarketsStatistic,
} from 'app/marketplace/services/marketplace-markets.service';
import { Observable, Subject } from 'rxjs';

export interface CheckedOrder {
  isChecked: boolean;
  orderId: number;
}

@Component({
  templateUrl: 'markets-page.component.html',
  styleUrls: [
    '../../../dashboard/components/_styles/dashboard-layout.scss',
    'markets-page.component.scss',
  ],
})
export class MarketsPageComponent implements OnDestroy {
  appNav = appNav;
  marketsData$: Observable<MarketsStatistic>;
  private destroy$ = new Subject();

  constructor(private marketplaceMarketsService: MarketplaceMarketsService) {
    this.marketsData$ = this.marketplaceMarketsService.getMarketsStatisticData();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
