<button appButton appRouterBack class="tab back-button align-center">
  <div class="display-flex align-center">
    <app-icon [icon]="chevronIcon" class="margin-right"></app-icon>
    <span>{{ 'marketplace.street_overview.back' | translate }}</span>
  </div>
</button>

<div class="data-container container-column dark">
  <ng-container *ngIf="!isError; else noData">
    <div [ngClass]="{ skeleton: isLoading }" class="overview-header">
      <div class="street-title width-100 margin-right">
        <h1 class="name">{{ street.name }}</h1>

        <div class="place">
          {{ street.secondaryName }}
        </div>
      </div>

      <div *mediaIf="'medium'" class="display-flex width-100 justify-space-between">
        <div class="street-price">
          <div class="street-price_label font-lg">
            {{ 'marketplace.street_overview.street_price' | translate }}
          </div>

          <div class="street-price_value">
            <span class="font-hg weight-semibold">
              {{ street.marketPrice | precision: 'DWRLD' }} DWRLD
            </span>
            <span class="color-primary">$ {{ street.marketPrice | baseRate: 'DWRLD' }}</span>
          </div>

          <div
            *ngIf="userBuyPrice || street.buyPrice"
            class="display-flex margin-top-tn text-right opacity-50">
            <div *ngIf="street.buyPrice" class="font-sm">
              <span class="margin-right-tn">
                {{ 'street.ui.street_details.max_bid' | translate }}
              </span>
              <span>{{ street.buyPrice | precision: 'DWRLD' }} DWRLD</span>
            </div>

            <ng-container *ngIf="userBuyPrice && street.buyPrice">
              <div class="margin-left-tn margin-right-tn">/</div>
            </ng-container>

            <div *ngIf="userBuyPrice" class="font-sm">
              <span class="margin-right-tn">
                {{ 'street.ui.street_details.your_price' | translate }}
              </span>
              <span>{{ userBuyPrice | precision: 'DWRLD' }} DWRLD</span>
            </div>
          </div>
        </div>

        <ng-container *ngIf="street.isEnabledActions">
          <div *mediaIf="'large'" class="street-actions">
            <button
              (click)="buyStreet()"
              *ngIf="!street.isOwner; else sellTmpl"
              appButton
              class="solid primary">
              <ng-container *ngIf="!userBuyPrice; else orderTmpl">
                {{
                  street.sellPrice || !street.marketId
                    ? street.marketId
                     ? ('marketplace.streets.button.buy_now' | translate)
                     : ('marketplace.streets.button.mint' | translate)
                    : ('marketplace.streets.button.offer_price' | translate)
                }}
              </ng-container>
              <ng-template #orderTmpl>
                {{ 'marketplace.streets.button.view_order' | translate }}
              </ng-template>
            </button>
            <ng-template #sellTmpl>
              <button (click)="sellStreet()" appButton class="solid primary">
                {{
                  !street.sellPrice
                    ? ('marketplace.streets.button.sell_street' | translate)
                    : ('marketplace.streets.button.cancel_sell' | translate)
                }}
              </button>
            </ng-template>

            <ng-container *ngTemplateOutlet="basketBtnTmp"></ng-container>

            <ng-container *ngTemplateOutlet="favoriteBtnTmp"></ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="!street.isEnabledActions">
          <div *mediaIf="'large'" class="street-actions">
            <ng-container *ngTemplateOutlet="favoriteBtnTmp"></ng-container>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="wrapper">
      <div *mediaIf="'large'" class="street-info">
        <ng-container *ngTemplateOutlet="streetInfo"></ng-container>
      </div>

      <app-street-images
        [largeMap]="true"
        [navigateToMap]="true"
        [ngClass]="{ skeleton: isLoading }"
        [onlyLinesMap]="true"
        [asset]="street"
        class="margin-bottom-lg street-image width-100"
        mapStyle="DARK"></app-street-images>

      <div *mediaIf="'medium down'" class="street-addition-info">
        <div *mediaIf="'small only'" class="street-price">
          <div class="street-price_label">
            {{ 'marketplace.street_overview.street_price' | translate }}
          </div>

          <div class="street-price_value">
            <span class="font-hg weight-semibold color-white">
              {{ street.marketPrice | precision: 'DWRLD' }} DWRLD
            </span>
            <span class="color-primary weight-medium">
              $ {{ street.marketPrice | baseRate: 'DWRLD' }}
            </span>
          </div>

          <div
            *ngIf="userBuyPrice || street.buyPrice"
            class="display-flex margin-top-tn text-right opacity-50 color-white">
            <div *ngIf="street.buyPrice" class="font-sm">
              <span class="margin-right-tn">
                {{ 'street.ui.street_details.max_bid' | translate }}
              </span>
              <span>{{ street.buyPrice | precision: 'DWRLD' }} DWRLD</span>
            </div>

            <ng-container *ngIf="userBuyPrice && street.buyPrice">
              <div class="margin-left-tn margin-right-tn">/</div>
            </ng-container>

            <div *ngIf="userBuyPrice" class="font-sm">
              <span class="margin-right-tn">
                {{ 'street.ui.street_details.your_price' | translate }}
              </span>
              <span>{{ userBuyPrice | precision: 'DWRLD' }} DWRLD</span>
            </div>
          </div>
        </div>

        <div *ngIf="street.isEnabledActions" class="street-actions">
          <button
            (click)="buyStreet()"
            *ngIf="!street.isOwner; else sellTmpl"
            appButton
            class="solid primary width-100 medium margin-right">
            <ng-container *ngIf="!userBuyPrice; else orderTmpl">
              {{
                street.sellPrice || !street.marketId
                  ? ('marketplace.streets.button.buy_now' | translate)
                  : ('marketplace.streets.button.offer_price' | translate)
              }}
            </ng-container>
            <ng-template #orderTmpl>
              {{ 'marketplace.streets.button.view_order' | translate }}
            </ng-template>
          </button>
          <ng-template #sellTmpl>
            <button
              (click)="sellStreet()"
              appButton
              class="solid primary width-100 medium margin-right">
              {{
                !street.sellPrice
                  ? ('marketplace.streets.button.sell_street' | translate)
                  : ('marketplace.streets.button.cancel_sell' | translate)
              }}
            </button>
          </ng-template>

          <ng-container *ngTemplateOutlet="basketBtnTmp"></ng-container>

          <ng-container *ngTemplateOutlet="favoriteBtnTmp"></ng-container>
        </div>
        <div *ngIf="!street.isEnabledActions" class="street-actions">
          <ng-container *ngTemplateOutlet="favoriteBtnTmp"></ng-container>
        </div>
      </div>

      <div *mediaIf="'medium down'" class="street-info">
        <ng-container *ngTemplateOutlet="streetInfo"></ng-container>
      </div>
    </div>

    <app-street-overview-building-list
      [street]="street"
      class="street-info_container"></app-street-overview-building-list>
  </ng-container>

  <ng-template #noData>
    <app-no-data [canRedirect]="false" class="no-data" text="The street is not found"></app-no-data>
  </ng-template>
</div>

<ng-template #streetInfo>
  <div [ngClass]="{ skeleton: isLoading }" class="street-info_container">
    <div class="street-info_container_label color-white font-lg weight-medium margin-bottom-lg">
      {{ 'marketplace.street_overview.details' | translate }}
    </div>

    <app-street-overview-details
      [street]="street"
      class="street-info_container_content"></app-street-overview-details>
  </div>

  <div [ngClass]="{ skeleton: isLoading }" class="street-info_container">
    <div class="street-info_container_label color-white font-lg weight-medium margin-bottom-lg">
      {{ 'marketplace.street_overview.street_history' | translate }}
    </div>

    <app-street-history-list
      (paginationChange)="onPaginationChange($event)"
      [history]="streetHistory"
      [pagination]="pagination"
      class="width-100 color-white"></app-street-history-list>
  </div>
</ng-template>

<ng-template #favoriteBtnTmp>
  <app-icon
    (click)="toggleFavorite()"
    [class.favorite]="street.isFavorite"
    [icon]="iconStar"
    class="column favorite favorite-icon font-xhg color-warn"></app-icon>
</ng-template>
<ng-template #basketBtnTmp>
  <ng-container *ngIf="!street.marketId">
    <app-icon
      (click)="addToBasket($event)"
      *ngIf="!street.cartId"
      [icon]="iconCart"
      class="font-xhg color-primary-light cursor-pointer margin-left"></app-icon>
    <app-icon
      (click)="removeFromBasket($event)"
      *ngIf="street.cartId"
      [icon]="iconBagCross"
      class="bag-cross font-xhg color-warn cursor-pointer margin-left"></app-icon>
  </ng-container>
</ng-template>
