import { NgIfContext } from '@angular/common';
import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { environment } from 'environments/environment';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[envIf]',
})
export class EnvIfDirective implements OnInit {
  @Input()
  envIf?: 'dev' | 'prod' | 'client' | 'dev-uat' | 'ssr';
  @Input()
  envIfElse: TemplateRef<NgIfContext> | null = null;

  private thenViewCreated = false;
  private elseViewCreated = false;

  constructor(
    private templateRef: TemplateRef<HTMLElement>,
    private viewContainer: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.updateViewContainer();
  }

  private updateViewContainer(): void {
    const isProd = environment.production;
    const isUAT = environment.uat;
    const isAllowed =
      (this.envIf === 'prod' && isProd) ||
      (this.envIf === 'dev' && !isProd) ||
      (this.envIf === 'dev-uat' && (!isProd || isUAT)) ||
      (this.envIf === 'client' && !environment.ssr) ||
      (this.envIf === 'ssr' && environment.ssr);
    if (isAllowed) {
      if (this.elseViewCreated) {
        this.viewContainer.clear();
        this.elseViewCreated = false;
      }
      if (!this.thenViewCreated) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.thenViewCreated = true;
      }
    } else {
      if (this.thenViewCreated) {
        this.viewContainer.clear();
        this.thenViewCreated = false;
      }
      if (this.envIfElse && !this.elseViewCreated) {
        this.viewContainer.createEmbeddedView(this.envIfElse);
        this.elseViewCreated = true;
      }
    }
  }
}
