import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-cookies-banner',
  templateUrl: './cookies-banner.component.html',
  styleUrls: ['./cookies-banner.component.scss'],
})
export class CookiesBannerComponent implements OnInit {
  @Input() url!: string;
  showCookiesBanner: string | null = null;
  @Output() acceptCookieEmitter = new EventEmitter<boolean>();
  private cookiesBannerKey = 'accept-cookies';

  ngOnInit() {
    this.showCookiesBanner = localStorage.getItem(this.cookiesBannerKey);
  }

  acceptAction(): void {
    this.showCookiesBanner = 'true';
    localStorage.setItem(this.cookiesBannerKey, 'true');
    this.acceptCookieEmitter.emit(true);
  }
}
