import { Injectable } from '@angular/core';
import { CurrencyService } from 'core/services/currency.service';
import { GTMService } from 'core/services/gtm.service';
import { zoneToLabel } from 'dashboard/models/street.data';
import { Street } from 'dashboard/models/street.model';
import { GeolocationService } from 'streets/services/geolocation.service';
import {email} from "cashier/utils/crypto.constants";

export type GTMPurchaseType = 'initial' | 'continuity';

export type GTMPurchaseTypeKeys =
  | 'gtm-initial-purchase-type'
  | 'gtm-listing-item'
  | 'gtm-sell-item'
  | 'gtm-offer-price'
  | 'gtm-buy-item';

export interface SecondaryMarketGTMConfigData {
  event: 'marketplace-listing' | 'marketplace-offer' | 'marketplace-sell' | 'marketplace-buy';
  action: 'offer price' | 'listing' | 'sell street' | 'buy street';
  purchaseType: GTMPurchaseType;
  street: unknown;
  country: string;
  city: string;
  label: string;
  amount: number;
}

export interface PurchaseGTMConfigData {
  type: GTMPurchaseType;
  country: string;
  name: string;
  city: string;
  label: string;
  amount: number;
}

export interface MarketBuySellGTMData {
  city: string;
  country: string;
  street: string;
  price: string;
  lastPrice?: string;
  zone: string;
  currency?: string;
}

export interface DepositGTMData {
  currency: string;
  amount: number;
  transactionId?: number;
}

@Injectable({
  providedIn: 'root',
})
export class MarketGTMService {
  constructor(
    private gtmService: GTMService,
    private geolocation: GeolocationService,
    private currencyService: CurrencyService
  ) {}

  pushDepositTag(data: DepositGTMData & { userId: string, email?: string, transactionId?: number}): void {
    const method = data.currency === 'USD' || data.currency === 'EUR' ? 'fiat' : 'crypto';
    const usdAmount = method === 'crypto' ? this.currencyService.toBaseRate(data.amount, data.currency) : data.amount;
    let quantity = 0;
    if (method === 'fiat') {
      const baseDWRLDrate = this.currencyService.getBaseRate('DWRLD');
      const baseCurrencyRate = this.currencyService.getBaseRate(data.currency);
      quantity = data.amount * (1 / baseDWRLDrate) * baseCurrencyRate;
    } else {
      quantity = this.currencyService.toDwrldRate(data.amount, data.currency);
    }
    const conifg = {
      'event': 'purchase',
      'user_id': data.userId,
      'email': data.email,
      'ecommerce': {
        'transaction_id': data.transactionId,
        'value': usdAmount,
        'currency': 'USD',
        items: [{
          'item_name': 'DWRLD', // item name
          'quantity': quantity, // amount of items
          'price': data.amount,
          'currency': data.currency,
        }]
      }
    };
    this.gtmService.pushTag(conifg);
  }

  pushPurchaseTag(street: Street): void {
    const config = this.getPurchaseGTMConfig({
      amount: street.marketPrice,
      country: street.country,
      city: street.city,
      label: street.label,
      name: street.name,
      type: this.getPurchaseType('gtm-initial-purchase-type'),
    });
    this.gtmService.pushTag(config);
  }

  pushSellTag(data: MarketBuySellGTMData): void {
    const config = this.getSecondaryMarketGTMConfig({
      event: 'marketplace-sell',
      amount: Number(data.price),
      country: data.country,
      city: data.city,
      label: zoneToLabel(+data.zone),
      street: data.street,
      action: 'sell street',
      purchaseType: this.getPurchaseType('gtm-sell-item'),
    });
    this.gtmService.pushTag(config);
  }

  pushOfferTag(street: Street): void {
    const config = this.getSecondaryMarketGTMConfig({
      event: 'marketplace-offer',
      action: 'offer price',
      purchaseType: this.getPurchaseType('gtm-offer-price'),
      amount: street.marketPrice,
      city: street.city,
      country: street.country,
      label: street.label,
      street: street.name,
    });
    this.gtmService.pushTag(config);
  }

  pushBuyTag(data: MarketBuySellGTMData): void {
    const config = this.getSecondaryMarketGTMConfig({
      event: 'marketplace-buy',
      amount: Number(data.price),
      country: data.country,
      city: data.city,
      label: zoneToLabel(+data.zone),
      street: data.street,
      action: 'buy street',
      purchaseType: this.getPurchaseType('gtm-buy-item'),
    });
    this.gtmService.pushTag(config);
  }

  pushListingTag(street: Street): void {
    const config = this.getSecondaryMarketGTMConfig({
      event: 'marketplace-listing',
      action: 'listing',
      purchaseType: this.getPurchaseType('gtm-listing-item'),
      amount: street.marketPrice,
      city: street.city,
      country: street.country,
      label: street.label,
      street: street.name,
    });

    this.gtmService.pushTag(config);
  }

  private getSecondaryMarketGTMConfig(data: SecondaryMarketGTMConfigData): Record<string, unknown> {
    return {
      event: data.event,
      'marketplace-action': data.action,
      'marketplace-purchase-type': data.purchaseType,
      'marketplace-item-street': data.street,
      'marketplace-item-country': data.country,
      'marketplace-item-city': data.city,
      'marketplace-item-level': data.label,
      'marketplace-item-amount': this.currencyService.toBaseRate(data.amount, 'DWRLD'),
      'login-country': this.geolocation.data.countryCode,
    };
  }

  private getPurchaseGTMConfig(data: PurchaseGTMConfigData): Record<string, unknown> {
    return {
      event: 'purchase',
      'purchase-type': data.type,
      'purchase-item-type': 'street',
      'purchase-item-country': data.country,
      'purchase-item-name': data.name,
      'purchase-item-city': data.city,
      'purchase-item-level': data.label,
      'purchase-item-amount': this.currencyService.toBaseRate(data.amount, 'DWRLD'),
      'login-country': this.geolocation.data.countryCode,
    };
  }

  private getPurchaseType(lsKey: GTMPurchaseTypeKeys): GTMPurchaseType {
    const purchaseType = localStorage.getItem(lsKey);
    if (purchaseType && +purchaseType) {
      return 'continuity';
    }

    localStorage.setItem(lsKey, '1');
    return 'initial';
  }
}
