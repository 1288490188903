<ng-container *ngFor="let header of headers">
  <ng-container
    *ngTemplateOutlet="defaultSortHeaderItem; context: { $implicit: header }"></ng-container>
</ng-container>

<ng-template #defaultSortHeaderItem let-header>
  <div (click)="onSortChange(header)" [ngClass]="header.itemClass" class="column table-header-col">
    <ng-container *ngIf="header?.label || header?.icon || header?.key">
      <span class="column-title">
        {{ header.label | translate }}

        <ng-container *ngIf="header?.icon">
          <ng-container *ngIf="header.tooltipContent">
               <app-icon [appTooltip]="header.tooltipContent | translate" [icon]="header.icon"
                         class="header-icon"></app-icon>
          </ng-container>
          <ng-container *ngIf="!header.tooltipContent">
               <app-icon [icon]="header.icon" class="header-icon"></app-icon>
          </ng-container>
        </ng-container>

        <app-icon
          *ngIf="header.key"
          [icon]="sortIcon"
          [ngClass]="getIconClass(header)"
          class="sort"></app-icon>
      </span>
    </ng-container>
  </div>
</ng-template>
