import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MarketExchanged } from 'app/exchange/models/market-statistic';
import { StorageExchangePageService } from 'app/exchange/services/storage-exchange-page.service';
import { StorageMarketsService } from 'app/exchange/services/storage-markets.service';
import { AppIconsLibrary } from 'core/icons/icons-library.service';
import { iconArrowSwapHorizontal } from 'core/icons/lib/icon-arrow-swap-horizontal';
import { iconStar } from 'core/icons/lib/icon-start';
import { GenericFormControl } from 'core/utils/form-generics';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-exchange-statistic-selector',
  templateUrl: './exchange-statistic-selector.component.html',
  styleUrls: ['./exchange-statistic-selector.component.scss'],
})
export class ExchangeStatisticSelectorComponent implements OnChanges, OnInit, OnDestroy {
  readonly arrowSwapHorizontal = iconArrowSwapHorizontal;
  readonly iconStar = iconStar;

  @Input() currentMarket?: MarketExchanged = {};
  @Input() marketsList: MarketExchanged[] = [];
  @Output() changeMarketEmitter = new EventEmitter<MarketExchanged>();

  searchMarketsList: MarketExchanged[] = this.marketsList;
  searchControl = new GenericFormControl<string>();
  marketsControl = new GenericFormControl<MarketExchanged>(this.currentMarket);
  private destroy$ = new Subject();

  constructor(
    public storageExchangePageService: StorageExchangePageService,
    private storageMarketsService: StorageMarketsService,
    iconLib: AppIconsLibrary
  ) {
    iconLib.addIcons([iconArrowSwapHorizontal]);
  }

  ngOnChanges(changes: SimpleChanges<ExchangeStatisticSelectorComponent>): void {
    if (changes && changes.marketsList) {
      this.searchMarketsList = this.marketsList;
    }

    if (
      changes &&
      changes.currentMarket &&
      changes.currentMarket.currentValue &&
      !changes.currentMarket.firstChange
    ) {
      this.marketsControl.setValue(this.currentMarket, { emitEvent: false });
    }
  }

  ngOnInit(): void {
    this.searchControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(result => {
      this.searchMarketsList = this.marketsList.filter(x =>
        x.title?.toLowerCase().includes(result.toLowerCase())
      );
    });

    this.marketsControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(result => {
      this.currentMarket = result;
      this.changeMarketEmitter.emit(result);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onToggleFavorite(a: MarketExchanged): void {
    a.isFavorite = !a.isFavorite;
    this.storageMarketsService.toggleFavorite(a.id || 0);
  }
}
