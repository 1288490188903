import { Component, OnInit } from '@angular/core';
import { SortHeaderItem } from 'core/components/list-components/sorting.model';

@Component({
  selector: 'app-markletplace-building-list-header',
  templateUrl: './markletplace-building-list-header.component.html',
  styleUrls: ['./markletplace-building-list-header.component.scss'],
})
export class MarkletplaceBuildingListHeaderComponent implements OnInit {
  sortData?: string;

  headers: SortHeaderItem[] = [
    {
      label: $t('dashboard.assets.buildings.column.build'),
      itemClass: 'street',
      key: '',
    },
    {
      label: $t('marketplace.streets.column.level'),
      itemClass: 'level',
      key: '',
    },
    {
      label: $t('marketplace.streets.column.owner'),
      itemClass: 'owner',
      key: '',
    },
    {
      label: $t('marketplace.streets.purchase_price'),
      itemClass: 'purchase-price',
      key: '',
    },
    {
      label: $t('marketplace.streets.column.price'),
      itemClass: 'current-price',
      key: '',
    },
    // {
    //   label: $t('marketplace.streets.column.max_bid'),
    //   itemClass: 'max-bid',
    //   key: 'buyingPrice',
    // },
    {
      label: '',
      itemClass: 'actions-container',
    },
  ];

  constructor() {}

  ngOnInit(): void {}

  onSortChange(sort: string | undefined): void {}
}
