/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { MoneyTransactionTOList } from '../models/money-transaction-tolist';
import { HealthCheckResponse } from '../models/health-check-response';

/**
 * Health Check Controller
 */
@Injectable({
  providedIn: 'root',
})
class HealthCheckControllerService extends __BaseService {
  static readonly getErrorDepositsUsingGETPath = '/api/v1/health-check/error-deposits';
  static readonly checkLastLoginUsingGETPath = '/api/v1/health-check/last-login';
  static readonly checkServicesUsingGETPath = '/api/v1/health-check/services';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List all deposits with ERROR status
   * @return OK
   */
  getErrorDepositsUsingGETResponse(): __Observable<__StrictHttpResponse<MoneyTransactionTOList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/health-check/error-deposits`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MoneyTransactionTOList>;
      })
    );
  }
  /**
   * List all deposits with ERROR status
   * @return OK
   */
  getErrorDepositsUsingGET(): __Observable<MoneyTransactionTOList> {
    return this.getErrorDepositsUsingGETResponse().pipe(
      __map(_r => _r.body as MoneyTransactionTOList)
    );
  }

  /**
   * Check if last login less than hour ago
   * @return OK
   */
  checkLastLoginUsingGETResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/health-check/last-login`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Check if last login less than hour ago
   * @return OK
   */
  checkLastLoginUsingGET(): __Observable<string> {
    return this.checkLastLoginUsingGETResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Health-check of all services
   * @return OK
   */
  checkServicesUsingGETResponse(): __Observable<__StrictHttpResponse<HealthCheckResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/health-check/services`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HealthCheckResponse>;
      })
    );
  }
  /**
   * Health-check of all services
   * @return OK
   */
  checkServicesUsingGET(): __Observable<HealthCheckResponse> {
    return this.checkServicesUsingGETResponse().pipe(
      __map(_r => _r.body as HealthCheckResponse)
    );
  }
}

module HealthCheckControllerService {
}

export { HealthCheckControllerService }
