import { Directive, HostListener } from '@angular/core';

import { MenuComponent } from './menu.component';

@Directive({ selector: '[appMenuItem]' })
// directive for MenuComponent to close it on click
export class MenuItemDirective {
  constructor(private menu: MenuComponent) {}

  @HostListener('click')
  onClick(): void {
    this.menu.close();
  }
}
