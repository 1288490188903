<div class="column asset" style="grid-area: asset">
  <app-street-thumb
    [imageUrl]="item.imageUrl"
    [level]="item.level"
    [mediaClass]="['medium', 'small-thumb xsmall']"
    [titleSecondary]="item.secondaryName"
    [title]="item.mainName"
    class="small-thumb xsmall rounded"></app-street-thumb>
</div>
<div class="column price" style="grid-area: price">
  DWRLD {{ item.marketPrice | precision: 'DWRLD' }}
</div>
<div class="column type" style="grid-area: type">
  {{
    isBuilding
      ? ('checkout.table.type.building' | translate)
      : ('checkout.table.type.street' | translate)
  }}
</div>
<div class="column action margin-left-auto" style="grid-area: action">
  <ng-container *ngIf="!status; else statusTmp">
    <button
      (click)="removeFromCart(); $event.stopImmediatePropagation()"
      [appRoundButton]="deleteIcon"
      appAnchor
      class="clear-error"></button>
  </ng-container>
</div>

<ng-template #statusTmp>
  <div class="status">
    <ng-container [ngSwitch]="status">
      <ng-container *ngSwitchCase="'FAILED'">
        <app-icon
          [appTooltip]="'checkout.table.status.out_of_stock' | translate"
          [icon]="iconError"
          class="color-error icon"></app-icon>
      </ng-container>
      <ng-container *ngSwitchCase="'UNDEFINED_PURCHASE_STATUS'">
        <app-icon
          [appTooltip]="'checkout.table.status.error' | translate"
          [icon]="iconError"
          class="color-error icon"></app-icon>
      </ng-container>
      <ng-container *ngSwitchCase="'SUCCESS'">
        <app-icon
          [appTooltip]="'checkout.table.status.success' | translate"
          [icon]="iconSuccess"
          class="color-success icon"></app-icon>
      </ng-container>
      <ng-container *ngSwitchCase="'PENDING'">
        <app-icon
          [appTooltip]="'checkout.table.status.pending' | translate"
          [icon]="iconPending"
          class="spin color-warn icon"></app-icon>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
