import { Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { AppIcon, AppIconName } from 'core/icons/icon.type';

import { AppIconsLibrary } from '../../icons/icons-library.service';

@Component({
  selector: 'app-icon',
  template: ``,
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
  @Input()
  set icon(i: AppIconName | AppIcon | undefined) {
    if (i !== this._icon) {
      i = i || '';
      const prev = this._icon;
      this._icon = i;
      this.updateIcon(i, prev);
    }
  }

  private _icon: AppIconName | AppIcon = '';

  constructor(
    public elementRef: ElementRef<HTMLElement>,
    private icons: AppIconsLibrary,
    private renderer: Renderer2
  ) {}

  private updateIcon(current: AppIconName | AppIcon, previous: AppIconName | AppIcon): void {
    let rawSvg: string;
    let currentClass = '';
    const previousClass = typeof previous === 'object' ? previous.name : previous;
    if (typeof current === 'object') {
      rawSvg = current.svgData;
      currentClass = current.name;
    } else {
      currentClass = current;
      rawSvg = this.icons.getIcon(current);
    }
    if (!rawSvg) {
      return;
    }
    rawSvg = rawSvg.replace('<svg', '<svg class="svg-icon" ').replace('data-style', 'style');
    this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', rawSvg);
    this.renderer.addClass(this.elementRef.nativeElement, 'icon');
    if (previousClass) {
      this.renderer.removeClass(this.elementRef.nativeElement, previousClass);
    }
    if (currentClass) {
      this.renderer.addClass(this.elementRef.nativeElement, currentClass);
    }
  }
}
