import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SortHeaderItem} from 'core/components/list-components/sorting.model';
import {iconChevronDown} from 'core/icons/lib/icon-chevron-down';
import {iconChevronLeft} from 'core/icons/lib/icon-chevron-left';
import {iconClose} from 'core/icons/lib/icon-close';
import {iconStar} from 'core/icons/lib/icon-start';
import {NftFilterForm} from 'dashboard/forms/nft-filter.form';
import {NftFilterData} from 'dashboard/models/nftFilterData.data';
import {PageControllerService} from 'dashboard/services/page-controller.service';
import {Subject} from 'rxjs';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {CountryTOResponse} from "api/models/country-toresponse";
import {CityTOResponse} from "api/models/city-toresponse";

@Component({
  selector: 'app-wishlist-table-header',
  templateUrl: './wishlist-table-header.component.html',
  styleUrls: ['./wishlist-table-header.component.scss', '../../../../../dashboard/components/_styles/dashboard-layout.scss']
})
export class WishlistTableHeaderComponent implements OnInit {
  canUpdate = false;
  @Input() headers: SortHeaderItem[] = [];
  @Input() hasItems?: boolean;
  @Input() enable5zone = false;
  @Output() emitFilteringData = new EventEmitter<{
    value: NftFilterData;
    sort: string;
  }>();
  @Input() isCollections = false;
  nftFilterForm = new NftFilterForm();
  starIcon = iconStar;
  chevronIcon = iconChevronLeft;
  dropdownIcon = iconChevronDown;
  closeIcon = iconClose;
  cityId?: number;
  countryCode?: string;

  selectedData: string | undefined = undefined;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(private pageController: PageControllerService<NftFilterData, string>) {}

  pickCountry(country?: CountryTOResponse): void {
    this.nftFilterForm.controls.countryCode.setValue(country?.code);
  }

  pickCity(city?: CityTOResponse): void {
    this.nftFilterForm.controls.cityId.setValue(city?.id);
  }

  ngOnInit(): void {
    this.nftFilterForm.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(value => {
        this.filterNFTData(value);
      });

    this.pageController
      .getQueryParams()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.selectedData = data.sort;
        this.nftFilterForm.controls.description.setValue(data.filter.description, {
          emitEvent: false,
        });
        this.nftFilterForm.controls.price.setValue(data.filter.price, { emitEvent: false });
        this.nftFilterForm.controls.zones.setValue(data.filter.zones, { emitEvent: false });
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSortChange(event: string | undefined): void {
    this.selectedData = event;
    this.pageController.setSortParams(event || '');
  }

  filterNFTData(value: NftFilterData, sort = ''): void {
    this.pageController.setFilterParams(value);
    this.emitFilteringData.emit({ value, sort });
  }

}
