/* eslint-disable prefer-arrow-functions/prefer-arrow-functions */
import { CurrencyTOResponse } from 'api/models';

export type CurrencyType = Required<CurrencyTOResponse>['type'];
export type CurrencyNetworkType = Exclude<CurrencyTOResponse['networkTypes'], undefined>[number];
export class Currency {
  code!: string;
  id!: number;
  sign!: string;
  name!: string;

  precision!: number;

  withdrawFee!: number;
  minDepositAmount!: number;
  minWithdrawAmount!: number;
  network!: string;
  networkTypes!: CurrencyNetworkType[];

  get precisionArgs(): string {
    return `.${this.precision}-${this.precision}`;
  }

  type!: CurrencyType;
  isActive?: boolean;
  isWalletEnabled?: boolean;

  constructor(init: PickData<Currency> = dataToCurrency('')) {
    Object.assign(this, init);
  }
}

export function dataToCurrency(
  code: string,
  data: CurrencyTOResponse = {}
): PickData<Required<Currency>> {
  return {
    code: data.code || code,
    id: data.id || 0,
    network: DEFAULT_NETWORKS[data.code || ''] || data.title || '',
    precision: data.precision || 2,
    withdrawFee: data.withdrawFee || 0,
    sign: data.sign || '',
    name: data.title || '',
    type: data.type || 'UNKNOWN',
    networkTypes: data.networkTypes || [],
    isActive: data.isActive || true,
    isWalletEnabled: data.isWalletEnabled || true,
    minDepositAmount: data.minDepositAmount || 0,
    minWithdrawAmount: data.minWithdrawAmount || 0,
  };
}

const DEFAULT_NETWORKS: { [key: string]: string } = {
  ETH: 'ETH erc20',
  USDT: 'ETH erc20',
  BNB: 'BSC',
  USDC: 'ETH erc20',
};
