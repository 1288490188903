/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DepositAddressTOResponse } from '../models/deposit-address-toresponse';
import { GetDepositAddressForm } from '../models/get-deposit-address-form';
import { DepositAddressTOListResponse } from '../models/deposit-address-tolist-response';
import { WalletTOListResponse } from '../models/wallet-tolist-response';
import { NftTOListResponse } from '../models/nft-tolist-response';
import { AccountBalanceTimeLineResponseEntryTO } from '../models/account-balance-time-line-response-entry-to';
import { GetWalletTimeLineForm } from '../models/get-wallet-time-line-form';
import { MoneyTransactionTOResponse } from '../models/money-transaction-toresponse';
import { MoneyTransactionTOListResponse } from '../models/money-transaction-tolist-response';
import { InternalTransferForm } from '../models/internal-transfer-form';

/**
 * Wallet Controller
 */
@Injectable({
  providedIn: 'root',
})
class WalletControllerService extends __BaseService {
  static readonly getDepositAddressUsingPOSTPath = '/api/v1/wallet/deposit/address';
  static readonly listAccountDepositAddressesUsingGETPath = '/api/v1/wallet/deposit/address/account/list';
  static readonly getIntegratedDepositAddressUsingPOSTPath = '/api/v1/wallet/deposit/address/integrated';
  static readonly checkDepositAddressUsingGETPath = '/api/v1/wallet/deposit/check-address';
  static readonly depositAmountVolumeUsingGETPath = '/api/v1/wallet/deposits/amount';
  static readonly listUsingGET21Path = '/api/v1/wallet/list';
  static readonly listUsingGET22Path = '/api/v1/wallet/nft/list/{walletId}';
  static readonly timeLineUsingPOSTPath = '/api/v1/wallet/timeline';
  static readonly transactionDetailsUsingGETPath = '/api/v1/wallet/transaction/details/{transactionId}';
  static readonly transactionsUsingGETPath = '/api/v1/wallet/transaction/list';
  static readonly internalTransferUsingPOSTPath = '/api/v1/wallet/transfer';
  static readonly listDepositAddressesUsingGETPath = '/api/v1/wallet/{walletId}/deposit/address/list';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * getDepositAddress
   * @param form form
   * @return OK
   */
  getDepositAddressUsingPOSTResponse(form: GetDepositAddressForm): __Observable<__StrictHttpResponse<DepositAddressTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/wallet/deposit/address`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DepositAddressTOResponse>;
      })
    );
  }
  /**
   * getDepositAddress
   * @param form form
   * @return OK
   */
  getDepositAddressUsingPOST(form: GetDepositAddressForm): __Observable<DepositAddressTOResponse> {
    return this.getDepositAddressUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as DepositAddressTOResponse)
    );
  }

  /**
   * listAccountDepositAddresses
   * @return OK
   */
  listAccountDepositAddressesUsingGETResponse(): __Observable<__StrictHttpResponse<DepositAddressTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/wallet/deposit/address/account/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DepositAddressTOListResponse>;
      })
    );
  }
  /**
   * listAccountDepositAddresses
   * @return OK
   */
  listAccountDepositAddressesUsingGET(): __Observable<DepositAddressTOListResponse> {
    return this.listAccountDepositAddressesUsingGETResponse().pipe(
      __map(_r => _r.body as DepositAddressTOListResponse)
    );
  }

  /**
   * getIntegratedDepositAddress
   * @param form form
   * @return OK
   */
  getIntegratedDepositAddressUsingPOSTResponse(form: GetDepositAddressForm): __Observable<__StrictHttpResponse<DepositAddressTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/wallet/deposit/address/integrated`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DepositAddressTOResponse>;
      })
    );
  }
  /**
   * getIntegratedDepositAddress
   * @param form form
   * @return OK
   */
  getIntegratedDepositAddressUsingPOST(form: GetDepositAddressForm): __Observable<DepositAddressTOResponse> {
    return this.getIntegratedDepositAddressUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as DepositAddressTOResponse)
    );
  }

  /**
   * checkDepositAddress
   * @param address address
   * @return OK
   */
  checkDepositAddressUsingGETResponse(address: string): __Observable<__StrictHttpResponse<DepositAddressTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (address != null) __params = __params.set('address', address.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/wallet/deposit/check-address`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DepositAddressTOResponse>;
      })
    );
  }
  /**
   * checkDepositAddress
   * @param address address
   * @return OK
   */
  checkDepositAddressUsingGET(address: string): __Observable<DepositAddressTOResponse> {
    return this.checkDepositAddressUsingGETResponse(address).pipe(
      __map(_r => _r.body as DepositAddressTOResponse)
    );
  }

  /**
   * depositAmountVolume
   * @return OK
   */
  depositAmountVolumeUsingGETResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/wallet/deposits/amount`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * depositAmountVolume
   * @return OK
   */
  depositAmountVolumeUsingGET(): __Observable<string> {
    return this.depositAmountVolumeUsingGETResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * list
   * @return OK
   */
  listUsingGET21Response(): __Observable<__StrictHttpResponse<WalletTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/wallet/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WalletTOListResponse>;
      })
    );
  }
  /**
   * list
   * @return OK
   */
  listUsingGET21(): __Observable<WalletTOListResponse> {
    return this.listUsingGET21Response().pipe(
      __map(_r => _r.body as WalletTOListResponse)
    );
  }

  /**
   * list
   * @param params The `WalletControllerService.ListUsingGET22Params` containing the following parameters:
   *
   * - `walletId`: walletId
   *
   * - `street`: street
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `country`: country
   *
   * - `city`: city
   *
   * @return OK
   */
  listUsingGET22Response(params: WalletControllerService.ListUsingGET22Params): __Observable<__StrictHttpResponse<NftTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.street != null) __params = __params.set('street', params.street.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.country != null) __params = __params.set('country', params.country.toString());
    if (params.city != null) __params = __params.set('city', params.city.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/wallet/nft/list/${encodeURIComponent(String(params.walletId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NftTOListResponse>;
      })
    );
  }
  /**
   * list
   * @param params The `WalletControllerService.ListUsingGET22Params` containing the following parameters:
   *
   * - `walletId`: walletId
   *
   * - `street`: street
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `country`: country
   *
   * - `city`: city
   *
   * @return OK
   */
  listUsingGET22(params: WalletControllerService.ListUsingGET22Params): __Observable<NftTOListResponse> {
    return this.listUsingGET22Response(params).pipe(
      __map(_r => _r.body as NftTOListResponse)
    );
  }

  /**
   * timeLine
   * @param form form
   * @return OK
   */
  timeLineUsingPOSTResponse(form: GetWalletTimeLineForm): __Observable<__StrictHttpResponse<Array<AccountBalanceTimeLineResponseEntryTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/wallet/timeline`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AccountBalanceTimeLineResponseEntryTO>>;
      })
    );
  }
  /**
   * timeLine
   * @param form form
   * @return OK
   */
  timeLineUsingPOST(form: GetWalletTimeLineForm): __Observable<Array<AccountBalanceTimeLineResponseEntryTO>> {
    return this.timeLineUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as Array<AccountBalanceTimeLineResponseEntryTO>)
    );
  }

  /**
   * transactionDetails
   * @param transactionId transactionId
   * @return OK
   */
  transactionDetailsUsingGETResponse(transactionId: number): __Observable<__StrictHttpResponse<MoneyTransactionTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/wallet/transaction/details/${encodeURIComponent(String(transactionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MoneyTransactionTOResponse>;
      })
    );
  }
  /**
   * transactionDetails
   * @param transactionId transactionId
   * @return OK
   */
  transactionDetailsUsingGET(transactionId: number): __Observable<MoneyTransactionTOResponse> {
    return this.transactionDetailsUsingGETResponse(transactionId).pipe(
      __map(_r => _r.body as MoneyTransactionTOResponse)
    );
  }

  /**
   * transactions
   * @param params The `WalletControllerService.TransactionsUsingGETParams` containing the following parameters:
   *
   * - `type`:
   *
   * - `status`:
   *
   * - `sort`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `id`:
   *
   * - `excludeCancelled`:
   *
   * - `dateTo`:
   *
   * - `dateFrom`:
   *
   * - `currencyIds`:
   *
   * @return OK
   */
  transactionsUsingGETResponse(params: WalletControllerService.TransactionsUsingGETParams): __Observable<__StrictHttpResponse<MoneyTransactionTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.type != null) __params = __params.set('type', params.type.toString());
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.excludeCancelled != null) __params = __params.set('excludeCancelled', params.excludeCancelled.toString());
    if (params.dateTo != null) __params = __params.set('dateTo', params.dateTo.toString());
    if (params.dateFrom != null) __params = __params.set('dateFrom', params.dateFrom.toString());
    (params.currencyIds || []).forEach(val => {if (val != null) __params = __params.append('currencyIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/wallet/transaction/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MoneyTransactionTOListResponse>;
      })
    );
  }
  /**
   * transactions
   * @param params The `WalletControllerService.TransactionsUsingGETParams` containing the following parameters:
   *
   * - `type`:
   *
   * - `status`:
   *
   * - `sort`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `id`:
   *
   * - `excludeCancelled`:
   *
   * - `dateTo`:
   *
   * - `dateFrom`:
   *
   * - `currencyIds`:
   *
   * @return OK
   */
  transactionsUsingGET(params: WalletControllerService.TransactionsUsingGETParams): __Observable<MoneyTransactionTOListResponse> {
    return this.transactionsUsingGETResponse(params).pipe(
      __map(_r => _r.body as MoneyTransactionTOListResponse)
    );
  }

  /**
   * internalTransfer
   * @param request request
   * @return OK
   */
  internalTransferUsingPOSTResponse(request: InternalTransferForm): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/wallet/transfer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * internalTransfer
   * @param request request
   * @return OK
   */
  internalTransferUsingPOST(request: InternalTransferForm): __Observable<boolean> {
    return this.internalTransferUsingPOSTResponse(request).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * listDepositAddresses
   * @param walletId walletId
   * @return OK
   */
  listDepositAddressesUsingGETResponse(walletId: number): __Observable<__StrictHttpResponse<DepositAddressTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/wallet/${encodeURIComponent(String(walletId))}/deposit/address/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DepositAddressTOListResponse>;
      })
    );
  }
  /**
   * listDepositAddresses
   * @param walletId walletId
   * @return OK
   */
  listDepositAddressesUsingGET(walletId: number): __Observable<DepositAddressTOListResponse> {
    return this.listDepositAddressesUsingGETResponse(walletId).pipe(
      __map(_r => _r.body as DepositAddressTOListResponse)
    );
  }
}

module WalletControllerService {

  /**
   * Parameters for listUsingGET22
   */
  export interface ListUsingGET22Params {

    /**
     * walletId
     */
    walletId: number;

    /**
     * street
     */
    street?: string;

    /**
     * pageSize
     */
    pageSize?: number;

    /**
     * pageNumber
     */
    pageNumber?: number;

    /**
     * country
     */
    country?: string;

    /**
     * city
     */
    city?: string;
  }

  /**
   * Parameters for transactionsUsingGET
   */
  export interface TransactionsUsingGETParams {
    type?: 'BUILDING_COMMISSION_GET' | 'BUILDING_COMMISSION_PAY' | 'BURN_TOKEN' | 'BUY_MYSTERY_BOX' | 'DEPOSIT' | 'DIVIDEND' | 'HOT_TO_COLD' | 'INTERNAL_MONEY_RECEIVE' | 'INTERNAL_MONEY_TRANSFER' | 'INTERNAL_RECEIVE_NFT' | 'INTERNAL_TRANSFER_NFT' | 'MANUAL_DEPOSIT' | 'MANUAL_WITHDRAW' | 'NONE' | 'PROMOTION' | 'PROMO_CODE' | 'REFERRAL' | 'REWARD_3D_GET' | 'REWARD_3D_PAY' | 'ROLLING_3D_GET' | 'ROLLING_3D_PAY' | 'SOCIAL_REWARD_GET' | 'SOCIAL_REWARD_PAY' | 'UNRECOGNIZED' | 'WITHDRAW' | 'YIELD';
    status?: 'CANCELED_BY_USER' | 'CONFIRMED' | 'PAID_OUT' | 'PENDING' | 'REJECTED' | 'SUSPENDED' | 'UNDEFINED' | 'UNRECOGNIZED' | 'WAITING_FOR_BLOCKCHAIN_CONFIRM' | 'WAITING_FOR_EMAIL_CONFIRM';
    sort?: string;
    pageSize?: number;
    pageNumber?: number;
    id?: string;
    excludeCancelled?: boolean;
    dateTo?: string;
    dateFrom?: string;
    currencyIds?: Array<number>;
  }
}

export { WalletControllerService }
