import { Injectable } from '@angular/core';
import { NftCollectionTOResponse, PageTO } from 'api/models';
import {
  CollectionMetaInformationControllerService,
  CollectionStakeControllerService,
  GisControllerService,
  NftCollectionControllerService,
  NftControllerService,
} from 'api/services';
import { StateClearService, StateFullService } from 'app/_main/services/state-clear.service';
import { AuthService } from 'auth/services/auth.service';
import { I18nService } from 'core/modules/i18n/i18n.service';
import { SocketService } from 'core/modules/socket/socket.service';
import { GTMService } from 'core/services/gtm.service';
import { Toaster } from 'core/toaster';
import { levelToZone, StreetLevelType } from 'dashboard/models/street.data';
import { Street } from 'dashboard/models/street.model';
import { CartService } from 'dashboard/services/cart.service';
import { StreetsFactory } from 'dashboard/services/streets.factory';
import { NotificationEventData } from 'profile/services/notification.service';
import { UserService } from 'profile/services/user.service';
import { BehaviorSubject, iif, Observable, of, Subject } from 'rxjs';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { Collection, CollectionStatusFromBlockchain } from '../models/collection.model';
import { CollectionFactory } from './collection.factory';
import { StakingService } from './staking.service';

const stakeStatus = ['READY_FOR_STAKE', 'STAKED', 'UNSTAKED'] as const;
export type StakingStatusChange = typeof stakeStatus[number];

interface CollectionStatusEventData {
  data: {
    id: number;
    metaCollectionId: number;
    status: CollectionStatusFromBlockchain;
    nextPayouts: number;
    stakingEnd: string;
    nextPayOutDate: string;
  };
  eventType: 'NFT_BLOCKCHAIN_STATUS_CHANGED';
}

export interface CollectionGTMData {
  action: 'attach' | 'detach';
  name: string;
  city: string;
  country: string;
  label: string;
  full: 'collected' | 'not collected';
}

interface CollectionIncomeEventData {
  amount: number;
  collectionId: number;
  metaCollectionId: number;
  currentBalance: number;
  nextPayOutDate: number;
}

@Injectable({ providedIn: 'root' })
export class CollectionService implements StateFullService {
  private unstakedCollectionsStats = new BehaviorSubject<number>(0);
  myCollections: Collection[] | undefined = [];

  private collectionStreetsChangedSubj = new Subject<Collection>();
  collectionStreetsChanged = this.collectionStreetsChangedSubj.asObservable();

  constructor(
    private nftApi: NftControllerService,
    private collectionApi: NftCollectionControllerService,
    private collectionMetaApi: CollectionMetaInformationControllerService,
    private collectionStakeApi: CollectionStakeControllerService,
    private gisApi: GisControllerService,
    private toaster: Toaster,
    private factory: CollectionFactory,
    private streetFactory: StreetsFactory,
    private stakingService: StakingService,
    private cartService: CartService,
    private stateClearService: StateClearService,
    private authService: AuthService,
    private gtmService: GTMService,
    private i18n: I18nService,
    auth: AuthService,
    socket: SocketService,
    userService: UserService
  ) {
    this.stateClearService.register(this);
    const subscribeToStatus = () => {
      socket.send({
        messageType: 'NFT_BLOCKCHAIN_STATUS_SUBSCRIBE',
        nftBlockchainStatusMessage: {
          accountId: userService.user.accountId,
        },
      });
    };
    auth.loggedIn.subscribe(() => {
      subscribeToStatus();
    });
    socket.reconnected.subscribe(() => {
      subscribeToStatus();
    });
    socket.on<CollectionStatusEventData>().subscribe(e => {
      if (e.eventType === 'NFT_BLOCKCHAIN_STATUS_CHANGED') {
        const collection = this.factory.get(e.data.metaCollectionId);
        if (e.data.stakingEnd) {
          collection.stakingEnd = new Date(e.data.stakingEnd);
          collection.nextPayout = e.data.nextPayouts;
          collection.nextPayOutAt = new Date(e.data.nextPayOutDate).getTime();
        }
        collection.statusFromBlockchain = e.data.status;
        if (e.data.status === 'UNSTAKED') {
          this.stakingService.needToSyncUnStaking();
          this.stakingService.needToSyncStaking();
        }
        if (
          e.data.status === 'READY_FOR_STAKE' ||
          e.data.status === 'STAKED' ||
          e.data.status === 'UNSTAKED'
        ) {
          this.loadReadyForStakeCount();
        }
      }
    });
    socket.on<NotificationEventData>().subscribe(e => {
      if (e.eventType === 'USER_MESSAGE_RECEIVED') {
        if (e.data.messageType === 'COLLECTION_BALANCE_CHANGED') {
          const params = e.data.params as unknown as CollectionIncomeEventData;
          const collection = this.factory.get(+params.metaCollectionId);
          collection.currentBalance = +params.currentBalance;
          if (params.nextPayOutDate) {
            collection.nextPayOutAt = +params.nextPayOutDate;
          }
        }
      }
    });
  }

  loadReadyForStakeCount(): void {
    this.collectionStakeApi
      .listUsingGET3({
        stakeFilter: 'ONLY_UNSTAKED',
        pageSize: 1,
        pageNumber: 0,
      })
      .subscribe(unstakeData => {
        this.unstakedCollectionsStats.next(unstakeData.page?.totalElements || 0);
      });
  }

  selectReadyForStakeCount(): Observable<number> {
    return this.unstakedCollectionsStats.asObservable();
  }

  createNft(collection: Collection): Observable<NftCollectionTOResponse> {
    return this.collectionApi.createUsingPOST1(collection.meta.id).pipe(
      tap(res => {
        collection.id = res.id || 1;
        collection.statusFromBlockchain = 'CREATING';
      })
    );
  }

  deleteNft(collection: Collection): Observable<null> {
    return this.collectionApi.deleteCollectionUsingDELETE(collection.id).pipe(
      switchMap(_res => {
        if (!this.cartService.items.find(x => x.id === collection.cartId)) {
          return of(null);
        }
        return this.cartService.removeFromCollection(collection);
      }),
      tap(() => {
        collection.reset();
      })
    );
  }

  attachNft(collection: Collection, nftIds: Array<number>): Observable<NftCollectionTOResponse> {
    return this.collectionApi
      .attachNftToCollectionUsingPOST({
        id: collection.id,
        nftIds,
      })
      .pipe(
        tap(res => {
          collection.isCompleted = res.completedPercent === 100;
          collection.basicStreetsCount = res.basicIncludeStreetsCount || 0;
          collection.standardStreetsCount = res.standardIncludeStreetsCount || 0;
          collection.premiumStreetsCount = res.premiumIncludeStreetsCount || 0;
          collection.eliteStreetsCount = res.eliteIncludeStreetsCount || 0;
          collection.suggestionForCurrentUser -= nftIds.length;
          if (collection.suggestionForCurrentUser < 0) {
            collection.suggestionForCurrentUser = 0;
          }
          const isFull = collection.currentCount >= collection.totalCount;
          if (isFull) {
            this.toaster.info(
              this.i18n.get($t('collections.toast.assembled-title')),
              this.i18n.get($t('collections.toast.assembled-message', [collection.title]))
            );
          }
          this.pushAttachTag(collection, isFull);
          this.collectionStreetsChangedSubj.next(collection);
        })
      );
  }

  detachNft(collection: Collection, nftIds: number[]): Observable<NftCollectionTOResponse> {
    return this.collectionApi
      .detachNftFromCollectionUsingPOST({
        id: collection.id,
        nftIds,
      })
      .pipe(
        tap(res => {
          this.handleDetachResponse(collection, res, nftIds.length);
          const isFull = collection.currentCount >= collection.totalCount;
          this.pushDetachTag(collection, isFull);
        })
      );
  }

  private handleDetachResponse(
    collection: Collection,
    res: NftCollectionTOResponse,
    suggestionsCount: number
  ): void {
    collection.isCompleted = res.completedPercent === 100;
    collection.basicStreetsCount = res.basicIncludeStreetsCount || 0;
    collection.standardStreetsCount = res.standardIncludeStreetsCount || 0;
    collection.premiumStreetsCount = res.premiumIncludeStreetsCount || 0;
    collection.eliteStreetsCount = res.eliteIncludeStreetsCount || 0;
    collection.suggestionForCurrentUser += suggestionsCount;
    if (collection.id) {
      this.collectionStreetsChangedSubj.next(collection);
    }
  }

  detachNftByCollectionId(
    userCollectionId: number,
    streets: Street[]
  ): Observable<NftCollectionTOResponse> {
    return this.collectionApi
      .detachNftFromCollectionUsingPOST({
        id: userCollectionId,
        nftIds: streets.map(s => s.nft.id),
      })
      .pipe(
        tap(res => {
          const collection = this.factory.getUserCollection(userCollectionId);
          streets.forEach(street => {
            collection.removeStreet(street);
          });
          this.handleDetachResponse(collection, res, streets.length);
        })
      );
  }

  getMyStreets(
    metaId: number,
    level: StreetLevelType,
    count = 10
  ): Observable<{ pageInfo?: PageTO; elements?: Street[] }> {
    return this.nftApi
      .suggestionForCollectionUsingGET1({
        metaInformationId: metaId,
        zone: levelToZone(level),
        pageSize: count,
      })
      .pipe(
        map(res => ({
          pageInfo: res.pageInfo,
          elements: res.elements?.map(r => this.streetFactory.get(r.properties?.streetId, r)),
        }))
      );
  }

  getSuggestedStreets(
    collection: Collection,
    level: StreetLevelType,
    count = 10
  ): Observable<{ pageInfo?: PageTO; elements?: Street[] }> {
    const params: GisControllerService.SuggestionForCollectionUsingGETParams = {
      countriesCodes: collection.meta.countryCodes,
      cityIds: collection.meta.cityIds,
      zone: levelToZone(level),
      pageSize: count,
    };
    if (collection.meta.level === 'plan5') {
      params.streetIds = collection.meta.requiredStreetIds.filter(id => id !== 0);
    }
    return iif(
      () => this.authService.isAuth,
      this.gisApi.suggestionForCollectionUsingPOST(params),
      this.gisApi.suggestionForCollectionPublicUsingGET(params)
    ).pipe(
      map(res => {
        res.elements?.forEach(s => ((s as { streetId: number | undefined }).streetId = s.id));
        return {
          pageInfo: res.pageInfo,
          elements: res.elements?.map(r => this.streetFactory.get(r.id, r)),
        };
      })
    );
  }

  getCollectionById(id: number): Observable<Collection> {
    return this.collectionApi.findByIdUsingGET1(id).pipe(
      map(res => {
        const collection = this.factory.get(res.metaInformation?.id, res);
        const cartItem = this.cartService.items.find(i => i.object.id === id);
        if (cartItem) {
          cartItem.nestedItems.forEach(s => {
            collection.addStreet(s as Street);
          });
        }
        return collection;
      })
    );
  }

  getMetaById(id: number): Observable<Collection> {
    let req = this.collectionMetaApi.publicFindByIdUsingGET(id);
    if (this.authService.isAuth) {
      req = this.collectionMetaApi.findByIdUsingGET(id);
    }
    return req.pipe(
      mergeMap(res => {
        if (res.collectionIdCreatedCurrentUser) {
          return this.getCollectionById(res.collectionIdCreatedCurrentUser);
        }
        return of(this.factory.get(res.id, { metaInformation: res }));
      })
    );
  }

  getCollectionList(
    params: NftCollectionControllerService.ListUsingGET13Params
  ): Observable<{ pageInfo?: PageTO; elements?: Collection[] }> {
    return this.collectionApi
      .listUsingGET13({
        ...params,
        zones: params.zones,
      })
      .pipe(
        map(res => ({
          pageInfo: res.page,
          elements: (this.myCollections = res.elements?.map(r =>
            this.factory.get(r.metaInformation?.id, r)
          )),
        }))
      );
  }

  unstakeCollection(collection: Collection): Observable<NftCollectionTOResponse> {
    return this.collectionApi.stakeUsingPOST({ id: collection.id, isStake: false }).pipe(
      tap(() => {
        collection.isStake = false;
        // TODO: for now it is wrong from BE response
        collection.statusFromBlockchain = 'WAITING_FOR_IRREVERSIBLE';
      })
    );
  }

  clearState(): void {
    this.myCollections = [];
    this.factory.emptyStore();
  }

  private pushAttachTag(collection: Collection, isFull: boolean): void {
    const config = this.getCollectionGTMTagConfig({
      action: 'attach',
      name: collection.title,
      city: String(collection.meta.cityIds[0]),
      country: collection.country,
      full: isFull ? 'collected' : 'not collected',
      label: collection.meta.label,
    });
    this.gtmService.pushTag(config);
  }

  private pushDetachTag(collection: Collection, isFull: boolean): void {
    const config = this.getCollectionGTMTagConfig({
      action: 'detach',
      name: collection.title,
      city: String(collection.meta.cityIds[0]),
      country: collection.country,
      full: isFull ? 'collected' : 'not collected',
      label: collection.meta.label,
    });
    this.gtmService.pushTag(config);
  }

  private getCollectionGTMTagConfig(data: CollectionGTMData): Record<string, unknown> {
    return {
      event: 'collection',
      'Collection-action': data.action,
      'Collection-name': data.name,
      'Collection-city': data.city,
      'Collection-country': data.country,
      'Collection-level': data.label,
      'Collection-full': data.full,
    };
  }
}
