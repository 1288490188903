import { Injectable } from '@angular/core';
import { UserControllerService } from 'api/services';
import { StateClearService, StateFullService } from 'app/_main/services/state-clear.service';
import { SocketService } from 'core/modules/socket/socket.service';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { UserService } from './user.service';

export interface NotificationWsEvent {
  topic: WsNotificationTypes;
  active: boolean;
}

export type WsNotificationTypes = 'NFT_BUY_SUBSCRIBE' | 'MYSTERY_BOX_SUBSCRIBE';

export type WsNotificationUnsubscribeTypes = 'NFT_BUY_UNSUBSCRIBE' | 'MYSTERY_BOX_UNSUBSCRIBE';

export const wsNotificationMap = {
  NFT_BUY_SUBSCRIBE: 'showNftBuy',
  MYSTERY_BOX_SUBSCRIBE: 'showMysteryBoxOpen',
};

@Injectable({
  providedIn: 'root',
})
export class NotificationWsConfigurationService implements StateFullService {
  private nftBuySubType: WsNotificationTypes = 'NFT_BUY_SUBSCRIBE';
  private nftBuyUnsubType: WsNotificationUnsubscribeTypes = 'NFT_BUY_UNSUBSCRIBE';
  private mysteryBoxSubType: WsNotificationTypes = 'MYSTERY_BOX_SUBSCRIBE';
  private mysteryBoxUnsubType: WsNotificationUnsubscribeTypes = 'MYSTERY_BOX_UNSUBSCRIBE';

  private notificationsWsMap$ = new BehaviorSubject<Map<WsNotificationTypes, NotificationWsEvent>>(
    new Map<WsNotificationTypes, NotificationWsEvent>()
  );

  constructor(
    private stateClearService: StateClearService,
    private socket: SocketService,
    private userService: UserService,
    private userController: UserControllerService
  ) {
    this.stateClearService.register(this);
  }

  getWsNotificationList(): Observable<NotificationWsEvent[]> {
    const { showMysteryBoxPopUp, showNftBuyPopUp } = this.userService.user;

    return forkJoin([
      of({
        topic: this.nftBuySubType,
        active: showNftBuyPopUp,
      }),
      of({
        topic: this.mysteryBoxSubType,
        active: showMysteryBoxPopUp,
      }),
    ]).pipe(
      tap(notifications => {
        this.setToMap(notifications);
      })
    );
  }

  updateWsNotifications(notifications: NotificationWsEvent[]): Observable<NotificationWsEvent[]> {
    notifications.forEach(notification => {
      if (notification.topic === this.nftBuySubType) {
        this.userService.user.showNftBuyPopUp = notification.active;
        this.socket.send({
          messageType: notification.active ? this.nftBuySubType : this.nftBuyUnsubType,
        });
      }

      if (notification.topic === this.mysteryBoxSubType) {
        this.userService.user.showMysteryBoxPopUp = notification.active;
        this.socket.send({
          messageType: notification.active ? this.mysteryBoxSubType : this.mysteryBoxUnsubType,
        });
      }
    });

    const params = notifications.reduce(
      (acc, notification) => ({
        ...acc,
        [wsNotificationMap[notification.topic]]: notification.active || false,
      }),
      {}
    ) as UserControllerService.UpdateUserWsSettingsUsingPUTParams;

    return this.userController.updateUserWsSettingsUsingPUT(params).pipe(
      tap(() => {
        this.setToMap(notifications);
      }),
      switchMap(() => this.selectWsNotifications())
    );
  }

  selectWsNotifications(): Observable<NotificationWsEvent[]> {
    return this.notificationsWsMap$.asObservable().pipe(map(map => Array.from(map.values())));
  }

  clearState(): void {
    const map = this.notificationsWsMap$.getValue();
    map.clear();
    this.notificationsWsMap$.next(map);
  }

  private setToMap(notifications: NotificationWsEvent[]): void {
    notifications.forEach(notification => {
      const map = this.notificationsWsMap$.getValue();
      map.set(notification.topic, notification);
      this.notificationsWsMap$.next(map);
    });
  }
}
