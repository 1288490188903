import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import {
  SortDirection,
  SortHeaderItem,
  SortStatus,
} from 'core/components/list-components/sorting.model';
import { iconArrowLeft } from 'core/icons/lib/icon-arrow-left';
import { iconCheckInner } from 'core/icons/lib/icon-check-inner';
import { iconChevronDown } from 'core/icons/lib/icon-chevron-down';
import { iconClose } from 'core/icons/lib/icon-close';
import { iconFilter } from 'core/icons/lib/icon-filter';

@Component({
  selector: 'app-mobile-filters',
  templateUrl: './mobile-filters.component.html',
  styleUrls: ['./mobile-filters.component.scss'],
})
export class MobileFiltersComponent implements OnChanges {
  @Input() headers: SortHeaderItem[] = [];
  @Input() hideFilters = false;
  @Input() sortData?: string;
  @Input() filterTitle = 'Filter Assets';

  @Output() sortChange = new EventEmitter<string | undefined>();

  readonly arrowIcon = iconArrowLeft;
  readonly filterIcon = iconFilter;
  readonly dropdownIcon = iconChevronDown;
  readonly closeIcon = iconClose;
  readonly checkIcon = iconCheckInner;

  sortStatus = '';

  activeSort: SortStatus = {
    key: '',
    direction: 'none',
    header: {},
  };

  private sortDirections: SortDirection[] = ['desc', 'asc'];

  ngOnChanges(changes: SimpleChanges<MobileFiltersComponent>): void {
    if (changes.sortData?.currentValue) {
      const sortEvent = changes.sortData?.currentValue.split(',');
      this.activeSort = {
        direction: sortEvent[1] as SortDirection,
        key: sortEvent[0],
        header: this.headers.find(header => header.key === sortEvent[0]) || {},
      };
    }
  }

  onSortChange(header: SortHeaderItem): void {
    this.activeSort = this.getNextSortDirection(header);
    const sortData = this.activeSort.key
      ? Object.values(this.activeSort)
          .filter(item => typeof item === 'string')
          .join(',')
      : undefined;
    this.sortChange.next(sortData);
  }

  changeDirection(value: SortDirection | undefined): void {
    const exactHeader = this.headers.find(item => item.key === this.activeSort.key);

    if (exactHeader) {
      this.getNextSortDirection(exactHeader);
    }

    if (value) {
      this.activeSort.direction = value;
      const { key, direction } = this.activeSort;
      const sortData = key ? `${key},${direction}` : undefined;
      this.sortChange.next(sortData);
    } else {
      this.activeSort = {
        key: exactHeader?.key || '',
        direction: this.sortDirections[0],
        header: exactHeader || {},
      };

      const { key, direction } = this.activeSort;
      const sortData = key ? `${key},${direction}` : undefined;
      this.sortChange.next(sortData);
    }
  }

  disableSort(): void {
    this.activeSort = {
      key: '',
      direction: 'none',
      header: {},
    };
    this.sortChange.next();
  }

  private getNextSortDirection(header: SortHeaderItem): SortStatus {
    if (header.key === this.activeSort.key) {
      const currentDirectionIndex = this.sortDirections.indexOf(this.activeSort.direction);
      const nextDirectionIndex =
        currentDirectionIndex + 1 > this.sortDirections.length - 1 ? -1 : currentDirectionIndex + 1;

      if (nextDirectionIndex < 0) {
        return {
          key: '',
          direction: 'none',
          header,
        };
      }

      return {
        key: this.activeSort.key,
        direction: this.sortDirections[nextDirectionIndex],
        header,
      };
    }

    return {
      key: header.key || '',
      direction: this.sortDirections[0],
      header,
    };
  }
}
