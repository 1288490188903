<ng-container *ngIf="!rotationService.emulatedFullScreen">
  <app-header
    [disableBalance]="disableBalance"
    [allowFixed]="rotationService.allowHeaderFixed"
    class="header"
    *ngIf="showHeader"
    [ngClass]="{
      blurred: blurredHeader,
      transparent: transparentHeader,
      v2: v2GuestHeader
    }"></app-header>
</ng-container>
<main
  #mainRef
  [class.notFullScreen]="!rotationService.emulatedFullScreen"
  [class.with-bonus-deposit]="isBonusShown">
  <router-outlet></router-outlet>
  <app-toolbar-outlet></app-toolbar-outlet>
  <ng-container *ngIf="!rotationService.emulatedFullScreen">
    <app-footer class="margin-top-auto" *ngIf="showFooter"></app-footer>
  </ng-container>
  <ng-container
    *ngIf="
      !isMetaversePage &&
      auth.isAuth &&
      !hideBonusScreen &&
      ((bonusService.isFirstDeposit && !bonusService.popupMinimized) ||
        !bonusService.isFirstDeposit)
    ">
    <div
      class="custom-popup primary"
      [ngClass]="{
        bottom: bonusService.popupMinimized,
        center: !bonusService.popupMinimized,
        primary: bonusService.isFirstDeposit,
        secondary: !bonusService.isFirstDeposit
      }"
      [@moveFromBottomTransition]
      *ngIf="bonusService.bonusChanged | async as x">
      <app-icon [icon]="closeIcon" (click)="bonusService.minimizePopup()"></app-icon>
      Get
      <span class="boost-val">{{ x }}x</span>
      <br />
      boost on your
      <br />
      {{ bonusService.isFirstDeposit || !auth.isAuth ? 'FIRST' : 'NEXT' }} top-up!
      <br />
      <button class="boost-btn" (click)="openDepositModal()">
        {{ bonusService.popupMinimized ? 'TOP-UP NOW!' : 'Boost now!' }}
      </button>
    </div>
  </ng-container>
</main>
<app-landscape-lock *ngIf="lockLandscape && showLockLandscape"></app-landscape-lock>
<app-fixed-card class="cart"></app-fixed-card>
<ng-container *envIf="'client'">
  <div class="toaster-host">
    <app-toaster class="toaster"></app-toaster>
  </div>
  <app-modal class="modal">
    <router-outlet name="primaryModal"></router-outlet>
  </app-modal>
  <app-cookies-banner
    [url]="appNav.legalNav.root('cookie_statement')"
    (acceptCookieEmitter)="acceptCookieAction()"></app-cookies-banner>
</ng-container>
