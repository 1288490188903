import { MarketTOResponse } from 'api/models/market-toresponse';

export interface MarketExchanged extends MarketTOResponse {
  isFavorite?: boolean;
  price?: number;
  changePercent?: number;
  down?: boolean;
  up?: boolean;
  lowValue?: number;
  highValue?: number;
  changeValue?: number;
}

export type EventType = 'TICKERS_SUBSCRIBE' | 'TICKERS' | 'OHLCV_DATA_UPDATE' | 'OHLCV_SUBSCRIBE';

export interface TickersEvent {
  marketId: number;
  price: number;
}

export interface OHLCVData {
  time: number;
  open: number;
  high: number;
  low: number;
  close: number;
  volume: number;
}
export interface OHLCVEventData {
  marketId: number;
  resolution: string;
  ohlcvList: OHLCVData[];
}

export interface ExchangeMarketStatistic {
  marketId?: number;
  lowValue?: number;
  highValue?: number;
  changeValue?: number;
  changePercent?: number;
  lastPrice?: number;
}

export interface MarketStatsData {
  dailyAverage: number;
  dailyHigh: number;
  dailyLow: number;
  dailyOpen: number;
  dailyVolumeBase: number;
  dailyVolumeQuote: number;
  lastPrice: number;
  marketId: number;
}

export const mapMarketsListToMap = (
  marketsList: MarketExchanged[]
): Record<number, MarketExchanged> =>
  marketsList.reduce(
    (acc, current) => ({
      ...acc,
      [current.id || 0]: current,
    }),
    {} as Record<number, MarketExchanged>
  );
