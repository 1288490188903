import { Directive, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive()
export abstract class ControlHandler {
  @Input()
  set control(c: AbstractControl | undefined) {
    this.fieldControl = c;
    if (c) {
      this.onControlAssigned();
    }
  }
  get control(): AbstractControl | undefined {
    return this.fieldControl;
  }
  private fieldControl?: AbstractControl;

  protected abstract onControlAssigned(): void;
}
