import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { StreetLevelType } from 'dashboard/models/street.data';

@Component({
  selector: 'app-street-thumb',
  templateUrl: 'street-thumb.component.html',
  styleUrls: ['./street-thumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StreetThumbComponent implements OnChanges {
  @Input()
  level?: StreetLevelType;

  @Input()
  title?: string;

  @Input()
  titleSecondary?: string;

  @Input()
  imageUrl?: string;

  @Input()
  isAvailable = true;

  @Input()
  isSold = false;

  @Input()
  isWithdrawn = false;

  @Input()
  coverLogoSize = 'default';

  backgroundImage = 'url()';

  ngOnChanges(changes: SimpleChanges<StreetThumbComponent>): void {
    if (changes.imageUrl) {
      this.updateBackgroundImage();
    }
  }

  private updateBackgroundImage(): void {
    this.backgroundImage = `url('${this.imageUrl}')`;
  }
}
