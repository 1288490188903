import { Injectable } from '@angular/core';
import { UserService } from 'profile/services/user.service';

export interface FavoritesMarketsList {
  list: number[];
}

export interface CurrentMarket {
  id?: number;
  title?: string;
}

export enum StorageMarketsKeys {
  favoriteMarketsKey = 'favorites-markets-key',
  currentMarketKey = 'current-market-key',
}

@Injectable({
  providedIn: 'root',
})
export class StorageMarketsService {
  private get marketKey(): string {
    return `${StorageMarketsKeys.currentMarketKey}-${UserService.instance.id}`;
  }
  private get favoriteKey(): string {
    return `${StorageMarketsKeys.favoriteMarketsKey}-${UserService.instance.id}`;
  }

  getCurrentMarket(): CurrentMarket {
    return JSON.parse(localStorage.getItem(this.marketKey) || '{}') as CurrentMarket;
  }

  changeCurrentMarket(currentMarket: CurrentMarket) {
    localStorage.setItem(this.marketKey, JSON.stringify(currentMarket));
  }

  getFavoriteList(): number[] {
    return (JSON.parse(localStorage.getItem(this.favoriteKey) || '{}') as FavoritesMarketsList)
      .list;
  }

  toggleFavorite(id: number): void {
    let list = (JSON.parse(localStorage.getItem(this.favoriteKey) || '{}') as FavoritesMarketsList)
      .list;
    if (list && list.length !== 0) {
      if (list.includes(id)) {
        list = list.filter(x => x !== id);
      } else {
        list.push(id);
      }
    } else {
      list = [id];
    }
    localStorage.setItem(this.favoriteKey, JSON.stringify({ list }));
  }
}
