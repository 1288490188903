import { Component } from '@angular/core';

@Component({
  selector: 'app-badge',
  template: `
    <ng-content></ng-content>
  `,
  styleUrls: ['./styles/badge.component.scss'],
})
export class BadgeComponent {}
