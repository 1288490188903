import { animate, style, transition, trigger } from '@angular/animations';

export const successTransition = trigger('successTransition', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateY(10px)' }),
    animate('.15s ease-out', style({ opacity: 1, transform: 'translateY(0)' })),
  ]),
  transition(':leave', [
    style({ opacity: 1, transform: 'translateY(0)' }),
    animate('.15s', style({ opacity: 0, transform: 'translateY(-5px)' })),
  ]),
]);
