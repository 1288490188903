import { Component, HostListener, OnDestroy } from '@angular/core';
import { lazyModalsNav } from 'app/app.navigation';
import { AppIconsLibrary } from 'core/icons/icons-library.service';
import { iconPlan1 } from 'core/icons/lib/icon-plan1';
import { iconPlan2 } from 'core/icons/lib/icon-plan2';
import { iconPlan3 } from 'core/icons/lib/icon-plan3';
import { iconPlan4 } from 'core/icons/lib/icon-plan4';
import { iconPlan5 } from 'core/icons/lib/icon-plan5';
import { iconProfile } from 'core/icons/lib/icon-profile';
import { ModalRouter } from 'core/modules/modal';
import { BaseCustomToast } from 'core/toaster/base-custom-toast.interface';
import { ToastExtras } from 'core/toaster/services/toaster.service';
import { StreetPreviewModal } from 'dashboard/dashboard.navigation';
import { NFTBuyEvent } from 'dashboard/models/nft-buy-event.model';
import { STREET_LEVELS } from 'dashboard/models/street.data';
import { StreetsService } from 'dashboard/services/streets.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface StreetToastExtras {
  count: number;
  elements: NFTBuyEvent[];
}
@Component({
  selector: 'app-street-toast',
  templateUrl: './street-toast.component.html',
  styleUrls: ['./street-toast.component.scss'],
})
export class StreetToastComponent extends BaseCustomToast<StreetToastExtras> implements OnDestroy {
  private destroy$ = new Subject<void>();
  readonly iconProfile = iconProfile;

  isStreetWithBuilding = false;
  levels = STREET_LEVELS;
  data!: StreetToastExtras;

  constructor(
    iconLib: AppIconsLibrary,
    private modalRouter: ModalRouter,
    private streetsService: StreetsService
  ) {
    super();
    iconLib.addIcons([iconPlan3, iconPlan2, iconPlan4, iconPlan5, iconPlan1]);
  }

  onCustomToastInit(extras?: ToastExtras<StreetToastExtras>): void {
    if (extras) {
      this.data = extras.data;

      if (extras.data.elements.length === 2) {
        if (
          (this.data.elements[0].dtype === 'BUILDING' && this.data.elements[1].dtype === 'STREET') ||
          (this.data.elements[0].dtype === 'STREET' && this.data.elements[1].dtype === 'BUILDING') ||
          (this.data.elements[0].dtype === 'NftBuilding' && this.data.elements[1].dtype === 'Nft') ||
          (this.data.elements[0].dtype === 'Nft' && this.data.elements[1].dtype === 'NftBuilding')
        ) {
          this.isStreetWithBuilding = true;
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('click')
  openPreview(): void {
    this.streetsService
      .findByNFTMarket({ nftId: Number(this.data.elements[0].id) })
      .pipe(takeUntil(this.destroy$))
      .subscribe(street => {
        this.modalRouter.navigate(lazyModalsNav.streets.streetBuy(), undefined, {
          data: {
            entity: street,
            isMarket: street?.buyAt ? true : false,
          } as StreetPreviewModal,
        });
      });
  }
}
