<ng-container *ngIf="isStreetWithBuilding">
  <div class="title weight-semibold display-flex align-center">
    <span class="owner" [appPublicProfile]="data.elements[0].ownerName">
      <app-icon [icon]="iconProfile" class="font-md"></app-icon>
      <div class="owner margin-left-tn font-sm">
        {{ data.elements[0].ownerName || 'User_' | nickname }}
      </div>
    </span>
    <div class="description font-sm">
      {{ 'main.street.toast.description-with-street' | translate }}
    </div>
  </div>

  <div class="content" *ngFor="let asset of data.elements">
    <div class="street-level-thumb">
      <div [ngClass]="['level-icon-container', levels[asset.level].icon]">
        <app-icon class="icon" [icon]="levels[asset.level]?.icon"></app-icon>
        <span class="level-icon-title">
          {{ levels[asset.level].title }}
        </span>
      </div>
      <div class="street-info">
        <div class="street-title">
          {{ asset.streetName }} {{ asset.number !== '' ? asset.number : '' }}
        </div>
        <div class="street-place">
          {{ asset.streetSecondaryName }}
        </div>
      </div>
    </div>

    <div class="street-price">{{ asset.lastPrice }} DWRLD</div>
  </div>
</ng-container>
<ng-container *ngIf="!isStreetWithBuilding">
  <ng-container *ngIf="data.count <= 1">
    <div class="title weight-semibold display-flex align-center">
      <span class="owner" [appPublicProfile]="data.elements[0].ownerName">
        <app-icon [icon]="iconProfile" class="font-md"></app-icon>
        <div class="owner margin-left-tn font-sm">
          {{ data.elements[0].ownerName || 'User_' | nickname }}
        </div>
      </span>
      <div class="description font-sm">
        {{
          (data.elements[0].dtype === 'Nft' || data.elements[0].dtype === 'STREET'
            ? 'main.street.toast.description-1'
            : 'main.street.toast.description-1-building'
          ) | translate: data.count
        }}
      </div>
    </div>

    <div class="content">
      <div class="street-level-thumb">
        <div [ngClass]="['level-icon-container', levels[data.elements[0].level].icon]">
          <app-icon class="icon" [icon]="levels[data.elements[0].level]?.icon"></app-icon>
          <span class="level-icon-title">
            {{ levels[data.elements[0].level].title }}
          </span>
        </div>
        <div class="street-info">
          <div class="street-title">
            {{ data.elements[0].streetName }} {{ data.elements[0].number }}
          </div>
          <div class="street-place">
            {{ data.elements[0].streetSecondaryName }}
          </div>
        </div>
      </div>

      <div class="street-price">{{ data.elements[0].lastPrice }} DWRLD</div>
    </div>
  </ng-container>
  <ng-container *ngIf="data.count > 2">
    <div class="title weight-semibold display-flex align-center">
      <span
        class="color-primary-light display-flex align-center owner"
        [appPublicProfile]="data.elements[0].ownerName">
        <app-icon [icon]="iconProfile" class="font-md"></app-icon>
        <div class="owner margin-left-tn font-sm">{{ data.elements[0].ownerName | nickname }}</div>
      </span>
      <div class="description font-sm">
        {{ 'main.street.toast.description-2' | translate: data.count }}
        {{ data.elements | streetPriceSum }} DWRLD
      </div>
    </div>
  </ng-container>
</ng-container>
