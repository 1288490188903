import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-text-input',
  template: `
    <textarea
      class="inp"
      [ngClass]="{ 'dc-scroll-light' : paintScroll }"
      placeholder="{{ placeholder }}"
      [attr.tabindex]="tabindex"
      [attr.autocomplete]="autocomplete"
      [attr.name]="name"
      [attr.inputmode]="inputmode"
      #textarea></textarea>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true,
    },
  ],
  styleUrls: ['./styles/solid.scss', './styles/text-solid.scss', './styles/solid-validation.scss'],
})
export class TextInputComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @ViewChild('textarea', { static: true })
  textareaRef!: ElementRef<HTMLTextAreaElement>;

  @Input()
  placeholder = '';

  @Input()
  paintScroll = false;

  @Input()
  tabindex = 0;

  @Input() maxLength = 2000;

  @Input()
  autocomplete = '';

  @Input()
  name: string | null = null;

  @Input()
  inputmode: string | null = null;

  get focused(): boolean {
    return this.active;
  }

  protected active = false;
  protected onControlTouched = (): null => null;
  protected onControlChanged = (_: unknown): null => null;
  private destroy$ = new Subject();

  constructor(
    public elementRef: ElementRef,
    public chRef: ChangeDetectorRef,
    protected renderer: Renderer2
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    fromEvent(this.textareaRef.nativeElement, 'focus')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => (this.active = true));
    fromEvent<InputEvent>(this.textareaRef.nativeElement, 'input')
      .pipe(takeUntil(this.destroy$))
      .subscribe(e => this.onInput(e));
    fromEvent(this.textareaRef.nativeElement, 'blur')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.onBlur());
  }

  protected onInput(_: InputEvent): void {
    let value = this.textareaRef.nativeElement.value;

    if (value) {
      value = value.slice(0, this.maxLength);
      this.textareaRef.nativeElement.value = value;
      (_.target as HTMLTextAreaElement).setSelectionRange(value.length, value.length);
    }

    this.onControlChanged(value);
  }

  protected onBlur(): void {
    this.active = false;
    this.onControlTouched();
  }

  focus(): void {
    this.textareaRef.nativeElement.focus();
  }

  clear(): void {
    this.textareaRef.nativeElement.value = '';
    this.onControlChanged('');
  }

  writeValue(value: string): void {
    this.textareaRef.nativeElement.value = value;
  }

  registerOnChange(fn: (val: unknown) => null): void {
    this.onControlChanged = fn;
  }

  registerOnTouched(fn: () => null): void {
    this.onControlTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.renderer.setAttribute(this.elementRef.nativeElement, 'disabled', '');
      this.renderer.setAttribute(this.textareaRef.nativeElement, 'disabled', '');
    } else {
      this.renderer.removeAttribute(this.elementRef.nativeElement, 'disabled');
      this.renderer.removeAttribute(this.textareaRef.nativeElement, 'disabled');
    }
  }
}
