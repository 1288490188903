import { Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NftFilterPriceForm, NftPriceFormGroup } from 'dashboard/forms/nft-price-filter.form';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-nft-price-filters',
  templateUrl: './nft-price-filters.component.html',
  styleUrls: ['./nft-price-filters.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NftPriceFiltersComponent),
      multi: true,
    },
  ],
})
export class NftPriceFiltersComponent implements OnInit, OnDestroy, ControlValueAccessor {
  form = new NftPriceFormGroup();

  protected onControlTouched = (): null => null;
  protected onControlChanged = (_: unknown): null => null;
  protected destroy$ = new Subject();

  constructor() {}

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(value => {
        this.onControlChanged(value);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  protected getFormValue(): NftFilterPriceForm {
    return this.form.value;
  }

  protected setFormValue(v?: NftFilterPriceForm): void {
    this.form.setValue(v || { fromPrice: 0, toPrice: 0 }, { emitEvent: false });
  }

  writeValue(v?: NftFilterPriceForm): void {
    if (!v) {
      return;
    }
    this.setFormValue(v);
  }

  registerOnChange(fn: (val: unknown) => null): void {
    this.onControlChanged = fn;
  }

  registerOnTouched(fn: () => null): void {
    this.onControlTouched = fn;
  }
}
