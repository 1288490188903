import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageTO } from 'api/models';
import { BasePageComponent } from 'app/_main/modules/components/base-page/base-page.component';
import { WishlistBuildingsService } from 'app/marketplace/services/wishlist-buildings.service';
import { SortHeaderItem } from 'core/components/list-components/sorting.model';
import { GenericFormControl } from 'core/utils/form-generics';
import { Building } from 'dashboard/models/building.model';
import { Street } from 'dashboard/models/street.model';
import { PageControllerService } from 'dashboard/services/page-controller.service';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-wishlist-buildings',
  templateUrl: './wishlist-buildings.component.html',
  styleUrls: [
    './wishlist-buildings.component.scss',
    '../../../../../dashboard/components/_styles/dashboard-layout.scss',
  ],
  providers: [
    {
      provide: PageControllerService,
      useExisting: WishlistBuildingsService,
    },
  ],
})
export class WishlistBuildingsComponent extends BasePageComponent implements OnInit, OnDestroy {
  checkAllFormControl = new GenericFormControl<boolean>();
  pagination: PageTO = {};

  readonly skeletonItems = Array<Building>(10).fill(new Building());

  headers: SortHeaderItem[] = [
    {
      label: $t('dashboard.wishlist.column.asset'),
      key: 'name',
      itemClass: 'watchList-asset',
    },
    {
      label: $t('dashboard.wishlist.column.city'),
      key: 'city',
      itemClass: 'watchList-city',
    },
    {
      label: $t('dashboard.wishlist.column.level'),
      key: 'zone',
      itemClass: 'watchList-level',
    },
    {
      label: $t('dashboard.wishlist.column.price'),
      key: 'price',
      itemClass: 'watchList-price',
    },
    { label: '', key: '', itemClass: 'watchList-action' },
    { label: '', key: '', itemClass: 'watchList-buttons' },
  ];
  selectedItems: Map<number, Building> = new Map();

  inProgress = false;

  constructor(private wishlistService: WishlistBuildingsService) {
    super();
    this.setTopOffset(120);
  }

  get favorites(): Building[] {
    return this.wishlistService.items;
  }

  ngOnInit(): void {
    this.wishlistService
      .initQueryParamsListener()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(requestData => {
        this.pagination = requestData.pagination || {};
        this.pagination.pageNumber = (requestData.pagination?.pageNumber || 0) + 1;
      });

    this.wishlistService
      .loadWithFilters()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.clearSelection();

        this.pagination = data.pageInfo || {};
        this.pagination.pageNumber = (data.pageInfo?.pageNumber || 0) + 1;
        this.inProgress = false;
      });

    this.inProgress = true;
  }

  ngOnDestroy(): void {
    // sync favs on component destroy because we dont remove favorites from list
    // immediately but on next time user open page he will not see removed favs
    this.wishlistService.syncIfRemoved();
    super.ngOnDestroy();
  }

  private clearSelection(): void {
    this.selectedItems.clear();
    this.checkAllFormControl.setValue(false, { emitEvent: false });
  }

  identifyFavorite(_: number, item: Building): number {
    return item.id;
  }

  setCheckedItems(item: [boolean, Street | Building]): void {
    const isChecked = item[0];
    const building = item[1] as Building;

    if (isChecked) {
      this.selectedItems.set(building.id, building);
      if (this.selectedItems.size === this.favorites.length) {
        this.checkAllFormControl.setValue(true, { emitEvent: false });
      }
    } else {
      this.selectedItems.delete(building.id);
      if (this.selectedItems.size !== this.favorites.length) {
        this.checkAllFormControl.setValue(false, { emitEvent: false });
      }
    }
  }

  bulkRemove(): void {
    this.wishlistService
      .removeBulkFavorites(Array.from(this.selectedItems.values()))
      .pipe(takeUntil(this.destroy$))
      .subscribe(item => {
        item?.forEach(item => {
          if (this.selectedItems.has(item)) {
            this.selectedItems.delete(item);
          }
        });
        this.checkAllFormControl.setValue(this.selectedItems.size === this.favorites.length);

        const page = Number(this.pagination?.pageNumber) - 1;
        const pageNumber = page <= 0 ? 0 : page;
        const nextPage =
          this.favorites.length < 1 ? (pageNumber === 0 ? 0 : pageNumber - 1) : pageNumber;
        this.wishlistService.setPaginationParams({ ...this.pagination, pageNumber: nextPage });
        this.wishlistService.refreshPage();
      });
  }

  removeSingle(event: Street | Building): void {
    const data = event as Building;

    this.wishlistService
      .remove(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        if (this.selectedItems.has(data.id)) {
          this.selectedItems.delete(data.id);

          this.checkAllFormControl.setValue(this.selectedItems.size === this.favorites.length);

          const page = Number(this.pagination?.pageNumber) - 1;
          const pageNumber = page <= 0 ? 0 : page;
          const nextPage =
            this.favorites.length < 1 ? (pageNumber === 0 ? 0 : pageNumber - 1) : pageNumber;
          this.wishlistService.setPaginationParams({ ...this.pagination, pageNumber: nextPage });
        }
      });
  }

  applyPage(page: number): void {
    this.selectedItems.clear();
    this.checkAllFormControl.setValue(false);
    this.wishlistService.setPaginationParams({
      ...this.pagination,
      pageNumber: page <= 0 ? 0 : page - 1,
    });
    this.scrollToTop();
  }

  checkBulk(value: boolean): void {
    if (value) {
      this.favorites.forEach(item => {
        if (!this.selectedItems.has(item.id)) {
          this.selectedItems.set(item.id, item);
        }
      });
    } else {
      this.selectedItems.clear();
    }
  }
}
