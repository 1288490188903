/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ShoppingBasketTOResponse } from '../models/shopping-basket-toresponse';
import { ShoppingBasketForm } from '../models/shopping-basket-form';
import { BasketPurchaseResultTOListResponse } from '../models/basket-purchase-result-tolist-response';
import { ShoppingBasketPurchaseForm } from '../models/shopping-basket-purchase-form';

/**
 * Shopping Basket Controller
 */
@Injectable({
  providedIn: 'root',
})
class ShoppingBasketControllerService extends __BaseService {
  static readonly listUsingGET18Path = '/api/v2/shopping-cart';
  static readonly createUsingPOST3Path = '/api/v2/shopping-cart';
  static readonly removeItemsUsingDELETEPath = '/api/v2/shopping-cart';
  static readonly listWithExtendInfoUsingGETPath = '/api/v2/shopping-cart/extend';
  static readonly purchaseUsingPOSTPath = '/api/v2/shopping-cart/purchase';
  static readonly removeItemUsingDELETEPath = '/api/v2/shopping-cart/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * list
   * @return OK
   */
  listUsingGET18Response(): __Observable<__StrictHttpResponse<Array<ShoppingBasketTOResponse>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/shopping-cart`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ShoppingBasketTOResponse>>;
      })
    );
  }
  /**
   * list
   * @return OK
   */
  listUsingGET18(): __Observable<Array<ShoppingBasketTOResponse>> {
    return this.listUsingGET18Response().pipe(
      __map(_r => _r.body as Array<ShoppingBasketTOResponse>)
    );
  }

  /**
   * create
   * @param basket basket
   * @return OK
   */
  createUsingPOST3Response(basket: ShoppingBasketForm): __Observable<__StrictHttpResponse<ShoppingBasketTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = basket;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v2/shopping-cart`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ShoppingBasketTOResponse>;
      })
    );
  }
  /**
   * create
   * @param basket basket
   * @return OK
   */
  createUsingPOST3(basket: ShoppingBasketForm): __Observable<ShoppingBasketTOResponse> {
    return this.createUsingPOST3Response(basket).pipe(
      __map(_r => _r.body as ShoppingBasketTOResponse)
    );
  }

  /**
   * removeItems
   * @param ids ids
   */
  removeItemsUsingDELETEResponse(ids: Array<number>): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v2/shopping-cart`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * removeItems
   * @param ids ids
   */
  removeItemsUsingDELETE(ids: Array<number>): __Observable<null> {
    return this.removeItemsUsingDELETEResponse(ids).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * listWithExtendInfo
   * @return OK
   */
  listWithExtendInfoUsingGETResponse(): __Observable<__StrictHttpResponse<Array<ShoppingBasketTOResponse>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/shopping-cart/extend`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ShoppingBasketTOResponse>>;
      })
    );
  }
  /**
   * listWithExtendInfo
   * @return OK
   */
  listWithExtendInfoUsingGET(): __Observable<Array<ShoppingBasketTOResponse>> {
    return this.listWithExtendInfoUsingGETResponse().pipe(
      __map(_r => _r.body as Array<ShoppingBasketTOResponse>)
    );
  }

  /**
   * purchase
   * @param form form
   * @return OK
   */
  purchaseUsingPOSTResponse(form: ShoppingBasketPurchaseForm): __Observable<__StrictHttpResponse<BasketPurchaseResultTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v2/shopping-cart/purchase`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BasketPurchaseResultTOListResponse>;
      })
    );
  }
  /**
   * purchase
   * @param form form
   * @return OK
   */
  purchaseUsingPOST(form: ShoppingBasketPurchaseForm): __Observable<BasketPurchaseResultTOListResponse> {
    return this.purchaseUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as BasketPurchaseResultTOListResponse)
    );
  }

  /**
   * removeItem
   * @param id id
   */
  removeItemUsingDELETEResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v2/shopping-cart/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * removeItem
   * @param id id
   */
  removeItemUsingDELETE(id: number): __Observable<null> {
    return this.removeItemUsingDELETEResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ShoppingBasketControllerService {
}

export { ShoppingBasketControllerService }
