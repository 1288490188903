import { animate, group, query, style, transition, trigger } from '@angular/animations';

export const modalTransitions = trigger('routerTransition', [
  transition('* <=> *', [
    group([
      query(
        '.slideLeft:enter',
        [
          style({
            opacity: 0,
            position: 'fixed',
            left: '0',
            transform: 'translate(calc(50vw - 50% + 200px))',
          }),
          animate('.2s', style({ transform: 'translate(calc(50vw - 50%))', opacity: 1 })),
        ],
        { optional: true }
      ),
      query(
        '.slideLeft:leave',
        [
          style({ transform: 'translateX(0)', opacity: 1, margin: '0 auto' }),
          animate('.2s', style({ transform: 'translateX(-200px)', opacity: 0 })),
        ],
        { optional: true }
      ),

      query(
        '.slideRight:enter',
        [
          style({
            opacity: 0,
            position: 'fixed',
            left: '0',
            transform: 'translate(calc(50vw - 50% - 200px))',
          }),
          animate('.2s', style({ transform: 'translate(calc(50vw - 50%))', opacity: 1 })),
        ],
        { optional: true }
      ),
      query(
        '.slideRight:leave',
        [
          style({ transform: 'translateX(0)', opacity: 1, margin: '0 auto' }),
          animate('.2s', style({ transform: 'translateX(+200px)', opacity: 0 })),
        ],
        { optional: true }
      ),

      query(
        '.fadeIn:enter',
        [
          style({
            position: 'fixed',
            opacity: 0,
            left: '0',
            transform: 'translate(calc(50vw - 50%), 100px)',
          }),
          animate(
            '.22s',
            style({ opacity: 1, transform: 'translate(calc(50vw - 50%), 0) scale(1)' })
          ),
        ],
        { optional: true }
      ),
      query('.fadeIn:leave', [animate('.22s', style({ opacity: 0 }))], {
        optional: true,
      }),
    ]),
  ]),
]);
