import { LeaderboardStreetTO, MarketInfoTOResponse, UserInfoTOResponse } from 'api/models';
import { appNav } from 'app/app.navigation';
import { AppIconName } from 'core/icons/icon.type';
import { StreetLevelType, zoneToLevel } from 'dashboard/models/street.data';
import { environment } from 'environments/environment';

export type KycStatus = UserInfoTOResponse['kysStatus'];

export class User {
  id!: string;
  accountId!: number;
  decentBonus!: number;
  firstName!: string;
  lastName!: string;
  username!: string;
  phone?: string;
  address?: string;
  country?: string;
  email?: string;
  referralCode?: string;
  referralFeePercent!: number;
  note?: string;
  avatarUrl?: string;
  facebookUrl?: string;
  instagramUrl?: string;
  twitterUrl?: string;
  telegramUrl?: string;

  isEmailConfirmed?: boolean;
  isGoogleAuthEnabled?: boolean;
  isPhoneAuthEnabled?: boolean;
  newPasswordRequired?: boolean;
  marketList!: MarketInfoTOResponse[];
  kysStatus!: KycStatus;
  accountList!: Array<number>;
  logoHash?: string;
  logoPath?: string;
  showMysteryBoxPopUp!: boolean;
  showNftBuyPopUp!: boolean;

  constructor(init: PickData<User> = dataToUser()) {
    Object.assign(this, init);
  }

  get isVerified(): boolean {
    return this.kysStatus === 'VERIFIED_CORPORATE' || this.kysStatus === 'VERIFIED_PERSONAL';
  }

  get logoUrl(): string {
    return this.logoPath ? `${environment.mainServiceUrl}${this.logoPath}&t=${this.logoHash}` : '';
  }

  get publicUrl(): string {
    return `${window.location.origin}${appNav.profileNav.publicRoot(this.username)}`;
  }
}

export type UserStatusType = Exclude<LeaderboardStreetTO['status'], undefined>;

export interface UserPlanDescription {
  title: string;
  icon: AppIconName;
  level: StreetLevelType;
}

const STATUS_LABELS = {
  ELITE: 'Magnate',
  PREMIUM: 'Pro',
  STANDARD: 'Advanced',
  DEFAULT_PLAN: 'Novice',
};

export const userStatusToIconName = (zone: number): AppIconName => zoneToLevel(zone) as AppIconName;

export const USER_PLANS: Record<string, UserPlanDescription> = {
  ELITE: { title: STATUS_LABELS['ELITE'], icon: userStatusToIconName(4), level: 'plan4' },
  PREMIUM: { title: STATUS_LABELS['PREMIUM'], icon: userStatusToIconName(3), level: 'plan3' },
  STANDARD: { title: STATUS_LABELS['STANDARD'], icon: userStatusToIconName(2), level: 'plan2' },
  DEFAULT_PLAN: {
    title: STATUS_LABELS['DEFAULT_PLAN'],
    icon: userStatusToIconName(1),
    level: 'plan1',
  },
};

export const dataToUser = (data: Partial<UserInfoTOResponse> = {}): PickData<User> => ({
  id: data.uuid || '',
  accountId: data.accountList ? data.accountList[0] : 0,
  decentBonus: data.levelPoints || 0,
  firstName: data.firstName || '',
  lastName: data.lastName || '',
  phone: data.phone,
  address: data.address,
  country: data.country,
  avatarUrl: data.avatarUrl || '',
  username: data.username || '',
  marketList: data.marketList || [],
  referralFeePercent: data.referralFeePercent || 0,
  email: data.email,
  logoPath: data.logo,
  newPasswordRequired: data.newPasswordRequired,
  isEmailConfirmed: data.isEmailConfirmed,
  isGoogleAuthEnabled: data.isGoogleAuthEnabled,
  isPhoneAuthEnabled: data.isPhoneAuthEnabled,
  kysStatus: data.kysStatus || 'NOT_VERIFIED',
  accountList: data.accountList || [],
  referralCode: data.referralCode,
  note: data.note,
  facebookUrl: data.facebookUrl,
  twitterUrl: data.twitterUrl,
  instagramUrl: data.instagramUrl,
  telegramUrl: data.telegramUrl,
  showMysteryBoxPopUp: data.showMysteryBoxPopUp ?? true,
  showNftBuyPopUp: data.showNftBuyPopUp ?? true,
});
