import { Environment } from './environment.type';

export const environment: Environment = {
  production: '#{isProd}#' as unknown as boolean,
  uat: '#{isUAT}#' as unknown as boolean,
  ssr: false,
  mapboxToken: '#{mapboxToken}#',
  reCaptchaKey: '#{capcha}#',
  mainServiceUrl: '#{mainServiceUrl}#',
  wsServiceUrl: '#{wsServiceUrl}#',
  tileServiceUrl: '#{tileServiceUrl}#',
  globalpassUrl: '#{globalpassUrl}#',
  transactionExplorerUrl: '#{transactionExplorerUrl}#',
  googleMapKey: '#{googleMapKey}#',
  googleAuthClientId: '#{googleAuthClientId}#',
  facebookAuthClientId: '#{facebookAuthClientId}#',
  enableGoogleAuth: '#{enableGoogleAuth}#' as unknown as boolean,
  enableFacebookAuth: '#{enableFacebookAuth}#' as unknown as boolean,
  gtagId: '#{gtagId}#',
  EOSServiceUrl: '#{EOSServiceUrl}#',
  streamingSettings: '#{streamingSettings}#'
};
