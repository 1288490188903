import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BasketPurchaseResultTOResponse } from 'api/models/basket-purchase-result-toresponse';
import { FixedCardService } from 'app/_main/services/fixed-card.service';
import { lazyModalsNav } from 'app/app.navigation';
import { Collection } from 'app/collections/models/collection.model';
import { Wallet } from 'cashier/models/wallet.model';
import { WalletsService } from 'cashier/services/wallets.service';
import { iconBag } from 'core/icons/lib/icon-bag';
import { iconCircleLine } from 'core/icons/lib/icon-circle-line';
import { iconCloseRounded } from 'core/icons/lib/icon-close-rounded';
import { iconDiamond } from 'core/icons/lib/icon-diamond';
import { ModalRouter } from 'core/modules/modal';
import { CurrencyService } from 'core/services/currency.service';
import { GenericFormControl } from 'core/utils/form-generics';
import { SuccessfullyPurchasedModal } from 'dashboard/dashboard.navigation';
import { Building } from 'dashboard/models/building.model';
import { CartItem } from 'dashboard/models/cart-item.model';
import { Street } from 'dashboard/models/street.model';
import { BuildingsFactory } from 'dashboard/services/buildings.factory';
import { CartService } from 'dashboard/services/cart.service';
import { StreetsFactory } from 'dashboard/services/streets.factory';
import {of, Subject, timer} from 'rxjs';
import {debounce, debounceTime, takeUntil, tap} from 'rxjs/operators';
import {GTMService} from "core/services/gtm.service";
import {FbService} from "core/services/fb.service";
import {UserService} from "profile/services/user.service";

@Component({
  selector: 'app-checkout-page',
  templateUrl: './checkout-page.component.html',
  styleUrls: ['./checkout-page.component.scss'],
})
export class CheckoutPageComponent implements OnDestroy, OnInit {
  readonly closeIcon = iconCloseRounded;
  readonly circleIcon = iconCircleLine;
  readonly deleteIcon = iconBag;
  readonly iconDiamond = iconDiamond;

  get total(): number {
    return this.cartService.totalPrice;
  }

  get bonuses(): number {
    return this.cartService.totalBonuses;
  }

  get isInvalid(): boolean {
    return (
      this.walletControl.value.available <
      this.currencyService.toDwrldRate(this.total, this.walletControl.value.currency.code)
    );
  }

  get items(): CartItem[] {
    return this.cartService.items;
  }

  inProgress?: boolean;
  submitted?: boolean;
  shouldClearCheckout?: boolean;
  walletControl: GenericFormControl<Wallet>;
  form: FormGroup;
  purchaseStatusMap: Record<number, BasketPurchaseResultTOResponse['status'] | 'PENDING'> = {};

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public walletsService: WalletsService,
    public fixedCardService: FixedCardService,
    private streetsFactory: StreetsFactory,
    private buildingsFactory: BuildingsFactory,
    private cartService: CartService,
    private GTMService: GTMService,
    private userService: UserService,
    private FbService: FbService,
    private currencyService: CurrencyService,
    private modal: ModalRouter
  ) {
    this.walletControl = new GenericFormControl<Wallet>(this.walletsService.dwrldWallet);
    this.form = new FormGroup({ wallet: this.walletControl });
  }

  ngOnInit() {
    timer(1000).pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.GTMService.pushTag(
          {
            'event': 'view_cart',
            'user_id': this.userService.user.id,
            'ecommerce': {
              items: [...this.convertCardItemToGTMItem()]
            }
          });
      });
  }

  ngOnDestroy(): void {
    if (this.shouldClearCheckout) {
      this.cartService.clear();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  removeCollection(collection: Collection, street?: Street): void {
    this.cartService
      .removeFromCollection(collection, street ? [street] : [])
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
      });
  }

  remove(entity: Street | Building): void {
    this.cartService.remove(entity).pipe(takeUntil(this.destroy$)).subscribe(() => {
    });
  }

  removeGroupedBuildings(data: { street: Street; items: (Building | Street)[] }) {
    this.cartService
      .removeFromStreet(data.street, data.items as Building[])
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
      });
  }

  noDataRedirect(): void {
    this.fixedCardService.hideCard();
  }

  purchaseAction(): void {
    if (this.isInvalid) {
      this.submitted = true;
      this.walletControl.setErrors({ noBalance: $t('shared.validation.insufficient_balance') });
      return;
    }
    this.inProgress = true;
    this.items.forEach(item => {
      this.purchaseStatusMap[item.object.cartId] = 'PENDING';
      item.nestedItems.forEach(nested => {
        this.purchaseStatusMap[nested.cartId] = 'PENDING';
      });
    });

    this.cartService
      .purchase(this.walletControl.value.currency.code)
      .pipe(
        tap(res => {
          res.elements?.forEach(item => {
            this.purchaseStatusMap[item.objectId || 0] = item.status || 'UNRECOGNIZED';
          });
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(
        res => {
          this.inProgress = false;
          this.shouldClearCheckout = true;
          const successItems = res.elements?.filter(e => e.status === 'SUCCESS');
          const haveFailed = res.elements?.some(e => e.status !== 'SUCCESS');
          if (successItems?.length) {
            this.modal.navigate(
              lazyModalsNav.dashboard.successfullyPurchased(),
              () => {
                // TODO:
                // this.cartService.removeAllSuccessItems();
                if (haveFailed) {
                  this.fixedCardService.isForcedActive = true;
                }
              },
              {
                data: {
                  items: [
                    ...successItems.map(i => this.streetsFactory.get(i.objectId)).filter(i => i.id),
                    ...successItems
                      .map(i => this.buildingsFactory.get(i.objectId))
                      .filter(i => i.id),
                  ],
                } as SuccessfullyPurchasedModal,
              }
            );

            const marketIds = (res.elements || []).map(x => {
              return `${this.userService.user.accountId}-${x.market?.nft?.id || 0}`;
            });
            this.sendMetricTags(marketIds, this.walletControl.value.currency.code);
          }
        },
        () => {
          this.inProgress = false;
        }
      );
  }

  private sendMetricTags(marketIds?: string[], purchaseCurrency?: string): void {
    const config = {
      'event': 'in_game_purchase',
      'user_id': this.userService.user.id,
      'email': this.userService.user.email,
      'ecommerce': {
        'purchase-currency': purchaseCurrency,
        'in_game_transaction_id': marketIds,
        'token_name': 'DWRLD', // token name
        'token_amount': this.total, // amount of tokens
        'value':  this.currencyService.toBaseRate(this.total, 'DWRLD'), // total tokens value in fiat
        'currency': 'USD', // currency
        items: [ ...this.convertCardItemToGTMItem()]
      }
    };
    this.FbService.pushTag(config);
    this.GTMService.pushTag(config);
  }
  private convertCardItemToGTMItem() {
    const items = this.items.map((x) => {
      if (x.street) {
        return {
          'item_name': x.street?.name, // street name
          'item_id': x.street?.id, // street ID
          'item_country': x.street?.country, // street country
          'item_city': x.street?.city, // city
          'item_level': x.street?.level, // street level
          'item_token': x.street?.marketPrice, // street value in DWRLD tokens
          'quantity': '1', // amount of items
          'value': this.currencyService.toBaseRate(x.street.marketPrice, 'DWRLD'), // tokens value in fiat
          'currency': 'USD', // currency,
          'price': this.currencyService.toBaseRate(x.street.marketPrice, 'DWRLD')
        };
      } else if (x.building) {
        return {
          'item_name': x.building?.name, // street name
          'item_id': x.building?.id, // street ID
          'item_country': x.building?.country, // street country
          'item_city': x.building?.city, // city
          'item_level': x.building?.level, // street level
          'item_token': x.building?.marketPrice, // street value in DWRLD tokens
          'quantity': '1', // amount of items
          'value':  this.currencyService.toBaseRate(x.building.marketPrice, 'DWRLD'), // tokens value in fiat
          'currency': 'USD', // currency,
          'price': this.currencyService.toBaseRate(x.building.marketPrice, 'DWRLD')
        };
      } else {
        return {
          'item_name': x.collection?.meta.title, // street name
          'item_id': x.collection?.id, // street ID
          'item_country': x.collection?.country, // street country
          'item_level': x.collection?.meta.level, // street level
          'item_token': x.collection?.collectionPrice, // street value in DWRLD tokens
          'quantity':
            (x.collection?.meta.basicStreetsCount || 0) +
            (x.collection?.meta.standardStreetsCount || 0) +
            (x.collection?.meta.eliteStreetsCount || 0) +
            (x.collection?.meta.premiumStreetsCount || 0), // amount of items
          'value': this.currencyService.toBaseRate((x.collection?.collectionPrice || 0), 'DWRLD'), // tokens value in fiat
          'currency': 'USD', // currency,
          'price': this.currencyService.toBaseRate((x.collection?.collectionPrice || 0), 'DWRLD')
        };
      }
    });
    return items;
  }
}
