/* eslint-disable prefer-arrow-functions/prefer-arrow-functions */

import { MoneyTransactionTOResponse } from 'api/models';
import { Collection } from 'app/collections/models/collection.model';
import { createNavigation } from 'core/utils/router.utils';
import { Map } from 'mapbox-gl';

import { Building } from './models/building.model';
import { Street } from './models/street.model';
import {EntityType, NFTEntity} from "dashboard/models/street.data";

export interface EntityOrderSuccessModal {
  entities: Street[] | Building[];
  nftType: 'street' | 'building';
  price: number;
  preserveQuery: boolean;
  type: 'market' | 'limit';
  side: 'buy' | 'sell';
}

export interface SharePreviewModal {
  entityData: NFTEntity;
  entityType: EntityType | 'custom';
  publicUrl?: string;
  publicImg?: string;
}

export interface StreetPreviewModal {
  preserveQuery: boolean;
  entity: Street | Building | Collection;
  additionalEntity: unknown;
  isBuilding?: boolean;
  mapInstance?: Map;
  isMarket?: boolean;
  closeOnOrderCancel: boolean;
  transactionType?: MoneyTransactionTOResponse['type'];
  addressFrom?: string;
  addressTo?: string;
}

export interface CertificatePreviewModal {
  showIcon?: string;
  headerText?: string;
  showMessage?: string;
  nftId?: string;
}

export interface SuccessfullyPurchasedModal {
  items: (Street | Building)[];
}

export const dashboardRoot = 'dashboard';

export interface DashboardAssetsParams {
  type: 'streets' | 'collections';
}

// dashboard paths

export function dashboard(): string {
  return '';
}

export function assetsStreets(): string {
  return 'streets';
}

export function assetsCollections(): string {
  return 'collections';
}

export function assetsBuildings(): string {
  return 'buildings';
}

export function assetsRoot(): string {
  return 'assets';
}

export function assets(type: 'streets' | 'collections' | 'buildings'): string {
  return `assets/${type}`;
}

export function history(): string {
  return 'history';
}

export function affiliate(): string {
  return 'affiliate';
}

export function desktopOnly(): string {
  return 'desktop-only';
}

export const paths = {
  assets,
  assetsRoot,
  assetsCollections,
  assetsStreets,
  assetsBuildings,
  history,
  affiliate,
  dashboard,
  desktopOnly,
};

// modals

export function successfullyPurchasedModal(): string {
  return 'successfully-purchased';
}

export function errorPurchasedModal(): string {
  return 'error-purchased';
}

export function transferNFTModal(): string {
  return 'transfer-nft';
}

export function sharePreviewModal(): string {
  return 'share-preview';
}

export function withdrawNFTModal(): string {
  return 'withdraw-nft';
}

export function certificatePreviewModal(): string {
  return 'certificate';
}

export function historyModal(): string {
  return 'history';
}

export function openMysteryBoxModal(): string {
  return 'open-mystery-box';
}

export function historyItemDetailsModal(): string {
  return 'history-item-details';
}

export const modalPaths = {
  certificatePreview: certificatePreviewModal,
  history: historyModal,
  transferNFT: transferNFTModal,
  withdrawNFT: withdrawNFTModal,
  successfullyPurchased: successfullyPurchasedModal,
  errorPurchased: errorPurchasedModal,
  openMysteryBox: openMysteryBoxModal,
  sharePreview: sharePreviewModal,
  historyItemDetails: historyItemDetailsModal,
};

export const dashboardNav = createNavigation(paths, dashboardRoot);
export const dashboardModalsNav = createNavigation(modalPaths, dashboardRoot);
