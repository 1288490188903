<app-data-expand
  (toggled)="onExpand($event)"
  [class.expanded]="expanded"
  [expanded]="expanded"
  [hideIcon]="true"
  [showMoreString]="false"
  class="width-100 extra expander">
  <ng-template>
    <div class="street-list">
      <app-checkout-item
        *ngFor="let street of streets"
        [item]="street"
        (removeEmitter)="removeStreetFromCart(street)"
        [status]="status"
        class="table-item"></app-checkout-item>
    </div>
  </ng-template>
  <div class="collection-asset-item">
    <ng-container *mediaIf="'medium'; else mobileView">
      <app-street-thumb
        [imageUrl]="collection.meta.smallImageUrl || collection.meta.placeholderUrl"
        [level]="collection.meta.level || 'plan1'"
        [mediaClass]="['medium', 'small-thumb xsmall']"
        [title]="collection.secondaryTitle"
        class="rounded column asset"
        style="grid-area: asset">
        <ng-container>
          {{ collection.isGlobal ? 'Global' : collection.country }}
        </ng-container>
      </app-street-thumb>

      <div class="column price" style="grid-area: price">
        DWRLD {{ price | precision: 'DWRLD' }}
      </div>

      <div
        [ngClass]="[expanded ? 'color-warn' : 'color-primary']"
        class="column type"
        style="grid-area: type">
        {{ 'Collection' }}
        <app-icon
          [icon]="expandIcon"
          [ngClass]="[expanded ? 'open' : 'close']"
          class="expand-icon margin-left"></app-icon>
      </div>

      <div class="column action margin-left-auto" style="grid-area: action">
        <ng-container *ngIf="!status; else statusTmp">
          <button
            (click)="removeFromCart(); $event.stopImmediatePropagation()"
            [appRoundButton]="deleteIcon"
            appAnchor
            class="clear-error"></button>
        </ng-container>
      </div>
    </ng-container>
  </div>
</app-data-expand>

<ng-template #mobileView>
  <app-collection-icon
    [imageUrl]="'url(' + (collection.meta.smallImageUrl || collection.meta.placeholderUrl) + ')'"
    [ngClass]="[collection.meta.level]"
    class="medium default margin-left margin-right-md"></app-collection-icon>

  <div class="display-flex-column flex-1 justify-space-between margin-right-tn">
    <p class="collection-name">{{ collection.meta.title }}</p>

    <div class="display-flex justify-start align-center">
      <app-level-icon
        [level]="collection.meta.level || 'plan1'"
        [price]="-1"
        class="tn clear margin-right"></app-level-icon>
    </div>
  </div>

  <div class="mobile-actions">
    <ng-container *ngIf="!status; else statusTmp">
      <button
        (click)="removeFromCart(); $event.stopImmediatePropagation()"
        [appRoundButton]="deleteIcon"
        appAnchor
        class="clear-error"></button>
    </ng-container>
  </div>
</ng-template>

<ng-template #statusTmp>
  <div class="status">
    <ng-container [ngSwitch]="status">
      <ng-container *ngSwitchCase="'FAILED'">
        <app-icon [icon]="iconError" class="color-error icon"></app-icon>
      </ng-container>
      <ng-container *ngSwitchCase="'UNDEFINED_PURCHASE_STATUS'">
        <app-icon [icon]="iconError" class="color-error icon"></app-icon>
      </ng-container>
      <ng-container *ngSwitchCase="'SUCCESS'">
        <app-icon [icon]="iconSuccess" class="color-success icon"></app-icon>
      </ng-container>
      <ng-container *ngSwitchCase="'PENDING'">
        <app-icon [icon]="iconPending" class="spin color-warn icon"></app-icon>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
