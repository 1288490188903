/* eslint-disable prefer-arrow-functions/prefer-arrow-functions */
import { createNavigation } from 'core/utils/router.utils';

export interface ResetPasswordSendEmailModel {
  email: string;
}

export interface AuthConfirmParams {
  token: string;
}

export interface SignUpParams {
  email: string;
  offid?: string;
  affid?: string;
  c?: string;
  referral: string;
  promoCode: string;
}

export interface ConfirmationResult {
  result: string;
}

export interface ConfirmGoogleParams {
  token: string;
  sid: string;
}

export const authRoot = 'auth';

export function authorizationConfirming(token: string): string {
  return `confirm-email/${token}`;
}

export function authorizationBlackFriday(code: string): string {
  return `black-friday/${code}`;
}

export function resetPassword(): string {
  return 'reset-password/:token/:email';
}

export function confirmGoogleSuccess(sid: string, token: string): string {
  return `enable-two-factor/${sid}/${token}`;
}

export function disableGoogleSuccess(): string {
  return 'disable-two-factor/:sid/:token';
}

export const paths = {
  authorizationConfirming,
  authorizationBlackFriday,
  confirmGoogleSuccess,
  resetPassword,
  disableGoogleSuccess,
};

export function resetPasswordModal(): string {
  return 'reset-password';
}

export function signUpSuccessModal(email: string): string {
  return `sign-up-email/${email}`;
}

export function authorizationResultModal(result: string): string {
  return `authorization-result/${result}`;
}

export function resetPasswordSendEmailModal(): string {
  return 'reset-password-send-email';
}

export function resetPasswordCreatingModal(token: string, email: string): string {
  return `reset-password-creating/${token}/${email}`;
}

export function confirmGoogleSuccessModal(): string {
  return `enable-two-factor`;
}

export function disableGoogleSuccessModal(): string {
  return 'disable-two-factor';
}

export function signUpModal(): string {
  return 'sign-up';
}

export function signInModal(): string {
  return 'sign-in';
}

export const modalPaths = {
  resetPassword: resetPasswordModal,
  resetPasswordSendEmail: resetPasswordSendEmailModal,
  signUpSuccess: signUpSuccessModal,
  authorizationResult: authorizationResultModal,
  enableTwoFactorSuccess: confirmGoogleSuccessModal,
  disableTwoFactorSuccess: disableGoogleSuccessModal,
  signUp: signUpModal,
  signIn: signInModal,
  resetPasswordCreating: resetPasswordCreatingModal,
};

export const authNav = createNavigation(paths, authRoot);
export const authModalsNav = createNavigation(modalPaths, `${authRoot}-modals`);
