import { Injectable } from '@angular/core';
import {
  PasswordChangeForm,
  PasswordChangeTOResponse,
  PublicProfileResponse,
  Resource,
  TwoFactorAuthUpdateTOResponse,
  UserInfoTOResponse,
  UserProfileTOResponse,
} from 'api/models';
import { UserControllerService, UserLogoControllerService } from 'api/services';
import { StateClearService, StateFullService } from 'app/_main/services/state-clear.service';
import { AuthService } from 'auth/services/auth.service';
import { JwtService } from 'auth/services/jwt.service';
import { SocketService } from 'core/modules/socket/socket.service';
import { EditProfileForm } from 'profile/models/edit-profile.form';
import { asyncScheduler, forkJoin, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { User } from '../models/user.model';
import { UserFactory } from './user.factory';

@Injectable({ providedIn: 'root' })
export class UserService implements StateFullService {
  static get instance() {
    return this._user;
  }

  get user(): User {
    return UserService._user;
  }

  preventedNavigation = '';

  private redirectTo2FAKey = 'redirectTo2FA';

  private static _user: User;

  constructor(
    auth: AuthService,
    socket: SocketService,
    jwtService: JwtService,
    public factory: UserFactory,
    private stateClearService: StateClearService,
    private userLogoApi: UserLogoControllerService,
    private api: UserControllerService
  ) {
    this.stateClearService.register(this);
    UserService._user = factory.get();
    const socketLogin = () => {
      socket.send({
        messageType: 'LOGIN',
        loginMessage: {
          accountId: this.user.accountId,
          jwt: jwtService.getToken(),
        },
      });
    };

    socket.connect({
      keepConnection: 10000,
    });
    auth.loggedIn.subscribe(userData => {
      UserService._user = factory.get(userData || {});
      asyncScheduler.schedule(() => {
        this.preventedNavigation = '';
      }, 2000);
      socketLogin();
    });
    socket.reconnected.subscribe(() => {
      socketLogin();
    });
  }

  refreshUserSettings(): Observable<UserInfoTOResponse> {
    return this.api.userSettingsUsingGET().pipe(
      tap(userData => {
        UserService._user = this.factory.get(userData || {});
      })
    );
  }

  setFlagForRedirectTo2FA(value: boolean): void {
    const key = `${this.redirectTo2FAKey}-${this.user.id}`;
    localStorage.setItem(key, value.toString());
  }

  getFlagForRedirectTo2FA(): boolean {
    const key = `${this.redirectTo2FAKey}-${this.user.id}`;
    const value = localStorage.getItem(key);
    return value === 'true';
  }

  getPublicProfile(uuid: string): Observable<PublicProfileResponse> {
    return this.api.userPublicSettingsUsingGET(uuid);
  }

  changePhone(phone: string): Observable<UserProfileTOResponse> {
    return this.api
      .updateUsingPOST({
        phone,
        address: this.user.address,
        country: this.user.country,
        lastName: this.user.lastName,
        username: this.user.username,
        firstName: this.user.firstName,
      })
      .pipe(
        tap(result => {
          this.user.phone = result.phone;
        })
      );
  }

  editProfileData(form: EditProfileForm): Observable<[UserProfileTOResponse, UserInfoTOResponse]> {
    form.twitterUrl = this.removeUrlProtocol(form.twitterUrl);
    form.instagramUrl = this.removeUrlProtocol(form.instagramUrl);
    form.facebookUrl = this.removeUrlProtocol(form.facebookUrl);
    form.telegramUrl = this.removeUrlProtocol(form.telegramUrl);
    return forkJoin([this.api.updateUsingPOST(form), this.updateUsername(form.username)]).pipe(
      tap(() => {
        this.user.firstName = form.firstName;
        this.user.lastName = form.lastName;
        this.user.username = form.username;
        this.user.twitterUrl = form.twitterUrl;
        this.user.instagramUrl = form.instagramUrl;
        this.user.facebookUrl = form.facebookUrl;
        this.user.telegramUrl = form.telegramUrl;
      })
    );
  }

  updateUsername(newUsername: string): Observable<UserInfoTOResponse> {
    return this.api.updateUsernameUsingPOST(newUsername).pipe(
      tap(() => {
        this.user.username = newUsername;
      })
    );
  }

  changePassword(form: PasswordChangeForm): Observable<PasswordChangeTOResponse> {
    return this.api.changePasswordUsingPOST(form);
  }

  updateLogo(data: Blob): Observable<Resource> {
    return this.userLogoApi.createLogoUsingPOST(data).pipe(
      tap(() => {
        this.user.logoHash = Date.now().toString();
      })
    );
  }

  removeLogo(): Observable<Resource> {
    return this.userLogoApi.deleteLogoUsingDELETE().pipe(
      tap(() => {
        this.user.logoHash = Date.now().toString();
      })
    );
  }

  requestTwoFAVerificationEmail(
    form: UserControllerService.SendVerificationEmailTwoFactorAuthUsingPOSTParams
  ): Observable<null> {
    return this.api.sendVerificationEmailTwoFactorAuthUsingPOST(form);
  }

  enableTwoFA(
    form: UserControllerService.ConfirmEnableTwoFactorAuthUsingPOSTParams
  ): Observable<TwoFactorAuthUpdateTOResponse> {
    return this.api.confirmEnableTwoFactorAuthUsingPOST(form).pipe(
      tap(res => {
        this.user.isGoogleAuthEnabled = res.isEnabled;
      })
    );
  }

  disableTwoFA(
    form: UserControllerService.ConfirmRemoveTwoFactorAuthUsingPOSTParams
  ): Observable<TwoFactorAuthUpdateTOResponse> {
    return this.api.confirmRemoveTwoFactorAuthUsingPOST(form).pipe(
      tap(res => {
        this.user.isGoogleAuthEnabled = res.isEnabled;
      })
    );
  }

  changeUserName(username: string): Observable<UserInfoTOResponse> {
    return this.api.updateUsernameUsingPOST(username).pipe(
      tap(res => {
        this.user.username = res.username || '';
        this.user.referralCode = res.referralCode || '';
      })
    );
  }

  clearState(): void {
    UserService._user = new User();
    this.factory.emptyStore();
  }

  private removeUrlProtocol(url?: string): string | undefined {
    if (!url) return url;
    return url.replace(/(^\w+:|^)\/\//, '');
  }
}
