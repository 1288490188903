import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { AppIconsLibrary } from 'core/icons/icons-library.service';
import { iconPlan1 } from 'core/icons/lib/icon-plan1';
import { iconPlan2 } from 'core/icons/lib/icon-plan2';
import { iconPlan3 } from 'core/icons/lib/icon-plan3';
import { iconPlan4 } from 'core/icons/lib/icon-plan4';
import { STREET_LEVELS, StreetLevelType } from 'dashboard/models/street.data';
import { Subject } from 'rxjs';
import {AppIcon, AppIconName} from "core/icons/icon.type";
import {iconPlan5} from "core/icons/lib/icon-plan5";

export interface StreetImage {
  src?: string;
  title?: string;
  gradient?: string;
  mainClass?: string;
  coverClass?: string;
}

@Component({
  selector: 'app-street-image',
  templateUrl: './street-image.component.html',
  styleUrls: ['./street-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StreetImageComponent implements OnChanges, OnDestroy {
  @Input() imageSrc?: string;
  @Input() level?: StreetLevelType;
  @Input() isAvailable = true;
  @Input() isWithdraw = false;
  @Input() showLogo = true;
  @Input() showSmallStatus = false;
  @Input() showLevelString = true;
  @Input() showCoverSymbol = true;
  @Input() showSecondaryElements = true;

  streetImage: StreetImage = {};
  levels = STREET_LEVELS;

  private destroy$ = new Subject();

  constructor(iconLib: AppIconsLibrary, private chRef: ChangeDetectorRef) {
    iconLib.addIcons([iconPlan1, iconPlan2, iconPlan3, iconPlan4, iconPlan5]);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges<StreetImageComponent>): void {
    if (changes.level && this.level) {
      this.assignImageData(this.level);
    }
  }

  private assignImageData(level: StreetLevelType): void {
    switch (level) {
      case 'plan1':
        this.streetImage = {
          src: '/assets/svg/logo-plan1.svg',
          title: 'basic asset '.repeat(25),
          mainClass: 'cover-plan-plan1',
          coverClass: 'cover-plan-wrapper-plan1',
          gradient: 'radial-gradient(circle, rgba(245,190,47,1) 0%, rgba(222,166,22,1) 88%)',
        };
        break;
      case 'plan2':
        this.streetImage = {
          src: '/assets/svg/logo-plan2.svg',
          title: 'standard asset '.repeat(25),
          gradient: 'radial-gradient(circle, rgba(246,97,50,1) 0%, rgba(215,77,33,1) 88%)',
          mainClass: 'cover-plan-plan2',
          coverClass: 'cover-plan-wrapper-plan2',
        };
        break;
      case 'plan3':
        this.streetImage = {
          src: '/assets/svg/logo-plan3.svg',
          title: 'premium asset '.repeat(25),
          gradient: 'radial-gradient(circle, rgba(2,148,157,1) 0%, rgba(9,189,200,1) 88%)',
          mainClass: 'cover-plan-plan3',
          coverClass: 'cover-plan-wrapper-plan3',
        };
        break;
      case 'plan4':
        this.streetImage = {
          src: '/assets/svg/logo-plan4.svg',
          title: 'elite asset '.repeat(25),
          gradient: 'radial-gradient(circle, rgba(100,54,245,1) 0%, rgba(112,70,244,1) 88%)',
          mainClass: 'cover-plan-plan4',
          coverClass: 'cover-plan-wrapper-plan4',
        };
        break;
      case 'plan5':
        this.streetImage = {
          src: '/assets/svg/logo-plan5.svg',
          title: 'unique asset '.repeat(25),
          gradient: 'linear-gradient(270.94deg, #ee6a99 7.48%, #eeabbe 89.16%)',
          mainClass: 'cover-plan-plan5',
          coverClass: 'cover-plan-wrapper-plan5',
        };
        break;
    }
  }
}
