import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyService } from 'core/services/currency.service';

import { PrecisionPipe } from './precision.pipe';

@Pipe({
  name: 'baseRate',
})
export class BaseRatePipe implements PipeTransform {
  private readonly precisionPipe = new PrecisionPipe(this.currencyService);

  constructor(private currencyService: CurrencyService) {}

  transform(value: number, code: string): string {
    // because for now user is unable to select base currency, it will be USD now
    return this.precisionPipe.transform(this.currencyService.toBaseRate(value, code), 'USD');
  }
}
