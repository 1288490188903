import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { lazyModalsNav } from 'app/app.navigation';
import { iconBagCross } from 'core/icons/lib/icon-bag-cross';
import { iconCart } from 'core/icons/lib/icon-cart';
import { iconEllipsisH } from 'core/icons/lib/icon-ellipsis-h';
import { iconLock } from 'core/icons/lib/icon-lock';
import { iconOval } from 'core/icons/lib/icon-oval';
import { iconStar } from 'core/icons/lib/icon-start';
import { ModalRouter } from 'core/modules/modal';
import { Building } from 'dashboard/models/building.model';
import { Street } from 'dashboard/models/street.model';
import { CartService } from 'dashboard/services/cart.service';
import { UserService } from 'profile/services/user.service';
import { Subject } from 'rxjs';
import {GTMService} from "core/services/gtm.service";
import {CurrencyService} from "core/services/currency.service";

@Component({
  selector: 'app-street-building-item',
  templateUrl: './street-building-item.component.html',
  styleUrls: ['./street-building-item.component.scss'],
})
export class StreetBuildingItemComponent implements OnDestroy {
  private destroy$ = new Subject();

  readonly starIcon = iconStar;
  readonly iconLocked = iconLock;
  readonly spinIcon = iconOval;
  readonly iconStar = iconStar;
  readonly iconEllipsis = iconEllipsisH;
  readonly iconCart = iconCart;
  readonly iconBagCross = iconBagCross;

  @Input()
  building: Building = new Building();

  @Input()
  street: Street = new Street();

  @Input() isWithdrawalStreet = false;

  @Output() onBuy = new EventEmitter<Building>();
  @Output() toggleFavorite = new EventEmitter<Building>();
  @Output() toggleCart = new EventEmitter<Building>();

  constructor(
    private modal: ModalRouter,
    private GTMService: GTMService,
    private currencyService: CurrencyService,
    private userService: UserService,
    private cartService: CartService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openTransferNFT(): void {
    // this.emitTransfer.emit(this.building);
  }

  toggleBasket(event: MouseEvent): void {
    event.stopPropagation();
    if (!this.userService.user.id) {
      this.modal.navigate(lazyModalsNav.auth.signIn());
      return;
    }
    this.toggleCart.emit(this.building);
  }

  buyNow(event: MouseEvent): void {
    event.stopPropagation();
    if (!this.userService.user.id) {
      this.modal.navigate(lazyModalsNav.auth.signIn());
      return;
    }
    this.onBuy.emit(this.building);
  }

  buy(event: MouseEvent): void {}

  sell(event: MouseEvent): void {}

  onToggleFavorite(event: MouseEvent): void {
    event.stopPropagation();
    if (!this.userService.user.id) {
      this.modal.navigate(lazyModalsNav.auth.signIn());
      return;
    }

    if (!this.building.isFavorite) {
      this.GTMService.pushTag({
        'event': 'add_to_wishlist',
        'user_id': this.userService.user.id,
        'ecommerce': {
          items: [{
            'item_name': this.building.name, // street name
            'item_id': this.building.id, // street ID
            'item_country': this.building.country, // street country
            'item_city': this.building.city, // city
            'item_level': this.building.level, // street level
            'item_token': this.building.marketPrice, // street value in DWRLD tokens
            'quantity': '1', // amount of items
            'price': this.currencyService.toDwrldRate(this.building.marketPrice, 'USD') // item worth amount in fiat
          }]
        }
      });
    }

    this.toggleFavorite.next(this.building);
  }
}
