import { Component, ElementRef, HostBinding, Input } from '@angular/core';
import { tooltipAnimation } from 'core/components/tooltip/tooltip.animation';

import { TooltipStyleType } from './tooltip.directive';

@Component({
  selector: 'app-tooltip',
  template: `
    <div class="content" @tooltip>{{ text }}</div>
  `,
  styleUrls: ['tooltip.component.scss'],
  animations: [tooltipAnimation],
})
export class TooltipComponent {
  @Input() text = '';

  @HostBinding('class')
  @Input()
  styleType: TooltipStyleType = undefined;

  constructor(public elementRef: ElementRef<HTMLElement>) {}
}
