import { Component, HostBinding, Input, OnChanges } from '@angular/core';
import { appNav } from 'app/app.navigation';
import { Collection } from 'app/collections/models/collection.model';
import { AppIconsLibrary } from 'core/icons/icons-library.service';
import { iconChevronLeft } from 'core/icons/lib/icon-chevron-left';
import { navigateByUrl } from 'core/utils/router.utils';
import { Building } from 'dashboard/models/building.model';
import { StreetLevelType } from 'dashboard/models/street.data';
import { Street } from 'dashboard/models/street.model';
import { environment } from 'environments/environment';
import { StreetsMapNavState } from 'streets/streets.navigation';

@Component({
  selector: 'app-street-images',
  templateUrl: './street-images.component.html',
  styleUrls: ['./street-images.component.scss'],
})
export class StreetImagesComponent implements OnChanges {
  @Input()
  asset: Building | Street | Collection = new Street();
  @Input()
  onlyLinesMap?: boolean;
  @Input()
  navigateToMap?: boolean;
  @Input()
  @HostBinding('class.large-map')
  largeMap?: boolean;
  @Input()
  mapStyle: 'DARK' | 'LIGHT' = 'LIGHT';

  markerImage?: string;
  pathImage?: string;

  assetType: 'STREET' | 'BUILDING' = 'STREET';

  constructor(iconLib: AppIconsLibrary) {
    iconLib.addIcons([iconChevronLeft]);
  }

  get isCollection(): boolean {
    return this.asset instanceof Collection;
  }

  ngOnChanges(changes: SimpleChanges<StreetImagesComponent>): void {
    if (changes.asset || changes.mapStyle || changes.largeMap) {
      let width = 410;
      let height = width;
      if (this.largeMap) {
        width = 1000;
        height = 650;
      }

      if (this.asset instanceof Street) {
        this.assetType = 'STREET';
        this.setImagesSrc(this.asset.id, width, height, true);
      } else {
        this.assetType = 'BUILDING';
        this.setImagesSrc(this.asset.id, width, height, false);
      }
    }
  }

  openStreetOnMap(): void {
    if (!this.navigateToMap) {
      return;
    }
    const state =
      this.asset instanceof Street ? { streetId: this.asset.id } : { buildingId: this.asset.id };
    navigateByUrl(appNav.streetsNav.map(), {
      state: state as unknown as StreetsMapNavState,
    });
  }

  setImagesSrc(id: number, width: number, height: number, isStreet = true) {
    const type = isStreet ? 'STREET' : 'BUILDING';
    const zoom = this.largeMap ? 17.5 : 16.8;
    const params = isStreet
      ? `width=${width}&height=${height}&padding=80&style=${this.mapStyle}`
      : `width=${width}&height=${height}&padding=80&style=${this.mapStyle}&pitch=40&zoom=${zoom}`;
    this.pathImage = `${environment.mainServiceUrl}/api/v1/gis/${id}/image/${type}/with-lines?${params}`;
    this.markerImage = `${environment.mainServiceUrl}/api/v1/gis/${id}/image/${type}/with-markers?zoom=15&width=${width}&height=${height}&style=${this.mapStyle}`;
  }

  get imageUrl(): string {
    return (
      (this.isCollection
        ? (this.asset as Collection)?.meta.imageUrl
        : (this.asset as Building | Street)?.imageUrl) || ''
    );
  }

  get isWithdrawn(): boolean {
    return this.isCollection
      ? !!(this.asset as Collection)?.isWithdrawn
      : !!(this.asset as Building | Street)?.isWithdrawn;
  }

  get levelNFT(): StreetLevelType {
    return this.isCollection
      ? (this.asset as Collection)?.meta.level
      : (this.asset as Building | Street)?.level;
  }
}
