import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CityTOResponse } from 'api/models';
import { CollectionMetaInformationControllerService } from 'api/services';
import { GenericFormControl } from 'core/utils/form-generics';
import { GisService } from 'dashboard/services/gis.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { CitySearchCollectionStrategy } from './city-search-collection.strategy';
import { CitySearchGisStrategy } from './city-search-gis.strategy';
import { CitySearchStrategy } from './city-search-strategy.interface';

@Component({
  selector: 'app-city-search',
  templateUrl: './city-search.component.html',
})
export class CitySearchComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  countryCode?: string;
  @Input()
  regionCode?: string;
  @Input()
  cityId?: number;
  @Input()
  set strategyType(value: 'gis' | 'collection') {
    if (value === 'gis') {
      this.strategy = new CitySearchGisStrategy(this.gisService);
    } else {
      this.strategy = new CitySearchCollectionStrategy(this.collectionMetaApi);
    }
  }

  cities: CityTOResponse[] = [];
  allCities: CityTOResponse[] = [];
  control = new GenericFormControl<string>();
  value: CityTOResponse = {};
  @Output()
  selected = new EventEmitter<CityTOResponse | undefined>();
  private destroy$ = new Subject();
  private pageSize = 300;

  private strategy!: CitySearchStrategy;

  constructor(
    private gisService: GisService,
    private collectionMetaApi: CollectionMetaInformationControllerService
  ) {
    this.strategy = new CitySearchGisStrategy(this.gisService);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges<CitySearchComponent>): void {
    if (changes.countryCode || changes.regionCode) {
      this.strategy
        .getCities(this.countryCode ? '' : 'h', this.countryCode, this.pageSize, this.regionCode)
        .pipe(takeUntil(this.destroy$))
        .subscribe(res => {
          this.allCities = this.cities = res.elements || [];
        });
    }

    if (changes.cityId) {
      if (changes.cityId?.currentValue) {
        this.gisService
          .findCityById(changes.cityId.currentValue)
          .pipe(takeUntil(this.destroy$))
          .subscribe(city => {
            this.value = city;
            this.selected.emit(city);
          });
      } else if (!changes.cityId?.currentValue) {
        this.value = {};
      }
    }
  }

  ngOnInit(): void {
    this.strategy
      .getCities(this.countryCode ? '' : 'h', this.countryCode, this.pageSize)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.allCities = this.cities = res.elements || [];
      });
    this.control.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(v => {
        if (v) {
          this.strategy
            .getCities(v, this.countryCode, this.pageSize)
            .pipe(takeUntil(this.destroy$))
            .subscribe(res => {
              this.cities = res.elements || [];
            });
        } else if (v !== undefined) {
          this.cities = this.allCities;
          this.value = {};
          this.selected.emit(undefined);
        }
      });
  }

  select(c: CityTOResponse): void {
    if (c.id !== this.value.id) {
      this.value = c;
      this.cities = this.allCities;
      this.control.setValue(undefined);
      this.selected.emit(this.value);
    }
  }
}
