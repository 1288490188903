import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EosService } from 'app/collections/services/eos.service';
import { JwtService } from 'auth/services/jwt.service';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private jwtService: JwtService, private eosService: EosService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let authReq = req;
    const tokenValue = this.jwtService.getToken();
    if (tokenValue) {
      const tokenResult = `${tokenValue}`;
      if (
        !req.url.includes(this.eosService.requestURL) &&
        !req.url.includes(environment.EOSServiceUrl || '')
      ) {
        authReq = req.clone({
          headers: req.headers.set('x-token', tokenResult),
        });
      }
    }
    return next.handle(authReq);
  }
}
