import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { asyncScheduler, fromEvent, ReplaySubject, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Directive({
  selector: '[appLandingFadeIn]',
})
export class SectionFadeInDirective implements AfterViewInit, OnDestroy, OnInit {
  @Input()
  delay?: number = 200;

  @Input()
  animation: 'from-bottom' | 'to-bottom' = 'from-bottom';

  private animatedSubj = new ReplaySubject(1);
  @Output()
  animated = this.animatedSubj.asObservable();

  private scrollSub = new Subscription();
  private offsetTop = 0;
  private active: boolean;

  constructor(
    router: Router,
    private renderer: Renderer2,
    private elementRef: ElementRef<HTMLElement>
  ) {
    this.active = !router.navigated;
  }

  ngOnDestroy(): void {
    this.scrollSub.unsubscribe();
  }

  ngOnInit(): void {
    if (environment.ssr || !this.active) {
      this.renderer.addClass(this.elementRef.nativeElement, 'animated');
      return;
    }
    this.renderer.setStyle(this.elementRef.nativeElement, 'opacity', '0');
    this.renderer.setStyle(this.elementRef.nativeElement, 'transform', 'translateY(160px)');
  }

  ngAfterViewInit(): void {
    if (environment.ssr || !this.active) {
      this.animatedSubj.next();
      return;
    }
    asyncScheduler.schedule(() => {
      this.animate();
    }, 100);
  }

  private animate(): void {
    let el = this.elementRef.nativeElement;
    while (el.parentElement) {
      this.offsetTop += el.offsetTop;
      el = el.parentElement;
    }
    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'transition',
      'opacity .4s, transform .5s ease-in'
    );
    if (this.animation === 'from-bottom') {
      this.renderer.setStyle(
        this.elementRef.nativeElement,
        'transition-timing-function',
        'cubic-bezier(.09,0,.47,1.46)'
      );
      this.renderer.setStyle(this.elementRef.nativeElement, 'transform', 'translateY(160px)');
    } else {
      this.renderer.setStyle(
        this.elementRef.nativeElement,
        'transition-timing-function',
        'ease-out'
      );
      this.renderer.setStyle(this.elementRef.nativeElement, 'transform', 'translateY(-160px)');
    }
    this.renderer.setStyle(this.elementRef.nativeElement, 'opacity', '0');
    this.scrollSub = fromEvent(window, 'scroll')
      .pipe(throttleTime(100, undefined, { trailing: true }))
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(() => {
        this.checkElementWithOffset(window.innerHeight / 3);
      });
    if (typeof this.delay === 'number') {
      asyncScheduler.schedule(() => {
        this.checkElementWithOffset(0);
      }, this.delay);
    }
  }

  private checkElementWithOffset(offset: number): void {
    if (window.scrollY + window.outerHeight - offset > this.offsetTop) {
      this.scrollSub.unsubscribe();
      this.renderer.setStyle(this.elementRef.nativeElement, 'opacity', '1');
      this.renderer.setStyle(this.elementRef.nativeElement, 'transform', 'translateY(0)');
      asyncScheduler.schedule(() => {
        this.renderer.addClass(this.elementRef.nativeElement, 'animated');
      }, 450);
      this.animatedSubj.next();
    }
  }
}
