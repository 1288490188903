import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'httpUrl',
})
export class HttpUrlPipe implements PipeTransform {
  transform(value: string | undefined, prefix = 'https://'): string {
    if (!value) return '';
    return prefix + value;
  }
}
