/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UserExternalWalletTO } from '../models/user-external-wallet-to';
import { BooleanTO } from '../models/boolean-to';
import { ExternalWalletTOList } from '../models/external-wallet-tolist';
import { UserExternalWalletTOList } from '../models/user-external-wallet-tolist';

/**
 * External Wallets Controller
 */
@Injectable({
  providedIn: 'root',
})
class ExternalWalletsControllerService extends __BaseService {
  static readonly externalWalletUserListUsingPUTPath = '/api/v1/external-wallet';
  static readonly checkExistingUsingGETPath = '/api/v1/external-wallet/check-existing';
  static readonly externalWalletListUsingGETPath = '/api/v1/external-wallet/ex-wallet-list';
  static readonly externalWalletUserListUsingGETPath = '/api/v1/external-wallet/user-ex-wallet-list';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * externalWalletUserList
   * @param params The `ExternalWalletsControllerService.ExternalWalletUserListUsingPUTParams` containing the following parameters:
   *
   * - `twoFaCode`: twoFaCode
   *
   * - `network`: network
   *
   * - `currencyCode`: currencyCode
   *
   * - `address`: address
   *
   * @return OK
   */
  externalWalletUserListUsingPUTResponse(params: ExternalWalletsControllerService.ExternalWalletUserListUsingPUTParams): __Observable<__StrictHttpResponse<UserExternalWalletTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.twoFaCode != null) __params = __params.set('twoFaCode', params.twoFaCode.toString());
    if (params.network != null) __params = __params.set('network', params.network.toString());
    if (params.currencyCode != null) __params = __params.set('currencyCode', params.currencyCode.toString());
    if (params.address != null) __params = __params.set('address', params.address.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/external-wallet`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserExternalWalletTO>;
      })
    );
  }
  /**
   * externalWalletUserList
   * @param params The `ExternalWalletsControllerService.ExternalWalletUserListUsingPUTParams` containing the following parameters:
   *
   * - `twoFaCode`: twoFaCode
   *
   * - `network`: network
   *
   * - `currencyCode`: currencyCode
   *
   * - `address`: address
   *
   * @return OK
   */
  externalWalletUserListUsingPUT(params: ExternalWalletsControllerService.ExternalWalletUserListUsingPUTParams): __Observable<UserExternalWalletTO> {
    return this.externalWalletUserListUsingPUTResponse(params).pipe(
      __map(_r => _r.body as UserExternalWalletTO)
    );
  }

  /**
   * checkExisting
   * @param address address
   * @return OK
   */
  checkExistingUsingGETResponse(address: string): __Observable<__StrictHttpResponse<BooleanTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (address != null) __params = __params.set('address', address.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/external-wallet/check-existing`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BooleanTO>;
      })
    );
  }
  /**
   * checkExisting
   * @param address address
   * @return OK
   */
  checkExistingUsingGET(address: string): __Observable<BooleanTO> {
    return this.checkExistingUsingGETResponse(address).pipe(
      __map(_r => _r.body as BooleanTO)
    );
  }

  /**
   * externalWalletList
   * @return OK
   */
  externalWalletListUsingGETResponse(): __Observable<__StrictHttpResponse<ExternalWalletTOList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/external-wallet/ex-wallet-list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExternalWalletTOList>;
      })
    );
  }
  /**
   * externalWalletList
   * @return OK
   */
  externalWalletListUsingGET(): __Observable<ExternalWalletTOList> {
    return this.externalWalletListUsingGETResponse().pipe(
      __map(_r => _r.body as ExternalWalletTOList)
    );
  }

  /**
   * externalWalletUserList
   * @return OK
   */
  externalWalletUserListUsingGETResponse(): __Observable<__StrictHttpResponse<UserExternalWalletTOList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/external-wallet/user-ex-wallet-list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserExternalWalletTOList>;
      })
    );
  }
  /**
   * externalWalletUserList
   * @return OK
   */
  externalWalletUserListUsingGET(): __Observable<UserExternalWalletTOList> {
    return this.externalWalletUserListUsingGETResponse().pipe(
      __map(_r => _r.body as UserExternalWalletTOList)
    );
  }
}

module ExternalWalletsControllerService {

  /**
   * Parameters for externalWalletUserListUsingPUT
   */
  export interface ExternalWalletUserListUsingPUTParams {

    /**
     * twoFaCode
     */
    twoFaCode: string;

    /**
     * network
     */
    network: 'BCH' | 'BNB' | 'BTC' | 'EOS' | 'ETH' | 'LTC' | 'SOLANA' | 'TRON' | 'UNKNOWN_BLOCKCHAIN_NETWORK' | 'UNRECOGNIZED' | 'WAX' | 'XRP';

    /**
     * currencyCode
     */
    currencyCode: string;

    /**
     * address
     */
    address: string;
  }
}

export { ExternalWalletsControllerService }
