import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StreetTO } from 'api/models';
import { appNav } from 'app/app.navigation';
import { MarketplaceStreetsService } from 'app/marketplace/services/marketplace-streets.service';
import { WishlistStreetsService } from 'app/marketplace/services/wishlist-streets.service';
import { AuthService } from 'auth/services/auth.service';
import { SeoService } from 'core/seo/seo.service';
import { dataToStreet, Street } from 'dashboard/models/street.model';
import { UserService } from 'profile/services/user.service';
import { Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import {NotificationEventData} from "profile/services/notification.service";
import {SocketService} from "core/modules/socket/socket.service";

@Component({
  selector: 'app-marketplace-streets-page',
  templateUrl: './marketplace-streets-page.component.html',
  styleUrls: ['./marketplace-streets-page.component.scss'],
})
export class MarketplaceStreetsPageComponent implements OnInit, OnDestroy {
  readonly skeletonItems = Array<Street>(10).fill(
    new Street(
      dataToStreet({
        name: 'Skeleton Name',
        city: 'Skeleton',
        country: 'Skeleton',
      })
    )
  );
  streets: Street[] = [];

  private destroy$ = new Subject<void>();
  private onPageLoaded: () => void = () => {};

  constructor(
    private socket: SocketService,
    private marketplaceStreetsService: MarketplaceStreetsService,
    private userService: UserService,
    private wishlistService: WishlistStreetsService,
    private router: Router,
    private seo: SeoService,
    private route: ActivatedRoute,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    this.initWebsocket();
    this.auth.onAuthorized.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.marketplaceStreetsService.reloadData();
      this.streets = this.skeletonItems;
    });

    this.streets = this.marketplaceStreetsService.streets.length
      ? this.marketplaceStreetsService.streets
      : this.skeletonItems;

    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(query => {
      this.marketplaceStreetsService.saveQueryParams(query);
    });

    this.marketplaceStreetsService
      .initQueryParamsListener()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.streets = this.skeletonItems;
      });

    this.marketplaceStreetsService.isInitial = true;
    this.marketplaceStreetsService
      .getMarketplaceStreets()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        const tmpLen = this.streets.length - this.skeletonItems.length;
        this.streets = data.elements;
        if (tmpLen !== this.streets.length) {
          this.onPageLoaded();
        }
      });

    this.seo.set({
      title: $t('seo.title.marketplace'),
      description: $t('seo.description.marketplace'),
      image: 'https://decentworld.com/assets/marketplace-1200x630.png',
      canonicalPath: appNav.marketplaceNav.marketplaceStreets(),
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  nextPageAction(next: () => void): void {
    this.marketplaceStreetsService.nextPage();
    this.streets = [...this.streets, ...this.skeletonItems];
    this.onPageLoaded = next;
  }

  goToOverview(street: Street): void {
    this.router.navigateByUrl(
      appNav.marketplaceNav.streetPreview(street.id, street.country, street.name)
    );
  }

  toggleFavorite(street: Street): void {
    if (this.userService.user.id) {
      this.wishlistService.toggleFavorite(street);
    } else {
      this.auth.handleUnauthorizedAction();
    }
  }

  identifyBy(_number: number, item: StreetTO): number {
    return item.id || 1;
  }

  private initWebsocket(): void {
    this.socket.on<NotificationEventData>().pipe(takeUntil(this.destroy$)).subscribe(message => {
      if (
        message.eventType === 'ORDER_CLOSED' && message.data.closeReason === 'COMPLETED'
      ) {
        this.marketplaceStreetsService.reloadData();
      }
    });
  }
}
