import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NicknamePipe } from './nickname.pipe';

const exportedDeclarations = [NicknamePipe];

@NgModule({
  imports: [CommonModule],
  declarations: exportedDeclarations,
  exports: exportedDeclarations,
})
export class AppPipesModule {}
