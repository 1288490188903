import { Component } from '@angular/core';

import { menuTransition } from './dropdown.transitions';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[appOverlayHost]',
  template: `
    <ng-content></ng-content>
  `,
  styleUrls: ['./styles/overlay.scss'],
  animations: [menuTransition],
})
export class OverlayHostComponent {}
