import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
} from '@angular/core';
import { AppIcon } from 'core/icons/icon.type';
import { iconChevronDown } from 'core/icons/lib/icon-chevron-down';
import { asyncScheduler } from 'rxjs';

import { TooltipStyleType } from '../tooltip/tooltip.directive';
import { expandTransition } from './data-expand.transitions';

@Component({
  selector: 'app-data-expand',
  templateUrl: './data-expand.component.html',
  styleUrls: [
    './styles/data-expand-frame.scss',
    './styles/data-expand-clear.scss',
    './styles/data-expand-extra.scss',
  ],
  animations: [expandTransition],
})
export class DataExpandComponent implements OnInit {
  @ContentChild(TemplateRef, { static: true })
  tmplRef!: TemplateRef<unknown>;
  @Input()
  arrowIcon = iconChevronDown;

  @Input()
  expandedArrowIcon: AppIcon | null = null;

  @Input()
  expanded?: boolean;
  @Input()
  expandBreakpoint?: number;
  @Input()
  showMoreString = true;
  @Input()
  tooltipText = '';
  @Input()
  tooltipIcon?: AppIcon;
  @Input()
  tooltipStyleType: TooltipStyleType;

  @HostBinding('class.absolute-icon')
  @Input()
  absoluteIcon = false;

  @Input()
  hideIcon = false;

  @Output()
  toggled = new EventEmitter<boolean>();

  transition?: 'open';

  constructor(private renderer: Renderer2, private elementRef: ElementRef<HTMLElement>) {}

  ngOnInit(): void {
    if (this.expandBreakpoint && window.innerHeight > this.expandBreakpoint) {
      this.expanded = true;
      this.renderer.addClass(this.elementRef.nativeElement, 'active');
    }
    asyncScheduler.schedule(() => {
      this.renderer.addClass(this.elementRef.nativeElement, 'animated');
    }, 200);
  }

  toggleExpanded(): void {
    this.expanded = !this.expanded;
    this.transition = this.expanded ? 'open' : undefined;
    this.toggled.emit(this.expanded);
    if (this.expanded) {
      this.renderer.addClass(this.elementRef.nativeElement, 'active');
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, 'active');
    }
  }
}
