import {
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MediaQuery } from 'core/modules/platform/services/media-query.service';
import { environment } from 'environments/environment';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil, throttleTime } from 'rxjs/operators';

@Component({
  selector: 'app-scroller',
  template: `
    <div class="container" #containerRef>
      <div class="scroll" #scrollRef>
        <ng-content select=".scroll-cell"></ng-content>
        <div class="padding-fix" #paddingRef></div>
      </div>
    </div>
    <ng-content></ng-content>
  `,
  styleUrls: ['./styles/scroller.scss'],
  exportAs: 'scroller',
})
export class ScrollerComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @ViewChild('scrollRef', { static: true })
  scrollRef!: ElementRef<HTMLElement>;

  @ViewChild('containerRef', { static: true })
  containerRef!: ElementRef<HTMLElement>;

  @ViewChild('paddingRef', { static: true })
  paddingRef!: ElementRef<HTMLElement>;

  @Input()
  @HostBinding('class')
  type: 'flexible' | 'grid' | 'slider' = 'grid';

  @ContentChildren('hostHeight', { read: ElementRef })
  hostHeightElements!: QueryList<ElementRef>;

  @Input()
  initialPage?: number;

  @Input()
  trackBy: unknown;

  @Input()
  heightRef?: ScrollerComponent;

  @Input()
  autoplay? = false;

  @Input()
  autoplayIntervalDelay = 5000;

  @Input()
  scrollBy = 100;

  private pageChangedSub = new Subject<{ nextPage: number; prevPage: number }>();
  @Output()
  pageChanged = this.pageChangedSub.asObservable();
  private scrollStartSub = new Subject<number>();
  scrollStart = this.scrollStartSub.asObservable();
  private scrollEndSub = new Subject<number>();
  scrollEnd = this.scrollEndSub.asObservable();
  private totalChangedSub = new Subject<number>();
  totalChanged = this.totalChangedSub.asObservable();

  @Output()
  scrolled = new EventEmitter<number>();

  protected hostHeight = 0;
  protected visibleWidth = 0;
  protected currentPage = 0;
  protected totalPages = 0;
  private hiderPadding = 50;
  private isScrolling = false;
  private scrollHidePadding = 50;
  private initialPadding = 50;
  private expandWidth = 0;
  private scrollRect?: DOMRect;
  private itemStyle?: CSSStyleDeclaration;
  private scrollStyle?: CSSStyleDeclaration;
  private destroy$ = new Subject();
  private timerId = 0;
  private _hasScroll = false;
  get hasScroll(): boolean {
    return this._hasScroll;
  }

  constructor(
    public elementRef: ElementRef<HTMLElement>,
    protected renderer: Renderer2,
    private ngZone: NgZone,
    private media: MediaQuery
  ) {}

  get isLast(): boolean {
    return this.page === this.totalPages - 1;
  }

  get isFirst(): boolean {
    return this.page === 0;
  }

  get scrollLeft(): number {
    return this.scrollRef.nativeElement.scrollLeft;
  }

  get total(): number {
    return this.totalPages;
  }

  get page(): number {
    return this.currentPage;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.initAutoPlay();

    this.media.resized.pipe(takeUntil(this.destroy$)).subscribe(() => {
      requestAnimationFrame(() => {
        // clear cached calculations of style and ClientRect
        this.scrollRect = undefined;
        this.itemStyle = undefined;
        this.scrollStyle = undefined;
        this.updateHostHeight();
      });
    });
    this.media.mediaChanged.pipe(takeUntil(this.destroy$)).subscribe(() => {
      requestAnimationFrame(() => {
        this.updateHiderPadding();
        this.scrollRef.nativeElement.scrollTo(0, 0);
        this.updateSlides();
      });
    });
    let scrollEndTimeout: NodeJS.Timeout;
    fromEvent(this.scrollRef.nativeElement, 'scroll', { passive: true })
      .pipe(throttleTime(50, undefined, { trailing: true }), takeUntil(this.destroy$))
      .subscribe(() => {
        // detect scroll events
        if (!this.isScrolling) {
          this.isScrolling = true;
          // because scroll is moved outside of zone by project setting we emit next event with zone
          this.ngZone.run(() => {
            this.scrollStartSub.next();
          });
        }
        this.ngZone.run(() => {
          this.scrolled.next(this.scrollRef.nativeElement.scrollLeft);
        });
        clearTimeout(scrollEndTimeout);
        scrollEndTimeout = setTimeout(() => {
          this.isScrolling = false;
          this.ngZone.run(() => {
            this.scrollEndSub.next();
          });
        }, 100);
        // what page?
        const scrollLeft = this.scrollRef.nativeElement.scrollLeft;
        const scrollWidth = this.scrollRef.nativeElement.scrollWidth - this.expandWidth;
        if (Math.ceil(scrollWidth - scrollLeft) === Math.ceil(this.visibleWidth)) {
          const previousPage = this.currentPage;
          this.currentPage = this.totalPages - 1;
          this.ngZone.run(() => {
            this.pageChangedSub.next({ nextPage: this.currentPage, prevPage: previousPage });
          });
        } else {
          // calculate page
          const scroll = scrollLeft / (scrollWidth / this.totalPages) + 0.087;
          for (let page = 0; page < this.totalPages; page++) {
            if (scroll > page && scroll < page + 1 && this.currentPage !== page) {
              const previousPage = this.currentPage;
              this.currentPage = page;
              this.ngZone.run(() => {
                this.pageChangedSub.next({ nextPage: this.currentPage, prevPage: previousPage });
              });
            }
          }
        }
      });
  }

  ngOnChanges(changes: SimpleChanges<ScrollerComponent>): void {
    if (changes.trackBy) {
      requestAnimationFrame(() => {
        // clear cached calculations of style and ClientRect
        this.scrollRect = undefined;
        this.itemStyle = undefined;
        this.scrollStyle = undefined;
        this.updateHostHeight();
        this.updateSlides();
      });
    }

    if (changes.autoplay) {
      this.initAutoPlay();
    }
  }

  ngAfterViewInit(): void {
    this.updateHiderPadding();
    requestAnimationFrame(() => {
      this.updateHostHeight();
      this.updateSlides();
      if (this.initialPage) {
        this.toPage(this.initialPage, 'auto');
      }
    });
  }

  moveForward() {
    this.scrollRef.nativeElement.scrollBy({
      left: this.scrollBy,
      behavior: 'smooth',
    });
  }

  moveBack() {
    this.scrollRef.nativeElement.scrollBy({
      left: -this.scrollBy,
      behavior: 'smooth',
    });
  }

  prev(): void {
    let page = this.currentPage - 1;
    if (page < 0) {
      page = 0;
    }
    this.toPage(page);
    if (this.autoplay && this.timerId) {
      clearInterval(this.timerId);

      this.timerId = window.setInterval(() => {
        if (this.isLast) {
          this.toPage(0);
        } else {
          this.next();
        }
      }, this.autoplayIntervalDelay);
    }
  }

  next(): void {
    let page = this.currentPage + 1;
    if (page >= this.totalPages) {
      page = this.totalPages - 1;
    }
    this.toPage(page);

    if (this.autoplay && this.timerId) {
      window.clearInterval(this.timerId);

      this.timerId = window.setInterval(() => {
        if (this.isLast) {
          this.toPage(0);
        } else {
          this.next();
        }
      }, this.autoplayIntervalDelay);
    }
  }

  toPage(page: number, scroll: ScrollBehavior = 'smooth'): void {
    const x = this.visibleWidth * page;
    this.scrollRef.nativeElement.scrollTo({ left: x, behavior: scroll });
  }

  toItem(index: number): void {
    const children = this.scrollRef.nativeElement.children;
    const itemElement = children[index];
    const itemRect = itemElement.getBoundingClientRect();
    this.itemStyle = this.itemStyle || window.getComputedStyle(itemElement);
    this.scrollRect = this.scrollRect || this.scrollRef.nativeElement.getBoundingClientRect();
    const margin =
      parseInt(this.itemStyle.marginLeft, 10) + parseInt(this.itemStyle.marginRight, 10);
    const itemWidth = itemRect.width + margin;
    if (itemRect.right + margin + itemWidth - (this.scrollRect.right - this.expandWidth / 2) > 0) {
      const item = Math.min(children.length - 1, index + 1);
      const left = itemWidth * (item + 1) - this.visibleWidth;
      this.scrollRef.nativeElement.scrollTo({ left, behavior: 'smooth' });
    } else if (itemRect.left - itemWidth - this.scrollRect.left - this.expandWidth / 2 < 0) {
      const item = Math.max(0, index - 1);
      const left = itemWidth * item;
      this.scrollRef.nativeElement.scrollTo({ left, behavior: 'smooth' });
    }
  }

  protected updateHiderPadding(): void {
    this.scrollStyle = this.scrollStyle || window.getComputedStyle(this.scrollRef.nativeElement);
    this.initialPadding = parseInt(this.scrollStyle.backgroundPosition, 10);
    this.hiderPadding = this.scrollHidePadding + this.initialPadding;
    this.renderer.setStyle(
      this.scrollRef.nativeElement,
      'padding-bottom',
      `${this.hiderPadding}px`
    );
  }

  protected updateHostHeight(): void {
    if (this.hostHeightElements.length) {
      this.hostHeight =
        this.heightRef?.hostHeight ||
        this.elementRef.nativeElement.parentElement?.clientHeight ||
        0;
      this.renderer.setStyle(this.containerRef.nativeElement, 'height', `${this.hostHeight}px`);
      this.hostHeightElements.forEach(e => {
        this.renderer.setStyle(e.nativeElement, 'height', `${this.hostHeight}px`);
      });
    } else {
      this.hostHeight = this.scrollRef.nativeElement.clientHeight - this.hiderPadding;
      this.renderer.setStyle(this.containerRef.nativeElement, 'height', `${this.hostHeight}px`);
    }
    this._hasScroll =
      this.scrollRef.nativeElement.scrollWidth > this.scrollRef.nativeElement.clientWidth;
  }

  protected updateSlides(): void {
    this.scrollStyle = this.scrollStyle || window.getComputedStyle(this.scrollRef.nativeElement);
    this.expandWidth =
      parseInt(this.scrollStyle.paddingLeft, 10) + parseInt(this.scrollStyle.paddingRight, 10);
    // flexible scroller will scroll (next, prev) by half of its original width
    const pageScaleFactor = this.type === 'flexible' ? 0.5 : 1;
    this.visibleWidth =
      (this.scrollRef.nativeElement.clientWidth - this.expandWidth) * pageScaleFactor;
    const paddingFix = this.paddingRef.nativeElement.clientWidth;
    const scrollWidth = this.scrollRef.nativeElement.scrollWidth - this.expandWidth;
    let totalPages = (scrollWidth - paddingFix) / this.visibleWidth;
    totalPages = this.type === 'slider' ? Math.round(totalPages) : Math.ceil(totalPages);
    if (this.totalPages !== totalPages) {
      this.totalPages = totalPages;
      this.totalChangedSub.next(totalPages);
    }
    if (this.currentPage > 0) {
      const x = this.visibleWidth * this.currentPage;
      this.scrollRef.nativeElement.scrollTo({ left: x, behavior: 'smooth' });
    }
  }

  private initAutoPlay(): void {
    if (environment.ssr) {
      return;
    }

    if (this.autoplay) {
      clearInterval(this.timerId);

      this.timerId = window.setInterval(() => {
        if (this.isLast) {
          this.toPage(0);
        } else {
          this.next();
        }
      }, this.autoplayIntervalDelay);
    } else {
      clearInterval(this.timerId);
    }
  }
}
