import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NftFilterPlanForm, NftPlanFilterFormGroup } from 'dashboard/forms/nft-plan-filter.form';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-nft-plan-filter',
  templateUrl: 'nft-plan-filter.component.html',
  styleUrls: ['./nft-plan-filter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NftPlanFilterComponent),
      multi: true,
    },
  ],
})
export class NftPlanFilterComponent implements OnInit, OnDestroy, ControlValueAccessor {
  form = new NftPlanFilterFormGroup();
  @Input() isCollections = false;
  @Input() enable5zone = false;
  protected destroy$ = new Subject();

  protected onControlTouched = (): null => null;
protected onControlChanged = (_: unknown): null => null;
constructor() {}

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(item => {
      this.onControlChanged(item);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  writeValue(v?: NftFilterPlanForm): void {
    if (!v) {
      return;
    }
    this.setFormValue(v);
  }

  registerOnChange(fn: (val: unknown) => null): void {
    this.onControlChanged = fn;
  }

  registerOnTouched(fn: () => null): void {
    this.onControlTouched = fn;
  }





  protected getFormValue(): NftFilterPlanForm {
    return this.form.value;
  }

  protected setFormValue(v?: NftFilterPlanForm): void {
    this.form.setValue(
      v || { isBasic: false, isElite: false, isPremium: false, isStandard: false },
      { emitEvent: false }
    );
  }
}
