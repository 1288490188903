import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BuildingTOResponse, NftTO, PageTO } from 'api/models';
import { GisControllerService, NftControllerService } from 'api/services';
import { StateClearService, StateFullService } from 'app/_main/services/state-clear.service';
import { AuthService } from 'auth/services/auth.service';
import { delayAtLeast } from 'core/utils/rxjs/delay-at-least.operator';
import { NftFilterPlanForm } from 'dashboard/forms/nft-plan-filter.form';
import {
  convertToNftZones,
  convertToQueryZones,
  STREET_LEVELS,
} from 'dashboard/models/street.data';
import { Street } from 'dashboard/models/street.model';
import { PageControllerService, RequestData } from 'dashboard/services/page-controller.service';
import { StreetsFactory } from 'dashboard/services/streets.factory';
import { defer, Observable, of } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import { GeolocationService } from 'streets/services/geolocation.service';

import {
  marketplaceMarketTypes,
  MarketplaceStreetsFilters,
} from '../forms/marketplace-streets.form';
import {UserService} from 'profile/services/user.service';

export interface MarketplaceStreets {
  elements: Street[];
  pageInfo?: PageTO;
}

@Injectable({
  providedIn: 'root',
})
export class MarketplaceStreetsService
  extends PageControllerService<MarketplaceStreetsFilters, string>
  implements StateFullService
{
  isInitial = true;
  streets: Street[] = [];
  queryParams: Params = {};

  protected pageSize = 30;
  private lastPage?: number;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private gisController: GisControllerService,
    private streetsFactory: StreetsFactory,
    private nftApi: NftControllerService,
    private stateClearService: StateClearService,
    private geolocation: GeolocationService,
    protected activatedRoute: ActivatedRoute,
    protected router: Router
  ) {
    super(activatedRoute, router);
    this.stateClearService.register(this);
  }

  get isQueryParamsEmpty(): boolean {
    return Object.keys(this.queryParams).length === 0;
  }

  clearState(): void {
    this.queryParams = {};
    this.streets = [];
    this.lastPage = undefined;
    this.clearParams();
  }

  override getQueryParams(): Observable<RequestData<MarketplaceStreetsFilters, string>> {
    return this.route.queryParams.pipe(
      tap((queryParams: Params) => {
        if (!Object.keys(queryParams).length) {
          this.replaceQueryParams({
            ...this.requestData$.value,
            filter: {
              cityId: this.geolocation.data.cityId,
              countryCode: this.geolocation.data.countryCode,
            },
          });
        }
      }),
      filter(data => Boolean(Object.keys(data).length)),
      map(queryParams => this.convertToRequestData(queryParams))
    );
  }

  getMarketplaceStreets(): Observable<MarketplaceStreets> {
    return this.getRequestData().pipe(
      debounceTime(0),
      switchMap(data =>
        defer(() => {
          const params: GisControllerService.SliceStreetsUsingGETParams = {
            sort: data.sort,
            minPrice: data.filter.price?.fromPrice,
            maxPrice: data.filter.price?.toPrice,
            name: data.filter.name,
            nameStart: data.filter.nameStart,
            zones: this.convertZones(data.filter.zones),
            pageNumber: data.pagination.pageNumber,
            pageSize: this.pageSize,
            nftMarketType: data.filter.nftMarketType?.item,
            countryCode: !this.isQueryParamsEmpty
              ? data.filter.countryCode
              : this.geolocation.data.countryCode,
            cityId: !this.isQueryParamsEmpty ? data.filter.cityId : this.geolocation.data.cityId,
            ownerId: 0
          };

          if (data.filter.hasMatching) {
              params.ownerId = this.userService.user.accountId;
          }
          if (params?.sort?.startsWith('name,') && data.filter.hasMatching) {
            params.sort = params?.sort?.replace('name,', 'street,');
          }

          if (this.authService.isAuth && data.filter.hasMatching) {
            params.sort = params?.sort?.replace('price', 'buyingPrice');
          }

          if (this.authService.isAuth && data.filter.hasMatching) {
            params.ownerId = this.userService.user.accountId;
          } else  {
            params.ownerId = undefined;
          }
          return this.gisController.sliceStreetsUsingGET(params);
        }).pipe(
          delayAtLeast(1200),
          map(response => {
            if (this.isInitial) {
              this.streets = [
                ...(response.elements || []).map(street => {
                  if (!(street as NftTO).collectionStatusFromBlockchain) {
                    (street as NftTO).streetId = street.id;
                  }
                  (street as NftTO).sellPrice = (street as BuildingTOResponse).price;
                  return this.streetsFactory.get((street as NftTO).streetId, street);
                })
              ];
              this.isInitial = false;
            } else {
              if (this.lastPage !== response.pageInfo?.pageNumber || !this.streets.length) {
                this.streets.push(
                  ...(response.elements || []).map(street => {
                    if (!(street as NftTO).collectionStatusFromBlockchain) {
                      (street as NftTO).streetId = street.id;
                    }
                    (street as NftTO).sellPrice = (street as BuildingTOResponse).price;
                    return this.streetsFactory.get((street as NftTO).streetId, street, true);
                  })
                );
              }
            }

            this.lastPage = response.pageInfo?.pageNumber;
            return {
              ...response,
              elements: this.streets,
            };
          }),
          catchError(() =>
            of({
              elements: [],
              pageInfo: {},
            })
          )
        )
      )
    );
  }

  setFilterParams(params: MarketplaceStreetsFilters): void {
    this.streets = [];
    this.requestData$.value.pagination.pageNumber = 0;
    super.setFilterParams(params);
    if (window.scrollY > 118) {
      window.scrollTo(0, 118);
    }
  }

  setSortParams(params: string): void {
    this.streets = [];
    this.requestData$.value.pagination.pageNumber = 0;
    super.setSortParams(params);
    if (window.scrollY > 118) {
      window.scrollTo(0, 118);
    }
  }

  reloadData(): void {
    this.streets = [];
    this.requestData$.value.pagination.pageNumber = 0;
    this.requestData$.next(this.requestData$.value);
    if (window.scrollY > 118) {
      window.scrollTo(0, 118);
    }
  }

  saveQueryParams(params: Params): void {
    this.queryParams = params;
  }

  protected override convertToRequestData(
    queryParams: Partial<Record<string, string>>
  ): RequestData<MarketplaceStreetsFilters, string> {
    const {
      sort,
      pageNumber,
      zones,
      name,
      fromPrice,
      toPrice,
      countryCode,
      cityId,
      nftMarketType,
      hasMatching,
    } = queryParams || {};
    return {
      sort: sort || '',
      filter: {
        name,
        price: {
          fromPrice: fromPrice ? +fromPrice : undefined,
          toPrice: toPrice ? +toPrice : undefined,
        },
        hasMatching: hasMatching === 'true' ? true : undefined,
        zones: convertToNftZones(zones || ''),
        countryCode,
        cityId: cityId ? +cityId : undefined,
        nftMarketType: marketplaceMarketTypes.find(type => type.item === nftMarketType),
      },
      pagination: {
        ...this.requestData$.getValue().pagination,
        pageNumber: pageNumber ? +pageNumber : this.requestData$.getValue().pagination.pageNumber,
      },
    };
  }

  protected override createQueryParams(
    data: RequestData<MarketplaceStreetsFilters, string>
  ): Params {
    const { sort, pagination, filter } = data;
    const queryParams = {
      sort: sort || null,
      pageNumber: pagination.pageNumber || null,
      name: filter.name || null,
      cityId: filter.cityId || null,
      countryCode: filter.countryCode || null,
      fromPrice: filter.price?.fromPrice || null,
      nftMarketType: filter.nftMarketType?.item || null,
      toPrice: filter.price?.toPrice || null,
      hasMatching: filter.hasMatching || false,
      zones: filter.zones ? convertToQueryZones(filter.zones) : null,
    };
    return queryParams;
  }

  private convertZones(zones?: NftFilterPlanForm): number[] {
    if (!zones) return [1, 2, 3, 4];

    const levels = [
      { ...STREET_LEVELS.plan1, control: zones.isBasic },
      { ...STREET_LEVELS.plan2, control: zones.isStandard },
      { ...STREET_LEVELS.plan3, control: zones.isPremium },
      { ...STREET_LEVELS.plan4, control: zones.isElite },
    ];

    return levels.filter(item => item?.control).map(item => item.zone);
  }
}
