<h1 class="font-xhg weight-semibold color-white">
  {{ 'marketplace.statistic.title' | translate }}
</h1>

<div class="statistic">
  <div class="available">
    <span
      class="display-inline-block"
      [innerHtml]="'marketplace.statistic.streets_available' | translate"></span>
  </div>

  <ng-container *envIf="'dev'">
    <div class="market">
      <span class="statistic-value">{{ statistic?.streetsOnMarkets | precision }}</span>
      <span>{{ 'marketplace.statistic.streets_market' | translate }}</span>
    </div>

    <div class="sales">
      <span class="statistic-value">{{ statistic?.streetsSoldToday | precision }}</span>
      <span>{{ 'marketplace.statistic.streets_sales_today' | translate }}</span>
    </div>
  </ng-container>
</div>
