import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import {
  SortDirection,
  SortHeaderItem,
  SortStatus,
} from 'core/components/list-components/sorting.model';
import { iconSortDouble } from 'core/icons/lib/icon-sort-double';

@Component({
  selector: 'app-sort-header',
  templateUrl: './sort-header.component.html',
  styleUrls: ['./sort-header.component.scss'],
})
export class SortHeaderComponent implements OnChanges {
  @Input() headers: SortHeaderItem[] = [];
  @Input() sortData: string | undefined;
  @Input() disableNoneDirection = false;

  @Output() sortChange = new EventEmitter<string | undefined>();

  sortStatus = '';

  activeSort: SortStatus = {
    key: '',
    direction: 'none',
    header: {},
  };

  readonly sortIcon = iconSortDouble;

  private sortDirections: SortDirection[] = ['desc', 'asc'];

  ngOnChanges(changes: SimpleChanges<{ sortData: string | undefined }>): void {
    if (changes.sortData?.currentValue) {
      const sortEvent = changes.sortData.currentValue.split(',');
      this.activeSort = {
        direction: sortEvent[1] as SortDirection,
        key: sortEvent[0],
        header: {},
      };
    }
  }

  onSortChange(header: SortHeaderItem): void {
    this.activeSort = this.getNextSortDirection(header);

    if (this.activeSort.key) {
      const sortData =
        this.activeSort.key && this.activeSort.direction !== 'none'
          ? Object.values(this.activeSort)
              .filter(item => typeof item === 'string')
              .join(',')
          : '';

      this.sortChange.next(sortData);
    } else {
      if (this.activeSort.header.buttonKey) {
        this.sortChange.next(this.activeSort.header.buttonKey);
      } else {
        this.sortChange.next('');
      }
    }
  }

  getIconClass(header: SortHeaderItem): Record<string, boolean> {
    return {
      [this.activeSort.direction]: this.activeSort.key === header.key,
    };
  }

  private getNextSortDirection(header: SortHeaderItem): SortStatus {
    if (header.key === this.activeSort.key) {
      const currentDirectionIndex = this.sortDirections.indexOf(this.activeSort.direction);
      const nextDirectionIndex =
        currentDirectionIndex + 1 > this.sortDirections.length - 1 ? -1 : currentDirectionIndex + 1;

      if (nextDirectionIndex < 0) {
        if (this.disableNoneDirection) {
          return {
            key: header.key,
            direction: this.sortDirections[0],
            header,
          };
        }

        return {
          key: '',
          direction: 'none',
          header,
        };
      }

      return {
        key: this.activeSort.key,
        direction: this.sortDirections[nextDirectionIndex],
        header,
      };
    }

    return {
      key: header.key || '',
      direction: header.key ? 'desc' : 'none',
      header,
    };
  }
}
