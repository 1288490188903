import { Component, OnDestroy } from '@angular/core';
import { appNav } from 'app/app.navigation';
import { Subject } from 'rxjs';

@Component({
  templateUrl: './wishlist-page.component.html',
  styleUrls: [
    './wishlist-page.component.scss',
    '../../../dashboard/components/_styles/dashboard-layout.scss',
  ],
})
export class WishlistPageComponent implements OnDestroy {
  private destroy$ = new Subject();

  appNav = appNav;

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
