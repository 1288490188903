import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { fromEvent } from 'rxjs';

interface NavigationState {
  url: string;
  timestamp: number;
  history: string[];
}

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private history: string[] = [];
  private fallback = '/';

  constructor(private router: Router, private location: Location) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.history[this.history.length - 1] !== event.urlAfterRedirects) {
          this.history.push(router.url);
        }
      }
    });
  }

  init(fallback: string): void {
    this.fallback = fallback;
    const lsKey = '_rtsts';
    const state = JSON.parse(localStorage.getItem(lsKey) || '{}') as NavigationState;
    if (state && state.url === this.location.path() && Date.now() - state.timestamp < 6000) {
      this.history = state.history;
    }
    localStorage.removeItem('routerStates');
    fromEvent(window, 'unload').subscribe(() => {
      localStorage.setItem(
        lsKey,
        JSON.stringify({
          url: this.location.path(),
          timestamp: Date.now(),
          history: this.history,
        } as NavigationState)
      );
    });
  }

  back(): void {
    this.history.pop();

    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl(this.fallback);
    }
  }
}
