import { Injectable } from '@angular/core';
import { AuthService } from 'auth/services/auth.service';

export interface StateFullService {
  clearState(): void;
}

@Injectable({
  providedIn: 'root',
})
export class StateClearService {
  private stateFullServices: StateFullService[] = [];

  constructor(private authService: AuthService) {
    this.authService.loggedOut.subscribe(() => {
      this.stateFullServices.forEach(service => {
        service.clearState();
      });
    });
  }

  register(service: StateFullService): void {
    this.stateFullServices = this.stateFullServices.concat(service);
  }
}
