import {HttpClient} from '@angular/common/http';
import { Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FbService {
  private access_token = 'EAAHUPEcmxgcBAGwvlMJDCSsRoL95Gt1ZAlL1D94MAEIHD5NNJAz5rwh1td5MZAJCrGTHL63qec4aNPZCcaUxqVmpYK0Cwk92tKrLAYKoZBUnx4biZB5kZBcU73l7QXdjJCVtthTJ3tirRZAWh7NyuCoLrcCoe4fCv15B71GcC67vET2ZA2ejeDHN';

  constructor(private http: HttpClient) {}

  pushTag(config: Record<string, unknown>): void {
    console.log('[FBTM]: ' + JSON.stringify(config));
    const url = `https://graph.facebook.com/v15.0/me?access_token=${this.access_token}`;

    this.http.post(url, {
        ...config
    }).subscribe(() => {
    }, (err) => {
      console.log('ERROR - [FBTM]: ' + JSON.stringify(config));
      console.log(err);
    });
  }
}
