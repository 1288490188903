import { Routes, UrlMatchResult, UrlSegment } from '@angular/router';
import { authRoot } from 'auth/auth.navigation';
import { AuthSuccessGuard } from 'auth/guards/auth-success.guard';
import { cashierRoot } from 'cashier/cashier.navigation';
import { RouteData } from 'core/models/route.data';
import { dashboardRoot } from 'dashboard/dashboard.navigation';
import { profileRoot } from 'profile/profile.navigation';

import { commonRoot } from './_main/main.navigation';
import { collectionKeyword, collectionsRoot } from './collections/collections.navigation';
import { exchangeRoot } from './exchange/exchange.navigation';
import { landingRoot } from './landing/landing.navigation';
import { legalRoot } from './legal/legal.navigation';
import { marketplaceRoot } from './marketplace/marketplace.navigation';
import { isBrokenMarketplaceSideMapUrl } from './marketplace/marketplace.routes';
import { miniGamesRoot } from './mini-games/mini-games.navigation';
import { streetsRoot } from './streets/streets.navigation';
import { streetsCatalogRoot } from './streets-catalog/streets-catalog.navigation';

export function marketplaceMatcher(url: UrlSegment[]): UrlMatchResult | null {
  const pathHead = url[0]?.path || '';
  const isBroken = isBrokenMarketplaceSideMapUrl(url);
  
  if (pathHead === marketplaceRoot) {
    return { consumed: [url[0]] };
  } else if (
    (/[\p{L}0-9-\u0600-\u06FF]+-[0-9]+$/giu.exec(pathHead) && !pathHead.includes(collectionKeyword)) ||
    isBroken
  ) {
    return { consumed: [] };
  }
  return null;
}

export function collectionMatcher(url: UrlSegment[]): UrlMatchResult | null {
  const pathHead = url[0]?.path || '';
  if (pathHead === collectionsRoot) {
    return { consumed: [url[0]] };
  } else if (/[\p{L}0-9-]+-[0-9]+$/giu.exec(pathHead) && pathHead.includes(collectionKeyword)) {
    return { consumed: [] };
  }
  return null;
}

export const routes: Routes = [
  {
    path: landingRoot,
    loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule),
    data: {
      hostClass: false,
      showFooter: true,
    } as RouteData,
  },
  {
    path: streetsRoot,
    loadChildren: () => import('./streets/streets.module').then(m => m.StreetsModule),
  },
  {
    path: dashboardRoot,
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthSuccessGuard],
    data: {
      showFooter: true,
    } as RouteData,
  },
  {
    path: authRoot,
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    data: {
      showFooter: true,
    } as RouteData,
  },
  {
    path: cashierRoot,
    loadChildren: () => import('./cashier/cashier.module').then(m => m.CashierModule),
    data: {
      showFooter: true,
    } as RouteData,
    canActivate: [AuthSuccessGuard],
  },
  {
    path: commonRoot,
    loadChildren: () => import('./_main/main.module').then(m => m.MainModule),
    data: {
      showFooter: true,
    } as RouteData,
  },
  {
    path: legalRoot,
    loadChildren: () => import('./legal/legal.module').then(m => m.LegalModule),
    data: {
      showFooter: true,
    } as RouteData,
  },
  {
    path: profileRoot,
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
    data: {
      showFooter: true,
    } as RouteData,
  },
  {
    path: exchangeRoot,
    loadChildren: () => import('./exchange/exchange.module').then(m => m.ExchangeModule),
    data: {
      showFooter: true,
      disableBalance: true,
    } as RouteData,
  },
  {
    matcher: marketplaceMatcher,
    loadChildren: () => import('./marketplace/marketplace.module').then(m => m.MarketplaceModule),
  },
  {
    path: streetsCatalogRoot,
    loadChildren: () =>
      import('./streets-catalog/streets-catalog.module').then(m => m.StreetsCatalogModule),
    data: {
      showFooter: true,
    } as RouteData,
  },
  {
    matcher: collectionMatcher,
    loadChildren: () => import('./collections/collections.module').then(m => m.CollectionsModule),
    data: {
      showFooter: true,
    } as RouteData,
  },
  {
    path: miniGamesRoot,
    loadChildren: () => import('./mini-games/mini-games.module').then(m => m.MiniGamesModule),
  },
  {
    path: '**',
    redirectTo: '/',
  },

  // lazy modals
  {
    path: profileRoot,
    outlet: 'primaryModal',
    loadChildren: () =>
      import('./profile/modules/modals/profile-modals.module').then(m => m.ProfileModalsModule),
  },
  {
    path: dashboardRoot,
    outlet: 'primaryModal',
    loadChildren: () =>
      import('./dashboard/modules/modals/dashboard-modals.module').then(
        m => m.DashboardModalsModule
      ),
  },
  {
    path: `${streetsRoot}-modals`,
    outlet: 'primaryModal',
    loadChildren: () =>
      import('./streets/modules/modals/streets-modals.module').then(m => m.StreetsModalsModule),
  },
  {
    path: `${cashierRoot}-modals`,
    outlet: 'primaryModal',
    loadChildren: () =>
      import('./cashier/modules/modals/cashier-modals.module').then(m => m.CashierModalsModule),
  },
  {
    path: `${authRoot}-modals`,
    outlet: 'primaryModal',
    loadChildren: () =>
      import('./auth/modules/modals/auth-modals.module').then(m => m.AuthModalsModule),
    data: { preload: true } as RouteData,
  },
  {
    path: commonRoot,
    outlet: 'primaryModal',
    loadChildren: () =>
      import('./_main/modules/modals/common-modals.module').then(m => m.CommonModalsModule),
  },
];
