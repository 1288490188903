import { MarketTOResponse } from 'api/models';

import { Building } from './building.model';
import { Street } from './street.model';

export class Market {
  id!: number;
  title!: string;
  feeSetId!: number;
  fixPrice!: number;
  priceLimit!: number;
  maxAmount!: number;
  minAmount!: number;

  leftCurrencyId!: number;
  leftCurrencyCode!: string;
  rightCurrencyId!: number;
  rightCurrencyCode!: string;

  isActive?: boolean;
  isDisabled?: boolean;
  isLimitDisabled?: boolean;
  type: MarketTOResponse['type'];

  constructor(
    init: PickData<Omit<Market, 'street' | 'building'>>,
    public street?: Street | Building
  ) {
    Object.assign(this, init);
  }
}

export const dataToMarket = (data: MarketTOResponse = {}): PickData<Omit<Market, 'street'>> => ({
  id: data.id || 0,
  title: data.title || '',
  feeSetId: data.feeSetId || 0,
  fixPrice: data.fixPrice || 0,
  priceLimit: data.priceLimit || 0,
  maxAmount: data.maxAmount || 0,
  minAmount: data.minAmount || 0,
  leftCurrencyId: data.rightCurrencyId || 0,
  leftCurrencyCode: data.leftCurrencyCode || '',
  rightCurrencyId: data.rightCurrencyId || 0,
  rightCurrencyCode: data.rightCurencyCode || '',
  isActive: data.isActive,
  isDisabled: (data as { isDisabled: boolean }).isDisabled || false,
  isLimitDisabled: data.isLimitDisabled,
  type: data.type,
});
