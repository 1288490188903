import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';

@Directive({
  selector: '[appTabItem]',
})
export class TabItemDirective {
  @Input() activeClass!: string;
  private observer!: MutationObserver;

  @Output() initActiveTab = new EventEmitter<HTMLElement>();

  constructor(public elementRef: ElementRef<HTMLElement>) {}

  observe(): void {
    this.observer = new MutationObserver((mutations: MutationRecord[]) => {
      mutations.forEach(() => {
        if (this.elementRef.nativeElement.classList.contains(this.activeClass)) {
          this.initActiveTab.next(this.elementRef.nativeElement);
        }
      });
    });

    this.observer.observe(this.elementRef.nativeElement, {
      attributes: true,
      attributeFilter: ['class'],
    });
  }

  disconnect(): void {
    this.observer.disconnect();
  }
}
