import { animate, style, transition, trigger } from '@angular/animations';

export const menuTransition = trigger('menuTransition', [
  transition('* => slide', [
    style({ opacity: 0, transform: 'translateY(35px)' }),
    animate('.22s ease-out', style({ opacity: 1, transform: 'translateY(0)' })),
  ]),
  transition('* => fade', [
    style({ opacity: 0, transform: 'scale(0.75)', 'transform-origin': 'bottom' }),
    animate('.22s ease-out', style({ opacity: 1, transform: 'scale(1)' })),
  ]),
  transition(':leave', [style({ opacity: 1 }), animate('.22s', style({ opacity: 0 }))]),
]);
