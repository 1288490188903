import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { iconChevronLeft } from 'core/icons/lib/icon-chevron-left';
import { MediaQuery } from 'core/modules/platform/services/media-query.service';
import { Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-pagination',
  template: `
    <a
      class="btn prev pagination-link"
      *ngIf="link; else prevButton"
      rel="prev"
      [class.disabled-link]="current === 1"
      [routerLink]="link"
      [queryParams]="{ page: current - 1 }">
      <app-icon [icon]="arrowLeftIcon"></app-icon>
    </a>
    <ng-template #prevButton>
      <button class="btn prev" (click)="changed.next(current - 1)" [disabled]="current === 1">
        <app-icon [icon]="arrowLeftIcon"></app-icon>
      </button>
    </ng-template>

    <div class="pages">
      <ng-container *ngIf="total > 6 && current !== first.value && !isFound(first)">
        <ng-container
          *ngTemplateOutlet="
            template;
            context: { $implicit: first, isCurrent: current === first.value }
          "></ng-container>
      </ng-container>

      <div
        class="split"
        *ngIf="
          total > 6 &&
          current !== first.value &&
          !isFound(first) &&
          pages[0].value - 1 !== first.value
        ">
        ...
      </div>

      <ng-container *ngFor="let page of pages">
        <ng-container
          *ngTemplateOutlet="
            template;
            context: { $implicit: page, isCurrent: current === page.value }
          "></ng-container>
      </ng-container>

      <div
        class="split"
        *ngIf="
          total > 6 &&
          current !== last.value &&
          !isFound(last) &&
          (last.value || 0) - 1 !== pages[pages.length - 1].value
        ">
        ...
      </div>

      <ng-container *ngIf="total > 6 && current !== last.value && !isFound(last)">
        <ng-container
          *ngTemplateOutlet="
            template;
            context: { $implicit: last, isCurrent: current === last.value }
          "></ng-container>
      </ng-container>
    </div>

    <a
      class="btn next pagination-link"
      *ngIf="link; else nextBtn"
      rel="next"
      [class.disabled-link]="current === total"
      [routerLink]="link"
      [queryParams]="{ page: current + 1 }">
      <app-icon [icon]="arrowLeftIcon"></app-icon>
    </a>

    <ng-template #nextBtn>
      <button class="btn next" (click)="changed.next(current + 1)" [disabled]="current === total">
        <app-icon [icon]="arrowLeftIcon"></app-icon>
      </button>
    </ng-template>
  `,
  styleUrls: ['pagination.component.scss'],
})
export class PaginationComponent implements OnChanges, OnDestroy, OnInit {
  arrowLeftIcon = iconChevronLeft;

  @Input()
  total = 1;
  @Input()
  current = 1;

  @Input() link?: string;

  @ContentChild(TemplateRef, { static: true })
  template!: TemplateRef<HTMLElement>;

  @Output()
  changed = new EventEmitter();

  first: { value?: number; label?: string } = {};
  last: { value?: number; label?: string } = {};

  pages: { value: number; label: string }[] = [];

  private destroy$ = new Subject();

  constructor(private media: MediaQuery) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.media.mediaChanged
      .pipe(skip(1), takeUntil(this.destroy$)) // skip first because of replay subject
      .subscribe(() => {
        this.ngOnChanges();
      });
  }

  ngOnChanges(): void {
    this.updatePagination(
      this.total || 1,
      this.current || 1,
      this.media.current === 'small' ? 4 : 5
    );
  }

  private updatePagination(totalPages: number, currentPage: number, uiLimit: number): void {
    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }
    let startPage: number;
    let endPage: number;
    if (totalPages <= uiLimit) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= Math.floor(uiLimit / 2 + 1)) {
        startPage = 1;
        endPage = uiLimit;
      } else if (currentPage + Math.ceil(uiLimit / 2 - 1) >= totalPages) {
        startPage = totalPages - (uiLimit - 1);
        endPage = totalPages;
      } else {
        startPage = currentPage - Math.floor(uiLimit / 2);
        endPage = currentPage + Math.ceil(uiLimit / 2 - 1);
      }
    }

    // create start and last page button of total > 6
    if (this.total > 6) {
      this.first = {
        value: 1,
        label: '01',
      };

      this.last = {
        value: this.total,
        label: this.total < 10 ? `0${this.total}` : this.total.toString(),
      };
    }

    // create an array of pages to ng-repeat in the pager control
    this.pages = Array.from(Array(endPage + 1 - startPage).keys()).map(i => ({
      value: startPage + i,
      label: (startPage + i < 10 ? `0${startPage + i}` : startPage + i).toString(),
    }));
  }

  isFound(item: { value?: number; label?: string }): boolean {
    return !!this.pages.find(page => item.value === page.value);
  }
}
