import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NftTOList } from 'api/models';
import { FavoriteObjectControllerService } from 'api/services';
import { APIError } from 'core/models/error.model';
import debounce from 'core/utils/rxjs/decorators/debounce.decorator';
import { NftFilterPlanForm } from 'dashboard/forms/nft-plan-filter.form';
import { FavoriteProvider } from 'dashboard/models/favoriteProvider.model';
import { NftFilterData } from 'dashboard/models/nftFilterData.data';
import {
  convertToNftZones,
  convertToQueryZones,
  STREET_LEVELS,
} from 'dashboard/models/street.data';
import { StreetsService } from 'dashboard/services/streets.service';
import { defer, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { Street } from '../../dashboard/models/street.model';
import {
  PageControllerService,
  RequestData,
} from '../../dashboard/services/page-controller.service';
import { StreetsFactory } from '../../dashboard/services/streets.factory';

@Injectable({ providedIn: 'root' })
export class WishlistStreetsService
  extends PageControllerService<NftFilterData, string>
  implements FavoriteProvider
{
  items: Street[] = [];

  private favorites: number[] = [];

  constructor(
    private favoritesApi: FavoriteObjectControllerService,
    private streetsService: StreetsService,
    private factory: StreetsFactory,
    protected route: ActivatedRoute,
    protected router: Router
  ) {
    super(route, router);
    factory.setupFavoriteProvider(this);
  }

  get favoritesLength(): number {
    return this.favorites.length;
  }

  load(): Observable<Array<number>> {
    return this.favoritesApi.checkFavoriteObjectsUsingPOST({ type: 'STREET', objectIds: [] }).pipe(
      tap(res => {
        this.favorites = res || [];
      })
    );
  }

  loadWithFilters(): Observable<NftTOList> {
    return this.getRequestData().pipe(
      switchMap(params =>
        this.favoritesApi
          .listUsingGET10({
            pageNumber: params.pagination.pageNumber || 0,
            sort: params.sort,
            pageSize: params.pagination.size || 15,
            description: params.filter.description,
            fromPrice: params.filter.price?.fromPrice,
            toPrice: params.filter.price?.toPrice,
            zones: this.convertZones(params.filter.zones),
            cityId: params.filter.cityId,
            countryCode: params.filter.countryCode,
            type: 'STREET',
          })
          .pipe(
            tap(res => {
              const newItems = (res.elements || []).map(d => this.factory.get(d.id, d));
              this.items = [...newItems];
            })
          )
      )
    );
  }

  isFavoriteId(id: number): boolean {
    return this.favorites.includes(id);
  }

  syncIfRemoved(): Subscription {
    if (this.items.some(f => !f.isFavorite)) {
      const params = this.requestData$.getValue();
      return this.favoritesApi
        .listUsingGET10({
          pageNumber: params.pagination.pageNumber || 0,
          sort: params.sort,
          pageSize: params.pagination.size || 15,
          description: params.filter.description,
          fromPrice: params.filter.price?.fromPrice,
          toPrice: params.filter.price?.toPrice,
          countryCode: params.filter.countryCode,
          cityId: params.filter.cityId,
          zones: this.convertZones(params.filter.zones),
          type: 'STREET',
        })
        .pipe(map(res => (this.items = (res.elements || []).map(d => this.factory.get(d.id, d)))))
        .subscribe();
    }
    return of().subscribe();
  }

  removeFavorite(street: Street): Observable<Array<number>> {
    return this.favoritesApi
      .removeFavoritesUsingPOST({ objectIds: [street.id], type: 'STREET' })
      .pipe(
        tap(() => {
          street.isFavorite = false;
          this.favorites = this.favorites.filter(id => id !== street.id);
          this.items = this.items.filter(x => x.id !== street.id);
        })
      );
  }

  removeBulkFavorites(streets: Street[]): Observable<Array<number>> {
    return this.favoritesApi
      .removeFavoritesUsingPOST({
        type: 'STREET',
        objectIds: [...streets.map(item => item.id)],
      })
      .pipe(
        tap(response => {
          streets.forEach(s => {
            s.isFavorite = false;
            this.favorites = this.favorites.filter(id => id !== s.id);
            this.items = this.items.filter(id => id.id !== s.id);
          });
        })
      );
  }

  toggleFavorite(street: Street): Subscription {
    const rollback = street.isFavorite;
    street.isFavorite = !street.isFavorite;
    return this.doFavoriteRequest(street, rollback);
  }

  private convertZones(zones?: NftFilterPlanForm): number[] {
    if (!zones) return [];

    const levels = [
      { ...STREET_LEVELS.plan1, control: zones.isBasic },
      { ...STREET_LEVELS.plan2, control: zones.isStandard },
      { ...STREET_LEVELS.plan3, control: zones.isPremium },
      { ...STREET_LEVELS.plan4, control: zones.isElite },
    ];

    return levels.filter(item => item?.control).map(item => item.zone);
  }

  getStreet(street: Street): Observable<Street> {
    return defer(() =>
      street?.marketId
        ? this.streetsService
            .findByNFTMarket({ streetId: street.id })
            .pipe(map(res => res as Street))
        : of(street)
    );
  }

  @debounce(100)
  private doFavoriteRequest(street: Street, rollback?: boolean): Subscription {
    let req: Observable<unknown>;
    if (street.isFavorite) {
      req = this.favoritesApi.addFavoritesUsingPOST({ objectIds: [street.id], type: 'STREET' });
    } else {
      req = this.favoritesApi.removeFavoritesUsingPOST({ objectIds: [street.id], type: 'STREET' });
    }
    return req
      .pipe(catchError((err: APIError) => this.handleFavoriteError(err, street, rollback)))
      .subscribe(() => {
        if (street.isFavorite) {
          this.favorites.push(street.id);
          this.items = [street, ...this.items];
        } else {
          this.favorites = this.favorites.filter(id => id !== street.id);
          this.items = this.items.filter(x => x.id !== street.id);
        }
      });
  }

  private handleFavoriteError(
    err: APIError,
    street: Street,
    rollback?: boolean
  ): Observable<never> {
    if (err.code === 'CONFLICT' || err.code === 'NOT_FOUND') {
      err.preventHandling();
    } else {
      street.isFavorite = rollback;
    }
    return throwError(err);
  }

  protected override convertToRequestData(
    queryParams: Partial<Record<string, string>>
  ): RequestData<NftFilterData, string> {
    const { sort, pageNumber, zones, description, fromPrice, toPrice, cityId, countryCode } =
      queryParams || {};
    return {
      sort: sort || '',
      filter: {
        cityId: cityId ? +cityId : 0,
        countryCode: countryCode ? countryCode : undefined,
        description,
        price: {
          fromPrice: fromPrice ? +fromPrice : undefined,
          toPrice: toPrice ? +toPrice : undefined,
        },
        zones: convertToNftZones(zones || ''),
      },
      pagination: {
        ...this.requestData$.getValue().pagination,
        pageNumber: pageNumber ? +pageNumber : 0,
      },
    };
  }

  protected override createQueryParams(data: RequestData<NftFilterData, string>): Params {
    const { sort, pagination, filter } = data;
    const queryParams = {
      sort: sort || null,
      pageNumber: pagination.pageNumber || null,
      description: filter.description || null,
      fromPrice: filter.price?.fromPrice || null,
      toPrice: filter.price?.toPrice || null,
      cityId: filter.cityId ? +filter.cityId : 0,
      countryCode: filter.countryCode ? filter.countryCode : undefined,
      zones: filter.zones ? convertToQueryZones(filter.zones) : null,
    };

    return queryParams;
  }
}
