import { MarketTOResponse, OrderTO, OrderTOResponse } from 'api/models';

import { Building } from './building.model';
import { Street } from './street.model';

export class Order {
  id!: number;
  mysteryBoxId?: number;
  currencyCode!: string;
  amount!: number;
  matchedAmount?: number;
  price!: number;
  averagePrice!: number;
  initialAmount!: number;
  initialReserved!: number;

  fromAmount!: number;
  toAmount!: number;
  toCurrency!: string;

  feeAmount!: number;
  feeCurrency!: string;
  takerFee!: number;
  makerFee!: number;
  marketId?: number;

  closeReason!: OrderTOResponse['closeReason'];
  closedAt!: Date;
  createdAt!: Date;
  processedAt!: Date;
  side!: OrderTOResponse['side'];
  state!: OrderTOResponse['state'];
  type!: OrderTOResponse['type'];
  isActive?: boolean;
  country?: string;
  city?: string;

  street: Street = new Street();
  building: Building = new Building();

  get isBuilding(): boolean {
    return !!this.building.id;
  }

  get isMysteryBox(): boolean {
    return !!this.mysteryBoxId;
  }

  constructor(
    init: PickData<Omit<Order, 'street' | 'building' | 'asset'>> = dataToOrder(),
    public asset: Street | Building = new Street()
  ) {
    Object.assign(this, init);
    if (asset instanceof Building) {
      this.building = asset;
    } else {
      this.street = asset;
    }
  }

  get isBuy(): boolean {
    return this.side === 'BUY';
  }

  get isSell(): boolean {
    return this.side === 'SELL';
  }
}

export const dataToOrder = (
  data: OrderTOResponse = {},
  market?: MarketTOResponse
): PickData<Omit<Order, 'street' | 'building' | 'asset'>> => ({
  id: data.id || 0,
  currencyCode: data.currencyCode || '',
  amount: data.amount || 0,
  price: data.price || 0,
  averagePrice: data.averagePrice || (data as OrderTO).avgPrice || 0,
  feeAmount: (data.closedAt ? data.feeAmount : data.reservedFee) || 0,
  initialAmount: data.initialAmount || 0,
  initialReserved: data.initialReserved || 0,
  mysteryBoxId: data.mysteryBoxId || 0,
  fromAmount: data.fromAmount || 0,
  toAmount: data.toAmount || 0,
  toCurrency: (data.side === 'SELL' ? market?.rightCurencyCode : market?.leftCurrencyCode) || '',
  feeCurrency: market?.rightCurencyCode || '',
  marketId: data.marketId || 0,
  takerFee: data.takerFee || 0,
  makerFee: data.makerFee || 0,
  closedAt: new Date(data.closedAt || 0),
  createdAt: new Date(data.createdAt || 0),
  processedAt: new Date(data.processedAt || 0),
  side: data.side,
  state: data.state,
  type: data.type,
  isActive: data.isActive,
  matchedAmount: data.matchedAmount,
  country: data.nft?.properties?.country,
  city: data.nft?.properties?.city,
  closeReason: data.closeReason,
});
