import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { iconAddSquare } from 'core/icons/lib/icon-add-square';
import { iconMinusSquare } from 'core/icons/lib/icon-minus-square';
import { CutDecimalsPipe } from 'core/pipes/core-pipes/cut-decimals.pipe';
import { UIConfig } from 'core/services/ui-config.service';
import { fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { InputComponent } from './input.component';

@Component({
  selector: 'app-number-input',
  template: `
    <app-icon *ngIf="icon" [icon]="icon"></app-icon>
    <span class="title">{{ title }}</span>
    <input
      class="inp"
      type="text"
      inputmode="decimal"
      [attr.tabindex]="tabindex"
      [attr.autocomplete]="autocomplete"
      [attr.placeholder]="placeholder"
      [attr.disabled]="readOnly ? 'disabled' : null"
      [attr.name]="name"
      #input />
    <ng-content></ng-content>
    <app-input-extras *ngIf="extras" class="extras" [type]="extras"></app-input-extras>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberInputComponent),
      multi: true,
    },
  ],
  styleUrls: [
    './styles/solid.scss',
    './styles/solid-number.scss',
    './styles/number-with-balance.scss',
    './styles/solid-validation.scss',
  ],
})
export class NumberInputComponent extends InputComponent implements OnInit {
  @Input()
  set step(s: number) {
    this.stepNumber = s || 1;
  }
  get step(): number {
    return this.stepNumber;
  }

  @Input()
  currency?: string;

  @Input()
  maxIntegerDigits?: number;

  @Input()
  @HostBinding('attr.appearance')
  appearance?: 'number';

  @Input()
  disable = false;

  @Input()
  readOnly = false;

  @Output()
  appearanceClick = new EventEmitter<{ new: string; previous: string }>();

  private readonly plusIcon = iconAddSquare;
  private readonly minusIcon = iconMinusSquare;
  // used to set value from FromControl
  // while current field is active writeValue() will be pending
  // and changed after blur occurred
  // this would be helpful to set min value on blur if user input value less then min
  private pendingValue: number | null = null;
  private stepNumber = 1;
  private dotRegex = /^[.,]$/gm;
  private numberPipe = new CutDecimalsPipe();

  constructor(renderer: Renderer2, elementRef: ElementRef, private uiConfig: UIConfig) {
    super(elementRef, renderer);
  }

  ngOnInit(): void {
    super.ngOnInit();
    fromEvent(this.inputRef.nativeElement, 'mousedown')
      .pipe(takeUntil(this.destroy$))
      .subscribe(e => {
        if (!this.disable) {
          e.stopImmediatePropagation();
        }
      });
    if (this.appearance) {
      this.createNumberAppearance();
    }
  }

  private createNumberAppearance(): void {
    const addEl = this.renderer.createElement('span') as HTMLElement;
    const subEl = this.renderer.createElement('span') as HTMLElement;

    this.renderer.setProperty(addEl, 'innerHTML', this.plusIcon.svgData);
    this.renderer.setProperty(subEl, 'innerHTML', this.minusIcon.svgData);

    this.renderer.setProperty(addEl, 'innerHTML', this.plusIcon.svgData);
    this.renderer.setProperty(subEl, 'innerHTML', this.minusIcon.svgData);

    this.renderer.addClass(addEl.lastChild, 'svg-icon');
    this.renderer.addClass(subEl.lastChild, 'svg-icon');

    this.renderer.addClass(addEl, 'plus');
    this.renderer.addClass(subEl, 'minus');
    this.renderer.appendChild(this.elementRef.nativeElement, addEl);
    this.renderer.appendChild(this.elementRef.nativeElement, subEl);
    fromEvent<MouseEvent>(addEl, 'click')
      .pipe(takeUntil(this.destroy$))
      .subscribe(e => {
        const tmpValue = this.getInputValue();
        e.preventDefault();
        this.active = true;
        this.changeNumber(1);
        this.appearanceClick.next({ new: this.getInputValue(), previous: tmpValue });
        this.active = false;
      });
    fromEvent<MouseEvent>(subEl, 'click')
      .pipe(takeUntil(this.destroy$))
      .subscribe(e => {
        const tmpValue = this.getInputValue();
        e.preventDefault();
        this.active = true;
        this.changeNumber(-1);
        this.appearanceClick.next({ new: this.getInputValue(), previous: tmpValue });
        this.active = false;
      });
  }

  private changeNumber(sign: 1 | -1): void {
    const strValue = this.getInputValue();

    if (!strValue) {
      this.setInputValue(this.toFixed(0, this.getPrecision()));
    } else {
      const value = parseFloat(strValue);
      this.setInputValue(Math.max(0, value + this.step * sign).toFixed(this.getPrecision()));
    }
    this.onControlChanged(+this.getInputValue());
    this.onControlTouched();
  }

  writeValue(value: string | number): void {
    if (this.active && !this.applyChangesOnActive) {
      this.pendingValue = +value;
    } else if (value) {
      this.setInputValue(this.toFixed(+value, this.getPrecision(), true));
    } else {
      this.setInputValue(typeof value === 'number' ? '0' : '');
    }
  }

  protected onInput(e: InputEvent): void {
    this.pendingValue = null;
    let value = this.getInputValue();

    if (value === '') {
      this.onControlChanged('');
      return;
    }

    const inputEl = this.inputRef.nativeElement as HTMLInputElement;
    let caret = inputEl.selectionStart || 0;

    if (e.data?.match(this.dotRegex)) {
      const splittedValue = value.split('');

      if (!Number.isFinite(+value[0])) {
        // if we have entered a dot and the first symbol is not a number
        // Example: -.
        value = '0';
      } else if (splittedValue.filter(v => this.dotRegex.exec(v)).length > 1) {
        // when we enter a dot in the center of a number, provided that there is already a dot in the number
        // Example: 121.121.12
        splittedValue.splice(caret - 1, 1);
        value = splittedValue.join('');
      }
    }

    const tmpLength = value.length;
    value = (value.match(/\d+|,|\./g) || []).join('').replace(/(\.|,)+/g, '.');
    const lastDigit = value[value.length - 1];
    const firstDigit = value[0];
    let decimals = (/[.,](\d+)/.exec(value) || ['.'])[0].length - 1; // minus dot
    decimals = Math.min(decimals, this.getPrecision());
    if (this.maxIntegerDigits) {
      const digits = value.split('.');
      const integerDigits =
        digits[0].length > this.maxIntegerDigits
          ? digits[0].slice(0, this.maxIntegerDigits)
          : digits[0];
      value = [integerDigits, ...(digits[1] ? [digits[1]] : [])].join('.');
    }
    value = `${this.toFixed(+value, decimals)}${lastDigit === '.' ? '.' : ''}`;

    if (firstDigit === '.' && value[0] === '0') {
      value = value.slice(1);
    }

    if (e.inputType === 'deleteContentBackward' && value === '0') {
      this.setInputValue('');
    } else {
      this.setInputValue(value);
      if (firstDigit === '0' && caret === 2 && e.data?.match(/\d/)) {
        inputEl.setSelectionRange(1, 1);
      } else {
        caret += tmpLength - value.length;
        inputEl.setSelectionRange(caret, caret);
      }
    }
    this.onControlChanged(+value);
  }

  protected onBlur(): void {
    if (this.pendingValue) {
      this.setInputValue(this.toFixed(this.pendingValue, this.getPrecision()));
      this.pendingValue = null;
    }
    super.onBlur();
  }

  private toFixed(value: number, decimals: number, trim?: boolean): string {
    const result = this.numberPipe.transform(value, decimals).replace(/,/g, '');
    return trim ? parseFloat(result).toString() : result;
  }

  private getPrecision(): number {
    return this.uiConfig.precisionProvider.getPrecision(this.currency || '');
  }
}
