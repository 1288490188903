import { NFTBuyEventData } from 'dashboard/services/nft-buy-event.service';

import { StreetLevelType, zoneToLevel } from './street.data';

export class NFTBuyEvent {
  id!: number;
  city!: string;
  country!: string;
  ownerName!: string;
  ownerUuid!: string;
  countryCode?: string;
  lastPrice!: number;
  streetName!: string;
  level!: StreetLevelType;
  streetId?: number;
  number!: string;
  dtype!: string;

  get streetSecondaryName(): string {
    return `${this.city}, ${this.country}`;
  }

  constructor(init?: PickData<NFTBuyEvent>) {
    Object.assign(this, init);
  }
}

export const dataToNFTBuyEvent = (data: NFTBuyEventData): PickData<NFTBuyEvent> => ({
  id: data.id || 0,
  city: data.city || '',
  country: data.country || '',
  ownerUuid: data.ownerUuid || '',
  ownerName: data.ownerName || '',
  lastPrice: data.lastPrice || 0,
  streetName: data.street || '',
  countryCode: data.countryCode,
  streetId: data.streetId,
  dtype: data.dtype,
  number: data.number,
  level: zoneToLevel(data.zone),
});
