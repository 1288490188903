/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { BuildingTOResponse } from '../models/building-toresponse';
import { BuildingPageResponse } from '../models/building-page-response';
import { CityTOListResponse } from '../models/city-tolist-response';
import { CityTO } from '../models/city-to';
import { CountryListResponse } from '../models/country-list-response';
import { RegionTOListResponse } from '../models/region-tolist-response';
import { StreetGeoJsonResponse } from '../models/street-geo-json-response';
import { BoundingBoxDto } from '../models/bounding-box-dto';
import { StreetTOExtendedListResponse } from '../models/street-toextended-list-response';
import { List } from '../models/list';
import { SuggestionStreetForm } from '../models/suggestion-street-form';

/**
 * Gis Controller
 */
@Injectable({
  providedIn: 'root',
})
class GisControllerService extends __BaseService {
  static readonly getBuildingUsingGETPath = '/api/v1/gis/buildings';
  static readonly listBuildingsUsingGETPath = '/api/v1/gis/buildings/list';
  static readonly sliceBuildingsUsingGETPath = '/api/v1/gis/buildings/slice';
  static readonly listCitiesUsingGETPath = '/api/v1/gis/cities/list';
  static readonly findCityByIdUsingGETPath = '/api/v1/gis/cities/{id}';
  static readonly findCityByGeoUsingGETPath = '/api/v1/gis/cities/{lon}/{lat}';
  static readonly listCountriesUsingGETPath = '/api/v1/gis/countries/list';
  static readonly listRegionsUsingGETPath = '/api/v1/gis/regions/list';
  static readonly findStreetUsingGETPath = '/api/v1/gis/streets';
  static readonly streetsBoundingBoxUsingGETPath = '/api/v1/gis/streets/bounding-box';
  static readonly listClosestStreetsUsingGETPath = '/api/v1/gis/streets/closest-to';
  static readonly listStreetsUsingGETPath = '/api/v1/gis/streets/list';
  static readonly listSimilarStreetsUsingGETPath = '/api/v1/gis/streets/similar-to/{streetId}';
  static readonly sliceStreetsUsingGETPath = '/api/v1/gis/streets/slice';
  static readonly suggestionForCollectionUsingGETPath = '/api/v1/gis/streets/suggestion-for-collection';
  static readonly suggestionForCollectionUsingPOSTPath = '/api/v1/gis/streets/suggestion-for-collection';
  static readonly suggestionForCollectionPublicUsingGETPath = '/api/v1/gis/streets/suggestion-for-collection/public';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get building
   * @param params The `GisControllerService.GetBuildingUsingGETParams` containing the following parameters:
   *
   * - `lon`:
   *
   * - `lat`:
   *
   * - `id`:
   *
   * @return OK
   */
  getBuildingUsingGETResponse(params: GisControllerService.GetBuildingUsingGETParams): __Observable<__StrictHttpResponse<BuildingTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.lon != null) __params = __params.set('lon', params.lon.toString());
    if (params.lat != null) __params = __params.set('lat', params.lat.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/gis/buildings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BuildingTOResponse>;
      })
    );
  }
  /**
   * Get building
   * @param params The `GisControllerService.GetBuildingUsingGETParams` containing the following parameters:
   *
   * - `lon`:
   *
   * - `lat`:
   *
   * - `id`:
   *
   * @return OK
   */
  getBuildingUsingGET(params: GisControllerService.GetBuildingUsingGETParams): __Observable<BuildingTOResponse> {
    return this.getBuildingUsingGETResponse(params).pipe(
      __map(_r => _r.body as BuildingTOResponse)
    );
  }

  /**
   * List buildings
   * @param params The `GisControllerService.ListBuildingsUsingGETParams` containing the following parameters:
   *
   * - `zones`:
   *
   * - `streetIds`:
   *
   * - `street`:
   *
   * - `sort`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `ownerIds`:
   *
   * - `nftMarketType`:
   *
   * - `name`:
   *
   * - `myCityId`:
   *
   * - `minPrice`:
   *
   * - `maxPrice`:
   *
   * - `isWithdrawn`:
   *
   * - `ids`:
   *
   * - `countryCodes`:
   *
   * - `cityIds`:
   *
   * @return OK
   */
  listBuildingsUsingGETResponse(params: GisControllerService.ListBuildingsUsingGETParams): __Observable<__StrictHttpResponse<BuildingPageResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.zones || []).forEach(val => {if (val != null) __params = __params.append('zones', val.toString())});
    (params.streetIds || []).forEach(val => {if (val != null) __params = __params.append('streetIds', val.toString())});
    if (params.street != null) __params = __params.set('street', params.street.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    (params.ownerIds || []).forEach(val => {if (val != null) __params = __params.append('ownerIds', val.toString())});
    if (params.nftMarketType != null) __params = __params.set('nftMarketType', params.nftMarketType.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.myCityId != null) __params = __params.set('myCityId', params.myCityId.toString());
    if (params.minPrice != null) __params = __params.set('minPrice', params.minPrice.toString());
    if (params.maxPrice != null) __params = __params.set('maxPrice', params.maxPrice.toString());
    if (params.isWithdrawn != null) __params = __params.set('isWithdrawn', params.isWithdrawn.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.countryCodes || []).forEach(val => {if (val != null) __params = __params.append('countryCodes', val.toString())});
    (params.cityIds || []).forEach(val => {if (val != null) __params = __params.append('cityIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/gis/buildings/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BuildingPageResponse>;
      })
    );
  }
  /**
   * List buildings
   * @param params The `GisControllerService.ListBuildingsUsingGETParams` containing the following parameters:
   *
   * - `zones`:
   *
   * - `streetIds`:
   *
   * - `street`:
   *
   * - `sort`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `ownerIds`:
   *
   * - `nftMarketType`:
   *
   * - `name`:
   *
   * - `myCityId`:
   *
   * - `minPrice`:
   *
   * - `maxPrice`:
   *
   * - `isWithdrawn`:
   *
   * - `ids`:
   *
   * - `countryCodes`:
   *
   * - `cityIds`:
   *
   * @return OK
   */
  listBuildingsUsingGET(params: GisControllerService.ListBuildingsUsingGETParams): __Observable<BuildingPageResponse> {
    return this.listBuildingsUsingGETResponse(params).pipe(
      __map(_r => _r.body as BuildingPageResponse)
    );
  }

  /**
   * Slice buildings
   * @param params The `GisControllerService.SliceBuildingsUsingGETParams` containing the following parameters:
   *
   * - `zones`:
   *
   * - `streetIds`:
   *
   * - `street`:
   *
   * - `sort`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `ownerIds`:
   *
   * - `nftMarketType`:
   *
   * - `name`:
   *
   * - `myCityId`:
   *
   * - `minPrice`:
   *
   * - `maxPrice`:
   *
   * - `isWithdrawn`:
   *
   * - `ids`:
   *
   * - `countryCodes`:
   *
   * - `cityIds`:
   *
   * @return OK
   */
  sliceBuildingsUsingGETResponse(params: GisControllerService.SliceBuildingsUsingGETParams): __Observable<__StrictHttpResponse<BuildingPageResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.zones || []).forEach(val => {if (val != null) __params = __params.append('zones', val.toString())});
    (params.streetIds || []).forEach(val => {if (val != null) __params = __params.append('streetIds', val.toString())});
    if (params.street != null) __params = __params.set('street', params.street.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    (params.ownerIds || []).forEach(val => {if (val != null) __params = __params.append('ownerIds', val.toString())});
    if (params.nftMarketType != null) __params = __params.set('nftMarketType', params.nftMarketType.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.myCityId != null) __params = __params.set('myCityId', params.myCityId.toString());
    if (params.minPrice != null) __params = __params.set('minPrice', params.minPrice.toString());
    if (params.maxPrice != null) __params = __params.set('maxPrice', params.maxPrice.toString());
    if (params.isWithdrawn != null) __params = __params.set('isWithdrawn', params.isWithdrawn.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.countryCodes || []).forEach(val => {if (val != null) __params = __params.append('countryCodes', val.toString())});
    (params.cityIds || []).forEach(val => {if (val != null) __params = __params.append('cityIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/gis/buildings/slice`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BuildingPageResponse>;
      })
    );
  }
  /**
   * Slice buildings
   * @param params The `GisControllerService.SliceBuildingsUsingGETParams` containing the following parameters:
   *
   * - `zones`:
   *
   * - `streetIds`:
   *
   * - `street`:
   *
   * - `sort`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `ownerIds`:
   *
   * - `nftMarketType`:
   *
   * - `name`:
   *
   * - `myCityId`:
   *
   * - `minPrice`:
   *
   * - `maxPrice`:
   *
   * - `isWithdrawn`:
   *
   * - `ids`:
   *
   * - `countryCodes`:
   *
   * - `cityIds`:
   *
   * @return OK
   */
  sliceBuildingsUsingGET(params: GisControllerService.SliceBuildingsUsingGETParams): __Observable<BuildingPageResponse> {
    return this.sliceBuildingsUsingGETResponse(params).pipe(
      __map(_r => _r.body as BuildingPageResponse)
    );
  }

  /**
   * List Cities
   * @param params The `GisControllerService.ListCitiesUsingGETParams` containing the following parameters:
   *
   * - `regionCode`: regionCode
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `nameStart`: nameStart
   *
   * - `name`: name
   *
   * - `countryCode`: countryCode
   *
   * @return OK
   */
  listCitiesUsingGETResponse(params: GisControllerService.ListCitiesUsingGETParams): __Observable<__StrictHttpResponse<CityTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionCode != null) __params = __params.set('regionCode', params.regionCode.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.nameStart != null) __params = __params.set('nameStart', params.nameStart.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.countryCode != null) __params = __params.set('countryCode', params.countryCode.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/gis/cities/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CityTOListResponse>;
      })
    );
  }
  /**
   * List Cities
   * @param params The `GisControllerService.ListCitiesUsingGETParams` containing the following parameters:
   *
   * - `regionCode`: regionCode
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `nameStart`: nameStart
   *
   * - `name`: name
   *
   * - `countryCode`: countryCode
   *
   * @return OK
   */
  listCitiesUsingGET(params: GisControllerService.ListCitiesUsingGETParams): __Observable<CityTOListResponse> {
    return this.listCitiesUsingGETResponse(params).pipe(
      __map(_r => _r.body as CityTOListResponse)
    );
  }

  /**
   * Find city by id
   * @param id id
   * @return OK
   */
  findCityByIdUsingGETResponse(id: number): __Observable<__StrictHttpResponse<CityTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/gis/cities/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CityTO>;
      })
    );
  }
  /**
   * Find city by id
   * @param id id
   * @return OK
   */
  findCityByIdUsingGET(id: number): __Observable<CityTO> {
    return this.findCityByIdUsingGETResponse(id).pipe(
      __map(_r => _r.body as CityTO)
    );
  }

  /**
   * Find city by Geo
   * @param params The `GisControllerService.FindCityByGeoUsingGETParams` containing the following parameters:
   *
   * - `lon`: lon
   *
   * - `lat`: lat
   *
   * @return OK
   */
  findCityByGeoUsingGETResponse(params: GisControllerService.FindCityByGeoUsingGETParams): __Observable<__StrictHttpResponse<CityTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/gis/cities/${encodeURIComponent(String(params.lon))}/${encodeURIComponent(String(params.lat))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CityTO>;
      })
    );
  }
  /**
   * Find city by Geo
   * @param params The `GisControllerService.FindCityByGeoUsingGETParams` containing the following parameters:
   *
   * - `lon`: lon
   *
   * - `lat`: lat
   *
   * @return OK
   */
  findCityByGeoUsingGET(params: GisControllerService.FindCityByGeoUsingGETParams): __Observable<CityTO> {
    return this.findCityByGeoUsingGETResponse(params).pipe(
      __map(_r => _r.body as CityTO)
    );
  }

  /**
   * List Countries
   * @param params The `GisControllerService.ListCountriesUsingGETParams` containing the following parameters:
   *
   * - `regionCode`: regionCode
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `nameStart`: nameStart
   *
   * - `name`: name
   *
   * - `countryCode`: countryCode
   *
   * - `containsCities`: containsCities
   *
   * @return OK
   */
  listCountriesUsingGETResponse(params: GisControllerService.ListCountriesUsingGETParams): __Observable<__StrictHttpResponse<CountryListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionCode != null) __params = __params.set('regionCode', params.regionCode.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.nameStart != null) __params = __params.set('nameStart', params.nameStart.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.countryCode != null) __params = __params.set('countryCode', params.countryCode.toString());
    if (params.containsCities != null) __params = __params.set('containsCities', params.containsCities.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/gis/countries/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CountryListResponse>;
      })
    );
  }
  /**
   * List Countries
   * @param params The `GisControllerService.ListCountriesUsingGETParams` containing the following parameters:
   *
   * - `regionCode`: regionCode
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `nameStart`: nameStart
   *
   * - `name`: name
   *
   * - `countryCode`: countryCode
   *
   * - `containsCities`: containsCities
   *
   * @return OK
   */
  listCountriesUsingGET(params: GisControllerService.ListCountriesUsingGETParams): __Observable<CountryListResponse> {
    return this.listCountriesUsingGETResponse(params).pipe(
      __map(_r => _r.body as CountryListResponse)
    );
  }

  /**
   * List Regions
   * @return OK
   */
  listRegionsUsingGETResponse(): __Observable<__StrictHttpResponse<RegionTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/gis/regions/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RegionTOListResponse>;
      })
    );
  }
  /**
   * List Regions
   * @return OK
   */
  listRegionsUsingGET(): __Observable<RegionTOListResponse> {
    return this.listRegionsUsingGETResponse().pipe(
      __map(_r => _r.body as RegionTOListResponse)
    );
  }

  /**
   * Find street
   * @param params The `GisControllerService.FindStreetUsingGETParams` containing the following parameters:
   *
   * - `name`:
   *
   * - `lon`:
   *
   * - `lat`:
   *
   * - `id`:
   *
   * @return OK
   */
  findStreetUsingGETResponse(params: GisControllerService.FindStreetUsingGETParams): __Observable<__StrictHttpResponse<StreetGeoJsonResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.lon != null) __params = __params.set('lon', params.lon.toString());
    if (params.lat != null) __params = __params.set('lat', params.lat.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/gis/streets`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StreetGeoJsonResponse>;
      })
    );
  }
  /**
   * Find street
   * @param params The `GisControllerService.FindStreetUsingGETParams` containing the following parameters:
   *
   * - `name`:
   *
   * - `lon`:
   *
   * - `lat`:
   *
   * - `id`:
   *
   * @return OK
   */
  findStreetUsingGET(params: GisControllerService.FindStreetUsingGETParams): __Observable<StreetGeoJsonResponse> {
    return this.findStreetUsingGETResponse(params).pipe(
      __map(_r => _r.body as StreetGeoJsonResponse)
    );
  }

  /**
   * Get bounding box of user's streets
   * @param accId accId
   * @return OK
   */
  streetsBoundingBoxUsingGETResponse(accId: number): __Observable<__StrictHttpResponse<BoundingBoxDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (accId != null) __params = __params.set('accId', accId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/gis/streets/bounding-box`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BoundingBoxDto>;
      })
    );
  }
  /**
   * Get bounding box of user's streets
   * @param accId accId
   * @return OK
   */
  streetsBoundingBoxUsingGET(accId: number): __Observable<BoundingBoxDto> {
    return this.streetsBoundingBoxUsingGETResponse(accId).pipe(
      __map(_r => _r.body as BoundingBoxDto)
    );
  }

  /**
   * List closest streets
   * @param params The `GisControllerService.ListClosestStreetsUsingGETParams` containing the following parameters:
   *
   * - `lon`: lon
   *
   * - `lat`: lat
   *
   * - `zones`: zones
   *
   * - `size`: size
   *
   * @return OK
   */
  listClosestStreetsUsingGETResponse(params: GisControllerService.ListClosestStreetsUsingGETParams): __Observable<__StrictHttpResponse<StreetTOExtendedListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.lon != null) __params = __params.set('lon', params.lon.toString());
    if (params.lat != null) __params = __params.set('lat', params.lat.toString());
    (params.zones || []).forEach(val => {if (val != null) __params = __params.append('zones', val.toString())});
    if (params.size != null) __params = __params.set('size', params.size.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/gis/streets/closest-to`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StreetTOExtendedListResponse>;
      })
    );
  }
  /**
   * List closest streets
   * @param params The `GisControllerService.ListClosestStreetsUsingGETParams` containing the following parameters:
   *
   * - `lon`: lon
   *
   * - `lat`: lat
   *
   * - `zones`: zones
   *
   * - `size`: size
   *
   * @return OK
   */
  listClosestStreetsUsingGET(params: GisControllerService.ListClosestStreetsUsingGETParams): __Observable<StreetTOExtendedListResponse> {
    return this.listClosestStreetsUsingGETResponse(params).pipe(
      __map(_r => _r.body as StreetTOExtendedListResponse)
    );
  }

  /**
   * List Streets
   * @param params The `GisControllerService.ListStreetsUsingGETParams` containing the following parameters:
   *
   * - `zones`: zones
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `nameStart`: nameStart
   *
   * - `name`: name
   *
   * - `cityId`: cityId
   *
   * @return OK
   */
  listStreetsUsingGETResponse(params: GisControllerService.ListStreetsUsingGETParams): __Observable<__StrictHttpResponse<List>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.zones || []).forEach(val => {if (val != null) __params = __params.append('zones', val.toString())});
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.nameStart != null) __params = __params.set('nameStart', params.nameStart.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.cityId != null) __params = __params.set('cityId', params.cityId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/gis/streets/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<List>;
      })
    );
  }
  /**
   * List Streets
   * @param params The `GisControllerService.ListStreetsUsingGETParams` containing the following parameters:
   *
   * - `zones`: zones
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `nameStart`: nameStart
   *
   * - `name`: name
   *
   * - `cityId`: cityId
   *
   * @return OK
   */
  listStreetsUsingGET(params: GisControllerService.ListStreetsUsingGETParams): __Observable<List> {
    return this.listStreetsUsingGETResponse(params).pipe(
      __map(_r => _r.body as List)
    );
  }

  /**
   * List similar streets
   * @param streetId streetId
   * @return OK
   */
  listSimilarStreetsUsingGETResponse(streetId: number): __Observable<__StrictHttpResponse<List>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/gis/streets/similar-to/${encodeURIComponent(String(streetId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<List>;
      })
    );
  }
  /**
   * List similar streets
   * @param streetId streetId
   * @return OK
   */
  listSimilarStreetsUsingGET(streetId: number): __Observable<List> {
    return this.listSimilarStreetsUsingGETResponse(streetId).pipe(
      __map(_r => _r.body as List)
    );
  }

  /**
   * Slice streets
   * @param params The `GisControllerService.SliceStreetsUsingGETParams` containing the following parameters:
   *
   * - `zones`:
   *
   * - `streetId`:
   *
   * - `sort`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `ownerIds`:
   *
   * - `ownerId`:
   *
   * - `nftMarketType`:
   *
   * - `nameStart`:
   *
   * - `name`:
   *
   * - `myCityId`:
   *
   * - `minPrice`:
   *
   * - `maxPrice`:
   *
   * - `marketIds`:
   *
   * - `ids`:
   *
   * - `countryCodes`:
   *
   * - `countryCode`:
   *
   * - `cityIds`:
   *
   * - `cityId`:
   *
   * @return OK
   */
  sliceStreetsUsingGETResponse(params: GisControllerService.SliceStreetsUsingGETParams): __Observable<__StrictHttpResponse<List>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.zones || []).forEach(val => {if (val != null) __params = __params.append('zones', val.toString())});
    if (params.streetId != null) __params = __params.set('streetId', params.streetId.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    (params.ownerIds || []).forEach(val => {if (val != null) __params = __params.append('ownerIds', val.toString())});
    if (params.ownerId != null) __params = __params.set('ownerId', params.ownerId.toString());
    if (params.nftMarketType != null) __params = __params.set('nftMarketType', params.nftMarketType.toString());
    if (params.nameStart != null) __params = __params.set('nameStart', params.nameStart.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.myCityId != null) __params = __params.set('myCityId', params.myCityId.toString());
    if (params.minPrice != null) __params = __params.set('minPrice', params.minPrice.toString());
    if (params.maxPrice != null) __params = __params.set('maxPrice', params.maxPrice.toString());
    (params.marketIds || []).forEach(val => {if (val != null) __params = __params.append('marketIds', val.toString())});
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.countryCodes || []).forEach(val => {if (val != null) __params = __params.append('countryCodes', val.toString())});
    if (params.countryCode != null) __params = __params.set('countryCode', params.countryCode.toString());
    (params.cityIds || []).forEach(val => {if (val != null) __params = __params.append('cityIds', val.toString())});
    if (params.cityId != null) __params = __params.set('cityId', params.cityId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/gis/streets/slice`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<List>;
      })
    );
  }
  /**
   * Slice streets
   * @param params The `GisControllerService.SliceStreetsUsingGETParams` containing the following parameters:
   *
   * - `zones`:
   *
   * - `streetId`:
   *
   * - `sort`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `ownerIds`:
   *
   * - `ownerId`:
   *
   * - `nftMarketType`:
   *
   * - `nameStart`:
   *
   * - `name`:
   *
   * - `myCityId`:
   *
   * - `minPrice`:
   *
   * - `maxPrice`:
   *
   * - `marketIds`:
   *
   * - `ids`:
   *
   * - `countryCodes`:
   *
   * - `countryCode`:
   *
   * - `cityIds`:
   *
   * - `cityId`:
   *
   * @return OK
   */
  sliceStreetsUsingGET(params: GisControllerService.SliceStreetsUsingGETParams): __Observable<List> {
    return this.sliceStreetsUsingGETResponse(params).pipe(
      __map(_r => _r.body as List)
    );
  }

  /**
   * suggestionForCollection
   * @param params The `GisControllerService.SuggestionForCollectionUsingGETParams` containing the following parameters:
   *
   * - `zone`: zone
   *
   * - `countriesCodes`: countriesCodes
   *
   * - `cityIds`: cityIds
   *
   * - `streetIds`: streetIds
   *
   * - `sort`: sort
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `marketType`: marketType
   *
   * @return OK
   */
  suggestionForCollectionUsingGETResponse(params: GisControllerService.SuggestionForCollectionUsingGETParams): __Observable<__StrictHttpResponse<List>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.zone != null) __params = __params.set('zone', params.zone.toString());
    (params.countriesCodes || []).forEach(val => {if (val != null) __params = __params.append('countriesCodes', val.toString())});
    (params.cityIds || []).forEach(val => {if (val != null) __params = __params.append('cityIds', val.toString())});
    (params.streetIds || []).forEach(val => {if (val != null) __params = __params.append('streetIds', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.marketType != null) __params = __params.set('marketType', params.marketType.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/gis/streets/suggestion-for-collection`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<List>;
      })
    );
  }
  /**
   * suggestionForCollection
   * @param params The `GisControllerService.SuggestionForCollectionUsingGETParams` containing the following parameters:
   *
   * - `zone`: zone
   *
   * - `countriesCodes`: countriesCodes
   *
   * - `cityIds`: cityIds
   *
   * - `streetIds`: streetIds
   *
   * - `sort`: sort
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `marketType`: marketType
   *
   * @return OK
   */
  suggestionForCollectionUsingGET(params: GisControllerService.SuggestionForCollectionUsingGETParams): __Observable<List> {
    return this.suggestionForCollectionUsingGETResponse(params).pipe(
      __map(_r => _r.body as List)
    );
  }

  /**
   * suggestionForCollection
   * @param form form
   * @return OK
   */
  suggestionForCollectionUsingPOSTResponse(form: SuggestionStreetForm): __Observable<__StrictHttpResponse<List>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/gis/streets/suggestion-for-collection`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<List>;
      })
    );
  }
  /**
   * suggestionForCollection
   * @param form form
   * @return OK
   */
  suggestionForCollectionUsingPOST(form: SuggestionStreetForm): __Observable<List> {
    return this.suggestionForCollectionUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as List)
    );
  }

  /**
   * suggestionForCollectionPublic
   * @param params The `GisControllerService.SuggestionForCollectionPublicUsingGETParams` containing the following parameters:
   *
   * - `zone`: zone
   *
   * - `countriesCodes`: countriesCodes
   *
   * - `cityIds`: cityIds
   *
   * - `streetIds`: streetIds
   *
   * - `sort`: sort
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `marketType`: marketType
   *
   * @return OK
   */
  suggestionForCollectionPublicUsingGETResponse(params: GisControllerService.SuggestionForCollectionPublicUsingGETParams): __Observable<__StrictHttpResponse<List>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.zone != null) __params = __params.set('zone', params.zone.toString());
    (params.countriesCodes || []).forEach(val => {if (val != null) __params = __params.append('countriesCodes', val.toString())});
    (params.cityIds || []).forEach(val => {if (val != null) __params = __params.append('cityIds', val.toString())});
    (params.streetIds || []).forEach(val => {if (val != null) __params = __params.append('streetIds', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.marketType != null) __params = __params.set('marketType', params.marketType.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/gis/streets/suggestion-for-collection/public`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<List>;
      })
    );
  }
  /**
   * suggestionForCollectionPublic
   * @param params The `GisControllerService.SuggestionForCollectionPublicUsingGETParams` containing the following parameters:
   *
   * - `zone`: zone
   *
   * - `countriesCodes`: countriesCodes
   *
   * - `cityIds`: cityIds
   *
   * - `streetIds`: streetIds
   *
   * - `sort`: sort
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `marketType`: marketType
   *
   * @return OK
   */
  suggestionForCollectionPublicUsingGET(params: GisControllerService.SuggestionForCollectionPublicUsingGETParams): __Observable<List> {
    return this.suggestionForCollectionPublicUsingGETResponse(params).pipe(
      __map(_r => _r.body as List)
    );
  }
}

module GisControllerService {

  /**
   * Parameters for getBuildingUsingGET
   */
  export interface GetBuildingUsingGETParams {
    lon?: number;
    lat?: number;
    id?: number;
  }

  /**
   * Parameters for listBuildingsUsingGET
   */
  export interface ListBuildingsUsingGETParams {
    zones?: Array<number>;
    streetIds?: Array<number>;
    street?: string;
    sort?: string;
    pageSize?: number;
    pageNumber?: number;
    ownerIds?: Array<number>;
    nftMarketType?: 'ALL_MARKETS' | 'PRIMARY' | 'SECONDARY';
    name?: string;
    myCityId?: number;
    minPrice?: number;
    maxPrice?: number;
    isWithdrawn?: boolean;
    ids?: Array<number>;
    countryCodes?: Array<string>;
    cityIds?: Array<number>;
  }

  /**
   * Parameters for sliceBuildingsUsingGET
   */
  export interface SliceBuildingsUsingGETParams {
    zones?: Array<number>;
    streetIds?: Array<number>;
    street?: string;
    sort?: string;
    pageSize?: number;
    pageNumber?: number;
    ownerIds?: Array<number>;
    nftMarketType?: 'ALL_MARKETS' | 'PRIMARY' | 'SECONDARY';
    name?: string;
    myCityId?: number;
    minPrice?: number;
    maxPrice?: number;
    isWithdrawn?: boolean;
    ids?: Array<number>;
    countryCodes?: Array<string>;
    cityIds?: Array<number>;
  }

  /**
   * Parameters for listCitiesUsingGET
   */
  export interface ListCitiesUsingGETParams {

    /**
     * regionCode
     */
    regionCode?: string;

    /**
     * pageSize
     */
    pageSize?: number;

    /**
     * pageNumber
     */
    pageNumber?: number;

    /**
     * nameStart
     */
    nameStart?: string;

    /**
     * name
     */
    name?: string;

    /**
     * countryCode
     */
    countryCode?: string;
  }

  /**
   * Parameters for findCityByGeoUsingGET
   */
  export interface FindCityByGeoUsingGETParams {

    /**
     * lon
     */
    lon: number;

    /**
     * lat
     */
    lat: number;
  }

  /**
   * Parameters for listCountriesUsingGET
   */
  export interface ListCountriesUsingGETParams {

    /**
     * regionCode
     */
    regionCode?: string;

    /**
     * pageSize
     */
    pageSize?: number;

    /**
     * pageNumber
     */
    pageNumber?: number;

    /**
     * nameStart
     */
    nameStart?: string;

    /**
     * name
     */
    name?: string;

    /**
     * countryCode
     */
    countryCode?: string;

    /**
     * containsCities
     */
    containsCities?: boolean;
  }

  /**
   * Parameters for findStreetUsingGET
   */
  export interface FindStreetUsingGETParams {
    name?: string;
    lon?: number;
    lat?: number;
    id?: number;
  }

  /**
   * Parameters for listClosestStreetsUsingGET
   */
  export interface ListClosestStreetsUsingGETParams {

    /**
     * lon
     */
    lon: number;

    /**
     * lat
     */
    lat: number;

    /**
     * zones
     */
    zones?: Array<number>;

    /**
     * size
     */
    size?: number;
  }

  /**
   * Parameters for listStreetsUsingGET
   */
  export interface ListStreetsUsingGETParams {

    /**
     * zones
     */
    zones?: Array<number>;

    /**
     * pageSize
     */
    pageSize?: number;

    /**
     * pageNumber
     */
    pageNumber?: number;

    /**
     * nameStart
     */
    nameStart?: string;

    /**
     * name
     */
    name?: string;

    /**
     * cityId
     */
    cityId?: number;
  }

  /**
   * Parameters for sliceStreetsUsingGET
   */
  export interface SliceStreetsUsingGETParams {
    zones?: Array<number>;
    streetId?: number;
    sort?: string;
    pageSize?: number;
    pageNumber?: number;
    ownerIds?: Array<number>;
    ownerId?: number;
    nftMarketType?: 'ALL_MARKETS' | 'PRIMARY' | 'SECONDARY';
    nameStart?: string;
    name?: string;
    myCityId?: number;
    minPrice?: number;
    maxPrice?: number;
    marketIds?: Array<number>;
    ids?: Array<number>;
    countryCodes?: Array<string>;
    countryCode?: string;
    cityIds?: Array<number>;
    cityId?: number;
  }

  /**
   * Parameters for suggestionForCollectionUsingGET
   */
  export interface SuggestionForCollectionUsingGETParams {

    /**
     * zone
     */
    zone: number;

    /**
     * countriesCodes
     */
    countriesCodes: Array<string>;

    /**
     * cityIds
     */
    cityIds: Array<number>;

    /**
     * streetIds
     */
    streetIds?: Array<number>;

    /**
     * sort
     */
    sort?: string;

    /**
     * pageSize
     */
    pageSize?: number;

    /**
     * pageNumber
     */
    pageNumber?: number;

    /**
     * marketType
     */
    marketType?: 'ALL_MARKETS' | 'PRIMARY' | 'SECONDARY';
  }

  /**
   * Parameters for suggestionForCollectionPublicUsingGET
   */
  export interface SuggestionForCollectionPublicUsingGETParams {

    /**
     * zone
     */
    zone: number;

    /**
     * countriesCodes
     */
    countriesCodes: Array<string>;

    /**
     * cityIds
     */
    cityIds: Array<number>;

    /**
     * streetIds
     */
    streetIds?: Array<number>;

    /**
     * sort
     */
    sort?: string;

    /**
     * pageSize
     */
    pageSize?: number;

    /**
     * pageNumber
     */
    pageNumber?: number;

    /**
     * marketType
     */
    marketType?: 'ALL_MARKETS' | 'PRIMARY' | 'SECONDARY';
  }
}

export { GisControllerService }
