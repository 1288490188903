import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { navigateByUrl } from 'core/utils/router.utils';
import { streetsNav } from 'streets/streets.navigation';

@Component({
  selector: 'app-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoDataComponent {
  @Input() title?: string;
  @Input() text = 'Go to map and buy the digital streets';
  @Input() buttonText?: string;
  @Input() canRedirect = true;
  @Input() redirectUrl = streetsNav.map();
  @Output() redirectEmitter = new EventEmitter();

  relocateToMap(): void {
    this.redirectEmitter.emit();
    navigateByUrl(this.redirectUrl);
  }
}
