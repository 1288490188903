import { AppIconName } from '../icons/icon.type';

export interface UIItemType<T> {
  name: LocalizedString | string;
  item: T;
  icon?: AppIconName;

  formValue(): T;
}

export class UIItem<T> implements UIItemType<T> {
  constructor(
    private itemName: LocalizedString | string,
    private itemValue: T,
    private itemIcon?: AppIconName
  ) {}

  get name(): LocalizedString | string {
    return this.itemName;
  }

  get item(): T {
    return this.itemValue;
  }

  get icon(): AppIconName | undefined {
    return this.itemIcon;
  }

  formValue(): T {
    return this.itemValue;
  }
}

export const formValue = (v: { formValue: () => unknown }): unknown =>
  v && typeof v.formValue === 'function' ? v.formValue() : v;

export type FormValue = Nominal<string, 'FormValue'>;
