import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NftHistoryTOList } from 'api/models';
import { PageControllerService, RequestData } from 'dashboard/services/page-controller.service';
import { StreetsService } from 'dashboard/services/streets.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class StreetDetailsService extends PageControllerService<null, null> {
  protected override pageSize = 6;
  protected override requestData$ = new BehaviorSubject<RequestData<null, null>>({
    pagination: {
      size: this.pageSize,
    },
    filter: null,
    sort: null,
  });

  constructor(private streetsService: StreetsService, protected route: ActivatedRoute) {
    super(route);
  }

  getHistory(id: number): Observable<NftHistoryTOList> {
    return this.getRequestData().pipe(
      switchMap(data =>
        this.streetsService.getNftHistory({
          nftId: id,
          pageNumber: data?.pagination?.pageNumber || 0,
          pageSize: this.pageSize,
        })
      ),
      map(data => data),
      catchError(() =>
        of({
          list: [],
          pageInfo: {},
        })
      )
    );
  }
}
