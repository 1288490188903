import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';

import { AppLocale } from './app-locale.service';
import { I18nService } from './i18n.service';

@Injectable()
export class I18nUrlSerializer implements UrlSerializer {
  private defaultUrlSerializer = new DefaultUrlSerializer();
  private isExplicitDefault: boolean;

  constructor(private locale: AppLocale, private i18n: I18nService, location: Location) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const serializer = this;
    this.isExplicitDefault = location.path().startsWith('/en');
    UrlTree.prototype.toString = function () {
      return serializer.serialize(this);
    };
  }

  parse(url: string): UrlTree {
    const query = (url.match(/\?.*/g) || [])[0] || '';
    url = url.replace(query, '');
    return this.defaultUrlSerializer.parse(`${this.locale.cleanUrl(url)}${query}`);
  }

  serialize(tree: UrlTree): string {
    const url = decodeURI(this.defaultUrlSerializer.serialize(tree));
    let code = this.i18n.language.code.toLowerCase();
    if (!this.isExplicitDefault && code === 'en') {
      code = '';
    }
    return `${code}${url}`;
  }
}
