import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { SelectComponent } from './select.component';

export interface SelectInputHost {
  multi?: boolean;
  changed: Observable<unknown | null>;
  onItemSelected(i: SelectItemDirective): void;
}

@Directive({
  selector: '[appSelectItem]',
})
export class SelectItemDirective implements OnDestroy, AfterViewInit {
  @Input('appSelectItem')
  value: unknown | undefined;

  changedSubj: Subscription | undefined;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private renderer: Renderer2,
    @Inject(SelectComponent) public selectComponent: SelectInputHost
  ) {}

  ngAfterViewInit(): void {
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.changedSubj = this.selectComponent.changed.subscribe(v => {
      if (!this.selectComponent.multi) {
        if (this.value === v) {
          this.renderer.addClass(this.elementRef.nativeElement, 'selected');
        } else {
          this.renderer.removeClass(this.elementRef.nativeElement, 'selected');
        }
      } else {
        const foundIndex = (v as unknown[]).findIndex(i => i === this.value);
        if (foundIndex >= 0) {
          this.renderer.addClass(this.elementRef.nativeElement, 'selected');
        } else {
          this.renderer.removeClass(this.elementRef.nativeElement, 'selected');
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.changedSubj) {
      this.changedSubj.unsubscribe();
    }
  }

  @HostListener('click')
  onClick(): void {
    this.selectComponent.onItemSelected(this);
  }
}
