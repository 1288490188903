import { AnimationDriver, ɵWebAnimationsDriver } from '@angular/animations/browser';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule, UrlSerializer } from '@angular/router';
import { FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import { ApiConfiguration } from 'api/api-configuration';
import { AuthInterceptor } from 'auth/interceptors/auth.interceptor';
import { CookiesBannerModule } from 'core/components/cookies-banner/cookies-banner.module';
import { IconModule } from 'core/components/icon/icon.module';
import { ToolbarOutletModule } from 'core/components/toolbar-outlet/toolbar-outlet.module';
import { provideI18nInitializer } from 'core/modules/i18n/i18n.initializer';
import { I18nUrlSerializer } from 'core/modules/i18n/i18n.serializer';
import { ModalModule, ModalRouter } from 'core/modules/modal';
import { PlatformModule } from 'core/modules/platform/platform.module';
import { MediaQuery } from 'core/modules/platform/services/media-query.service';
import { provideSocketConfig } from 'core/modules/socket/socket.config';
import { SocketModule } from 'core/modules/socket/socket.module';
import { AppUI } from 'core/services/app-ui.service';
import { CustomPreloadingStrategy } from 'core/services/custom-preload.strategy';
import { NavigationService } from 'core/services/navigation.service';
import { ScrollRestoration } from 'core/services/scroll-restoration.service';
import { ToasterModule } from 'core/toaster/toaster.module';
import { BASE_ACCOUNT_SERVICE_TOKEN } from 'core/tokens/base-account-service.token';
import { RouterBehavior } from 'core/utils/router.utils';
import { NFTBuyEventService } from 'dashboard/services/nft-buy-event.service';
import { environment } from 'environments/environment';
import { providers } from 'environments/providers/providers';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { provideUserInitializer } from 'profile/initializers/user.initializer';
import { AccountsService } from 'profile/services/accounts.service';
import * as smoothscroll from 'smoothscroll-polyfill';
import sassVariables from 'src/styles/shared/variables.json';

/////////
import { ErrorInterceptor } from './_main/interceptors/error.interceptor';
import { MainComponentsModule } from './_main/modules/main-components.module';
import { AppComponent } from './app.component';
import { provideAppInitializer } from './app.initializer';
import { appNav } from './app.navigation';
import { routes } from './app.routes';

export function animationFactory(): ɵWebAnimationsDriver {
  const wantToAnimate = !!document.body.animate;
  const driver = new ɵWebAnimationsDriver();
  // turn off animations for browsers that does not support Web Animations API
  if (!wantToAnimate) {
    driver.animate = AnimationDriver.NOOP.animate;
  }
  return driver;
}

// custom handler to reload page if lazy load modules overwritten after build
// and cannot be loaded
export function routerErrorHandler(err: Error): void {
  if (err.toString().match(/Error: Loading chunk/g)) {
    window.location.reload();
  } else {
    throw err;
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ModalModule,
    SocketModule,
    IconModule,
    CommonModule,
    PlatformModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      errorHandler: routerErrorHandler,
      preloadingStrategy: CustomPreloadingStrategy,
    }),
    HttpClientModule,
    MainComponentsModule,
    CookiesBannerModule,
    ToolbarOutletModule,
    ToasterModule.forRoot(),
  ],
  providers: [
    {
      provide: UrlSerializer,
      useClass: I18nUrlSerializer,
    },
    ...providers,
    provideI18nInitializer(),
    provideAppInitializer(),
    provideUserInitializer(),
    provideSocketConfig({ url: environment.wsServiceUrl }),
    {
      provide: BASE_ACCOUNT_SERVICE_TOKEN,
      useExisting: AccountsService,
      multi: false,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookAuthClientId, {}),
          },
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleAuthClientId, {
              scope: 'profile email',
            }),
          },
        ],
      },
    },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.reCaptchaKey },
    {
      provide: AnimationDriver,
      useFactory: animationFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: Window,
      useValue: window,
    },
    CustomPreloadingStrategy,
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    media: MediaQuery,
    router: Router,
    ui: AppUI,
    modal: ModalRouter,
    apiConfig: ApiConfiguration,
    navigation: NavigationService,
    scrollRestoration: ScrollRestoration,
    nftBuyEventService: NFTBuyEventService
  ) {
    apiConfig.rootUrl = environment.mainServiceUrl;
    smoothscroll.polyfill();
    ui.setup();
    media.init(sassVariables);
    modal.initRouter(router);
    navigation.init(appNav.initial());
    RouterBehavior.setup(router, scrollRestoration);
    nftBuyEventService.initNFTBuyEvent();
  }
}
