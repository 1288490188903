import { Component, Input } from '@angular/core';
import { iconProfile } from 'core/icons/lib/icon-profile';
import { Building } from 'dashboard/models/building.model';
import { Street } from 'dashboard/models/street.model';

@Component({
  selector: 'app-street-overview-details',
  templateUrl: './street-overview-details.component.html',
  styleUrls: ['./street-overview-details.component.scss'],
})
export class StreetOverviewDetailsComponent {
  @Input() street = new Street();
  @Input() building = new Building();

  readonly iconProfile = iconProfile;

  get buildingCategory(): string[] {
    const firstCategory = this.building.types.length !== 0 ? this.building.types[0] : '';
    return firstCategory.split(':').reduce((acum, item) => {
      if (item !== 'yes' && item !== 'building') {
        acum.push(item.replace('_', ' '));
      }
      return acum;
    }, [] as string[]);
  }
}
