/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UsePromoCodeTO } from '../models/use-promo-code-to';
import { PromoCodeForm } from '../models/promo-code-form';

/**
 * Promo Code Controller
 */
@Injectable({
  providedIn: 'root',
})
class PromoCodeControllerService extends __BaseService {
  static readonly transferUsingPOST1Path = '/api/v1/promo-code';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * transfer
   * @param form form
   * @return OK
   */
  transferUsingPOST1Response(form: PromoCodeForm): __Observable<__StrictHttpResponse<UsePromoCodeTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/promo-code`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UsePromoCodeTO>;
      })
    );
  }
  /**
   * transfer
   * @param form form
   * @return OK
   */
  transferUsingPOST1(form: PromoCodeForm): __Observable<UsePromoCodeTO> {
    return this.transferUsingPOST1Response(form).pipe(
      __map(_r => _r.body as UsePromoCodeTO)
    );
  }
}

module PromoCodeControllerService {
}

export { PromoCodeControllerService }
