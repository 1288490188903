import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NavigationLink } from 'app/_main/models/navigation-link';
import { FixedCardService } from 'app/_main/services/fixed-card.service';
import { AppComponent } from 'app/app.component';
import { appNav, lazyModalsNav } from 'app/app.navigation';
import { MarketExchanged } from 'app/exchange/models/market-statistic';
import { ExchangeMarketsService } from 'app/exchange/services/exchange-markets.service';
import { MarketplaceStreetsService } from 'app/marketplace/services/marketplace-streets.service';
import { SignUpParams } from 'auth/auth.navigation';
import { AuthService } from 'auth/services/auth.service';
import { CashierCryptoData } from 'cashier/cashier.navigation';
import { Wallet } from 'cashier/models/wallet.model';
import { WalletsService } from 'cashier/services/wallets.service';
import { iconAddSquareFill } from 'core/icons/lib/icon-add-square-fill';
import { iconAvatar } from 'core/icons/lib/icon-avatar';
import { iconBasket } from 'core/icons/lib/icon-basket';
import { iconBell } from 'core/icons/lib/icon-bell';
import { iconCashier } from 'core/icons/lib/icon-cashier';
import { iconCog } from 'core/icons/lib/icon-cog';
import { iconCubeScan } from 'core/icons/lib/icon-cube-scan';
import { iconDashboard } from 'core/icons/lib/icon-dashboard';
import { iconDiamond } from 'core/icons/lib/icon-diamond';
import { iconDiscord } from 'core/icons/lib/icon-discord';
import { iconDocuments } from 'core/icons/lib/icon-documents';
import { iconEllipsisV } from 'core/icons/lib/icon-ellipsis-v';
import { iconExchange } from 'core/icons/lib/icon-exchange';
import { iconHelp } from 'core/icons/lib/icon-help';
import { iconLogout } from 'core/icons/lib/icon-logout';
import { iconMacos } from 'core/icons/lib/icon-macos';
import { iconMapMarker } from 'core/icons/lib/icon-map-marker';
import { iconMenu } from 'core/icons/lib/icon-menu';
import { iconProfile } from 'core/icons/lib/icon-profile';
import { iconSettingsCircle } from 'core/icons/lib/icon-settings-circle';
import { iconShop } from 'core/icons/lib/icon-shop';
import { iconWindows } from 'core/icons/lib/icon-windows';
import { I18nService, LangUIItem } from 'core/modules/i18n/i18n.service';
import { ModalRouter } from 'core/modules/modal';
import { MediaQuery } from 'core/modules/platform/services/media-query.service';
import { AppUI } from 'core/services/app-ui.service';
import { CurrencyService } from 'core/services/currency.service';
import { GenericFormControl } from 'core/utils/form-generics';
import { navigateByUrl } from 'core/utils/router.utils';
import { CartService } from 'dashboard/services/cart.service';
import { ProfileNotificationsComponent } from 'profile/components/_ui/notifications/notifications.component';
import { BonusService } from 'profile/services/bonus.service';
import { NotificationService } from 'profile/services/notification.service';
import { UserService } from 'profile/services/user.service';
import { asyncScheduler, forkJoin, fromEvent, of, Subject } from 'rxjs';
import { filter, switchMap, takeUntil, throttleTime } from 'rxjs/operators';

import { CheckoutPageComponent } from '../checkout-page/checkout-page.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnDestroy, OnInit {
  @Input() disableBalance?: boolean;

  @HostBinding('class.fixed')
  @Input()
  allowFixed?: boolean;

  private preventMobileClose = false;
  private destroy$ = new Subject();

  readonly appNav = appNav;
  readonly userIcon = iconProfile;
  readonly settingsCircleIcon = iconSettingsCircle;
  readonly mapMarketIcon = iconMapMarker;
  readonly menuIcon = iconMenu;
  readonly logoutIcon = iconLogout;
  readonly documentsIcon = iconDocuments;
  readonly ellipsisIcon = iconEllipsisV;
  readonly dashboardIcon = iconDashboard;
  readonly bellIcon = iconBell;
  readonly profileIcon = iconCog;
  readonly discordIcon = iconDiscord;
  readonly cashierIcon = iconCashier;
  readonly helpIcon = iconHelp;
  readonly basketIcon = iconBasket;
  readonly bonusIcon = iconDiamond;
  readonly shopIcon = iconShop;
  readonly addSquare = iconAddSquareFill;
  readonly metaverseIcon = iconCubeScan;
  readonly exchangeIcon = iconExchange;
  readonly windowsIcon = iconWindows;
  readonly macosIcon = iconMacos;
  readonly avatarIcon = iconAvatar;

  @HostBinding('class.menu-opened')
  showMobileMenu = false;

  selectedLink = 0;
  isExchangePage = false;
  animateLogo?: boolean;
  defaultCurrency = new Wallet();
  largeHeader = true;
  largeLogo = true;
  showBalance = true;
  isSmallScreen?: boolean;

  localization = this.localizeService.languages.filter(
    i => i.iso2 !== this.localizeService.language.iso2
  );
  localizeFormControl = new GenericFormControl<LangUIItem>(this.localizeService.language);

  get isNotification(): boolean {
    return this.fixedCardService.isNotificationsActive;
  }

  get isCheckout(): boolean {
    return this.fixedCardService.isCheckoutActive;
  }

  get hideBonusScreen(): boolean {
    return this.app.hideBonusScreen;
  }

  navigationLinks: Array<NavigationLink> = [
    {
      icon: this.dashboardIcon,
      link: appNav.dashboardNav.dashboard(),
      isExact: false,
      text: 'main.header.dashboard',
      key: 'dashboard',
    },
    {
      icon: this.mapMarketIcon,
      link: appNav.streetsNav.map(),
      isExact: true,
      text: 'main.header.map',
      key: 'map',
    },
    {
      icon: this.shopIcon,
      link: appNav.marketplaceNav.marketplaceInit(),
      isExact: false,
      text: 'main.header.marketplace',
      key: 'marketplace',
    },
    {
      icon: this.documentsIcon,
      link: appNav.collectionsNav.allCollections(),
      isExact: false,
      text: 'main.header.collections',
      key: 'collections',
    },
    {
      icon: this.metaverseIcon,
      link: appNav.landingNav.application(),
      isExact: false,
      text: 'main.header.metaverse',
      key: 'metaverse',
    },
    /*    {
      icon: this.exchangeIcon,
      link: appNav.exchangeNav.exchangeMain(),
      isExact: false,
      text: 'main.header.exchange',
      key: 'exchange',
    },*/
  ];

  constructor(
    media: MediaQuery,
    appUi: AppUI,
    public bonusService: BonusService,
    public router: Router,
    public auth: AuthService,
    public fixedCardService: FixedCardService,
    public marketplaceService: MarketplaceStreetsService,
    public userService: UserService,
    public currencyService: CurrencyService,
    public walletsService: WalletsService,
    public notificationService: NotificationService,
    public elementRef: ElementRef<HTMLElement>,
    public cartService: CartService,
    public exchangeMarketsService: ExchangeMarketsService,
    private app: AppComponent,
    private modal: ModalRouter,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private localizeService: I18nService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.largeHeader = window.innerWidth > 1100;
    this.largeLogo = window.innerWidth > 1300;
    appUi.headerComponent = this;
    media.mediaChanged.pipe(takeUntil(this.destroy$)).subscribe(e => {
      if (e.new !== 'small' && this.showMobileMenu) {
        this.toggleMobileMenu();
      }
      if (e.new !== 'small') {
        this.showBalance = true;
        this.isSmallScreen = false;
      } else {
        this.showBalance = false;
        this.isSmallScreen = true;
      }
    });
    media.resized.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.largeHeader = window.innerWidth > 1100;
      this.largeLogo = window.innerWidth > 1300;
    });
    router.events.pipe(takeUntil(this.destroy$)).subscribe(e => {
      if (e instanceof NavigationStart) {
        if (this.showMobileMenu) {
          this.toggleMobileMenu();
        }
      }
    });
    router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe(event => {
        console.log('NavigationEnd');
        this.checkIsExchangePage(event.url);
        this.selectedLink = this.checkUrl(event.url);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.notificationService
      .loadMetrics()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {});
    this.checkIsExchangePage(this.router.url);
    this.selectedLink = this.checkUrl(this.router.url);
    this.auth.loggedIn
      .pipe(
        switchMap(() =>
          forkJoin([of(this.walletsService.wallets), of(this.walletsService.defaultWalletId)])
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(([_, defaultWalletId]) => {
        const wallet =
          this.walletsService.wallets.length !== 0
            ? this.walletsService.wallets.find(x => x.id === defaultWalletId) ||
              this.walletsService.dwrldWallet
            : this.walletsService.dwrldWallet;
        this.defaultCurrency = wallet;
      });

    fromEvent(window, 'scroll')
      .pipe(throttleTime(250, undefined, { trailing: true }), takeUntil(this.destroy$))
      .subscribe(() => {
        if (window.scrollY > 20) {
          this.renderer.addClass(this.elementRef.nativeElement, 'scrolled');
        } else {
          this.renderer.removeClass(this.elementRef.nativeElement, 'scrolled');
        }
      });
    fromEvent(window, 'mouseup')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.showMobileMenu && !this.preventMobileClose) {
          this.toggleMobileMenu();
        }
      });
  }

  setBonusHeader(): void {
    document.documentElement.style.setProperty('--header-height', '116px');
    document.documentElement.style.setProperty('--header-height-sm', '104px');
  }

  setDefaultHeader(): void {
    document.documentElement.style.setProperty('--header-height', '62px');
    document.documentElement.style.setProperty('--header-height-sm', '56px');
  }

  connectToCryptoAction(): void {
    this.modal.navigate(lazyModalsNav.cashier.connectToPrivateWallets());
  }

  changeMarket(market: MarketExchanged): void {
    this.exchangeMarketsService.currentMarketId$.next(market.id || 0);
  }

  openDiscordInviteLink(): void {
    window.open('https://discord.com/invite/qqWJmBwFcT', '_blank');
  }

  openDepositModal(): void {
    if (this.auth.isAuth) {
      this.modal.navigate(lazyModalsNav.cashier.buyDcoin());
    } else {
      this.openSignUp();
    }
  }

  private checkIsExchangePage(url: string): void {
    const currentUrl = url.split('/')[1] || '';
    const exchangeUrl = appNav.exchangeNav.exchangeHeader().split('/')[1] || '';
    this.isExchangePage = currentUrl === exchangeUrl;
  }
  private checkUrl(urlString: string): number {
    let indexReturned = -1;
    let currentUrl = urlString.split('/')[1] || '';
    const correctUrlKey = currentUrl.split('?');
    currentUrl = correctUrlKey[0];
    this.navigationLinks.forEach((item, index) => {
      if (item.key === currentUrl) {
        indexReturned = index;
      }
    });

    return indexReturned === -1 ? 0 : indexReturned;
  }

  changeDefaultCurrency(wallet: Wallet): void {
    this.walletsService.setDefaultCurrency(wallet).pipe(takeUntil(this.destroy$)).subscribe();
    this.defaultCurrency = wallet;
  }

  openDepositAction(): void {
    let id = this.defaultCurrency.id;
    if (this.defaultCurrency.currency.code === 'DPC') {
      id = this.walletsService.dwrldWallet.id;
    }
    if (this.defaultCurrency.currency.code === 'DWRLD') {
      this.modal.navigate(lazyModalsNav.cashier.buyDcoin());
    } else {
      this.modal.navigate(lazyModalsNav.cashier.crypto(), undefined, {
        data: { type: 'deposit', account: id } as CashierCryptoData,
      });
    }
  }

  openBuyDwrld(): void {
    this.modal.navigate(lazyModalsNav.cashier.buyDcoin());
  }

  stopClose(): void {
    this.preventMobileClose = true;
    asyncScheduler.schedule(() => {
      this.preventMobileClose = false;
    }, 250);
  }

  logout(): void {
    this.auth
      .logout()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        navigateByUrl(appNav.landingNav.root());
      });
  }

  openSignUp(): void {
    this.modal.navigate(lazyModalsNav.auth.signUp(), undefined, {
      data: this.route.snapshot.queryParams as SignUpParams,
    });
  }

  openSignIn(): void {
    this.modal.navigate(lazyModalsNav.auth.signIn());
  }

  openNotifications(): void {
    if (this.showMobileMenu) {
      this.toggleMobileMenu();
    }

    if (this.isNotification) {
      this.fixedCardService.hideCard();
      this.fixedCardService.setIsNotificationsActive(false);
    } else {
      this.fixedCardService.openCard(ProfileNotificationsComponent);
      this.fixedCardService.setIsNotificationsActive(true);
      this.fixedCardService.setIsCheckoutActive(false);
    }
  }

  openProfile(): void {
    navigateByUrl(appNav.profileNav.root());
  }

  openCheckout(): void {
    if (this.isCheckout) {
      this.fixedCardService.hideCard();
      this.fixedCardService.setIsCheckoutActive(false);
    } else {
      this.fixedCardService.openCard(CheckoutPageComponent);
      this.fixedCardService.setIsCheckoutActive(true);
      this.fixedCardService.setIsNotificationsActive(false);
    }
  }

  toggleMobileMenu(): void {
    this.showMobileMenu = !this.showMobileMenu;
    if (this.showMobileMenu) {
      this.renderer.setStyle(this.elementRef.nativeElement, 'z-index', '1000');
      this.renderer.setStyle(this.document.body, 'overflow', 'hidden');
      this.renderer.addClass(this.document.body, 'overlay-on');
    } else {
      this.renderer.setStyle(this.document.body, 'overflow', '');
      this.renderer.removeClass(this.document.body, 'overlay-on');
      asyncScheduler.schedule(() => {
        this.renderer.setStyle(this.elementRef.nativeElement, 'z-index', '');
      }, 400);
    }
  }

  changeLanguage(language: LangUIItem): void {
    this.localizeService.change(language.code);
  }
}
