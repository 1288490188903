/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UserDefaultWalletDto } from '../models/user-default-wallet-dto';

/**
 * User Default Wallet Controller
 */
@Injectable({
  providedIn: 'root',
})
class UserDefaultWalletControllerService extends __BaseService {
  static readonly getForCurrentUserOrCreateDefaultUsingGETPath = '/user-default-wallet';
  static readonly saveUsingPOST1Path = '/user-default-wallet';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * getForCurrentUserOrCreateDefault
   * @return OK
   */
  getForCurrentUserOrCreateDefaultUsingGETResponse(): __Observable<__StrictHttpResponse<UserDefaultWalletDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/user-default-wallet`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserDefaultWalletDto>;
      })
    );
  }
  /**
   * getForCurrentUserOrCreateDefault
   * @return OK
   */
  getForCurrentUserOrCreateDefaultUsingGET(): __Observable<UserDefaultWalletDto> {
    return this.getForCurrentUserOrCreateDefaultUsingGETResponse().pipe(
      __map(_r => _r.body as UserDefaultWalletDto)
    );
  }

  /**
   * save
   * @param form form
   * @return OK
   */
  saveUsingPOST1Response(form: UserDefaultWalletDto): __Observable<__StrictHttpResponse<UserDefaultWalletDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/user-default-wallet`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserDefaultWalletDto>;
      })
    );
  }
  /**
   * save
   * @param form form
   * @return OK
   */
  saveUsingPOST1(form: UserDefaultWalletDto): __Observable<UserDefaultWalletDto> {
    return this.saveUsingPOST1Response(form).pipe(
      __map(_r => _r.body as UserDefaultWalletDto)
    );
  }
}

module UserDefaultWalletControllerService {
}

export { UserDefaultWalletControllerService }
