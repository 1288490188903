import { Injectable } from '@angular/core';
import { ApplicationSettingsTOResponse } from 'api/models';
import { HealthCheckControllerService, SettingsControllerService } from 'api/services';
import { Observable, of, throwError } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApplicationSettingsService {
  private _settings: ApplicationSettingsTOResponse = {};

  get settings(): ApplicationSettingsTOResponse {
    return this._settings;
  }

  constructor(
    private settingsController: SettingsControllerService,
    private healthCheckService: HealthCheckControllerService
  ) {}

  load(): Observable<ApplicationSettingsTOResponse> {
    return this.settingsController.settingsUsingGET().pipe(
      tap(data => {
        this._settings = data;
      })
    );
  }

  healthCheck(): Observable<{ checked: boolean }> {
    const coreServices = ['GisService', 'CurrencyConvertor', 'PrivateAPI'];
    return this.healthCheckService.checkServicesUsingGET().pipe(
      mergeMap(res => {
        console.log(res);
        if (res.failedServices?.some(r => coreServices.includes(r))) {
          return throwError(new Error('Health-check failed'));
        }
        return of({ checked: true });
      })
    );
  }
}
