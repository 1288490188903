import { Component, OnDestroy, OnInit } from '@angular/core';
import { NftOrderHistoryTO, PageTO } from 'api/models';
import { BasePageComponent } from 'app/_main/modules/components/base-page/base-page.component';
import {
  HistoryFilterParams,
  MarketplaceHistoryService,
} from 'app/marketplace/services/marketplace-history.service';
import { SortHeaderItem } from 'core/components/list-components/sorting.model';
import { iconFilter } from 'core/icons/lib/icon-filter';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  templateUrl: './history-page.component.html',
  styleUrls: [
    './history-page.component.scss',
    '../../../dashboard/components/_styles/dashboard-layout.scss',
  ],
})
export class HistoryPageComponent extends BasePageComponent implements OnInit, OnDestroy {
  inProgress?: boolean;
  filterIcon = iconFilter;
  page: PageTO = {
    offset: 0,
    totalPages: 0,
    totalElements: 0,
    pageNumber: 1,
  };
  headers: SortHeaderItem[] = [
    {
      label: $t('dashboard.history.column.asset'),
      key: 'name',
      itemClass: 'history-asset',
    },
    {
      label: $t('dashboard.history.column.type'),
      key: '',
      itemClass: 'history-type',
    },
    {
      label: $t('dashboard.history.column.level'),
      key: 'zoneType',
      itemClass: 'history-level',
    },
    {
      label: $t('dashboard.history.column.order'),
      key: 'id',
      itemClass: 'history-order',
    },
    {
      label: $t('dashboard.history.column.balance'),
      key: 'avgPrice',
      itemClass: 'history-balance',
    },
    {
      label: $t('dashboard.history.column.date'),
      key: 'closeAt',
      itemClass: 'history-date',
    },
    {
      label: $t('dashboard.history.column.status'),
      key: 'side',
      itemClass: 'history-status',
    },
  ];
  submitted?: boolean;

  readonly skeletonItems = Array<NftOrderHistoryTO>(10).fill({});

  constructor(private historyService: MarketplaceHistoryService) {
    super();
    this.setTopOffset(120);
  }

  get history(): NftOrderHistoryTO[] {
    return this.historyService.history;
  }

  ngOnInit(): void {
    this.historyService
      .initQueryParamsListener()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(requestData => {
        this.page = requestData.pagination;
      });
    this.getData();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.historyService.clearParams();
  }

  applySortAction(event: string | undefined): void {
    this.historyService.setSortParams(event || '');
  }

  identifyHistory(_: number, item: NftOrderHistoryTO): number {
    return item?.id || 0;
  }

  handleFilter(params: HistoryFilterParams): void {
    this.historyService.setFilterParams(params);
  }

  nextPageAction(pageNumber: number): void {
    this.historyService.setPaginationParams({
      pageNumber: pageNumber - 1,
    });
    this.scrollToTop();
  }

  private getData(): void {
    this.inProgress = true;
    this.historyService
      .load()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        data => {
          this.inProgress = false;
          this.page = data.pageInfo || {};
          if (this.page.pageNumber) {
            this.page.pageNumber++;
          }
        },
        () => {
          this.inProgress = false;
        }
      );
  }
}
