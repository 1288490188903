import {
  ChangeDetectorRef,
  Directive,
  EmbeddedViewRef,
  EventEmitter,
  HostListener,
  Inject,
  InjectionToken,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { MediaQuery } from 'core/modules/platform/services/media-query.service';
import { asyncScheduler, Subject } from 'rxjs';
import { takeUntil, throttleTime } from 'rxjs/operators';

export const INITIAL_ITEM_SIZE = new InjectionToken<string>('InitialItemSize');

@Directive({
  selector: '[appVirtualFor]',
})
export class VirtualForDirective implements OnChanges, OnInit, OnDestroy {
  @Input()
  appVirtualFor!: Array<unknown> | string;
  @Input()
  itemSizes: [string, number, number?] = ['any', 0];
  @Input()
  itemSizesRef?: HTMLElement;
  @Input()
  type: 'grid' | 'column' = 'column';
  @Input()
  containerRef!: HTMLElement;
  @Input()
  virtualContainerRef!: HTMLElement;
  @Input()
  overflow = 0;
  @Input()
  offsetTop = 0;
  @Input()
  paddingBottom = 0;
  @Input()
  trackBy = 0;

  @Output()
  itemSizeChanged = new EventEmitter();

  private itemSize = 0;
  private fitCount = 0;
  private columns = 1;
  private renderIndex = 0;
  private destroy$ = new Subject();
  private views: { value: EmbeddedViewRef<unknown>; index: number; rendered: boolean }[] = [];
  private containerHeight = 0;

  constructor(
    private container: ViewContainerRef,
    private template: TemplateRef<unknown>,
    private renderer: Renderer2,
    private media: MediaQuery,
    private chRef: ChangeDetectorRef,
    @Optional()
    @Inject(INITIAL_ITEM_SIZE)
    public itemSizeProvider: { initialItemSize: number } = { initialItemSize: 0 }
  ) {}

  ngOnDestroy(): void {
    this.renderer.setStyle(this.containerRef, 'min-height', '');
    this.renderer.setStyle(this.virtualContainerRef, 'transform', '');
    this.views.forEach(v => v.value.destroy());
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    let height = window.innerHeight;
    this.media.resized
      .pipe(throttleTime(150, undefined, { trailing: true }), takeUntil(this.destroy$))
      .subscribe(() => {
        if (Math.abs(height - window.innerHeight) < 100) {
          height = window.innerHeight;
          return;
        }
        height = window.innerHeight;
        this.updateItemSize();
        this.updateContainerHeight();
        this.renderItems();
      });
  }

  updateContainerHeight(): void {
    this.containerHeight =
      this.itemSize * Math.ceil(this.appVirtualFor.length / this.columns) + this.paddingBottom;
    this.renderer.setStyle(this.containerRef, 'min-height', `${this.containerHeight}px`);
  }

  ngOnChanges(changes: SimpleChanges<VirtualForDirective>) {
    if (changes.appVirtualFor) {
      // need to execute in next frame in case of itemSizesRef
      if (
        changes.appVirtualFor.firstChange &&
        this.itemSizesRef &&
        !this.itemSizeProvider.initialItemSize
      ) {
        asyncScheduler.schedule(() => {
          this.updateItemSize();
          this.updateContainerHeight();
          this.renderItems();
        });
      } else {
        this.updateItemSize();
        this.updateContainerHeight();
        this.renderItems();
      }
    }
    if (changes.trackBy && !changes.appVirtualFor) {
      this.updateContainerHeight();
      this.handleScroll();
    }
  }

  private updateItemSize(): void {
    if (this.itemSizesRef) {
      if (this.itemSizeProvider.initialItemSize) {
        this.updateColumnsCount(this.itemSizesRef);
        this.itemSize = this.itemSizeProvider.initialItemSize;
        this.itemSizeProvider = { initialItemSize: 0 };
      } else if (this.type === 'column') {
        this.itemSize = this.itemSizesRef.clientHeight;
      } else {
        this.updateColumnsCount(this.itemSizesRef);
        this.itemSize = this.itemSizesRef.clientHeight;
      }
    } else {
      if (this.itemSizes[0] === 'any') {
        this.itemSize = this.itemSizes[1];
      } else if (this.media.is(this.itemSizes[0])) {
        this.itemSize = this.itemSizes[1];
      } else {
        this.itemSize = this.itemSizes[2] || 0;
      }
    }
    this.itemSizeChanged.next(this.itemSize);
  }

  private updateColumnsCount(itemSizeRef: HTMLElement): void {
    const childWidth = itemSizeRef.firstElementChild?.clientWidth || 0;
    const containerWidth = this.containerRef.clientWidth;
    this.columns = Math.floor(containerWidth / childWidth);
  }

  private updateItems(): void {
    const renderTo = this.renderIndex + this.fitCount;
    const views = [...this.views];
    const newItems: number[] = [];
    for (let i = this.renderIndex; i <= renderTo; i++) {
      for (let k = 0; k < this.columns; k++) {
        const index = i * this.columns + k;
        const e = this.appVirtualFor[index];
        const j = views.findIndex(v => (v.value.context as { $implicit: unknown }).$implicit === e);
        if (j >= 0) {
          views[j].index = index;
          views[j].rendered = true;
          views.splice(j, 1);
        } else {
          newItems.push(index);
        }
      }
    }
    views.forEach((v, i) => {
      const index = newItems[i];
      v.index = index;
      if (index < this.appVirtualFor.length) {
        (v.value.context as { $implicit: unknown }).$implicit = this.appVirtualFor[index];
        v.rendered = true;
      } else {
        v.rendered = false;
      }
    });
    this.views.sort((a, b) => a.index - b.index);
    this.views.forEach((v, i) => {
      if (v.rendered) {
        this.renderer.setStyle(v.value.rootNodes[0], 'display', '');
      } else {
        this.renderer.setStyle(v.value.rootNodes[0], 'display', 'none');
      }
      this.container.move(v.value, i);
    });
    this.applyTransform();
  }

  private applyTransform(): void {
    this.renderer.setStyle(
      this.virtualContainerRef,
      'transform',
      `translateY(${this.itemSize * this.renderIndex}px)`
    );
  }

  private renderItems(): void {
    const scrollY = Math.max(0, window.scrollY - this.offsetTop - this.overflow - this.itemSize);
    this.fitCount = Math.ceil((window.innerHeight + this.overflow * 2) / this.itemSize);
    this.renderIndex = Math.ceil(scrollY / this.itemSize);
    const renderTo = this.renderIndex + this.fitCount;
    this.container.clear();
    if (this.appVirtualFor.length === 0) {
      return;
    }
    this.views = [];
    let j = 0;
    for (let i = this.renderIndex; i <= renderTo; i++) {
      for (let k = 0; k < this.columns; k++) {
        const index = i * this.columns + k;
        this.createView(index, j++);
      }
    }
    this.applyTransform();
    this.chRef.detectChanges();
  }

  private createView(index: number, viewIndex: number) {
    const e = this.appVirtualFor[index];
    let view: EmbeddedViewRef<unknown>;
    if (e) {
      view = this.container.createEmbeddedView(this.template, {
        $implicit: e,
        index: viewIndex,
      });
    } else {
      view = this.container.createEmbeddedView(this.template, {
        $implicit: this.appVirtualFor[0],
        index: viewIndex,
      });
      this.renderer.setStyle(view.rootNodes[0], 'display', 'none');
    }
    this.views.push({ value: view, index, rendered: !!e });
  }

  @HostListener('window:scroll')
  windowScrollHandler(): void {
    this.handleScroll();
  }

  private handleScroll(): void {
    const scrollY = Math.max(0, window.scrollY - this.offsetTop - this.overflow - this.itemSize);
    const from = Math.ceil(scrollY / this.itemSize);
    if (from !== this.renderIndex && this.appVirtualFor.length > 0) {
      this.renderIndex = from;
      this.updateItems();
      this.chRef.detectChanges();
    }
  }
}
