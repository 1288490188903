import { Component, ElementRef } from '@angular/core';
import { tooltipAnimation } from 'core/components/tooltip/tooltip.animation';
import { TooltipComponent } from 'core/components/tooltip/tooltip.component';

@Component({
  selector: 'app-tooltip-frequency',
  templateUrl: './tooltip-frequency.component.html',
  styleUrls: ['../tooltip.component.scss', './tooltip-frequency.component.scss'],
  animations: [tooltipAnimation],
})
export class TooltipFrequencyComponent extends TooltipComponent {
  constructor(public elementRef: ElementRef<HTMLElement>) {
    super(elementRef);
  }
}
