import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NftTO, PageTO } from 'api/models';
import { GisControllerService } from 'api/services/gis-controller.service';
import { NftControllerService } from 'api/services/nft-controller.service';
import { StateClearService, StateFullService } from 'app/_main/services/state-clear.service';
import { AuthService } from 'auth/services/auth.service';
import { delayAtLeast } from 'core/utils/rxjs/delay-at-least.operator';
import { NftFilterPlanForm } from 'dashboard/forms/nft-plan-filter.form';
import { Building } from 'dashboard/models/building.model';
import {
  convertToNftZones,
  convertToQueryZones,
  STREET_LEVELS,
} from 'dashboard/models/street.data';
import { BuildingsFactory } from 'dashboard/services/buildings.factory';
import { PageControllerService, RequestData } from 'dashboard/services/page-controller.service';
import { UserService } from 'profile/services/user.service';
import { defer, Observable, of } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import { GeolocationService } from 'streets/services/geolocation.service';

import {
  marketplaceMarketTypes,
  MarketplaceStreetsFilters,
} from '../forms/marketplace-streets.form';

export interface MarketplaceBuildings {
  elements: Building[];
  pageInfo?: PageTO;
}

@Injectable({
  providedIn: 'root',
})
export class MarketplaceBuildingsService
  extends PageControllerService<MarketplaceStreetsFilters, string>
  implements StateFullService
{
  isInitial = true;
  buildings: Building[] = [];
  queryParams: Params = {};
  protected pageSize = 30;
  private lastPage?: number;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    private authService: AuthService,
    private gisController: GisControllerService,
    private buildingFactory: BuildingsFactory,
    private nftApi: NftControllerService,
    private geolocation: GeolocationService,
    protected activatedRoute: ActivatedRoute,
    private gisAPI: GisControllerService,
    private userService: UserService,
    private stateClearService: StateClearService
  ) {
    super(route, router);
    this.stateClearService.register(this);
  }

  get isQueryParamsEmpty(): boolean {
    return Object.keys(this.queryParams).length === 0;
  }

  clearState(): void {
    this.queryParams = {};
    this.buildings = [];
    this.lastPage = undefined;
    this.clearParams();
  }

  override getQueryParams(): Observable<RequestData<MarketplaceStreetsFilters, string>> {
    return this.route.queryParams.pipe(
      tap((queryParams: Params) => {
        if (!Object.keys(queryParams).length) {
          this.replaceQueryParams({
            ...this.requestData$.value,
            filter: {
              cityId: this.geolocation.data.cityId,
              countryCode: this.geolocation.data.countryCode,
            },
          });
        }
      }),
      filter(data => Boolean(Object.keys(data).length)),
      map(queryParams => this.convertToRequestData(queryParams))
    );
  }

  getMarketplaceBuildings(): Observable<MarketplaceBuildings> {
    return this.getRequestData().pipe(
      debounceTime(0),
      switchMap(data =>
        defer(() => {
          const params: GisControllerService.SliceBuildingsUsingGETParams = {
            sort: data.sort,
            minPrice: data.filter.price?.fromPrice,
            maxPrice: data.filter.price?.toPrice,
            street: data.filter.name,
            // description: data.filter.nameStart,
            zones: this.convertZones(data.filter.zones),
            pageNumber: data.pagination.pageNumber,
            pageSize: this.pageSize,
            nftMarketType: data.filter.nftMarketType?.item,
            countryCodes: !this.isQueryParamsEmpty
              ? [data.filter.countryCode || '']
              : [this.geolocation.data.countryCode || ''],
            cityIds: !this.isQueryParamsEmpty
              ? [data.filter.cityId || 0]
              : [this.geolocation.data.cityId || 0],
          };
          if (params?.sort?.startsWith('name,') && data.filter.hasMatching) {
            params.sort = params.sort.replace('name,', 'building,');
          }

          if (this.authService.isAuth && data.filter.hasMatching) {
            params.sort = params?.sort?.replace('price,', 'buyingPrice,');
          }

          if (this.authService.isAuth && data.filter.hasMatching) {
            params.ownerIds = [this.userService.user.accountId];
          } else {
            params.ownerIds = undefined;
          }
          return this.gisController.sliceBuildingsUsingGET(params);
        }).pipe(
          delayAtLeast(1200),
          map(response => {
            this.isInitial = false;
            if (this.lastPage !== response.pageInfo?.pageNumber || !this.buildings.length) {
              this.buildings.push(
                ...(response.elements || []).map(building => {
                  if (!(building as NftTO).collectionStatusFromBlockchain) {
                    (building as NftTO).id = building.id;
                  }
                  // TODO: figure out what happening
                  // (building as NftTO).sellPrice = (building as BuildingTOResponse).price;
                  return this.buildingFactory.get((building as NftTO).id, building);
                })
              );
            }
            this.lastPage = response.pageInfo?.pageNumber;
            return {
              ...response,
              elements: this.buildings,
            };
          }),
          catchError(() =>
            of({
              elements: [],
              pageInfo: {},
            })
          )
        )
      )
    );
  }

  setFilterParams(params: MarketplaceStreetsFilters): void {
    this.buildings = [];
    this.requestData$.value.pagination.pageNumber = 0;
    super.setFilterParams(params);
    if (window.scrollY > 118) {
      window.scrollTo(0, 118);
    }
  }

  setSortParams(params: string): void {
    this.buildings = [];
    this.requestData$.value.pagination.pageNumber = 0;
    super.setSortParams(params);
    if (window.scrollY > 118) {
      window.scrollTo(0, 118);
    }
  }

  reloadData(): void {
    this.buildings = [];
    this.requestData$.value.pagination.pageNumber = 0;
    this.requestData$.next(this.requestData$.value);
    if (window.scrollY > 118) {
      window.scrollTo(0, 118);
    }
  }

  saveQueryParams(params: Params): void {
    this.queryParams = params;
  }

  protected override convertToRequestData(
    queryParams: Partial<Record<string, string>>
  ): RequestData<MarketplaceStreetsFilters, string> {
    const {
      sort,
      pageNumber,
      zones,
      name,
      fromPrice,
      toPrice,
      countryCode,
      cityId,
      nftMarketType,
      hasMatching,
    } = queryParams || {};
    return {
      sort: sort || '',
      filter: {
        name,
        price: {
          fromPrice: fromPrice ? +fromPrice : undefined,
          toPrice: toPrice ? +toPrice : undefined,
        },
        hasMatching: hasMatching === 'true' ? true : undefined,
        zones: convertToNftZones(zones || ''),
        countryCode,
        cityId: cityId ? +cityId : undefined,
        nftMarketType: marketplaceMarketTypes.find(type => type.item === nftMarketType),
      },
      pagination: {
        ...this.requestData$.getValue().pagination,
        pageNumber: pageNumber ? +pageNumber : this.requestData$.getValue().pagination.pageNumber,
      },
    };
  }

  protected override createQueryParams(
    data: RequestData<MarketplaceStreetsFilters, string>
  ): Params {
    const { sort, pagination, filter } = data;
    const queryParams = {
      sort: sort || null,
      pageNumber: pagination.pageNumber || null,
      name: filter.name || null,
      cityId: filter.cityId || null,
      countryCode: filter.countryCode || null,
      fromPrice: filter.price?.fromPrice || null,
      nftMarketType: filter.nftMarketType?.item || null,
      toPrice: filter.price?.toPrice || null,
      hasMatching: filter.hasMatching || false,
      zones: filter.zones ? convertToQueryZones(filter.zones) : null,
    };
    return queryParams;
  }

  private convertZones(zones?: NftFilterPlanForm): number[] {
    if (!zones) return [1, 2, 3, 4, 5];

    const levels = [
      { ...STREET_LEVELS.plan1, control: zones.isBasic },
      { ...STREET_LEVELS.plan2, control: zones.isStandard },
      { ...STREET_LEVELS.plan3, control: zones.isPremium },
      { ...STREET_LEVELS.plan4, control: zones.isElite },
      { ...STREET_LEVELS.plan5, control: zones.isUnique },
    ];

    return levels.filter(item => item?.control).map(item => item.zone);
  }
}
