import { Injectable } from '@angular/core';
import { BasketPurchaseResultTOListResponse } from 'api/models/basket-purchase-result-tolist-response';
import { ShoppingBasketTOResponse } from 'api/models/shopping-basket-toresponse';
import { ShoppingBasketControllerService } from 'api/services';
import { Collection } from 'app/collections/models/collection.model';
import { CollectionFactory } from 'app/collections/services/collection.factory';
import { CurrencyService } from 'core/services/currency.service';
import { FbService } from 'core/services/fb.service';
import { GTMService } from 'core/services/gtm.service';
import { Building } from 'dashboard/models/building.model';
import { CartItem } from 'dashboard/models/cart-item.model';
import { Street } from 'dashboard/models/street.model';
import { AccountsService } from 'profile/services/accounts.service';
import { UserService } from 'profile/services/user.service';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GeolocationService } from 'streets/services/geolocation.service';

import { BuildingsFactory } from './buildings.factory';
import { StreetsFactory } from './streets.factory';

export type GTMEventType = 'remove_from_cart' | 'add_to_cart' | 'purchased';
export type GTMActionType = 'added' | 'removed' | 'purchased';
export type GTMEntityType = 'building' | 'street' | 'collection';

export interface CheckoutGTMTagConfigData {
  event: GTMEventType;
  country: string;
  city: string;
  action: GTMActionType;
  type: GTMEntityType;
  label: string;
  amount: number;
  name: string;
}

@Injectable({ providedIn: 'root' })
export class CartService {
  items: CartItem[] = [];

  get totalBonuses(): number {
    return this.bonuses;
  }
  private bonuses = 0;

  get totalPrice(): number {
    return this.price;
  }
  private price = 0;

  get length(): number {
    return this.itemsCount;
  }
  private itemsCount = 0;

  constructor(
    private cartApi: ShoppingBasketControllerService,
    private accountsService: AccountsService,
    private collectionFactory: CollectionFactory,
    private buildingFactory: BuildingsFactory,
    private streetFactory: StreetsFactory,
    private currencyService: CurrencyService,
    private geolocation: GeolocationService,
    private gtmService: GTMService,
    private FbService: FbService,
    private userService: UserService
  ) {}

  load(): Observable<ShoppingBasketTOResponse[]> {
    return this.cartApi.listUsingGET18().pipe(
      tap(res => {
        this.items = [];
        res.forEach(i => {
          const data = Object.assign(i.info || {}, {
            cartId: i.id || 0,
            streetId: i.objectId,
            countries: [{ name: i.info?.country }],
            metaInformation: i.info,
          });
          const item =
            i.type === 'COLLECTION'
              ? this.collectionFactory.get(i.info?.id, Object.assign(data, { id: i.objectId }))
              : i.type === 'STREET'
              ? this.streetFactory.get(i.objectId, Object.assign(data, { id: undefined }))
              : this.buildingFactory.get(i.objectId, data);
          const nestedItems: (Street | Building)[] = [];
          i.elements?.forEach(c => {
            const data = Object.assign(c.info || {}, { cartId: c.id || 0, streetId: c.objectId });
            const object = (c.type === 'BUILDING' ? this.buildingFactory : this.streetFactory).get(
              c.objectId,
              Object.assign(data, { id: undefined })
            );
            nestedItems.push(object);
          });
          this.items.push(
            new CartItem({
              object: item,
              id: i.id || 0,
              nestedItems,
            })
          );
        });
        this.updateCounters();
      })
    );
  }

  private updateCounters(): void {
    this.price = this.bonuses = 0;
    this.itemsCount = this.items.length;
    this.items.forEach(i => {
      if (i.nestedItems.length) {
        this.itemsCount += i.nestedItems.length - 1 + (i.collection ? 0 : 1);

        if (!i.collection) {
          i.nestedItems.forEach(item => {
            this.price += item.lastPrice;
          });
        }
      }

      this.price += i.price;
    });
    this.bonuses = this.price * ((this.accountsService.activeProps.decentBonusCashback || 0) / 100);
  }

  addToCollection(
    collection: Collection,
    items: Street[] = []
  ): Observable<ShoppingBasketTOResponse> {
    return this.cartApi
      .createUsingPOST3({
        objectId: collection.id,
        type: collection instanceof Street ? 'STREET' : 'COLLECTION',
        elements: items.map(o => ({
          objectId: o.id,
          type: o instanceof Street ? 'STREET' : 'BUILDING',
        })),
      })
      .pipe(
        tap(res => {
          collection.cartId = res.id || 0;
          items.forEach(i => {
            collection.addStreet(i);
          });

          res.elements?.forEach(e => {
            const item = items.find(i => i.id === e.objectId);
            if (item) {
              item.cartId = e.id || 0;
            }
          });
          const cartItem = this.items.find(i => i.object.id === collection.id);
          if (cartItem) {
            cartItem.nestedItems.push(...items);
            this.pushNFTTag(items, 'add_to_cart', 'added');
          } else {
            this.items.push(
              new CartItem({
                id: res.id || 0,
                object: collection,
                nestedItems: items,
              })
            );
            this.pushCollectionTag([collection], 'add_to_cart', 'added');
            this.pushNFTTag(items, 'add_to_cart', 'added');
          }
          this.updateCounters();
        })
      );
  }

  addToStreet(street: Street, items: Building[] = []): Observable<ShoppingBasketTOResponse> {
    return this.cartApi
      .createUsingPOST3({
        objectId: street.id,
        type: street instanceof Street ? 'STREET' : 'BUILDING',
        elements: items.map(o => ({
          objectId: o.id,
          type: 'BUILDING',
        })),
      })
      .pipe(
        tap(res => {
          street.cartId = res.id || 0;
          res.elements?.forEach(e => {
            const item = items.find(i => i.id === e.objectId);
            if (item) {
              item.cartId = e.id || 0;
            }
          });
          const cartItem = this.items.find(i => i.object.id === street.id);
          if (cartItem) {
            cartItem.nestedItems.push(...items);
            this.pushNFTTag(items, 'add_to_cart', 'added');
          } else {
            this.items.push(
              new CartItem({
                id: res.id || 0,
                object: street,
                nestedItems: items,
              })
            );
            this.pushNFTTag([street], 'add_to_cart', 'added');
            this.pushNFTTag(items, 'add_to_cart', 'added');
          }
          this.updateCounters();
        })
      );
  }

  removeFromCollection(collection: Collection, items: Street[] = []): Observable<null> {
    const cartItem = this.items.find(i => i.id === collection.cartId);
    if (!cartItem) {
      return of();
    }
    // remove all nested streets if empty array passed
    if (!items.length) {
      items = cartItem.nestedStreets;
    }
    const idsToRemove = [...items.map(i => i.cartId)];
    // if collection empty also remove collection
    if (cartItem.nestedItems.length - items.length <= 0) {
      idsToRemove.push(collection.cartId);
    }
    return this.cartApi.removeItemsUsingDELETE(idsToRemove).pipe(
      tap(() => {
        items.forEach(i => (i.cartId = 0));
        cartItem.nestedItems = cartItem.nestedItems.filter(
          i => !items.find(r => r.cartId === i.cartId)
        );
        items.forEach(i => {
          collection.removeStreet(i);
        });

        this.pushNFTTag(items, 'remove_from_cart', 'removed');
        if (!cartItem.nestedItems.length) {
          this.items = this.items.filter(i => collection.cartId !== i.id);
          collection.cartId = 0;

          this.pushCollectionTag([collection], 'remove_from_cart', 'removed');
        }
        this.updateCounters();
      })
    );
  }

  removeFromStreet(street: Street, items: Building[] = []): Observable<null> {
    const isEmpty = !items.length;
    const cartItem = this.items.find(i => i.id === street.cartId);
    if (!cartItem) {
      return of();
    }

    // remove all nested buildings if empty array passed
    if (isEmpty) {
      items = cartItem.nestedItems as Building[];
    }

    const itemsIDS = [...items.map(i => i.cartId)];

    const idsToRemove = isEmpty ? [...itemsIDS, street.cartId] : itemsIDS;

    return this.cartApi.removeItemsUsingDELETE(idsToRemove).pipe(
      tap(() => {
        items.forEach(i => (i.cartId = 0));
        cartItem.nestedItems = cartItem.nestedItems.filter(
          i => !items.find(r => r.cartId === i.cartId)
        );
        this.pushNFTTag(items, 'remove_from_cart', 'removed');

        if (!cartItem.nestedItems.length) {
          this.items = this.items.filter(i => street.cartId !== i.id);
          street.cartId = 0;
          this.pushNFTTag([street], 'remove_from_cart', 'removed');
        }

        this.updateCounters();
      })
    );
  }

  add(item: Building | Street): Observable<ShoppingBasketTOResponse> {
    if (item.cartId) {
      return of({});
    }
    return this.cartApi
      .createUsingPOST3({
        type: item instanceof Building ? 'BUILDING' : 'STREET',
        objectId: item.id,
      })
      .pipe(
        tap(res => {
          item.cartId = res.id || 0;
          this.pushNFTTag([item], 'add_to_cart', 'added');
          this.items.push(
            new CartItem({
              id: res.id || 0,
              object: item,
              nestedItems: [],
            })
          );
          this.updateCounters();
        })
      );
  }

  remove(item: Building | Street): Observable<null> {
    if (!item.cartId) {
      return of();
    }
    this.items = this.items.filter(i => item.cartId !== i.id);
    this.updateCounters();
    return this.cartApi.removeItemsUsingDELETE([item.cartId]).pipe(
      tap(() => {
        item.cartId = 0;
        this.pushNFTTag([item], 'remove_from_cart', 'removed');
      })
    );
  }

  purchase(currencyCode: string): Observable<BasketPurchaseResultTOListResponse> {
    return this.cartApi.purchaseUsingPOST({ currencyCode }).pipe(
      tap(data => {
        data?.elements?.forEach(item => {
          console.log('item: ', item);

          if (item.objectType === 'STREET') {
            this.streetFactory.getMarket(item.marketId, item.market);
          }

          if (item.objectType === 'BUILDING') {
            this.buildingFactory.getMarket(item.marketId, item.market);
          }
        });

        this.items.forEach(item => {
          if (item && item instanceof Collection) {
            item?.collection?.update();
          }
        });

        const decomposingElements = this.decomposeEntities(this.items);

        decomposingElements.forEach(item => {
          if (item instanceof Collection) {
            item.update();
          }
        });

        this.accumulateTags(decomposingElements);
      })
    );
  }

  clear(): void {
    this.items = [];
    this.updateCounters();
  }

  private pushNFTTag(
    entities: (Street | Building)[],
    event: GTMEventType,
    action: GTMActionType
  ): void {
    entities.forEach(entity => {
      const config = {
        event: event,
        user_id: this.userService.user.id,
        ecommerce: {
          items: [this.getCardConfig(entity)],
        },
      };
      if (event !== 'remove_from_cart') {
        this.FbService.pushTag(config);
      }
      this.gtmService.pushTag(config);
    });
  }

  private pushCollectionTag(
    entities: Collection[],
    event: GTMEventType,
    action: GTMActionType
  ): void {
    entities.forEach(entity => {
      const config = this.getCheckoutGTMTagConfig({
        event: event,
        action: action,
        type: 'collection',
        country: `${entity.meta.countries[0].name}`,
        city: entity.title,
        label: entity.secondaryTitle,
        amount: entity.collectionPrice,
        name: entity.secondaryTitle,
      });
      if (event !== 'remove_from_cart') {
        this.FbService.pushTag(config);
      }
      this.gtmService.pushTag(config);
    });
  }

  private getCardConfig(data: Street | Building): Record<string, unknown> {
    return {
      item_name: data.name || data.mainName, // street name
      nft_type: data instanceof Street ? 'street' : 'building',
      item_id: data.id, // street ID
      item_country: data.country, // street country
      item_city: data.city, // city
      item_level: data.level, // street level
      item_token: data.marketPrice, // street value in DWRLD tokens
      quantity: '1', // amount of items
      price: this.currencyService.toDwrldRate(data.marketPrice, 'USD'), // item worth amount in fiat
    };
  }
  private getCheckoutGTMTagConfig(data: CheckoutGTMTagConfigData): Record<string, unknown> {
    return {
      event: data.event,
      user_id: this.userService.user.id,
      'Cart-action': data.action,
      'Cart-item-type': data.type,
      'Cart-item-country': data.country,
      'Cart-item-city': data.city,
      'Cart-item-level': data.label,
      'Cart-item-name': data.name,
      'Cart-item-amount': this.currencyService.toBaseRate(data.amount, 'DWRLD'),
      'login-country': this.geolocation.data.countryCode,
    };
  }

  private decomposeEntities(
    entities: CartItem[] | (Collection | Street | Building)[]
  ): (Collection | Street | Building)[] {
    const a1: (Collection | Street | Building)[] = [];
    entities.forEach((entity: CartItem | Collection | Street | Building) => {
      a1.push(entity instanceof CartItem ? entity.object : entity);

      if (entity instanceof CartItem && entity?.nestedItems?.length) {
        a1.push(...this.decomposeEntities(entity.nestedItems));
      }
    });

    return a1;
  }

  private accumulateTags(items: (Collection | Street | Building)[]): void {
    items.forEach(entity => {
      if (entity instanceof Collection) {
        this.pushCollectionTag([entity], 'purchased', 'purchased');
      } else {
        this.pushNFTTag([entity], 'purchased', 'purchased');
      }
    });
  }
}
