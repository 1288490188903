<div class="header-h3">
  <span>Building list</span>
  <span *ngIf="pagination?.totalElements" class="margin-left-tn">
    {{ pagination?.totalElements || 0 }}
  </span>
</div>

<app-markletplace-building-list-header
  *ngIf="pagination?.totalElements"
  [mediaClass]="[
    'large',
    'margin-right-lg',
    'margin-right'
  ]"></app-markletplace-building-list-header>

<div class="scroll-container" [class.display-flex]="!pagination?.totalElements">
  <cdk-virtual-scroll-viewport
    *ngIf="buildings.length; else noData"
    (scrollNearEnd)="nextPageAction($event)"
    appInfiniteScroll
    [itemSize]="82"
    [maxBufferPx]="300"
    [minBufferPx]="150"
    class="buildings dc-scroll-light"
    #cdkVirtualScroll>
    <app-street-building-item
      (onBuy)="purchaseBuildingNow($event)"
      [mediaClass]="['large', 'margin-right-lg', 'margin-right']"
      (toggleFavorite)="toggleFavorite($event)"
      (toggleCart)="toggleCart($event)"
      [building]="n"
      *cdkVirtualFor="let n of buildings; trackBy: identifyBuilding"
      class="building-item"></app-street-building-item>
  </cdk-virtual-scroll-viewport>
</div>

<ng-template #noData>
  <app-no-data class="no-data" text="No buildings" [canRedirect]="false"></app-no-data>
</ng-template>
