import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlatformModule } from 'core/modules/platform/platform.module';
import { ButtonModule } from 'core/components/button/button.module';
import { IconModule } from 'core/components/icon/icon.module';
import { CorePipesModule } from 'core/pipes/core-pipes/core-pipes.module';
import { TranslateModule } from 'core/modules/i18n/translate.module';
import { ListComponentsModule } from 'core/components/list-components/list-components.module';
import { AppDirectivesModule } from 'core/directives/app-directives/app-directives.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormControlModule } from 'core/components/form-control/form-control.module';
import { InputModule } from 'core/components/input/input.module';
import { DropdownModule } from 'core/components/dropdown/dropdown.module';
import { ExchangeStatisticSelectorComponent } from './components/exchange-statistic-selector/exchange-statistic-selector.component';
import {
  ExchangeStatisticMarketItemComponent
} from 'app/exchange/modules/components/exchange-statistic-market-item/exchange-statistic-market-item.component';
import { DefaultMarketsPipe, FavoritesMarketsPipe } from 'app/exchange/pipes/markets';
import { RangeModule } from 'core/components/range/range.module';

const exportedDeclarations = [
  ExchangeStatisticSelectorComponent,
  ExchangeStatisticMarketItemComponent,
  FavoritesMarketsPipe,
  DefaultMarketsPipe,
];

@NgModule({
  declarations: [...exportedDeclarations],
  exports: [...exportedDeclarations],
  imports: [
    CommonModule,
    PlatformModule,
    ListComponentsModule,
    AppDirectivesModule,
    ButtonModule,
    IconModule,
    DropdownModule,
    InputModule,
    TranslateModule,
    ReactiveFormsModule,
    InputModule,
    CorePipesModule,
    FormControlModule,
    CorePipesModule,
    RangeModule,
    CorePipesModule,
    CorePipesModule,
    DropdownModule,
    TranslateModule,
    ReactiveFormsModule,
    InputModule,
    IconModule,
    CommonModule,
    CorePipesModule

  ]
})
export class ExchangeComponentsModule {}
