import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormControlModule } from 'core/components/form-control/form-control.module';
import { IconModule } from 'core/components/icon/icon.module';
import { AdditionalInfoComponent } from 'profile/components/_ui/additional-info/additional-info.component';
import { ProfileApiKeyFormComponent } from 'profile/components/_ui/profile-api-key-form/profile-api-key-form.component';
import { UserLevelIconComponent } from 'profile/components/_ui/user-level-icon/user-level-icon.component';
import { InputModule } from 'core/components/input/input.module';

const profileComponents = [
  ProfileApiKeyFormComponent,
  AdditionalInfoComponent,
  UserLevelIconComponent,
];

@NgModule({
  imports: [CommonModule, IconModule, ReactiveFormsModule, FormControlModule, InputModule],
  exports: [...profileComponents],
  declarations: [...profileComponents],
  providers: [],
})
export class ProfileComponentsModule {}
