<app-sort-header
  (sortChange)="onSortChange($event)"
  *mediaIf="'large'"
  [headers]="headers"
  [sortData]="sortData"
  class="data-header"></app-sort-header>

<app-mobile-filters
  (sortChange)="onSortChange($event)"
  *mediaIf="'medium down'"
  [headers]="headers"
  [sortData]="sortData"
  class="margin-bottom"
  [filterTitle]="'marketplace.streets.filter.title' | translate"></app-mobile-filters>
