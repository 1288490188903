import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { IconModule } from 'core/components/icon/icon.module';
import { CorePipesModule } from 'core/pipes/core-pipes/core-pipes.module';

import { ToastComponent } from './components/toast/toast.component';
import { ToasterComponent } from './components/toaster/toaster.component';
import { Toaster } from './services/toaster.service';

@NgModule({
  declarations: [ToastComponent, ToasterComponent],
  imports: [CommonModule, IconModule, CorePipesModule],
  exports: [ToasterComponent, ToastComponent],
})
export class ToasterModule {
  static forRoot(): ModuleWithProviders<ToasterModule> {
    return {
      ngModule: ToasterModule,
      providers: [Toaster],
    };
  }
}
