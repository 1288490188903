import {Pipe, PipeTransform} from '@angular/core';
import {I18nService} from 'core/modules/i18n/i18n.service';
import {TranslatePipe} from 'core/modules/i18n/pipes/translate.pipe';

@Pipe({
  name: 'buildingCategory',
})
export class BuildingCategoryPipe implements PipeTransform {
  private readonly translatePipe = new TranslatePipe(this.i18n);

  constructor(private i18n: I18nService) {}

  transform(buildingsTypes: string[]): string {
    const correctTypes = buildingsTypes.reduce((acumType, type) => {
      const correctItem = type.split(':').reduce((acumSubType, subType) => {
        if (subType !== 'yes') {
          acumSubType.push(subType);
        }
        return acumSubType;
      }, [] as string[]);

      if (correctItem.length  !== 0) {
        console.log(correctItem);
        acumType.push(correctItem.join(': '));
      }

      return acumType;
    }, [] as string[]);

    if (correctTypes.length === 0) {
      return this.translatePipe.transform('marketplace.building_category.not_specified');
    }

    const translateKey = `marketplace.building_category.${correctTypes[0]}`;
    const translateAnswer = this.translatePipe.transform(translateKey);

    if (translateAnswer !== translateKey) {
      return translateAnswer;
    } else {
      return correctTypes[0].replace(/_/gim, ' ');
    }
  }
}
