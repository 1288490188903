/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { QuantumCashDepositTOResponse } from '../models/quantum-cash-deposit-toresponse';
import { NewDepositTransactionForm } from '../models/new-deposit-transaction-form';
import { ResponseEntity } from '../models/response-entity';

/**
 * Quantum Payment Gateway Controller
 */
@Injectable({
  providedIn: 'root',
})
class QuantumPaymentGatewayControllerService extends __BaseService {
  static readonly checkEnableDepositUsingGETPath = '/api/v1/quantum/pay-gateway/deposit/enable';
  static readonly createCashDepositTransactionUsingPOSTPath = '/api/v1/quantum/pay-gateway/deposit/quantum';
  static readonly returnUrlUsingGETPath = '/api/v1/quantum/pay-gateway/pmnt/charge-state-return/quantum';
  static readonly chargeStateWebhookUsingPOSTPath = '/api/v1/quantum/pay-gateway/pmnt/charge-state-webhook/quantum';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Check quantum enable
   * @return OK
   */
  checkEnableDepositUsingGETResponse(): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/quantum/pay-gateway/deposit/enable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * Check quantum enable
   * @return OK
   */
  checkEnableDepositUsingGET(): __Observable<boolean> {
    return this.checkEnableDepositUsingGETResponse().pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * Create deposit transaction
   * @param form form
   * @return OK
   */
  createCashDepositTransactionUsingPOSTResponse(form: NewDepositTransactionForm): __Observable<__StrictHttpResponse<QuantumCashDepositTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/quantum/pay-gateway/deposit/quantum`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QuantumCashDepositTOResponse>;
      })
    );
  }
  /**
   * Create deposit transaction
   * @param form form
   * @return OK
   */
  createCashDepositTransactionUsingPOST(form: NewDepositTransactionForm): __Observable<QuantumCashDepositTOResponse> {
    return this.createCashDepositTransactionUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as QuantumCashDepositTOResponse)
    );
  }

  /**
   * Return URL from Quantum
   * @param id id
   * @return OK
   */
  returnUrlUsingGETResponse(id: string): __Observable<__StrictHttpResponse<ResponseEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/quantum/pay-gateway/pmnt/charge-state-return/quantum`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseEntity>;
      })
    );
  }
  /**
   * Return URL from Quantum
   * @param id id
   * @return OK
   */
  returnUrlUsingGET(id: string): __Observable<ResponseEntity> {
    return this.returnUrlUsingGETResponse(id).pipe(
      __map(_r => _r.body as ResponseEntity)
    );
  }

  /**
   * Charge state webhook from Quantum
   * @param request request
   * @return OK
   */
  chargeStateWebhookUsingPOSTResponse(request: string): __Observable<__StrictHttpResponse<ResponseEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/quantum/pay-gateway/pmnt/charge-state-webhook/quantum`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseEntity>;
      })
    );
  }
  /**
   * Charge state webhook from Quantum
   * @param request request
   * @return OK
   */
  chargeStateWebhookUsingPOST(request: string): __Observable<ResponseEntity> {
    return this.chargeStateWebhookUsingPOSTResponse(request).pipe(
      __map(_r => _r.body as ResponseEntity)
    );
  }
}

module QuantumPaymentGatewayControllerService {
}

export { QuantumPaymentGatewayControllerService }
