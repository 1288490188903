import { Injectable, NgZone } from '@angular/core';
import { MysteryBoxToastComponent } from 'app/_main/modules/components/mystery-box-toast/mystery-box-toast.component';
import { StreetToastComponent } from 'app/_main/modules/components/street-toast/street-toast.component';
import { SocketService } from 'core/modules/socket/socket.service';
import { Toaster } from 'core/toaster';
import { dataToNFTBuyEvent, NFTBuyEvent } from 'dashboard/models/nft-buy-event.model';
import { NotificationWsConfigurationService } from 'profile/services/notification-ws-configuration.service';
import { UserService } from 'profile/services/user.service';
import { interval } from 'rxjs';
import { buffer, filter, first } from 'rxjs/operators';

export interface NFTBuyEventData {
  city: string;
  country: string;
  countryCode?: string;
  id: number;
  lastPrice: number;
  ownerName?: string;
  ownerUuid: string;
  street: string;
  zone: number;
  number: string;
  dtype: string;
  streetId: number;
}

@Injectable({
  providedIn: 'root',
})
export class NFTBuyEventService {
  constructor(
    private socket: SocketService,
    private toaster: Toaster,
    private userService: UserService,
    private notificationWsService: NotificationWsConfigurationService,
    private zone: NgZone
  ) {}

  initNFTBuyEvent(): void {
    this.notificationWsService
      .getWsNotificationList()
      .pipe(first())
      .subscribe(notifications => {
        notifications.forEach(notification => {
          if (notification.active) {
            this.socket.send({
              messageType: notification.topic,
            });
          }
        });
      });
    this.listenForMysteryBox();
    this.zone.runOutsideAngular(() => {
      this.listenForNFTBuy();
    });
  }

  private listenForMysteryBox(): void {
    this.socket
      .on<{ eventType: 'MYSTERY_BOX'; data: NFTBuyEventData }>()
      .pipe(
        filter(
          response =>
            response.eventType === 'MYSTERY_BOX' &&
            response.data.ownerUuid !== this.userService.user.id
        )
      )
      .subscribe(event => {
        this.toaster.customToast<NFTBuyEvent>(
          MysteryBoxToastComponent,
          {
            data: new NFTBuyEvent(dataToNFTBuyEvent(event.data)),
          },
          'mystery-box'
        );
      });
  }

  private listenForNFTBuy(): void {
    this.socket
      .on<{ eventType: 'NFT_BUY'; data: NFTBuyEventData }>()
      .pipe(
        filter(
          response =>
            response.eventType === 'NFT_BUY' && response.data.ownerUuid !== this.userService.user.id
        ),
        buffer(interval(3000)),
        filter(data => Boolean(data.length))
      )
      .subscribe(data => {
        this.zone.run(() => {
          Object.values(
            data.reduce<Record<string, NFTBuyEvent[]>>(
              (acc, curr) => ({
                [curr.data.ownerUuid]: acc[curr.data.ownerUuid]
                  ? acc[curr.data.ownerUuid].concat(new NFTBuyEvent(dataToNFTBuyEvent(curr.data)))
                  : [new NFTBuyEvent(dataToNFTBuyEvent(curr.data))],
              }),
              {}
            )
          ).forEach(events => {
            events = events.map((x) => {
              const exist = data.find(item => item.data.id === x.id) ;
              if (exist) {
                x.number= exist?.data.number;
                x.dtype = exist?.data.dtype;
              }
              return x;
            });
            this.toaster.customToast<{ count: number; elements: NFTBuyEvent[] }>(
              StreetToastComponent,
              {
                data: {
                  count: events.length,
                  elements: events,
                },
              }
            );
          });
        });
      });
  }
}
