import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ApiKeyFormGroup, apiKeyVerificationTypes } from 'profile/forms/api-key.form';
import { ApiKeyFormData } from 'profile/models/api-key-form-data.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-profile-api-key-form',
  templateUrl: './profile-api-key-form.component.html',
  styleUrls: ['./profile-api-key-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProfileApiKeyFormComponent),
      multi: true,
    },
  ],
})
export class ProfileApiKeyFormComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() submitted = false;

  form = new ApiKeyFormGroup();

  readonly emailConfirmationTypes = apiKeyVerificationTypes;
  private destroy$ = new Subject<void>();

  private cvaOnChange: (_: unknown) => void = () => {};
  private cvaOnTouched: () => void = () => {};
  constructor() {}

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      const value = this.form.getRawValue() as ApiKeyFormData;
      this.cvaOnChange(value);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  writeValue(data: ApiKeyFormData): void {
    if (data) {
      this.form.patchValue(data);
    }
  }

  registerOnChange(fn: (_: unknown) => void): void {
    this.cvaOnChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.cvaOnTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }
}
