import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ButtonModule } from '../button/button.module';
import { CookiesBannerComponent } from './cookies-banner.component';
import { TranslateModule } from 'core/modules/i18n/translate.module';

@NgModule({
  imports: [CommonModule, ButtonModule, RouterModule, TranslateModule],
  declarations: [CookiesBannerComponent],
  exports: [CookiesBannerComponent],
})
export class CookiesBannerModule {}
