<app-svg [asset]="['svg', 'logo-small']" class="round huge margin-bottom-hg"></app-svg>

<p class="header-h3 margin-bottom text-center">{{ title || '' }}</p>

<p class="margin-bottom-hg info">{{ text }}</p>

<button
  (click)="relocateToMap()"
  *ngIf="canRedirect"
  appButton
  class="solid primary navigation-button">
  {{ buttonText || ('core.no-data.map' | translate) }}
</button>
