/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DirectMessageTOResponse } from '../models/direct-message-toresponse';
import { ChatUserTOList } from '../models/chat-user-tolist';
import { ChatRoomTO } from '../models/chat-room-to';
import { ChatMessageTOList } from '../models/chat-message-tolist';

/**
 * Chat Controller
 */
@Injectable({
  providedIn: 'root',
})
class ChatControllerService extends __BaseService {
  static readonly sendDirectMessageUsingPOSTPath = '/api/v1/chat/direct';
  static readonly getFriendListUsingGETPath = '/api/v1/chat/friend-list';
  static readonly addFriendUsingPOSTPath = '/api/v1/chat/friend-list';
  static readonly deleteFriendUsingDELETEPath = '/api/v1/chat/friend-list';
  static readonly getIgnoreListUsingGETPath = '/api/v1/chat/ignore-list';
  static readonly addUserToIgnoreListUsingPOSTPath = '/api/v1/chat/ignore-list';
  static readonly deleteFromIgnoreListUsingDELETEPath = '/api/v1/chat/ignore-list';
  static readonly updateLastReadMessageUsingPOSTPath = '/api/v1/chat/last-reed-room-message';
  static readonly joinRoomUsingPOSTPath = '/api/v1/chat/room';
  static readonly getRoomHistoryUsingPOSTPath = '/api/v1/chat/room-history';
  static readonly sendRoomMessageUsingPOSTPath = '/api/v1/chat/room-message';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * sendDirectMessage
   * @param params The `ChatControllerService.SendDirectMessageUsingPOSTParams` containing the following parameters:
   *
   * - `uuid`: uuid
   *
   * - `message`: message
   *
   * @return OK
   */
  sendDirectMessageUsingPOSTResponse(params: ChatControllerService.SendDirectMessageUsingPOSTParams): __Observable<__StrictHttpResponse<DirectMessageTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.uuid != null) __params = __params.set('uuid', params.uuid.toString());
    if (params.message != null) __params = __params.set('message', params.message.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/chat/direct`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DirectMessageTOResponse>;
      })
    );
  }
  /**
   * sendDirectMessage
   * @param params The `ChatControllerService.SendDirectMessageUsingPOSTParams` containing the following parameters:
   *
   * - `uuid`: uuid
   *
   * - `message`: message
   *
   * @return OK
   */
  sendDirectMessageUsingPOST(params: ChatControllerService.SendDirectMessageUsingPOSTParams): __Observable<DirectMessageTOResponse> {
    return this.sendDirectMessageUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as DirectMessageTOResponse)
    );
  }

  /**
   * getFriendList
   * @return OK
   */
  getFriendListUsingGETResponse(): __Observable<__StrictHttpResponse<ChatUserTOList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/chat/friend-list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ChatUserTOList>;
      })
    );
  }
  /**
   * getFriendList
   * @return OK
   */
  getFriendListUsingGET(): __Observable<ChatUserTOList> {
    return this.getFriendListUsingGETResponse().pipe(
      __map(_r => _r.body as ChatUserTOList)
    );
  }

  /**
   * addFriend
   * @param uuid uuid
   * @return OK
   */
  addFriendUsingPOSTResponse(uuid: string): __Observable<__StrictHttpResponse<ChatUserTOList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (uuid != null) __params = __params.set('uuid', uuid.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/chat/friend-list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ChatUserTOList>;
      })
    );
  }
  /**
   * addFriend
   * @param uuid uuid
   * @return OK
   */
  addFriendUsingPOST(uuid: string): __Observable<ChatUserTOList> {
    return this.addFriendUsingPOSTResponse(uuid).pipe(
      __map(_r => _r.body as ChatUserTOList)
    );
  }

  /**
   * deleteFriend
   * @param uuid uuid
   * @return OK
   */
  deleteFriendUsingDELETEResponse(uuid: string): __Observable<__StrictHttpResponse<ChatUserTOList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (uuid != null) __params = __params.set('uuid', uuid.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/chat/friend-list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ChatUserTOList>;
      })
    );
  }
  /**
   * deleteFriend
   * @param uuid uuid
   * @return OK
   */
  deleteFriendUsingDELETE(uuid: string): __Observable<ChatUserTOList> {
    return this.deleteFriendUsingDELETEResponse(uuid).pipe(
      __map(_r => _r.body as ChatUserTOList)
    );
  }

  /**
   * getIgnoreList
   * @return OK
   */
  getIgnoreListUsingGETResponse(): __Observable<__StrictHttpResponse<ChatUserTOList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/chat/ignore-list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ChatUserTOList>;
      })
    );
  }
  /**
   * getIgnoreList
   * @return OK
   */
  getIgnoreListUsingGET(): __Observable<ChatUserTOList> {
    return this.getIgnoreListUsingGETResponse().pipe(
      __map(_r => _r.body as ChatUserTOList)
    );
  }

  /**
   * addUserToIgnoreList
   * @param uuid uuid
   * @return OK
   */
  addUserToIgnoreListUsingPOSTResponse(uuid: string): __Observable<__StrictHttpResponse<ChatUserTOList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (uuid != null) __params = __params.set('uuid', uuid.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/chat/ignore-list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ChatUserTOList>;
      })
    );
  }
  /**
   * addUserToIgnoreList
   * @param uuid uuid
   * @return OK
   */
  addUserToIgnoreListUsingPOST(uuid: string): __Observable<ChatUserTOList> {
    return this.addUserToIgnoreListUsingPOSTResponse(uuid).pipe(
      __map(_r => _r.body as ChatUserTOList)
    );
  }

  /**
   * deleteFromIgnoreList
   * @param uuid uuid
   * @return OK
   */
  deleteFromIgnoreListUsingDELETEResponse(uuid: string): __Observable<__StrictHttpResponse<ChatUserTOList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (uuid != null) __params = __params.set('uuid', uuid.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/chat/ignore-list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ChatUserTOList>;
      })
    );
  }
  /**
   * deleteFromIgnoreList
   * @param uuid uuid
   * @return OK
   */
  deleteFromIgnoreListUsingDELETE(uuid: string): __Observable<ChatUserTOList> {
    return this.deleteFromIgnoreListUsingDELETEResponse(uuid).pipe(
      __map(_r => _r.body as ChatUserTOList)
    );
  }

  /**
   * updateLastReadMessage
   * @param params The `ChatControllerService.UpdateLastReadMessageUsingPOSTParams` containing the following parameters:
   *
   * - `roomId`: roomId
   *
   * - `messageId`: messageId
   *
   * @return OK
   */
  updateLastReadMessageUsingPOSTResponse(params: ChatControllerService.UpdateLastReadMessageUsingPOSTParams): __Observable<__StrictHttpResponse<ChatRoomTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.roomId != null) __params = __params.set('roomId', params.roomId.toString());
    if (params.messageId != null) __params = __params.set('messageId', params.messageId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/chat/last-reed-room-message`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ChatRoomTO>;
      })
    );
  }
  /**
   * updateLastReadMessage
   * @param params The `ChatControllerService.UpdateLastReadMessageUsingPOSTParams` containing the following parameters:
   *
   * - `roomId`: roomId
   *
   * - `messageId`: messageId
   *
   * @return OK
   */
  updateLastReadMessageUsingPOST(params: ChatControllerService.UpdateLastReadMessageUsingPOSTParams): __Observable<ChatRoomTO> {
    return this.updateLastReadMessageUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as ChatRoomTO)
    );
  }

  /**
   * joinRoom
   * @param roomName roomName
   * @return OK
   */
  joinRoomUsingPOSTResponse(roomName: string): __Observable<__StrictHttpResponse<ChatRoomTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (roomName != null) __params = __params.set('roomName', roomName.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/chat/room`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ChatRoomTO>;
      })
    );
  }
  /**
   * joinRoom
   * @param roomName roomName
   * @return OK
   */
  joinRoomUsingPOST(roomName: string): __Observable<ChatRoomTO> {
    return this.joinRoomUsingPOSTResponse(roomName).pipe(
      __map(_r => _r.body as ChatRoomTO)
    );
  }

  /**
   * getRoomHistory
   * @param params The `ChatControllerService.GetRoomHistoryUsingPOSTParams` containing the following parameters:
   *
   * - `startMessageId`: startMessageId
   *
   * - `roomId`: roomId
   *
   * - `moveBack`: moveBack
   *
   * - `amountOfMessages`: amountOfMessages
   *
   * @return OK
   */
  getRoomHistoryUsingPOSTResponse(params: ChatControllerService.GetRoomHistoryUsingPOSTParams): __Observable<__StrictHttpResponse<ChatMessageTOList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startMessageId != null) __params = __params.set('startMessageId', params.startMessageId.toString());
    if (params.roomId != null) __params = __params.set('roomId', params.roomId.toString());
    if (params.moveBack != null) __params = __params.set('moveBack', params.moveBack.toString());
    if (params.amountOfMessages != null) __params = __params.set('amountOfMessages', params.amountOfMessages.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/chat/room-history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ChatMessageTOList>;
      })
    );
  }
  /**
   * getRoomHistory
   * @param params The `ChatControllerService.GetRoomHistoryUsingPOSTParams` containing the following parameters:
   *
   * - `startMessageId`: startMessageId
   *
   * - `roomId`: roomId
   *
   * - `moveBack`: moveBack
   *
   * - `amountOfMessages`: amountOfMessages
   *
   * @return OK
   */
  getRoomHistoryUsingPOST(params: ChatControllerService.GetRoomHistoryUsingPOSTParams): __Observable<ChatMessageTOList> {
    return this.getRoomHistoryUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as ChatMessageTOList)
    );
  }

  /**
   * sendRoomMessage
   * @param params The `ChatControllerService.SendRoomMessageUsingPOSTParams` containing the following parameters:
   *
   * - `roomId`: roomId
   *
   * - `message`: message
   *
   * @return OK
   */
  sendRoomMessageUsingPOSTResponse(params: ChatControllerService.SendRoomMessageUsingPOSTParams): __Observable<__StrictHttpResponse<DirectMessageTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.roomId != null) __params = __params.set('roomId', params.roomId.toString());
    if (params.message != null) __params = __params.set('message', params.message.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/chat/room-message`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DirectMessageTOResponse>;
      })
    );
  }
  /**
   * sendRoomMessage
   * @param params The `ChatControllerService.SendRoomMessageUsingPOSTParams` containing the following parameters:
   *
   * - `roomId`: roomId
   *
   * - `message`: message
   *
   * @return OK
   */
  sendRoomMessageUsingPOST(params: ChatControllerService.SendRoomMessageUsingPOSTParams): __Observable<DirectMessageTOResponse> {
    return this.sendRoomMessageUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as DirectMessageTOResponse)
    );
  }
}

module ChatControllerService {

  /**
   * Parameters for sendDirectMessageUsingPOST
   */
  export interface SendDirectMessageUsingPOSTParams {

    /**
     * uuid
     */
    uuid: string;

    /**
     * message
     */
    message: string;
  }

  /**
   * Parameters for updateLastReadMessageUsingPOST
   */
  export interface UpdateLastReadMessageUsingPOSTParams {

    /**
     * roomId
     */
    roomId: number;

    /**
     * messageId
     */
    messageId: number;
  }

  /**
   * Parameters for getRoomHistoryUsingPOST
   */
  export interface GetRoomHistoryUsingPOSTParams {

    /**
     * startMessageId
     */
    startMessageId: number;

    /**
     * roomId
     */
    roomId: number;

    /**
     * moveBack
     */
    moveBack: boolean;

    /**
     * amountOfMessages
     */
    amountOfMessages: number;
  }

  /**
   * Parameters for sendRoomMessageUsingPOST
   */
  export interface SendRoomMessageUsingPOSTParams {

    /**
     * roomId
     */
    roomId: number;

    /**
     * message
     */
    message: string;
  }
}

export { ChatControllerService }
