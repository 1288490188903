import { Injectable } from '@angular/core';
import { ExchangePageMobileTab } from 'app/exchange/models/exchange';
import { UserService } from 'profile/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class StorageExchangePageService {
  private prefix = 'exchange-page';
  private postfix = 'key';
  private exchangeMobileTabKey = `${this.prefix}-exchange-mobile-tab-${this.postfix}`;

  constructor(private userService: UserService) {}

  private get currentMobileTabKey(): string {
    return `${this.exchangeMobileTabKey}-${this.userService.user.id}`;
  }

  getCurrentTab(): ExchangePageMobileTab {
    return (
      (localStorage.getItem(this.currentMobileTabKey) as ExchangePageMobileTab) ||
      ExchangePageMobileTab.CHART
    );
  }

  changeCurrentTab(tab: ExchangePageMobileTab): void {
    localStorage.setItem(this.currentMobileTabKey, tab);
  }
}
