import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CountryResponseTO } from 'api/models';
import { GenericFormControl } from 'core/utils/form-generics';
import { GisService } from 'dashboard/services/gis.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-country-search',
  templateUrl: './country-search.component.html',
})
export class CountrySearchComponent implements OnChanges, OnInit, OnDestroy {
  @Input()
  countryCode?: string;
  @Input()
  regionCode?: string;
  @Output()
  selected = new EventEmitter<CountryResponseTO | undefined>();

  @Input()
  allowClearButton = true;

  searchCountries: CountryResponseTO[] = [];
  regionCountries: CountryResponseTO[] = [];
  allCountries: CountryResponseTO[] = [];
  control = new GenericFormControl<string>();
  value: CountryResponseTO = {};

  private destroy$ = new Subject();
  private pageSize = 300;
  private containsCountry = true;

  constructor(private gisService: GisService) {}

  get countryList(): CountryResponseTO[] {
    return this.regionCode
      ? this.regionCountries
      : this.control.value === '' || this.control.value === null || this.control.value === undefined
      ? this.allCountries
      : this.searchCountries;
  }

  ngOnChanges(changes: SimpleChanges<CountrySearchComponent>): void {
    if (changes.regionCode) {
      this.gisService
        .findCountryStartsWith('', this.pageSize, '', this.regionCode, this.containsCountry)
        .pipe(takeUntil(this.destroy$))
        .subscribe(countries => {
          if (this.regionCode) {
            this.regionCountries = countries;
          } else {
            this.regionCountries = this.allCountries;
          }
        });
    }

    if (changes.countryCode) {
      if (changes.countryCode?.currentValue) {
        this.gisService
          .findCountryStartsWith(
            '',
            this.pageSize,
            changes.countryCode.currentValue,
            this.regionCode,
            this.containsCountry
          )
          .pipe(
            map(countries => countries[0]),
            takeUntil(this.destroy$)
          )
          .subscribe(country => {
            this.value = country;
            this.selected.emit(country);
          });
      } else if (!changes.countryCode?.currentValue) {
        this.value = {};
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.gisService
      .findCountryStartsWith('', this.pageSize, '', '', this.containsCountry)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.allCountries = res;
      });
    this.control.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged((prev, curr) => {
          if (curr === '') {
            return false;
          } else {
            return prev === curr;
          }
        }),
        switchMap(v =>
          this.gisService.findCountryStartsWith(v, this.pageSize, '', this.regionCode, this.containsCountry).pipe(
            tap(res => {
              this.regionCountries = this.searchCountries = res;
              if (v !== undefined) {
                this.value = {};
                this.selected.emit(undefined);
              }
            })
          )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  select(c: CountryResponseTO): void {
    if (c.code !== this.value.code) {
      this.value = c;
      this.control.setValue(undefined);
      this.selected.emit(c);
    }
  }
}
