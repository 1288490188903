import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NftOrderHistoryTO } from 'api/models/nft-order-history-to';
import { PageTO } from 'api/models/page-to';
import { NftControllerService } from 'api/services';
import { delayAtLeast } from 'core/utils/rxjs/delay-at-least.operator';
import { PageControllerService, RequestData } from 'dashboard/services/page-controller.service';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { OrdersFactory } from '../../dashboard/services/orders.factory';

export type HistoryFilterParams = Omit<
  NftControllerService.GetNftOrderHistoryUsingGETParams,
  'limit' | 'offset'
>;

export interface HistoryListData {
  list: NftOrderHistoryTO[];
  pageInfo?: PageTO;
}

@Injectable({ providedIn: 'root' })
export class MarketplaceHistoryService extends PageControllerService<HistoryFilterParams, string> {
  private historyItems: NftOrderHistoryTO[] = [];

  constructor(
    private nftApiService: NftControllerService,
    private factory: OrdersFactory,
    protected route: ActivatedRoute,
    protected router: Router
  ) {
    super(route, router);
  }

  get history(): NftOrderHistoryTO[] {
    return this.historyItems;
  }

  load(): Observable<HistoryListData> {
    return this.getRequestData().pipe(
      switchMap(data =>
        this.nftApiService
          .getNftOrderHistoryUsingGET({
            ...data.filter,
            pageSize: data.pagination.size || 15,
            pageNumber: data.pagination.pageNumber || 0,
            sort: data.sort || '',
            closeReason: 'COMPLETED',
          })
          .pipe(
            delayAtLeast(1000),
            map(result => ({
              list: result.elements || [],
              pageInfo: result.pageInfo,
            })),
            tap(result => {
              this.historyItems = result?.list || [];
            }),
            catchError(() =>
              of({
                list: [],
                pageInfo: {},
              })
            )
          )
      )
    );
  }

  protected override convertToRequestData(
    queryParams: Partial<Record<string, string>>
  ): RequestData<HistoryFilterParams, string> {
    const { sort, pageNumber, orderType, side, dateFrom, dateTo, nftType } = queryParams || {};
    return {
      sort: sort || '',

      filter: {
        orderType: orderType as NftControllerService.GetNftOrderHistoryUsingGETParams['orderType'],
        side: side as NftControllerService.GetNftOrderHistoryUsingGETParams['side'],
        closedFrom: +(dateFrom || 0),
        closedTo: +(dateTo || 0),
        pageSize: 15,
        nftType: nftType  as NftControllerService.GetNftOrderHistoryUsingGETParams['nftType'],
        pageNumber: pageNumber ? +pageNumber : 0,
      },
      pagination: {
        ...this.requestData$.getValue().pagination,
        pageNumber: pageNumber ? +pageNumber : 0,
      },
    };
  }

  protected override createQueryParams(data: RequestData<HistoryFilterParams, string>): Params {
    const { sort, pagination, filter } = data;
    const queryParams = {
      sort: sort || null,
      pageNumber: pagination.pageNumber || null,
      orderType: filter.orderType || null,
      side: filter.side || null,
      nftType: filter.nftType || null,
      dateFrom: filter.closedFrom || null,
      dateTo: filter.closedTo || null,
    };

    return queryParams;
  }
}
