import { Component, OnInit } from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {CityTOResponse} from 'api/models/city-toresponse';
import {CountryTOResponse} from 'api/models/country-toresponse';
import {
  MarketplaceMarketType,
  marketplaceMarketTypes,
  MarketplaceStreetsFilters,
  MarketplaceStreetsFormGroup
} from 'app/marketplace/forms/marketplace-streets.form';
import {marketplaceNav} from 'app/marketplace/marketplace.navigation';
import {MarketplaceStreetsService} from 'app/marketplace/services/marketplace-streets.service';
import {AuthService} from 'auth/services/auth.service';
import {SortHeaderItem} from 'core/components/list-components/sorting.model';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {GeolocationService} from 'streets/services/geolocation.service';
import {MarketplaceBuildingsService} from "app/marketplace/services/marketplace-buildings.service";

@Component({
  selector: 'app-marketplace-building-header',
  templateUrl: './marketplace-building-header.component.html',
  styleUrls: ['./marketplace-building-header.component.scss']
})
export class MarketplaceBuildingHeaderComponent implements OnInit {
  readonly marketplaceMarketTypes = marketplaceMarketTypes;

  redirectFromSameUrl = false;

  form = new MarketplaceStreetsFormGroup();

  sortData?: string;
  cityId?: number;
  countryCode?: string;
  headers: SortHeaderItem[] = [
    {
      label: $t('marketplace.streets.column.street'),
      itemClass: 'street',
      key: 'street:number',
    },
    {
      label: $t('marketplace.streets.column.level'),
      itemClass: 'level',
      key: 'zone',
    },
    {
      label: $t('marketplace.streets.column.owner'),
      itemClass: 'owner',
      key: 'ownerName',
    },
    {
      label: $t('marketplace.streets.purchase_price'),
      itemClass: 'purchase-price',
      key: 'lastPrice',
    },
    {
      label: $t('marketplace.streets.column.price'),
      itemClass: 'current-price',
      key: 'price',
    },
    {
      label: $t('marketplace.streets.column.max_bid'),
      itemClass: 'max-bid',
      key: 'buyingPrice',
    },
    {
      label: '',
      itemClass: 'actions-container',
    },
  ];
  private city?: CityTOResponse;
  private destroy$ = new Subject<void>();

  constructor(
    public authService: AuthService,
    private marketplaceBuildingsService: MarketplaceBuildingsService,
    private geolocation: GeolocationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.marketplaceBuildingsService.isInitial = true;

    this.form.controls.hasMatching.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(hasMatching => {
      });

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.applySearch();
    });

    this.router.events
      .pipe(
        filter(e => e instanceof NavigationStart),
        takeUntil(this.destroy$)
      )
      .subscribe(data => {
        const event = data as NavigationStart;
        this.redirectFromSameUrl = event.url === marketplaceNav.marketplaceStreets();
      });

    this.marketplaceBuildingsService
      .getQueryParams()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.form.controls.name.setValue(data.filter.name, { emitEvent: false });
        this.form.controls.price.setValue(data.filter.price, { emitEvent: false });
        this.form.controls.zones.setValue(data.filter.zones, { emitEvent: false });
        this.form.controls.hasMatching.setValue(data.filter.hasMatching, { emitEvent: false });
        this.form.controls.nftMarketType.setValue(
          data.filter.nftMarketType || marketplaceMarketTypes[0],
          { emitEvent: false }
        );

        this.sortData = data.sort;
        this.countryCode = data.filter.countryCode;
        this.cityId = data.filter.cityId;
      });
  }

  ngOnDestroy(): void {
    this.marketplaceBuildingsService.clearParams();
    this.form.reset(
      {},
      {
        emitEvent: false,
      }
    );
    this.destroy$.next();
    this.destroy$.complete();
  }

  pickCountry(country?: CountryTOResponse): void {
    if (country?.code) {
      this.countryCode = country?.code;
      if (this.countryCode !== this.city?.countryCode) {
        this.cityId = undefined;
      }
      if (!this.marketplaceBuildingsService.isInitial) {
        this.applySearch();
      }
    }
  }

  pickCity(city?: CityTOResponse): void {
    this.city = city;
    this.cityId = city?.id;
    if (!this.marketplaceBuildingsService.isInitial) {
      this.applySearch();
    }
  }

  onSortChange(sort: string | undefined): void {
      const sortArray = (sort || '').split(',');
      const sortHeaders = sortArray[0].split(':');
      const correctSort = sortHeaders.map(x => {
        return `${x},${sortArray[1]}`;
      }).join(';');
      this.marketplaceBuildingsService.setSortParams(correctSort);
  }

  pickMarketType(option: MarketplaceMarketType): void {
    this.form.controls.nftMarketType.setValue(option);
  }

  applySearch(): void {
    const formValue = this.form.getRawValue() as Omit<
      MarketplaceStreetsFilters,
      'cityId' | 'countryCode'
      >;

    if (formValue.name && formValue.name?.length >= 1 && formValue.name?.length < 3) {
      this.marketplaceBuildingsService.setFilterParams({
        ...formValue,
        name: '',
        cityId: this.cityId,
        countryCode: this.countryCode,
      });
    } else {
      this.marketplaceBuildingsService.setFilterParams({
        ...formValue,
        cityId: this.cityId,
        countryCode: this.countryCode,
      });
    }

  }
}
