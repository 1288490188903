import { Component, HostListener, OnDestroy } from '@angular/core';
import { lazyModalsNav } from 'app/app.navigation';
import { ModalRouter } from 'core/modules/modal/services/modal.router';
import { BaseCustomToast } from 'core/toaster/base-custom-toast.interface';
import { ToastExtras } from 'core/toaster/services/toaster.service';
import { NFTBuyEvent } from 'dashboard/models/nft-buy-event.model';
import { StreetsService } from 'dashboard/services/streets.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {StreetPreviewModal} from "dashboard/dashboard.navigation";

@Component({
  selector: 'app-mystery-box-toast',
  templateUrl: './mystery-box-toast.component.html',
  styleUrls: ['./mystery-box-toast.component.scss'],
})
export class MysteryBoxToastComponent extends BaseCustomToast<NFTBuyEvent> implements OnDestroy {
  nftBuyEvent?: NFTBuyEvent;

  private destroy$ = new Subject<void>();

  constructor(private modalRouter: ModalRouter, private streetsService: StreetsService) {
    super();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onCustomToastInit(extras?: ToastExtras<NFTBuyEvent>): void {
    this.nftBuyEvent = extras?.data;
  }

  @HostListener('click')
  openPreview(): void {
    this.streetsService
      .findStreetOrMarket(this.nftBuyEvent?.streetId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(street => {
        this.modalRouter.navigate(lazyModalsNav.streets.streetPreview(), undefined, {
          data: {
            entity: street,
          } as StreetPreviewModal,
        });
      });
  }
}
