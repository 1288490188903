import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core';
import { lazyModalsNav } from 'app/app.navigation';
import { WishlistBuildingsService } from 'app/marketplace/services/wishlist-buildings.service';
import { WishlistStreetsService } from 'app/marketplace/services/wishlist-streets.service';
import { iconBag } from 'core/icons/lib/icon-bag';
import { ModalRouter } from 'core/modules/modal';
import { GenericFormControl } from 'core/utils/form-generics';
import { StreetPreviewModal } from 'dashboard/dashboard.navigation';
import { Building } from 'dashboard/models/building.model';
import { Street } from 'dashboard/models/street.model';
import { User } from 'profile/models/user.model';
import { UserService } from 'profile/services/user.service';
import { defer, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {GTMService} from "core/services/gtm.service";
import {CurrencyService} from "core/services/currency.service";
import {FbService} from "core/services/fb.service";

@Component({
  selector: 'app-wishlist-item',
  templateUrl: './wishlist-item.component.html',
  styleUrls: ['./wishlist-item.component.scss'],
})
export class WishlistItemComponent implements OnChanges, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  deleteIcon = iconBag;
  isCheckedControl = new GenericFormControl<boolean>();

  @Input()
  street = new Street();

  @Input()
  building = new Building();

  @Input()
  isChecked = false;

  @Output()
  setChecked = new EventEmitter<[boolean, Street | Building]>();

  @Output()
  emitDeleting = new EventEmitter<Street | Building>();

  constructor(
    private modal: ModalRouter,
    public userService: UserService,
    private GTMService: GTMService,
    private FbService: FbService,
    private currencyService: CurrencyService,
    private wishlistStreets: WishlistStreetsService,
    private wishlistBuildings: WishlistBuildingsService
  ) {}

  ngOnChanges(): void {
    this.isCheckedControl.setValue(this.isChecked);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getUser(): User {
    return this.userService.user;
  }

  openStreetBuy(): void {
    this.modal.navigate(
      this.street.id ? lazyModalsNav.streets.streetBuy() : lazyModalsNav.streets.buildingBuy(),
      undefined,
      {
        data: {
          entity: this.street.id ? this.street : this.building,
        } as StreetPreviewModal,
      }
    );

    const entity = this.street.id ? this.street : this.building;
    this.sendMetricTags(entity);
  }

  private sendMetricTags(entity: Building | Street): void {
    const config = {
      'event': 'select_item',
      'user_id': this.userService.user.id,
      'ecommerce': {
        items: [{
          'item_name': entity.name || entity.mainName, // street name
          'item_id': entity.id, // street ID
          'item_country': entity.country, // street country
          'item_city': entity.city, // city
          'item_level': entity.level, // street level
          'item_token': entity.marketPrice, // street value in DWRLD tokens
          'quantity': '1', // amount of items
          'price': this.currencyService.toDwrldRate(entity.marketPrice, 'USD')// item worth amount in fiat
        }]
      }
    };
    this.FbService.pushTag(config);
    this.GTMService.pushTag(config);
  }

  @HostListener('click')
  openStreetPreview(): void {
    defer(() =>
      this.street.id
        ? this.wishlistStreets.getStreet(this.street)
        : this.wishlistBuildings.getBuilding(this.building)
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(entity => {
        this.modal.navigate(
          this.street.id
            ? lazyModalsNav.streets.streetPreview()
            : lazyModalsNav.streets.buildingPreview(),
          undefined,
          {
            data: {
              entity: this.street.id ? (entity as Street) : (entity as Building),
            } as StreetPreviewModal,
          }
        );
      });
  }

  emitIsChecked(): void {
    this.setChecked.emit([
      this.isCheckedControl.value,
      this.street.id ? this.street : this.building,
    ]);
  }

  deleteFromFavorites(): void {
    this.emitDeleting.emit(this.street.id ? this.street : this.building);
  }
}
