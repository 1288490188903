/* eslint-disable prefer-arrow-functions/prefer-arrow-functions */
import { APP_INITIALIZER, Provider } from '@angular/core';
import { APIError } from 'core/models/error.model';
import { CurrencyService } from 'core/services/currency.service';
import { navigateByUrl } from 'core/utils/router.utils';
import { navigateOnError } from 'core/utils/rxjs/navigate-on-error.operator';
import { forkJoin, Observable, TimeoutError } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { GeolocationService } from 'streets/services/geolocation.service';

import { ApplicationSettingsService } from './_main/services/application-settings.service';
import { appNav } from './app.navigation';

export function provideAppInitializer(): Provider {
  return {
    provide: APP_INITIALIZER,
    useFactory: appInitializerFactory,
    deps: [CurrencyService, GeolocationService, ApplicationSettingsService],
    multi: true,
  };
}

export function appInitializerFactory(
  currencyService: CurrencyService,
  geolocation: GeolocationService,
  applicationSettingsService: ApplicationSettingsService
): () => Promise<unknown> {
  return () =>
    new Observable(s => {
      forkJoin([
        currencyService.load(),
        currencyService.loadBaseRates(),
        geolocation.initialize(),
        applicationSettingsService.healthCheck(),
        applicationSettingsService.load(),
      ])
        .pipe(navigateOnError(appNav.commonNav.serviceUnavailable()), timeout(8000))
        .subscribe({
          next: () => {
            s.complete();
          },
          error: err => {
            console.error(`App initialization error!`);
            if (err instanceof TimeoutError) {
              navigateByUrl(appNav.commonNav.serviceUnavailable(), { skipLocationChange: true });
            } else if (err instanceof APIError && err.original.status === 503) {
              navigateByUrl(appNav.commonNav.serviceUnavailable(), { skipLocationChange: true });
            }
            s.complete();
          },
        });
    }).toPromise();
}
