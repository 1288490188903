<div class="data-container container-column dark">
  <div class="width-100 content-container position-relative">
    <div class="title">
      <h3 class="color-white text-left">{{ 'chat_room.title' | translate }}</h3>
      <div class='sub-title'>{{ 'chat_room.sub_title' | translate: online }}</div>
    </div>

    <div class="container dc-scroll-light" #chatContainer (scroll)='updateScroll($event)'>
      <app-room-dialog-message-item
        *ngFor='let message of messageList'
        [message]='message'
        class='message'
      ></app-room-dialog-message-item>
    </div>
  </div>

  <div [class.sticky]="true" class="width-100 content-footer">
      <div class='textarea-block'>
        <form
          (ngSubmit)="$event.preventDefault(); submit()"
          (keydown)='keyDown($event)'
          [formGroup]="form"
          appAPIValidate
          class="default width-100 force-error">
        <app-form-field class="width-100">
          <div class='display-flex width-100'>
            <app-text-input
              [paintScroll]='true'
              [maxLength]="3000"
              [formControl]='form.controls.message'
              class="message-input solid width-100"
              [placeholder]="'main.create-new-message.placeholder.message' | translate"></app-text-input>
            <button
              appButton
              class='solid warn medium width-100 textarea-button'
              type='submit'
              [disabled]='form.invalid'
            >
              {{ 'char_room.textarea.button' | translate }}
            </button>
          </div>
        </app-form-field>
        </form>
      </div>
  </div>
</div>
