import { Injectable } from '@angular/core';

import { MarketExchanged, TickersEvent } from '../models/market-statistic';

export type TickersEventStatistic = TickersEvent & { down?: boolean; up?: boolean };

@Injectable({
  providedIn: 'root',
})
export class MarketsSocketsService {
  static defaultMarket: MarketExchanged = {
    id: 3845,
    leftCurrencyId: 8,
    rightCurrencyId: 1,
    leftCurrencyCode: 'USDT',
    rightCurencyCode: 'DWRLD',
    isActive: true,
    type: 'REGULAR',
    title: 'USDT-DWRLD',
    feeSetId: 1,
    minAmount: 1,
    maxAmount: 10000000,
    messageInfo: {
      source: '',
    },
    isLimitDisabled: true,
    priceLimit: 0,
  };

  baseCurrency = 'USD';

  constructor() {}
}
