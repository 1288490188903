import { Platform } from '@angular/cdk/platform';
import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { ReferralControllerService } from 'api/services/referral-controller.service';
import { FixedCardService } from 'app/_main/services/fixed-card.service';
import { RotationService } from 'app/_main/services/rotation.service';
import { SignUpParams } from 'auth/auth.navigation';
import { AuthService } from 'auth/services/auth.service';
import { ReferralsService } from 'auth/services/referrals.service';
import { iconCloseRounded } from 'core/icons/lib/icon-close-rounded';
import { RouteData } from 'core/models/route.data';
import { ModalRouter } from 'core/modules/modal';
import { MediaQuery } from 'core/modules/platform/services/media-query.service';
import { SeoService } from 'core/seo/seo.service';
import { RouterBehavior } from 'core/utils/router.utils';
import { NotificationEventsService } from 'dashboard/services/notification-events.service';
import { environment } from 'environments/environment';
import { BonusService } from 'profile/services/bonus.service';
import { Subject } from 'rxjs';
import { filter, first, skip, take, takeUntil } from 'rxjs/operators';

import { appNav, lazyModalsNav } from './app.navigation';
import { moveFromBottomTransition } from './app.transitions';

type RouterOutletPrivateAccess = Record<string, { location: ElementRef }>;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  animations: [moveFromBottomTransition],
})
export class AppComponent implements OnInit, OnDestroy {
  readonly closeIcon = iconCloseRounded;

  @ViewChild('mainRef', { static: true })
  mainRef!: ElementRef<HTMLElement>;

  disableBalance?: boolean;
  showHeader?: boolean;
  showFooter?: boolean;
  blurredHeader?: boolean;
  v2GuestHeader?: boolean;
  lockLandscape?: boolean = true;
  showLockLandscape?: boolean = false;
  transparentHeader?: boolean;
  isLandscape = false;
  showCookiesBanner: string | null = null;
  appNav = appNav;

  get hideBonusScreen(): boolean {
    //TODO: remove if we need bannners
    //return this.isMetaversePage && this.isMobile;
    return true;
  }
  // TODO: REMOVE WHEN we remove bonuses
  isMetaversePage = false;

  get isBonusShown(): boolean {
    //TODO: remove if we need bannners
    // return (
    //   (this.bonusService.isFirstDeposit || !this.auth.isAuth || this.isSmall) &&
    //   !this.hideBonusScreen
    // );
    return false;
  }

  private isMobile = false;
  private isSmall = false;
  private cookiesBannerKey = 'accept-cookies';
  private destroy$ = new Subject<void>();

  constructor(
    public fixedCardService: FixedCardService,
    public rotationService: RotationService,
    public bonusService: BonusService,
    public auth: AuthService,
    private seo: SeoService,
    private renderer: Renderer2,
    private platform: Platform,
    private modal: ModalRouter,
    private notificationEventsService: NotificationEventsService,
    private route: ActivatedRoute,
    media: MediaQuery,
    referralApiService: ReferralControllerService,
    referrals: ReferralsService,
    elementRef: ElementRef<HTMLElement>,
    router: Router
  ) {
    this.isMobile = platform.ANDROID || platform.IOS;
    media.mediaChanged.pipe(takeUntil(this.destroy$)).subscribe(m => {
      this.isSmall = m.new === 'small';
    });
    router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        first(),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.renderer.addClass(elementRef.nativeElement, 'navigated');
      });
    router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe(e => {
        this.isMetaversePage = (e as NavigationEnd).url.includes('metaverse');
      });
    this.route.queryParams.pipe(skip(1), take(1), takeUntil(this.destroy$)).subscribe(p => {
      const params = p as SignUpParams;
      referrals.updateReferrals({
        referral: params.referral || params.promoCode,
        offid: params.offid,
        affid: params.affid,
        c: params.c,
      });
      if (params.referral) {
        referralApiService
          .saveUniqueReferralClickUsingGET(params.referral)
          .pipe(takeUntil(this.destroy$))
          .subscribe();
      }
    });
  }

  @ViewChild(RouterOutlet, { static: true })
  set outlet(o: RouterOutlet) {
    let hostClass = '';
    o.activateEvents.pipe(takeUntil(this.destroy$)).subscribe(() => {
      const routeData = o.activatedRouteData as RouteData;
      this.showHeader = !routeData.hideHeader;
      this.blurredHeader = routeData.blurredHeader;
      this.v2GuestHeader = routeData.v2GuestHeader;

      this.transparentHeader = routeData.transparentHeader;
      this.showFooter = routeData.showFooter;
      this.disableBalance = !!routeData?.disableBalance;

      if (routeData.disableLockLandscape) {
        this.lockLandscape = false;
        this.disableLockLandscape();
      } else {
        this.lockLandscape = true;
        this.enableLockLandscape();
      }

      if (this.showHeader) {
        this.renderer.removeClass(this.mainRef.nativeElement, 'no-header');
      } else {
        this.renderer.addClass(this.mainRef.nativeElement, 'no-header');
      }
      if (hostClass) {
        this.renderer.removeClass(this.mainRef.nativeElement, hostClass);
      }
      hostClass = routeData.hostClass !== false ? routeData.hostClass || 'container' : '';
      if (hostClass) {
        this.renderer.addClass(
          (o as unknown as RouterOutletPrivateAccess).activated.location.nativeElement,
          'main-child'
        );
        this.renderer.addClass(this.mainRef.nativeElement, hostClass);
      }
    });
  }

  ngOnInit() {
    this.initSeoForPage();
    this.showCookiesBanner = localStorage.getItem(this.cookiesBannerKey);
    this.notificationEventsService.observeNotificationEvents();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  acceptCookieAction(): void {
    this.showCookiesBanner = 'true';
  }

  openDepositModal(): void {
    this.modal.navigate(lazyModalsNav.cashier.buyDcoin()).then(() => {
      this.bonusService.minimizePopup();
    });
  }

  private initSeoForPage(): void {
    this.seo.set({});
    RouterBehavior.routeDataChange.pipe(takeUntil(this.destroy$)).subscribe((data: RouteData) => {
      if (data.seoTitle || data.seoDescription) {
        this.seo.set({
          title: data.seoTitle,
          description: data.seoDescription,
          canonicalPath: data.seoCanonicalPath,
          image: data.seoImage,
        });
      }
    });
  }

  private enableLockLandscape(): void {
    if ((this.platform.IOS || this.platform.ANDROID) && !environment.ssr) {
      if (window.screen.orientation) {
        window.screen.orientation.lock('landscape');
        window.screen.orientation.lock('landscape-primary');
        window.screen.orientation.lock('landscape-secondary');
      }
      const portrait = window.matchMedia('(orientation: portrait)');

      this.showLockLandscape = !portrait.matches;
      portrait.addEventListener('change', e => {
        this.showLockLandscape = !e.matches;
      });
    }
  }

  private disableLockLandscape(): void {
    if ((this.platform.IOS || this.platform.ANDROID) && !environment.ssr) {
      if (window.screen.orientation) {
        window.screen.orientation.unlock();
      }
      this.showLockLandscape = false;
    }
  }
}
