import { Component, HostBinding, Input } from '@angular/core';
import { StreetLevelType, USER_LEVELS } from 'dashboard/models/street.data';

@Component({
  selector: 'app-user-level-icon',
  templateUrl: './user-level-icon.component.html',
  styleUrls: ['./user-level-icon.component.scss'],
})
export class UserLevelIconComponent {
  userLevels = USER_LEVELS;
  @Input()
  @HostBinding('class')
  level: StreetLevelType = 'plan1';
  @Input()
  showMessage = true;
}
