import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-svg',
  template: `
    <span class="svg" [ngStyle]="{ 'background-image': assetUrl }"></span>
    <span class="lb" *ngIf="label">{{ label }}</span>
  `,
  styleUrls: ['svg.component.scss'],
})
export class SvgComponent {
  @Input()
  label?: string;

  @Input()
  set asset(c: ['flags' | 'lib' | 'currency' | 'svg', string] | null) {
    if (c) {
      this.assetUrl = `url(assets/${c[0]}/${c[1].toLowerCase()}.svg)`;
    }
  }

  assetUrl = 'url()';
}
