import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { BasketPurchaseResultTOResponse } from 'api/models';
import { Collection } from 'app/collections/models/collection.model';
import { iconBag } from 'core/icons/lib/icon-bag';
import { iconCheckCircle } from 'core/icons/lib/icon-check-circle';
import { iconChevronDown } from 'core/icons/lib/icon-chevron-down';
import { iconCircleLine } from 'core/icons/lib/icon-circle-line';
import { iconError } from 'core/icons/lib/icon-error';
import { iconOval } from 'core/icons/lib/icon-oval';
import { Street } from 'dashboard/models/street.model';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-collection-checkout-item',
  templateUrl: './collection-checkout-item.component.html',
  styleUrls: ['./collection-checkout-item.component.scss'],
})
export class CollectionCheckoutItemComponent implements OnDestroy {
  readonly circleIcon = iconCircleLine;
  readonly expandIcon = iconChevronDown;
  readonly deleteIcon = iconBag;
  readonly spinIcon = iconOval;
  readonly iconError = iconError;
  readonly iconPending = iconOval;
  readonly iconSuccess = iconCheckCircle;

  @Input() collection = new Collection();
  @Input() streets: Street[] = [];
  @Input() status?: BasketPurchaseResultTOResponse['status'] | 'PENDING';
  @Input() price = 0;

  @Output() removeEmitter = new EventEmitter<void>();
  @Output() removeStreetEmitter = new EventEmitter<Street>();

  expanded = false;

  private destroy$ = new Subject<void>();

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  removeFromCart(): void {
    this.removeEmitter.next();
  }

  removeStreetFromCart(street: Street): void {
    this.removeStreetEmitter.next(street);
  }

  onExpand(expanded: boolean): void {
    this.expanded = expanded;
  }
}
