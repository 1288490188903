import {
  BuildingTOResponse,
  CollectionStakeElementTOResponse,
  NftTO,
  Properties,
} from 'api/models';
import { environment } from 'environments/environment';
import { UserService } from 'profile/services/user.service';

import { FavoriteProvider } from './favoriteProvider.model';
import { dataToNFT, NFT } from './nft.model';
import { StreetLevelType, zoneToLabel, zoneToLevel } from './street.data';

export class Building {
  id!: number;
  assetId!: number;
  marketId!: number;
  cartId!: number;
  lat!: number;
  lng!: number;
  city!: string;
  cityId!: number;
  street!: string;
  streetId!: number;
  country!: string;
  countryCode!: string;
  name!: string;
  buildingNumber!: string;
  fingerprint!: string;
  fingerPrintNetworkCode!: string;
  lastPrice!: number;
  price!: number;
  buyPrice!: number;
  sellPrice!: number;
  isReserved!: boolean;
  isWithdrawn!: boolean;
  types!: Array<string>;
  label!: string;
  level!: StreetLevelType;
  nft!: NFT;
  ownerName!: string;
  isFavorite?: boolean;
  buyAt!: Date;
  valuable!: boolean;
  streetMarketId!: number;
  streetOwnerId!: number;
  streetOwnerName!: string;
  streetIsWithrawn!: boolean;

  collectionCompleted?: boolean;
  collectionStatus!: CollectionStakeElementTOResponse['statusFromBlockchain'];
  collectionId!: number;

  constructor(init: PickData<Building> = dataToBuilding({})) {
    Object.assign(this, init);
  }

  get isOwner(): boolean {
    return (
      !!UserService.instance.id &&
      (this.nft.ownerId === UserService.instance.id ||
        this.nft.ownerId === UserService.instance.accountId.toString())
    );
  }

  get isEnabledActions(): boolean {
    return !this.isWithdrawn;
  }

  get isStaked(): boolean {
    return this.collectionStatus === 'STAKED';
  }

  get canStake(): boolean {
    return this.collectionStatus === 'READY_FOR_STAKE' || this.collectionStatus === 'UNSTAKED';
  }

  get canAddToCart(): boolean {
    return !this.marketId && !!this.streetMarketId;
  }

  get mainName(): string {
    return this.name || `${this.street}, ${this.buildingNumber}`;
  }

  get secondaryName(): string {
    return `${this.city}, ${this.country}`;
  }

  get isAvailable(): boolean {
    return this.sellPrice > 0 || this.marketId === 0;
  }

  get imageUrl(): string {
    return this.id ? `${environment.mainServiceUrl}/api/v1/gis/${this.id}/image/BUILDING/view` : '';
  }

  get marketPrice(): number {
    return this.sellPrice || this.lastPrice;
  }

  get streetViewUrl(): string {
    return `https://maps.googleapis.com/maps/api/streetview?size=900x600&return_error_code=true&source=outdoor&location=${this.lat},${this.lng}&key=${environment.googleMapKey}`;
  }

  get isWAXBlockchain(): boolean {
    return false;
  }

  get fingerprintUrl(): string {
    const url =
      this.fingerPrintNetworkCode !== 'WAX'
        ? environment.transactionExplorerUrl.replace('wax.', '')
        : environment.transactionExplorerUrl;
    return (this.fingerprint && !this.fingerprint?.includes('-')) ? `${url}/${this.fingerprint}` : '';
  }
}

export const dataToBuilding = (
  data: BuildingTOResponse & NftTO & Properties & { cartId?: number } = {},
  marketId?: number,
  favoriteProvider?: FavoriteProvider,
  ref?: Building
): PickData<Building> => ({
  id: data.buildingId || data.id || ref?.id || 0,
  cartId: data.cartId || ref?.cartId || 0,
  assetId: data.assetId || ref?.assetId || 0,
  marketId: marketId || data.marketId || ref?.marketId || 0,
  lat: data.center?.lat || (data.center || [ref?.lat || 0])[1],
  lng: data.center?.lon || (data.center || [ref?.lng || 0])[0],
  city: data.city || ref?.city || '',
  cityId: data.cityId || ref?.cityId || 0,
  street: data.street || data.name || ref?.street || '',
  streetId: data.streetId || ref?.streetId || 0,
  ownerName: data.ownerName || ref?.ownerName || '',
  country: data.country || ref?.country || '',
  countryCode: data.countryCode || ref?.countryCode || '',
  name: data.name || ref?.name || '',
  isWithdrawn: data.isWithdrawn || ref?.isWithdrawn || false,
  isReserved: data.isReserved || ref?.isReserved || false,
  buildingNumber: data.number || ref?.buildingNumber || '',
  fingerprint: data.fingerPrint || ref?.fingerprint || '',
  fingerPrintNetworkCode: data.fingerPrintNetworkCode || ref?.fingerPrintNetworkCode || '',
  price: data.price || 0,
  lastPrice: data.lastPrice || data.price || ref?.lastPrice || 0,
  buyPrice: data.buyingPrice || ref?.buyPrice || 0,
  sellPrice: data.sellPrice || ref?.sellPrice || 0,
  valuable: data.valuable || ref?.valuable || false,
  streetIsWithrawn: data.streetIsWithrawn || ref?.streetIsWithrawn || false,
  collectionCompleted: (data as NftTO).collectionIsCompleted || ref?.collectionCompleted,
  collectionStatus:
    (data as NftTO).collectionStatusFromBlockchain ||
    ref?.collectionStatus ||
    'UNRECOGNIZED_STATUS',
  collectionId: data.collectionId || ref?.collectionId || 0,

  types: data.types || ref?.types || [],
  label: zoneToLabel(data.zone),
  level: zoneToLevel(data.zone),

  buyAt: new Date(data.buyAt || ref?.buyAt || 0),
  isFavorite: favoriteProvider ? favoriteProvider.isFavoriteId(data.id) : false,

  streetMarketId: data.streetMarketId || ref?.streetMarketId || 0,
  streetOwnerId: data.streetOwnerId || ref?.streetOwnerId || 0,
  streetOwnerName: data.streetOwnerName || ref?.streetOwnerName || '',

  nft: new NFT(dataToNFT(data, ref?.nft)),
});
