<ng-container [formGroup]="nftFilterForm">
  <div class="filters">
    <app-nft-search
      [formControl]="nftFilterForm.controls.description"
      class="search-field"></app-nft-search>

    <ng-container *mediaIf="'large'" [ngTemplateOutlet]="responsiveFilters"></ng-container>

    <app-mobile-filters
      (sortChange)="onSortChange($event)"
      *mediaIf="'small only' && 'medium down'"
      [headers]="headers"
      [sortData]="selectedData">
      <ng-container [ngTemplateOutlet]="responsiveFilters" additionalLayout></ng-container>
      <ng-container [ngTemplateOutlet]="handlerButtons" handleButtons></ng-container>
    </app-mobile-filters>
  </div>

  <ng-template #responsiveFilters>
    <app-nft-price-filters
      [formControl]="nftFilterForm.controls.price"
      class="price-range"></app-nft-price-filters>
    <app-nft-plan-filter [formControl]="nftFilterForm.controls.zones"
                         [isCollections]="isCollections" [enable5zone]="enable5zone"></app-nft-plan-filter>
  </ng-template>
</ng-container>

<div *mediaIf="'large'" class="display-flex align-center width-100 flex-1 margin-bottom-lg">
  <ng-container [ngTemplateOutlet]="handlerButtons"></ng-container>

  <ng-container *ngIf="hasItems">
    <app-sort-header
      (sortChange)="onSortChange($event)"
      [headers]="headers"
      [sortData]="selectedData"
      class="data-header width-100"></app-sort-header>
  </ng-container>
</div>

<ng-template #handlerButtons>
  <ng-content></ng-content>
</ng-template>
