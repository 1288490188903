<div class="item selector-tmpl">
  <app-select
    [lightScroll]="true"
    [formControl]="marketsControl"
    class="width-100 selector-tmpl"
    appearance="full-screen"
    [minWidth]="350"
    theme="dark">
    <button
      appAnchor
      appButton="arrow-swap-horizontal"
      class="exchange-button solid primary medium">
      {{ currentMarket?.title?.replace('-', '/') }}
    </button>
    <ng-template>
      <div class="search">
        <app-input
          [formControl]="searchControl"
          class="solid medium width-100 search-input margin-bottom primary-dark"
          extras="search"
          placeholder="Search"></app-input>
      </div>

      <div class="markets">
        <div *ngIf="searchMarketsList.length === 0" class="color-white">
          {{ 'exchange.empty-markets.text' | translate }}
        </div>
        <button
          *ngFor="let a of searchMarketsList | favoritesMarkets"
          [appSelectItem]="a"
          appButton
          class="solid for-select market-item dark">
          <app-exchange-statistic-market-item
            [market]="a"
            (toggleFavoriteMarketEmitter)="
                onToggleFavorite(a)
              "></app-exchange-statistic-market-item>
        </button>
        <button
          *ngFor="let a of searchMarketsList | defaultMarkets"
          [appSelectItem]="a"
          appButton
          class="solid for-select market-item dark">
          <app-exchange-statistic-market-item
            [market]="a"
            (toggleFavoriteMarketEmitter)="
                onToggleFavorite(a)
              "></app-exchange-statistic-market-item>
        </button>
      </div>
    </ng-template>
  </app-select>
</div>

