<ng-container *ngIf="type === 'USER_WANT_BUY_NFT'; else outbid">
  <div class="title weight-semibold display-flex align-center">
    <span
      class="color-primary-light display-flex align-center owner"
      [appPublicProfile]="nftBuyEvent?.buyerUsername || 'User_'">
      <app-icon [icon]="iconProfile" class="font-md"></app-icon>
      <div class="owner margin-left-tn font-sm">
        {{ nftBuyEvent?.buyerUsername || 'User_' | nickname }}
      </div>
    </span>
    <div class="description font-sm">{{ 'main.nft-trade.toast.description-1' | translate }}</div>
  </div>

  <div class="content">
    <div class="street-level-thumb">
      <div class="level-icon-container">
        <app-icon class="icon" [icon]="iconProfile"></app-icon>
      </div>
      <div class="street-info">
        <div class="street-title">
          {{ nftBuyEvent?.street }}
        </div>
        <div class="street-place">
          {{ nftBuyEvent?.city }}
        </div>
      </div>
    </div>

    <div class="street-price">{{ nftBuyEvent?.price | money: 'DWRLD' }}</div>
  </div>
</ng-container>

<ng-template #outbid>
  <div class="title weight-semibold display-flex align-center">
    <span
      class="color-primary-light display-flex align-center owner"
      [appPublicProfile]="nftBuyEvent?.username || 'User_'">
      <app-icon [icon]="iconProfile" class="font-md"></app-icon>
      <div class="owner margin-left-tn font-sm">
        {{ nftBuyEvent?.username || 'User_' | nickname }}
      </div>
    </span>
    <div class="description font-sm">{{ 'main.nft-trade.toast.description-2' | translate }}</div>
  </div>

  <div class="content">
    <div class="street-level-thumb">
      <div class="level-icon-container">
        <app-icon class="icon" [icon]="iconProfile"></app-icon>
      </div>
      <div class="street-info">
        <div class="street-title">
          {{ nftBuyEvent?.street }}
        </div>
        <div class="street-place">
          {{ nftBuyEvent?.city }}
        </div>
      </div>
    </div>

    <div class="display-flex-column">
      <div class="street-price old">{{ 'main.nft-trade.toast.your-bid' | translate: nftBuyEvent?.oldBeat || 0 }}</div>
      <div class="street-price new">{{ nftBuyEvent?.newBeat }} DWRLD</div>
    </div>
  </div>
</ng-template>
