<div class="data-container container-column dark">
  <div #tableHeader [lockPadding]="15" class="table-header" scrollLock>
    <ng-container *mediaIf="'large'; else mobile" [ngTemplateOutlet]="filterForm"></ng-container>

    <ng-template #mobile>
      <app-mobile-filters class="margin-bottom-lg">
        <ng-container [ngTemplateOutlet]="filterForm" additionalLayout></ng-container>
      </app-mobile-filters>
    </ng-template>

    <app-sort-header
      *mediaIf="'large'"
      [headers]="headers"
      (sortChange)="applySortAction($event)"
      class="data-header margin-bottom-lg"
    ></app-sort-header>
  </div>

  <div class="table">
    <div>
      <div #tableBody class="history-list">
        <ng-container *ngIf="history.length">
          <app-history-item
            *ngFor="let order of history; trackBy: identifyHistory"
            class="history-item"
            [order]="order"></app-history-item>
        </ng-container>

        <ng-container *ngIf="!history.length && inProgress">
          <app-history-item
            *ngFor="let order of skeletonItems; trackBy: identifyHistory"
            class="history-item skeleton"
            [order]="order"></app-history-item>
        </ng-container>

        <ng-container *ngIf="!history.length && !inProgress">
          <app-no-data
            [canRedirect]="false"
            [text]="'dashboard.history.empty.text' | translate"
            [title]="'dashboard.history.empty.title' | translate"
            class="darken no-data-item"></app-no-data>
        </ng-container>
      </div>
      <app-pagination
        (changed)="nextPageAction($event)"
        *ngIf="page.totalPages && page.totalPages > 1"
        [current]="page.pageNumber || 1"
        [total]="page.totalPages"
        class="margin-top-lg">
        <ng-template let-current="isCurrent" let-page>
          <button (click)="nextPageAction(page.value)" [ngClass]="{ active: current }" class="page">
            {{ page.label }}
          </button>
        </ng-template>
      </app-pagination>
    </div>
  </div>

  <ng-template #filterForm>
    <app-filter-history-form (emitHistoryFilter)="handleFilter($event)"></app-filter-history-form>
  </ng-template>
</div>
