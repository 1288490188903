/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { MysteryBoxResponse } from '../models/mystery-box-response';
import { MysteryBoxListResponse } from '../models/mystery-box-list-response';
import { MysteryBoxUserLimitTO } from '../models/mystery-box-user-limit-to';
import { MysteryBoxMetaInfoListResponse } from '../models/mystery-box-meta-info-list-response';

/**
 * Mystery Box Controller
 */
@Injectable({
  providedIn: 'root',
})
class MysteryBoxControllerService extends __BaseService {
  static readonly buyBoxUsingPOSTPath = '/api/v1/mystery-box/box-buy';
  static readonly mysteryBoxListUsingGETPath = '/api/v1/mystery-box/box-list';
  static readonly openBoxUsingPOSTPath = '/api/v1/mystery-box/box-open';
  static readonly getUserLimitUsingGETPath = '/api/v1/mystery-box/limit';
  static readonly mysteryMetaBoxListUsingGETPath = '/api/v1/mystery-box/meta-box-list';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * buyBox
   * @param params The `MysteryBoxControllerService.BuyBoxUsingPOSTParams` containing the following parameters:
   *
   * - `idMysteryBoxMetaInfo`: idMysteryBoxMetaInfo
   *
   * - `currency`: currency
   *
   * @return OK
   */
  buyBoxUsingPOSTResponse(params: MysteryBoxControllerService.BuyBoxUsingPOSTParams): __Observable<__StrictHttpResponse<MysteryBoxResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.idMysteryBoxMetaInfo != null) __params = __params.set('idMysteryBoxMetaInfo', params.idMysteryBoxMetaInfo.toString());
    if (params.currency != null) __params = __params.set('currency', params.currency.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/mystery-box/box-buy`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MysteryBoxResponse>;
      })
    );
  }
  /**
   * buyBox
   * @param params The `MysteryBoxControllerService.BuyBoxUsingPOSTParams` containing the following parameters:
   *
   * - `idMysteryBoxMetaInfo`: idMysteryBoxMetaInfo
   *
   * - `currency`: currency
   *
   * @return OK
   */
  buyBoxUsingPOST(params: MysteryBoxControllerService.BuyBoxUsingPOSTParams): __Observable<MysteryBoxResponse> {
    return this.buyBoxUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as MysteryBoxResponse)
    );
  }

  /**
   * mysteryBoxList
   * @param params The `MysteryBoxControllerService.MysteryBoxListUsingGETParams` containing the following parameters:
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `isOpen`: isOpen
   *
   * - `isNotOpen`: isNotOpen
   *
   * @return OK
   */
  mysteryBoxListUsingGETResponse(params: MysteryBoxControllerService.MysteryBoxListUsingGETParams): __Observable<__StrictHttpResponse<MysteryBoxListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.isOpen != null) __params = __params.set('isOpen', params.isOpen.toString());
    if (params.isNotOpen != null) __params = __params.set('isNotOpen', params.isNotOpen.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/mystery-box/box-list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MysteryBoxListResponse>;
      })
    );
  }
  /**
   * mysteryBoxList
   * @param params The `MysteryBoxControllerService.MysteryBoxListUsingGETParams` containing the following parameters:
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `isOpen`: isOpen
   *
   * - `isNotOpen`: isNotOpen
   *
   * @return OK
   */
  mysteryBoxListUsingGET(params: MysteryBoxControllerService.MysteryBoxListUsingGETParams): __Observable<MysteryBoxListResponse> {
    return this.mysteryBoxListUsingGETResponse(params).pipe(
      __map(_r => _r.body as MysteryBoxListResponse)
    );
  }

  /**
   * openBox
   * @param mysteryBoxId mysteryBoxId
   * @return OK
   */
  openBoxUsingPOSTResponse(mysteryBoxId: number): __Observable<__StrictHttpResponse<MysteryBoxResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (mysteryBoxId != null) __params = __params.set('mysteryBoxId', mysteryBoxId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/mystery-box/box-open`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MysteryBoxResponse>;
      })
    );
  }
  /**
   * openBox
   * @param mysteryBoxId mysteryBoxId
   * @return OK
   */
  openBoxUsingPOST(mysteryBoxId: number): __Observable<MysteryBoxResponse> {
    return this.openBoxUsingPOSTResponse(mysteryBoxId).pipe(
      __map(_r => _r.body as MysteryBoxResponse)
    );
  }

  /**
   * getUserLimit
   * @return OK
   */
  getUserLimitUsingGETResponse(): __Observable<__StrictHttpResponse<MysteryBoxUserLimitTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/mystery-box/limit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MysteryBoxUserLimitTO>;
      })
    );
  }
  /**
   * getUserLimit
   * @return OK
   */
  getUserLimitUsingGET(): __Observable<MysteryBoxUserLimitTO> {
    return this.getUserLimitUsingGETResponse().pipe(
      __map(_r => _r.body as MysteryBoxUserLimitTO)
    );
  }

  /**
   * mysteryMetaBoxList
   * @param params The `MysteryBoxControllerService.MysteryMetaBoxListUsingGETParams` containing the following parameters:
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * @return OK
   */
  mysteryMetaBoxListUsingGETResponse(params: MysteryBoxControllerService.MysteryMetaBoxListUsingGETParams): __Observable<__StrictHttpResponse<MysteryBoxMetaInfoListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/mystery-box/meta-box-list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MysteryBoxMetaInfoListResponse>;
      })
    );
  }
  /**
   * mysteryMetaBoxList
   * @param params The `MysteryBoxControllerService.MysteryMetaBoxListUsingGETParams` containing the following parameters:
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * @return OK
   */
  mysteryMetaBoxListUsingGET(params: MysteryBoxControllerService.MysteryMetaBoxListUsingGETParams): __Observable<MysteryBoxMetaInfoListResponse> {
    return this.mysteryMetaBoxListUsingGETResponse(params).pipe(
      __map(_r => _r.body as MysteryBoxMetaInfoListResponse)
    );
  }
}

module MysteryBoxControllerService {

  /**
   * Parameters for buyBoxUsingPOST
   */
  export interface BuyBoxUsingPOSTParams {

    /**
     * idMysteryBoxMetaInfo
     */
    idMysteryBoxMetaInfo: number;

    /**
     * currency
     */
    currency: string;
  }

  /**
   * Parameters for mysteryBoxListUsingGET
   */
  export interface MysteryBoxListUsingGETParams {

    /**
     * pageSize
     */
    pageSize: number;

    /**
     * pageNumber
     */
    pageNumber: number;

    /**
     * isOpen
     */
    isOpen?: boolean;

    /**
     * isNotOpen
     */
    isNotOpen?: boolean;
  }

  /**
   * Parameters for mysteryMetaBoxListUsingGET
   */
  export interface MysteryMetaBoxListUsingGETParams {

    /**
     * pageSize
     */
    pageSize: number;

    /**
     * pageNumber
     */
    pageNumber: number;
  }
}

export { MysteryBoxControllerService }
