<app-menu appearance="full-screen" class="autocomplete search-mobile width-100">
  <ng-template>
    <cdk-virtual-scroll-viewport
      *ngIf="countryList.length; else emptyTmpl"
      [style.height.px]="40 * (countryList.length > 6 ? 6 : countryList.length)"
      class="data-host"
      itemSize="44"
      maxBufferPx="750"
      minBufferPx="650">
      <button
        (click)="select(c)"
        *cdkVirtualFor="let c of countryList"
        appButton
        appMenuItem
        class="solid for-select">
        <app-svg [asset]="['flags', c.code]" class="round tiny"></app-svg>
        {{ c.name }}
      </button>
    </cdk-virtual-scroll-viewport>
    <ng-template #emptyTmpl>
      <button appButton class="solid for-select">
        {{ 'main.country_search.empty' | translate }}
      </button>
    </ng-template>
  </ng-template>
  <app-input
    [extras]="value.code && allowClearButton? 'clear' : 'dropdown'"
    [formControl]="control"
    [ngClass]="{ 'with-value': value.name }"
    [placeholder]="value.name || ('main.country_search.placeholder' | translate)"
    appAnchor
    autocomplete="new-country"
    class="solid medium primary-dark with-flag">
    <app-svg *ngIf="value.code" [asset]="['flags', value.code]" class="round tiny"></app-svg>
  </app-input>
</app-menu>
