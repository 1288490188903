import { Injectable } from '@angular/core';
import {
  BuildingTOResponse,
  MarketTOResponse,
  NftGeoJsonResponse,
  Properties,
  StreetGeoJsonResponse,
} from 'api/models';
import { Building, dataToBuilding } from 'dashboard/models/building.model';
import { FavoriteProvider } from 'dashboard/models/favoriteProvider.model';
import { dataToMarket, Market } from 'dashboard/models/market.model';

export const instanceOfGeoJsonResponse = (
  object: unknown
): object is NftGeoJsonResponse | StreetGeoJsonResponse =>
  !!(object as NftGeoJsonResponse | StreetGeoJsonResponse)?.geometry;

@Injectable({ providedIn: 'root' })
export class BuildingsFactory {
  private buildingsStore: Map<number, Building> = new Map();
  private marketsStore: Map<number, Market> = new Map();
  private favoriteProvider!: FavoriteProvider;

  constructor() {}

  get(id = 0, data?: BuildingTOResponse | NftGeoJsonResponse): Building {
    if (instanceOfGeoJsonResponse(data)) {
      return this.createBuilding(id, data);
    } else {
      const props = data as Properties;
      return this.createBuilding(id, props ? { properties: props } : undefined);
    }
  }

  setupFavoriteProvider(provider: FavoriteProvider): void {
    this.favoriteProvider = provider;
  }

  private createBuilding(id = 0, data?: NftGeoJsonResponse, marketId?: number): Building {
    let building = this.buildingsStore.get(id);
    const props = data?.properties;
    const market = this.marketsStore.get(props?.id || 0);

    if (building) {
      building = data
        ? Object.assign(
            building,
            dataToBuilding(props, market?.id || marketId, this.favoriteProvider, building)
          )
        : building;
      return building;
    } else {
      building = new Building(dataToBuilding(props, market?.id || marketId, this.favoriteProvider));
      this.buildingsStore.set(id, building);
      return building;
    }
  }

  getMarket(id = 0, data: MarketTOResponse = {}): Market {
    let market = this.marketsStore.get(id);
    if (market) {
      market = data ? Object.assign(market, dataToMarket(data)) : market;
      market.street = this.createBuilding(data.nft?.properties?.buildingId, data.nft, data.id);
      return market;
    } else {
      market = new Market(dataToMarket(data));
      this.marketsStore.set(id, market);
      market.street = this.createBuilding(data.nft?.properties?.buildingId, data.nft, data.id);
      return market;
    }
  }

  emptyStore(): void {
    this.buildingsStore.clear();
  }
}
