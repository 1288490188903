import { Injectable } from '@angular/core';
import { DecentWorldMarketplaceTO } from 'api/models';
import { StatisticControllerService } from 'api/services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MarketplaceStatisticService {
  constructor(private statisticController: StatisticControllerService) {}

  getMarketplaceStatistic(): Observable<DecentWorldMarketplaceTO> {
    return this.statisticController.getDecentWorldMarketplaceUsingGET();
  }
}
