import { AppIcon } from 'core/icons/icon.type';

import { NotificationMessageType } from '../enum/notification-message-type';
import { NotificationTabType } from 'profile/enum/notification-tab-type';

export class UserNotification {
  id!: number;
  date?: Date;
  icon?: AppIcon;
  isRead?: boolean;
  title?: LocalizedString;
  short?: LocalizedString;
  long?: LocalizedString<{ [key: string]: string | number | undefined }>;
  type?: NotificationMessageType;
  tabType?: NotificationTabType;
  params?: Record<string, unknown>;

  constructor(init: PickData<UserNotification>) {
    Object.assign(this, init);
  }
}
