import { GenericFormControl, GenericFormGroup } from 'core/utils/form-generics';
import { NftFilterData } from 'dashboard/models/nftFilterData.data';

import { NftFilterPlanForm } from './nft-plan-filter.form';
import { NftFilterPriceForm } from './nft-price-filter.form';

export class NftFilterForm extends GenericFormGroup<NftFilterData> {
  constructor() {
    super({
      cityId: new GenericFormControl<number>(),
      countryCode: new GenericFormControl<string>(),
      description: new GenericFormControl(),
      price: new GenericFormControl<NftFilterPriceForm>(),
      zones: new GenericFormControl<NftFilterPlanForm>(),
    });
  }
}
