import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HistoryTO, PageTO } from 'api/models';
import { appNav, lazyModalsNav } from 'app/app.navigation';
import { WishlistBuildingsService } from 'app/marketplace/services/wishlist-buildings.service';
import { iconBagCross } from 'core/icons/lib/icon-bag-cross';
import { iconCart } from 'core/icons/lib/icon-cart';
import { iconChevronLeft } from 'core/icons/lib/icon-chevron-left';
import { iconStar } from 'core/icons/lib/icon-start';
import { APIError } from 'core/models/error.model';
import { ModalRouter } from 'core/modules/modal';
import { SeoService } from 'core/seo/seo.service';
import { delayAtLeast } from 'core/utils/rxjs/delay-at-least.operator';
import { StreetPreviewModal } from 'dashboard/dashboard.navigation';
import { Building } from 'dashboard/models/building.model';
import { BuildingsService } from 'dashboard/services/buildings.service';
import { CartService } from 'dashboard/services/cart.service';
import { environment } from 'environments/environment';
import { UserService } from 'profile/services/user.service';
import { of, Subject } from 'rxjs';
import {map, mergeMap, switchMap, takeUntil} from 'rxjs/operators';
import { StreetDetailsService } from 'streets/services/street-details.service';
import {CurrencyService} from "core/services/currency.service";
import {GTMService} from "core/services/gtm.service";
import {FbService} from "core/services/fb.service";
import {StreetsService} from "dashboard/services/streets.service";
import {iconLocate} from "core/icons/lib/icon-locate";
import {StreetsMapNavState} from "streets/streets.navigation";
import {navigateByUrl} from "core/utils/router.utils";
import {Street} from "dashboard/models/street.model";

@Component({
  selector: 'app-marketplace-building-overview',
  templateUrl: './marketplace-building-overview.component.html',
  styleUrls: ['./marketplace-building-overview.component.scss'],
  providers: [StreetDetailsService],
})
export class MarketplaceBuildingOverviewComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  readonly chevronIcon = iconChevronLeft;
  readonly iconStar = iconStar;
  readonly iconCart = iconCart;
  readonly iconBagCross = iconBagCross;
  readonly iconLocate = iconLocate;

  building = new Building();
  buildingHistory: HistoryTO[] = [];
  pagination: PageTO = {};
  isError = false;
  isLoading = false;
  userBuyPrice? = 0;

  constructor(
    private activatedRoute: ActivatedRoute,
    private buildingService: BuildingsService,
    private seo: SeoService,
    private modal: ModalRouter,
    private cartService: CartService,
    private streetDetailsService: StreetDetailsService,
    private wishlistService: WishlistBuildingsService,
    private userService: UserService,
    private GTMService: GTMService,
    private streetsService: StreetsService,
    private FbService: FbService,
    private currencyService: CurrencyService,
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.activatedRoute.params
      .pipe(
        switchMap(params =>
          this.buildingService.findBuildingOrMarket(params.buildingId).pipe(
            switchMap(building => {
              this.loadBuildingHistory(building?.nft?.id);
              this.seo.set({
                title: `${building?.marketId ? 'Buy' : 'Mint'} virtual building on ${
                  building?.street
                } ${building?.buildingNumber} in ${
                  building?.city
                } - Virtual Real Estate NFTs at DecentWorld`,
                description: `Purchase virtual building on ${
                  building?.street
                } ${building?.buildingNumber} in ${building?.city}, ${
                  building?.country
                }. Collect virtual real estate NFTs at DecentWorld metaverse`,
                image: `${environment.mainServiceUrl}/api/v1/gis/${building?.id}/image/BUILDING/view?width=1200&height=630&colored=true`,
                imageSize: ['1200px', '630px'],
                canonicalPath: appNav.marketplaceNav.buildingPreview(
                  building?.id || 0,
                  building?.country || '',
                  building?.city || '',
                  building?.name || '',
                  building?.types || []
                ),
              });
              return of(building);
            })
          )
        ),
        delayAtLeast(1000),
        map(building => {
          this.building = building || new Building();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(
        () => {
          this.isLoading = false;
        },
        (error: APIError) => {
          this.isLoading = false;
          if (error.code === 'STREET_NOT_FOUND') {
            error.preventHandling();
          }
          this.isError = true;
        }
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openBuildingOnMap(): void {
    navigateByUrl(appNav.streetsNav.map(), {
      state: {
        buildingId: this.building?.id || 0
      } as StreetsMapNavState,
    });
  }

  loadBuildingHistory(nftId?: number): void {
    this.streetDetailsService
      .getHistory(nftId || 0)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.buildingHistory = data.elements?.[0].elements || [];
        this.pagination = data.pageInfo || {};

        if (this.pagination.pageNumber) {
          this.pagination.pageNumber++;
        }
      });
  }

  onPaginationChange(pagination: PageTO): void {
    this.pagination = { ...pagination };
    this.streetDetailsService.setPaginationParams(pagination);
  }

  toggleFavorite(): void {
    if (!this.userService.user.id) {
      this.modal.navigate(lazyModalsNav.auth.signIn());
      return;
    }
    this.wishlistService.toggleFavorite(this.building);

    if (!this.building.isFavorite) {
      this.GTMService.pushTag({
        'event': 'add_to_wishlist',
        'user_id': this.userService.user.id,
        'ecommerce': {
          items: [{
            'item_name': this.building.name, // street name
            'item_id': this.building.id, // street ID
            'item_country': this.building.country, // street country
            'item_city':this.building.city, // city
            'item_level': this.building.level, // street level
            'item_token': this.building.marketPrice, // street value in DWRLD tokens
            'quantity': '1', // amount of items
            'price': this.currencyService.toDwrldRate(this.building.marketPrice, 'USD') // item worth amount in fiat
          }]
        }
      });
    }
  }

  buyBuilding(): void {
    if (!this.userService.user.id) {
      this.modal.navigate(lazyModalsNav.auth.signIn());
      return;
    }
    this.streetsService
      .findStreetOrMarket(this.building.streetId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((streetRes) => {
        this.modal.navigate(
          lazyModalsNav.streets.buildingBuy(),
          () => {
            this.userBuyPrice = this.building.buyPrice;
          },
          {
            data: {
              entity: this.building,
              isBuilding: true,
              additionalEntity: streetRes
            } as StreetPreviewModal,
          }
        );
      });


    if (!this.building.isOwner) {
      this.sendMetricTags();
    }
  }

  private sendMetricTags(): void {
    const config = {
      'event': 'select_item',
      'user_id': this.userService.user.id,
      'ecommerce': {
        items: [{
          'item_name': this.building.name, // street name
          'item_id': this.building.id, // street ID
          'item_country': this.building.country, // street country
          'item_city': this.building.city, // city
          'item_level': this.building.level, // street level
          'item_token': this.building.lastPrice, // street value in DWRLD tokens
          'quantity': '1', // amount of items
          'price': this.currencyService.toDwrldRate(this.building.lastPrice, 'USD')// item worth amount in fiat
        }]
      }
    };
    this.FbService.pushTag(config);
    this.GTMService.pushTag(config);
  }

  sell(): void {}



  toggleBasketWithFreeStreet(): void {
    this.streetsService
    .findStreetOrMarket(this.building.streetId)
    .pipe(
        mergeMap(streetRes => streetRes?.marketId?
            (this.building.cartId? this.cartService.remove(this.building)
                : this.cartService.add(this.building))
            : this.building.cartId
                ? this.cartService.removeFromStreet(streetRes || new Street(), [this.building])
                : this.cartService.addToStreet(streetRes || new Street(), [this.building])
        ),
        takeUntil(this.destroy$)
    )
    .subscribe();
  }

  private checkBuildingType(type: string[] = []): string {
    if (!type?.length) return 'building';

    const str = type[0].split(':')[0];
    return str !== 'building' ? str : 'building';
  }
}
