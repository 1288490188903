import { Injectable, OnDestroy } from '@angular/core';
import { ChatMessageTO } from 'api/models/chat-message-to';
import { ChatRoomTO } from 'api/models/chat-room-to';
import { DirectMessageTOResponse } from 'api/models/direct-message-toresponse';
import { ChatControllerService } from 'api/services/chat-controller.service';
import { AuthService } from 'auth/services/auth.service';
import { SocketService } from 'core/modules/socket/socket.service';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ChatMessageTOList } from 'api/models/chat-message-tolist';
import { UserService } from 'profile/services/user.service';


export interface ChatMessageSocket {
  eventType: 'CHAT_ROOM_MESSAGE_RECEIVE',
  data: {
    id: number,
    chatMessage: string,
    fromUserUuid: string,
    toUserUuid: string,
    createdAt: number,
    updatedAt: number,
    isRead: boolean,
    readAt: number,
    isAnswered: boolean,
    answeredAt: number,
    fromUserId: number,
    toUserId: number,
    fromUsername: string,
    toUsername: string,
    online: number,
  }
}

export interface ChatRoomModel {
  id?: number;
  name?: string;
  online?: number;
  firstMessageId?: number;
  unreadMessages?: number;
  lastReadMessage?: number;
  messages?: ChatMessageTO[];
}

export interface loadNextPage {
  roomId?: number,
  moveBack?: boolean,
  initialLoad?: boolean;
}

@Injectable({ providedIn: 'root' })
export class ChatRoomService implements OnDestroy {
  rooms?: ChatRoomModel[] = [];
  destroy$ = new Subject();
  readMessage$ = new BehaviorSubject<{ roomId: number, messageId: number }>({ roomId: 0, messageId: 0 });
  loadNextPage$ = new BehaviorSubject<loadNextPage>({ roomId: 0, moveBack: true, initialLoad: false });
  private messageUpdated$ = new BehaviorSubject<{  needToScrollBottom?: boolean }>({  needToScrollBottom: false });
  private debounceTime = 300;
  private maxMessageCount = 300;

  constructor(
    private auth: AuthService,
    private socket: SocketService,
    private userService: UserService,
    private chatApiService: ChatControllerService,
  ) {}

  ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
  }

  loadNextPage(): Observable<loadNextPage> {
    return this.loadNextPage$.asObservable();
  }

  refreshMessage(): Observable<{ needToScrollBottom?: boolean }> {
    return this.messageUpdated$.asObservable();
  }

  subscribeRoom(id: number): Subject<void> {
    const roomDestroy$ = new Subject<void>();
    const subscribeToStatus = () => {
      this.socket.send({
        messageType: 'CHAT_ROOM_SUBSCRIBE',
        chatRoomDataMessageDto: {
          roomId: id
        }
      });
    };
    this.loadNextPage()
      .pipe(
        debounceTime(this.debounceTime),
        switchMap((nextPage: loadNextPage) => {
          if (!nextPage.roomId) {
            return of({});
          }
          const currentRoom = this.rooms?.find(x => x.id === nextPage.roomId);
          const currentMessages = (currentRoom?.messages || []);
          const startMessageId =  nextPage.moveBack ?
            currentMessages[0]?.id || 0 :
            currentMessages[currentMessages.length - 1]?.id || 0;

          if (currentMessages.length >= this.maxMessageCount) {
            return of({});
          }

          return  this.chatApiService.getRoomHistoryUsingPOST({
            roomId: currentRoom?.id || 0,
            amountOfMessages: 20,
            moveBack: nextPage.moveBack || false,
            startMessageId: nextPage.initialLoad ? (currentRoom?.lastReadMessage || 0): startMessageId,
          }).pipe(
            tap((result) => {
              const correctMessages = this.updateMessageList(result.elements);
              if (currentRoom) {
                currentRoom.messages =  nextPage.moveBack ?
                  [ ...correctMessages, ...(currentRoom?.messages || [])] :
                  [ ...(currentRoom?.messages || []), ...correctMessages];
              }
              this.messageUpdated$.next({ needToScrollBottom: nextPage.initialLoad });
            })
          );
        }),
        takeUntil(roomDestroy$)
      )
      .subscribe((result) => { });

    this.auth.loggedIn.pipe(takeUntil(roomDestroy$)).subscribe(() => {
      subscribeToStatus();
    });

    this.socket.reconnected.pipe(takeUntil(roomDestroy$)).subscribe(() => {
      subscribeToStatus();
    });

    this.socket.on<ChatMessageSocket>().pipe(takeUntil(roomDestroy$)).subscribe(e => {
      if (e.eventType === 'CHAT_ROOM_MESSAGE_RECEIVE') {
        const currentRoom = this.rooms?.find(x => x.id === id);

        if (currentRoom) {
          currentRoom.online = e.data.online;
        }

        if (e.data.id !== null) {
          if (currentRoom) {
            currentRoom.unreadMessages = (currentRoom?.unreadMessages || 0) + 1;
          }

          this.addNewMessage(id, {
            ...e.data,
            ownerUuid: e.data.fromUserUuid,
          });
        }
      }
    });
    return roomDestroy$;
  }

  joinRoom(name: string): Observable<ChatRoomTO> {
    return this.chatApiService.joinRoomUsingPOST(name).pipe(

      tap((result) => {
        const exist = this.rooms?.find(x => x.name === result.name);
        const correctMessages = this.updateMessageList(result.messages?.elements);
        if (exist) {
          exist.messages = [ ...correctMessages];
          exist.unreadMessages = result.unreadMessages;
          exist.firstMessageId = result.firstMessageId;
          exist.lastReadMessage = result.lastReadMessage;
        } else {
          this.rooms?.push({
            id: result.id,
            name: result.name,
            messages: correctMessages,
            unreadMessages: result.unreadMessages,
            firstMessageId: result.firstMessageId,
            lastReadMessage: result.lastReadMessage,
          });
        }
      }),
      tap((result) => {
        if ((result.messages?.elements?.length || 0) <= 10 && (result.unreadMessages || 0) <= 10) {
          this.loadNextPage$.next({
            roomId: (result.id || 0),
            moveBack: true,
            initialLoad: true,
          });
        }
      }),
    );
  }

  readMessage(): Observable<ChatRoomTO> {
    return this.readMessage$.asObservable()
      .pipe(
        debounceTime(this.debounceTime),
        switchMap((data) => {
          if (!data.messageId) {
            return of({});
          }
          return this.chatApiService.updateLastReadMessageUsingPOST({
            roomId: data.roomId,
            messageId: data.messageId
          });
        }),
        tap((result) => {
          if (!!result) {
            const currentRoom = this.rooms?.find(x => x.id === result.id);
            if (currentRoom) {
              currentRoom.unreadMessages = result.unreadMessages;
              currentRoom.firstMessageId = result.firstMessageId;
              currentRoom.lastReadMessage = result.lastReadMessage;
            }
          }
        })
      );
  }

  sendMessage(roomId: number, message: string): Observable<DirectMessageTOResponse> {
    const room = this.rooms?.find(x => x.id === roomId);
    if (!message || !roomId || room === undefined) {
      return of({});
    }

    return this.chatApiService.sendRoomMessageUsingPOST({
      message: message.trim(),
      roomId: room.id || 0
    });
  }

  addNewMessage(roomId: number, message: ChatMessageTO): void {
    const exist = this.rooms?.find(x => x.id === roomId);
    const needToScrollBottom = message.ownerUuid === this.userService.user.id;
    if (exist) {
      message.chatMessage = this.updateMessageViewBuilder(message.chatMessage || '');
      exist.messages?.push(message);
      this.messageUpdated$.next({ needToScrollBottom: needToScrollBottom });
    }
  }

  private updateMessageList(messageList?: ChatMessageTO[]): ChatMessageTO[] {
    if (messageList?.length === 0 || !messageList) {
      return [];
    }

    let resultMessageList = [...messageList];
    resultMessageList = resultMessageList.sort((a, b) => (a.createdAt || 0) - (b.createdAt || 0)) || [];
    resultMessageList = resultMessageList.map((item) => {
      const result = {
        ...item,
        chatMessage: this.updateMessageViewBuilder(item.chatMessage || '')
      };
      return result;
    });

    return resultMessageList;
  }

  private updateMessageViewBuilder(message: string): string {
    let result = message;
    result = this.replaceUrl(result);
    result = this.replaceBreaker(result);
    return result;
  }

  private replaceUrl(message: string): string {
    const urlRegexp = new RegExp('(?:(?:https?|ftp|file):\\/\\/|www\\.|ftp\\.)(?:\\([-A-Z0-9+&@#/%=~_|$?!:,.]*\\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\\([-A-Z0-9+&@#/%=~_|$?!:,.]*\\)|[A-Z0-9+&@#/%=~_|$])', 'gim');
    const urlExec = urlRegexp.exec(message);
    const urlReplacedValue = urlExec ? urlExec[0] : '';
    return message.replace(
      urlRegexp,
      `<a href='${urlReplacedValue}' class='link color-primary-light' target='_blank'>${urlReplacedValue}</a>`
    );
  }

  private replaceBreaker(message: string): string {
    const breakerRegexp = new RegExp('\\n', 'gim');
    const breakerExec = breakerRegexp.exec(message);
    const breakerReplacedValue = breakerExec ? breakerExec[0] : '';
   return message.replace(breakerRegexp, `<br/>`);
  }
}
