/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { MoneyTransactionTOResponse } from '../models/money-transaction-toresponse';
import { EOSAccountRequestForm } from '../models/eosaccount-request-form';

/**
 * Crypto Wallet Controller
 */
@Injectable({
  providedIn: 'root',
})
class CryptoWalletControllerService extends __BaseService {
  static readonly getCommissionUsingGETPath = '/crypto_wallet/commission';
  static readonly createEosAccountUsingPOSTPath = '/crypto_wallet/eos_account';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * getCommission
   * @return OK
   */
  getCommissionUsingGETResponse(): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/crypto_wallet/commission`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * getCommission
   * @return OK
   */
  getCommissionUsingGET(): __Observable<number> {
    return this.getCommissionUsingGETResponse().pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * createEosAccount
   * @param form form
   * @return OK
   */
  createEosAccountUsingPOSTResponse(form: EOSAccountRequestForm): __Observable<__StrictHttpResponse<MoneyTransactionTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/crypto_wallet/eos_account`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MoneyTransactionTOResponse>;
      })
    );
  }
  /**
   * createEosAccount
   * @param form form
   * @return OK
   */
  createEosAccountUsingPOST(form: EOSAccountRequestForm): __Observable<MoneyTransactionTOResponse> {
    return this.createEosAccountUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as MoneyTransactionTOResponse)
    );
  }
}

module CryptoWalletControllerService {
}

export { CryptoWalletControllerService }
