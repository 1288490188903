import { Injectable } from '@angular/core';
import { UserInfoTOResponse } from 'api/models';

import { dataToUser, User } from '../models/user.model';

@Injectable({ providedIn: 'root' })
export class UserFactory {
  private store: Map<string, User> = new Map();

  get(data: Partial<UserInfoTOResponse> = {}): User {
    let user = this.store.get(data.uuid || '');
    if (user) {
      return Object.assign(user, dataToUser(data));
    } else {
      user = new User(dataToUser(data));
      this.store.set(user.id, user);
      return user;
    }
  }

  emptyStore(): void {
    this.store.clear();
  }
}
