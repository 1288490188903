import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';

import { EnvIfDirective } from './directives/env-if.directive';
import { FeatureIfDirective } from './directives/feature-if.directive';
import { MediaClassDirective } from './directives/media-class.directive';
import { MediaIfDirective } from './directives/media-if.directive';
import { OsIfDirective } from './directives/platform-if.directive';

const exportedDeclarations = [
  MediaIfDirective,
  MediaClassDirective,
  OsIfDirective,
  FeatureIfDirective,
  EnvIfDirective,
];

@NgModule({
  declarations: [...exportedDeclarations],
  exports: [...exportedDeclarations],
  imports: [CommonModule],
})
export class PlatformModule {
  static forRoot(p: Provider[]): ModuleWithProviders<PlatformModule> {
    return {
      ngModule: PlatformModule,
      providers: [...p],
    };
  }
}
