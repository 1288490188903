import { NgModule } from '@angular/core';

import { ToolbarContentOutletComponent } from './toolbar-content-outlet/toolbar-content-outlet.component';
import { ToolbarOutletComponent } from './toolbar-outlet/toolbar-outlet.component';

@NgModule({
  imports: [],
  declarations: [ToolbarOutletComponent, ToolbarContentOutletComponent],
  exports: [ToolbarOutletComponent, ToolbarContentOutletComponent],
})
export class ToolbarOutletModule {}
