/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UserInfoTOResponse } from '../models/user-info-toresponse';
import { UserInfoTOListResponse } from '../models/user-info-tolist-response';
import { UserInfoPublicTOResponse } from '../models/user-info-public-toresponse';
import { UserInfoPublicTOListResponse } from '../models/user-info-public-tolist-response';

/**
 * User Filter Controller
 */
@Injectable({
  providedIn: 'root',
})
class UserFilterControllerService extends __BaseService {
  static readonly getUserUsingGETPath = '/api/v1/user';
  static readonly getUsersUsingGETPath = '/api/v1/user/list';
  static readonly getPublicUserUsingGETPath = '/api/v1/user/public';
  static readonly getPublicUsersUsingGETPath = '/api/v1/user/public/list';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * getUser
   * @param params The `UserFilterControllerService.GetUserUsingGETParams` containing the following parameters:
   *
   * - `userNameStart`:
   *
   * - `userName`:
   *
   * - `searchValue`:
   *
   * - `email`:
   *
   * @return OK
   */
  getUserUsingGETResponse(params: UserFilterControllerService.GetUserUsingGETParams): __Observable<__StrictHttpResponse<UserInfoTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userNameStart != null) __params = __params.set('userNameStart', params.userNameStart.toString());
    if (params.userName != null) __params = __params.set('userName', params.userName.toString());
    if (params.searchValue != null) __params = __params.set('searchValue', params.searchValue.toString());
    if (params.email != null) __params = __params.set('email', params.email.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/user`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserInfoTOResponse>;
      })
    );
  }
  /**
   * getUser
   * @param params The `UserFilterControllerService.GetUserUsingGETParams` containing the following parameters:
   *
   * - `userNameStart`:
   *
   * - `userName`:
   *
   * - `searchValue`:
   *
   * - `email`:
   *
   * @return OK
   */
  getUserUsingGET(params: UserFilterControllerService.GetUserUsingGETParams): __Observable<UserInfoTOResponse> {
    return this.getUserUsingGETResponse(params).pipe(
      __map(_r => _r.body as UserInfoTOResponse)
    );
  }

  /**
   * getUsers
   * @param params The `UserFilterControllerService.GetUsersUsingGETParams` containing the following parameters:
   *
   * - `userNameStart`:
   *
   * - `userName`:
   *
   * - `searchValue`:
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `email`:
   *
   * @return OK
   */
  getUsersUsingGETResponse(params: UserFilterControllerService.GetUsersUsingGETParams): __Observable<__StrictHttpResponse<UserInfoTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userNameStart != null) __params = __params.set('userNameStart', params.userNameStart.toString());
    if (params.userName != null) __params = __params.set('userName', params.userName.toString());
    if (params.searchValue != null) __params = __params.set('searchValue', params.searchValue.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.email != null) __params = __params.set('email', params.email.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/user/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserInfoTOListResponse>;
      })
    );
  }
  /**
   * getUsers
   * @param params The `UserFilterControllerService.GetUsersUsingGETParams` containing the following parameters:
   *
   * - `userNameStart`:
   *
   * - `userName`:
   *
   * - `searchValue`:
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `email`:
   *
   * @return OK
   */
  getUsersUsingGET(params: UserFilterControllerService.GetUsersUsingGETParams): __Observable<UserInfoTOListResponse> {
    return this.getUsersUsingGETResponse(params).pipe(
      __map(_r => _r.body as UserInfoTOListResponse)
    );
  }

  /**
   * getPublicUser
   * @param params The `UserFilterControllerService.GetPublicUserUsingGETParams` containing the following parameters:
   *
   * - `userNameStart`:
   *
   * - `userName`:
   *
   * - `searchValue`:
   *
   * - `email`:
   *
   * @return OK
   */
  getPublicUserUsingGETResponse(params: UserFilterControllerService.GetPublicUserUsingGETParams): __Observable<__StrictHttpResponse<UserInfoPublicTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userNameStart != null) __params = __params.set('userNameStart', params.userNameStart.toString());
    if (params.userName != null) __params = __params.set('userName', params.userName.toString());
    if (params.searchValue != null) __params = __params.set('searchValue', params.searchValue.toString());
    if (params.email != null) __params = __params.set('email', params.email.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/user/public`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserInfoPublicTOResponse>;
      })
    );
  }
  /**
   * getPublicUser
   * @param params The `UserFilterControllerService.GetPublicUserUsingGETParams` containing the following parameters:
   *
   * - `userNameStart`:
   *
   * - `userName`:
   *
   * - `searchValue`:
   *
   * - `email`:
   *
   * @return OK
   */
  getPublicUserUsingGET(params: UserFilterControllerService.GetPublicUserUsingGETParams): __Observable<UserInfoPublicTOResponse> {
    return this.getPublicUserUsingGETResponse(params).pipe(
      __map(_r => _r.body as UserInfoPublicTOResponse)
    );
  }

  /**
   * getPublicUsers
   * @param params The `UserFilterControllerService.GetPublicUsersUsingGETParams` containing the following parameters:
   *
   * - `userNameStart`:
   *
   * - `userName`:
   *
   * - `searchValue`:
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `email`:
   *
   * @return OK
   */
  getPublicUsersUsingGETResponse(params: UserFilterControllerService.GetPublicUsersUsingGETParams): __Observable<__StrictHttpResponse<UserInfoPublicTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userNameStart != null) __params = __params.set('userNameStart', params.userNameStart.toString());
    if (params.userName != null) __params = __params.set('userName', params.userName.toString());
    if (params.searchValue != null) __params = __params.set('searchValue', params.searchValue.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.email != null) __params = __params.set('email', params.email.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/user/public/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserInfoPublicTOListResponse>;
      })
    );
  }
  /**
   * getPublicUsers
   * @param params The `UserFilterControllerService.GetPublicUsersUsingGETParams` containing the following parameters:
   *
   * - `userNameStart`:
   *
   * - `userName`:
   *
   * - `searchValue`:
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `email`:
   *
   * @return OK
   */
  getPublicUsersUsingGET(params: UserFilterControllerService.GetPublicUsersUsingGETParams): __Observable<UserInfoPublicTOListResponse> {
    return this.getPublicUsersUsingGETResponse(params).pipe(
      __map(_r => _r.body as UserInfoPublicTOListResponse)
    );
  }
}

module UserFilterControllerService {

  /**
   * Parameters for getUserUsingGET
   */
  export interface GetUserUsingGETParams {
    userNameStart?: string;
    userName?: string;
    searchValue?: string;
    email?: string;
  }

  /**
   * Parameters for getUsersUsingGET
   */
  export interface GetUsersUsingGETParams {
    userNameStart?: string;
    userName?: string;
    searchValue?: string;

    /**
     * pageSize
     */
    pageSize?: number;

    /**
     * pageNumber
     */
    pageNumber?: number;
    email?: string;
  }

  /**
   * Parameters for getPublicUserUsingGET
   */
  export interface GetPublicUserUsingGETParams {
    userNameStart?: string;
    userName?: string;
    searchValue?: string;
    email?: string;
  }

  /**
   * Parameters for getPublicUsersUsingGET
   */
  export interface GetPublicUsersUsingGETParams {
    userNameStart?: string;
    userName?: string;
    searchValue?: string;

    /**
     * pageSize
     */
    pageSize?: number;

    /**
     * pageNumber
     */
    pageNumber?: number;
    email?: string;
  }
}

export { UserFilterControllerService }
