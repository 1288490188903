import {
  CollectionStakeElementTOResponse,
  Geometry,
  NftTO,
  Properties,
  StreetGeoJsonResponse,
} from 'api/models';
import { environment } from 'environments/environment';
import { UserService } from 'profile/services/user.service';

import { FavoriteProvider } from './favoriteProvider.model';
import { dataToNFT, NFT } from './nft.model';
import { StreetLevelType, zoneToLabel, zoneToLevel } from './street.data';

export class Street {
  id!: number;
  marketId!: number;
  assetId!: number;
  cartId!: number;
  name!: string;
  city!: string;
  country!: string;
  price!: number;
  lastPrice!: number;
  buyPrice!: number;
  sellPrice!: number;
  label!: string;
  isReserved?: boolean;
  isWithdrawn?: boolean;
  level!: StreetLevelType;
  fingerprint!: string;
  orderId!: number;
  lat!: number;
  lng!: number;
  nft!: NFT;
  fingerPrintNetworkCode!: string;
  isFavorite?: boolean;
  buyAt?: Date;
  updateAt?: Date;
  geometry?: StreetGeoJsonResponse['geometry'];
  collectionCompleted?: boolean;
  collectionStatus!: CollectionStakeElementTOResponse['statusFromBlockchain'];
  collectionId!: number;

  // cartBuildings!: PrivateArray<Building>;

  get isOwner(): boolean {
    return (
      !!UserService.instance.id &&
      (this.nft.ownerId === UserService.instance.id ||
        this.nft.ownerId === UserService.instance.accountId.toString())
    );
  }

  get isStaked(): boolean {
    return this.collectionStatus === 'STAKED';
  }

  get canStake(): boolean {
    return this.collectionStatus === 'READY_FOR_STAKE' || this.collectionStatus === 'UNSTAKED';
  }

  get isAvailable(): boolean {
    return this.sellPrice > 0 || !this.marketId;
  }

  get canAddToCart(): boolean {
    return !this.marketId;
  }

  get marketPrice(): number {
    return this.sellPrice || this.lastPrice;
  }

  get mainName(): string {
    return this.name;
  }

  get secondaryName(): string {
    return `${this.city}, ${this.country}`;
  }

  get imageUrl(): string {
    return this.id ? `${environment.mainServiceUrl}/api/v1/gis/${this.id}/image/STREET/view` : '';
  }

  get isEnabledActions(): boolean {
    return !this.isWithdrawn;
  }

  get streetViewUrl(): string {
    return `https://maps.googleapis.com/maps/api/streetview?size=900x600&return_error_code=true&source=outdoor&location=${this.lat},${this.lng}&key=${environment.googleMapKey}`;
  }

  get isWAXBlockchain(): boolean {
    return this.fingerPrintNetworkCode === 'WAX';
  }

  get fingerprintUrl(): string {
    const url =
      this.fingerPrintNetworkCode !== 'WAX'
        ? environment.transactionExplorerUrl.replace('wax.', '')
        : environment.transactionExplorerUrl;
    return (this.fingerprint && !this.fingerprint?.includes('-')) ? `${url}/${this.fingerprint}` : '';
  }

  constructor(init: PickData<Omit<Street, 'market' | 'user'>> = dataToStreet({})) {
    Object.assign(this, init);
  }
}

export const dataToStreet = (
  data: Properties & { cartId?: number; orderId?: number; isWithdrawn?: boolean } = {},
  geometry?: Geometry,
  favoriteProvider?: FavoriteProvider,
  marketId?: number,
  ref?: Street,
  forceSave = false
): PickData<Omit<Street, 'market' | 'user'>> => ({
  id: data.streetId || data?.id || 0,
  marketId: marketId || (data as { marketId: number }).marketId || 0,
  assetId: (data as { assetId?: number })?.assetId || ref?.assetId || 0,
  cartId: data.cartId || ref?.cartId || 0,
  price: data.price || 0,
  lastPrice: (data as { lastPrice: number }).lastPrice || data.price || (forceSave ? 0 : ref?.lastPrice) || 0,
  buyPrice: data.buyingPrice || ref?.buyPrice || 0,
  sellPrice: data.sellPrice ||  (forceSave ? 0 : ref?.sellPrice) || 0,
  name: data.name || (data as NftTO).street || ref?.name || '',
  buyAt: new Date(data.buyAt || ref?.buyAt || 0),
  updateAt: new Date(data.updateAt || ref?.updateAt || 0),
  city: data.city || '',
  country: data.country || '',
  isReserved: data.isReserved || ref?.isReserved || false,
  isWithdrawn: data.isWithdrawn || ref?.isWithdrawn || false,
  orderId: data.orderId || ref?.orderId || 0,
  label: zoneToLabel(data.zone),
  level: zoneToLevel(data.zone),
  lat: (data.center || [ref?.lat || 0])[1],
  lng: (data.center || [ref?.lng || 0])[0],
  fingerprint: data.fingerPrint || ref?.fingerprint || '',
  fingerPrintNetworkCode: data.fingerPrintNetworkCode || ref?.fingerPrintNetworkCode || '',
  isFavorite: favoriteProvider ? favoriteProvider.isFavoriteId(data.streetId) : false,
  geometry: geometry?.coordinates?.length ? geometry : ref?.geometry,
  collectionCompleted:
    typeof (data as NftTO).collectionIsCompleted === 'boolean'
      ? (data as NftTO).collectionIsCompleted
      : ref?.collectionCompleted,
  collectionStatus:
    (data as NftTO).collectionStatusFromBlockchain ||
    ref?.collectionStatus ||
    'UNRECOGNIZED_STATUS',
  collectionId: data.collectionId || ref?.collectionId || 0,
  nft: new NFT(dataToNFT(data, ref?.nft)),
});
//
