import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ModalComponent } from './components/modal.component';
import { OutletWrapperComponent } from './components/outlet-wrapper.component';

@NgModule({
  declarations: [ModalComponent, OutletWrapperComponent],
  imports: [CommonModule, RouterModule],
  exports: [ModalComponent],
})
export class ModalModule {}
