import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { RouteData } from 'core/models/route.data';
import { Observable, of, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class CustomPreloadingStrategy implements PreloadingStrategy {
  preload(route: Route, load: () => Observable<void>): Observable<void | null> {
    const routeData: RouteData = route.data || {};
    if (routeData.preload) {
      const delay: number = +(routeData.preloadDelay || 0);
      console.log(`[ROUTER]: Preload called on ${route.path} delay is ${delay}`);
      return delay ? timer(delay).pipe(mergeMap(_ => load())) : load();
    } else {
      return of(null);
    }
  }
}
