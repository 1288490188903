import { Pipe, PipeTransform } from '@angular/core';
import { NFTBuyEvent } from 'dashboard/models/nft-buy-event.model';

@Pipe({
  name: 'streetPriceSum',
})
export class StreetPriceSumPipe implements PipeTransform {
  transform(nftBuyEvent: NFTBuyEvent[]): number {
    return nftBuyEvent.reduce((acc, curr) => acc + curr.lastPrice, 0);
  }
}
