import { ComponentRef, Injectable, Type, ViewContainerRef } from '@angular/core';
import { asyncScheduler, BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class FixedCardService {
  isActive = false;
  isForcedActive = false;

  isClose = true;

  private componentInstance$ = new BehaviorSubject<unknown>(undefined);
  private viewContainerRef!: ViewContainerRef;
  private _isNotificationsActive = false;
  private _isCheckoutActive = false;

  constructor() {}

  isOpened(): Observable<boolean> {
    return this.componentInstance$.asObservable().pipe(map(v => Boolean(v)));
  }

  get isNotificationsActive(): boolean {
    return this._isNotificationsActive;
  }

  setIsNotificationsActive(value: boolean): void {
    this._isNotificationsActive = value;
  }

  get isCheckoutActive(): boolean {
    return this._isCheckoutActive;
  }

  setIsCheckoutActive(value: boolean): void {
    this._isCheckoutActive = value;
  }

  initViewContainerRef(viewContainerRef: ViewContainerRef): void {
    this.viewContainerRef = viewContainerRef;
  }

  openCard<T, S>(componentType: Type<T>, componentData?: S): void {
    if (this.componentInstance$.getValue()) {
      this.hideCard();
    }
    const componentInstance: ComponentRef<T> = this.viewContainerRef.createComponent(componentType);
    if (componentData && componentInstance) {
      Object.keys(componentData).forEach((key: string) => {
        // @ts-ignore
        componentInstance.instance[key] = componentData[key];
      });
    }
    this.componentInstance$.next(componentInstance);
  }

  hideCard(closeAll = false): void {
    this.viewContainerRef.clear();
    this.componentInstance$.next(undefined);

    if (closeAll) {
      this.setIsCheckoutActive(false);
      this.setIsNotificationsActive(false);
    }
  }

  toggleCard(): void {
    if (this.isClose) {
      this.isClose = false;
      asyncScheduler.schedule(() => {
        this.isActive = true;
        document.body.classList.add('cart-overlay');
      }, 100);
    } else {
      this.isActive = false;
      document.body.classList.remove('cart-overlay');
      asyncScheduler.schedule(() => {
        this.isClose = true;
      }, 400);
    }
  }
}
