import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from 'profile/services/user.service';

@Pipe({
  name: 'nickname',
})
export class NicknamePipe implements PipeTransform {
  constructor(private userService: UserService) {}

  transform(username: string | undefined, ownName = 'You', userUid?: string): string | undefined {
    if (
      (username === this.userService.user.username && this.userService.user.username && ownName) ||
      (userUid && this.userService.user.id === userUid)
    ) {
      return ownName;
    }
    if (username === 'DecentAdmin') {
      return 'DecentWorld';
    }
    if (username) {
      return username.includes('User_') ? 'Anonymous' : username;
    }
    return 'DecentWorld';
  }
}
