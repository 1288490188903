/* eslint-disable prefer-arrow-functions/prefer-arrow-functions */
import { MoneyTransactionTOResponse } from 'api/models/money-transaction-toresponse';
import { ExternalWalletsControllerService } from 'api/services/external-wallets-controller.service';
import { createNavigation } from 'core/utils/router.utils';
import { Order } from 'dashboard/models/order.model';
import { NFTEntity } from 'dashboard/models/street.data';

import { EntityType } from './../dashboard/models/street.data';

export interface TransactionNftWithdrawModal {
  nftId?: number;
  assetId?: number;
  network?: MoneyTransactionTOResponse['network'];
  blockchainTxIds?: string;
  entity?: NFTEntity;
  entityType: EntityType;
}

export interface BuyDcoinModalData {
  amount: number;
}

export interface CashierCryptoData {
  type: 'deposit' | 'exchange' | 'withdraw';
  account: number;
}

export interface CashierSuccessModal {
  type: 'deposit' | 'market' | 'limit' | 'withdraw';
}

export interface CashierWithdrawalConfirm {
  token: string;
  withdrawalId: string;
}

export interface CashierHistoryItemModal {
  order: Order;
}

export interface CashierTransactionItemModal {
  transaction: MoneyTransactionTOResponse;
}

export interface ImportPrivateWalletItemModal {
  network?: ExternalWalletsControllerService.ExternalWalletUserListUsingPUTParams['network'];
  currency?: string;
}

export enum MyBalancesSections {
  privateWallets = 'PRIVATE_WALLETS',
}

export const cashierRoot = 'wallet';

export function myBalances(): string {
  return '';
}

export function openOrders(): string {
  return 'open-orders';
}

export function ordersHistory(): string {
  return 'orders-history';
}

export function transactions(): string {
  return 'transactions';
}

export function confirmWithdraw(id: string, token: string): string {
  return `confirm-withdraw/${id}/${token}`;
}

export function externalDeposit(): string {
  return `buy-dwrld`;
}

// Modals
export function transactionItemModal(): string {
  return 'cashier-transaction-item-modal';
}

export function historyItemModal(): string {
  return 'cashier-history-item-modal';
}

export function cryptoModal(): string {
  return 'cashier-crypto';
}

export function orderSuccessModal(): string {
  return 'order-success';
}

export function transferDCoinModal(): string {
  return 'withdraw-dcoin';
}

export function buyDcoinModal(): string {
  return 'buy-dcoin';
}
export function createPrivateEosWalletModal(): string {
  return 'create-private-eos-wallet';
}
export function importPrivateWalletModal(): string {
  return 'import-private-wallet';
}
export function connectToPrivateWalletsModal(): string {
  return 'connect-to-private-wallets';
}

export function transactionNftWithdrawModal(): string {
  return 'transaction-nft-withdraw-modal';
}

export const paths = {
  myBalances,
  openOrders,
  transactions,
  ordersHistory,
  confirmWithdraw,
  externalDeposit,
};

export const modalPaths = {
  crypto: cryptoModal,
  orderSuccess: orderSuccessModal,
  historyItem: historyItemModal,
  transactionItem: transactionItemModal,
  transferDCoin: transferDCoinModal,
  buyDcoin: buyDcoinModal,
  createPrivateEosWallet: createPrivateEosWalletModal,
  importPrivateWallet: importPrivateWalletModal,
  connectToPrivateWallets: connectToPrivateWalletsModal,
  transactionNftWithdraw: transactionNftWithdrawModal,
};

export const cashierNav = createNavigation(paths, cashierRoot);
export const cashierModalsNav = createNavigation(modalPaths, `${cashierRoot}-modals`);
