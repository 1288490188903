import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, UrlTree } from '@angular/router';
import { AppLocale } from 'core/modules/i18n/app-locale.service';
import { asyncScheduler } from 'rxjs';
import { filter, first } from 'rxjs/operators';

import { ModalComponent } from '../components/modal.component';
import { modalCallback, ModalExtras } from '../data/modal.data';

@Injectable({ providedIn: 'root' })
export class ModalRouter {
  router!: Router;

  isCloseAction = false;
  private modalHost!: ModalComponent;
  private activating = false;

  get isActivated(): boolean {
    return this.modalHost ? this.modalHost.isActivated : false;
  }

  get isActivating(): boolean {
    return this.activating;
  }

  constructor(private location: Location, private locale: AppLocale) {}

  init(modalHost: ModalComponent): void {
    this.modalHost = modalHost;
  }

  initRouter(router: Router): void {
    this.router = router;
    router.events.subscribe(e => {
      if (e instanceof NavigationStart) {
        this.activating = /\(primaryModal:.[^)]*\)/.exec(e.url) !== null;
      } else if (e instanceof NavigationEnd) {
        this.activating = false;
      }
    });
  }

  navigate<R>(
    url: string,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    callback: modalCallback<R> = () => {},
    extras: ModalExtras = {}
  ): Promise<boolean | void> {
    extras.queryParamsHandling = 'merge';
    if (extras.skipLocationChange === undefined && extras.replaceUrl === undefined) {
      extras.skipLocationChange = true;
    }
    this.modalHost.activated.pipe(first()).subscribe(component => {
      let callbackResult: unknown;
      const cbWrapper: modalCallback = r => {
        callbackResult = r;
      };
      if (component.registerModalCallback) {
        component.registerModalCallback(cbWrapper);
      }
      if (component.onModalInit) {
        component.onModalInit(extras.data || {});
      }
      const sub = this.modalHost.deactivated.pipe(filter(c => c === component)).subscribe(() => {
        sub.unsubscribe();
        asyncScheduler.schedule(() => {
          callback(callbackResult as R);
        });
      });
    });
    this.isCloseAction = false;
    const isLocationReplaced = this.location.path() !== this.router.url;
    const path = this.location.path();
    return this.router
      .navigate(['', { outlets: { primaryModal: url.replace(/^\//, '') } }], extras)
      .then(res => {
        // same page navigation
        if (res === null && extras.toggle) {
          void this.close();
        }
        if (isLocationReplaced) {
          this.location.replaceState(path);
        }
      });
  }

  close(): Promise<boolean> {
    const outlets: string = (/\((.*)\)/.exec(this.router.url) || ['', ''])[1]
      .split('//')
      .filter(v => !v.startsWith('primaryModal'))
      .join('//');
    let url = this.router.url.replace(/\(.*\)/, '');
    this.isCloseAction = true;
    url = this.locale.cleanUrl(`/${url}`);
    return this.router.navigateByUrl(`${url}${outlets ? `(${outlets})` : ''}`, {
      skipLocationChange: true,
    });
  }

  clearUrl<T extends UrlTree | string>(url: T): T {
    if (typeof url === 'string') {
      return (url || this.router.url).replace(/\(primaryModal:.[^)]*\)/gi, '') as T;
    } else {
      delete url.root.children.primaryModal;
      return url;
    }
  }
}
