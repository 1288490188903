/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { NftTOListResponse } from '../models/nft-tolist-response';
import { NftTOList } from '../models/nft-tolist';
import { NftHistoryTOList } from '../models/nft-history-tolist';
import { NftOrderHistoryTOList } from '../models/nft-order-history-tolist';
import { NftListPriceCheckResponse } from '../models/nft-list-price-check-response';
import { NftStatisticTOResponse } from '../models/nft-statistic-toresponse';
import { NftGeoJsonResponse } from '../models/nft-geo-json-response';
import { NftTransferForm } from '../models/nft-transfer-form';

/**
 * Nft Controller
 */
@Injectable({
  providedIn: 'root',
})
class NftControllerService extends __BaseService {
  static readonly listUsingGET14Path = '/api/v1/nft/list';
  static readonly myNftUsingGETPath = '/api/v1/nft/my-asset';
  static readonly myFavoriteUsingGETPath = '/api/v1/nft/my-favorite';
  static readonly getNftHistoryUsingGETPath = '/api/v1/nft/nft/history';
  static readonly getNftOrderHistoryUsingGETPath = '/api/v1/nft/nft/order-history';
  static readonly nftPriceListUsingGETPath = '/api/v1/nft/price-check';
  static readonly publicAssetsUsingGETPath = '/api/v1/nft/public-asset';
  static readonly publicStatisticUsingGETPath = '/api/v1/nft/public-statistic';
  static readonly statisticUsingGETPath = '/api/v1/nft/statistic';
  static readonly suggestionForCollectionUsingGET1Path = '/api/v1/nft/suggestion-for-collection';
  static readonly transferUsingPOSTPath = '/api/v1/nft/transfer';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * list
   * @param params The `NftControllerService.ListUsingGET14Params` containing the following parameters:
   *
   * - `zones`:
   *
   * - `zone`:
   *
   * - `walletIds`:
   *
   * - `walletId`:
   *
   * - `uuids`:
   *
   * - `types`:
   *
   * - `toPrice`:
   *
   * - `streetIds`:
   *
   * - `streetId`:
   *
   * - `street`:
   *
   * - `sort`:
   *
   * - `priceMin`:
   *
   * - `priceMax`:
   *
   * - `priceBuy`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `pageFilter.sort`:
   *
   * - `pageFilter.pageSize`:
   *
   * - `pageFilter.pageNumber`:
   *
   * - `pageFilter.offset`:
   *
   * - `ownerUuids`:
   *
   * - `ownerUuid`:
   *
   * - `ownerName`:
   *
   * - `nftIds`:
   *
   * - `lastPrice`:
   *
   * - `isWithdrawn`:
   *
   * - `isReserved`:
   *
   * - `ids`:
   *
   * - `id`:
   *
   * - `fromPrice`:
   *
   * - `discriminators`:
   *
   * - `description`:
   *
   * - `currencyIds`:
   *
   * - `currencyId`:
   *
   * - `countryCodes`:
   *
   * - `countryCode`:
   *
   * - `country`:
   *
   * - `collectionIds`:
   *
   * - `collectionId`:
   *
   * - `cityIds`:
   *
   * - `cityId`:
   *
   * - `city`:
   *
   * - `buyingPrice`:
   *
   * - `buildingIds`:
   *
   * - `assetIds`:
   *
   * @return OK
   */
  listUsingGET14Response(params: NftControllerService.ListUsingGET14Params): __Observable<__StrictHttpResponse<NftTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.zones || []).forEach(val => {if (val != null) __params = __params.append('zones', val.toString())});
    if (params.zone != null) __params = __params.set('zone', params.zone.toString());
    (params.walletIds || []).forEach(val => {if (val != null) __params = __params.append('walletIds', val.toString())});
    if (params.walletId != null) __params = __params.set('walletId', params.walletId.toString());
    (params.uuids || []).forEach(val => {if (val != null) __params = __params.append('uuids', val.toString())});
    (params.types || []).forEach(val => {if (val != null) __params = __params.append('types', val.toString())});
    if (params.toPrice != null) __params = __params.set('toPrice', params.toPrice.toString());
    (params.streetIds || []).forEach(val => {if (val != null) __params = __params.append('streetIds', val.toString())});
    if (params.streetId != null) __params = __params.set('streetId', params.streetId.toString());
    if (params.street != null) __params = __params.set('street', params.street.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.priceMin != null) __params = __params.set('priceMin', params.priceMin.toString());
    if (params.priceMax != null) __params = __params.set('priceMax', params.priceMax.toString());
    if (params.priceBuy != null) __params = __params.set('priceBuy', params.priceBuy.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    (params.pageFilterSort || []).forEach(val => {if (val != null) __params = __params.append('pageFilter.sort', val.toString())});
    if (params.pageFilterPageSize != null) __params = __params.set('pageFilter.pageSize', params.pageFilterPageSize.toString());
    if (params.pageFilterPageNumber != null) __params = __params.set('pageFilter.pageNumber', params.pageFilterPageNumber.toString());
    if (params.pageFilterOffset != null) __params = __params.set('pageFilter.offset', params.pageFilterOffset.toString());
    (params.ownerUuids || []).forEach(val => {if (val != null) __params = __params.append('ownerUuids', val.toString())});
    if (params.ownerUuid != null) __params = __params.set('ownerUuid', params.ownerUuid.toString());
    if (params.ownerName != null) __params = __params.set('ownerName', params.ownerName.toString());
    (params.nftIds || []).forEach(val => {if (val != null) __params = __params.append('nftIds', val.toString())});
    if (params.lastPrice != null) __params = __params.set('lastPrice', params.lastPrice.toString());
    if (params.isWithdrawn != null) __params = __params.set('isWithdrawn', params.isWithdrawn.toString());
    if (params.isReserved != null) __params = __params.set('isReserved', params.isReserved.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.fromPrice != null) __params = __params.set('fromPrice', params.fromPrice.toString());
    (params.discriminators || []).forEach(val => {if (val != null) __params = __params.append('discriminators', val.toString())});
    if (params.description != null) __params = __params.set('description', params.description.toString());
    (params.currencyIds || []).forEach(val => {if (val != null) __params = __params.append('currencyIds', val.toString())});
    if (params.currencyId != null) __params = __params.set('currencyId', params.currencyId.toString());
    (params.countryCodes || []).forEach(val => {if (val != null) __params = __params.append('countryCodes', val.toString())});
    if (params.countryCode != null) __params = __params.set('countryCode', params.countryCode.toString());
    if (params.country != null) __params = __params.set('country', params.country.toString());
    (params.collectionIds || []).forEach(val => {if (val != null) __params = __params.append('collectionIds', val.toString())});
    if (params.collectionId != null) __params = __params.set('collectionId', params.collectionId.toString());
    (params.cityIds || []).forEach(val => {if (val != null) __params = __params.append('cityIds', val.toString())});
    if (params.cityId != null) __params = __params.set('cityId', params.cityId.toString());
    if (params.city != null) __params = __params.set('city', params.city.toString());
    if (params.buyingPrice != null) __params = __params.set('buyingPrice', params.buyingPrice.toString());
    (params.buildingIds || []).forEach(val => {if (val != null) __params = __params.append('buildingIds', val.toString())});
    (params.assetIds || []).forEach(val => {if (val != null) __params = __params.append('assetIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/nft/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NftTOListResponse>;
      })
    );
  }
  /**
   * list
   * @param params The `NftControllerService.ListUsingGET14Params` containing the following parameters:
   *
   * - `zones`:
   *
   * - `zone`:
   *
   * - `walletIds`:
   *
   * - `walletId`:
   *
   * - `uuids`:
   *
   * - `types`:
   *
   * - `toPrice`:
   *
   * - `streetIds`:
   *
   * - `streetId`:
   *
   * - `street`:
   *
   * - `sort`:
   *
   * - `priceMin`:
   *
   * - `priceMax`:
   *
   * - `priceBuy`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `pageFilter.sort`:
   *
   * - `pageFilter.pageSize`:
   *
   * - `pageFilter.pageNumber`:
   *
   * - `pageFilter.offset`:
   *
   * - `ownerUuids`:
   *
   * - `ownerUuid`:
   *
   * - `ownerName`:
   *
   * - `nftIds`:
   *
   * - `lastPrice`:
   *
   * - `isWithdrawn`:
   *
   * - `isReserved`:
   *
   * - `ids`:
   *
   * - `id`:
   *
   * - `fromPrice`:
   *
   * - `discriminators`:
   *
   * - `description`:
   *
   * - `currencyIds`:
   *
   * - `currencyId`:
   *
   * - `countryCodes`:
   *
   * - `countryCode`:
   *
   * - `country`:
   *
   * - `collectionIds`:
   *
   * - `collectionId`:
   *
   * - `cityIds`:
   *
   * - `cityId`:
   *
   * - `city`:
   *
   * - `buyingPrice`:
   *
   * - `buildingIds`:
   *
   * - `assetIds`:
   *
   * @return OK
   */
  listUsingGET14(params: NftControllerService.ListUsingGET14Params): __Observable<NftTOListResponse> {
    return this.listUsingGET14Response(params).pipe(
      __map(_r => _r.body as NftTOListResponse)
    );
  }

  /**
   * myNft
   * @param params The `NftControllerService.MyNftUsingGETParams` containing the following parameters:
   *
   * - `zones`: zones
   *
   * - `toPrice`: toPrice
   *
   * - `sort`: sort
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `fromPrice`: fromPrice
   *
   * - `discriminators`: discriminators
   *
   * - `description`: description
   *
   * - `countryCode`: countryCode
   *
   * - `collectionId`: collectionId
   *
   * - `cityId`: cityId
   *
   * @return OK
   */
  myNftUsingGETResponse(params: NftControllerService.MyNftUsingGETParams): __Observable<__StrictHttpResponse<NftTOList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.zones || []).forEach(val => {if (val != null) __params = __params.append('zones', val.toString())});
    if (params.toPrice != null) __params = __params.set('toPrice', params.toPrice.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.fromPrice != null) __params = __params.set('fromPrice', params.fromPrice.toString());
    (params.discriminators || []).forEach(val => {if (val != null) __params = __params.append('discriminators', val.toString())});
    if (params.description != null) __params = __params.set('description', params.description.toString());
    if (params.countryCode != null) __params = __params.set('countryCode', params.countryCode.toString());
    if (params.collectionId != null) __params = __params.set('collectionId', params.collectionId.toString());
    if (params.cityId != null) __params = __params.set('cityId', params.cityId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/nft/my-asset`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NftTOList>;
      })
    );
  }
  /**
   * myNft
   * @param params The `NftControllerService.MyNftUsingGETParams` containing the following parameters:
   *
   * - `zones`: zones
   *
   * - `toPrice`: toPrice
   *
   * - `sort`: sort
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `fromPrice`: fromPrice
   *
   * - `discriminators`: discriminators
   *
   * - `description`: description
   *
   * - `countryCode`: countryCode
   *
   * - `collectionId`: collectionId
   *
   * - `cityId`: cityId
   *
   * @return OK
   */
  myNftUsingGET(params: NftControllerService.MyNftUsingGETParams): __Observable<NftTOList> {
    return this.myNftUsingGETResponse(params).pipe(
      __map(_r => _r.body as NftTOList)
    );
  }

  /**
   * myFavorite
   * @param params The `NftControllerService.MyFavoriteUsingGETParams` containing the following parameters:
   *
   * - `zones`: zones
   *
   * - `toPrice`: toPrice
   *
   * - `sort`: sort
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `fromPrice`: fromPrice
   *
   * - `description`: description
   *
   * @return OK
   */
  myFavoriteUsingGETResponse(params: NftControllerService.MyFavoriteUsingGETParams): __Observable<__StrictHttpResponse<NftTOList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.zones || []).forEach(val => {if (val != null) __params = __params.append('zones', val.toString())});
    if (params.toPrice != null) __params = __params.set('toPrice', params.toPrice.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.fromPrice != null) __params = __params.set('fromPrice', params.fromPrice.toString());
    if (params.description != null) __params = __params.set('description', params.description.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/nft/my-favorite`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NftTOList>;
      })
    );
  }
  /**
   * myFavorite
   * @param params The `NftControllerService.MyFavoriteUsingGETParams` containing the following parameters:
   *
   * - `zones`: zones
   *
   * - `toPrice`: toPrice
   *
   * - `sort`: sort
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `fromPrice`: fromPrice
   *
   * - `description`: description
   *
   * @return OK
   */
  myFavoriteUsingGET(params: NftControllerService.MyFavoriteUsingGETParams): __Observable<NftTOList> {
    return this.myFavoriteUsingGETResponse(params).pipe(
      __map(_r => _r.body as NftTOList)
    );
  }

  /**
   * getNftHistory
   * @param params The `NftControllerService.GetNftHistoryUsingGETParams` containing the following parameters:
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `nftId`: nftId
   *
   * @return OK
   */
  getNftHistoryUsingGETResponse(params: NftControllerService.GetNftHistoryUsingGETParams): __Observable<__StrictHttpResponse<NftHistoryTOList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.nftId != null) __params = __params.set('nftId', params.nftId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/nft/nft/history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NftHistoryTOList>;
      })
    );
  }
  /**
   * getNftHistory
   * @param params The `NftControllerService.GetNftHistoryUsingGETParams` containing the following parameters:
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `nftId`: nftId
   *
   * @return OK
   */
  getNftHistoryUsingGET(params: NftControllerService.GetNftHistoryUsingGETParams): __Observable<NftHistoryTOList> {
    return this.getNftHistoryUsingGETResponse(params).pipe(
      __map(_r => _r.body as NftHistoryTOList)
    );
  }

  /**
   * getNftOrderHistory
   * @param params The `NftControllerService.GetNftOrderHistoryUsingGETParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `zones`:
   *
   * - `sort`:
   *
   * - `side`:
   *
   * - `priceTo`:
   *
   * - `priceFrom`:
   *
   * - `orderType`:
   *
   * - `nftType`:
   *
   * - `nftId`:
   *
   * - `name`:
   *
   * - `id`:
   *
   * - `closedTo`:
   *
   * - `closedFrom`:
   *
   * - `closeReason`:
   *
   * @return OK
   */
  getNftOrderHistoryUsingGETResponse(params: NftControllerService.GetNftOrderHistoryUsingGETParams): __Observable<__StrictHttpResponse<NftOrderHistoryTOList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    (params.zones || []).forEach(val => {if (val != null) __params = __params.append('zones', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.side != null) __params = __params.set('side', params.side.toString());
    if (params.priceTo != null) __params = __params.set('priceTo', params.priceTo.toString());
    if (params.priceFrom != null) __params = __params.set('priceFrom', params.priceFrom.toString());
    if (params.orderType != null) __params = __params.set('orderType', params.orderType.toString());
    if (params.nftType != null) __params = __params.set('nftType', params.nftType.toString());
    if (params.nftId != null) __params = __params.set('nftId', params.nftId.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.closedTo != null) __params = __params.set('closedTo', params.closedTo.toString());
    if (params.closedFrom != null) __params = __params.set('closedFrom', params.closedFrom.toString());
    if (params.closeReason != null) __params = __params.set('closeReason', params.closeReason.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/nft/nft/order-history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NftOrderHistoryTOList>;
      })
    );
  }
  /**
   * getNftOrderHistory
   * @param params The `NftControllerService.GetNftOrderHistoryUsingGETParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `zones`:
   *
   * - `sort`:
   *
   * - `side`:
   *
   * - `priceTo`:
   *
   * - `priceFrom`:
   *
   * - `orderType`:
   *
   * - `nftType`:
   *
   * - `nftId`:
   *
   * - `name`:
   *
   * - `id`:
   *
   * - `closedTo`:
   *
   * - `closedFrom`:
   *
   * - `closeReason`:
   *
   * @return OK
   */
  getNftOrderHistoryUsingGET(params: NftControllerService.GetNftOrderHistoryUsingGETParams): __Observable<NftOrderHistoryTOList> {
    return this.getNftOrderHistoryUsingGETResponse(params).pipe(
      __map(_r => _r.body as NftOrderHistoryTOList)
    );
  }

  /**
   * nftPriceList
   * @param streetIds streetIds
   * @return OK
   */
  nftPriceListUsingGETResponse(streetIds?: Array<number>): __Observable<__StrictHttpResponse<NftListPriceCheckResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (streetIds || []).forEach(val => {if (val != null) __params = __params.append('streetIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/nft/price-check`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NftListPriceCheckResponse>;
      })
    );
  }
  /**
   * nftPriceList
   * @param streetIds streetIds
   * @return OK
   */
  nftPriceListUsingGET(streetIds?: Array<number>): __Observable<NftListPriceCheckResponse> {
    return this.nftPriceListUsingGETResponse(streetIds).pipe(
      __map(_r => _r.body as NftListPriceCheckResponse)
    );
  }

  /**
   * publicAssets
   * @param params The `NftControllerService.PublicAssetsUsingGETParams` containing the following parameters:
   *
   * - `zones`: zones
   *
   * - `uuid`: uuid
   *
   * - `toPrice`: toPrice
   *
   * - `sort`: sort
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `fromPrice`: fromPrice
   *
   * - `discriminators`: discriminators
   *
   * - `description`: description
   *
   * - `countryCode`: countryCode
   *
   * - `cityId`: cityId
   *
   * @return OK
   */
  publicAssetsUsingGETResponse(params: NftControllerService.PublicAssetsUsingGETParams): __Observable<__StrictHttpResponse<NftTOList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.zones || []).forEach(val => {if (val != null) __params = __params.append('zones', val.toString())});
    if (params.uuid != null) __params = __params.set('uuid', params.uuid.toString());
    if (params.toPrice != null) __params = __params.set('toPrice', params.toPrice.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.fromPrice != null) __params = __params.set('fromPrice', params.fromPrice.toString());
    (params.discriminators || []).forEach(val => {if (val != null) __params = __params.append('discriminators', val.toString())});
    if (params.description != null) __params = __params.set('description', params.description.toString());
    if (params.countryCode != null) __params = __params.set('countryCode', params.countryCode.toString());
    if (params.cityId != null) __params = __params.set('cityId', params.cityId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/nft/public-asset`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NftTOList>;
      })
    );
  }
  /**
   * publicAssets
   * @param params The `NftControllerService.PublicAssetsUsingGETParams` containing the following parameters:
   *
   * - `zones`: zones
   *
   * - `uuid`: uuid
   *
   * - `toPrice`: toPrice
   *
   * - `sort`: sort
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * - `fromPrice`: fromPrice
   *
   * - `discriminators`: discriminators
   *
   * - `description`: description
   *
   * - `countryCode`: countryCode
   *
   * - `cityId`: cityId
   *
   * @return OK
   */
  publicAssetsUsingGET(params: NftControllerService.PublicAssetsUsingGETParams): __Observable<NftTOList> {
    return this.publicAssetsUsingGETResponse(params).pipe(
      __map(_r => _r.body as NftTOList)
    );
  }

  /**
   * publicStatistic
   * @param uuid uuid
   * @return OK
   */
  publicStatisticUsingGETResponse(uuid: string): __Observable<__StrictHttpResponse<NftStatisticTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (uuid != null) __params = __params.set('uuid', uuid.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/nft/public-statistic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NftStatisticTOResponse>;
      })
    );
  }
  /**
   * publicStatistic
   * @param uuid uuid
   * @return OK
   */
  publicStatisticUsingGET(uuid: string): __Observable<NftStatisticTOResponse> {
    return this.publicStatisticUsingGETResponse(uuid).pipe(
      __map(_r => _r.body as NftStatisticTOResponse)
    );
  }

  /**
   * statistic
   * @return OK
   */
  statisticUsingGETResponse(): __Observable<__StrictHttpResponse<NftStatisticTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/nft/statistic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NftStatisticTOResponse>;
      })
    );
  }
  /**
   * statistic
   * @return OK
   */
  statisticUsingGET(): __Observable<NftStatisticTOResponse> {
    return this.statisticUsingGETResponse().pipe(
      __map(_r => _r.body as NftStatisticTOResponse)
    );
  }

  /**
   * suggestionForCollection
   * @param params The `NftControllerService.SuggestionForCollectionUsingGET1Params` containing the following parameters:
   *
   * - `zone`: zone
   *
   * - `metaInformationId`: metaInformationId
   *
   * - `sort`: sort
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * @return OK
   */
  suggestionForCollectionUsingGET1Response(params: NftControllerService.SuggestionForCollectionUsingGET1Params): __Observable<__StrictHttpResponse<NftTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.zone != null) __params = __params.set('zone', params.zone.toString());
    if (params.metaInformationId != null) __params = __params.set('metaInformationId', params.metaInformationId.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/nft/suggestion-for-collection`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NftTOListResponse>;
      })
    );
  }
  /**
   * suggestionForCollection
   * @param params The `NftControllerService.SuggestionForCollectionUsingGET1Params` containing the following parameters:
   *
   * - `zone`: zone
   *
   * - `metaInformationId`: metaInformationId
   *
   * - `sort`: sort
   *
   * - `pageSize`: pageSize
   *
   * - `pageNumber`: pageNumber
   *
   * @return OK
   */
  suggestionForCollectionUsingGET1(params: NftControllerService.SuggestionForCollectionUsingGET1Params): __Observable<NftTOListResponse> {
    return this.suggestionForCollectionUsingGET1Response(params).pipe(
      __map(_r => _r.body as NftTOListResponse)
    );
  }

  /**
   * transfer
   * @param form form
   * @return OK
   */
  transferUsingPOSTResponse(form: NftTransferForm): __Observable<__StrictHttpResponse<NftGeoJsonResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/nft/transfer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NftGeoJsonResponse>;
      })
    );
  }
  /**
   * transfer
   * @param form form
   * @return OK
   */
  transferUsingPOST(form: NftTransferForm): __Observable<NftGeoJsonResponse> {
    return this.transferUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as NftGeoJsonResponse)
    );
  }
}

module NftControllerService {

  /**
   * Parameters for listUsingGET14
   */
  export interface ListUsingGET14Params {
    zones?: Array<number>;
    zone?: number;
    walletIds?: Array<number>;
    walletId?: number;
    uuids?: Array<string>;
    types?: Array<'BUILDING' | 'COLLECTION' | 'STREET' | 'UNKNOWN_DISCRIMINATOR' | 'UNRECOGNIZED'>;
    toPrice?: number;
    streetIds?: Array<number>;
    streetId?: number;
    street?: string;
    sort?: string;
    priceMin?: number;
    priceMax?: number;
    priceBuy?: number;
    pageSize?: number;
    pageNumber?: number;
    pageFilterSort?: Array<string>;
    pageFilterPageSize?: number;
    pageFilterPageNumber?: number;
    pageFilterOffset?: number;
    ownerUuids?: Array<string>;
    ownerUuid?: string;
    ownerName?: string;
    nftIds?: Array<number>;
    lastPrice?: number;
    isWithdrawn?: boolean;
    isReserved?: boolean;
    ids?: Array<number>;
    id?: number;
    fromPrice?: number;
    discriminators?: Array<'BUILDING' | 'COLLECTION' | 'STREET' | 'UNKNOWN_DISCRIMINATOR' | 'UNRECOGNIZED'>;
    description?: string;
    currencyIds?: Array<number>;
    currencyId?: number;
    countryCodes?: Array<string>;
    countryCode?: string;
    country?: string;
    collectionIds?: Array<number>;
    collectionId?: number;
    cityIds?: Array<number>;
    cityId?: number;
    city?: string;
    buyingPrice?: number;
    buildingIds?: Array<number>;
    assetIds?: Array<number>;
  }

  /**
   * Parameters for myNftUsingGET
   */
  export interface MyNftUsingGETParams {

    /**
     * zones
     */
    zones?: Array<number>;

    /**
     * toPrice
     */
    toPrice?: number;

    /**
     * sort
     */
    sort?: string;

    /**
     * pageSize
     */
    pageSize?: number;

    /**
     * pageNumber
     */
    pageNumber?: number;

    /**
     * fromPrice
     */
    fromPrice?: number;

    /**
     * discriminators
     */
    discriminators?: Array<'BUILDING' | 'COLLECTION' | 'STREET' | 'UNKNOWN_DISCRIMINATOR' | 'UNRECOGNIZED'>;

    /**
     * description
     */
    description?: string;

    /**
     * countryCode
     */
    countryCode?: string;

    /**
     * collectionId
     */
    collectionId?: number;

    /**
     * cityId
     */
    cityId?: number;
  }

  /**
   * Parameters for myFavoriteUsingGET
   */
  export interface MyFavoriteUsingGETParams {

    /**
     * zones
     */
    zones?: Array<number>;

    /**
     * toPrice
     */
    toPrice?: number;

    /**
     * sort
     */
    sort?: string;

    /**
     * pageSize
     */
    pageSize?: number;

    /**
     * pageNumber
     */
    pageNumber?: number;

    /**
     * fromPrice
     */
    fromPrice?: number;

    /**
     * description
     */
    description?: string;
  }

  /**
   * Parameters for getNftHistoryUsingGET
   */
  export interface GetNftHistoryUsingGETParams {

    /**
     * pageSize
     */
    pageSize: number;

    /**
     * pageNumber
     */
    pageNumber: number;

    /**
     * nftId
     */
    nftId: number;
  }

  /**
   * Parameters for getNftOrderHistoryUsingGET
   */
  export interface GetNftOrderHistoryUsingGETParams {
    pageSize: number;
    pageNumber: number;
    zones?: Array<number>;
    sort?: string;
    side?: 'BUY' | 'SELL' | 'UNKNOWN_SIDE' | 'UNRECOGNIZED';
    priceTo?: number;
    priceFrom?: number;
    orderType?: 'LIMIT' | 'MARKET' | 'MYSTERY_BOX' | 'PVP' | 'UNRECOGNIZED' | 'UNRECOGNIZED_NFT_ORDER';
    nftType?: 'BUILDING' | 'COLLECTION' | 'STREET' | 'UNKNOWN_DISCRIMINATOR' | 'UNRECOGNIZED';
    nftId?: number;
    name?: string;
    id?: number;
    closedTo?: number;
    closedFrom?: number;
    closeReason?: 'CANCELLED_BY_ADMIN' | 'CANCELLED_BY_OWNER' | 'COMPLETED' | 'EMPTY_AMOUNT' | 'ERROR' | 'MARKET_DISABLED' | 'MARKET_ORDER_NOT_FILLED' | 'OPEN' | 'PARTIALLY_FILLED' | 'PENDING_CANCEL' | 'PENDING_MARKET_DISABLED' | 'TIME_EXPIRED' | 'TOO_LOW_AMOUNT' | 'TOO_LOW_MATCH' | 'UNKNOWN_CLOSE_REASON' | 'UNRECOGNIZED';
  }

  /**
   * Parameters for publicAssetsUsingGET
   */
  export interface PublicAssetsUsingGETParams {

    /**
     * zones
     */
    zones?: Array<number>;

    /**
     * uuid
     */
    uuid?: string;

    /**
     * toPrice
     */
    toPrice?: number;

    /**
     * sort
     */
    sort?: string;

    /**
     * pageSize
     */
    pageSize?: number;

    /**
     * pageNumber
     */
    pageNumber?: number;

    /**
     * fromPrice
     */
    fromPrice?: number;

    /**
     * discriminators
     */
    discriminators?: Array<'BUILDING' | 'COLLECTION' | 'STREET' | 'UNKNOWN_DISCRIMINATOR' | 'UNRECOGNIZED'>;

    /**
     * description
     */
    description?: string;

    /**
     * countryCode
     */
    countryCode?: string;

    /**
     * cityId
     */
    cityId?: number;
  }

  /**
   * Parameters for suggestionForCollectionUsingGET1
   */
  export interface SuggestionForCollectionUsingGET1Params {

    /**
     * zone
     */
    zone: number;

    /**
     * metaInformationId
     */
    metaInformationId: number;

    /**
     * sort
     */
    sort?: string;

    /**
     * pageSize
     */
    pageSize?: number;

    /**
     * pageNumber
     */
    pageNumber?: number;
  }
}

export { NftControllerService }
