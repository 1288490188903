export enum UserPlan {
  BASIC = 'Basic',
  STANDARD = 'Standard',
  PREMIUM = 'Premium',
  ELITE = 'Elite',
}

export enum UserPlanUppercase {
  BASIC = 'DEFAULT_PLAN',
  STANDARD = 'STANDARD',
  PREMIUM = 'PREMIUM',
  ELITE = 'ELITE',
}
