import { CityTOListResponse } from 'api/models';
import { CollectionMetaInformationControllerService } from 'api/services';
import { Observable } from 'rxjs';

import { CitySearchStrategy } from './city-search-strategy.interface';

export class CitySearchCollectionStrategy implements CitySearchStrategy {
  constructor(private collectionMetaApi: CollectionMetaInformationControllerService) {}

  getCities(
    name: string,
    countryCode?: string,
    pageSize?: number,
    regionCode?: string
  ): Observable<CityTOListResponse> {
    return this.collectionMetaApi.availableCitiesUsingGET({
      nameStart: name,
      countryCode,
      pageSize,
      regionCode,
    });
  }
}
