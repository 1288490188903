/* eslint-disable prefer-arrow-functions/prefer-arrow-functions */
import { Geometry as StreetGeometry, Properties } from 'api/models';
import { Collection } from 'app/collections/models/collection.model';
import { AppIcon, AppIconName } from 'core/icons/icon.type';
import { iconAccountAdvanced } from 'core/icons/lib/icon-account-advanced';
import { iconAccountMonopolist } from 'core/icons/lib/icon-account-monopolist';
import { iconAccountNovice } from 'core/icons/lib/icon-account-novice';
import { iconAccountPro } from 'core/icons/lib/icon-account-pro';
import { NftFilterPlanForm } from 'dashboard/forms/nft-plan-filter.form';

import { Building } from './building.model';
import { Street } from './street.model';

export type StreetLevelType = 'plan1' | 'plan2' | 'plan3' | 'plan4' | 'plan5';
export type StreetLevelDescription = {
  title: string;
  icon: AppIconName;
  zone: number;
  level: StreetLevelType;
};
export type UserLevelDescription = { title: string; icon: AppIcon; zone: number };
export type StreetData = Properties & {
  coordinates?: StreetGeometry['coordinates'];
};

export type EntityType = 'street' | 'building' | 'collection';

export type NFTEntity = Collection | Building | Street;

export const LEVELS_ZONE: { [key: string]: number } = {
  plan5: 5,
  plan4: 4,
  plan3: 3,
  plan2: 2,
  plan1: 1,
};
export const ZONE_LEVELS: { [key: string]: StreetLevelType } = {
  5: 'plan5',
  4: 'plan4',
  3: 'plan3',
  2: 'plan2',
  1: 'plan1',
};

export const ZONE_LABELS: { [key: string]: string } = {
  5: 'Unique',
  4: 'Elite',
  3: 'Premium',
  2: 'Standard',
  1: 'Basic',
};

export const QUIZ_ZONE_LABELS: { [key: string]: StreetLevelType } = {
  ELITE: 'plan4',
  PREMIUM: 'plan3',
  STANDARD: 'plan2',
  BASIC: 'plan1',
};

export const STREET_LEVELS: { [key in StreetLevelType]: StreetLevelDescription } = {
  plan1: { zone: 1, title: zoneToLabel(1), icon: zoneToLevel(1), level: 'plan1' },
  plan2: { zone: 2, title: zoneToLabel(2), icon: zoneToLevel(2), level: 'plan2' },
  plan3: { zone: 3, title: zoneToLabel(3), icon: zoneToLevel(3), level: 'plan3' },
  plan4: { zone: 4, title: zoneToLabel(4), icon: zoneToLevel(4), level: 'plan4' },
  plan5: { zone: 5, title: zoneToLabel(5), icon: zoneToLevel(5), level: 'plan5' },
} as const;

export const USER_LEVELS: { [key: string]: UserLevelDescription } = {
  plan1: { zone: 1, title: 'Novice', icon: iconAccountNovice },
  plan2: { zone: 2, title: 'Advanced', icon: iconAccountAdvanced },
  plan3: { zone: 3, title: 'Pro', icon: iconAccountPro },
  plan4: { zone: 4, title: 'Magnate', icon: iconAccountMonopolist },
} as const;

export function convertToQueryZones(zones: NftFilterPlanForm): string {
  if (!zones) {
    return '';
  }

  const levels = [
    { ...STREET_LEVELS.plan1, control: zones.isBasic },
    { ...STREET_LEVELS.plan2, control: zones.isStandard },
    { ...STREET_LEVELS.plan3, control: zones.isPremium },
    { ...STREET_LEVELS.plan4, control: zones.isElite },
    { ...STREET_LEVELS.plan5, control: zones.isUnique },
  ];

  return levels
    .filter(level => level.control)
    .map(level => level.zone)
    .join(',');
}

export function convertToNftZones(zones: string): NftFilterPlanForm {
  if (!zones) {
    return {
      isBasic: true,
      isElite: true,
      isPremium: true,
      isStandard: true,
      isUnique: true,
    };
  }

  const levels = {
    [STREET_LEVELS.plan1.zone]: 'isBasic',
    [STREET_LEVELS.plan2.zone]: 'isStandard',
    [STREET_LEVELS.plan3.zone]: 'isPremium',
    [STREET_LEVELS.plan4.zone]: 'isElite',
    [STREET_LEVELS.plan5.zone]: 'isUnique',
  };

  return zones
    .split(',')
    .map(zone => +zone)
    .reduce(
      (acc, curr) => ({
        ...acc,
        [levels[curr]]: true,
      }),
      {
        isBasic: false,
        isElite: false,
        isPremium: false,
        isStandard: false,
        isUnique: false,
      }
    );
}

export function zoneToLevel(zone?: number): StreetLevelType {
  return ZONE_LEVELS[zone || 1];
}

export function zoneToLabel(zone?: number): string {
  return ZONE_LABELS[zone || 1];
}

export function levelToZone(level: StreetLevelType): number {
  return LEVELS_ZONE[level];
}
