import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NftCollectionTOResponse, OrderTOResponse, PageTO } from 'api/models';
import { DwrldControllerService, OrderControllerService } from 'api/services';
import { StateClearService, StateFullService } from 'app/_main/services/state-clear.service';
import { CollectionService } from 'app/collections/services/collections.service';
import { SocketService } from 'core/modules/socket/socket.service';
import { NftFilterPlanForm } from 'dashboard/forms/nft-plan-filter.form';
import { DwrldOrder } from 'dashboard/models/dwrld-order.model';
import { NftFilterData } from 'dashboard/models/nftFilterData.data';
import {
  convertToNftZones,
  convertToQueryZones,
  STREET_LEVELS,
} from 'dashboard/models/street.data';
import { Street } from 'dashboard/models/street.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import {
  OrderClosedEventData,
  OrderOpenedEventData,
  UserMessageReceived,
} from './dashboard.service';
import { DwrldOrdersFactory } from './dwrld-orders.factory';
import { PageControllerService, RequestData } from './page-controller.service';
import { StreetsFactory } from './streets.factory';

type EventType = 'ORDER_OPENED' | 'ORDER_CLOSED' | 'USER_MESSAGE_RECEIVED' | 'NFT_BUY';
export interface DwrldOrdersResponse {
  list?: DwrldOrder[];
  pageInfo?: PageTO;
}
@Injectable({ providedIn: 'root' })
export class DwrldOrdersService
  extends PageControllerService<NftFilterData, string>
  implements StateFullService
{
  private dwrldOrders$ = new BehaviorSubject<DwrldOrdersResponse>({
    list: [],
    pageInfo: {},
  });

  constructor(
    private dwrldOrdersFactory: DwrldOrdersFactory,
    private streetsFactory: StreetsFactory,
    private api: DwrldControllerService,
    private ordersApi: OrderControllerService,
    private stateClearService: StateClearService,
    private collectionService: CollectionService,
    protected activatedRoute: ActivatedRoute,
    protected route: ActivatedRoute,
    protected router: Router,
    socket: SocketService
  ) {
    super(route, router);
    this.stateClearService.register(this);
    socket
      .on<{
        eventType: EventType;
        data: OrderOpenedEventData | OrderClosedEventData | UserMessageReceived;
      }>()
      .subscribe(response => {
        if (response.eventType === 'ORDER_OPENED' || response.eventType === 'ORDER_CLOSED') {
          this.refreshPage();
        }
        if (response.eventType === 'USER_MESSAGE_RECEIVED') {
          const data = response.data as UserMessageReceived;
          if (data.messageType === 'USER_WANT_BUY_NFT') {
            this.refreshPage();
          }
        }
      });
  }

  getMarketData(
    side: DwrldControllerService.OrdersUsingGETParams['side']
  ): Observable<DwrldOrdersResponse> {
    return this.getRequestData().pipe(
      switchMap(data =>
        this.api
          .ordersUsingGET({
            side,
            searchText: !Object.is(data.filter, null) ? data.filter?.description : '',
            minPrice: data.filter?.price?.fromPrice || undefined,
            maxPrice: data.filter?.price?.toPrice || undefined,
            pageNumber: data.pagination.pageNumber,
            pageSize: this.pageSize,
            zones: this.convertZones(data.filter?.zones),
            sort: data.sort,
          })
          .pipe(
            tap(data => {
              this.dwrldOrders$.next({
                list: (data.elements || []).map(order =>
                  this.dwrldOrdersFactory.get(order.orderId, order)
                ),
                pageInfo: {
                  ...data.pageInfo,
                  pageNumber: data.pageInfo?.pageNumber ? data.pageInfo.pageNumber : 0,
                },
              });
            }),
            switchMap(() => this.dwrldOrders$.asObservable()),
            catchError(() =>
              of({
                list: [],
                pageInfo: {},
              })
            )
          )
      )
    );
  }

  closeOrder(orderId: number): Observable<OrderTOResponse> {
    return this.ordersApi.closeUsingPOST({ orderId }).pipe(
      tap(orderData => {
        const street: Street = this.streetsFactory.get(orderData.nft?.properties?.streetId);
        street.buyPrice = orderData.nft?.properties?.buyingPrice || 0;
      })
    );
  }

  detachNFTFromCollection(
    collectionId: number,
    nftId: number
  ): Observable<NftCollectionTOResponse> {
    return this.collectionService
      .getCollectionById(collectionId)
      .pipe(switchMap(collectionData => this.collectionService.detachNft(collectionData, [nftId])));
  }

  private convertZones(zones?: NftFilterPlanForm): number[] {
    if (!zones) return [1, 2, 3, 4];

    const levels = [
      { ...STREET_LEVELS.plan1, control: zones.isBasic },
      { ...STREET_LEVELS.plan2, control: zones.isStandard },
      { ...STREET_LEVELS.plan3, control: zones.isPremium },
      { ...STREET_LEVELS.plan4, control: zones.isElite },
    ];

    return levels.filter(item => item?.control).map(item => item.zone);
  }

  protected override convertToRequestData(
    queryParams: Partial<Record<string, string>>
  ): RequestData<NftFilterData, string> {
    const { sort, pageNumber, zones, description, fromPrice, toPrice } = queryParams || {};
    return {
      sort: sort || '',
      filter: {
        description,
        price: {
          fromPrice: fromPrice ? +fromPrice : undefined,
          toPrice: toPrice ? +toPrice : undefined,
        },
        zones: convertToNftZones(zones || ''),
      },
      pagination: {
        ...this.requestData$.getValue().pagination,
        pageNumber: pageNumber ? +pageNumber : 0,
      },
    };
  }

  protected override createQueryParams(data: RequestData<NftFilterData, string>): Params {
    const { sort, pagination, filter } = data;
    const queryParams = {
      sort: sort || null,
      pageNumber: pagination.pageNumber || null,
      description: filter.description || null,
      fromPrice: filter.price?.fromPrice || null,
      toPrice: filter.price?.toPrice || null,
      zones: filter.zones ? convertToQueryZones(filter.zones) : null,
    };

    return queryParams;
  }

  clearState(): void {
    this.dwrldOrders$.next({
      list: [],
      pageInfo: {},
    });

    this.dwrldOrdersFactory.emptyStore();
  }
}
