/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UserMessageCountsResponseTO } from '../models/user-message-counts-response-to';
import { UserMessageGroupTOListResponse } from '../models/user-message-group-tolist-response';
import { UserMessageTOListResponse } from '../models/user-message-tolist-response';
import { UserMessageTOResponse } from '../models/user-message-toresponse';
import { UserMessageSubscribeTOListResponse } from '../models/user-message-subscribe-tolist-response';
import { UserMessageSubscribeFrom } from '../models/user-message-subscribe-from';
import { UserMessageTypeTOListResponse } from '../models/user-message-type-tolist-response';

/**
 * User Messages Controller
 */
@Injectable({
  providedIn: 'root',
})
class UserMessagesControllerService extends __BaseService {
  static readonly userMessageCountsUsingGETPath = '/api/v1/user/messages/counts';
  static readonly groupsUsingGETPath = '/api/v1/user/messages/groups';
  static readonly listUsingGET19Path = '/api/v1/user/messages/list';
  static readonly markAllProcessedUsingPOSTPath = '/api/v1/user/messages/mark-all-processed';
  static readonly markAllProcessedForMetricsUsingPOSTPath = '/api/v1/user/messages/mark-all-processed-for-metrics';
  static readonly markAllReadUsingPOSTPath = '/api/v1/user/messages/mark-all-read';
  static readonly markAsProcessedUsingPOSTPath = '/api/v1/user/messages/mark-as-processed';
  static readonly markAsProcessedForMetricsUsingPOSTPath = '/api/v1/user/messages/mark-as-processed-for-metrics';
  static readonly markAsReadUsingPOSTPath = '/api/v1/user/messages/mark-as-read';
  static readonly subscribeUsingPOSTPath = '/api/v1/user/messages/subscribe';
  static readonly subscriptionsUsingGETPath = '/api/v1/user/messages/subscriptions';
  static readonly typesUsingGETPath = '/api/v1/user/messages/types';
  static readonly unsubscribeUsingPOSTPath = '/api/v1/user/messages/unsubscribe';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * User message counts
   *
   * User message counts<br/>
   * @return OK
   */
  userMessageCountsUsingGETResponse(): __Observable<__StrictHttpResponse<UserMessageCountsResponseTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/user/messages/counts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserMessageCountsResponseTO>;
      })
    );
  }
  /**
   * User message counts
   *
   * User message counts<br/>
   * @return OK
   */
  userMessageCountsUsingGET(): __Observable<UserMessageCountsResponseTO> {
    return this.userMessageCountsUsingGETResponse().pipe(
      __map(_r => _r.body as UserMessageCountsResponseTO)
    );
  }

  /**
   * User messages group list
   *
   * List of groups for user messages<br/>
   * @return OK
   */
  groupsUsingGETResponse(): __Observable<__StrictHttpResponse<UserMessageGroupTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/user/messages/groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserMessageGroupTOListResponse>;
      })
    );
  }
  /**
   * User messages group list
   *
   * List of groups for user messages<br/>
   * @return OK
   */
  groupsUsingGET(): __Observable<UserMessageGroupTOListResponse> {
    return this.groupsUsingGETResponse().pipe(
      __map(_r => _r.body as UserMessageGroupTOListResponse)
    );
  }

  /**
   * User messages list
   *
   * List of user messages<br/>
   * @param params The `UserMessagesControllerService.ListUsingGET19Params` containing the following parameters:
   *
   * - `type`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `onlyNotProcessed`:
   *
   * @return OK
   */
  listUsingGET19Response(params: UserMessagesControllerService.ListUsingGET19Params): __Observable<__StrictHttpResponse<UserMessageTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.type != null) __params = __params.set('type', params.type.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.onlyNotProcessed != null) __params = __params.set('onlyNotProcessed', params.onlyNotProcessed.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/user/messages/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserMessageTOListResponse>;
      })
    );
  }
  /**
   * User messages list
   *
   * List of user messages<br/>
   * @param params The `UserMessagesControllerService.ListUsingGET19Params` containing the following parameters:
   *
   * - `type`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `onlyNotProcessed`:
   *
   * @return OK
   */
  listUsingGET19(params: UserMessagesControllerService.ListUsingGET19Params): __Observable<UserMessageTOListResponse> {
    return this.listUsingGET19Response(params).pipe(
      __map(_r => _r.body as UserMessageTOListResponse)
    );
  }

  /**
   * Mark all as processed
   *
   * Return list of user messages<br/>
   * @param params The `UserMessagesControllerService.MarkAllProcessedUsingPOSTParams` containing the following parameters:
   *
   * - `type`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `onlyNotProcessed`:
   *
   * @return OK
   */
  markAllProcessedUsingPOSTResponse(params: UserMessagesControllerService.MarkAllProcessedUsingPOSTParams): __Observable<__StrictHttpResponse<UserMessageTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.type != null) __params = __params.set('type', params.type.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.onlyNotProcessed != null) __params = __params.set('onlyNotProcessed', params.onlyNotProcessed.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/user/messages/mark-all-processed`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserMessageTOListResponse>;
      })
    );
  }
  /**
   * Mark all as processed
   *
   * Return list of user messages<br/>
   * @param params The `UserMessagesControllerService.MarkAllProcessedUsingPOSTParams` containing the following parameters:
   *
   * - `type`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `onlyNotProcessed`:
   *
   * @return OK
   */
  markAllProcessedUsingPOST(params: UserMessagesControllerService.MarkAllProcessedUsingPOSTParams): __Observable<UserMessageTOListResponse> {
    return this.markAllProcessedUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as UserMessageTOListResponse)
    );
  }

  /**
   * Mark all as processed  for metrics
   * @param params The `UserMessagesControllerService.MarkAllProcessedForMetricsUsingPOSTParams` containing the following parameters:
   *
   * - `type`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `onlyNotProcessed`:
   *
   * @return OK
   */
  markAllProcessedForMetricsUsingPOSTResponse(params: UserMessagesControllerService.MarkAllProcessedForMetricsUsingPOSTParams): __Observable<__StrictHttpResponse<UserMessageTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.type != null) __params = __params.set('type', params.type.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.onlyNotProcessed != null) __params = __params.set('onlyNotProcessed', params.onlyNotProcessed.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/user/messages/mark-all-processed-for-metrics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserMessageTOListResponse>;
      })
    );
  }
  /**
   * Mark all as processed  for metrics
   * @param params The `UserMessagesControllerService.MarkAllProcessedForMetricsUsingPOSTParams` containing the following parameters:
   *
   * - `type`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `onlyNotProcessed`:
   *
   * @return OK
   */
  markAllProcessedForMetricsUsingPOST(params: UserMessagesControllerService.MarkAllProcessedForMetricsUsingPOSTParams): __Observable<UserMessageTOListResponse> {
    return this.markAllProcessedForMetricsUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as UserMessageTOListResponse)
    );
  }

  /**
   * Mark all as read
   *
   * Return list of user messages<br/>
   * @param params The `UserMessagesControllerService.MarkAllReadUsingPOSTParams` containing the following parameters:
   *
   * - `type`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `onlyNotProcessed`:
   *
   * @return OK
   */
  markAllReadUsingPOSTResponse(params: UserMessagesControllerService.MarkAllReadUsingPOSTParams): __Observable<__StrictHttpResponse<UserMessageTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.type != null) __params = __params.set('type', params.type.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.onlyNotProcessed != null) __params = __params.set('onlyNotProcessed', params.onlyNotProcessed.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/user/messages/mark-all-read`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserMessageTOListResponse>;
      })
    );
  }
  /**
   * Mark all as read
   *
   * Return list of user messages<br/>
   * @param params The `UserMessagesControllerService.MarkAllReadUsingPOSTParams` containing the following parameters:
   *
   * - `type`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `onlyNotProcessed`:
   *
   * @return OK
   */
  markAllReadUsingPOST(params: UserMessagesControllerService.MarkAllReadUsingPOSTParams): __Observable<UserMessageTOListResponse> {
    return this.markAllReadUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as UserMessageTOListResponse)
    );
  }

  /**
   * Mark selected message as processed
   * @param messageId messageId
   * @return OK
   */
  markAsProcessedUsingPOSTResponse(messageId: number): __Observable<__StrictHttpResponse<UserMessageTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (messageId != null) __params = __params.set('messageId', messageId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/user/messages/mark-as-processed`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserMessageTOResponse>;
      })
    );
  }
  /**
   * Mark selected message as processed
   * @param messageId messageId
   * @return OK
   */
  markAsProcessedUsingPOST(messageId: number): __Observable<UserMessageTOResponse> {
    return this.markAsProcessedUsingPOSTResponse(messageId).pipe(
      __map(_r => _r.body as UserMessageTOResponse)
    );
  }

  /**
   * Mark selected message as processed for metrics
   * @param messageId messageId
   * @return OK
   */
  markAsProcessedForMetricsUsingPOSTResponse(messageId: number): __Observable<__StrictHttpResponse<UserMessageTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (messageId != null) __params = __params.set('messageId', messageId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/user/messages/mark-as-processed-for-metrics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserMessageTOResponse>;
      })
    );
  }
  /**
   * Mark selected message as processed for metrics
   * @param messageId messageId
   * @return OK
   */
  markAsProcessedForMetricsUsingPOST(messageId: number): __Observable<UserMessageTOResponse> {
    return this.markAsProcessedForMetricsUsingPOSTResponse(messageId).pipe(
      __map(_r => _r.body as UserMessageTOResponse)
    );
  }

  /**
   * Mark selected message as read
   * @param messageId messageId
   * @return OK
   */
  markAsReadUsingPOSTResponse(messageId: number): __Observable<__StrictHttpResponse<UserMessageTOResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (messageId != null) __params = __params.set('messageId', messageId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/user/messages/mark-as-read`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserMessageTOResponse>;
      })
    );
  }
  /**
   * Mark selected message as read
   * @param messageId messageId
   * @return OK
   */
  markAsReadUsingPOST(messageId: number): __Observable<UserMessageTOResponse> {
    return this.markAsReadUsingPOSTResponse(messageId).pipe(
      __map(_r => _r.body as UserMessageTOResponse)
    );
  }

  /**
   * Subscribe current user for selected user messages group
   * @param form form
   * @return OK
   */
  subscribeUsingPOSTResponse(form: UserMessageSubscribeFrom): __Observable<__StrictHttpResponse<UserMessageSubscribeTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/user/messages/subscribe`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserMessageSubscribeTOListResponse>;
      })
    );
  }
  /**
   * Subscribe current user for selected user messages group
   * @param form form
   * @return OK
   */
  subscribeUsingPOST(form: UserMessageSubscribeFrom): __Observable<UserMessageSubscribeTOListResponse> {
    return this.subscribeUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as UserMessageSubscribeTOListResponse)
    );
  }

  /**
   * List of user messages groups that user subscribed
   * @return OK
   */
  subscriptionsUsingGETResponse(): __Observable<__StrictHttpResponse<UserMessageSubscribeTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/user/messages/subscriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserMessageSubscribeTOListResponse>;
      })
    );
  }
  /**
   * List of user messages groups that user subscribed
   * @return OK
   */
  subscriptionsUsingGET(): __Observable<UserMessageSubscribeTOListResponse> {
    return this.subscriptionsUsingGETResponse().pipe(
      __map(_r => _r.body as UserMessageSubscribeTOListResponse)
    );
  }

  /**
   * User messages types list
   *
   * List of types for user messages<br/>
   * @return OK
   */
  typesUsingGETResponse(): __Observable<__StrictHttpResponse<UserMessageTypeTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/user/messages/types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserMessageTypeTOListResponse>;
      })
    );
  }
  /**
   * User messages types list
   *
   * List of types for user messages<br/>
   * @return OK
   */
  typesUsingGET(): __Observable<UserMessageTypeTOListResponse> {
    return this.typesUsingGETResponse().pipe(
      __map(_r => _r.body as UserMessageTypeTOListResponse)
    );
  }

  /**
   * Unsubscribe current user from selected user messages group
   * @param form form
   * @return OK
   */
  unsubscribeUsingPOSTResponse(form: UserMessageSubscribeFrom): __Observable<__StrictHttpResponse<UserMessageSubscribeTOListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/user/messages/unsubscribe`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserMessageSubscribeTOListResponse>;
      })
    );
  }
  /**
   * Unsubscribe current user from selected user messages group
   * @param form form
   * @return OK
   */
  unsubscribeUsingPOST(form: UserMessageSubscribeFrom): __Observable<UserMessageSubscribeTOListResponse> {
    return this.unsubscribeUsingPOSTResponse(form).pipe(
      __map(_r => _r.body as UserMessageSubscribeTOListResponse)
    );
  }
}

module UserMessagesControllerService {

  /**
   * Parameters for listUsingGET19
   */
  export interface ListUsingGET19Params {
    type?: 'ALL' | 'ASSET' | 'DOCUMENT' | 'METAVERSE_3D' | 'UNRECOGNIZED';
    pageSize?: number;
    pageNumber?: number;
    onlyNotProcessed?: boolean;
  }

  /**
   * Parameters for markAllProcessedUsingPOST
   */
  export interface MarkAllProcessedUsingPOSTParams {
    type?: 'ALL' | 'ASSET' | 'DOCUMENT' | 'METAVERSE_3D' | 'UNRECOGNIZED';
    pageSize?: number;
    pageNumber?: number;
    onlyNotProcessed?: boolean;
  }

  /**
   * Parameters for markAllProcessedForMetricsUsingPOST
   */
  export interface MarkAllProcessedForMetricsUsingPOSTParams {
    type?: 'ALL' | 'ASSET' | 'DOCUMENT' | 'METAVERSE_3D' | 'UNRECOGNIZED';
    pageSize?: number;
    pageNumber?: number;
    onlyNotProcessed?: boolean;
  }

  /**
   * Parameters for markAllReadUsingPOST
   */
  export interface MarkAllReadUsingPOSTParams {
    type?: 'ALL' | 'ASSET' | 'DOCUMENT' | 'METAVERSE_3D' | 'UNRECOGNIZED';
    pageSize?: number;
    pageNumber?: number;
    onlyNotProcessed?: boolean;
  }
}

export { UserMessagesControllerService }
