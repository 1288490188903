import { Injectable } from '@angular/core';
import {
  MarketTOResponse,
  NftGeoJsonResponse,
  NftTO,
  Properties,
  StreetGeoJsonResponse,
} from 'api/models';
import { SocketService } from 'core/modules/socket/socket.service';
import { FavoriteProvider } from 'dashboard/models/favoriteProvider.model';
import { dataToMarket, Market } from 'dashboard/models/market.model';
import { UserWantsToBuyEvent } from 'dashboard/models/nft-trade-events';

import { dataToStreet, Street } from '../models/street.model';
import { EventType } from './dashboard.service';

export const instanceOfGeoJsonResponse = (
  object: unknown
): object is NftGeoJsonResponse | StreetGeoJsonResponse =>
  !!(object as NftGeoJsonResponse | StreetGeoJsonResponse)?.geometry;

@Injectable({ providedIn: 'root' })
export class StreetsFactory {
  private streetsStore: Map<number, Street> = new Map();
  private marketsStore: Map<number, Market> = new Map();
  private favoriteProvider!: FavoriteProvider;

  constructor(socket: SocketService) {
    socket
      .on<{
        eventType: EventType;
        data: { params: UserWantsToBuyEvent; messageType: 'USER_WANT_BUY_NFT' };
      }>()
      .subscribe(response => {
        if (response.eventType === 'USER_MESSAGE_RECEIVED') {
          const data = response.data;
          if (data.messageType === 'USER_WANT_BUY_NFT') {
            const params = response.data.params;
            const street = this.streetsStore.get(+params.streetId);
            if (street) {
              const price = +params.price;
              street.buyPrice = street.buyPrice < price ? price : street.buyPrice;
            }
          }
        }
      });
  }

  setupFavoriteProvider(provider: FavoriteProvider): void {
    this.favoriteProvider = provider;
  }

  get(id = 0, data?: StreetGeoJsonResponse | NftGeoJsonResponse | NftTO, forceSave = false): Street {
    if (instanceOfGeoJsonResponse(data)) {
      // if there is no streetId we have StreetGeoJsonResponse
      if (data.properties && !data.properties.streetId) {
        data.properties.streetId = data.properties.id;
      }
      return this.createStreet(id, data);
    } else {
      const props = data as Properties;
      // TODO: need same models for favorite street and for nft and for gis
      if (data?.currentPrice && !data.ownerAccountId) {
        data.lastPrice = data.currentPrice;
        data.sellPrice = 0;
      }
      return this.createStreet(id, props ? { properties: props } : undefined, data?.marketId, forceSave);
    }
  }

  private createStreet(
    id = 0,
    apiData?: StreetGeoJsonResponse | NftGeoJsonResponse,
    marketId?: number,
    forceSave = false
  ): Street {
    let street = this.streetsStore.get(id);
    const props = apiData?.properties;
    const market = this.marketsStore.get(props?.id || 0);
    if (street) {
      street = apiData
        ? Object.assign(
            street,
            dataToStreet(
              props,
              apiData?.geometry,
              this.favoriteProvider,
              market?.id || marketId || street.marketId,
              street,
              forceSave
            )
          )
        : street;
      return street;
    } else {

      street = new Street(
        dataToStreet(props, apiData?.geometry, this.favoriteProvider, market?.id || marketId)
      );
      this.streetsStore.set(id, street);
      return street;
    }
  }

  getMarket(id = 0, data: MarketTOResponse = {}): Market {
    let market = this.marketsStore.get(id);
    if (market) {
      market = data ? Object.assign(market, dataToMarket(data)) : market;
      market.street = this.createStreet(data.nft?.properties?.streetId, data.nft, data.id);
      return market;
    } else {
      market = new Market(dataToMarket(data));
      this.marketsStore.set(id, market);
      market.street = this.createStreet(data.nft?.properties?.streetId, data.nft, data.id);
      return market;
    }
  }

  emptyStore(): void {
    this.streetsStore.clear();
    this.marketsStore.clear();
  }
}
