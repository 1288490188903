import {
  Directive,
  EventEmitter,
  Host,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { MediaQuery } from 'core/modules/platform/services/media-query.service';
import { asyncScheduler, interval, Subject, Subscription } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

import { ScrollerComponent } from '../../modules/scroller/scroller.component';
import { SectionFadeInDirective } from './section-fade-in.directive';

@Directive({ selector: '[appSectionAutoScroll]' })
export class SectionAutoScrollDirective implements OnInit, OnDestroy {
  @Input()
  scrollBy = 100;

  @Input()
  scrollInterval = 2300;

  @Input()
  toRight?: boolean;

  @Output()
  animated = new EventEmitter();
  private destroy$ = new Subject();
  intervalSub = new Subscription();
  constructor(
    @Host() private scroller: ScrollerComponent,
    private sectionFadeIn: SectionFadeInDirective,
    private media: MediaQuery,
    private renderer: Renderer2
  ) {}

  ngOnDestroy(): void {
    this.intervalSub.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.media.mediaChanged.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.media.is('large')) {
        // eslint-disable-next-line rxjs-angular/prefer-takeuntil
        this.sectionFadeIn.animated.pipe(first()).subscribe(() => {
          this.startAutoScroll();
        });
      } else {
        this.intervalSub.unsubscribe();
        this.renderer.setStyle(this.scroller.elementRef.nativeElement, 'pointer-events', '');
        this.scroller.scrollRef.nativeElement.scrollTo({
          left: 0,
        });
      }
    });
  }

  private startAutoScroll(): void {
    this.renderer.setStyle(this.scroller.elementRef.nativeElement, 'pointer-events', 'none');
    this.intervalSub.unsubscribe();
    this.intervalSub = interval(this.scrollInterval)
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(() => {
        this.scroller.scrollRef.nativeElement.scrollBy({
          left: this.scrollBy,
          behavior: 'smooth',
        });
        asyncScheduler.schedule(() => {
          this.animated.next();
          this.scroller.scrollRef.nativeElement.scrollBy({ left: -this.scrollBy });
        }, this.scrollInterval / 2);
      });
  }
}
