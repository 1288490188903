import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ChatMessageTO } from 'api/models';
import { UserService } from 'profile/services/user.service';

@Component({
  selector: 'app-room-dialog-message-item',
  templateUrl: './room-dialog-message-item.component.html',
  styleUrls: ['./room-dialog-message-item.component.scss'],
})
export class RoomDialogMessageItemComponent implements OnInit{

  @Input() message!: ChatMessageTO;

  @HostBinding('class.mine') mine: boolean = false;

  constructor(
    private userService: UserService,
  ) {}

  ngOnInit() {
    this.mine = this.userService.user.id === this.message?.ownerUuid;
  }
}
