import { Pipe, PipeTransform } from '@angular/core';

import { MarketExchanged } from '../models/market-statistic';

@Pipe({ name: 'favoritesMarkets' })
export class FavoritesMarketsPipe implements PipeTransform {
  transform(allMarkets: MarketExchanged[]) {
    return allMarkets.filter(hero => hero.isFavorite).sort((a, b) => (a.id || 0) - (b.id || 0));
  }
}

@Pipe({ name: 'defaultMarkets' })
export class DefaultMarketsPipe implements PipeTransform {
  transform(allMarkets: MarketExchanged[]) {
    return allMarkets.filter(hero => !hero.isFavorite).sort((a, b) => (a.id || 0) - (b.id || 0));
  }
}
