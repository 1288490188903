import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { AppIcon } from 'core/icons/icon.type';
import { Subscription } from 'rxjs';

import { BaseCustomToast } from '../base-custom-toast.interface';
import { ToastIconType } from './toast.type';

export type toastCallback<R = unknown> = (res: R) => void;

export interface ToastData {
  title: string;
  message: string;
  id: number;
  timeoutSub: Subscription;
  type?: ToastIconType;
  button?: string;
  description?: string;
  callback?: () => void;
  notificationIcon?: AppIcon;
  componentRef?: ComponentType<BaseCustomToast<unknown>>;
  extras?: ToastExtras<unknown>;
  toastClass?: string;
  closeOnClick?: boolean;
}

export interface ToastParameters {
  title?: string;
  message?: string;
  buttonMessage?: string;
  description?: string;
  type?: ToastIconType;
  timeout: number;
  callback?: () => void;
  icon?: AppIcon;
  componentRef?: ComponentType<BaseCustomToast<unknown>>;
  extras?: ToastExtras<unknown>;
  toastClass?: string;
  closeOnClick?: boolean;
}

export interface ToastExtras<E> {
  data: E;
}

export interface ToasterHost {
  toast(parameters: ToastParameters): void;
}

@Injectable()
export class Toaster {
  toasterHost: ToasterHost = {
    toast(): void {
      return;
    },
  };

  info(title: string, message: string, button?: string, callback?: () => void): void {
    this.toasterHost.toast({
      title,
      message,
      buttonMessage: button || '',
      type: 'info',
      timeout: 4200,
      callback,
    });
  }

  success(title: string, message: string, button?: string, callback?: () => void): void {
    this.toasterHost.toast({
      title,
      message,
      buttonMessage: button || '',
      type: 'success',
      timeout: 4200,
      callback,
    });
  }

  warn(title: string, message: string, button?: string, callback?: () => void): void {
    this.toasterHost.toast({
      title,
      message,
      buttonMessage: button || '',
      type: 'warn',
      timeout: 4200,
      callback,
    });
  }

  error(
    title: string,
    message: string,
    button?: string,
    callback?: () => void,
    closeOnClick?: boolean
  ): void {
    this.toasterHost.toast({
      title,
      message,
      buttonMessage: button || '',
      type: 'error',
      timeout: 4200,
      closeOnClick: !!closeOnClick,
      callback,
    });
  }

  notification(message: string, icon?: AppIcon, callback?: () => void, description?: string): void {
    this.toasterHost.toast({ message, icon, timeout: 4200, callback, description });
  }

  customToast<E>(
    componentRef: ComponentType<BaseCustomToast<unknown>>,
    extras: ToastExtras<E>,
    toastClass?: string
  ): void {
    this.toasterHost.toast({ componentRef, extras, timeout: 4200, toastClass });
  }
}
