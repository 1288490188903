import { animate, style, transition, trigger } from '@angular/animations';

export const datepickerTransition = trigger('datepickerTransition', [
  transition('* => open', [
    style({ opacity: 0, transform: 'translate(0, 35px)' }),
    animate('.22s ease-out', style({ opacity: 1, transform: 'translate(0, 0)' })),
  ]),
  transition('* => mobile', [
    style({ opacity: 0, transform: 'translate(-50%, calc(-50% + 35px))' }),
    animate('.22s', style({ opacity: 1, transform: 'translate(-50%, -50%)' })),
  ]),
  transition(':leave', [style({ opacity: 1 }), animate('.2s', style({ opacity: 0 }))]),
]);
